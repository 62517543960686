(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('searchArrow', searchArrow);

    /** @ngInject */
    function searchArrow() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/searcharrow/searcharrow.html',
            scope: {
                currentIndex: '=',
            },
            controller: ['arrowService', 'searchArrowConstants', 'platformService', SearchArrowController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function SearchArrowController(arrowService, searchArrowConstants, platformService) {
            var vm = this;
            vm.shouldBeDisplayed = [];
            vm.shouldBeDisplayed['m'] = [];
            vm.shouldBeDisplayed['d'] = [];
            vm.getShouldBeDisplayed = function() {
                var space = (platformService.getWidth()<768) ? 'm':'d';
                if(angular.isUndefined(vm.shouldBeDisplayed[space][vm.currentIndex])) {
                    var index = vm.currentIndex + 1;
                    if (platformService.getWidth() < 768) {
                        vm.shouldBeDisplayed[space][vm.currentIndex] = (index % 5 == 0);
                    } else {
                        if (index == arrowService.getDesktopArrowIndex()) {
                            arrowService.setDesktopArrowIndex(index + arrowService.getdesktopNextIncrement());
                            arrowService.setdesktopNextIncrement();
                            vm.shouldBeDisplayed[space][vm.currentIndex] = true;
                        } else {
                            vm.shouldBeDisplayed[space][vm.currentIndex] = false;
                        }
                    }
                }

                return vm.shouldBeDisplayed[space][vm.currentIndex];
            };
        }
    }
})();
