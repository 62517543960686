(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpAddress
     * @description Directive displays main application navigation bar. It consist of link to home page, search form,
     * dropdown menu with available categories, add business link that opens modal for adding new business, and user
     * icon that change behavior depending are user currently logged in, offering account info, and displaying user
     * avatar if user is logged in, and log in / sing up links if user is not logged in.
     *
     */
    .directive('rootNavbar', rootNavbar);

  /** @ngInject */
    function rootNavbar() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/websiteRootLocation/websiteRootLocation.html',
            scope: {
                listing: '=',
                bottom: '=',
                top: '=',
                isSearch: '=',
            },


      /**
       * @memberof truelocal
       * @ngdoc controller
       * @name WebsiteRootLocationController
       * @description WebsiteRootLocationController.
       */
            controller: WebsiteRootLocationController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function WebsiteRootLocationController($location, platformService, searchConfigManager, $scope, $rootScope, $window) {
            var vm = this;
            vm.suburb = '';
            vm.category = '';
            vm.state = '';
            vm.name = '';
            vm.isMobile = (platformService.getWidth() < 991) ? true : false;
            vm.baseUrl = $location.protocol() + '://' + $location.host();
            vm.searchConfigManager = searchConfigManager;
            if ($location.port() != '80' || $location.port() != '') {
                vm.baseUrl = vm.baseUrl + ':' + $location.port();
            }
            vm.order = ['category', 'state', 'region', 'suburb', 'businessName'];
            vm.breadcrumbs = [];
            vm.bread = [];
            vm.getBreadcrumbsObject = function() {

                if($rootScope.isBrowseByCategoriesPage) {
                    $rootScope.isBrowseByCategoriesPage = false;
                    vm.setBreadcrumbsForCategoryPage();
                    return vm.breadcrumbs;
                }

                if (vm.breadcrumbs.length == 0) {
                    if (angular.isDefined(vm.listing) && angular.isDefined(vm.listing.breadcrumbs)) {
                        angular.forEach(vm.listing.breadcrumbs, function(breadcrumb, index) {
                            var _breadCrumb = {
                                name: breadcrumb.name,
                                value: breadcrumb.value,
                            };
                            vm.bread[vm.order.indexOf(index)] = _breadCrumb;
                        });
                    }

                    var index = 0;
                    angular.forEach(vm.bread, function(br) {
                        var _breadCrumb = {
                            name: br.name,
                            value: br.value,
                        };
                        vm.breadcrumbs[index] = _breadCrumb;
                        index++;
                    });
                }
                return vm.breadcrumbs;
            };

            vm.setBreadcrumbsForCategoryPage = function() {
                var _breadCrumb = {
                    name: 'Browse by Category',
                    value: 'Browse by Category'
                }
                vm.breadcrumbs[0] = _breadCrumb;
            };

            vm.getBreadCrumbUrl = function(index) {
                var _url = vm.baseUrl + '/find';
                for (var i = 0; i <= index; i++) {
                    _url = _url + '/' + vm.breadcrumbs[i].value;
                }

                return _url;
            };

            vm.bottomVisible = function() {
                if (vm.bottom == true) {
                    return true;
                } else {
                    return false;
                }
            };

            vm.topVisible = function() {
                if (vm.top == true && (vm.isMobile == false || platformService.isIPad())) {
                    return true;
                } else {
                    return false;
                }
            };


            vm.left = 0;
            vm.swipeLeft = function() {
                var width = platformService.getWidth();
                var lwidth = 0;
                var breadcrumbs = angular.element(document.querySelector('.breadcrumbs'));
                /*var breadcrumbs = document.querySelector('.breadcrumbs:visible').first();*/
                if (angular.isDefined(breadcrumbs[0])) {
                    angular.forEach(breadcrumbs.find('ul').find('li'), function(elem, index) {
                        lwidth = lwidth + parseInt(angular.element(elem)[0].clientWidth);
                    });
                }

                while (vm.left > (parseInt(width) - parseInt(lwidth))) {
                    vm.left = vm.left - 50;
                }
                angular.forEach(angular.element(document.querySelector('.breadcrumbs')), function(elem, index) {
                    angular.element(elem).find('ul').css({
                        'margin-left': vm.left + 'px'
                    });
                });
            };

            vm.isSearchPage = function() {
                if (angular.isDefined(vm.isSearch) && vm.isSearch) {
                    return true;
                }
                return false;
            };

            vm.getSearchDescription = function() {
                var keyword = searchConfigManager.getProperty('keyword', 'category').capitalizeSentence();
                keyword = keyword ? keyword : 'Search';
                var location = searchConfigManager.getProperty('location', 'suburb', 'region', 'state').capitalizeSentence().capitalizeSuburb();
                location = location ? ' in ' + location : (keyword) ? '' : ' in Australia';

                var _createdTitle = keyword + location;

                if(_createdTitle == 'Search') {
                    return '';
                }

                return _createdTitle;
            };

            vm.swipeRight = function() {
                vm.left = 0;
                angular.forEach(angular.element(document.querySelector('.breadcrumbs')), function(elem, index) {
                    angular.element(elem).find('ul').css({
                        'margin-left': vm.left + 'px'
                    });
                });
            };

            $scope.$on('$destroy', function() {
                angular.element(window).off('resize', _resizeHandler);
            });

            var _resizeHandler = function() {
                vm.isMobile = (platformService.getWidth() < 991) ? true : false;
            };

            angular.element($window).on('resize', _resizeHandler);
        }
    }
})();
