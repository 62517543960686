(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name ModalLikeDislikeFactory
     * @description Factory service provide method for setMessage for comment or review.
     */
    .factory('ModalLikeDislikeFactory', ModalLikeDislikeFactory)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name modalLikeDislike
     * @description Modal for displaying like/dislike respond message.
     */
    .directive('modalLikeDislike', modalLikeDislike);

    function ModalLikeDislikeFactory() {
        var methods = {
            setMessage: setMessage,
        };

        function setMessage(_storedData) {
            this.message = '';

            if (angular.isUndefined(_storedData)) {
                _storedData = '';
            }

            this.message = _storedData;
        }

        return methods;
    }

  /** @ngInject */
    function modalLikeDislike() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
            scope: {
                message: '=',
            },
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
})();
