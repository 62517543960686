(function() {
    'use strict';

    angular
      .module('truelocal')


      /**
       * @memberof truelocal
       * @ngdoc controller
       * @name BSServiceController
       * @description Business Services controller contain logic of search for business by its name and location
       * option. BSServiceController prepare search results gathered from API, for the user's preferred view option, and
       * fill searchItems directive with proper data.
       *
       *  Services is accessible on the following URLs, depending of wanted result.
       *
       *
       */
      .controller('BSServiceController', BSServiceController);

  /** @ngInject */

    function BSServiceController($window, $log, serviceConfigManager, $cookies, $timeout, DTM, headerService, Seo, apiConfig, platformService, appService) {
        var myEl = angular.element( document.querySelector( 'body' ) );
        myEl.addClass('services-page-body');

    // set header
        headerService.showSearchForm = true;
        headerService.logoClass = '';
        headerService.dynamicShowHideSearchForm = false;
    // end set header

        var vm = this;

    // $("main-navbar, main-footer").hide();

        vm.pathArray = $window.location.pathname.split('/');

        window.truncateCounter = 0;

        vm.getViewOnPage = function() {
            return 'results';
            if($cookies.get('tl_srcp_vop')) {
                return $cookies.get('tl_srcp_vop');
            } else {
                return 'results';
            }
        };

        vm.apidata = {};
        vm.metadata = {};
        vm.tagsdata = {};
        vm.sponsoreddata = {};
        vm.classLoad = 'invisible';
        vm.notfound = false;
        vm.viewOnPage = vm.getViewOnPage();
        vm.noresults = false;
        vm.suggestionsList = [];
        vm.gridClass = 'service-results_type_grid-view';

        vm.showMarkerId = null;
        vm.backToListId = null;


        vm.changeToGrid = function() {
            vm.gridClass = 'service-results_type_grid-view';
            vm.viewOnPage = 'results';
            serviceConfigManager.setCurrentViewType('gridView');
            vm.backToListId = null;
            $cookies.put('tl_srcp_vop', 'results', {path: '/'});
            $cookies.put('tl_srcp_grid', 'service-results_type_grid-view', {path: '/'});
            angular.element($window).triggerHandler('force-toggle-sort');
            return vm;
        };

    // angular.element($window).resize(function(){
    //   $timeout(function(){
    //     if(angular.element($window).outerWidth() < 690) {
    //       if(angular.isDefined(vm.gridClass) && vm.gridClass) {
    //        return vm.changeToList();
    //       }
    //     }
    //     //angular.extend(vm.mapContainerDimensions, _mapContainerDimensions);
    //   },0);
    // });

        vm.isLoaded = function() {
            return vm.classLoad;
        };

        vm.tabsClass = function(_obj) {
            switch (_obj) {
            case 'list':
                if(vm.gridClass=='' && vm.viewOnPage == 'results') return 'active';
                break;
            case 'grid':
                if(vm.gridClass!='' && vm.viewOnPage == 'results') return 'active';
                break;
            case 'map':
                if(vm.viewOnPage == 'map') return 'active';
                break;
            }
        };

    // generate the url
        var arrayPath = vm.pathArray;
        var foundBusinessTag = false;
        var _arrayPath = [];

        angular.forEach(arrayPath, function(objUrls) {
            if (foundBusinessTag == true) {
                _arrayPath.push(objUrls);
            }
            if (objUrls == 'business') {
                foundBusinessTag = true;
            }
        });

        var seoUrl = _arrayPath.join('/');

        vm.getErrorClass = function() {
            if (vm.notfound == false) {
                return '';
            } else {
                return 'extra-visible';
            }
        };


        vm.noResults = function() {
            return vm.noresults;
        };

        var _bodyWindowEl, _viewToobarEl, _mainContainer, _scrolledFromTop, _scrollingUp, _breadCrumbs, _platformClassCover, _headerHeight, _findWrapper, _isChrome, _isSafari, _isIE;

        serviceConfigManager.initializeBaseParams(vm.apidata, vm.metadata, vm.sponsoreddata, 1, function() {
            Seo.pageTitle.set(vm.metadata.title);
            Seo.description.set(vm.metadata.description);

            apiConfig.setHeaderVersion(1);

            if (angular.isDefined(vm.apidata) &&
        angular.isDefined(vm.apidata.size) &&
        vm.apidata.size == 0) {
                vm.noresults = true;

                if (angular.isDefined(vm.apidata) &&
          angular.isDefined(vm.apidata.spellCheckSuggestions) &&
          angular.isDefined(vm.apidata.spellCheckSuggestions.length) &&
          vm.apidata.spellCheckSuggestions.length) {
                    for (var iSugg = 0, lSugg = vm.apidata.spellCheckSuggestions.length; iSugg < lSugg; iSugg++) {
                        if (angular.isDefined(vm.apidata.spellCheckSuggestions[iSugg]) &&
              angular.isDefined(vm.apidata.spellCheckSuggestions[iSugg].type) &&
              vm.apidata.spellCheckSuggestions[iSugg].type == 'BUSINESS_NAME' &&
              angular.isDefined(vm.apidata.spellCheckSuggestions[iSugg].suggestions) &&
              angular.isDefined(vm.apidata.spellCheckSuggestions[iSugg].suggestions.length) &&
              vm.apidata.spellCheckSuggestions[iSugg].suggestions.length) {
                            for (var indSugg = 0, lenSugg = vm.apidata.spellCheckSuggestions[iSugg].suggestions.length; indSugg < lenSugg; indSugg++) {
                                var _suggestionItem = {};
                                if (angular.isDefined(vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg]) &&
                  angular.isDefined(vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg].keyword) &&
                  vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg].keyword) {
                                    _suggestionItem.name = vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg].keyword;
                                    _suggestionItem.url = '/search/' + vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg].keyword;
                                    vm.suggestionsList.push(_suggestionItem);
                                }
                            }
                        }
                    }
                }
            } else if((angular.isUndefined(vm.apidata) || vm.apidata == null) || (angular.isDefined(vm.apidata) && angular.isUndefined(vm.apidata.size))) {
                vm.notfound = false;
                vm.noresults = true;
        // angular.element('body').addClass('terminator-active');
                return;
            }

            if (angular.isDefined(vm.viewOnPage) &&
        vm.viewOnPage == 'premap') {
                vm.viewOnPage = 'map';
            }

            if (angular.isDefined(vm.metadata) &&
        angular.isDefined(vm.metadata.searchExpanded) &&
        vm.metadata.searchExpanded) {
                for (var iMess = 0, lMess = vm.metadata.messages.length; iMess < lMess; iMess++) {
                    if (vm.metadata.messages[iMess].toLowerCase().startsWith('by expanding')) {
                        vm.expandedSearch = true;
                        vm.expandedSearchMessage = vm.metadata.messages[iMess];
                    }
                }
            }

            $log.debug(vm.apidata);
            if (angular.isDefined(vm.gridClass) && vm.gridClass) {
                if (vm.gridClass == 'search-results_type_grid-view') {
                    serviceConfigManager.setCurrentViewType('gridView');
                }
            }

            if (vm.viewOnPage == 'map') {
                $timeout(function() {
                    angular.element(window).triggerHandler('refresh-map');
                }, 150);
            }

            angular.element($window).triggerHandler('update-search-fields');

            _isChrome = platformService.isChrome();
            _isSafari = platformService.isSafari();
            _isIE = platformService.isIE();
            _bodyWindowEl = (_isChrome || _isSafari) ? angular.element(document.querySelector('body,window')) : angular.element(document.querySelector('body,html'));
            _findWrapper = angular.element(document.querySelector('.findWrapper'));
            _viewToobarEl = angular.element(document.querySelector('.view-tools'));
            _mainContainer = angular.element(document.querySelector('#main'));
            _scrolledFromTop = 0;
            _scrollingUp = false;
            _breadCrumbs = (angular.element(document.querySelector('.breadcrumbsWrapper')).length == 2) ? 24 : 1;
            _platformClassCover = 'set-fixed ' + ((vm.platformType == 0) ? 'mobile' : (vm.platformType == 1) ? 'tablet' : '');
            _headerHeight = angular.element('header').outerHeight() + 'px';

      /**
       * @memberof BSController
       * @event onWindowScroll
       * @name onWindowScroll
       * @description Hide view option toolbar while scrolling.
       */
            if (vm.platformType == 2) {
                angular.element(window).on('scroll', _manageScrollUX);
            } else {
                _viewToobarEl.addClass(_platformClassCover).css('top', _headerHeight);
                _mainContainer.css('padding-top', '63px');
            }
            angular.element(window).on('show-on-map', vm.changeToMap);
            window.truncateCounter = 0;
        });
    
        appService.displayFooter();
    }

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }
})();
