/* global moment:false */
(function() {
    'use strict';

    angular
        .module('truelocal')
        .constant('searchArrowConstants', {
            'sections': [
                'sdsdsd',
                'sdasdasd',
                'asdasd',
            ],
        });
})();
