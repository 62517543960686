(function() {
    'use strict';

    angular
        .module('truelocal')

    /**
     * @memberof truelocal
     * @DTM factory
     * @name DTM
     * @description DTM
     * Using new DTM service for truelocl
     * using DTM service for TrueLocal
     * Author: Ali 23/7/16
     */
    .factory('DTM', DTM);

    /** @ngInject */
    function DTM(apiConfig, $window, $location, $http, $log, $rootScope, $timeout, $state, $cookies, $interval) {
        var service = {
                tracking: tracking,
                trackAnalyticOnClick: trackAnalyticOnClick,
                trackPage: trackPage,
                trackSearchEvents: trackSearchEvents,
                getTopLevelCategory: getTopLevelCategory,
                fireEventAtPageLoad: fireEventAtPageLoad,
                hasOneTrustScript: hasOneTrustScript,
            },
            TL = $window.TL = {},
            dtmPage = '',
            dtmEvent = '';
        var tlValue = '';
        var topLevelCategory = '';

        /**
         * @memberof DTMService
         * @method isDtmAvailable
         * @name isDtmAvailable
         * @return boolean
         * @description Checks if satellite library is available and DTM call can be made
         */
        function isDtmAvailable () {
            return (window._satellite && typeof window._satellite.track === 'function');
        }

        /**
         * @memberof DTMService
         * @method hasOptedInAnalytics
         * @name hasOptedInAnalytics
         * @return boolean
         * @description Checks if performance and targeting cookies are enabled
         */
        function hasOptedInAnalytics() {
            // targeting and performance cookies
            return window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes('C0002') && window.OnetrustActiveGroups.includes('C0004');
        }

        /**
         * @memberof DTMService
         * @method hasIntentionallyOptedOutOfAnalytics
         * @name hasIntentionallyOptedOutOfAnalytics
         * @return boolean
         * @description Checks if user has disabled analytics cookies through the cookie settings or simply closed the banner
         */
        function hasIntentionallyOptedOutOfAnalytics() {
            // there's a possibility that OnetrustActiveGroups is undefined for a few milliseconds so check if undefined first before deciding not to do re-try
            return window.OnetrustActiveGroups && $cookies.get('OptanonAlertBoxClosed') && !hasOptedInAnalytics();
        }

        /**
         * @memberof DTMService
         * @method hasOneTrustScript
         * @name hasOneTrustScript
         * @return boolean
         * @description Checks if there's OneTrust script injected
         */
        function hasOneTrustScript() {
            var hasOtScript = document.querySelectorAll('script[src=\'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js\']').length > 0;
            $log.debug('hasOneTrustScript: ', hasOtScript);
            return hasOtScript;
        }

        /**
         * @memberof DTMService
         * @method fireEventAtPageLoad
         * @name fireEventAtPageLoad
         * @description Fires the Adobe event at page load if analytics cookies are enabled. Otherwise, re-attempts will be made until a stop condition is met
         */
        function fireEventAtPageLoad(pageName) {
            var optedInAnalytics = hasOptedInAnalytics();
            var intentionallyOptedOutOfAnalytics = hasIntentionallyOptedOutOfAnalytics();
            var fired = false;
            var initialFireTime = new Date();

            console.log('I\'m about to fire event for ', pageName, ' at ', initialFireTime.toLocaleString(), 
                        ', launch available: ', isDtmAvailable(), ', active cookie groups: ', window.OnetrustActiveGroups);
          
            // fire only if user has opted in analytics and _satellite object is available (launch script has loaded)
            if (optedInAnalytics && isDtmAvailable()) {
                fired = trackPage();
            }

            if (fired) {
                console.log('Successfully fired event for ', pageName, ' page at ', new Date().toLocaleString());
            } else if (intentionallyOptedOutOfAnalytics) {
                console.log('Did not fire event since user closed banner without enabling analytics cookies');
            /*
                Attempt to re-fire every X second(s) until one of the conditions are met

                Retry will stop and Adobe event will be fired for the ff. cases:
                1. user is in opt-in consent model AND actively opts in by enabling both targeting and performances cookies AND launch script has finally loaded within the allowed time
                2. user is in opt-out consent model AND launch script has finally loaded

                Retry will stop but adobe event will NOT be fired for the ff. cases:
                1. user is in opt-in consent model AND has closed the banner
                2. user is in opt-in consent model AND actively enables either targeting or performance cookies only.
                3. user is in opt-in consent model AND ignores the banner for maximum of X minutes
            */
            } else {
                var refireInterval = 1000; // in milliseconds
                var maxMinForRetries = 1;
                var maxMsForRetries = maxMinForRetries * 60 * 1000;

                var refire = $interval(function() {
                    console.log('Re-try firing event for ', pageName, ' again at ', new Date().toLocaleString(),
                    ', launch available: ', isDtmAvailable(), ', active cookie groups: ', window.OnetrustActiveGroups);

                    if (hasOptedInAnalytics() && isDtmAvailable()) {
                        trackPage();
                        $interval.cancel(refire);
                    }

                    if (hasIntentionallyOptedOutOfAnalytics()) {
                        console.log('Stop re-trying firing of event for ', pageName, ' since user closed banner without enabling analytics cookies');
                        $interval.cancel(refire);
                    }

                    if (pageName !== $state.current.name) {
                        console.log('Stop re-trying firing of event for ', pageName, ' since user loaded another page: ', $state.current.name);
                        $interval.cancel(refire);
                    }

                    var timeDiff = new Date().getTime() - initialFireTime.getTime();
                    // console.log('diff between initial and retry time: ', timeDiff, ', maxMsForRetries: ', maxMsForRetries);

                    if (timeDiff >= maxMsForRetries) {
                        console.log('Stop re-trying firing of event for ', pageName, ' since max time for retries of ', maxMinForRetries, ' minute(s) has been reached');
                        $interval.cancel(refire);
                    }
                }, refireInterval);
            }
        }

        /**
         * @memberof DTMService
         * @method tracking
         * @name tracking
         * @description tracking
         */
        function tracking(description, DTMdata, eType) {
            if (isDtmAvailable()) {
                $log.debug('www-js DTM available - tracking');
                var trackingInfo = angular.isDefined(description) ? description.split(' % ') : null,
                    userId = '',
                    userEmail = '',
                    id = '',
                    dtmData = {},
                    userData = apiConfig.userData.data,
                    trackingInfoLength = 3;

                if (angular.isDefined(TL.metrics) && angular.isDefined(TL.metrics.url.page)) {
                    dtmData = TL.metrics;
                    if (angular.isDefined(userData) && dtmData.loggedin === 'anonymous') {
                        dtmData.loggedin = userData.data.id;
                    }

                    if (angular.isUndefined(description)) {
                        $log.debug('www-js DTM: tracking Description needed!');
                    } else if (trackingInfo.length !== trackingInfoLength) {
                        $log.debug('www-js DTM: Description should be 3 parts, ends with %, description, page, event');
                    } else {
                        dtmPage = trackingInfo[1];
                        dtmEvent = trackingInfo[2];
                    }

                    dtmData.linkname = dtmEvent;
                    var join = dtmEvent.split(':').join('_');
                    join = join.split(' ').join('_').toUpperCase();

                    dtmData.events = {
                        value: createEventName(dtmEvent, eType),
                        event: join,
                    };

                    /**
                     * After sorting on find and search TOL listing changes.
                     * So TOL postcode needs to be updated before click events to make sure it is the postcode of current TOL
                     */
                    if(['find', 'search', 'search-location'].indexOf(dtmPage) > -1){
                        var tols = getTolListingsFromAPIData(DTMdata);
                        var tol = tols[0];
                        dtmData.search.tolpost = (tol ? (((tol.listing || {}).addresses || {}).address || [])[0] : {}).postCode || '';
                    }

                    if (!isBDP()) delete dtmData.business;
                    TL.metrics = dtmData;
                    $log.debug('%c TL.metrics ', 'background: #161210; color: #ffffff', TL.metrics.events.event + '\n' + JSON.stringify(TL.metrics));
                    if (angular.isDefined(TL.metrics)) window._satellite.track(join);
                    return dtmData;
                }
            } else {
                $log.debug('www-js DTM unavailable - tracking');
            }
        };

        function callback(listMap) {
            var stringBuf = '';
            var searchResultList = listMap.map(function(obj) {
                if(obj._has('listing')) obj = obj.listing;
                stringBuf = obj.id + '~';
                if(obj._has('sensisProductId') && angular.isDefined(obj.sensisProductId)) {
                    stringBuf += obj.sensisProductId;
                }
                stringBuf = stringBuf + '~';
                if(obj._has('tlCustomerNumber') && angular.isDefined(obj.tlCustomerNumber)) {
                    stringBuf += obj.tlCustomerNumber;
                }
                return stringBuf;
            }).join('|');

            searchResultList = '{'+searchResultList+'}';
            return searchResultList;
        }

        /**
         * @memberof DTMService
         * @method initializeMetrics
         * @name initializeMetrics
         * @description Initializes the TL.metrics object
         * @param {string} userId the reviewer ID of the logged in user. Otherwise, value is "anonymous"
         * @param {string} pageName the name of the page
         * @param {Object} apidata the listing data from TLAPI
         * @returns {Object} the initialized TL.metrics object
         */
        function initializeMetrics(userId, pageName, apidata) {
            var metrics = {
                linkname: $location.absUrl(),
                loggedin: userId,
                url: {
                    page: pageName,
                },
            };

            switch(pageName) {
                case 'businessdetails':
                    if (apidata._has('id')) {
                        metrics.listing = getListingForMetrics(apidata);
                    }
                    var address = ((apidata.addresses || {}).address || [])[0] || {};
                    var primaryCategory = apidata.primaryCategory;
                    topLevelCategory = '';
                    if (primaryCategory && primaryCategory.vertical) {
                        topLevelCategory = primaryCategory.vertical.name;
                    }
                    metrics.search = {
                        state: address.state || '',
                        location: address.suburb || '',
                        heading: (primaryCategory || {}).name || '',
                        toppost: address.postCode || '',
                        tolpost: address.postCode || '',
                        sorttype: (angular.element(document.querySelector('#sort-toggle')).text().trim() &&
                                    angular.element(document.querySelector('#sort-toggle')).text().trim() !== '' &&
                                    ['Latest','Oldest','Rating Ascending','Rating Descending'].indexOf(angular.element(document.querySelector('#sort-toggle')).text().trim().toLowerCase()) > -1) ?
                                    angular.element(document.querySelector('#sort-toggle')).text().trim() : 'Latest'
                    };
                    metrics.reviewsno = (apidata.reviews || {}).size || 0;
                    if (apidata.paid) {
                        metrics.featured = {
                            product: apidata.primaryProduct || '',
                        };
                    }
                    break;

                case 'find':
                case 'search':
                case 'search-location':
                    var listings = getListingsFromAPIData(apidata);
                    var tols = getTolListingsFromAPIData(apidata);
                    var tol = tols[0];
                    var state = ($location.path().split('/')[3]) ? (($location.path().split('/')[3].toUpperCase() !== 'AUSTRALIA') ? $location.path().split('/')[3] : '') : '';
                    if(state.indexOf('-') > -1) {
                        state = state.split('-')[1];
                    }
                    var searchLocation = '';
                    if($location.path().split('/').length > 4) {
                        for(var i = 4; i < $location.path().split('/'). length; i++) {
                            searchLocation = (searchLocation !== '')? searchLocation+'/'+$location.path().split('/')[i] : $location.path().split('/')[i];
                        };
                    } else {
                        searchLocation = $location.path().split('/')[3] || '';
                    }
                    var heading = (pageName == 'find') ? (((apidata.breadcrumb && apidata.breadcrumb.category) ? apidata.breadcrumb.category.name : {}) || {}) || '' : (((apidata.breadcrumb && apidata.breadcrumb.search) ? apidata.breadcrumb.search.name : {}) || {}) || '';

                    metrics.search = {
                        pageno: $location.search().page || 1,
                        resultsno: listings.length,
                        sorttype: (angular.element(document.querySelector('#sort-toggle')).text().trim() &&
                                angular.element(document.querySelector('#sort-toggle')).text().trim() !== '' &&
                                ['best match','reviews','deals'].indexOf(angular.element(document.querySelector('#sort-toggle')).text().trim().toLowerCase()) > -1) ?
                                angular.element(document.querySelector('#sort-toggle')).text().trim() : 'best match',
                        heading: heading,
                        state: state,
                        location: searchLocation,
                        tolpost: (tol ? (((tol.listing || {}).addresses || {}).address || [])[0] : {}).postCode || '',
                    };
                    metrics.searchsubmit = {
                        location: $location.path().split('/')[3] || '',
                        term: $location.path().split('/')[2] || '',
                    };
                    metrics.listing = {
                        tolappearance: callback(tols),
                        allappearances: callback(listings),
                    };
                    if (getTolListingsFromAPIData(apidata).length) {
                        metrics.featured = {
                            inventoryid: tol.id || '',
                            product: (tol ? tol : {}).category || '',
                        };
                    }
                    break;

                default:
                    break;
            }

            return metrics;
        }

        /**
         * @memberof DTMService
         * @method trackPage
         * @name trackPage
         * @description trackPage for the atrributes on page load
         */
        function trackPage() {
            var fired = false;
            var pageName = '',
                userId = '',
                pageType = getLocationUC(),
                apidata = apiConfig.getListing() || {};

            if ('business' === pageType) {
                pageName = 'businessdetails';
            } else {
                pageName = pageType;
            }
            dtmPage = pageName;

            if (angular.isDefined($cookies.get('token'))) {
                if (apiConfig.userData._has('data.data.id')) {
                    userId = apiConfig.userData.data.data.id;
                }
            } else {
                userId = 'anonymous';
            }

            $log.debug('www-js DTM: User visited ' + pageName, ', isDtmAvailable = ', isDtmAvailable());

            if (isDtmAvailable()) {
                $log.debug('www-js DTM available - trackPage');
                TL.metrics = initializeMetrics(userId, pageName, apidata);

                switch (pageType) {
                    case 'business':
                        if (!apiConfig.getListing() && pageName == 'businessdetails') {
                            $log.debug('delaying trackPage in BDP due to unavailable listing data');
                            $timeout(trackPage, 2000);
                        }
                        $log.debug(apidata.name);
                        $log.debug('%c TL.metrics ', 'background: #161210; color: #ffffff', 'track(\'bdp_pageload\') \n' + JSON.stringify(TL.metrics));
                        window._satellite.track('bdp_pageload');

                        break;
                    case 'homepage':
                        $log.debug('%c TL.metrics ', 'background: #161210; color: #ffffff', 'track(\'home_pageload\') \n' + JSON.stringify(TL.metrics));
                        window._satellite.track('home_pageload');
                        break;

                    case 'find':
                    case 'search':
                    case 'search-location':
                        if (!apiConfig.getListing() && ['find', 'search', 'search-location'].indexOf(pageName) !== -1) {
                            $log.debug('delaying trackPage in SRP due to unavailable listing data');
                            $timeout(trackPage);
                        }
                        $log.debug('%c TL.metrics ', 'background: #161210; color: #ffffff', 'track(\'' + ((pageName == 'find') ? 'find_pageload' : (pageName == 'search' ? 'search_pageload' : 'search-location_pageload')) +'\') \n' + JSON.stringify(TL.metrics));
                        window._satellite.track(((pageName == 'find') ? 'find_pageload' : (pageName == 'search' ? 'search_pageload' : 'search-location_pageload')));
                        break;

                    case 'member':
                    case 'member-edit':
                    case 'member-settings':
                        $log.debug('%c TL.metrics ', 'background: #161210; color: #ffffff', 'track(\'' + ((pageName == 'member') ? 'profile_pageload' : (pageName == 'member-edit' ? 'profile-edit_pageload' : 'account-settings_pageload')) +'\') \n' + JSON.stringify(TL.metrics));
                        window._satellite.track(((pageName == 'member') ? 'profile_pageload' : (pageName == 'member-edit' ? 'profile-edit_pageload' : 'account-settings_pageload')));
                        break;
                    default:
                        $log.debug('%c TL.metrics \n', 'background: #161210; color: #ffffff', JSON.stringify(TL.metrics));
                        window._satellite.pageBottom();
                }

                fired = true;
            } else {
                $log.debug('www-js DTM unavailable - trackPage');
                TL.metrics = initializeMetrics(userId, pageName, apidata);
            }
            return fired;
        }

        /**
         * @memberof DTMService
         * @method getListingForMetrics
         * @name getListingForMetrics
         * @description retrieves the listing data used by TL.metrics
         * @param {Object} apidata  the listing data for tracking
         * @returns {Object} the listing data used by TL.metrics
         */
        function getListingForMetrics(apidata) {
            return {
                advertiserid: apidata.id,
                name: apidata.name,
                productcode: apidata.primaryProduct || '',
                productId: apidata._has('sensisProductId') === true ? apidata.sensisProductId : '',
                productVersion: apidata._has('sensisProductVersion') === true ? apidata.sensisProductVersion : '',
                publicationId: apidata._has('sensisPublicationId') === true ? apidata.sensisPublicationId : '',
                sensisCustomerNumber: apidata._has('sensisCustomerNumber') === true ? apidata.sensisCustomerNumber : '',
                tlCustomerNumber: apidata._has('tlCustomerNumber') === true ? apidata.tlCustomerNumber : '',
                tlListingId: apidata.id,
                tlHeading: apidata._has('primaryCategory.name') === true ? apidata.primaryCategory.name : '',
                tlValue: angular.isDefined(tlValue) ? tlValue : '',
                tlPostCode: apidata._has('(apidata.addresses.address[0]') === true ? apidata.addresses.address[0].postCode : '',
                tlSuburb: apidata._has('(apidata.addresses.address[0]') === true ? apidata.addresses.address[0].suburb : '',
                tlRegion: apidata._has('apidata.regions[0].name') === true ? apidata.regions[0].name : '',

            };
        }

        /**
         * @memberof DTMService
         * @method listingNodeForTrack
         * @name listingNodeForTrack
         * @param apidata  the listing data for tracking
         * @description creates TL.metrics.listing node
         */
        function listingNodeForTrack(apidata) {
            if(apidata._has('id') && TL.metrics) {
                TL.metrics.listing = getListingForMetrics(apidata);
                return TL;
            }else{
                return false;
            }
        };

        /**
         * @memberof DTMService
         * @method trackAnalyticOnClick
         * @name trackAnalyticOnClick
         * @param value argument is optional
         * @description tracking click for DTM
         */
        function trackAnalyticOnClick(name, value) {
            var trackData = name + ' % ' + dtmPage + ' % ' + name;
            if(angular.isDefined(value)) {
                TL.metrics.listing['tlValue'] = value;
            }
            tracking(trackData, apiConfig.getListing(), 'click');
        }

        /**
         * @memberof DTMService
         * @method trackSearchEvents
         * @name trackSearchEvents
         * @description Triggers DTM tracking of click event
         * @param {String} name
         * @param {Object} listing
         */
        function trackSearchEvents(name, listing) {
             // removing the allappearance and tol appearance array.
            if (TL.metrics) {
              delete TL.metrics.listing;
            }

            if(listingNodeForTrack(listing) ) {
                if(listing.isTol) {
                    trackAnalyticOnClick('top:' + name);
                } else if (listing.isBol) {
                    trackAnalyticOnClick('bottom:' + name);
                } else {
                    if(name === 'reviews') {
                        name = 'read ' + name;
                    }
                    trackAnalyticOnClick(name);
                }
            }
        };

        /**
         * @memberof DTMService
         * @method createEventName
         * @name createEventName
         * @description createEventName for DTM
         */
        function createEventName(name, eType) {
            var join = 'tlo:' + dtmPage + '|' + name;
            return join;
        }

        /* $rootScope.$on('$stateChangeSuccess', function() {
          $timeout(trackPage, 2000);
        });*/


        function getLocationUC() {
            return $state.current.name;
        }

        function isBDP() {
            return 'BUSINESS' == getLocationUC().toUpperCase();
        }

        function getTolListingsFromAPIData(apidata) {
            if (!apidata || !apidata.inventories) return [];
            var tol;
            angular.forEach(apidata.inventories, function(inventory) {
                if (inventory.type === 'TOL') tol = inventory.inventory;
            });
            return tol || [];
        }

        function getListingsFromAPIData(apidata) {
            return apidata && apidata.listing || [];
        }

        function getTopLevelCategory() {
            return !topLevelCategory ? '' : topLevelCategory.trim();
        }
        
        return service;
    }
})();
