(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name searchTile
     * @description Directive display sponsored links as tiles that appear in row between results.
     *
     * @param {service}   $scope                    Angular scope service
     *
     * @example
     *
     * <search-tile tiles="[object]" index="[integer]"></search-tile>
     */
    .directive('searchTile', function() {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/app/components/searchpage/searchitems/searchtile.html',
            scope: {
                tiles: '=',
                index: '=',
            },
            controller: BsSearchTileController,
            controllerAs: 'vm',
            bindToController: true,
        };

        function BsSearchTileController($scope) {
            var vm = this;

            vm.tilesLimit = 6;
            vm.fewTiles = [];


        /**
         * @memberof searchTile
         * @method getFewTiles
         * @name getFewTiles
         * @description Returns random tiles array.
         * @returns {Array} tilesArr
         */
            vm.getFewTiles = function() {
                if (!vm.tiles) return [];
                if (vm.tilesLimit >= vm.tiles.length) {
                    return vm.tiles;
                } else {
                    var id = Math.round(Math.random() * vm.tiles.length);
                    var tilesArr = vm.tiles.slice(id, vm.tilesLimit);
                    id = 0;
                    while (tilesArr.length < vm.tilesLimit) {
                        tilesArr.push(vm.tiles[id]);
                        id++;
                    }
                    return tilesArr;
                }
            };


        /**
         * @memberof searchTile
         * @event onScopeChange
         * @name onScopeChange
         * @description Change tiles array.
         */
            $scope.$watch(
          'vm.tiles',
          function handleFooChange(newValue, oldValue) {
              vm.fewTiles[vm.index] = vm.getFewTiles();
          }
        );
        }
    });
})();
