(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name comAddress
     * @description Directive displays business address on search and find result items. Address will be displayed
     * in following format:
     *
     *          First line:  streetAddress (street and house number)
     *          Second line: suburb, state, post code
     *
     * Address is expected to take two lines. If address is to long, and doesn't fit in two lines, truncation will
     * be applied. When applying truncation next rules are followed:
     *
     *          on mobile truncation height will be 40px,
     *          on tablet/desktop  truncation height will be 50px and
     *          on map truncation height will be be 120 px.     *
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *
     * @example
     * <com-address addresses="[object]" grid-class="[string]" on-list="[boolean]" on-grid="[boolean]" ></com-address>
     *
     */
    .directive('comAddress', comAddress);

  /** @ngInject */
    function comAddress() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/comaddress/comaddress.html',
            scope: {
                addresses: '=',
                addressTruncate: '=',
                gridClass: '=',
                onMap: '=',
                onList: '=',
                onGrid: '=',
            },
            controller: ['platformService', ComAddressController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function ComAddressController(platformService) {
            var vm = this;

            vm.isMobile = (platformService.getWidth() > 768) ? false : true;

      /**
       * @memberof comAddress
       * @method getIfTruncated
       * @name getIfTruncated
       * @description Return true if addressTruncate is undefined.
       * @returns {Boolean} truncate
       */
            vm.getIfTruncated = function() {
                if (angular.isUndefined(vm.addressTruncate)) {
                    if(vm.onMap == true) {
                        if(platformService.getWidth()<768) {
                            return false;
                        } else {
                            var address = vm.getAddressLines(vm.addresses) + vm.getStreetAddress(vm.addresses) + vm.getSuburb() + vm.getState() + vm.getPostCode();
                            if (address.length > 200) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    } else {
                        return true;
                    }
                } else {
                    return vm.addressTruncate;
                }
            };

      /**
       * @memberof comAddress
       * @method getTruncationHeight
       * @name getTruncationHeight
       * @description Return truncation height depending of present view.
       * @param {Object} addresses
       * @returns {Number} height
       */
            vm.getTruncationHeight = function() {
                if (platformService.getWidth()<768) {
                    if(vm.onList == true) {
                        return 50;
                    } else {
                        return 25;
                    }
                } else if (vm.onMap == true) {
                    return 120;
                } else{
                    if(vm.onList == true) {
                        return 25;
                    } else if(vm.onGrid == true) {
                        return 50;
                    } else {
                        return 25;
                    }
                }
            };


      /**
       * @memberof comAddress
       * @method getStreetAddress
       * @name getStreetAddress
       * @description Get street address. Empty string if empty.
       * @param {Object} addresses
       * @returns {String}
       */
            vm.getStreetAddress = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }

                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }

                if (typeof vm.addresses == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0] == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0].streetName == 'undefined' && typeof vm.addresses.address[0].streetNumber == 'undefined') {
                    return '';
                } else if (vm.addresses.address[0].streetName == '' && vm.addresses.address[0].streetNumber == '') {
                    return '';
                } else {
                    var _comma = '';
                    if (vm.getAddressLines(vm.addresses) != '') {
                        _comma = ', ';
                    }
                    var result = ((typeof vm.addresses.address[0].streetNumber != 'undefined') ? vm.addresses.address[0].streetNumber : '') + ' ' + ((typeof vm.addresses.address[0].streetName != 'undefined') ? vm.addresses.address[0].streetName + ' ' + vm.addresses.address[0].streetType : '');
                    var firstLine = vm.getAddressLines(vm.addresses) + result;
                    if (vm.gridClass != '') {
                        if (firstLine.length < 120) return _comma + result;
                        else {
                            return _comma + result;
                        }
                    } else {
                        if(firstLine.length > 60) {
                            return _comma + result;
                        } else {
                            return _comma + result + ', ';
                        }
                    }
                }
            };

      /**
       * @memberof comAddress
       * @method getSurpresedClass
       * @name getSurpresedClass
       * @description Return a specific class if address is surpresed
       * @returns {Boolean} true/false
       */

            vm.getSurpresedClass = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }
                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }

                var firstLine = vm.getAddressLines(vm.addresses) + vm.getStreetAddress(addresses);
                if (firstLine.length > 60) {
                    return 'surpresed-tl-grid-view-address';
                } else return '';
            };


      /**
       * @memberof comAddress
       * @method getWholeBodyAddress
       * @name getWholeBodyAddress
       * @description get entire address
       * @returns {string} address
       */
            vm.getWholeBodyAddress = function() {
                var _class = 'short';
                var line = vm.getAddressLines()+vm.getStreetAddress();
                if(line.length>120) {
                    _class = _class + 'extra-long-first-line';
                }
                var _htmlStr = '';
                _htmlStr += vm.getAddressLines();
                _htmlStr += vm.getStreetAddress();
                if(line.length>0 && line.slice(-2)!=', ') _htmlStr += ', ';
                _htmlStr += '<span class="' + _class + '">';
                _htmlStr += (vm.getSuburb() + '' + vm.getState() + '' + vm.getPostCode());
                _htmlStr += '</span>';

                return _htmlStr;
            };

      /**
       * @memberof comAddress
       * @method shouldItTruncateAddress
       * @name shouldItTruncateAddress
       * @description get if the address should be truncated
       * @returns {boolean} true/false
       */

            vm.shouldItTruncateAddress = function() {
                if(vm.onMap == true) {
                    return false;
                } else {
                    if ((vm.getAddressLines() + vm.getStreetAddress()).length <= 24) {
                        if ((vm.getSuburb() + '' + vm.getState() + '' + vm.getPostCode()).length <= 24) {
                            return false;
                        }
                        return true;
                    }
                    return true;
                }
            };

      /**
       * @memberof comAddress
       * @method getAddressLines
       * @name getAddressLines
       * @description Get main address fields as string.
       * @param {Object} addresses
       * @returns {String} 'addressLine1, addressLine2'
       */
            vm.getAddressLines = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }

                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }

                if (typeof vm.addresses == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0] == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0].addressLine1 == 'undefined' && typeof vm.addresses.address[0].addressLine2 == 'undefined') {
                    return '';
                } else if (vm.addresses.address[0].addressLine1 == '' && vm.addresses.address[0].addressLine2 == '') {
                    return '';
                } else {
                    if (vm.addresses.address[0].addressLine1.length == 0 && vm.addresses.address[0].addressLine2.length == 0) {
                        return '';
                    } else if (vm.addresses.address[0].addressLine1.length > 0 && vm.addresses.address[0].addressLine2.length == 0) {
                        return vm.addresses.address[0].addressLine1;
                    } else if (vm.addresses.address[0].addressLine1.length == 0 && vm.addresses.address[0].addressLine2.length > 0) {
                        return vm.addresses.address[0].addressLine2;
                    } else {
                        return vm.addresses.address[0].addressLine1 + ' ' + vm.addresses.address[0].addressLine2;
                    }
                }
            };

      /**
       * @memberof comAddress
       * @method suppressedFirstLine
       * @name suppressedFirstLine
       * @description get specific class if first line of address is surpresed
       * @returns {string}
       */
            vm.suppressedFirstLine = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }

                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }

                if (typeof vm.addresses == 'undefined') {
                    return 'hidden';
                } else if (vm.getStreetAddress(vm.addresses) == '' && vm.getAddressLines(vm.addresses) == '') {
                    return 'hidden';
                } else {
                    return '';
                }
            };


      /**
       * @memberof comAddress
       * @method getSuburb
       * @name getSuburb
       * @description Get suburb as string.
       * @param {Object} addresses
       * @param {Boolean} nocoma weather to add comma at the end.
       * @returns {String} suburb
       */
            vm.getSuburb = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }

                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }

                if (typeof vm.addresses == 'undefined') {
                    return '';  // will return undefined
                } else if (typeof vm.addresses.address == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0] == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0].suburb == 'undefined') {
                    return '';
                } else {
                    return vm.addresses.address[0].suburb;
                }
            };


      /**
       * @memberof comAddress
       * @method getState
       * @name  getState
       * @description Get state as string.
       * @param {Object} addresses
       * @returns {String} state
       */
            vm.getState = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }

                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }

                if (typeof vm.addresses == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0] == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0].state == 'undefined') {
                    return '';
                } else if (vm.addresses.address[0].state.length == 0) {
                    return '';
                } else {
                    if (vm.getSuburb(vm.addresses) != '') {
                        return ', ' + vm.addresses.address[0].state;
                    } else {
                        return vm.addresses.address[0].state;
                    }
                }
            };


      /**
       * @memberof comAddress
       * @method getPostCode
       * @name getPostCode
       * @description Get postCode as string.
       * @param {Object} addresses
       * @returns {String} postCode
       */
            vm.getPostCode = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }

                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }

                if (typeof vm.addresses == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0] == 'undefined') {
                    return '';
                } else if (typeof vm.addresses.address[0].postCode == 'undefined') {
                    return '';
                } else if (vm.addresses.address[0].postCode.length == 0) {
                    return '';
                } else {
                    if (vm.getSuburb(vm.addresses) != '' || vm.getState(vm.addresses) != '') {
                        return ', ' + vm.addresses.address[0].postCode;
                    } else {
                        return vm.addresses.address[0].postCode;
                    }
                }
            };

      /**
       * @memberof comAddress
       * @method suppressedSecondLine
       * @name suppressedSecondLine
       * @description get specific class if second line of address is surpresed
       * @returns {string}
       */

            vm.suppressedSecondLine = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }

                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }

                if (typeof vm.addresses == 'undefined') {
                    return 'hidden';
                } else if (vm.getSuburb(vm.addresses) == '' || vm.getState(vm.addresses) == '' || vm.getPostCode(vm.addresses) == '') {
                    return 'hidden';
                } else {
                    return '';
                }
            };


      /**
       * @memberof comAddress
       * @method haveLocation
       * @name haveLocation
       * @description Return true if address, suburb, state and postal code are present, or false otherwise.
       * @param {Object} addresses
       * @returns {Boolean} have location
       */
            vm.haveLocation = function(addresses) {
                if (typeof addresses != 'undefined') {
                    vm.addresses = addresses;
                }

                if (typeof vm.addresses != 'undefined') {
                    if (typeof vm.addresses[0] != 'undefined') {
                        vm.addresses.address = vm.addresses;
                    }
                }


                if (typeof vm.addresses == 'undefined') {
                    return false;
                } else if (vm.suppressedSecondLine(vm.addresses) == 'hidden' && vm.suppressedFirstLine(vm.addresses) == 'hidden') {
                    return false;
                } else {
                    return true;
                }
            };


      /**
       * @memberof comAddress
       * @method getTruncateAddress
       * @name getTruncateAddress
       * @description Return true if addressTruncate is not undefined.
       * @param {Object} addresses
       * @returns {Boolean} truncate
       */
            vm.getTruncateAddress = function() {
                if (typeof vm.addressTruncate != 'undefined') return true;
                else return false;
            };


      /**
       * @memberof comAddress
       * @method getTruncateHeight
       * @name getTruncateHeight
       * @description Return truncation height (0 if undefined).
       * @param {Object} addresses
       * @returns {Number} height
       */
            vm.getTruncateHeight = function() {
                if (typeof vm.addressTruncate != 'undefined') return 0;
                else return vm.addressTruncate;
            };
        }
    }
})();
