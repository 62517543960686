(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReviewCommentAnswer
     * @description Directive enable user to answer on comment of review.
     *
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.@param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.
     * @param {service}   $window                   Angular window wrapper
     * @param {service}   $rootScope                Angular app root scope
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {factory}   DTM           TL tracking services
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     *
     *
     * @example
     * <bdp-review-comment-answer review-comments="[object]"></bdp-review-comment-answer>
     */
    .directive('bdpReviewCommentAnswer', bdpReviewCommentAnswer);

  /** @ngInject */
    function bdpReviewCommentAnswer() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreviewcommentanswer/bdpreviewcommentanswer.html',
            scope: {
                reviewComments: '=',
            },
            controller: ['$modal', 'modalFactory', 'authService', 'apiConfig', 'ModalLikeDislikeFactory', '$window', '$rootScope', 'constantsPlaceholders', 'DTM', 'windowScroll', BdpReviewCommentAnswerController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;


    /**
     * @memberof bdpReviewCommentAnswer
     * @name bdpReviewCommentAnswer_BdpReviewCommentAnswerController
     * @method bdpReviewCommentAnswer_BdpReviewCommentAnswerController
     * @description Directive controller
     *
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.@param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.
     * @param {service}   $window                   Angular window wrapper
     * @param {service}   $rootScope                Angular app root scope
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {factory}   DTM           TL tracking services
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     *
     *
     */

    /** @ngInject */
        function BdpReviewCommentAnswerController($modal, modalFactory, authService, apiConfig, ModalLikeDislikeFactory, $window, $rootScope, constantsPlaceholders, DTM, windowScroll) {
            var vm = this;
            this.commentItemOpened = null;

            this.getComments = function() {
                if (angular.isUndefined(this.reviewComments)) {
                    return [];
                }
                return this.reviewComments;
            };


      /**
       * @memberof bdpReviewCommentAnswer
       * @method hideIfMine
       * @name hideIfMine
       * @description Returns true if current comment is of the current user.
       * @params {Object} _commentItem
       * @returns {Boolean} isMine
       */
            this.hideIfMine = function(_commentItem) {
                if (angular.isUndefined(_commentItem) || angular.isUndefined(_commentItem.user)) {
                    return true;
                }
                if (apiConfig.checkIfMine(_commentItem.user.id)) {
                    return false;
                }
                return true;
            };


      /**
       * @memberof bdpReviewCommentAnswer
       * @method notReported
       * @name notReported
       * @description Check is comment already reported.
       * @params {Object} _commentItem
       * @returns {Boolean} notReported
       */
            this.notReported = function(_commentItem) {
                if (angular.isUndefined(_commentItem)) {
                    return false;
                }
                if (_commentItem.alreadyReported) {
                    return false;
                }
                return true;
            };


      /**
       * @memberof bdpReviewCommentAnswer
       * @method reportNow
       * @name reportNow
       * @description Call modal that enable report now option.
       * @params {Object} _commentItem
       */
            this.reportNow = function(_commentItem) {
                this.resetAllStates();

                DTM.trackAnalyticOnClick('report comment start');

                if (angular.isUndefined(_commentItem)) {
                    return false;
                }

                var _modalInstance;
                if (_commentItem.reported) {
                    _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                        controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                        resolve: {
                            storedData: function() {
                                return constantsPlaceholders.bdpReviewCommentAnswer.alreadyReportedText;
                            },
                        },
                    });

                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });

                    return false;
                } else if (apiConfig.userIsAuthenticated()) {
                    angular.extend(_commentItem, {expandedDropdownStateSmall: false, reportingReview: true});
                    this.focusBlock(_commentItem.id);
                } else {
                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                        controller: 'ModalAuthPromptController',
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                    });
    
                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });
                }
            };


      /**
       * @memberof bdpReviewCommentAnswer
       * @method isReportNow
       * @name isReportNow
       * @description Check does it current user reported comment.
       * @params {Object} _commentItem
       * @returns {Boolean} notReported
       */
            this.isReportNow = function(_commentItem) {
                if (angular.isUndefined(_commentItem)) {
                    return false;
                }
                if (_commentItem.reportingReview) {
                    return true;
                }
                return false;
            };


      /**
       * @memberof bdpReviewCommentAnswer
       * @method isExpanded
       * @name isExpanded
       * @description Returns dropdown state open if it is open or empty string otherwise.
       * @params {Object} _commentItem
       * @returns {String} isExpanded
       */
            this.isExpanded = function(_commentItem) {
                if (angular.isUndefined(_commentItem)) {
                    return '';
                }
                return (_commentItem.expandedDropdownStateSmall) ? 'open' : '';
            };


      /**
       * @memberof bdpReviewCommentAnswer
       * @method collapseDropdown
       * @name collapseDropdown
       * @description Collapse dropdown.
       * @params {Object} _commentItem
       */
            this.collapseDropdown = function(_commentItem) {
                if (angular.isUndefined(_commentItem)) {
                    return false;
                }

                if (_commentItem.expandedDropdownStateSmall) {
                    angular.extend(_commentItem, {expandedDropdownStateSmall: false});
                }

                return;
            };


      /**
       * @memberof bdpReviewCommentAnswer
       * @event onWindowScroll
       * @name onWindowScroll
       * @description Hide dropdown while scrolling.
       */
            angular.element($window).bind('scroll', function() {
                if (vm.commentItemOpened) {
                    vm.commentItemOpened.expandedDropdownStateSmall = false;
                    $rootScope.$apply();
                }
            });


      /**
       * @memberof bdpReviewCommentAnswer
       * @method expandDropdown
       * @name expandDropdown
       * @description Expand dropdown.
       * @params {Object} _commentItem
       */
            this.expandDropdown = function(_commentItem) {
                if (angular.isUndefined(_commentItem)) {
                    return false;
                }

                if (_commentItem.expandedDropdownStateSmall) {
                    vm.commentItemOpened = null;
                    angular.extend(_commentItem, {expandedDropdownStateSmall: false});
                    return;
                }
                vm.commentItemOpened = _commentItem;
                angular.extend(_commentItem, {expandedDropdownStateSmall: true});
            };


      /**
       * @memberof bdpReviewCommentAnswer
       * @method resetAllStates
       * @name resetAllStates
       * @description Reset all states to default values.
       */
            this.resetAllStates = function() {
                var _bussinessModel = apiConfig.getListing();

                if (angular.isDefined(_bussinessModel) && angular.isDefined(_bussinessModel.featureReview) && _bussinessModel.featureReview) {
                    angular.extend(_bussinessModel.featureReview,
                        {
                            expandedDropdownState: false,
                            startAddingComment: false,
                            reportingReview: false,
                        });

                    if (_bussinessModel.featureReview.comments && _bussinessModel.featureReview.comments.length) {
                        for (var featuredCommentsIndex = 0, featuredCommentsListSize = _bussinessModel.featureReview.comments.length; featuredCommentsIndex < featuredCommentsListSize; featuredCommentsIndex++) {
                            angular.extend(_bussinessModel.featureReview.comments[featuredCommentsIndex], {
                                reportingReview: false,
                                expandedDropdownStateSmall: false,
                            });
                        }
                    }
                }

                if (angular.isDefined(_bussinessModel) && angular.isDefined(_bussinessModel.bussinessDetails) && _bussinessModel.bussinessDetails.reviewList) {
                    for (var reviewsIndex = 0, reviewsListSize = _bussinessModel.bussinessDetails.reviewList.length; reviewsIndex < reviewsListSize; reviewsIndex++) {
                        angular.extend(_bussinessModel.bussinessDetails.reviewList[reviewsIndex],
                            {
                                expandedDropdownState: false,
                                startAddingComment: false,
                                reportingReview: false,
                            });

                        if (_bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments && _bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments.length) {
                            for (var revCommentsIndex = 0, revCommentsListSize = _bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments.length; revCommentsIndex < revCommentsListSize; revCommentsIndex++) {
                                angular.extend(_bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments[revCommentsIndex], {
                                    reportingReview: false,
                                    expandedDropdownStateSmall: false,
                                });
                            }
                        }
                    }
                }
            };
        }
    }
})();
