(function() {
    'use strict';

    angular
        .module('truelocal')

        /**
         * @memberof truelocal
         * @method mainRouter
         * @name mainRouter
         * @description Main SPA router.
         */
        .config(routerConfig);

    /** @ngInject */
    function routerConfig($stateProvider, $urlRouterProvider,
        $locationProvider, $urlMatcherFactoryProvider, env, $resourceProvider, findSearchCategories) {

        //TODO: Start - Remove after Angular Search and Find is released for all categories
        var useAngularFindSearch = true; //If category white list is removed, find|search|search-location states should work
        if(angular.isDefined(findSearchCategories.categoryList)){
            useAngularFindSearch = false;//If category white list is defined, find|search|search-location states should work based on categories in the white list
            var pageSplit = window.location.pathname.split('/');
            var pageType = pageSplit[1];
            var keywordOrCategory = pageSplit[2];
            if((['find', 'search'].indexOf(pageType) > -1 && findSearchCategories.categoryList.indexOf(keywordOrCategory) > -1)){
                useAngularFindSearch = true;
            }
        }
        //TODO: End - Remove after Angular Search and Find is released for all categories

  $urlMatcherFactoryProvider.strictMode(false);
        if (env.states.homepage === true) {
            $stateProvider.state('homepage', {
                url: '/',
                templateUrl: env.templateUrl + 'homepage.html',
                controller: 'HomepageController',
                controllerAs: 'vm',
                bindToController: true,
            });
        }

        if (env.states.business === true) {
            $stateProvider.state('business', {
                url: '/business/{path:.*}',
                templateUrl: env.templateUrl + 'bdp.html',
                controller: 'BdpController',
                controllerAs: 'main',
                bindToController: true,
                resolve: {
                    listing: ['serviceListing', '$stateParams', 'windowScroll', '$window', 'sharedService', function (serviceListing, $stateParams, windowScroll, $window, sharedService) {
                        // Map instance is destroyed, refetch map when page is refreshed on the route page.
                        if (!$window.google) {
                            sharedService.initializeGoogleMapApi();
                        } else {
                            sharedService.initMapSettings();
                        }
                        return serviceListing.getListing($stateParams.path);
                    }]
                }
            });
        }

        if (env.states.search === true && useAngularFindSearch) {
            $stateProvider.state('search', {
                url: '/search/{path:.*}',
                templateUrl: env.templateUrl + 'findsearch.html',
                controller: 'BFSController',
                controllerAs: 'main',
                bindToController: true,
                resolve: {
                    findSearchListing: ['serviceListing', '$stateParams', 'sharedService', '$window', function (serviceListing, $stateParams, sharedService, $window) {
                        var searchSplit = $stateParams.path.split('/');
                        var options = {
                            inventory:true,
                            keyword:searchSplit[0],
                            limit: 12,
                            offset: 0,
                            showCopyPoints: true,
                            type: 'keyword',
                        }

                        if (!$window.google) {
                            sharedService.initializeGoogleMapApi();
                        } else {
                            sharedService.initMapSettings();
                        }

                        if(searchSplit[1]){
                            options.location = searchSplit[1];
                        }
                        if(options.keyword==="" && angular.isUndefined(options.location)){
                          delete options.keyword;
                          options.location='australia';
                          options.type='location';
                        }
                        return serviceListing.listingSearch(undefined, null, options, 2);
                    }]
                }
            });
        }

        if (env.states.searchLocation === true && useAngularFindSearch) {
            $stateProvider.state('search-location', {
                url: '/search-location/{path:.*}',
                templateUrl: env.templateUrl + 'findsearch.html',
                controller: 'BFSController',
                controllerAs: 'main',
                bindToController: true,
                resolve: {
                    findSearchListing: ['serviceListing', '$stateParams', function (serviceListing, $stateParams) {
                        var options = {
                            inventory:true,
                            location: $stateParams.path,
                            limit: 12,
                            offset: 0,
                            showCopyPoints: true,
                            type: 'location',
                        }
                        return serviceListing.listingSearch(undefined, null, options, 2);
                    }]
                }
            });
        }

        if (env.states.find === true && useAngularFindSearch) {
            $stateProvider.state('find', {
                url: '/find/{path:.*}',
                templateUrl: env.templateUrl + 'findsearch.html',
                controller: 'BFSController',
                controllerAs: 'main',
                bindToController: true,
                resolve: {
                    findSearchListing: ['serviceListing', '$stateParams', 'sharedService', '$window', '$q', function (serviceListing, $stateParams, sharedService, $window, $q) {
                        // Map instance is destroyed, refetch map when page is refreshed on the route page.
                        if (!$window.google) {
                            sharedService.initializeGoogleMapApi();
                        } else {
                            sharedService.initMapSettings();
                        }

                        var findSplit = $stateParams.path.split('/');
                        var options = {
                            category:findSplit[0],
                            inventory:true,
                            limit: 12,
                            offset: 0,
                            showCopyPoints: true,
                            type: 'browse',
                        }
                        if(findSplit[1]){
                            options.state = findSplit[1];
                            options.location = findSplit[1];
                        }
                        if(findSplit[2]){
                            options.region = findSplit[2];
                            options.location = findSplit[2];
                        }
                        if(findSplit[3]){
                            options.suburb = findSplit[3];
                            options.location = findSplit[3];
                        }
                        if(options.category==="" && angular.isUndefined(options.state) && angular.isUndefined(options.region) && angular.isUndefined(options.suburb)){
                         delete options.category;
                         options.location='australia';
                         options.type='location';
                       }
                        return serviceListing.listingSearch(undefined, null, options, 2);
                    }]
                }
            });

            $stateProvider.state('find-default', {
                url: '/find',
                templateUrl: env.templateUrl + 'findsearch.html',
                controller: 'BFSController',
                controllerAs: 'main',
                bindToController: true,
                resolve: {
                  findSearchListing: ['serviceListing', function (serviceListing) {
                      var options = {
                          inventory:true,
                          location: 'australia',
                          limit: 12,
                          offset: 0,
                          showCopyPoints: true,
                          type: 'location',
                      }
                      return serviceListing.listingSearch(undefined, null, options, 2);
                  }]
                }
            });

            $stateProvider.state('search-default', {
                url: '/search',
                templateUrl: env.templateUrl + 'findsearch.html',
                controller: 'BFSController',
                controllerAs: 'main',
                bindToController: true,
                resolve: {
                  findSearchListing: ['serviceListing', function (serviceListing) {
                      var options = {
                          inventory:true,
                          location: 'australia',
                          limit: 12,
                          offset: 0,
                          showCopyPoints: true,
                          type: 'location',
                      }
                      return serviceListing.listingSearch(undefined, null, options, 2);
                  }]
                }
            });
        }

        if (env.states.memberProfile === true) {
            $stateProvider.state('member', {
                url: '/member/{path}?target',
                templateUrl: env.templateUrl + 'userprofile.html',
                controller: 'ProfileController'
            });
        }

        if (env.states.memberProfile === true) {
            $stateProvider.state('member-edit', {
                url: '/member/{path:.*}/edit',
                templateUrl: env.templateUrl + 'userprofile.html',
                controller: 'ProfileController'
            });
        }

        if (env.states.memberProfile === true) {
            $stateProvider.state('member-settings', {
                url: '/member/{path:.*}/settings?hashToken',
                templateUrl: env.templateUrl + 'userprofile.html',
                controller: 'ProfileController'
            });
        }

        if (env.states.service === true) {
            $stateProvider.state('service', {
                url: '/services/{path:.*}',
                templateUrl: env.templateUrl + 'service.html',
                controller: 'BSServiceController',
                controllerAs: 'main',
                bindToController: true,
            });
        }

        $stateProvider.state('tradies', {
            url: '/tradies/{path:.*}',
            templateUrl: env.templateUrl + 'tradie.html',
            controller: 'TRController',
            controllerAs: 'main',
            bindToController: true,
        });

        $stateProvider.state('browse', {
            url: '/business-type',
            templateUrl: env.templateUrl + 'browse.html',
            controller: 'BrowseController',
            controllerAs: 'main',
            bindToController: true,
        });

        $stateProvider.state('truelocal-advertising', {
            url: '/truelocal-advertising',
            templateUrl: env.templateUrl + 'advertising.html',
            controller: 'AdvertisingController',
            controllerAs: 'marketing',
            bindToController: true,
        });
        $stateProvider.state('truelocal-websites', {
            url: '/websites',
            templateUrl: env.templateUrl + 'websites.html',
            controller: 'WebsitesController',
            controllerAs: 'marketing',
            bindToController: true,
        });
        $stateProvider.state('dms-signup', {
           url: '/pocket-office/signup/:listingId',
           template: '<dms-signup-directive></dms-signup-directive>',
        }); 
        $urlRouterProvider.otherwise(function($injector, $location) {
            var path = window.location.href;
            var $state = $injector.get('$state');
            window.location.reload(); // refreshing none angular pages
            // alert( 'otherwise'+window.location.protocol + '//' + window.location.hostname+' path:'+path)
            if (env.states.homepage === true) {
                if ((window.location.protocol + '//' + window.location
                        .hostname + '/') == path || (window.location
                        .protocol + '//' + window.location.hostname +
                        '/#') == path) {
                    // alert( 'go to state home')
                    $state.go('homepage');
                }
            } else {
                window.location.href = path;
            }
        });

        $locationProvider.html5Mode(true);
    }
})();


/**
 * Other docs
 *
 *  @memberof docs
 *  @ngdoc overview
 *  @name  Add_new_pages
 *  @description The following text is how to add new pages, new componenents
 *
 *  1. Add new page:
 *      * write into index.route.js the state for that new page
 *      * create the template into the path used for the state
 *      * create the controller anywhere in `src/app` - we use `src/app/main`
 *
 *  2. Add new component (directive):
 *      * create a folder into `src/app/components` with directive name
 *      * inside create a js file with directive and also create a template file
 *      * then you just inject in html files where you need: `<bdp-example val=""></bdp-example>`
 *
 *
 *
 *
 *
 *
 */
