(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpRatingStars
     * @description Directive display stars allowing user to rate business when adding review. User is allowed ro rate
     * by precision of half star.
     *
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {constant}  constantsClasses          Constant classes
     * @param {constant}  constantsSettings         Constant settings
     *
     * @example
     * <bdp-rating-stars checked-rating="[object]" parent-scope="[object]"></bdp-rating-stars>
     *
     */
    .directive('bdpRatingStars', bdpRatingStars);

  /** @ngInject */
    function bdpRatingStars() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/bdpratingstars/bdpratingstars.html',
            scope: {
                checkedRating: '=',
                parentScope: '=',
            },
            controller: ['apiConfig', 'platformService', 'constantsPlaceholders', 'constantsClasses', 'constantsSettings', '$noLocalStorage', '$localstorage', 'DTM', BdpStarsRatingController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;


    /**
     * @memberof bdpRatingStars
     * @name bdpRatingStars_BdpStarsRatingController
     * @method bdpRatingStars_BdpStarsRatingController
     * @description Directive controller
     *
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {constant}  constantsClasses          Constant classes
     * @param {constant}  constantsSettings         Constant settings
     *
     */

    /** @ngInject */
        function BdpStarsRatingController(apiConfig, platformService, constantsPlaceholders, constantsClasses, constantsSettings, $noLocalStorage, $localstorage, DTM) {
            this.ratingValue = 0;
            this.checkedRating = {value: 0};
            this.alreadyRated = false;
            this.rateLabelTitles = constantsPlaceholders.bdpRatingStars.rateLabelTitles;
            this.showRateLabel = constantsPlaceholders.bdpRatingStars.showRateLabel;
            this.showRateLabelMobile = constantsPlaceholders.bdpRatingStars.showRateLabelMobile;


      /**
       * @memberof bdpRatingStars
       * @name leavingRatingBox
       * @method leavingRatingBox
       * @description  On mouse leave event
       *
       */
            this.leavingRatingBox = function() {
                if (this.alreadyRated) {
                    this.ratingValue = this.checkedRating.value;
                    var _trueIndex = 0;
                    if (this.checkedRating.value % 1 !== 0) {
                        _trueIndex = parseInt(this.checkedRating.value - 0.5);
                    } else {
                        _trueIndex = parseInt(this.checkedRating.value - 1);
                    }
                    this.showRateLabel = this.rateLabelTitles[_trueIndex];
                } else {
                    this.showRateLabel = constantsPlaceholders.bdpRatingStars.showRateLabel;
                    this.ratingValue = 0;
                    this.checkedRating.value = 0;
                }
            };

            this.checkedRating.setRating = angular.bind(null, function(_dirRatingStar) {
        // _dirRatingStar.checkedRating.value = 0;
            }, this);

      /**
       * @memberof bdpRatingStars
       * @name updateValue
       * @method updateValue
       * @description  Event on mouse over a star to set if half or not and how many stars to be selected.
       *
       * @param event   {event}   the mouse event
       * @param _rating {integer} the rating value (1 to 5)
       */

            this.updateValue = function(event, _rating) {
                var isHalf = false;
                if (event !== null) {
                    var _eventTarget = event.target;
                    var _elementLeft = angular.element(_eventTarget).prop('offsetLeft') + angular.element(_eventTarget.offsetParent).prop('offsetLeft');
                    var _pageXPos = (angular.isUndefined(event.pageX)) ? event.changedTouches[0].pageX : event.pageX;

                    if (_pageXPos - _elementLeft < 15) {
                        isHalf = true;
                    }
                    if (this.parentScope.ratingNotValid) {
                        angular.extend(this.parentScope, {ratingNotValid: false});
                    }
                }
                if (event.type == 'mousemove') {
                    if (this.parentScope.ratingNotValid) {
                        angular.extend(this.parentScope, {ratingNotValid: false});
                    }

                    if (angular.isUndefined(_rating)) {
                        _rating = 0;
                    }
                } else if (event.type == 'click' || event.type == 'touchend') {
                    this.alreadyRated = true;

                    this.checkedRating.value = _rating - ((isHalf) ? 0.5 : 0);

                    var saveReviewRating = this.checkedRating.value;
                    $localstorage.setItem('save_review_rating', JSON.stringify(saveReviewRating));

                    DTM.trackAnalyticOnClick('star rating submit');
                }
                this.showRateLabel = this.rateLabelTitles[_rating - 1];
                this.ratingValue = _rating - ((isHalf) ? 0.5 : 0);
            };

      // Review rating comes from $localstorage if its available
            function lsTest() {
                var test = 'test';
                try {
                    $localstorage.setItem(test, test);
                    $localstorage.removeItem(test);
                    return true;
                } catch (e) {
                    return false;
                }
            }

            if (lsTest() === true) {
                var saveReviewObjectGet = $localstorage.getItem('save_review');

                if (saveReviewObjectGet) {
                    saveReviewObjectGet = JSON.parse(saveReviewObjectGet);
                    var currentPath = window.location.href;

                    if (currentPath == saveReviewObjectGet.path) {
                        var saveReviewRating = $localstorage.getItem('save_review_rating');
                        this.checkedRating.value = saveReviewRating;

                        this.showRateLabel = this.rateLabelTitles[saveReviewRating - 1];
                        this.ratingValue = saveReviewRating;
                    } else {
                        this.checkedRating.value = '';
                        $localstorage.removeItem('save_review_rating');
                    }
                } else {
                    this.checkedRating.value = '';
                }
            } else {
                this.checkedRating.value = '';
            }


      /**
       * @memberof bdpRatingStars
       * @name getCategoryVertical
       * @method getCategoryVertical
       * @description  Get the object with the Label acordin to the stars
       *
       */

            this.getCategoryVertical = function() {
                var _listing = apiConfig.getListing();
                if (angular.isUndefined(_listing) || angular.isUndefined(_listing.bussinessDetails)) {
                    return false;
                }

                if (constantsSettings.categoriesWithTicks.indexOf(_listing.bussinessDetails.categoryName) > -1) {
                    return true;
                } else {
                    return false;
                }
            };

      /**
       * @memberof bdpRatingStars
       * @name active
       * @method active
       * @description  Get the active rating
       *
       */

            this.active = function(_rating) {
                if (angular.isUndefined(_rating)) {
                    _rating = 0;
                }

                var isVertical = this.getCategoryVertical();

                if (_rating <= this.ratingValue) {
                    return (isVertical) ? constantsClasses.bdpRatingStars.activeIconCircle : constantsClasses.bdpRatingStars.activeIconStar;
                } else if (_rating - 0.5 == this.ratingValue) {
                    return (isVertical) ? constantsClasses.bdpRatingStars.activeIconCircleHalf : constantsClasses.bdpRatingStars.iconStarHalf;
                }
                return (isVertical) ? constantsClasses.bdpRatingStars.iconCircle : constantsClasses.bdpRatingStars.iconStar;
            };
        }
    }
})();
