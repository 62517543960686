(function () {
  "use strict";

  angular
    .module("truelocal")

    /**
     * @memberof truelocal
     * @ngdoc service
     * @name scriptLoaderService
     * @description scriptLoaderService provides method that load dynamic script creation
     */
    .service("scriptLoaderService", [
      "$document",
      function scriptLoaderService($document) {
        var service = {
          load: load,
        };

        /**
         * @name load
         * @param src script source url
         * @description create script tag and attached provided src
         */
        function load(src) {
          var script = $document[0].createElement("script");

          script.src = src;
          script.async = true;
          script.defer = true;
          $document[0].head.appendChild(script);
        }

        return service;
      },
    ]);
})();
