(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpSimilar
     * @description Directive displays "similar in the area items". At the bottom of the page as suggestion to user
     * in banner items are displayed four business that are similar in the area. Sponsored business are displayed with
     * special style. If screen goes tight on desktop, listing are displayed as carousel, and as column items on mobile.
     *
     *
     * Similar in the area services on the BDP enable user to see similar businesses (related to the current BDP
     * category..) This are rules for displaying:
     *
     *    Business must be in same category
     *    Business must have atleast one review
     *    Business must have 3.5 or greater star rating average
     *    Non managed business: Business image should be any image which can be used for background image size
     *    Managed business: Business image should be the selected feature image, (never a user generated image)
     *
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {constant}  constantsSettings         Constant settings
     * @param {factory}   DTM           TL tracking services
     * @param {constant}  env                       Environmental constants
     * @param {service}   $location                 Angular window.location service wrapper
     *
     * @example
     * <bdp-similar similar-listings="[object]"></bdp-similar>
     */
    .directive('bdpSimilar', bdpSimilar);

  /** @ngInject */
    function bdpSimilar() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpsimilar/bdpsimilar.html',
            scope: {
                similarListings: '=',
            },
            controller: ['platformService', 'constantsSettings', 'DTM', 'env', '$location', BdpSimilarController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpSimilarController(platformService, constantsSettings, DTM, env, $location) {
            var vm = this;

            vm.isMobile = platformService.isMobile();


      /**
       * @memberof bdpSimilar
       * @name listLimit
       * @description Limit of items to display defined in application constants settings.
       */
            vm.listLimit = constantsSettings.listLimits.bdpsimilar;

            vm.getHeightLimit = function() {
                if(platformService.getWidth()<768) {
                    return 94;
                } else {
                    return 50;
                }
            };
      /**
       * @memberof bdpSimilar
       * @method primaryCategory
       * @name primaryCategory
       * @description Returns similar item primary category.
       * @params {Object} categories
       * @returns {String} categoryName
       */
            this.primaryCategory = function(categories) {
                if (angular.isUndefined(categories)) return '';

                else if (angular.isUndefined(categories[0])) return '';
                else if (angular.isUndefined(categories[0].name)) return '';
                else return categories[0].name;
            };


      /**
       * @memberof bdpSimilar
       * @method getSuburb
       * @name getSuburb
       * @description Returns suburb from addresses.
       * @params {Object} addresses
       * @returns {String} suburb
       */
            this.getSuburb = function(addresses) {
                if (angular.isUndefined(addresses)) return '';
                else if (angular.isUndefined(addresses)) return '';
                else if (angular.isUndefined(addresses.address)) return '';
                else if (angular.isUndefined(addresses.address[0])) return '';
                else if (angular.isUndefined(addresses.address[0].suburb)) return '';
                else return addresses.address[0].suburb;
            };


      /**
       * @memberof bdpSimilar
       * @method titleClass
       * @name titleClass
       * @description Returns proper css size class based on number of letters in entered text.
       * @params {String} text
       * @returns {String} class
       */
            this.titleClass = function(text) {
                if (angular.isUndefined(text)) return '';
                if (text.length > 13 && text.length < 18) {
                    return 'small';
                } else if (text.length >= 18 && text.length < 25) {
                    return 'xsmall';
                } else if (text.length >= 25) {
                    return 'xxsmall';
                } else {
                    return '';
                }
            };


      /**
       * @memberof bdpSimilar
       * @method getUrl
       * @name getUrl
       * @description Returns url for item.
       * @params {Object} listing
       * @params {String} target
       * @returns {String} getUrl
       */
            this.getUrl = function(listing, target) {
                if (angular.isDefined(target)) {
                    return env.urlStructure + listing.seoUrl + '?target=' + target;
                } else {
                    return env.urlStructure + listing.seoUrl;
                }
            };


      /**
       * @memberof bdpSimilar
       * @method trackingSimilarBiz
       * @name trackingSimilarBiz
       * @description Tracks user interaction with similar business.
       * @params {Number} index
       * @params {Object} listing
       * @params {String} target
       * @returns {String} url
       */
            this.trackingSimilarBiz = function(index, listing, target) {
                DTM.trackAnalyticOnClick('similar in the area ' + (parseInt(index) + 1));
                if (angular.isDefined(target)) {
                    return $location.path(this.getUrl(listing)).search({target: target});
                } else {
                    return $location.path(this.getUrl(listing));
                }
            };

            this.page = 1;
            this.left = 0;


      /**
       * @memberof bdpSimilar
       * @method swipeLeft
       * @name swipeLeft
       * @description Swipe on nearby items event. Available if screen is bigger that 480px, and items doesn't
       * fit on screen.
       * @returns {Object} page
       */
            this.swipeLeft = function() {
                var width = platformService.getWidth();
                var lwidth = 0;
                if (width < 480) return false;
                angular.forEach(angular.element(document.querySelector('ul#similar-area')).find('li'), function(elem, index) {
                    lwidth = lwidth + parseInt(angular.element(elem)[0].clientWidth);
                });

                while (this.left > (parseInt(width) - parseInt(lwidth))) {
                    this.left = this.left - 1;
                }
        // $('#navigation-swiping').find('ul').css({});
                angular.element(document.querySelector('ul#similar-area')).css({
                    'margin-left': (this.left - 106) + 'px'
                });
                if (this.page == 1) {
                    this.page = 2;
                }
                return this.page;
            };


      /**
       * @memberof bdpSimilar
       * @method swipeRight
       * @name swipeRight
       * @description Swipe on nearby items event. Available if screen is bigger that 480px, and items doesn't
       * fit on screen.
       * @returns {Object} page
       */
            this.swipeRight = function() {
                var width = platformService.getWidth();
                if (width < 480) return false;
                this.left = 0;
                angular.element(document.querySelector('ul#similar-area')).css({
                    'margin-left': (this.left) + 'px'
                });
                if (this.page == 2) {
                    this.page = 1;
                }
                return this.page;
            };


      /**
       * @memberof bdpSimilar
       * @method hasImage
       * @name hasImage
       * @description Returns true if similar business have image.
       * @params {Object} listing
       * @returns {Boolean} hasImage
       */
            this.hasImage = function(listing) {
                //do not show image of other similar businesses
                return false;
            };

            this.pagingClass = function(page) {
                return (angular.isDefined(page) && this.page == page) ? 'active' : '';
            };


      /**
       * @memberof bdpSimilar
       * @method getElemClass
       * @name getElemClass
       * @description Returns similar item class after checking is it paid service and does it have image.
       * @params {Object} listing
       * @returns {String} class
       */
            this.getElemClass = function(listing) {
                var _class = '';
                if (angular.isDefined(listing)) {
                    if (angular.isDefined(listing.paid) && listing.paid == true) _class += 'sponsored-item';
                    //do not show image of other similar businesses
                    var listingNoImage = 'listing-no-image';
                    _class += _class != '' ? ' ' + listingNoImage : listingNoImage;
                }
                return _class;
            };
        }
    }
})();
