(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name searchResults
     * @description Directive holds search results items and display them according to chosen view option.
     *
     * @param {service}   $location                 Angular window.location service wrapper
     * @param {service}   $scope                    Angular scope service
     * @param {constant}  env                       Environmental constants
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {service}   $compile                  Angular compile service
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {service}   $q                        A service that helps you run functions asynchronously, and use their return values (or exceptions) when they are done processing.
     * @param {service}   $log                      Angular console log wrapper
     * @param {factory}   uriService                uriService provide uriEncode and uriDecode methods that are responsible to unify different uri
     *
     * @example
     * <search-results search-data="[object]" expanded-search="[object]" expanded-search-message="[string]" grid-class="[string]" show-marker-id="[string]" view-on-page="[string]" on-map="string"></search-results>
     */
    .directive('searchResults', searchResults);

  /** @ngInject */
    function searchResults() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/searchresults/searchresults.html',
            scope: {
                searchData: '=',
                gridClass: '=',
                viewOnPage: '@',
                onMap: '&',
                showMarkerId: '&',
                expandedSearch: '=',
                expandedSearchMessage: '=',
            },
            controller: BsSearchResultsController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BsSearchResultsController($location, $scope, env, platformService, $rootScope,
                                           uriService, $window, $modal, listingModels, DTM, apiConfig, $timeout, windowScroll, constantsIcons, businessService) {
            var vm = this;
            vm.emailBusinessIcon = constantsIcons.srpIcons.emailBusinessIcon;
            vm.visitWebsiteIcon = constantsIcons.srpIcons.visitWebsiteIcon;
            vm.callBusinessIcon = constantsIcons.srpIcons.callBusinessIcon;
            vm.callBusinessMobileIcon = constantsIcons.commonIcons.callBusinessIcon;

            vm.readMore = [];
            vm.platformService = platformService;

            var _baseurl = $location.protocol() + '://' + $location.host();
            var _pageY;

            if ($location.port() != '80' || $location.port() != '') {
                _baseurl = _baseurl + ':' + $location.port();
            }

            vm.updateListings = function() {
                vm.listings = addAdvertises(vm.searchData.listing);
            };
            $scope.$watch('vm.searchData.listing.length', vm.updateListings);
            $scope.$watch('vm.searchData.listing', vm.updateListings);

            vm.getExpandedStatus = function() {
                return vm.expandedSearch;
            };

            vm.getExpandedMessage = function() {
                return vm.expandedSearchMessage;
            };

            vm.readMoreText = 'read more description';

            apiConfig.getBacklink(window.location.pathname.replace('/find/', ''))
                .then(function(response) {
                    vm.backlink = response;
                })
                .catch(function(error) {
                    vm.backlink = undefined;
                });

            vm.isMobileScreenSize = function() {
                return platformService.isMobileScreen();
            };

            vm.getCallBusinessIcon = function() {
                return $window.outerWidth > 767 ? vm.callBusinessIcon : vm.callBusinessMobileIcon;
            };

            vm.getHavePrimaryWebsite = function(listing) {
                return businessService.getHavePrimaryWebsite(listing);
            };

            /**
             * @memberof searchResults
             * @method trackWebsiteClick
             * @name trackWebsiteClick
             * @description track Visit Website clicks
             */
            vm.trackWebsiteClick = function(listing) {
                var url = businessService.getPrimaryWebsite(listing);
                return businessService.trackWebsiteClick(url, 'SRP', listing);
            };

            /**
             * @memberof searchResults
             * @method emailABusiness
             * @name emailABusiness
             * @description Open the modal to send an e-mail to the business owner
             *
             */
            vm.emailABusiness = function($event, listing) {
                businessService.emailABusiness($event, 'SRP', listing);
            };

            /**
             * @memberof searchResults
             * @method hasContactEmail
             * @name hasContactEmail
             * @description Checks if the listing has email address
             *
             */
            vm.hasContactEmail = function(listing) {
                return businessService.hasContactEmail(listing);
            };

      /**
       * @memberof searchResults
       * @method getPhoneNumberFormated
       * @name getPhoneNumberFormated
       * @description Returns business phone number in user friendly view format.
       * @param {Object} listing
       * @param {Number} index
       * @returns {String} phoneNumberFormated
       */
            vm.getPhoneNumberFormated = function(listing, index) {
                var _phoneNumber = vm.getPhoneNumber(listing, index);
                return _formatPhone(_phoneNumber);
            };

      /**
       * @memberof searchResults
       * @method _formatPhone
       * @name _formatPhone
       * @description Format phone number in user friendly view format.
       * @param {String} _phoneNumber
       * @returns {String} phoneNumberFormated
       */
            var _formatPhone = function(_phoneNumber) {
                var _formattedPhoneNumber = '';
                if (typeof _phoneNumber == 'undefined' || !_phoneNumber) {
                    return '';
                }
                if (_phoneNumber.length == 10) {
                    if (_phoneNumber.startsWith('04') || _phoneNumber.startsWith('1300') ||
            _phoneNumber.startsWith('1800') || _phoneNumber.startsWith('1900')) {
                        _formattedPhoneNumber += _phoneNumber.substr(0, 4) + ' ' + _phoneNumber.substr(4, 3) + ' ' + _phoneNumber.substr(7, 3);
                    } else {
                        _formattedPhoneNumber += '(' + _phoneNumber.substr(0, 2) + ') ';
                        _formattedPhoneNumber += _phoneNumber.substr(2, 4) + ' ' + _phoneNumber.substr(6);
                    }
                } else if (_phoneNumber.length == 6) {
                    _formattedPhoneNumber += _phoneNumber.substr(0, 2) + ' ' + _phoneNumber.substr(2, 2) + ' ' + _phoneNumber.substr(4, 2);
                } else {
                    _formattedPhoneNumber = _phoneNumber;
                }

                return _formattedPhoneNumber;
            };

      /**
       * @memberof searchResults
       * @method getPhoneNumber
       * @name getPhoneNumber
       * @description Returns business phone number.
       * @param {Object} listing
       * @param {Number} index
       * @returns {String} phoneNumber
       */
            vm.getPhoneNumber = function(listing, index) {
                if (vm.getHavePhoneNumber(listing, index) == true) {
                    var _phoneNumber = null;
          // check first for mobile
                    var found = 0;
                    angular.forEach(listing.contacts.contact, function(contact) {
                        if (contact.type == 'mobile' && found == 0) {
                            found = 1;
                            _phoneNumber = contact.value;
                        }
                    });

                    if (_phoneNumber == null) {
                        found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (contact.type == 'phone' && found == 0) {
                                found = 1;
                                _phoneNumber = contact.value;
                            }
                        });
                    }

                    if (_phoneNumber == null) {
                        found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (contact.type == 'national' && found == 0) {
                                found = 1;
                                _phoneNumber = contact.value;
                            }
                        });
                    }

                    if (_phoneNumber == '0200000000') {
                        _phoneNumber = null;
                    }

                    return _phoneNumber;
                }
            };

      /**
       * @memberof searchResults
       * @method getHavePhoneNumber
       * @name getHavePhoneNumber
       * @description Returns true if business has phone number.
       * @param {Object} listing
       * @param {Number} index
       * @returns {Boolean} havePhoneNumber
       */
            vm.havePhoneNumber = [];
            vm.getHavePhoneNumber = function(listing, index) {
                if (typeof vm.havePhoneNumber[listing.id] == 'undefined') {
                    if (typeof listing == 'undefined' || typeof listing.contacts == 'undefined' || typeof listing.contacts.contact == 'undefined' || listing.contacts.contact.length == 0) {
                        vm.havePhoneNumber[listing.id] = false;
                    } else {
                        var found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (typeof contact.type != 'undefined') {
                                if (contact.type == 'phone' || contact.type == 'national' || contact.type == 'mobile') {
                                    if (contact.value != '0200000000') {
                                        found = 1;
                                    }
                                }
                            }
                        });
                        vm.havePhoneNumber[listing.id] = !found==0;
                    }
                }

                return vm.havePhoneNumber[listing.id];
            };

      /**
       * @memberof searchResults
       * @method getListingUrl
       * @name getListingUrl
       * @description Returns business url.
       * @param {Object} listing
       * @returns {String} url
       */
            vm.getListingUrl = function(listing) {
                return _baseurl + env.searchsettings.businessUrl + '/' + listing.seoUrl;
            };


      /**
       * @memberof searchResults
       * @method getServicing
       * @name getServicing
       * @description Returns searched category.
       * @param {Object} listing
       * @param {Number} index
       * @returns {String} getServicing
       */
            vm.getServicing = function(listing) {
                var serviceLocation = vm.getServiceLocation(listing);
                if (serviceLocation) {
                    return serviceLocation.suburb+', '+serviceLocation.state+', '+serviceLocation.postCode;
                }
            };

      /**
       * @memberof searchResults
       * @method getServiceLocation
       * @name getServiceLocation
       * @description Returns service location for a listing
       * @param {Object} listing
       * @returns {Object} serviceLocation or null
       */
            vm.getServiceLocation = function(listing) {
                var result = null;

                if (listing._has('serviceLocationFormated')) {
                    return listing.serviceLocationFormated;
                }

                if (listing._has('serviceLocations.serviceLocation[0]')) {
                    var _url = $location.url();

                    if (_url.indexOf('search')!= -1) {
                        var _params = _url.split('search');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            var listingCategory = listing.categories.category[0].name.toLowerCase();
                            if (uriService.uriDecode(_params[1]) == listingCategory) {
                                var serviceLocationSuburbListing;
                                var serviceLocationStateListing;
                                var searchLocation = _params[2].match(/^([a-zA-Z]+)-([a-zA-Z]+)$/);
                                var searchLocationSuburb;
                                var searchLocationState;
                                if (searchLocation!=null) {
                                    if (searchLocation._has('[1]')) {
                                        searchLocationSuburb=searchLocation[1];
                                        searchLocationState=searchLocation[2];
                                    } else {
                                        return result=null;
                                    }
                                } else {
                                    return result=null;
                                }
                                listing.serviceLocations.serviceLocation.forEach(function(serviceLocation) {
                                    serviceLocationSuburbListing = serviceLocation.suburb.toLowerCase().trim();
                                    serviceLocationStateListing = serviceLocation.state.toLowerCase().trim();
                                    if (serviceLocationSuburbListing == searchLocationSuburb && serviceLocationStateListing == searchLocationState) {
                                        result = serviceLocation;
                                    }
                                });
                            }
                        }
                    }

                    listing.serviceLocationFormated = result;
                }

                return result;
            };

      /**
       * @memberof searchResults
       * @name getServicingUrl
       * @description return category url
       * @param {object} listing
       * @param {int} index
       */
            vm.categoriesUrls = [];
            vm.getServicingUrl = function(listing, index) {
                var listingCategory;

                if (angular.isUndefined(vm.categoriesUrls[listing.id])) {
                    var _url = $location.url();
                    var _finalUrl;
                    var _location='';
                    var _params;

                    if (_url.indexOf('search')!= -1) {
                        _params = _url.split('search');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            if (angular.isUndefined(_params[2])) {
                                _location='australia';
                            }
                            listingCategory = vm.getServicing(listing, index).toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                var _encodedURLName = listingCategory.toLowerCase() + '';
                                _encodedURLName = _encodedURLName.replace(/[\&]/g, 'and');
                                _encodedURLName = _encodedURLName.replace(/[^a-zA-Z\-\_\,]/g, ' ');
                                _encodedURLName = _encodedURLName.replace(/[ ]/g, '-');
                                _finalUrl = _url.replace(_params[1], _encodedURLName);
                                if (_location!='') {
                                    _finalUrl = _finalUrl + '/' + _location;
                                }
                            } else {
                                _finalUrl = _url + ((_location!='') ? '/' + _location: '');
                            }
                        }
                    } else {
                        _params = _url.split('find');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            listingCategory = vm.getServicing(listing, index).toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                _finalUrl = _url.replace(_params[1], uriService.uriEncode(listingCategory));
                            } else {
                                _finalUrl = _url;
                            }
                        }
                    }
                    vm.categoriesUrls[listing.id] = _finalUrl;
                }

                return vm.categoriesUrls[listing.id];
            };

            vm.hasImage = {};
            vm.getHasImage = function(listing, index) {
                if (typeof vm.hasImage[listing.id] == 'undefined') {
                    if (typeof listing == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage.urls == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage.urls.small == 'undefined') vm.hasImage[listing.id] = false;
                    else vm.hasImage[listing.id] = true;
                }

                if (vm.hasImage[listing.id] == true) {
                    imageInServer(listing.featureImage.urls.small).then(function(test) {
                        vm.hasImage[listing.id] = test;
                    });
                }

                return vm.hasImage[listing.id];
            };

      /**
       * @memberof searchItems
       * @method getHasImage
       * @name getHasImage
       * @description Returns true if business has image.
       * @param {Object} listing
       * @returns {Boolean} hasImage
       */
            vm.checkImage = function(listing) {
                if (typeof listing == 'undefined') return;
                if (typeof vm.hasImage[listing.id] == 'undefined') {
                    if (typeof listing.featureImage == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage.urls == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage.urls.small == 'undefined') vm.hasImage[listing.id] = false;
                    else vm.hasImage[listing.id] = true;
                }
            };

      /**
       * @memberof searchResults
       * @method getImage
       * @name getImage
       * @description Returns style object with business image as background.
       * @param {Object} listing
       * @param {Number} index
       * @returns {Object} style
       */
            vm.getImage = function(listing) {
                if (vm.hasImage[listing.id] == true) {
                    return {
                        'background-image': 'url(' + listing.featureImage.urls.small + ')',
                    };
                }
                return {};
            };

            vm.getSeoImage = function(listing) {
                if (vm.hasImage[listing.id] == true) {
                    return listing.featureImage.urls.small;
                }
                return 'https://media.truelocal.com.au/docs/images/logo.png';
            };

      /**
       * @memberof searchResults
       * @name getCategory
       * @description Object containing category names.
       */
            vm.categoryNames = {};

        /**
         * @memberof searchResults
         * @method getTruncatedCategoryName
         * @name getTruncatedCategoryName
         * @description Returns searched category name truncated if necessary for the layout width.
         * @param {Object} listing
         * @returns {String} categoryName
         */
            vm.getTruncatedCategoryName = function(listing) {
                var categoryName = angular.copy(vm.getCategory(listing));
                var hasImage = vm.hasImage[listing.id];
                if(platformService.getWidth() < 768 && !platformService.isIPad()) {
                    if(!hasImage) {
                        if(listing.newListing && categoryName.length > 35) {
                            categoryName = categoryName.substring(0, 31) + '...';
                        } else if(categoryName.length > 41) {
                            categoryName = categoryName.substring(0, 37) + '...';
                        }
                    } else if(listing.newListing && categoryName.length > 20) {
                        categoryName = categoryName.substring(0, 16) + '...';
                    } else if(categoryName.length > 26) {
                        categoryName = categoryName.substring(0, 22) + '...';
                    }
                } else if(vm.gridClass != '') {
                    if(listing.newListing && categoryName.length > 33) {
                        categoryName = categoryName.substring(0, 29) + '...';
                    } else if( categoryName.length > 39) {
                        categoryName = categoryName.substring(0, 35) + '...';
                    }
                }
                return categoryName;
            };

        /**
       * @memberof searchResults
       * @method getCategory
       * @name getCategory
       * @description Returns searched category.
       * @param {Object} listing
       * @param {Number} index
       * @returns {String} categoryName
       */
            vm.getCategory = function(listing, index) {
                if (typeof vm.categoryNames[listing.id] != 'undefined') {
                    return vm.categoryNames[listing.id];
                } else if (typeof listing.categories == 'undefined') {
                    return '';
                } else if (typeof listing.categories.category == 'undefined') {
                    return '';
                } else if (typeof listing.categories.category[0] == 'undefined') {
                    return '';
                } else if (typeof listing.categories.category[0].name == 'undefined') {
                    return '';
                } else {
                    vm.categoryNames[listing.id] = listing.categories.category[0].name;
                    return vm.categoryNames[listing.id];
                }
            };


      /**
       * @memberof searchResults
       * @name getCategoryUrl
       * @description return category url
       * @param {object} listing
       * @param {int} index
       */
            vm.categoriesUrls = [];
            vm.getCategoryUrl = function(listing, index) {
                var listingCategory;

                if (angular.isUndefined(vm.categoriesUrls[listing.id])) {
                    var _url = $location.url();
                    var _finalUrl;
                    var _location='';
                    var _params;

                    if (_url.indexOf('search')!= -1) {
                        _params = _url.split('search');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            if (angular.isUndefined(_params[2])) {
                                _location='australia';
                            }
                            listingCategory = vm.getCategory(listing, index).toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                var _encodedURLName = listingCategory.toLowerCase() + '';
                                _encodedURLName = _encodedURLName.replace(/[\&]/g, 'and');
                                _encodedURLName = _encodedURLName.replace(/[^a-zA-Z\-\_\,]/g, ' ');
                                _encodedURLName = _encodedURLName.replace(/[ ]/g, '-');
                                _finalUrl = _url.replace(_params[1], _encodedURLName);
                                if (_location!='') {
                                    _finalUrl = _finalUrl + '/' + _location;
                                }
                            } else {
                                _finalUrl = _url + ((_location!='') ? '/' + _location: '');
                            }
                        }
                    } else {
                        _params = _url.split('find');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            listingCategory = vm.getCategory(listing, index).toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                _finalUrl = _url.replace(_params[1], uriService.uriEncode(listingCategory));
                            } else {
                                _finalUrl = _url;
                            }
                        }
                    }
                    vm.categoriesUrls[listing.id] = _finalUrl;
                }

                return vm.categoriesUrls[listing.id];
            };

            vm.businessTruncationHeight = 32;
            if (platformService.getWidth() < 768 || vm.gridClass != '' ) {
                vm.businessTruncationHeight = 50;
            }

            $scope.$watch('vm.gridClass', function(current, original) {
                if (platformService.getWidth() < 768 || vm.gridClass != '' ) {
                    vm.businessTruncationHeight = 50;
                } else {
                    vm.businessTruncationHeight = 32;
                }
            });

            angular.element($window).bind('orientationchange', function() {
                if (platformService.getWidth()<768 || vm.gridClass != '' ) {
                    vm.businessTruncationHeight = 50;
                } else {
                    vm.businessTruncationHeight = 32;
                }
            });

            vm.descriptionTruncationLimit = [];
            vm.getDescriptionTtruncationLimit = function(listing) {
                if (angular.isUndefined(vm.descriptionTruncationLimit[listing.id])) {
                    if (platformService.getWidth() <= 768) {
                        if (vm.hasImage[listing.id]) {
                            vm.descriptionTruncationLimit[listing.id] = 98;
                        } else {
                            vm.descriptionTruncationLimit[listing.id] = 80;
                        }
                    } else {
                        if (vm.hasImage[listing.id]) {
                            vm.descriptionTruncationLimit[listing.id] = 105;
                        } else {
                            vm.descriptionTruncationLimit[listing.id] = 105;
                        }
                    }
                }
                return vm.descriptionTruncationLimit[listing.id];
            };

            /**
             * @memberof searchResults
             * @method toggleReadMore
             * @name toggleReadMore
             * @description Sets the variable to toggle description
             */
            vm.toggleReadMore = function($event, index, readMore) {
                $event.preventDefault();
                if(readMore) {
                    _pageY = $event.pageY;
                } else {
                    $window.scrollTo(0, _pageY - 450);
                }
                vm.readMore[index] = !vm.readMore[index];
            };

            /**
             * @memberof searchResults
             * @method showReadMore
             * @name showReadMore
             * @returns {Boolean}
             * @description Check to display readmore button
             */
            vm.showReadMore = function(listing, hasImage) {
                if(!angular.isUndefined(listing) && !angular.isUndefined(listing.description)) {
                    var mobileBaseLength = 165;
                    var tabletHasImageLength = 120;
                    var tabletNoImageLength = 170;

                    var desktopHasImageLength = 236;
                    var desktopNoImageLength = 286;

                    var listingLength = listing.description.length;

                    // Tablet screen to small devices
                    if ($window.innerWidth <= 767 && hasImage) {
                        return listingLength > mobileBaseLength;
                    }

                    // Tablet devices to large devices
                    if ($window.innerWidth >= 768 && $window.innerWidth <= 991) {
                        if (hasImage) {
                            return listingLength >= tabletHasImageLength;
                        } else {
                            return listingLength >= tabletNoImageLength;
                        }
                    }

                    // Large screen to wider devices
                    if ($window.innerWidth >= 992) {
                        if (hasImage) {
                            return listingLength >= desktopHasImageLength;
                        } else {
                            return listingLength >= desktopNoImageLength;
                        }
                    }
                }
                return false;
            };

            vm.addressStatus = [];
            vm.hasAddress = function(listing) {
                if (angular.isUndefined(vm.addressStatus[listing.id])) {
                    if (angular.isUndefined(listing.addresses) || angular.isUndefined(listing.addresses.address) ||
              angular.isUndefined(listing.addresses.address[0]) || angular.isUndefined(listing.addresses.address[0].state) ||
              listing.addresses.address[0].state == '') {
                        vm.addressStatus[listing.id] = false;
                    } else {
                        vm.addressStatus[listing.id] = true;
                    }
                }
                return vm.addressStatus[listing.id];
            };


            vm.onMapSelect = function(id) {
                angular.element(window).trigger('show-on-map', id);
            };
            vm.haveCoords= [];
            vm.getHaveCoords = function(listing, index) {
                if (typeof vm.haveCoords[listing.id] == 'undefined') {
                    if (angular.isUndefined(listing.addresses) || angular.isUndefined(listing.addresses.address)
              || angular.isUndefined(listing.addresses.address[0]) || angular.isUndefined(listing.addresses.address[0].longitude)
              || angular.isUndefined(listing.addresses.address[0].latitude)) {
                        vm.haveCoords[listing.id] = false;
                    } else {
            // check if is full address
                        var address = listing.addresses.address[0];
                        if ((angular.isUndefined(address.streetName) || address.streetName.length==0)
                && ((angular.isUndefined(address.addressLine1) || address.addressLine1.length==0 || address.addressLine1.length>50) &&
                (angular.isUndefined(address.addressLine2) || address.addressLine2.length==0 || address.addressLine2.length>50))) {
                            vm.haveCoords[listing.id] = false;
                        }

                        if (angular.isUndefined(vm.haveCoords[listing.id])) {
                            var founded = false;
                            angular.forEach(vm.coords, function(coord) {
                                if (coord.latitude == listing.addresses.address[0].latitude && coord.logitude == listing.addresses.address[0].logitude) {
                                    founded = true;
                                }
                            });

                            if (founded) {
                                coords.push({
                                    latitude: listing.addresses.address[0].latitude,
                                    longitude: listing.addresses.address[0].logitude,
                                });
                                vm.haveCoords[listing.id] = false;
                            } else {
                                vm.haveCoords[listing.id] = true;
                            }
                        }
                    }
                }

                return vm.haveCoords[listing.id];
            };

            function addAdvertises(items) {
                if (!items) return;

                var END_OF_LIST_INDEX = 0;
                var CYCLE_LOOP = 13;
                var sponsoredItems = [];
                var relativeIndex;
                var tol = getInventory('TOL');
                var tiles = getInventory('TILES');

                var numberOfTiles = 0;

                if (tol) {
                    tol.isTol = true;
                    sponsoredItems.push(angular.copy(tol));
                    delete tol.isTol;
                }

                for (var i = 0; i < items.length; i++) {
                    sponsoredItems.push(items[i]);
                    relativeIndex = sponsoredItems.length % (CYCLE_LOOP + 1);

                    if (relativeIndex === 6 && tiles) {
                        sponsoredItems.push(angular.copy(tiles));
                        numberOfTiles++;
                    }

                    if (relativeIndex === END_OF_LIST_INDEX && tiles) {
                        sponsoredItems.push(angular.copy(tiles));
                        numberOfTiles++;
                    }
                }

                if (numberOfTiles === 0 && tiles) {
                    sponsoredItems.push(tiles);
                }

                if (tol) {
                    // tol.isBol = true;   //This is commented for now, as we do NOT have exclusive DTM event for Bottom Of List to track
                    tol.isTol = true;
                    sponsoredItems.push(angular.copy(tol));
                    // delete tol.isBol;
                    delete tol.isTol;
                }

                return sponsoredItems;
            }

            function getInventory(type) {
                if (!vm.searchData) return;

                var inventories = vm.searchData.inventories;
                if (!inventories || !inventories.length) return;

                var inventory;
                for (var i = 0; i < inventories.length; i++) {
                    if (inventories[i].type === type) {
                        inventory = inventories[i];
                        break;
                    }
                }

                if (!inventory) return;

                switch (type) {
                case 'TOL':
                    inventory = inventory.inventory
                && inventory.inventory[0] && inventory.inventory[0].listing;
                    if (inventory) {
                        inventory.sponsored = true;
                    }
                    break;
                }

                return inventory;
            }

            vm.openModalSendtoMobile = function(listing) {
                var data = listingModels.businessDetailHeader(listing);

                // To determine if send to mobile is used for tol/bol
                if(listing.isTol) {
                    data.isTol = listing.isTol;
                } else if (listing.isBol) {
                    data.isBol = listing.isBol;
                }

                $modal.open({
                    templateUrl: '/app/components/bdp/bdpsendtomobile/modalsendtomobile.html',
                    controller: 'MobileController',
                    controllerAs: 'vm',
                    size: 'dialog_size_sendtomobile',
                    resolve: {
                        storedData: function() {
                            return data;
                        },
                    },
                });
                vm.trackSearchEvents('send to mobile click', listing);
            };

            /**
              * @memberof searchResults
              * @method trackSearchEvents
              * @name trackSearchEvents
              * @description Triggers DTM tracking of click event
              * @param {String} name
              * @param {Object} listing
             */
            vm.trackSearchEvents = function(name, listing) {
                DTM.trackSearchEvents(name, listing);
            };

            /**
             * @memberof searchResults
             * @method saveClickedListingAndTrack
             * @name saveClickedListingAndTrack
             * @description Stores the name of listing which user clicks to go to BDP page.
             *              It is later used if user presses back to land on same listing
             * @param {String} name
             * @param {Object} listing
             */
            vm.saveClickedListingAndTrack = function(name, listing) {
                $rootScope.findSearchAnchor = '#list-item-' + listing.id;

                DTM.trackSearchEvents(name, listing);
            };

            vm.generalInfoAllowedColumn = function() {
              // Large screen to small devices
              if ($window.innerWidth <= 991) {
                return 1
              }
              // Large screen to wider devices
              if ($window.innerWidth >= 992) {
                return 2
              }
            };

            $scope.$watch(function() { return window.innerWidth; }, function(newVal) {
              if (newVal <= 992) {
                vm.listings = addAdvertises(vm.searchData.listing);
              } else {
                vm.listings = addAdvertises(vm.searchData.listing);
              }
            });

            var footer = document.querySelector('footer');
            footer.style.display = 'block';
        }
    }
})();
