(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReviewSorting
     * @description Directive enable sorting of review by review date and by rating.
     *
     * @param  {service}  $timeout                  Angular window.setTimeout service
     * @param  {factory}  serviceListings           Factory what handle the API requests for listing
     * @param {service}   $window                   Angular window wrapper
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $rootScope                Angular app root scope
     * @param {factory}   DTM           TL tracking services
     * @param {constant}  constantsPlaceholders     Constant placeholders
     *
     * @example
     * <bdp-review-sorting bussiness-reviews="[object]"></bdp-review-sorting>
     *
     */
    .directive('bdpReviewSorting', bdpReviewSorting);

  /** @ngInject */
    function bdpReviewSorting() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreviewsorting/bdpreviewsorting.html',
            scope: {
                bussinessReviews: '=',
            },
            controller: ['$timeout', 'serviceListing', '$window', '$log', '$rootScope', 'DTM', 'constantsPlaceholders', BdpReviewSortingController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpReviewSortingController($timeout, serviceListing, $window, $log, $rootScope, DTM, constantsPlaceholders) {
            var vm = this;
            this.constantsPlaceholder = constantsPlaceholders.bdpReviewSorting;
            this.sortingActiveSelection = this.constantsPlaceholder.defaultActiveLabel;
            this.sortingOptions = this.constantsPlaceholder.defaultSortingOptions;
            this.sortingBox = false;

            var sortingBoxTimeout;


      /**
       * @memberof bdpReviewSorting
       * @event onWindowScroll
       * @name onWindowScroll
       * @description Hide sort dropdown while sorting.
       */
            angular.element($window).bind('scroll', function() {
                if (vm.sortingBox == true) {
                    vm.sortingBox = false;
                    if (sortingBoxTimeout) {
                        $timeout.cancel(sortingBoxTimeout);
                    }
                    $rootScope.$apply();
                }
            });


      /**
       * @memberof bdpReviewSorting
       * @method mouseLeftSorting
       * @name mouseLeftSorting
       * @description Start sorting after mouse click on proper btn.
       */
            this.mouseLeftSorting = function() {
                if (this.sortingBox) {
                    sortingBoxTimeout = $timeout(function() {
                        this.toggleSortingBox();
                    }.bind(this));
                }
            };


      /**
       * @memberof bdpReviewSorting
       * @method mouseEnteredSorting
       * @name mouseEnteredSorting
       * @description Prevent sorting actions on mouse enter if sorting is in progress.
       */
            this.mouseEnteredSorting = function() {
                if (sortingBoxTimeout) {
                    $timeout.cancel(sortingBoxTimeout);
                }
            };


      /**
       * @memberof bdpReviewSorting
       * @method toggleSortingBox
       * @name toggleSortingBox
       * @description Change sortingBox value true/false to show/hide dropdown.
       */
            this.toggleSortingBox = function() {
                this.sortingBox = !this.sortingBox;
                if (sortingBoxTimeout) {
                    $timeout.cancel(sortingBoxTimeout);
                }
            };


      /**
       * @memberof bdpReviewSorting
       * @method sortingOptionsInactive
       * @name sortingOptionsInactive
       * @description Return sorting options.
       * @return {Array} _sortingOptsList
       */
            this.sortingOptionsInactive = function() {
                var _permList = [];
                for (var sortingOptionIndex = 0, numberOfSortingOptions = this.sortingOptions.length; sortingOptionIndex < numberOfSortingOptions; sortingOptionIndex++) {
                    if (!this.sortingOptions[sortingOptionIndex].active)
                        _permList.push(this.sortingOptions[sortingOptionIndex]);
                }
                return _permList;
            };


      /**
       * @memberof bdpReviewSorting
       * @method manageSort
       * @name manageSort
       * @description Menage sorting by chosen criteria.
       * @params {Array} _sortingOptsList
       */
            this.manageSort = function(_sortingOption) {
                if (angular.isUndefined(_sortingOption)) {
                    _sortingOption = this.constantsPlaceholder.defaultActiveSortingSetup;
                }

                for (var sortingOptionIndex = 0, numberOfSortingOptions = this.sortingOptions.length, _listElement; sortingOptionIndex < numberOfSortingOptions; sortingOptionIndex++) {
                    _listElement = this.sortingOptions[sortingOptionIndex];
                    if (_listElement.active) {
                        _listElement.active = false;
                    }

                    if (_listElement.title == _sortingOption.title) {
                        _listElement.active = true;
                        this.sortingActiveSelection = _listElement.title;
                    }
                }
                this.toggleSortingBox();

                var _queryObject = {},
                    _parameter = _sortingOption.type,
                    _valType = _sortingOption.sortby;

                _queryObject['order'] = _valType;
                _queryObject['sort'] = _parameter;

                if (angular.isUndefined(this.bussinessReviews)) {
                    this.bussinessReviews = {};
                }
                if (angular.isUndefined(this.bussinessReviews.id)) {
                    this.bussinessReviews.id = '';
                }

                serviceListing.getSortedReviews(this.bussinessReviews.id, _queryObject, this.bussinessReviews);

                DTM.trackAnalyticOnClick('review filter: ' + _sortingOption.title);
            };
        }
    }
})();
