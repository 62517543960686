(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('signedupBanner', signedupBanner);

    /** @ngInject */
    function signedupBanner() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/signedupbanner/signedupbanner.html',
            scope: {
                creationDate: '='
            },
            controller: signedupBannerController,
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        /** @ngInject */
        function signedupBannerController(apiConfig, $http, env, $cookies, $rootScope) {
          var vm = this;

          vm.resendEmail = false;
          vm.addBussLive = env.addBussLive;

          angular.element(window).on('user-authenticated', function () {
            vm.getUserName = function () {
              return apiConfig.userData.data.data.displayName;
            };
          });

          vm.closeSuccess = function() {
            $cookies.remove('justSignedUp');
            $rootScope.justSignedUp = false;
          }

          // Commented because this code will be used later

          // vm.resendEmail = function () {
          //   var email = {
          //     email: apiConfig.userData.data.data.email
          //   };
          //
          //   return $http.post(apiConfig.generate('auth', 'resend-activation'), email, apiConfig.getXAuthToken())
          //   .then(resendEmailSuccess)
          //   .catch(resendEmailFail);
          //
          //   function resendEmailSuccess(response) {
          //     alert("success");
          //
          //     vm.resendEmail = true;
          //   }
          //
          //   function resendEmailFail(response) {
          //     alert("fail");
          //
          //     vm.resendEmail = true;
          //   }
          // }

        }
    }

})();
