(function () {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc controller
         * @name BFSController
         * @description Business find and search controller contains logic of:
         *     a. search for business by its
         *      category and location. BFSController prepare results gathered from API, for the
         *      user's preferred view option, and fill searchItems directive with proper data.
         *      searchItems directive is responsible for displaying of find and search results.
         *      Finding of business by category offer user with smart refine options.
         *
         *      Finding by category is accessible on the following URLs:
         *
         *      only keyword url will look like:
         *          /find/<keyword>
         *
         *      in case of both params:
         *          /find/<keyword>/<location>
         *
         *
         *    b. search for business by its name and
         *      location option. BSController prepare search results gathered from API, for the user's
         *      preferred view option, and fill searchItems directive with proper data.
         *
         *      Search is accessible on the following URLs, depending of wanted result.
         *
         *      only keyword url will look like:
         *          /search/<keyword>
         *
         *      only location url will look like:
         *          /search-location/<location>
         *
         *      in case of both params:
         *          /search/<keyword>/<location>
         *
         */
        .controller('BFSController', BFSController);

    /** @ngInject */
    function BFSController($window, $log, apiConfig, searchConfigManager, platformService, $cookies,
                          $timeout, $stateParams, $location, $scope, headerService, Seo, $rootScope,
                           findSearchListing, DTM,searchPageSeo, $state,$document, appService) {
        // set header
        headerService.showSearchForm = true;
        headerService.logoClass = '';
        headerService.dynamicShowHideSearchForm = false;
        // end set header

        var vm = this;

        vm.pathArray = $window.location.pathname.split('/');

        window.truncateCounter = 0;

        if($window.scrollY !=0){
            $document.scrollTop(0);
        }
        // adding header back
        var header = angular.element(document.querySelector('body'));
        if (header.hasClass('services-page-body')) {
            header.removeClass('services-page-body');
        }

        /**
         * @memberof BFSController
         * @method getViewOnPage
         * @name getViewOnPage
         * @description Get chosen view option from cookie.
         * @returns {String}
         */
        vm.getViewOnPage = function () {
            if ($cookies.get('tl_srcp_vop')) {
                if ($cookies.get('tl_srcp_vop') == 'map') {
                    return 'premap';
                } else {
                    return $cookies.get('tl_srcp_vop');
                }
            } else {
                return 'results';
            }
        };

        /**
         * @memberof BFSController
         * @method getGridClass
         * @name getGridClass
         * @description If user choose grid view return grid view class as string.
         * @returns {String}
         */
        vm.getGridClass = function () {
            if ($cookies.get('tl_srcp_grdc')) {
                return $cookies.get('tl_srcp_grdc');
            } else {
                return '';
            }
        };

        vm.apidata = {};
        vm.metadata = {};
        vm.tagsdata = {};
        vm.sponsoreddata = {};
        vm.refinedata = {};
        vm.classLoad = 'invisible';
        vm.notfound = false;
        vm.viewOnPage = vm.getViewOnPage();
        vm.noresults = false;
        vm.suggestionsList = [];
        vm.gridClass = vm.getGridClass();
        vm.showMarkerId = null;
        vm.backToListId = null;
        vm.refineButtonClicked = false;
        vm.refineExpanded =
            (platformService.getWidth() < 768 && !platformService.isIPad()) ? false : $cookies.get(
                                                                                'tl_ref_exp') ? true
                                                                                : false;
        _wasExpanded =
            (platformService.getWidth() < 768 && !platformService.isIPad()) ? false : $cookies.get(
                                                                                'tl_ref_exp') ? true
                                                                                : false;

        /**
         * @memberof BFSController
         * @method changeToGrid
         * @name changeToGrid
         * @description Change current view to grid view.
         * @returns {Object} this
         */
        vm.changeToGrid = function () {
            $document.scrollTop(0, 500);
            angular.element($window).triggerHandler('close-missing-tooltip');

            if (vm.viewOnPage == 'map') {
                window.truncateCounter = 0;
            }

            if (window.truncateCounter > 0) {
                return false;
            } else if (vm.viewOnPage == 'results' && vm.gridClass
                                                     == 'search-results_type_grid-view') {
                $timeout.cancel(vm.isCurrentInProgress);
                vm.isCurrentInProgress = null;
                window.truncateCounter = 0;
                return false;
            }
            window.truncateCounter = 1;
            window.scrollTo(0,0);

            $cookies.put('tl_srcp_vop', 'results', {path: '/'});
            $cookies.put('tl_srcp_grdc', 'search-results_type_grid-view', {path: '/'});
            vm.gridClass = 'search-results_type_grid-view';
            vm.viewOnPage = 'results';
            vm.isCurrentInProgress = $timeout(function () {
                _disableScrollingHandler();
                angular.element($window).triggerHandler('force-toggle-sort');

                searchConfigManager.setCurrentViewType('gridView');
                vm.backToListId = null;
                angular.element(window).triggerHandler('update-truncate');
            }).then(function () {
                window.scrollTo(0,0);
                $timeout.cancel(vm.isCurrentInProgress);
                vm.isCurrentInProgress = null;

                $timeout(function () {
                    _enableScrollingHandler();
                }).then(function () {
                    if (window.truncateCounter <= 1) {
                        window.truncateCounter = 0;
                    }
                });
            });
            DTM.trackAnalyticOnClick('tile view');
            return vm;
        };

        /**
         * @memberof BFSController
         * @method changeToList
         * @name changeToList
         * @description Change current view to list view.
         * @returns {Object} this
         */
        vm.changeToList = function () {
            $document.scrollTop(0, 500);
            angular.element($window).triggerHandler('close-missing-tooltip');

            if (vm.viewOnPage == 'map') {
                window.truncateCounter = 0;
            }

            if (window.truncateCounter > 0) {
                return false;
            } else if (vm.viewOnPage == 'results' && vm.gridClass == '') {
                $timeout.cancel(vm.isCurrentInProgress);
                vm.isCurrentInProgress = null;
                window.truncateCounter = 0;
                return false;
            }

            window.truncateCounter = 1;
            window.scrollTo(0,0);

            $cookies.put('tl_srcp_vop', 'results', {path: '/'});
            $cookies.put('tl_srcp_grdc', '', {path: '/'});
            vm.gridClass = '';
            vm.viewOnPage = 'results';

            vm.isCurrentInProgress = $timeout(function () {
                _disableScrollingHandler();
                angular.element($window).triggerHandler('force-toggle-sort');
                searchConfigManager.setCurrentViewType('listView');
                angular.element(window).triggerHandler('update-truncate');
            }).then(function () {
                if (angular.isDefined(vm.backToListId) && vm.backToListId) {
                    var _delay = 10;
                    if (window.truncateCounter > 1) {
                        _delay = window.truncateCounter;
                    }
                    $timeout(function () {
                        $document.scrollTop(angular.element(document.querySelector(vm.backToListId))[0].offsetTop - 30, 100).then(function(){
                          if (window.truncateCounter <= 1) {
                            window.truncateCounter = 0;
                          }
                        });
                        /*angular.element(document.querySelector('body,html'))[0].animate(
                            {scrollTop: angular.element(document.querySelector(vm.backToListId))[0].offsetTop - 30}, 'fast',
                            function () {
                                if (window.truncateCounter <= 1) {
                                    window.truncateCounter = 0;
                                }
                            });*/
                    }, _delay);
                } else {
                    if (window.truncateCounter <= 1) {
                        window.truncateCounter = 0;
                    }
                }
                $timeout.cancel(vm.isCurrentInProgress);
                vm.isCurrentInProgress = null;
                $timeout(function () {
                    _enableScrollingHandler();
                }).then(function () {
                    if (window.truncateCounter <= 1) {
                        window.truncateCounter = 0;
                    }
                });
            });
            DTM.trackAnalyticOnClick('list view');
            return vm;
        };

        /**
         * @memberof BFSController
         * @method changeToMap
         * @name changeToMap
         * @description Change current view to map view.
         * @returns {Object} this
         */
        vm.changeToMap = angular.bind(this, function (e, itemObj) {
            $document.scrollTop(0,500);
            var item;
            if(angular.isDefined(itemObj)){
                if(angular.isObject(itemObj)){
                    item = itemObj.item;
                } else {
                    item = itemObj;
                }
            }
            if (window.truncateCounter > 0) {
                return false;
            } else if (vm.viewOnPage == 'map') {
                $timeout.cancel(vm.isCurrentInProgress);
                vm.isCurrentInProgress = null;
                window.truncateCounter = 0;
                return false;
            }

            window.truncateCounter = 1;
            angular.element(window).scrollTop = 0;

            $cookies.put('tl_srcp_vop', 'map', {path: '/'});
            $cookies.put('tl_srcp_grdc', '', {path: '/'});

            vm.viewOnPage = 'map';
            vm.isCurrentInProgress = $timeout(function () {
                angular.element($window).triggerHandler('force-toggle-sort');

                if (angular.isUndefined(item)) {
                    vm.showMarkerId = null;
                    vm.backToListId = null;
                } else {
                    vm.showMarkerId = item;
                    vm.backToListId = '#list-item-' + item;
                }
            }, 250).then(function () {
                $timeout.cancel(vm.isCurrentInProgress);
                vm.isCurrentInProgress = null;
                $timeout(function () {
                    _enableScrollingHandler();
                }).then(function () {
                    if (window.truncateCounter <= 1) {
                        window.truncateCounter = 0;
                    }
                    angular.element(window)
                        .triggerHandler('refresh-map', [vm.showMarkerId, vm.backToListId]);
                });
            });
            if(angular.isDefined(item)){ //When user clicks on address to view listing on map
                if(itemObj.isTol){
                    DTM.trackAnalyticOnClick('top:view listing map');
                } else if(itemObj.isBol) {
                    DTM.trackAnalyticOnClick('bottom:view listing map');
                } else {
                    DTM.trackAnalyticOnClick('view listing map');
                }
            } else {
                DTM.trackAnalyticOnClick('map view'); //When user click in map view if find results
            };
            return vm;
        });

        var _unsetExpandHandler = function () {
            vm.refineExpanded = false;
            _wasExpanded = false;
            if(_platformType == 1){
                _viewToobarEl.addClass(_platformClassCover).css('top', angular.element(document.querySelector('header')).prop('offsetHeight') + _breadCrumbs+'px');
                _mainContainer.css('padding-top',
                                   _viewToobarEl.prop('offsetHeight'));
            }
            onCloseRefine();
        };

        angular.element(window).on('unset-expand', _unsetExpandHandler);

        var _wasExpanded = false;

        vm.expandRefine = function () {
            vm.refineButtonClicked = true;
            $document.scrollTop(0, 500);
            angular.element(window).triggerHandler('expand-refine');
            vm.refineExpanded = true;

            angular.element(window).triggerHandler('close-top-banner');

            onShowRefine();
            _mainContainer.removeAttr('style');

            angular.element(window).scrollTop = 0;

            if (platformService.getWidth() < 768 && !platformService.isIPad()) {
                angular.element(document.querySelector('.view-tools')).css('z-index', '450');
            }


            $cookies.put('tl_ref_exp', 'expanded', {path: '/'});
            _wasExpanded = true;

            if(_platformType !== 0){
                _viewToobarEl.removeClass('set-fixed mobile tablet').css('top', 'inherit');
            }

            $timeout(function(){
                if (!_findWrapper || (_findWrapper && !_findWrapper.length)) {
                    _findWrapper = angular.element(document.querySelector('.findWrapper'));
                }

                if (angular.isDefined(_findWrapper[0])) {
                    _sizeOfExpanded = _findWrapper[0].clientHeight + 'px';
                }
            });
            _enableScrollingHandler();
            setTimeout(function(){
                vm.refineButtonClicked = false;
            }, 500);
        };

        var _changeViewHandler = angular.bind(this, function () {
            this.changeToList();
        });

        /**
         * @memberof BFSController
         * @event onWindowChangeView
         * @name onWindowChangeView
         * @description On view change event do change to list.
         */
        angular.element(window).on('change-view', _changeViewHandler);

        angular.element(document.querySelector('#noLocationLink')).removeClass('active');

        /**
         * @memberof BFSController
         * @event onWindowResize
         * @name onWindowResize
         * @description On window resize if screen smaller than 690px, do change to list.
         */

        var _resizeHandler = function () {
            /*if (angular.element(window)[0].outerWidth == window.outerWidth) {
                return;
            } else {
                window.outerWidth = angular.element(window)[0].outerWidth;
            }*/

            $timeout(function () {
                _disableScrollingHandler();
                vm.platformType = getLookType();
                _breadCrumbs = (angular.element(document.querySelector('.breadcrumbsWrapper')).length == 2) ? 24 : 0;
                _headerHeight = angular.element(document.querySelector('header'))[0].clientHeight + 'px';
                _comBanner = angular.isDefined(angular.element(document.querySelector('.com-open-btn'))[0]) ?
                    angular.element(document.querySelector('.com-open-btn'))[0].clientHeight : 0;
                _viewToobarEl.removeClass('set-fixed mobile tablet').css('top', 'inherit');
                _mainContainer.css('padding-top', 'inherit');
                if (angular.element(window)[0].outerWidth < 690) {
                    if (angular.isDefined(vm.gridClass) && vm.gridClass) {
                        return vm.changeToList();
                    }
                }

                _enableScrollingHandler();
            }).then(function () {
                $timeout(function () {
                    if (!_findWrapper || (_findWrapper && !_findWrapper.length)) {
                        _findWrapper = angular.element(document.querySelector('.findWrapper'));
                    }

                    if (vm.refineExpanded && angular.isDefined(_findWrapper[0])) {
                        _sizeOfExpanded = _findWrapper[0].clientHeight + 'px';
                        _wasExpanded = true;
                    }

                    if (vm.platformType) {
                        angular.element(window).on('scroll', _manageScrollUX);
                    } else {
                        _viewToobarEl.addClass(_platformClassCover).css('top', _headerHeight);
                        //_mainContainer.css('padding-top', '63px');
                    }
                });
            });
        };

        angular.element(window).on('resize', _resizeHandler);

        /**
         * @memberof BFSController
         * @method isLoaded
         * @name isLoaded
         * @description Are API data loaded.
         * @returns {Boolean} isLoaded
         */
        vm.isLoaded = function () {
            return vm.classLoad;
        };

        /**
         * @memberof BFSController
         * @method tabsClass
         * @name tabsClass
         * @description Detect is entered class active.
         * @returns {String} tabsClass
         */
        vm.tabsClass = function (_obj) {
            switch (_obj) {
                case 'list':
                    if (vm.gridClass == '' && vm.viewOnPage == 'results') {
                        return 'active';
                    }
                    break;
                case 'grid':
                    if (vm.gridClass != '' && vm.viewOnPage == 'results') {
                        return 'active';
                    }
                    break;
                case 'map':
                    if (vm.viewOnPage == 'map') {
                        return 'active';
                    }
                    break;
            }
        };

        // generate the url
        var arrayPath = vm.pathArray;
        var foundBusinessTag = false;
        var _arrayPath = [];

        angular.forEach(arrayPath, function (objUrls) {
            if (foundBusinessTag == true) {
                _arrayPath.push(objUrls);
            }
            if (objUrls == 'business') {
                foundBusinessTag = true;
            }
        });

        var seoUrl = _arrayPath.join('/');

        /**
         * @memberof BFSController
         * @method getErrorClass
         * @name getErrorClass
         * @description Returns error class if error exist, empty string otherwise.
         * @returns {String} errorClass
         */
        vm.getErrorClass = function () {
            if (vm.notfound == false) {
                return '';
            } else {
                return 'extra-visible';
            }
        };

        var _disableScrollingHandler = function () {
            angular.element(window).off('scroll', _manageScrollUX);
        };

        var _enableScrollingHandler = function () {
            angular.element(window).on('scroll', _manageScrollUX);
        };

        angular.element(window).on('expand-refine', function () {
            if (platformService.getWidth() < 768) { // TODO: replace with full-screen toggle on body
                angular.element(document.body).css({overflow: 'hidden'});
            }
        });

        angular.element(window).on('close-refine', function () {
            angular.element(document.body).css({overflow: 'visible'});
        });

        $scope.$on('$destroy', function () {

            if (_mainContainer && _mainContainer.length) {
                _mainContainer.removeAttr('style');
            }

            window.scrollTo(0,0); //Making sure browser doesn't remember & use scroll level on next page
            angular.element(window).off('scroll', _manageScrollUX);
            angular.element(window).off('resize', _resizeHandler);
            angular.element(window).off('disable-scrolling', _disableScrollingHandler);
            angular.element(window).off('enable-scrolling', _enableScrollingHandler);
            angular.element(window).off('change-view', _changeViewHandler);
            angular.element(window).off('unset-expand', _unsetExpandHandler);
            angular.element(window).off('show-on-map', vm.changeToMap);
            angular.element(window).off('bfrefine-height-change', _recalculateToolbarHeight);
            angular.element(window).off('banner-change', _bannerChangeHandler);
            Seo.cleanCustomMeta('prerender-status-code');
            Seo.cleanCustomMeta('prerender-header');
        });

        /**
         * @memberof BFSController
         * @method noResults
         * @name noResults
         * @description Returns error class if error exist, empty string otherwise.
         * @returns {String} errorClass
         */
        vm.noResults = function () {
            return vm.noresults;
        };

        vm.notFoundOrEmpty = function () {
            return vm.notfound;
        };

        /**
         * @memberof BFSController
         * @method getLookType
         * @name getLookType
         * @description Returns 0 if screen < 767px; 1 if screen >=767px and <=991px and; 2 if
         *     screen > 991px.
         * @returns {Number} lookType
         */
        function getLookType() {
            var ua = navigator.userAgent;
            if (ua.match(/iPad/i)) {
                return 1;
            }

            if (platformService.getWidth() < 768 && !platformService.isIPad()) {
                return 0;
            } else if (platformService.isIPad() || (platformService.getWidth() >= 768
                                                    && platformService.getWidth() <= 991)) {
                return 1;
            }

            return 2;
        }

        this.platformType = getLookType();

        var _bodyWindowEl, _viewToobarEl, _mainContainer, _scrolledFromTop = 0, _scrollingUp,
            _platformType, _platformClassCover = 'set-fixed ' + ((vm.platformType == 0) ? 'mobile'
                    : (vm.platformType == 1) ? 'tablet' : ''),
            _findWrapper, wasExpanded = false, checkedPlatform, _isSafari, _isChrome, _isIE,
            _sizeOfExpanded, _breadCrumbs, _refineBtn, _manageScrollUX, _headerHeight, _compRunning, _comBanner;

        var _bannerChangeHandler = function (e, _isOpen) {
            if (_isOpen) {
                _comBanner = angular.element(document.querySelector('.com-banner'))[0].clientHeight;
            } else {
                _comBanner = 0;
            }
        };

        var _recalcualteTimeout;
        var _recalculateToolbarHeight = function () {
            if (_recalcualteTimeout) {
                clearTimeout(_recalcualteTimeout);
                _recalcualteTimeout = null;
            }

            _recalcualteTimeout = setTimeout(function () {
                _findWrapper = angular.element(document.querySelector('.findWrapper'));
                if (angular.isDefined(_findWrapper[0])) {
                    _sizeOfExpanded = _findWrapper[0].clientHeight + 'px';
                }
                var _refreshScroller = $window.scrollY + 1;
                if(['find', 'search', 'search-location'].indexOf(window.location.pathname.split('/')[1]) > -1 &&
                   $rootScope.findSearchAnchor && angular.element(document.querySelector($rootScope.findSearchAnchor))){
                    var elementOffset = (angular.element(document.querySelector($rootScope.findSearchAnchor)).prop('offsetTop')) ?
                                        angular.element(document.querySelector($rootScope.findSearchAnchor)).prop('offsetTop') - 150 : 0;
                    $document.scrollTop(elementOffset, 500);

                    $rootScope.findSearchAnchor = undefined;
                } else {
                    //$window.scrollY = _refreshScroller;
                }
            }, 500);
        };

        /**
         * @memberof BFSController
         * @name initializeParamsCallBack
         * @description Prepare view
         */
        var initializeParamsCallBack = function () {
            var pageTitle = (function () {
                var keyword = searchConfigManager.getProperty(
                        'keyword', 'category')
                                  .capitalizeSentence()
                              || 'results';
                var location = searchConfigManager.getProperty(
                        'location', 'suburb', 'region',
                        'state').capitalizeSentence()
                                   .capitalizeSuburb()
                               || 'Australia';

                return 'Browse ' + keyword + ' in '
                       + location + ' — True local';
            })();

            if(vm.metadata.title) {
              Seo.pageTitle.set(vm.metadata.title);
            } else {
              Seo.pageTitle.set(pageTitle);
            }
            Seo.description.set(vm.metadata.description);
            if (!vm.apidata.listing
                || !vm.apidata.listing.length) {
                Seo.customMeta('robots', 'noindex,follow',
                               'replace');
            }
            if($state.$current.name === 'search' || $state.$current.name === 'search-location'){
              if (searchPageSeo.meta) {
                  for (var metaName in searchPageSeo.meta) {
                      Seo.cleanCustomMeta(metaName);
                      Seo.customMeta(metaName, searchPageSeo.meta[metaName]);
                  }
              }
            }
            apiConfig.setHeaderVersion(2);
            if (angular.isDefined(vm.apidata) &&
                angular.isDefined(vm.apidata.size) &&
                vm.apidata.size == 0) {
                vm.noresults = true;

                if (angular.isDefined(vm.apidata) &&
                    angular.isDefined(
                        vm.apidata.spellCheckSuggestions)
                    &&
                    angular.isDefined(
                        vm.apidata.spellCheckSuggestions.length)
                    &&
                    vm.apidata.spellCheckSuggestions.length) {
                    for (var iSugg = 0, lSugg = vm.apidata.spellCheckSuggestions.length;
                         iSugg < lSugg; iSugg++) {
                        if (angular.isDefined(
                                vm.apidata.spellCheckSuggestions[iSugg])
                            &&
                            angular.isDefined(
                                vm.apidata.spellCheckSuggestions[iSugg].type)
                            &&
                            vm.apidata.spellCheckSuggestions[iSugg].type
                            == 'BUSINESS_NAME' &&
                            angular.isDefined(
                                vm.apidata.spellCheckSuggestions[iSugg].suggestions)
                            &&
                            angular.isDefined(
                                vm.apidata.spellCheckSuggestions[iSugg].suggestions.length)
                            &&
                            vm.apidata.spellCheckSuggestions[iSugg].suggestions.length) {
                            for (var indSugg = 0, lenSugg = vm.apidata.spellCheckSuggestions[iSugg].suggestions.length;
                                 indSugg < lenSugg;
                                 indSugg++) {
                                var _suggestionItem = {};
                                if (angular.isDefined(
                                        vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg])
                                    &&
                                    angular.isDefined(
                                        vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg].keyword)
                                    &&
                                    vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg].keyword) {
                                    _suggestionItem.name =
                                        vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg].keyword;
                                    _suggestionItem.url =
                                        '/search/'
                                        + vm.apidata.spellCheckSuggestions[iSugg].suggestions[indSugg].keyword;
                                    vm.suggestionsList.push(
                                        _suggestionItem);
                                }
                            }
                        }
                    }
                }
            } else if ((angular.isUndefined(vm.apidata)
                       || vm.apidata == null)
                       || (angular.isDefined(vm.apidata)
                           && angular.isUndefined(
                    vm.apidata.size))) {
                vm.notfound = false;
                vm.noresults = true;
                return;
            }

            if (angular.isDefined(vm.apidata)
                && angular.isDefined(
                    vm.apidata.breadcrumb)) {
                vm.apidata.breadcrumbs = {};
                angular.extend(vm.apidata.breadcrumbs,
                               vm.apidata.breadcrumb);
            }

            if (angular.isDefined(vm.apidata.facets) &&
                angular.isDefined(vm.apidata.facets.length)
                &&
                vm.apidata.facets.length) {
                vm.refinedata = vm.apidata.facets;

                var _haveFacetElements = false;
                for (var _facetIndex = 0, _facetSize = vm.refinedata.length;
                     _facetIndex < _facetSize;
                     _facetIndex++) {
                    _haveFacetElements = true;
                }

                if (!_haveFacetElements) {
                    vm.refinedata.length = 0;
                }
            }

            if (angular.isDefined(vm.viewOnPage) &&
                vm.viewOnPage == 'premap') {
                vm.viewOnPage = 'map';
            }

            if (angular.isDefined(vm.metadata) &&
                angular.isDefined(
                    vm.metadata.searchExpanded) &&
                vm.metadata.searchExpanded) {
                for (var iMess = 0, lMess = vm.metadata.messages.length;
                     iMess < lMess; iMess++) {
                    if (vm.metadata.messages[iMess].toLowerCase()
                            .startsWith('by expanding')) {
                        vm.expandedSearch = true;
                        vm.expandedSearchMessage =
                            vm.metadata.messages[iMess];
                    }
                }
            }

            $log.debug(vm.apidata);
            if (angular.isDefined(vm.gridClass)
                && vm.gridClass) {
                if (vm.gridClass
                    == 'search-results_type_grid-view') {
                    searchConfigManager.setCurrentViewType(
                        'gridView');
                }
            }

            _isChrome = platformService.isChrome();
            _isSafari = platformService.isSafari();
            _isIE = platformService.isIE();
            _bodyWindowEl =
                (_isChrome || _isSafari) ? angular.element(
                                             document.querySelector('body,window'))
                    : angular.element(document.querySelector('body,html'));
            _viewToobarEl = angular.element(document.querySelector('.view-tools'));
            _findWrapper =
                angular.element(document.querySelector(
                    '.findWrapper'));
            _mainContainer = angular.element(document.querySelector('#main'));
            _scrolledFromTop = 0;
            _scrollingUp = false;
            _platformType = vm.platformType;
            _platformClassCover =
                'set-fixed ' + ((vm.platformType == 0)
                    ? 'mobile' : (vm.platformType == 1)
                                    ? 'tablet' : '');
            _breadCrumbs =
                (angular.element(document.querySelector('.breadcrumbsWrapper')).length == 2)
                    ? 24 : 0;
            _refineBtn = angular.element(document.querySelector('#refine-btn'));
            _comBanner =
                angular.element(document.querySelector('.com-open-btn')).prop('offsetHeight');
            _comBanner =
                (_comBanner <= 100) ? 0 : _comBanner;
            _headerHeight =
                angular.element(document.querySelector('header')).prop('offsetHeight')
                + 'px';
            _compRunning =
                angular.element(document.querySelector('.com-banner')).length;

            /**
             * @memberof BSController
             * @method manageScrollUX
             * @name manageScrollUX
             * @description Hide view option toolbar while
             *     scrolling.
             */
            _manageScrollUX = function () {
                var pageSplit = window.location.pathname.split('/');
                var pageType = pageSplit[1];
                if(['find', 'search', 'search-location'].indexOf(pageType) >= 0){
                  _scrolledFromTop = $window.scrollY;
                  _breadCrumbs =
                      (angular.element(document.querySelector('.breadcrumbsWrapper')).length == 2)
                      ? 24 : 0;
                  var _expandedOffset = (_wasExpanded) ? parseInt(_sizeOfExpanded) : 3;
                  _expandedOffset += (_breadCrumbs + _comBanner);
                  if (_scrolledFromTop > _expandedOffset || _platformType == 0 ||
                      (_platformType == 1 && !_wasExpanded)) {
                    if(platformService.isIPad()&& !(_scrolledFromTop > _expandedOffset)){
                      _viewToobarEl.addClass(_platformClassCover).css('top', angular.element(document.querySelector('header')).prop('offsetHeight') + _breadCrumbs+'px');
                    }
                    else{
                      _viewToobarEl.addClass(_platformClassCover).css('top', _headerHeight);
                    }
                    onCloseRefine();
                  } else if (_scrolledFromTop <= _expandedOffset + 1) {
                    _viewToobarEl.removeClass(_platformClassCover).css('top', 'inherit');
                    if (_wasExpanded) {
                      onShowRefine();
                    } else {
                      onCloseRefine();
                    }
                    _mainContainer.removeAttr('style');
                  }

                  //On mobile - this prevents refine filter opening due to scroll first time after pageload
                  if (platformService.getWidth() < 768 && !platformService.isIPad() && !_wasExpanded){
                    angular.element(window).triggerHandler('unset-expand');
                  }

                  //Keep header fixed
                  angular.element(document.querySelector('body')).removeClass('sub-menu-fixed').addClass('header-show');
                }
            };

            angular.element(window).on('disable-scrolling', _disableScrollingHandler);
            angular.element(window).on('enable-scrolling', _enableScrollingHandler);

            /**
             * @memberof BSController
             * @event onWindowScroll
             * @name onWindowScroll
             * @description Hide view option toolbar while
             *     scrolling.
             */

            angular.element(document.querySelector('html')).removeClass('_menu-open modal-open');
            angular.element(document.querySelector('body')).removeClass('modal-open');

            if (vm.viewOnPage == 'map') {
                $timeout(function () {
                    angular.element(window)
                        .triggerHandler('refresh-map');
                }, 150);
            }

            $timeout(function () {
                if (!_findWrapper || (_findWrapper && !_findWrapper.length)) {
                    _findWrapper = angular.element(document.querySelector('.findWrapper'));
                }

                if (vm.refineExpanded && angular.isDefined(_findWrapper[0])) {
                    _sizeOfExpanded = _findWrapper[0].clientHeight + 'px';
                    _wasExpanded = true;
                }

                angular.element(window).on('scroll', _manageScrollUX);
                _recalculateToolbarHeight();
            });

            // Used in breadcrumbs
            vm.isSearch = $location.path().indexOf("/search/") > -1 || $location.path().indexOf("/search-location/") > -1;

            angular.element($window).triggerHandler('update-search-fields');

            angular.element(window).on('show-on-map', vm.changeToMap);
            angular.element(window).on('banner-change', _bannerChangeHandler);
            angular.element(window).on('bfrefine-height-change', _recalculateToolbarHeight);
            window.truncateCounter = 0;

            if (DTM.hasOneTrustScript()) {
                DTM.fireEventAtPageLoad($state.current.name);
            } else {
                DTM.trackPage();
            }
        };



        var bypassApiCall = false;
        if(findSearchListing){
            vm.apidata = findSearchListing.data;
            vm.metadata = findSearchListing.meta;

            if(vm.metadata) {
                Seo.pageTitle.set(vm.metadata.title);
                Seo.description.set(vm.metadata.description);
            }

            bypassApiCall = true;

            //Check if URL has an invalid page number
            var pageNumber = parseInt($location.search().page) || 1;
            if(pageNumber > Math.ceil(vm.apidata.size/vm.apidata.limit)){
                //Page number in url is higher than what actually exists so redirect to page 1
                bypassApiCall = false;
                $location.url($location.path());

            }
        }

        /**
         * @memberof BFSController
         * @name initializeBaseParams
         * @description Sets search configuration manager with parameters to prepare view.
         */
        searchConfigManager.initializeBaseParams(vm.apidata, vm.metadata, vm.sponsoreddata, 2, initializeParamsCallBack, bypassApiCall);


        function onShowRefine() {
          _refineBtn.css('display', 'none');
        }

        function onCloseRefine() {
          _refineBtn.css('display', 'block');
        }

	    // sticky view tools manager
	    angular.element(window).on('scroll', function() {
        var pageSplit = window.location.pathname.split('/');
        var pageType = pageSplit[1];
        if(['find', 'search', 'search-location'].indexOf(pageType) >= 0){
          var viewToolsEl     = angular.element(document.querySelector('.view-tools')),
              breadcrumbsEl   = angular.element(document.querySelector('.breadcrumbs')),
              headerHeight    = angular.element(document.querySelector('header'))[0].clientHeight,
              findWrapperEl   = angular.element(document.querySelector('.findWrapper')),
              findHeight      = angular.isDefined(findWrapperEl[0]) ? findWrapperEl[0].clientHeight : 0,
              isFindExpanded  = findHeight > 0,
              startStickyFrom = isFindExpanded? headerHeight + findHeight: headerHeight,
              documentTop     = $window.scrollY;
          if(angular.isUndefined(documentTop) || platformService.isIE()) documentTop = window.pageYOffset;
          if(documentTop > startStickyFrom) {
            viewToolsEl.addClass('sticky');
            breadcrumbsEl.addClass('sticky');
            breadcrumbsEl.css({'top': '72px'});
            // collapse findWrapper if expanded when view tools bar gets sticky
            if(isFindExpanded && !vm.refineButtonClicked) {
              angular.element(document.querySelector('.findWrapper .icon-remove-1')).triggerHandler('click');
            }
          } else {
            viewToolsEl.removeClass('sticky');
            breadcrumbsEl.removeClass('sticky');
          }
          if([0,1].indexOf(vm.platformType) > -1){ breadcrumbsEl.removeClass('sticky');}
        }
	    });

        appService.displayFooter();
    }

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }

})();
