(function() {
    'use strict';

    angular.module('truelocal')

    /**
     * @memberof truelocal
     * @bdpSendtomobile directive
     * @name bdpSendtomobile
     * @description Directive displays button in bdp header with option to send to mobile.
     *
     * @param {service}   $scope                    Angular scope service
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {service}   $window                   angular window service wrapper
     *
     */

    .directive('bdpSendtomobile', bdpSendtomobile);

    /** @ngInject */
    function bdpSendtomobile($window, platformService) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpsendtomobile/bdpsendtomobile.html',
            scope: {
                show: '=',
                details: '=',
                listing: '=',
            },
            controller: ['$scope', '$modal', 'apiConfig', 'modalFactory', '$window', 'DTM', bdpSendtomobileController],
            controllerAs: 'vm',
            bindToController: true,
        };
        return directive;
    }

    function bdpSendtomobileController($scope, $modal, apiConfig, modalFactory, $window, DTM) {
        var vm = this;
        vm.details = this.details;

      /**
       * @memberof bdpSendtomobileController
       * @method openModalSendtoMobile
       * @name openModalSendtoMobile
       * @description Send to mobile
       * @returns $modal.open
       */
        vm.openModalSendtoMobile = function() {
        // load modal
            $modal.open({
                templateUrl: '/app/components/bdp/bdpsendtomobile/modalsendtomobile.html',
                controller: 'MobileController',
                controllerAs: 'vm',
                size: 'dialog_size_sendtomobile',
                resolve: {
                    storedData: function() {
                        return vm.details;
                    },
                },
            });
        };

      /**
       * @memberof bdpSendtomobileController
       * @method userAuthenticated
       * @name userAuthenticated
       * @description Check if the user is authenticated
       * @returns {bool}
       */
        vm.userAuthenticated = function() {
            return apiConfig.userIsAuthenticated();
        };

      /**
       * @memberof MobileController
       * @method trackingSendToMobile
       * @name trackingSendToMobile
       * @description tracker for send to mobile: params retrieve event to catch
       *
       */

        vm.trackingSendToMobile = function(id) {
            DTM.trackAnalyticOnClick(id);
        };
    }
})();
