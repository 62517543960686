(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name externalScripts
     * @description Manages the importing and embedding of external scripts and snippets.
     *
     * @params {constant} externalScriptsConfig  Constant that holds all external scripts that should be embedded.
     *
     * @example
     * <external-scripts></external-scripts>
     */
    .directive('externalScripts', externalScripts);

    /** @ngInject */
    function externalScripts() {
        var directive = {
            restrict: 'E',
            template: '<div><!-- placeholder only --></div>',
            controller: ExternalScriptsController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function ExternalScriptsController($scope, scriptImporter, $timeout, externalScriptsConfig, apiConfig) {
            var vm = this;
            vm.scripts = externalScriptsConfig.slice(0);
            var nextToLoad;
            
            vm.unWatch = $scope.$watch('vm.scripts', function(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }
                var scriptToLoad = findScriptObjectToLoad();
                if (scriptToLoad) {
                    scriptImporter.importScript(scriptToLoad);
                }

                if (everythingHasBeenLoaded()) {
                    vm.unWatch();
                }
            }, true);

            angular.forEach(vm.scripts, function(script, index){
                $timeout(function(){
                    script.loaded = true;
                    nextToLoad = script.name;
                    // Process script here
                    // if(script.name === 'Some script')
                }, script.delay);
            });
            
            // Private Functions
            var everythingHasBeenLoaded = function() {
                return vm.scripts.filter(function(script) {
                    return script.loaded === true;
                }).length == vm.scripts.length;
            }
            
            var findScriptObjectToLoad = function() {
                return vm.scripts.find(function(script){
                     return script.name === nextToLoad;
                });
            }
        }
    }
})();
