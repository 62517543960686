(function() {
    'use strict';

    angular
    .module('truelocal')
    .factory('listingModels', listingModels);

    function listingModels(DataModel, constantsPlaceholders) {

        var userIdToVisit;

        function getBusinessDetailHeader(_apiData) {
      /** *
       *  returns:
       *  core model of
       *  Business Detail Page Header part
       **/

            var _dataModel = new DataModel({
                name: {
                    root: null,
                    format: 'name',
                    fields: 'name',
                    default: '-',
                },
                id: {
                    root: null,
                    format: 'id',
                    fields: 'id',
                    defautl: '-',
                },
                logo: {
                    root: 'logo',
                    format: null,
                    fields: null,
                    default: '-',
                },
                cover: {
                    root: 'coverImage.urls.cover',
                    format: null,
                    fields: null,
                    default: '/www-js/img/img01.jpg',
                },
                coverImage: {
                    root: 'coverImage',
                    format: null,
                    fields: null,
                    default: null,
                },
                canUpdateCoverImage: {
                    root: 'canUpdateCoverImage',
                    format: null,
                    fields: null,
                    default: false,
                },
                categoryVertical: {
                    root: 'categories.category[0].vertical',
                    format: null,
                    fields: null,
                    default: '',
                },
                description: {
                    root: null,
                    format: 'description',
                    fields: 'description',
                    default: '-',
                },
                slogan: {
                    root: null,
                    format: function(_d) {
                        var _val = _d['slogan'];
                        return _val;
                    },
                    fields: 'slogan',
                    default: '-',
                },
                brands: {
                    root: null,
                    format: 'brands',
                    fields: 'brands',
                    default: '-',
                },
                address: {
                    root: 'addresses.address[0]',
                    format: 'address, streetName, suburb, state, postCode',
                    fields: ['addressLine1|addressLine2 as address', 'streetName', 'suburb', 'state', 'postCode'],
                    default: '-',
                },
                addresses: {
                    root: null,
                    format: function(_d) {
                        return _d.addresses.address;
                    },
                    fields: 'addresses',
                    default: {},
                },
                phone: {
                    root: 'contacts.contact[0]',
                    format: 'phone',
                    fields: 'value as phone',
                    default: '-',
                },
                categoryName: {
                    root: 'primaryCategory',
                    format: 'name',
                    fields: 'name',
                    default: '-',
                },
                categoryId: {
                    root: 'primaryCategory',
                    format: 'id',
                    fields: 'id',
                    default: '',
                },
                reviews: {
                    root: 'statistics',
                    format: null,
                    fields: null,
                    default: '-',
                },
                pendingReviews: {
                    root: 'pendingReviews',
                    format: null,
                    fields: null,
                    default: false,
                },
                managed: {
                    root: 'managed',
                    format: null,
                    fields: null,
                    default: false,
                },
                ownerId: {
                    root: 'owners.owner[0].id',
                    format: null,
                    fields: null,
                    default: null,
                },
                newListing: {
                    root: 'newListing',
                    format: null,
                    fields: null,
                    default: false,
                },
                reviewRatings: {
                    root: 'statistics',
                    format: function(_ratingsObject) {
                        var _returnPercent = function(_numberOfVotes) {
                            if (!_numberOfVotes || isNaN(_numberOfVotes)) {
                                return 0;
                            }
                            var _totalPercent = (parseInt(_numberOfVotes) * 100) / parseInt(_ratingsObject.total);
                            return _totalPercent;
                        };

                        var _customFormat = {
                            one: {
                                votes: _ratingsObject.ratings[1],
                                percent: _returnPercent(_ratingsObject.ratings[1]),
                            },
                            two: {
                                votes: _ratingsObject.ratings[2],
                                percent: _returnPercent(_ratingsObject.ratings[2]),
                            },
                            tree: {
                                votes: _ratingsObject.ratings[3],
                                percent: _returnPercent(_ratingsObject.ratings[3]),
                            },
                            four: {
                                votes: _ratingsObject.ratings[4],
                                percent: _returnPercent(_ratingsObject.ratings[4]),
                            },
                            five: {
                                votes: _ratingsObject.ratings[5],
                                percent: _returnPercent(_ratingsObject.ratings[5]),
                            },
                        };

                        return _customFormat;
                    },
                    fields: ['reviews as total', 'ratingDistribution as ratings'],
                    default: '0',
                },
                openingHours: {
                    root: 'openingHours.openingHour',
                    format: null,
                    fields: null,
                    default: '-',
                },
                reviewsData: {
                    root: 'reviews',
                    format: null,
                    fields: null,
                    default: '',
                },
                featuredReview: {
                    root: 'featureReview',
                    format: function(_featureReview) {
                        _featureReview.rating = _featureReview.rating;
                        _featureReview.statisticsLike = (_featureReview.statistics && _featureReview.statistics.helpfulCount) ? _featureReview.statistics.helpfulCount : 0;
                        _featureReview.statisticsDislike = (_featureReview.statistics && _featureReview.statistics.unhelpfulCount) ? _featureReview.statistics.unhelpfulCount : 0;

                        return _featureReview;
                    },
                    fields: null,
                    default: '',
                },
            }, {
                reviewsNext: {
                    root: 'reviewsData.actions',
                    format: 'reviewsNext',
                    fields: 'next as reviewsNext',
                    default: '',
                },
                reviewList: {
                    root: 'reviewsData.review',
                    format: function(_reviewList) {
                        var _formatedList = [];
                        for (var i = 0, _customStructure, _comments, l = _reviewList.length; i < l; i++) {
                            _customStructure = {
                                id: _reviewList[i].id,
                                isActive: (_reviewList[i].status.publishStatus && _reviewList[i].status.publishStatus == 'active') ? true : false,
                                isApproved: (_reviewList[i].status.moderationStatus && _reviewList[i].status.moderationStatus == 'approved') ? true : false,
                                isReported: (_reviewList[i].status.moderationStatus && _reviewList[i].status.moderationStatus == 'reported') ? true : false,
                                dateCreated: _reviewList[i].dateCreated,
                                rating: _reviewList[i].rating,
                                userName: _reviewList[i].user.displayName,
                                userAvatar: (_reviewList[i]._has('user.avatars.image[0].urls.thumbnail')) ? _reviewList[i].user.avatars.image[0].urls.thumbnail : constantsPlaceholders.bdpReviewUserDetails.defaultAvatar,
                                userComment: _reviewList[i].text,
                                statisticsLike: (_reviewList[i].statistics && _reviewList[i].statistics.helpfulCount) ? _reviewList[i].statistics.helpfulCount : 0,
                                statisticsDislike: (_reviewList[i].statistics && _reviewList[i].statistics.unhelpfulCount) ? _reviewList[i].statistics.unhelpfulCount : 0,
                                user: _reviewList[i].user,
                            };

                            _customStructure.comments = [];

                            if (_reviewList[i].comment) {
                                _comments = _reviewList[i].comment;

                                for (var m = 0, _customComment, lm = _comments.length; m < lm; m++) {
                                    _customComment = {
                                      id: _comments[m].id,
                                      dateCreated: _comments[m].dateCreated,
                                      userName: _comments[m].user.displayName,
                                      userAvatar: _comments[m]._has('user.avatars.image[0].urls.thumbnail') ? _comments[m].user.avatars.image[0].urls.thumbnail : constantsPlaceholders.bdpReviewUserDetails.defaultAvatar,
                                      userComment: _comments[m].commentText,
                                      user: _comments[m].user,
                                  };

                                    _customStructure.comments.push(_customComment);
                                }
                            }
                            _formatedList.push(_customStructure);
                        }

                        return _formatedList;
                    },
                    fields: null,
                    default: '-',
                },
                firstInList: {
                    root: 'openingHours[0]',
                    format: null,
                    fields: 'day as d',
                    default: '-',
                },
            }, {
                testing: {
                    root: 'firstInList.d',
                    format: null,
                    fields: null,
                    default: '-',
                },
            }, _apiData);

            return _dataModel;
        }

        var getNextReviewBlock = function(_apiData) {
            var _dataModel = new DataModel({
                reviewsData: {
                    root: null,
                    format: null,
                    fields: null,
                    default: '',
                },
            }, {
                reviewsNext: {
                    root: 'reviewsData.actions',
                    format: 'reviewsNext',
                    fields: 'next as reviewsNext',
                    default: '',
                },
                reviewList: {
                    root: 'reviewsData.review',
                    format: function(_reviewList) {
                        var _formatedList = [];
                        for (var i = 0, _customStructure, _comments, l = _reviewList.length; i < l; i++) {
                            _customStructure = {
                                id: _reviewList[i].id,
                                isActive: (_reviewList[i].status.publishStatus && _reviewList[i].status.publishStatus == 'active') ? true : false,
                                isApproved: (_reviewList[i].status.moderationStatus && _reviewList[i].status.moderationStatus == 'approved') ? true : false,
                                isReported: (_reviewList[i].status.moderationStatus && _reviewList[i].status.moderationStatus == 'reported') ? true : false,
                                dateCreated: _reviewList[i].dateCreated,
                                rating: _reviewList[i].rating,
                                userName: _reviewList[i].user.displayName,
                                userAvatar: _reviewList[i].user.avatars.image[0].urls.thumbnail,
                                userComment: _reviewList[i].text,
                                statisticsLike: (_reviewList[i].statistics && _reviewList[i].statistics.helpfulCount) ? _reviewList[i].statistics.helpfulCount : 0,
                                statisticsDislike: (_reviewList[i].statistics && _reviewList[i].statistics.unhelpfulCount) ? _reviewList[i].statistics.unhelpfulCount : 0,
                                user: _reviewList[i].user,
                            };

                            _customStructure.comments = [];

                            if (_reviewList[i].comment) {
                                _comments = _reviewList[i].comment;

                                for (var m = 0, _customComment, lm = _comments.length; m < lm; m++) {
                                    _customComment = {
                                      id: _comments[m].id,
                                      dateCreated: _comments[m].dateCreated,
                                      userName: _comments[m].user.displayName,
                                      userAvatar: _comments[m].user.avatars.image[0].urls.thumbnail,
                                      userComment: _comments[m].commentText,
                                      user: _comments[m].user,
                                  };

                                    _customStructure.comments.push(_customComment);
                                }
                            }
                            _formatedList.push(_customStructure);
                        }

                        return _formatedList;
                    },
                    fields: null,
                    default: '-',
                },
            }, _apiData);

            return _dataModel;
        };

        var getUserIdToVisit = function() {
            return userIdToVisit;
        }
        var setUserIdToVisit = function(userId) {
            userIdToVisit = userId;
        }

        var _modelTypes = {
            businessDetailHeader: getBusinessDetailHeader,
            getNextReviewBlock: getNextReviewBlock,
            getUserIdToVisit: getUserIdToVisit,
            setUserIdToVisit: setUserIdToVisit
        };

        return _modelTypes;
    }
})();
