(function() {
    'use strict';

    angular.module('truelocal')
    .directive('searchResultAddress', searchResultAddress);

    function searchResultAddress() {
        return {
            restrict: 'EA',
            replace: true,
            templateUrl: '/app/components/searchpage/searchresultaddress/searchresultaddress.html',
            scope: {
                listing: '=searchResultListing',
                gridClass: '=',
            },
            controller: searchResultAddressController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

  /** @ngInject */
    function searchResultAddressController(constantsIcons) {
        var vm = this;
        vm.mapLocationIcon = constantsIcons.srpIcons.mapLocationIcon;

        vm.onMapSelect = function(id, isTol, isBol,isDisplayMap) {
          if(isDisplayMap){
            angular.element(window).triggerHandler('show-on-map', {item:id, isTol:isTol, isBol:isBol});
            }
        };

        vm.isService = false;
        vm.address = null;
        vm.isDisplayMap=false;

        (function updateAddress(listing) {
            vm.address=getCoreAddress(listing);
            if(listing.matchAddress && listing.matchAddress.type === 'SERVICE')
            {
                listing.seoUrl= listing.matchAddress.seoUrl;
                vm.address = listing.matchAddress;
                vm.isService = true;
            }
            else if (listing.matchAddress && !angular.isUndefined(listing.matchAddress.latLong) && listing.matchAddress.latLong!=""){
                vm.isDisplayMap = true;
            }
        })(vm.listing);

        function getCoreAddress(listing) {
            var address = listing.addresses && listing.addresses.address && listing.addresses.address[0];
            return isAddress(address) ? address : "";
        }

        function isAddress(address) {
            return !!(address.suburb || address.state || address.postCode);
        }
    }
})();
