(function() {
    'use strict';
    angular
        .module('truelocal')
        .directive('srpOpeninghours', SrpOpeningHours);

    /**
     *
     * @memberof truelocal
     * @ngdoc directive
     * @name srpOpeningHours
     * @description Directive will display business opening hours, with current status. Current status can be:
     *
     *      Closed until (soonest opening day)
     *      Open today (if business is currently closed, but will be opened today)
     *      Currently open
     *
     * Pair of opening hours for describing day will be displayed with current status e.g.:
     *
     *      Open today
     *      5:00 pm - 9:00 pm
     *
     * Each business have opening hours for each day. For one day, business can have zero, one or two pairs of opening
     * hours, or can work non stop. Directive contain expandable menu that displays opening hours for each day of the
     * week e.g:
     *
     *      Mon
     *      11:30 am - 2:00 pm
     *      5:00 pm - 9:00 pm
     *      Tue
     *      11:30 am - 2:00 pm
     *      5:00 pm - 9:00 pm
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {constant}  constantsClasses          Constant classes
     * @param {constant}  constantsSettings         Constant settings
     * @param {service}   @window                   Angular window wrapper
     *
     * @example
     * <srp-openinghours opening-hours="[object]"></srp-openinghours>
     */

    /** @ngInject */
    function SrpOpeningHours() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/searchopeninghours/srpopeninghours.html',
            scope: {
                listing: '=',
            },
            controller: SrpOpeningHoursController,
            controllerAs: 'vm',
            bindToController: true,
        };
        return directive;
    }

    /**
     *
     * @memberof srpOpeningHours
     * @method srpOpeningHours_SrpOpeningHoursController
     * @name srpOpeningHours_SrpOpeningHoursController
     * @description Directive controller
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {constant}  constantsClasses          Constant classes
     * @param {constant}  constantsSettings         Constant settings
     * @param {service}   @window                   Angular window wrapper
     *
     */

    /** @ngInject */
    function SrpOpeningHoursController($scope, platformService, $log, $timeout, constantsPlaceholders, constantsSettings, $document, DTM, $window, constantsIcons) {
        var vm = this;
        var now = new Date();
        vm.listing = vm.listing;
        vm.id = vm.listing.id;
        vm.openingHours = vm.listing.openingHours;
        vm.openingHoursIcon = constantsIcons.srpIcons.openingHoursIcon;
        vm.hasOpeningHours = hasOpeningHours;
        vm.getNowDay = getNowDay;
        vm.currentStatus = currentStatus;
        vm._calculateStatus = _calculateStatus;
        vm._createTimeObj = createTimeObj;
        vm._sortDay = sortDay;
        vm.dayWorkingHours = dayWorkingHours;
        vm.boxIsHidden = true;
        vm.toggleWorkingHoursBox = toggleWorkingHoursBox;
        vm._isMobile = platformService.isMobile();
        vm.hover = false;
        vm.setHover = setHover;
        // vm.windowWidth
        // vm.generateTooltip = generateTooltip;//OLD
        vm.scrollTooltip = scrollTooltip;
        var days = [];
        angular.copy(constantsSettings.daysOfWeekShort, days);
        days.push(days.shift());
        vm.daysOfWeek = days;
        vm.doubleShifts = false; // two pair of working hours
        vm.shrinked = shouldItShrink(); // screen is shrinked to hide op hours

        /**
         * @memberof srpOpeningHours
         * @method hasOpeningHours
         * @name hasOpeningHours
         * @description Returns true if business have opening hours.
         * @param {Object} openingHours
         * @returns {Boolean}
         */
        function hasOpeningHours(openingHours) {
            if (angular.isDefined(openingHours)) {
                vm.openingHours = openingHours;
            }
            if (angular.isUndefined(vm.openingHours)) {
                return false;
            } else if (vm.openingHours === '-') {
                return false;
            } else {
                return true;
            }
        }

        /**
         * @memberof srpOpeningHours
         * @method getNowDay
         * @name getNowDay
         * @description Return current day as string ("Monday"/"Tuesday"...)
         * @param {String}
         * @returns {String}
         */
        function getNowDay(_now) {
            if (angular.isDefined(_now)) {
                now = new Date(_now);
            }
            var day = now.getDay();
            var daysOfWeek = constantsSettings.daysOfWeekShort;
            return daysOfWeek[day];
        }


        /**
         * @memberof srpOpeningHours
         * @method currentStatus
         * @name currentStatus
         * @description Return current opening status (Currently open, Open today, Closed until etc...) and setting
         * array of currently opened hours.
         * @returns {String}
         */
        function currentStatus(openingHours) {
            var currStat = _calculateStatus(openingHours);
            return currStat;
        }

        /**
         * @memberof srpOpeningHours
         * @method _calculateStatus
         * @name _calculateStatus
         * @description method will pass through all opening hours and find current status and closest opening hours to
         * display. It will store data in array that will be exported through currentStatus() method:
         *  first element of array is string with current status Open today/Closed until day/Currently open
         *  second element of array is pair of day working hours closest to current time.
         * @param {Object} openingHours
         * @param {String} _now
         * @returns {String}
         */
        function _calculateStatus(openingHours, _now) {
            if (angular.isDefined(_now)) {
                now = new Date(_now);
            }
            if (angular.isDefined(openingHours)) {
                vm.openingHours = openingHours;
            }
            var status = constantsPlaceholders.openingHours.closed;
            var currentDay = getNowDay(now);
            angular.forEach(vm.openingHours.openingHour, function(objectDay) {
                if (objectDay.day === currentDay) {
                    if (objectDay.hasOwnProperty('24Hours') && objectDay['24Hours']) {
                        status = constantsPlaceholders.openingHours.openCurrently;
                    } else {
                        for (var i = 0; i < objectDay.hours.length; i++) {
                            if (angular.isDefined(objectDay.hours[i]) && status === constantsPlaceholders.openingHours.closed) {
                                var start = createTimeObj(objectDay.hours[i].open, _now);
                                var stop = createTimeObj(objectDay.hours[i].close, _now);
                                if (now.getTime() >= start.getTime() && now.getTime() <= stop.getTime()) {
                                    status = constantsPlaceholders.openingHours.openCurrently;
                                } else if (now.getTime() < start.getTime()) {
                                    status = constantsPlaceholders.openingHours.openToday;
                                }
                            }
                        }
                    }
                }
            });

            if (status === constantsPlaceholders.openingHours.closed) {
                var daysOfWeek = constantsSettings.daysOfWeekShort;
                var position = 1 + parseInt(daysOfWeek.indexOf(currentDay));
                var daysOfWeekSorted = daysOfWeek.slice(position, daysOfWeek.length).concat(daysOfWeek.slice(0, position));
                var findNextWorkDay = function(daysOfWeekSorted, openingHours) {
                    var result;
                    for (var i = 0; i < daysOfWeekSorted.length; i++) {
                        for (var j = 0; j < openingHours.length; j++) {
                            if (openingHours[j].day === daysOfWeekSorted[i]) {
                                if ((openingHours[j].hasOwnProperty('hours') && openingHours[j].hours.length > 0 && angular.isDefined(openingHours[j].hours[0])) ||
                                    (openingHours[j].hasOwnProperty('24Hours') && openingHours[j]['24Hours'])) { // if non stop open
                                    result = daysOfWeekSorted[i];
                                    return result;
                                }
                            }
                        }
                    }
                    return result;
                };
                var findResult = findNextWorkDay(daysOfWeekSorted, vm.openingHours.openingHour);
                status = status + ' until ' + constantsSettings.daysOfWeek[daysOfWeek.indexOf(findResult)];
            }
            return status;
        }

        /**
         * @memberof srpOpeningHours
         * @method createTimeObj
         * @name createTimeObj
         * @description create date object with today date, and hours & minutes from API
         * @param {String} inputTime "08:00 am - 04:00 pm"
         * @param {String} _now
         * @returns {Date}
         */
        function createTimeObj(inputTime, _now) {
            var startArray = inputTime.split(' ');
            var startHourMinute = startArray[0].split(':');
            var startHour = startHourMinute[0];

            if (startHour.substring(0, 1) === '0' && startHour !== '0' && startHour !== '00') {
                startHour = startHour.substring(1);
            }
            startHour = parseInt(startHour);
            var startMinute = startHourMinute[1];
            if (startMinute.substring(0, 1) === '0' && startMinute !== '0' && startMinute !== '00') {
                startMinute = startMinute.substring(1);
            }
            startMinute = parseInt(startMinute);
            if (startArray[1] === 'PM' || startArray[1] === 'pm') {
                if (startHour !== 12) {
                    startHour = startHour + 12;
                }
            }
            if (startArray[1] === 'AM' || startArray[1] === 'am') {
                if (startHour == 12) {
                    startHour = 0;
                }
            }
            var start;
            if (angular.isDefined(_now)) {
                start = new Date(_now);
            } else {
                start = new Date();
            }
            start.setHours(startHour);
            start.setMinutes(startMinute);
            start.setSeconds(0);
            return start;
        }


        /**
         * @memberof srpOpeningHours
         * @method sortDay
         * @name sortDay
         * @description Compare opening hours in current day (used for sorting).
         * @param {Object} day
         * @returns {Number}
         */
        function sortDay(day) {
            day.hours.sort(function(timeA, timeB) {
                return createTimeObj(timeA.open) - createTimeObj(timeB.open);
            });
        }


        /**
         * @memberof srpOpeningHours
         * @method dayWorkingHours
         * @name dayWorkingHours
         * @description Calculate opening hours for each day, and return it as array.
         * @param {Object} day
         * @returns {Array}
         */
        function dayWorkingHours(day) {
            var status = [];
            status[0] = constantsPlaceholders.openingHours.closed;
            if (angular.isUndefined(vm.openingHours)) {
                status[0] = constantsPlaceholders.openingHours.closed;
                return status[0];
                // return 'Closed';
            } else if (vm.openingHours === '-') {
                status[0] = constantsPlaceholders.openingHours.closed;
                return status[0];
                // return 'Closed';
            } else {
                angular.forEach(vm.openingHours.openingHour, function(objectDay) {
                    if (objectDay.day === day) {
                        if (objectDay.hasOwnProperty('24Hours') && objectDay['24Hours']) {
                            status[0] = '12:00 am - 11:59 pm';
                        } else {
                            status[0] = objectDay.hours[0].open.toLowerCase() + ' - ' + objectDay.hours[0].close.toLowerCase();
                            if (objectDay.hours.length > 1) {
                                if (objectDay.day == vm.getNowDay()) {
                                    vm.doubleShifts = true; // have two pair of working hours
                                }
                                status[1] = objectDay.hours[1].open.toLowerCase() + ' - ' + objectDay.hours[1].close.toLowerCase();
                            }
                        }
                    }
                });
                return status;
            }
        }

        /**
         * @memberof srpOpeningHours
         * @method toggleWorkingHoursBox
         * @name toggleWorkingHoursBox
         * @param name the name of the event
         * @description Show/hide opening hours modal..
         */
        function toggleWorkingHoursBox(name) {
            vm.boxIsHidden = !vm.boxIsHidden;
            if (vm.boxIsHidden == false) {
                DTM.trackSearchEvents(name, vm.listing);
            }
        }

        $scope.$on('open-opening-hours', function(event, data) {
            vm.boxIsHidden = false;
            vm.scrollTooltip();
        });

        /**
         * @memberof srpOpeningHours
         * @method setHover
         * @name setHover
         * @description User hover opening hours.
         */
        function setHover(val) {
            if (angular.isUndefined(val)) {
                return;
            }
            vm.hover = val;
            vm.scrollTooltip();
        }

        /**
         * @memberof srpOpeningHours
         * @method documentClickHandler
         * @name documentClickHandler
         * @description Called on mouse click.
         */
        var documentClickHandler = function(event) {
            if (vm.boxIsHidden == false) {
                var element = angular.element(document.querySelector('.wrapperHours .tooltip'));
                var eventOutsideTarget = (element[0] !== event.target) && (0 === element.find(event.target).length);
                if (eventOutsideTarget) {
                    $timeout(function() {
                        vm.boxIsHidden = true;
                    });
                }
            }
        };

        $document.on('click', documentClickHandler);
        // $document.on("touchstart", documentClickHandler);


        /**
         * @memberof srpOpeningHours
         * @method offset
         * @name offset
         * @description Calculate element dimensions.
         */
        function offset(elm) {
            try {
                return elm.offset();
            } catch (e) {
                $log.debug('inconsecvent error');
            }
            var rawDom = elm[0];
            var _x = 0;
            var _y = 0;
            var body = document.documentElement || document.body;
            var scrollX = window.pageXOffset || body.scrollLeft;
            var scrollY = window.pageYOffset || body.scrollTop;
            _x = rawDom.getBoundingClientRect().left + scrollX;
            _y = rawDom.getBoundingClientRect().top + scrollY;
            return {
                left: _x,
                top: _y,
            };
        }

        /**
         * @memberof srpOpeningHours
         * @method scrollTooltip
         * @name scrollTooltip
         * @description scroll opening hours toltip.
         */
        function scrollTooltip() {
            var toolTip = angular.element(document.querySelector('#wrapperHoursTooltip-' + vm.id));
            var toolTipOffset = offset(toolTip);
            var modalBottom = toolTipOffset.top + toolTip[0].offsetHeight;
            var shouldScrool = modalBottom > $window.outerHeight;
            if (shouldScrool || vm._isMobile) {
                $document.scrollTop(250, 1000);
            }
        }

        /**
         * @memberof srpOpeningHours
         * @method shouldItShrink
         * @name shouldItShrink
         * @description Calculate does opening hours tooltip fit on page..
         */
        function shouldItShrink() {
            var opHours = angular.element(document.querySelector('#opening-hours-1'));
            var address = angular.element(document.querySelector('#address-1'));
            var phone = angular.element(document.querySelector('#phone-1'));
            var contact = angular.element(document.querySelector('#contact'));
            var reqWidth1 = ((angular.isDefined(address) && address.length > 0) ? address[0].offsetWidth : 0 ) +
                            ((angular.isDefined(opHours) && opHours.length > 0) ? opHours[0].offsetWidth : 0 ) +
                            ((angular.isDefined(phone) && phone.length > 0) ? phone[0].offsetWidth : 0 ) + 155;
            var windowW = $window.outerWidth;

            if (platformService.getWidth() < 640) {
                return true;
            }
            return (windowW < reqWidth1);
        }

        angular.element($window).bind('resize', function() {
            vm._isMobile = platformService.isMobile();
            vm.shrinked = shouldItShrink();
        });
    }
})();
