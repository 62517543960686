(function () {
    'use strict';

    angular
        .module('truelocal')

        /**
         * @memberof truelocal
         * @method configBlock
         * @name configBlock
         * @description Loader configurations.
         */
        .config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
            cfpLoadingBarProvider.includeSpinner = false;
            cfpLoadingBarProvider.parentSelector = '#loading-bar-container';
        }])
        /**
         * @memberof truelocal
         * @method configBlock
         * @name configBlock
         * @description Application configuration block (log level and similar)..
         */
        .config(config);

    /** @ngInject */
    function config($logProvider, env, $httpProvider) {
        // Enable log
        $logProvider.debugEnabled(true);
        if (env.name === "prod")
            $logProvider.debugEnabled(false);

        $httpProvider.interceptors.push(function ($q, $rootScope, $window, $cookies) {
            return {
                'request': function (config) {
                    if (config.url.indexOf('.html') === -1 && !/^https?/.test(config.url)) {
                        var url = env.api.url;
                        var passToken = $window.localStorage.getItem('token') || $cookies.get('token') || env.api.token;
                        config.url = encodeURI(url + config.url + ((config.url.indexOf('?') === -1) ? '?' : '&') + 'passToken=' + passToken);
                        config.url = $window.encodeURI(config.url);
                    }
                    return config || $q.when(config);
                },
            };
        });

        $httpProvider.defaults.withCredentials = true;

        // Set options third-party lib
        // toastrConfig.allowHtml = true;
        // toastrConfig.timeOut = 3000;
        // toastrConfig.positionClass = 'toast-top-right';
        // toastrConfig.preventDuplicates = true;
        // toastrConfig.progressBar = true;
    }
})();
