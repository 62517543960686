(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name googleMap
     * @description Load Google Maps API and perform map initialization.
     *
     *  @example
     *  <google-map></google-map>

     */
    .directive('googleMap', googleMap);

  /** @ngInject */
    function googleMap(sharedService, $window) {
        var directive = {
            restrict: 'E',
            link: function(scope, element, attrs) {
                if (!$window.google) {
                    sharedService.initializeGoogleMapApi();
                } else {
                    sharedService.initMapSettings();
                }
            }
        };


        return directive;
    }
})();
