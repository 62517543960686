(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpSingleReview
     * @description Directive display singe user review with user details, ratings, review text and user action.
     *
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.@param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.
     * @param {service}   $window                   Angular window wrapper
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {factory}   DTM           TL tracking services
     * @param {factory}   Iovation                  Factory service provider method for handle integration with Iovation platforms
     * @param {service}   $rootScope                Angular app root scope
     *
     * @example
     * <bdp-single-review review-list="[object]" listing="[object]"></bdp-single-review>
     */
    .directive('bdpSingleReview', bdpSingleReview)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name focusActionBox
     * @description Directive provide scrolling to review or comment box holder.
     *
     * @param {constant}  constantsPlaceholders     Constant placeholders
     *
     * @example
     *
     * <div focus-action-box="">
     */
    .directive('focusActionBox', focusActionBox);

    function focusActionBox(constantsPlaceholders, $document) {
        return {
            restrict: 'A',
            link: function(scope) {
                scope.vm.focusBlock = function(_id) {
                    setTimeout(angular.bind(this, function(__id) {
                        var _elementId = '#review-' + __id;
                        var offset = angular.element(document.querySelector('.anchors.fixed')).length > 0 ?
                              angular.element(document.querySelector('.anchors.fixed')).prop('offsetTop') + angular.element(document.querySelector('.anchors.fixed'))[0].clientHeight + 10 : 10;
                        var _scrollToElementOffsetFromPageTop = (angular.element(document.querySelector(_elementId)).prop('offsetTop') - offset);

                        $document.scrollTop(_scrollToElementOffsetFromPageTop, constantsPlaceholders.focusActionBox.animationTime);
                    }, _id), constantsPlaceholders.focusActionBox.animationTime);
                };
            },
            scope: {
                vm: '=',
            },
        };
    }

  /** @ngInject */
    function bdpSingleReview() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpsinglereview/bdpsinglereview.html',
            scope: {
                reviewList: '=',
                listing: '=',
            },
            controller: BdpSingleReviewController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpSingleReviewController($timeout, $modal, modalFactory, authService, apiConfig, ModalLikeDislikeFactory, $window, constantsPlaceholders, Iovation, $rootScope, reviewsService, constantsApiErrorCodes, windowScroll, DTM, $document) {
            var vm = this;
            this.reviewItemOpened = null;

      /**
       * @memberof bdpSingleReview
       * @method active
       * @name active
       * @description Get if active by number and rating
       * @params {integer} number
       * @params {integer} rating
       */

            this.active = function(number, rating) {
                if (angular.isUndefined(rating)) {
                    rating = 0;
                }

                if (angular.isDefined(number) && rating >= number) {
                    return constantsPlaceholders.bdpSingleReview.active;
                }

                return constantsPlaceholders.bdpSingleReview.inactive;
            };

            vm.reviewsPanelStatus = [];

      /**
       * @memberof bdpSingleReview
       * @method hideIfMine
       * @name hideIfMine
       * @description Returns true if current review is of current user.
       * @param {Boolean} _reviewItem
       * @returns {Boolean} notMine
       */
            this.notMine = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return true;
                }
                if (angular.isUndefined(_reviewItem.isMine)) {
                    return true;
                }
                if (_reviewItem.isMine) {
                    return false;
                }
                return true;
            };


      /**
       * @memberof bdpSingleReview
       * @method getFormatedDate
       * @name getFormatedDate
       * @description Returns date of review as string in user friendly format.
       * @param {String} _timestamp, if not entered, use current review date.
       * @returns {String} formatDate
       */
            this.getFormatedDate = function(_timestamp) {
                var _formatedDate = '';
                if (angular.isUndefined(_timestamp)) {
                    return _formatedDate;
                }
                var date = new Date(_timestamp);
                if (!isFinite(date.getTime())) {
                    return _formatedDate;
                }
                _formatedDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

                return _formatedDate;
            };


      /**
       * @memberof bdpSingleReview
       * @method hideIfMine
       * @name hideIfMine
       * @description Returns true if current comment is of the current user.
       * @params {Object} _reviewItem
       * @returns {Boolean} isMine
       */
            this.hideIfMine = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return true;
                }
                if (angular.isDefined(_reviewItem.user) && angular.isDefined(_reviewItem.user.id) && apiConfig.checkIfMine(_reviewItem.user.id)) {
                    return false;
                }
                return true;
            };

      /**
       * @memberof bdpSingleReview
       * @method reportNow
       * @name reportNow
       * @description Call modal that enable report now option.
       * @params {Object} _reviewItem
       */
            this.reportNow = function(_reviewItem) {
                this.resetAllStates();
                DTM.trackAnalyticOnClick('report review:start');

                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                if (_reviewItem.reported) {
                    $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                        controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                        resolve: {
                            storedData: function() {
                                return constantsPlaceholders.bdpSingleReview.alreadyReportedText;
                            },
                        },
                    });
                    return false;
                } else if (apiConfig.userIsAuthenticated()) {
                    $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpmodalreportpendingmoderation/bdpmodalreportpendingmoderation.html',
                        controller: ['_reviewItem', 'apiConfig', 'constantsPlaceholders', 'reviewsService', 'DTM', 'constantsApiErrorCodes', 'listing', modalReportPendingModeration],
                        controllerAs: 'vm',
                        size: 'dialog_size_big',
                        resolve: {
                            _reviewItem: function() {
                                return _reviewItem;
                            },
                            listing: function() {
                                return vm.listing;
                            },
                        },

                    });
                } else {
                    this.showAuthModal();
                }
            };
            
            this.showAuthModal = function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                    controller: 'ModalAuthPromptController',
                    controllerAs: 'vm',
                    size: 'dialog_size_small',
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
            };

      /**
       * @memberof bdpSingleReview
       * @method reportActiveReview
       * @name reportActiveReview
       * @description Return boolean whether review is active
       * @params {Object} _reviewItem
       * @returns {Boolean} notReported
       */
            this.reportActiveReview = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }
                if (angular.isUndefined(_reviewItem.alreadyReported) && (_reviewItem.reportingReview) && (_reviewItem.isActive)) {
                    return true;
                }
                if ((_reviewItem.alreadyReported) && angular.isUndefined(_reviewItem.reportingReview) && !(_reviewItem.isActive)) {
                    return false;
                }
                return false;
            };

      /**
       * @memberof bdpSingleReview
       * @method commentTracking
       * @name commentTracking
       * @description Track user activity.
       */
            this.commentTracking = function() {
                DTM.trackAnalyticOnClick('write comment start');
            };

      /**
       * @memberof bdpSingleReview
       * @method addComment
       * @name addComment
       * @description Handle adding comment.s
       */
            this.addComment = function(_reviewItem) {
                this.resetAllStates();

                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                if (apiConfig.userIsAuthenticated()) {
          // angular.extend(_reviewItem, {expandedDropdownState: false, startAddingComment: true});
                    angular.extend(_reviewItem, {expandedDropdownState: false, startAddingComment: true, addedCommentThankYouMessage: false});
                    this.commentTracking();
                    this.focusBlock(_reviewItem.id);
                    Iovation.initBlackBox(); // init the blackbox only now
                } else {
                    this.showAuthModal();
                }
            };


      /**
       * @memberof bdpSingleReview
       * @method isAddingComment
       * @name isAddingComment
       * @description Check is adding comments in progress.
       * @returns {Boolean} isAddingComment
       */
            this.isAddingComment = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }
                if (angular.isUndefined(_reviewItem.startAddingComment) && angular.isUndefined(_reviewItem.addedCommentThankYouMessage)) {
                    return false;
                }
                if (_reviewItem.startAddingComment || _reviewItem.addedCommentThankYouMessage) {
                    return true;
                }
                return false;
            };

      /**
       * @memberof bdpSingleReview
       * @method getCategoryVertical
       * @name getCategoryVertical
       * @description Returns category vertical object.
       * @returns {Object} categoryVertical
       */
            this.getCategoryVertical = function() {
                var _listing = apiConfig.getListing();
                if (angular.isUndefined(_listing)) {
                    return {name: ''};
                }
                return (angular.isDefined(_listing.bussinessDetails)) ?
                        ((angular.isDefined(_listing.bussinessDetails.categoryVertical)) ?
                       _listing.bussinessDetails.categoryVertical : {name: ''}) : {name: ''};
            };
            vm.categoryVertical = this.getCategoryVertical();

      /**
       * @memberof bdpSingleReview
       * @method isExpanded
       * @name isExpanded
       * @description Returns dropdown state open if it is open or empty string otherwise.
       * @params {Object} _reviewItem
       * @returns {String} isExpanded
       */
            this.isExpanded = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }
                return (_reviewItem.expandedDropdownState) ? true : false;
            };


      /**
       * @memberof bdpSingleReview
       * @method collapseDropdown
       * @name collapseDropdown
       * @description Collapse dropdown.
       * @params {Object} _reviewItem
       */
            this.collapseDropdown = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                _reviewItem.timeout = $timeout(angular.bind(this, function(_rItem) {
                    angular.extend(_rItem, {expandedDropdownState: false});
                }, _reviewItem), 800);
            };


      /**
       * @memberof bdpSingleReview
       * @method cancelTimeout
       * @name cancelTimeout
       * @description Cancel current progress.
       * @params {Object} _reviewItem
       */
            this.cancelTimeout = function(_reviewItem) {
                $timeout.cancel(_reviewItem.timeout);
                _reviewItem.timeout = null;
            };

            this.preventProp = function(event) {
                event.preventDefault();
                return;
            };

            angular.element($window).bind('scroll', function() {
                if (vm.reviewItemOpened) {
                    vm.reviewItemOpened.expandedDropdownState = false;
                    $rootScope.$apply();
                }
            });


      /**
       * @memberof bdpSingleReview
       * @method expandDropdown
       * @name expandDropdown
       * @description Expand dropdown.
       * @params {Object} _reviewItem
       */
            this.expandDropdown = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                if (_reviewItem.expandedDropdownState) {
                    angular.extend(_reviewItem, {expandedDropdownState: false});
                    vm.reviewItemOpened = null;
                    return;
                }
                vm.reviewItemOpened = _reviewItem;
                angular.extend(_reviewItem, {expandedDropdownState: true});
            };


      /**
       * @memberof bdpSingleReview
       * @method resetAllStates
       * @name resetAllStates
       * @description Reset all states to default values.
       */
            this.resetAllStates = function() {
                var _bussinessModel = apiConfig.getListing();

                if (angular.isDefined(_bussinessModel) && angular.isDefined(_bussinessModel.featureReview) && _bussinessModel.featureReview) {
                    angular.extend(_bussinessModel.featureReview,
                        {
                            expandedDropdownState: false,
                            startAddingComment: false,
                            reportingReview: false,
                        });

                    if (_bussinessModel.featureReview.comments && _bussinessModel.featureReview.comments.length) {
                        for (var featuredCommentsIndex = 0, featuredCommentsListSize = _bussinessModel.featureReview.comments.length; featuredCommentsIndex < featuredCommentsListSize; featuredCommentsIndex++) {
                            angular.extend(_bussinessModel.featureReview.comments[featuredCommentsIndex], {
                                reportingReview: false,
                                expandedDropdownStateSmall: false,
                            });
                        }
                    }
                }

                if (angular.isDefined(_bussinessModel) && angular.isDefined(_bussinessModel.bussinessDetails) && _bussinessModel.bussinessDetails.reviewList) {
                    for (var reviewsIndex = 0, reviewsListSize = _bussinessModel.bussinessDetails.reviewList.length; reviewsIndex < reviewsListSize; reviewsIndex++) {
                        angular.extend(_bussinessModel.bussinessDetails.reviewList[reviewsIndex],
                            {
                                expandedDropdownState: false,
                                startAddingComment: false,
                                reportingReview: false,
                            });

                        if (_bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments && _bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments.length) {
                            for (var revCommentsIndex = 0, revCommentsListSize = _bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments.length; revCommentsIndex < revCommentsListSize; revCommentsIndex++) {
                                angular.extend(_bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments[revCommentsIndex], {
                                    reportingReview: false,
                                    expandedDropdownStateSmall: false,
                                });
                            }
                        }
                    }
                }
            };
        }
    }
})();
