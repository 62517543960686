(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpGallerySingleImageBox
     * @description Directive is holder box element for single image. It handles gathering of data, and setting
     * appropriate style.
     *
     * @param  {constant}  constantsClasses          Constant classes
     *
     * @example
     *  <bdp-gallery-single-image-box images-data="[object]" listing="[object]" parent-scope="[object]"></bdp-gallery-single-image-box>
     *
     */
    .directive('bdpGallerySingleImageBox', bdpGallerySingleImageBox)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name singleImageHandler
     * @description Directive handle behavior of single image element holder, respecting current screen size and
     * orientation.
     *
     * @param {service}   $window                   Angular window wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     */
    .directive('singleImageHandler', ['$window', '$timeout', 'platformService', singleImageHandler]);

    function singleImageHandler($window, $timeout, platformService) {
        return {
            restrict: 'A',
            link: function(scope, element, attr) {
                var _setActiveViewPort = function(_s) {
                    var _currentWindowWidth = platformService.getWidth();
                    if (_currentWindowWidth < 768) {
                        _s.vm.viewOption = 0;
                    } else if (_currentWindowWidth >= 768 && _currentWindowWidth <= 1085) {
                        _s.vm.viewOption = 1;
                    } else if (_currentWindowWidth > 1085 && _currentWindowWidth < 1296) {
                        _s.vm.viewOption = 2;
                    } else {
                        _s.vm.viewOption = 3;
                    }
                    $timeout(angular.bind(_s, _updateList, _s), 5);
                };

                var _updateList = function(_s) {
                    _s.vm.imagesList = _s.vm.imagesData || [];
                };

                angular.element($window).on('resize', angular.bind(scope, _setActiveViewPort, scope));

                _setActiveViewPort(scope);

                attr.$observe('backgroundImage', function() {
                    if (attr.backgroundImage) {
                        var image = new Image();
                        image.src = attr.backgroundImage;
                        image.onload = function() {
              // Image loaded- set the background image to it
                            element.removeAttr('style').attr('style', 'background: url(' + attr.backgroundImage + '); background-size: cover');
                            if (element[0].querySelector('.spinner') != null) {
                                var spinnerId = element[0].querySelector('.spinner').attributes['id'].value;
                                if (spinnerId) {
                                    var spinner = document.getElementById(spinnerId);
                                    if (angular.isDefined(spinner) && spinner != null) {
                                        spinner.parentNode.removeChild(spinner);
                                    }
                                }
                            }
                        };
                    }
                });
            },
            scope: {
                vm: '=',
            },
        };
    }


  /** @ngInject */
    function bdpGallerySingleImageBox() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpgallerysingleimagebox/bdpgallerysingleimagebox.html',
            scope: {
                imagesData: '=',
                parentScope: '=',
                listing: '=',
            },
            controller: ['constantsClasses', BdpGallerySingleImageBoxController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;


    /**
     * @memberof bdpGallerySingleImageBox
     * @method bdpGallerySingleImageBox
     * @name bdpGallerySingleImageBox_bdpGallerySingleImageBox
     * @description Controller for directive
     *
     * @param  {constant}  constantsClasses          Constant classes
     *
     *
     */

    /** @ngInject */
        function BdpGallerySingleImageBoxController(constantsClasses) {
            this.viewOption = 0;
            this.sizeOfList = 0;


      /**
       * @memberof bdpGallerySingleImageBox
       * @name imagesList
       * @description Array of gallery images.
       */
            this.imagesList = this.imagesData || [];


      /**
       * @memberof bdpGallerySingleImageBox
       * @method getImagesList
       * @name getImagesList
       * @description Getting list of images.
       * @returns {Array} imagesList
       */
            this.getImagesList = function() {
                this.imagesList = this.imagesData || [];
                return this.imagesList;
            };


      /**
       * @memberof bdpGallerySingleImageBox
       * @method getImage
       * @name getImage
       * @description Returns image url according to entered image index, current viewOption and current list size.
       * @params {Number} _index of image
       * @params {Object} _imageData
       * @returns {String} imageUrl
       */
            this.getImage = function(_index, _imageData) {
                if (angular.isUndefined(_index)) {
                    return false;
                } else if (_index == 0) {
                    return _imageData.urls.large;
                } else if (_index == 1 &&
          (this.viewOption == 2 ||
          this.viewOption == 3) &&
          this.getCurrentListSize() == 2) {
                    return _imageData.urls.large;
                } else {
                    return _imageData.urls.medium;
                }
            };


      /**
       * @memberof bdpGallerySingleImageBox
       * @method getMarkClass
       * @name getMarkClass
       * @description Returns proper mark image class (verified or user).
       * @params {Number} _index of image
       * @returns {String} isVerified
       */
            this.getMarkClass = function(_index) {
                if (angular.isUndefined(_index)) {
                    return constantsClasses.bdpGallerySingleImageBox.verified;
                } else if (this.imagesList[_index].user) {
                    return constantsClasses.bdpGallerySingleImageBox.user;
                } else {
                    return constantsClasses.bdpGallerySingleImageBox.verified;
                }
            };


      /**
       * @memberof bdpGallerySingleImageBox
       * @method isBoxAvailable
       * @name isBoxAvailable
       * @description Check is box available according to current view option, and returns true if it is or
       * false otherwise.
       * @params {Number} _index of image
       * @returns {Boolean} isVerified
       */
            this.isBoxAvailable = function(_index) {
                if (angular.isUndefined(_index)) {
                    return false;
                } else if (this.viewOption <= 1) {
                    if (_index == 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.viewOption == 2) {
                    if (_index < 3) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.viewOption == 3) {
                    if (_index < 5) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            };


      /**
       * @memberof bdpGallerySingleImageBox
       * @method getUserImage
       * @name getUserImage
       * @description Returns user avatar if available or empty string otherwise.
       * @params {Number} _index of image
       * @returns {String} imageUrl
       */
            this.getUserImage = function(_index) {
                if (angular.isUndefined(_index)) {
                    return '';
                } else if (this.imagesList._has('[' + _index + '].user.avatars.image[0].urls.thumbnail')) {
                    return this.imagesList[_index].user.avatars.image[0].urls.thumbnail;
                } else {
                    return '';
                }
            };

        /**
         * @memberof bdpGallerySingleImageBox
         * @method getAltText
         * @name getAltText
         * @description Constructs the alternate text for the image.
         * @params {Number} _index of image
         * @returns {String} alternate text
         */
        this.getAltText = function(_index) {
            var businessName = this.imagesList[_index].businessName;
            var caption = this.imagesList[_index].caption;
            var picCtr = _index + 1;
            if(angular.isDefined(caption)){
                caption = caption.replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
                caption = caption.substring(0, 255);
                return businessName + ' Pic ' + picCtr + ' - ' + caption;
            }
            return businessName + ' Pic ' + picCtr;
        };


      /**
       * @memberof bdpGallerySingleImageBox
       * @method getCurrentListSize
       * @name getCurrentListSize
       * @description Returns number of images in gallery.
       * @returns {Number} sizeOfList
       */
            this.getCurrentListSize = function() {
                if (this.imagesData && this.imagesData.length) {
                    this.sizeOfList = this.imagesData.length;
                    return this.sizeOfList;
                } else {
                    this.sizeOfList = 0;
                    return 0;
                }
            };


      /**
       * @memberof bdpGallerySingleImageBox
       * @method getBoxClass
       * @name getBoxClass
       * @description Returns image cover class according to image index and current view option.
       * @params {Number} _index of image
       * @returns {String | Boolean} imageUrl
       */
            this.getBoxClass = function(_index) {
                if (angular.isUndefined(_index)) {
                    return false;
                }
                if (_index == 0) {
                    this.specialCoverClass = constantsClasses.bdpGallerySingleImageBox.small;
                    var _class = constantsClasses.bdpGallerySingleImageBox.big;
                    if (this.viewOption == 0 || this.viewOption == 1 || this.viewOption == 2) {
                        return _class + ' ' + constantsClasses.bdpGallerySingleImageBox.fullSize;
                    } else {
                        return _class;
                    }
                } else if (_index == 1 && (this.viewOption == 3 && this.getCurrentListSize() == 4) || ((this.viewOption == 2 || this.viewOption == 3) && this.getCurrentListSize() == 2)) {
                    this.specialCoverClass = constantsClasses.bdpGallerySingleImageBox.small + ' ' + constantsClasses.bdpGallerySingleImageBox.opposite;
                    return constantsClasses.bdpGallerySingleImageBox.medium;
                } else {
                    return this.specialCoverClass;
                }
            };


      /**
       * @memberof bdpGallerySingleImageBox
       * @method openTheatre
       * @name openTheatre
       * @description Opens theatre view.
       * @params {Number} _index of image
       */
            this.openTheatre = function(index) {
                this.parentScope.openTheatre(index);
            };
        }
    }
})();
