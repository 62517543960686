(function() {
    'use strict';

    angular
        .module('truelocal')
        .constant('externalScriptsConfig', [
            // {
            //     name: 'Some script',
            //     snippet:  '/*<![CDATA[*/ {Add script here without the curly brace} /*]]>*/',
            //     delay: 10000
            // }
        ])

})();
