(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name notifyBanner
     * @description Display Notify Banner on True Local
     *
     * @example
     * <notify-banner campaign-name="campaignName"></notify-banner>
     */
    .directive('notifyBanner', notifyBanner);

    /** @ngInject */
    function notifyBanner() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/notifyBanner/notifyBanner.html',
            scope: {},

            /**
             * @memberof truelocal
             * @ngdoc controller
             * @name NotifyBannerController
             * @description NotifyBannerController.
             */
            controller: ['$attrs', 'env', '$timeout', '$sce', '$window','$cookies', NotifyBannerController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function NotifyBannerController($attrs, env, $timeout, $sce, $window, $cookies) {
            var vm = this;
            vm.isHidden = true;
            vm.notifyBanner = env.notifyBanner;
            vm.notifyCampaignName = $attrs.campaignName || "sampleCampaignName";
            vm.campaignContent = vm.notifyBanner[vm.notifyCampaignName] && vm.notifyBanner[vm.notifyCampaignName].content || "";
            vm.cookiePrefix = vm.notifyBanner.cookiePrefix || "";

            vm.startDate =  vm.notifyBanner[vm.notifyCampaignName] && vm.notifyBanner[vm.notifyCampaignName].startDate;
            vm.endDate = vm.notifyBanner[vm.notifyCampaignName] && vm.notifyBanner[vm.notifyCampaignName].endDate || vm.notifyBanner[vm.notifyCampaignName].defaultEndDate;
            vm.expiryDays = vm.notifyBanner[vm.notifyCampaignName] && vm.notifyBanner[vm.notifyCampaignName].expiryDays || null;
            vm.clickInside = vm.notifyBanner[vm.notifyCampaignName] && vm.notifyBanner[vm.notifyCampaignName].clickInside || false;
            vm.notifyCampaignNameClass = vm.notifyCampaignName;
            vm.cookieName = vm.cookiePrefix + vm.notifyCampaignName;

            // Security feature, helps prevent Cross-Site Scripting (XSS) attacks etc
            vm.textContent = $sce.trustAsHtml(vm.campaignContent);
            vm.isInRange = isDateInRange(vm.startDate, vm.endDate);
            vm.cookieValue = getCookie(vm.cookieName);
            vm.isHidden = onLoadInitialValue();

            // logger
            // console.log(vm.cookieName, { cookieName: vm.cookieName, isHidden: vm.isHidden, cookieValue: vm.cookieValue, isInRange: vm.isInRange, currentDate: getCurrentDate(), endDate: vm.endDate });

            function getCurrentDate() {
                var date = new Date();
                var year = date.getFullYear();

                // zero-based, so need to add 1 to get the correct month
                var currentMonth = date.getMonth() + 1;
                var currentDate = date.getDate();
                if (currentMonth < 10) {
                    currentMonth = '0' + currentMonth;
                }
                if (currentDate < 10) {
                    currentDate = '0' + currentDate;
                }
                var month = currentMonth.toString();
                var day = currentDate.toString();
                var formattedCurrentDate = year + '-' + month + '-' + day;
                // console.log('formattedCurrentDate: ', formattedCurrentDate);

                return formattedCurrentDate;
            }

            function parseDate(dateString) {
                return new Date(dateString);
            }

            function isDateInRange(startDate, endDate) {
               var currentDate = parseDate(getCurrentDate());
               var startDateObj = parseDate(startDate);
               var endDateObj = parseDate(endDate);

                return currentDate >= startDateObj && currentDate <= endDateObj;
            }

            function getCookie(name) {
                var value = $cookies.get(name);

                if (value !== undefined) {
                    return value.toLowerCase() === 'true';
                }
                return false;
            }

            function onLoadInitialValue() {
                if (!validateCampaignName()) {
                    return true;
                }

                return (vm.cookieValue && vm.isInRange) || (vm.cookieName !== true && vm.isInRange !== true);
            }

            function validateCampaignName() {
                if (vm.notifyBanner && !vm.notifyBanner[vm.notifyCampaignName]) {
                    console.warn(vm.notifyCampaignName + ' is not supported please define properties in the configuration file.');
                }

                if (!hasRequiredProperties(vm.notifyBanner[vm.notifyCampaignName])) {
                    console.warn(vm.notifyCampaignName + ': "startDate" and "content" properties are required.');
                }

                return vm.notifyBanner && vm.notifyBanner[vm.notifyCampaignName];
            }

            function hasRequiredProperties(obj) {
                var data = obj || {};
                return data.hasOwnProperty('content') && data.hasOwnProperty('startDate')
            }

            function setCookie(name, value, expiryDays) {
                var options = {};

                if (expiryDays !== undefined && expiryDays !== null) {
                    var expiryDate = new Date();
                    expiryDate.setDate(expiryDate.getDate() + expiryDays);

                    options.expires = expiryDate;
                }

                $cookies.put(name, value.toString(), options);
            }

            function countAllBanners() {
                $timeout(function() {
                    vm.notifyWrapperElement = angular.element(document.querySelectorAll('.notify-wrapper'));
                    applyStylesTo(vm.notifyWrapperElement);
                }, 250);
            }

            function applyStylesTo(wrapperElement) {
                var _baseHeight = 0;

                for (var index = 0; index < wrapperElement.length; index++) {
                    var notifyItem = angular.element(wrapperElement[index]);

                    if (index >= 1) {
                        _baseHeight += angular.element(wrapperElement[index - 1]).prop('clientHeight');

                        notifyItem.css({
                            'bottom': _baseHeight + 'px',
                            'border-bottom': '1px solid #222'
                        });
                    } else {
                        notifyItem.css({
                            'bottom': 0 + 'px'
                        });
                    }
                }
            }

            function initializeElements() {
                vm.notifyWrapperElement = angular.element(document.querySelectorAll('.notify-wrapper'));
                applyStylesTo(vm.notifyWrapperElement);
            };

            function onWindowResize() {
                initializeElements();
                applyStylesTo(vm.notifyWrapperElement);
            }

            vm.closeBanner = function() {
                var elemWrap = angular.element(document.querySelector('.' + vm.notifyCampaignName));

                if (env.name !== 'prod') {
                    console.log("Set notify banner cookie name: ", vm.cookieName);
                }

                setCookie(vm.cookieName, true, vm.expiryDays);
                elemWrap.addClass('away');

                $timeout(function() {
                    vm.isHidden = true;
                    // re-count all banner after closing one
                    countAllBanners();

                    // re-fetch banner selectors after removing one in the DOM
                    initializeElements();
                }, 400);
            };

            vm.start = function() {
                countAllBanners();
                initializeElements();
                angular.element($window).on('resize', onWindowResize);
            }
        }
    }
})();
