(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name bdpBooking
         * @description Directive displays button in bdp header with option to request booking. should not be visible when no vcitaId.
         *
         */
        .directive('bdpBooking', bdpBooking);

    /** @ngInject */
    function bdpBooking() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpbooking/bdpbooking.html',
            scope: {
                details: '=',
                listing: '=',
            },
            controller: ['$scope', '$window', BdpBookingController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */

        function BdpBookingController($scope, $window) {
            var vm = this;
            vm.details = this.details;

            vm.hasVCitaId = false;

            vm.haveVCitaId = function(listing) {
                if (angular.isDefined(listing)) {
                    this.listing = listing;
                }

                vm.hasVCitaId = angular.isDefined(this.listing) && angular.isDefined(this.listing.vcitaId) && (this.listing.vcitaServiceEnabled == true);

                return vm.hasVCitaId;
            };
        }
    }
})();