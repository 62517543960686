(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpAutoListHolder
     * @description Directive displays search suggestions in dropdown menu based on current user input in search
     * business box. When user choose suggestion search address text and location are filled in.
     * @param {service} HeaderSearchDropDownSevice Service for getter/setter specific data cross-directive
     * @example
     * <bdp-auto-list-holder list-category="[string:key]" list-data="[string:locationList]" parent-scope="[reference:vm]" data-ng-repeat="(int:key, obj:locationList) in obj:vm.sortedObjectlocation"></bdp-auto-list-holder>
     */
    .directive('bdpAutoListHolder', bdpAutoListHolder)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name scrollToSelection
     * @description Directive enable scrolling into search suggestions that are displayed in search box dropdown
     * while user entering text.
     */
    .directive('scrollToSelection', scrollToSelection);


    function scrollToSelection() {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                scope.$watch(scope.parentScope.getCurrentSelected, function(_new) {
                    if (angular.isDefined(_new) && _new !== null) {
                        var _idOfElement = '#term' + _new;
                        var _parentElement = angular.element(elem);
                        var _parentElementOffset = _parentElement.scrollTop();
                        var _parentElementHeight = _parentElement.height();
                        var _selectedElement = _parentElement.find(_idOfElement);
                        var _selectedElementOffset = _selectedElement.position().top;
                        var _selectedElementHeight = _selectedElement.outerHeight();

                        if (_parentElementOffset < _selectedElementOffset + _parentElementOffset &&
                _parentElementOffset + _parentElementHeight > (_selectedElementOffset + _selectedElementHeight + _parentElementOffset)) {
                            return;
                        }

                        if (_parentElementOffset > _selectedElementOffset + _parentElementOffset) {
                            var _scrollToTopFix = _selectedElementOffset + _parentElementOffset;
                            _scrollToTopFix = (_scrollToTopFix < 30 || _new == 0) ? 0 : _scrollToTopFix;
                            _parentElement.scrollTop(_scrollToTopFix);
                        }
                        if (_parentElementOffset + _parentElementHeight < (_selectedElementOffset + _selectedElementHeight + _parentElementOffset)) {
                            _parentElement.scrollTop((_selectedElementOffset + _parentElementOffset + _selectedElementHeight) - _parentElementHeight);// (_selectedElementOffset + _parentElementOffset + _parentElementHeight) - _selectedElementHeight);
                        }
                    }
                });
            },
            scope: {
                parentScope: '=',
            },
        };
    }

    function bdpAutoListHolder() {
        return {
            restrict: 'E',
            templateUrl: '/app/components/shared/bdpheadersearchform/bdpheaderlistholder.html',
            scope: {
                listData: '=',
                listCategory: '=',
                parentScope: '=',
            },
            controller: ['HeaderSearchDropDownSevice', BdpAutoListHolderController],
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function BdpAutoListHolderController(HeaderSearchDropDownSevice) {
        this.HeaderSearchDropDownSevice = HeaderSearchDropDownSevice;

    /**
     * @memberof bdpAutoListHolder
     * @method getListItems
     * @name getListItems
     * @description This method return the list with results from scope. If the list is not defined will return empty array
     * @return {array/object} list with results
     */
        this.getListItems = function() {
            if (angular.isUndefined(this.listData)) {
                return [];
            }
            return this.listData;
        };

    /**
     * @memberof bdpAutoListHolder
     * @method activeClass
     * @name activeClass
     * @description This method will return if active or not
     * @return {string} html class name
     */
        this.activeClass = function(_itemIndex) {
            if (this.parentScope.isSelected(_itemIndex)) {
                return 'selected';
            }
            return '';
        };
        this.oldMouseMovePosition = {pageX: 0, pageY: 0};


    /**
     * @memberof bdpAutoListHolder
     * @method onMouseMove
     * @name onMouseMove
     * @description On mouse move determine the selected one
     */
        this.onMouseMove = function(e) {
            if (this.oldMouseMovePosition.pageX != e.pageX || this.oldMouseMovePosition.pageY != e.pageY) {
                this.oldMouseMovePosition = {pageX: e.pageX, pageY: e.pageY};
                this.parentScope.setCurrentSelected(null);
            } else {
                e.stopPropagation();
                e.preventDefault();
            }
        };

    /**
     * @memberof bdpAutoListHolder
     * @method onMouseOver
     * @name onMouseOver
     * @description On mouse over determine the selected one
     */
        this.onMouseOver = function(e) {
            this.oldMouseMovePosition = {pageX: e.pageX, pageY: e.pageY};
            e.stopPropagation();
            e.preventDefault();
        };

    /**
     * @memberof bdpAutoListHolder
     * @method selectedAutoOption
     * @name selectedAutoOption
     * @description This method is executed on clicking on a list item
     */
        this.selectedAutoOption = function(_selectedItem) {
            if (angular.isUndefined(_selectedItem)) {
                return;
            } else if (_selectedItem.searchUrl.locations) {
                this.parentScope.populateFields(_selectedItem.searchUrl);
                return;
            } else {
                this.parentScope.focusButtonSearch(_selectedItem);
                return;
            }
        };
    }
})();
