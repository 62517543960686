(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc service
         * @name arrowService
         * @description arrowService is actually AdService used to work with QUBIT but now works with DTM
         *
         * @param {service}   $log                      Angular console log wrapper
         * @param {service}   $document                 Angular document wrapper
         * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
         * @param {service}   $location                 Angular window.location service wrapper
         * @param {constant}  arrowConstants            Constants for ads
         */
        .factory('arrowService', arrowService);

    /** @ngInject */
    function arrowService($log, $document, platformService, $location, arrowConstants, env) {
        var service = {
            initBdp: initBdp,
            geminiActive: false,
            initGemini: initGemini,
            desktopArrowIndex: 5,
            desktopNextIncrement: 3,
            setDesktopArrowIndex: setDesktopArrowIndex,
            getDesktopArrowIndex: getDesktopArrowIndex,
            setdesktopNextIncrement: setdesktopNextIncrement,
            getdesktopNextIncrement: getdesktopNextIncrement
        };

        var field = 'ad_debug_tool';
        var url = $location.absUrl();
        var debugAdVariables = false;
        if (url.indexOf('?' + field) != -1) {
            debugAdVariables = true;
        } else if (url.indexOf('&' + field) != -1) {
            debugAdVariables = true;
        }

        /**
         * @memberof apiConfig
         * @method initBdp
         * @name initBdp
         * @description Init the bdp adds
         * @param {object} listing The business data
         */
        function initBdp(listing) {
            var _bdpSsn = getBdpSsn(listing);
            $document.find('body').append(_bdpSsn);
        }

        /**
         * @memberof apiConfig
         * @method initGemini
         * @name initGemini
         * @description Handle the mobile ads from Yahoo gemini
         */
        function initGemini() {
            if (platformService.getWidth() < 768 && service.geminiActive === false) {
                var _script = '<script type="text/javascript">' +
                    'var sectionCode = sectionCode || [];' +
                    'sectionCode.push("' + arrowConstants.geminiSectionCode + '");' +
                    '(function(){' +
                    'var script = document.createElement("script");' +
                    'script.async = true;' +
                    'script.src = "https://s.yimg.com/av/gemini/ga/gemini.js";' +
                    'document.body.appendChild(script);' +
                    '})();' +
                    '</script>';
                angular.element(window).on('similarOtherRenderComplete', function() {
                    if (service.geminiActive === false) {
                        service.geminiActive = true;
                        $document.find('head').append(_script);
                    }
                });
            }
        }

        /**
         * @memberof apiConfig
         * @method getCategory
         * @name getCategory
         * @description get category from listing
         *
         * @param {object} listing Listing object
         */
        function getCategory(listing) {
            if (typeof listing == 'undefined') return '';
            else if (typeof listing.categories == 'undefined') return '';
            else if (typeof listing.categories.category == 'undefined') return '';
            else if (typeof listing.categories.category[0] == 'undefined') return '';
            else if (typeof listing.categories.category[0].name == 'undefined') return '';
            else return listing.categories.category[0].name.replace(" ","-").replace(" ","-");
        }

        /**
         * @memberof apiConfig
         * @method getBusName
         * @name getBusName
         * @description get business name from listing
         *
         * @param {object} listing Listing object
         */
        function getBusName(listing) {
            if (typeof listing == 'undefined') return '';
            else if (typeof listing.name == 'undefined') return '';
            else return listing.name;
        }
        /**
         * @memberof apiConfig
         * @method getState
         * @name getState
         * @description get state from listing
         *
         * @param {object} listing Listing object
         */
        function getState(listing) {
            if (typeof listing == 'undefined') return '';
            else if (typeof listing.addresses == 'undefined') return '';
            else if (typeof listing.addresses.address == 'undefined') return '';
            else if (typeof listing.addresses.address[0] == 'undefined') return '';
            else if (typeof listing.addresses.address[0].state == 'undefined') return '';
            else return listing.addresses.address[0].state;
        }
        /**
         * @memberof apiConfig
         * @method getPostcode
         * @name getPostcode
         * @description get post code from listing
         *
         * @param {object} listing Listing object
         */
        function getPostcode(listing) {
            if (typeof listing == 'undefined') return '';
            else if (typeof listing.addresses == 'undefined') return '';
            else if (typeof listing.addresses.address == 'undefined') return '';
            else if (typeof listing.addresses.address[0] == 'undefined') return '';
            else if (typeof listing.addresses.address[0].postCode == 'undefined') return '';
            else return listing.addresses.address[0].postCode;
        }
        /**
         * @memberof apiConfig
         * @method getSuburb
         * @name getSuburb
         * @description get suburb from listing
         *
         * @param {object} listing Listing object
         */
        function getSuburb(listing) {
            if (typeof listing == 'undefined') return '';
            else if (typeof listing.addresses == 'undefined') return '';
            else if (typeof listing.addresses.address == 'undefined') return '';
            else if (typeof listing.addresses.address[0] == 'undefined') return '';
            else if (typeof listing.addresses.address[0].suburb == 'undefined') return '';
            else return listing.addresses.address[0].suburb;
        }
        /**
         * @memberof apiConfig
         * @method getRating
         * @name getRating
         * @description get rating from listing
         *
         * @param {object} listing Listing object
         */
        function getRating(listing) {
            if (typeof listing == 'undefined') return 0;
            else if (typeof listing.statistics == 'undefined') return 0;
            else if (typeof listing.statistics.rating == 'undefined') return 0;
            else return listing.statistics.rating;
        }
        /**
         * @memberof apiConfig
         * @method getListType
         * @name getListType
         * @description check if is paid or unpaid business
         *
         * @param {object} listing Listing object
         */
        function getListType(listing) {
            if (typeof listing == 'undefined') return 'unpaid';
            else if (typeof listing.paid == 'undefined') return 'unpaid';
            else {
                if (listing.paid) return 'paid';
                else return 'unpaid';
            }
        }
        /**
         * @memberof apiConfig
         * @method getBdpSsn
         * @name getBdpSsn
         * @description get ssn ad
         *
         * @param {object} listing Listing object
         */
        function getBdpSsn(listing) {
            var category = getCategory(listing);
            var busname = getBusName(listing);
            var state = getState(listing);
            var suburb = getSuburb(listing);
            var rating = getRating(listing);
            var listtype = getListType(listing);
            var postcode = getPostcode(listing);
            if (debugAdVariables === true) {
                $log.debug('AD VARIABLES (bdp)');
                $log.debug('===============================');
                $log.debug('category: ' + category);
                $log.debug('busname: ' + busname);
                $log.debug('state: ' + state);
                $log.debug('suburb: ' + suburb);
                $log.debug('rating: ' + rating);
                $log.debug('listtype: ' + listtype);
                $log.debug('postcode: ' + postcode);
                $log.debug('adBDPpos2DivID: bdpssn');
            }
            // vm.elem.empty();

            var tp = '<script type="text/javascript">' +
                'var category="' + category + '";' +
                'var busname="' + busname + '";' +
                'var state="' + state + '";' +
                'var suburb="' + suburb + '";' +
                'var rating="' + rating + '";' +
                'var listtype="' + listtype + '";' +
                'var adBDPpos2DivID="bdpssn";' +
                'var postcode="' + postcode + '";' +
                'var debugAdVariables="' + debugAdVariables + '";';
            if (listtype == 'unpaid') {
                tp = tp + 'var has_ssn_bottom="true";' +
                    '</script>';
            } else {
                tp = tp + '</script>';
            }
            return tp;
        }

        function setDesktopArrowIndex(index) {
            service.desktopArrowIndex = index;
        }

        function getDesktopArrowIndex() {
            return service.desktopArrowIndex;
        }

        function setdesktopNextIncrement() {
            if (service.desktopNextIncrement == 5) {
                service.desktopNextIncrement = 3;
            } else {
                service.desktopNextIncrement = 5;
            }
        }

        function getdesktopNextIncrement() {
            return service.desktopNextIncrement;
        }

        return service;
    }

})();
