(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReviewLikeDislike
     * @description Directive enable user to like or dislike review.
     *
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   reviewsService            Factory what handle the API requests for reviews
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   apiConfig                 Api configuration factory
     *
     * @example
     * <bdp-review-like-dislike review-data="[object]"></bdp-review-like-dislike>
     */
    .directive('bdpReviewLikeDislike', bdpReviewLikeDislike);

  /** @ngInject */
    function bdpReviewLikeDislike() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdpreviewlikedislike.html',
            scope: {
                reviewData: '=',
            },
            controller: ['authService', 'reviewsService', 'modalFactory', 'apiConfig', '$modal', 'windowScroll', BdpReviewLikeDislikeController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /**
     * @memberof bdpReviewLikeDislike
     * @method bdpReviewLikeDislike_BdpReviewLikeDislikeController
     * @name bdpReviewLikeDislike_BdpReviewLikeDislikeController
     * @description Directive controller
     *
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   reviewsService            Factory what handle the API requests for reviews
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   apiConfig                 Api configuration factory
     *
     */

    /** @ngInject */
        function BdpReviewLikeDislikeController(authService, reviewsService, modalFactory, apiConfig, $modal, windowScroll) {
            var vm = this;
            vm.actionInProgress = false;
            


      /**
       * @memberof bdpReviewLikeDislike
       * @method likeReview
       * @name likeReview
       * @description If user is authenticated inform reviewService of like action. Display modal on response.
       *
       * @param {object} reviewData   review object
       */
            this.likeReview = function(reviewData) {
                if (vm.actionInProgress == true) {
                    return false;
                }
                vm.actionInProgress = true;
                if (angular.isUndefined(reviewData)) {
                    reviewData = this.reviewData;
                }

                if (apiConfig.userIsAuthenticated()) {
                    reviewsService.likeReview(reviewData.id, reviewData, vm.actionInProgress);
                } else {
                    this.showAuthModal();
                }

                setTimeout(function() {
                    vm.actionInProgress = false;
                }, 1000);
            };
            
            this.showAuthModal = function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                    controller: 'ModalAuthPromptController',
                    controllerAs: 'vm',
                    size: 'dialog_size_small',
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
            };


      /**
       * @memberof bdpReviewLikeDislike
       * @method dislikeReview
       * @name dislikeReview
       * @description If user is authenticated inform reviewService of dislike action. Display modal on response.
       *
       * @param {object} reviewData   review object
       */
            this.dislikeReview = function(reviewData) {
                if (vm.actionInProgress == true) {
                    return false;
                }
                vm.actionInProgress = true;

                if (angular.isUndefined(reviewData)) {
                    reviewData = this.reviewData;
                }

                if (apiConfig.userIsAuthenticated()) {
                    reviewsService.dislikeReview(reviewData.id, reviewData, vm.actionInProgress);
                } else {
                    this.showAuthModal();
                }
                setTimeout(function() {
                    vm.actionInProgress = false;
                }, 1000);
            };


      /**
       * @memberof bdpReviewLikeDislike
       * @method getLikes
       * @name getLikes
       * @description Returns number of likes.
       * @returns {String} _numberOfLikes
       */
            this.getLikes = function(_numberOfLikes) {
                if (angular.isUndefined(_numberOfLikes)) {
                    _numberOfLikes = this.reviewData.statisticsLike;
                }
                if (!_numberOfLikes) {
                    return '';
                }
                return _numberOfLikes;
            };


      /**
       * @memberof bdpReviewLikeDislike
       * @method getDislikes
       * @name getDislikes
       * @description Returns number of dislikes.
       * @returns {String} _numberOfDislikes
       */
            this.getDislikes = function(_numberOfDislikes) {
                if (angular.isUndefined(_numberOfDislikes)) {
                    _numberOfDislikes = this.reviewData.statisticsDislike;
                }
                if (!_numberOfDislikes) {
                    return '';
                }
                return _numberOfDislikes;
            };
        }
    }
})();
