(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpAddReview
     * @description Directive for adding review for current listing.
     *
     * @param {service}   $log                      Angular console log wrapper
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   serviceListings           Factory what handle the API requests for listing
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   DTM           TL tracking services
     * @param {service}   $window                   Angular window wrapper
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {factory}   Iovation                  Factory service provider method for handle integration with Iovation platforms
     * @param {service}   swear                     Swear service provide collection of blocked words to enable client side user's input validation.
     *
     * @example
     * <bdp-add-review reviews-data="[object]"></bdp-add-review>
     */
    .directive('bdpAddReview', bdpAddReview)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name focusCurrentBox
     * @description Directive scrolls and focus to add review input box after user click add review button.
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *
     * @example
     * <div focus-current-box></div>
     */
    .directive('focusCurrentBox', ['platformService','$window','$document', focusCurrentBox]);

    function focusCurrentBox(platformService, $window, $document) {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                if (platformService.isMobile()) {
                    setTimeout(angular.bind(this, function(__e) {
                        var _currentWinScroll = $window.scrollY;
                        var _scrollTo = (__e.prop('offsetTop') - 213);
                        $document.scrollTop(scrollTo);
                    }, elem), 200);
                }
            },
            scope: {
                vm: '=',
            },
        };
    }

    /** @ngInject */
    function bdpAddReview() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpaddreview/bdpaddreview.html',
            scope: {
                reviewsData: '=',
                enteredComp: '=',
            },
            controller: ['$log', 'apiConfig', 'serviceListing', 'modalFactory', '$modal', 'windowScroll', 'authService', 'DTM', '$rootScope', '$window', 'platformService', 'Iovation', 'runningCompetition', 'swear', '$interval', '$localstorage', '$document', '$cookies', BdpAddReviewController],

            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */


        function BdpAddReviewController($log, apiConfig, serviceListing, modalFactory, $modal, windowScroll, authService, DTM, $rootScope, $window, platformService, Iovation, runningCompetition, swear, $interval, $localstorage, $document, $cookies) {
            var vm = this;

            this.resetErrors = function() {
                this.errMsg = '';
                this.textNotValid = false;
                this.minLengthError = false;
                this.maxLengthError = false;
            }

            this.resetComment = function() {
                this.userComment = '';
                this.typedComment = '';
            }

            this.compRunning = apiConfig.compToggle();
            this.apiConfig = apiConfig;
            this.selectedRating = {};
            this.selectedRating.value = 0;
            this.formActive = false;
            this.addingReview = false;
            this.ratingNotValid = false;
            vm.authModalOpened = false;

            var userData = this.apiConfig.userData;
            if(userData != ''){
                var reviews = userData.data.data.statistics.reviews;
                var moderationStatus = userData.data.data.status.moderationStatus;
                vm.secondCommentPendingUser = (reviews == 1) && (moderationStatus == 'pending');
            } else {
                vm.secondCommentPendingUser = false;
            }

            if ($cookies.get('authenticateFromBdpReview')) {
                this.reviewExpanded = true;
                this.formActive = true;
            }

            this.resetErrors();
            this.resetComment();

            /**
             * @memberof bdpAddReview
             * @method loginUser
             * @name loginUser
             * @description Call login modal and login user.
             */
            this.loginUser = function() {
                if (vm.authModalOpened == false) {
                    vm.authModalOpened = true;
                    var that = this;
                    setTimeout(function() {
                        var authInstance = authService.authModal(modalFactory, {});
                        authInstance.result.finally(function() {
                            vm.authModalOpened = false;
                            if (that.apiConfig.userIsAuthenticated()) {
                                vm.continueWithDetails();
                            }
                        });
                    });
                }
            };

            /**
             * @memberof bdpAddReview
             * @method showAddReviewPrompt
             * @name showAddReviewPrompt
             * @description Display add review prompt if user is not logged in.
             */
            this.showAddReviewPrompt = function() {
                return true;
            };

            this.addReviewLogin = function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/reviews/bdpaddreviewlogin/bdpaddreviewlogin.html',
                    controller: 'ModalAddReviewLoginController',
                    controllerAs: 'vm',
                    size: 'dialog_size_small',
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
            };

            /**
             * @memberof bdpAddReview
             * @method showErrorBlock
             * @name showErrorBlock
             * @description Display error if user already add review.
             */
            this.showErrorBlock = function() {
                if (!apiConfig.userIsAuthenticated()) {
                    return false;
                }

                if (this.reviewsData && apiConfig.userOwnsBusiness() == 1) {
                    return false;
                }

                if (this.reviewsData && this.reviewsData.alreadyAddedReview) {
                    return true;
                }
                return false;
            };


            /**
             * @memberof bdpAddReview
             * @method showAddReviewBlock
             * @name showAddReviewBlock
             * @description Display AddReviewBlock block.
             * @return {Boolean} show/hide add review block
             */
            this.showAddReviewBlock = function() {
                if (!apiConfig.userIsAuthenticated()) {
                    return true; // GRO-8 - Logged out user can write review
                }

                if (this.reviewsData && apiConfig.userOwnsBusiness() == 1) {
                    return false;
                }

                if (this.reviewsData && (!this.reviewsData.addedReviewThankYouMessage && !this.reviewsData.alreadyAddedReview)) {
                    return true;
                }
                return false;
            };


            /**
             * @memberof bdpAddReview
             * @method showThankYouBlock
             * @name showThankYouBlock
             * @description Display AddReviewBlock block.
             * @return {Boolean} show/hide thank you block
             */
            this.showThankYouBlock = function() {
                if (!apiConfig.userIsAuthenticated()) {
                    return false;
                }

                if (this.reviewsData && (this.reviewsData.addedReviewThankYouMessage && !this.reviewsData.alreadyAddedReview)) {
                    return true;
                }

                return false;
            };


            /**
             * @memberof bdpAddReview
             * @method continueWithDetails
             * @name continueWithDetails
             * @description Continue displaying details, track user interaction.
             */
            this.continueWithDetails = function() {
                if (this.apiConfig.userIsAuthenticated()) {
                    this.reviewExpanded = true;
                    this.formActive = true;
                    Iovation.initBlackBox(); // init the blackbox only now
                    DTM.trackAnalyticOnClick('add review:start');
                    return;
                }
                // if not login, open modal for login sign up
                DTM.trackAnalyticOnClick('add review:not logged in');
                this.addReviewLogin();
            };

            // adding 2 layers of time check, would be good to use ViewContentLoad along - Ali
            angular.element(document).ready(function() {
                setTimeout(function() {
                    
                    if ($cookies.get('authenticateFromBdpReview')) {
                        $cookies.remove('authenticateFromBdpReview');
                        $document.scrollTo(0, angular.element(document.querySelector('#review-area-form')).prop('offsetTop'), 100);
                    }
                    
                    var saveReviewObjectGet = $localstorage.getItem('save_review');

                    if (saveReviewObjectGet) {
                        saveReviewObjectGet = JSON.parse(saveReviewObjectGet);
                        var currentPath = window.location.href;

                        if (currentPath == saveReviewObjectGet.path) {
                            vm.continueWithDetails();

                            // Only if logged in
                            if (apiConfig.userIsAuthenticated() || apiConfig.userPending()) {
                                // If review has been submitted
                                var reviewSubmitted = $localstorage.getItem('save_review_submitted');
                                if (reviewSubmitted) {
                                    // Scroll to review
                                    var _scrollTo = angular.element(document.querySelector('#reviews')).prop('offsetTop');
                                    /*angular.element(document.querySelector('body,html'))[0].animate({
                                        scrollTop: _scrollTo,
                                    }, 'slow');*/
                                    $document.scrollTo(0, _scrollTo, 1000);
                                    // Trigger submit review button after scroll
                                    setTimeout(function() {
                                        angular.element(document.querySelector('.submit-review')).triggerHandler('click');
                                        console.log('FIRED from bdp ADD');
                                    }, 1500);
                                }
                            }
                        }
                    }
                });
            });

            /**
             * @memberof bdpAddReview
             * @method cancelAddingReview
             * @name cancelAddingReview
             * @description Handle cancelling of adding review.
             */
            this.cancelAddingReview = function(bool) {
                if (this.addingReview) {
                    return;
                }
                this.resetComment();
                this.resetErrors();
                this.selectedRating.value = 0;
                this.reviewExpanded = false;
                this.formActive = false;
                this.ratingNotValid = false;
                vm.enteredComp = bool;
                $localstorage.removeItem('save_review');
                $localstorage.removeItem('save_review_submitted');
                $localstorage.removeItem('save_review_rating');
                var _scrollTo = angular.element(document.querySelector('#reviews')).prop('offsetTop');
               /* angular.element(document.querySelector('body,html'))[0].animate({
                    scrollTop: _scrollTo,
                }, 'fast');*/
                $document.scrollToElement(angular.element(document.querySelector('#reviews')), 0, 10);
            };

            /**
             * @memberof bdpAddReview
             * @method cancelReview
             * @description Handle cancelling of adding review with event.
             * @param {*} bool
             */
            this.cancelReview = function(bool) {
                this.cancelAddingReview(bool);
                DTM.trackAnalyticOnClick('review cancel');
            };


            /**
             * @memberof bdpAddReview
             * @method reviewsExist
             * @name reviewsExist
             * @description Check does reviews already exists.
             * @return {Boolean} reviewsExist
             */
            this.reviewsExist = function() {
                if (angular.isDefined(this.reviewsData) && angular.isDefined(this.reviewsData.reviewList) && this.reviewsData.reviewList.length) {
                    return true;
                }
                return false;
            };


            /**
             * @memberof bdpAddReview
             * @method manageAddReviewResponse
             * @name manageAddReviewResponse
             * @description Handle displaying of response to adding review.
             */
            this.manageAddReviewResponse = function(_resp) {
                $log.debug(_resp);
                this.addingReview = false;

                if (_resp.status == 'error') {
                    if (_resp.code == 410810001) {
                        this.addingReview = false;
                    } else {
                        this.formActive = false;
                        this.reviewExpanded = false;

                        angular.extend(this.reviewsData, {
                            alreadyAddedReview: true,
                        });
                    }
                    this.textNotValid = true;
                    this.errMsg = _resp.data.data.meta.messages.pop();
                } else {
                    var _revObject = {
                        id: _resp.data.id,
                        isActive: 'pending',
                        isApproved: 'inactive',
                        dateCreated: '',
                        rating: _resp.config.data.rating,
                        userName: '',
                        userAvatar: '',
                        userComment: _resp.config.data.text,
                        statisticsLike: 0,
                        statisticsDislike: 0,
                    };

                    angular.extend(this.reviewsData, {
                        addedReviewThankYouMessage: true,
                        userReview: _revObject,
                    });

                    this.cancelAddingReview(vm.enteredComp);
                }
            };


            /**
             * @memberof bdpAddReview
             * @method isRatingNotInvalid
             * @name isRatingNotInvalid
             * @description Validate user rating.
             * @return {Boolean} isRatingNotInvalid
             */
            this.isRatingNotInvalid = function() {
                if (!this.selectedRating || !this.selectedRating.value) {
                    this.ratingNotValid = true;
                    return true;
                }
                this.ratingNotValid = false;
                return false;
            };

            /**
             * @memberof bdpAddReview
             * @method isNotInvalid
             * @name isNotInvalid
             * @description Validate user comment.
             * @return {Boolean} isCommentNotInvalid
             */
            this.isNotInvalid = function() {
                this.textNotValid = false;
                if(this.typedComment.trim().length < 50) {
                    this.textNotValid = true;
                    this.minLengthError = true;
                } else if(this.typedComment.trim().length > 5000) {
                    this.textNotValid = true;
                    this.maxLengthError = true;
                }
                return this.textNotValid;
            };

            /**
             * @memberof bdpAddReview
             * @method checkIfUserPending
             * @name checkIfUserPending
             * @description Return true if user pending
             * using Different methods to make it work,
             this is Doing different ways to get vm.userPending as some stuffs not working Cross browsing, don't ask me why :)
             */

            angular.element(window).on('user-authenticated', function() {
                vm.userPending = apiConfig.userPending();
                vm.setUserPending();
            });

            $rootScope.$on('user-pending', function() {
                vm.userPending = apiConfig.userPending();
                vm.setUserPending();
            });

            vm.setUserPending = function() {
                setTimeout(function() {
                    vm.userPending = apiConfig.userPending();
                });
            };
            vm.setUserPending();
            // This is a bit hacky, but Event Dispatcher didn't work properly, so will be more investigated later on____
            vm.userPendingCheck = function() {
                vm.userPending = apiConfig.userPending();
                if (angular.isDefined(vm.userPending) || !apiConfig.userIsAuthenticated()) {
                    $interval.cancel(vm.timer);
                };
            };


            vm.timerForPending = function() {
                vm.timer = $interval(vm.userPendingCheck, 100);
            }();

            /**
             * @memberof bdpAddReview
             * @method addReview
             * @name addReview
             * @description Display add review modal and handle adding of review, and tracking of user activity.
             */
            this.addReview = function() {
                this.resetErrors();
                var _textCheck = this.isNotInvalid();
                var _ratingCheck = this.isRatingNotInvalid();
                
                if (_textCheck || _ratingCheck) {
                    return;
                }

                // added for the Competition
                var _reviewData;
                if (vm.enteredComp) {
                    _reviewData = {
                        'text': this.typedComment,
                        'rating': this.selectedRating.value,
                        'fp': Iovation.fingerPrint,
                        'comp-name': runningCompetition.competition.name,
                    };
                    DTM.trackAnalyticOnClick('ENTERED competition ---' + runningCompetition.competition.name);
                } else {
                    _reviewData = {
                        'text': this.typedComment,
                        'rating': this.selectedRating.value,
                        'fp': Iovation.fingerPrint,
                    };
                }


                // If logged in
                if (apiConfig.userIsAuthenticated()) {
                    // Submit the review
                    serviceListing.addReview(this.reviewsData.id, _reviewData, this.reviewsData).then(angular.bind(this, this.manageAddReviewResponse));
                    this.addingReview = true;

                    // Remove saved review
                    $localstorage.removeItem('save_review');
                    $localstorage.removeItem('save_review_submitted');
                    $localstorage.removeItem('save_review_rating');

                    // Track that user has submitted review
                    DTM.trackAnalyticOnClick('add review:completed');
                    $document.scrollToElement(angular.element(document.querySelector('#reviews')), 0, 1000);

                    // If not logged in
                } else {
                    // Track that review has been submitted
                    var saveReviewObjectSubmitted = true;
                    $localstorage.setItem('save_review_submitted', JSON.stringify(saveReviewObjectSubmitted));

                    // Set review as false so button loading stops
                    this.addingReview = false;

                    // Trigger the login prompt
                    this.addReviewLogin();

                    // Track that user is not logged in but is trying to submit review
                    DTM.trackAnalyticOnClick('add review:not logged in');
                }
            };
        }
    }
})();
