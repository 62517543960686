(function() {
    'use strict';

    angular
    .module('truelocal')
    .directive('tileAdverts', tileAdverts);

  /** @ngInject */
    function tileAdverts() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/tileadverts/tileadverts.html',
            scope: {
                advertsItem: '=adverts',
            },
            controller: TileAdvertsController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function TileAdvertsController() {
            var vm = this;
            vm.adverts = (vm.advertsItem.inventory || []).slice(0, 6);
        }
    }
})();
