(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('searchTradie', searchTradie);

    /** @ngInject */
    function searchTradie(apiConfig) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/tradiepage/search/search.html',
            scope: {
                city: '=',
            },
            controller: searchTradieController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function searchTradieController() {
            var vm = this;
        }
    }
})();
