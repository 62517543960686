(function() {
    'use strict';
    angular
        .module('truelocal')
        .constant('routerConfiguration', {
            states: {
                'homepage': false,
                'business': true,
                'find': false,
                'search': false,
                'search-location': false,
                'service': true,
            },
        });
})();
