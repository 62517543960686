(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpSimpleOpeninghours
     * @description Directive display business current opening status and hours when business is shown in banner items
     * "Similar in the area" or "Other nearby services". Current status can be:
     *
     *      Closed until (soonest opening day)
     *      Open today (if business is currently closed, but will be opened today)
     *      Currently open
     *
     * Pair of opening hours for describing day will be displayed with current status e.g.:
     *
     *      Open today
     *      5:00 pm - 9:00 pm
     *
     * @param {constant}  constantsSettings         Constant settings
     *
     * @example
     * <bdp-simple-openinghours opening-hours="[object]"></bdp-simple-openinghours>
     */
    .directive('bdpSimpleOpeninghours', BdpSimpleOpeningHours);

  /** @ngInject */
    function BdpSimpleOpeningHours() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpsimpleopeninghours/bdpsimpleopeninghours.html',
            scope: {
                openingHours: '=',
            },
            controller: ['constantsSettings', BdpSimpleOpeningHoursController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */

        function BdpSimpleOpeningHoursController(constantsSettings) {
            var now = new Date();


      /**
       * @memberof bdpSimpleOpeninghours
       * @method hasOpeningHours
       * @name hasOpeningHours
       * @description Returns true if business have opening hours.
       * @param {Object} openingHours
       * @returns {Boolean}
       */
            this.hasOpeningHours = function(openingHours) {
                if (angular.isDefined(openingHours)) {
                    this.openingHours = openingHours;
                }
                if (angular.isUndefined(this.openingHours)) return false;
                else return true;
            };


      /**
       * @memberof bdpSimpleOpeninghours
       * @method getNowDay
       * @name getNowDay
       * @description Return current day as string ("Monday"/"Tuesday"...)
       * @param {String}
       * @returns {String}
       */
            this.getNowDay = function(_now) {
                if (angular.isDefined(_now)) {
                    now = new Date(_now);
                }
                var day = now.getDay();
                var daysOfWeek = constantsSettings.daysOfWeekShort;
        // var daysOfWeek = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
                return daysOfWeek[day];
            };


      /**
       * @memberof bdpSimpleOpeninghours
       * @method currentStatus
       * @name currentStatus
       * @description Return current opening status (Currently open, Open today, Closed until etc...) and setting
       * array of currently opened hours.
       * @returns {String}
       */
            this.currentStatus = function(openingHours, _now) {
                if (angular.isDefined(_now)) {
                    now = new Date(_now);
                }
                if (angular.isDefined(openingHours)) {
                    this.openingHours = openingHours;
                }
                var status = 'Closed';
                var currentDay = this.getNowDay();
                angular.forEach(this.openingHours, function(objectDay) {
                    if (objectDay.day == currentDay) {
                        if (angular.isDefined(objectDay.hours[0])) {
              // create date object with today date but set the hours from API for open and close
                            var startArray = objectDay.hours[0].open.split(' ');
                            var startHourMinute = startArray[0].split(':');
                            var startHour = startHourMinute[0];
                            if (startHour.substring(0, 1) == '0' && startHour != '0' && startHour != '00') startHour = startHour.substring(1);
                            startHour = parseInt(startHour);
                            var startMinute = startHourMinute[1];
                            if (startMinute.substring(0, 1) == '0' && startMinute != '0' && startMinute != '00') startMinute = startMinute.substring(1);
                            startMinute = parseInt(startMinute);
                            if (startArray[1] === 'PM' || startArray[1] === 'pm') {
                                if (startHour !== 12) {
                                    startHour = startHour + 12;
                                }
                            }
                            if (startArray[1] === 'AM' || startArray[1] === 'am') {
                                if (startHour == 12) {
                                    startHour = 0;
                                }
                            }

                            var stopArray = objectDay.hours[0].close.split(' ');
                            var stopHourMinute = stopArray[0].split(':');
                            var stopHour = stopHourMinute[0];
                            if (stopHour.substring(0, 1) == '0' && stopHour != '0' && stopHour != '00') stopHour = stopHour.substring(1);
                            stopHour = parseInt(stopHour);
                            var stopMinute = stopHourMinute[1];
                            if (stopMinute.substring(0, 1) == '0' && stopMinute != '0' && stopMinute != '00') stopMinute = stopMinute.substring(1);
                            stopMinute = parseInt(stopMinute);
                            if (stopArray[1] === 'PM' || stopArray[1] === 'pm') {
                                if (stopHour !== 12) {
                                    stopHour = stopHour + 12;
                                }
                            }
                            if (stopArray[1] === 'AM' || stopArray[1] === 'am') {
                                if (stopHour == 12) {
                                    stopHour = 0;
                                }
                            }
                            var start;
                            if (angular.isDefined(_now)) {
                                start = new Date(_now);
                            } else {
                                start = new Date();
                            }
                            start.setHours(startHour);
                            start.setMinutes(startMinute);
                            var stop;
                            if (angular.isDefined(_now)) {
                                stop = new Date(_now);
                            } else {
                                stop = new Date();
                            }
                            stop.setHours(stopHour);
                            stop.setMinutes(stopMinute);
              // check if now is between start and stop (start and stop are generated by open and close)
                            if (now >= start && now <= stop) {
                                status = 'Currently open';
                            }
                            if (now < start) {
                                status = 'Open today';
                            }
                        }
                    }
                });

                if (status == 'Closed') {
                    var daysOfWeekStandard = {
                        'Sun': 'Sunday',
                        'Mon': 'Monday',
                        'Tue': 'Tuesday',
                        'Wed': 'Wednesday',
                        'Thu': 'Thursday',
                        'Fri': 'Friday',
                        'Sat': 'Saturday',
                    };
                    var daysOfWeek = constantsSettings.daysOfWeekShort;
                    var position = 1 + parseInt(daysOfWeek.indexOf(currentDay));
                    var daysOfWeekSorted = daysOfWeek.slice(position, daysOfWeek.length).concat(daysOfWeek.slice(0, position));

                    var findNextWorkDay = function(daysOfWeekSorted, openingHours) {
                        for (var i = 0; i < daysOfWeekSorted.length; i++) {
                            for (var j = 0; j < openingHours.length; j++) {
                                if (openingHours[j].day == daysOfWeekSorted[i] && angular.isDefined(openingHours[j].hours[0])) {
                                    return daysOfWeekSorted[i];
                                }
                            }
                        }
                        return null;
                    };
                    var findResult = findNextWorkDay(daysOfWeekSorted, this.openingHours);
                    status = findResult ? status + ' until ' + daysOfWeekStandard[findResult] : status;
                }
                return status;
            };


      /**
       * @memberof bdpSimpleOpeninghours
       * @method todayWorkingHours
       * @name todayWorkingHours
       * @description Calculate opening hours for current day, and return it as array. Empty string if doesn't exist.
       * @param {Object} openingHours
       * @param {String} _now
       * @returns {Array | String}
       */
            this.todayWorkingHours = function(openingHours, _now) {
                if (angular.isDefined(_now)) {
                    now = new Date(_now);
                }
                if (angular.isDefined(openingHours)) {
                    this.openingHours = openingHours;
                }
                var currentDay = this.getNowDay();
                var workingHours = '';
                var daysOfWeek = constantsSettings.daysOfWeekShort;
                var position = parseInt(daysOfWeek.indexOf(currentDay));
                var daysOfWeekSorted = daysOfWeek.slice(position, daysOfWeek.length).concat(daysOfWeek.slice(0, position));
                var findClosestWorkHours = function(daysOfWeekSorted, openingHours) {
                    for (var i = 0; i < daysOfWeekSorted.length; i++) {
                        for (var j = 0; j < openingHours.length; j++) {
                            if (openingHours[j].day == daysOfWeekSorted[i]) {
                                return openingHours[j].hours[0].open + ' - ' + openingHours[j].hours[0].close;
                            }
                        }
                    }
                    return null;
                };
                workingHours = findClosestWorkHours(daysOfWeekSorted, this.openingHours);
                return workingHours ? workingHours : '';
            };
        }
    }
})();
