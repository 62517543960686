(function () {
  "use strict";

  angular
    .module("truelocal")

    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name BdpEmailABusinessController
     * @description Display link with option to share current business by e-mail on it's bdp. Opens modal for sending
     * of e-mails. It is responsible for user's form validation before sending e-mail to business.
     *
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   swear                     Swear service provide collection of blocked words to enable client side user's input validation.
     * @param {service}   $http                     angular http service
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {object}    listing                   Listing object
     * @param {service}   $rootScope                Every application has a single root scope. All other scopes are descendant scopes of the root scope. Scopes provide separation between the model and the view, via a mechanism for watching the model for changes. They also provide event emission/broadcast and subscription facility. See the developer guide on scopes.
     * @param {service}   $modalInstance            ui-bootstrap modal event handler wrapper
     */

    .controller("BdpEmailABusinessController", [
      "$log",
      "swear",
      "$http",
      "apiConfig",
      "listing",
      "$modalInstance",
      "$rootScope",
      "DTM",
      "footerUrls",
      "grecaptchaService",
      "validatorService",
      "urlValidatorService",
      BdpEmailABusinessController,
    ]);

  /** @ngInject */
  function BdpEmailABusinessController(
    $log,
    swear,
    $http,
    apiConfig,
    listing,
    $modalInstance,
    $rootScope,
    DTM,
    footerUrls,
    grecaptchaService,
    validatorService,
    urlValidatorService
  ) {
    var eb = this;

    $rootScope.$on("$locationChangeStart", function () {
      $modalInstance.close();
    });

    eb.togglePrivacyNotice = false;
    eb.privacyPolicyUrl = footerUrls.cmsPrivacy.url;
    eb.listing = listing;
    eb.userData = apiConfig.userData;
    eb.validApiPhone = true;

    eb.contents = {
      errors: [],
    };

    eb.form = {
      senderEmail: eb.userData.hasOwnProperty("data")
        ? eb.userData.data.data.email
        : "",
      name: eb.userData.hasOwnProperty("data")
        ? eb.userData.data.data.firstName
        : "",
      message: "",
    };

    eb.allSwars = 0;

    var test = {
      $viewValue: "fs",
      $validators: {},
      $asyncValidators: {},
      $parsers: [],
      $formatters: [null],
      $viewChangeListeners: [],
      $untouched: false,
      $touched: false,
      $pristine: false,
      $dirty: true,
      $valid: false,
      $invalid: true,
      $error: { pattern: true, email: true },
      $name: "senderEmail",
      $options: null,
    };

    eb.states = {
      signingIn: false,
      httpError: false,
      showForm: true,
      isLikelyBot: false,
    };

    var MINIMUM_V3_SCORE = 0.7;

    eb.reCaptchaRequest = {
      token: "",
      version: 3,
    };

    // Initialize to use version 3 token
    grecaptchaService.getResponse("v3").then(function (token) {
      eb.reCaptchaRequest.token = token;
    });

    // Render Checkbox
    grecaptchaService.renderCheckbox();

    eb.grecaptchaSiteKey = grecaptchaService.getSiteKey("v2");

    function scrollToTop() {
      angular.element(
        document.querySelector(
          ".modal-dialog:not(.gallery-modal-open) > .modal-content"
        )
      )[0].scrollTop = 0;
      angular.element(
        document.querySelector(
          ".modal-dialog:not(.gallery-modal-open) > .modal-content"
        )
      )[0].scrollTop = 1;
    }

    /**
     * @memberof BdpEmailABusinessController
     * @events specialFocus
     * @name specialFocus
     * @description Configure validation options.
     */
        eb.specialFocus = function() {
            if (eb.contents.errors.length > 0) {
                eb.contents.errors = [];
                eb.validApiPhone = true;
            }

            return eb.validApiPhone;
        };

    /**
     * @memberof BdpEmailABusinessController
     * @events isCheckboxToggled
     * @name isCheckboxToggled
     * @description Validates if the checkbox v2 token is not empty
     */
    eb.isCheckboxToggled = function () {
      if (eb.reCaptchaRequest.version !== 3 && eb.states.isLikelyBot) {
        return !!grecaptchaService.getResponse("v2");
      }
    };

    /**
     * @memberof BdpEmailABusinessController
     * @events actions
     * @name actions
     * @description Actions on e-mail form.
     */
    eb.actions = {
      /**
       * @memberof BdpEmailABusinessController
       * @method isValid
       * @name isValid
       * @description Validate form.
       * @param {Object} field
       * @returns {Boolean} hasSwear
       */
      isValid: function (field) {
        if (field) {
          return field.$touched && field.$invalid;
        } else {
          return false;
        }
      },

      /**
       * @memberof BdpEmailABusinessController
       * @method hasSwear
       * @name hasSwear
       * @description Validate swear not entered.
       * @param {Object} field
       * @returns {Boolean} hasSwear
       */
      hasSwear: function (field) {
        if (field) {
          var value = field.$viewValue || "";
          var isSwear = swear.hasSwearing(value);
          return isSwear;
        } else {
          return false;
        }
      },

      /**
       * Checks whether the field value contains any HTML tags.
       * @memberof BdpEmailABusinessController
       * @method
       * @name containsHtml
       * @param {Object} field - The field object whose value needs to be validated.
       * @returns {boolean} Returns true if the field value contains HTML tags, false otherwise.
       */
      containsHtml: function (field) {
        if (field) {
          var value = field.$viewValue || "";
          return validatorService.containsHtml(value);
        } else {
          return false;
        }
      },

      /**
       * Checks whether the field value contains any URLs.
       * @memberof BdpEmailABusinessController
       * @method
       * @name containsUrl
       * @param {Object} field - The field object whose value needs to be validated.
       * @returns {boolean} Returns true if the field value contains URLs, false otherwise.
       */
      containsUrl: function (field) {
        if (field) {
          var value = field.$viewValue || "";
          return urlValidatorService.containsUrl(value);
        } else {
          return false;
        }
      },

      /**
       * @memberof BdpEmailABusinessController
       * @method submit
       * @name submit
       * @description Submit e-mail form.
       */
      submit: function () {
        eb.states.signingIn = true;
        eb.states.httpError = false;
        eb.contents.errors.length = 0;

        function handleApiError(response) {
          eb.states.signingIn = false;
          eb.states.httpError = true;
          var errors = [];

          if (
            response.data &&
            response.data.meta &&
            response.data.meta.errors
          ) {
            if (angular.isDefined(response.data.meta.errors.phone)) {
              eb.validApiPhone = false;
            }

            Object.keys(response.data.meta.errors).forEach(function (key) {
              if (key !== "length") {
                errors.push(response.data.meta.errors[key]);
              }
            });
          }

          if (response.data.message !== "") {
            errors.push(response.data.message);
          }

          var messages =
            (response &&
              response.data &&
              response.data.meta &&
              response.data.meta.messages) ||
            [];
          eb.contents.errors = errors.concat(messages);

          if (eb.contents.errors.length === 0) {
            eb.contents.errors = [
              "Hmph... looks like there was an error when sending your message. Please try again.",
            ];
          }
        }

        function handleRecaptchaResponse(response) {
          eb.reCaptchaRequest.token = "";

          if (!response || !response.data || !response.data.data) {
            return;
          }

          if (
            response.data.data.score < MINIMUM_V3_SCORE &&
            response.data.data.version === 3
          ) {
            eb.states.isLikelyBot = true;
            eb.reCaptchaRequest.version = 2;

            // Remove validation failed error on v3 only
            eb.contents.errors.length = 0;
          } else if (
            response.data.data.score === 0.0 &&
            response.data.data.version === 2
          ) {
            eb.states.isLikelyBot = true;
          }
        }

        var payload = {
          name: eb.form.senderName,
          email: eb.form.senderEmail,
          phone: eb.form.senderContactNumber,
          message: eb.form.message,
          token: eb.reCaptchaRequest.token,
          version: eb.reCaptchaRequest.version,
        };

        // Get new token if checkbox is visible and empty
        if (
          (eb.states.isLikelyBot && payload.version === 2) ||
          (payload.version === 2 && payload.token === "")
        ) {
          payload.token = grecaptchaService.getResponse("v2");
          payload.version = 2;
          return makeApiRequest();
        }

        // Get new token if version 3 and empty
        if (payload.version === 3 && payload.token === "") {
          grecaptchaService.getResponse("v3").then(function (token) {
            payload.token = token;
            makeApiRequest();
          });
          return;
        }

        if (payload.version === 2 && payload.token === "") {
          eb.states.signingIn = false;
          eb.states.httpError = true;
          eb.contents.errors = ["Click the Recaptcha to proceed."];
          return;
        }

        makeApiRequest();

        function makeApiRequest() {
          $http
            .post(
              apiConfig.generate("listings", eb.listing.id, "email"),
              payload,
              apiConfig.getXAuthToken()
            )
            .then(function (response) {
              eb.states.signingIn = false;
              eb.states.showForm = false;
              scrollToTop();
              DTM.trackAnalyticOnClick("send advertiser email:completed");
            })
            .catch(function (error) {
              handleApiError(error);
              handleRecaptchaResponse(error);
            });
        }
      },
    };
  }
})();
