(function() {
    'use strict';

    angular
    .module('truelocal')

  /**
   * @memberof truelocal
   * @ngdoc directive
   * @name bdpHeader
   * @description Directive is holder of business detail page top header that display category, description,
   * business name, ratings, contact info, opening hours and address.
   */
  .directive('bdpHeader', BdpHeader);

  /** @ngInject */
    function BdpHeader(env, $log, apiConfig, $window, platformService) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpheader/bdpheader.html',
            scope: {
                bussinessDetails: '=',
                listing: '=',
            },
            controller: BdpHeaderController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpHeaderController(apiConfig, platformService, $location, $filter, $modal, $timeout, $scope, $rootScope, $window, windowScroll, scrollDetectWithTimeout, DTM, businessService) {
            var vm = this;
            this.showEditCoverTools = false;

      /**
       * @memberof bdpHeader
       * @name decidedAddreses
       * @description Array of business addresses.
       */
            vm.decidedAddreses = [];

            vm.matched = false;
            vm.platformService = platformService;

      /**
       * @memberof bdpHeader
       * @method decideAddress
       * @name decideAddress
       * @description Extract main address lines from data object and add it to decidedAddreses Array.
       * @returns {Array} decidedAddress
       */
            vm.decideAddress = function() {
                if (vm.matched == false) {
                    if (angular.isDefined(vm.listing.addresses) && angular.isDefined(vm.listing.addresses.address) && vm.listing.addresses.address.length > 0) {
                        angular.forEach(vm.listing.addresses.address, function(address) {
                            if (angular.isDefined(address.match)) {
                                if (address.match == true) {
                                    vm.matched = true;
                                    vm.decidedAddreses.push(address);
                                }
                            }
                        });
                    }
                }
                if (vm.matched == false) {
                    if (angular.isDefined(vm.listing.serviceLocations) && angular.isDefined(vm.listing.serviceLocations.serviceLocation)) {
                        angular.forEach(vm.listing.serviceLocations.serviceLocation, function(location) {
                            if (angular.isDefined(location.match)) {
                                if (location.match == true) {
                                    vm.matched = true;
                                    vm.decidedAddreses.push(location);
                                }
                            }
                        });
                    }
                }
                return vm.decidedAddreses;
            };


      /**
       * @memberof bdpHeader
       * @name currentSuburb
       * @description Current suburb.
       */
            vm.currentSuburb = '';


      /**
       * @memberof bdpHeader
       * @method getCategoryUrl
       * @name getCategoryUrl
       * @description Returns url to current business category.
       * @returns {String} categoryUrl
       */
            vm.getCategoryUrl = function(listing) {
                if (angular.isUndefined(vm.listing)) {
                    vm.listing = listing;
                }
        // get the category url
                var url = '#';
                if (angular.isDefined(vm.listing) && angular.isDefined(vm.listing.breadcrumbs) && angular.isDefined(vm.listing.breadcrumbs.category) && vm.listing.breadcrumbs.category != null) {
                    url = $location.protocol() + '://' + $location.host() + '/search/' + vm.listing.breadcrumbs.category.value;
                }
                if (angular.isDefined(vm.listing) && angular.isDefined(vm.listing.breadcrumbs) && angular.isDefined(vm.listing.breadcrumbs.category) && vm.listing.breadcrumbs.category != null && angular.isDefined(vm.listing.breadcrumbs.suburb) && vm.listing.breadcrumbs.suburb != null) {
                    url = url + '/' + vm.listing.breadcrumbs.suburb.value;
                }

                return url;
            };


      /**
       * @memberof bdpHeader
       * @name havePhone
       * @description Does business have phone.
       */
            vm.havePhone = false;


      /**
       * @memberof bdpHeader
       * @method hasPhone
       * @name hasPhone
       * @description Check does current business has phone and set havePhone accordingly.
       * @returns {Boolean} havePhone
       */
            vm.hasPhone = function(contact) {
                if (angular.isDefined(contact)) {
                    this.listing.contacts.contact = contact;
                }
                if (angular.isUndefined(vm.listing) || angular.isUndefined(vm.listing.contacts) || angular.isUndefined(vm.listing.contacts.contact)) {
                    return false;
                } else {
                    angular.forEach(vm.listing.contacts.contact, function(contact) {
                        if (contact.type == 'phone' || contact.type == 'mobile' || contact.type == 'national') {
                            vm.havePhone = true;
                        }
                    });
                }
                return vm.havePhone;
            };


      /**
       * @memberof bdpHeader
       * @method isMobile
       * @name isMobile
       * @description Check is current platform or screen size mobile.
       * @returns {Boolean} isMobile
       */
            vm.isMobile = platformService.isMobile();

      /**
       * @memberof bdpHeader
       * @method isMobileScreen
       * @name isMobileScreen
       * @description Check if screen size is mobile.
       * @returns {Boolean} isMobileScreen
       */
            vm.isMobileScreen = function() {
                return platformService.isMobileScreen();
            };

    /**
       * @memberof bdpHeader
       * @method isTabletPortraitScreen
       * @name isTabletPortraitScreen
       * @description Check if screen size is tablet portrait.
       * @returns {Boolean} isTabletPortraitScreen
       */
            vm.isTabletPortraitScreen = function() {
                return platformService.isTabletPortraitScreen();
            };


      /**
       * @memberof bdpHeader
       * @method hasPhoneDesktop
       * @name hasPhoneDesktop
       * @description Returns true if current platform is desktop and business have phone.
       * @returns {Boolean} havePhone && isDesktop
       */
            this.hasPhoneDesktop = function(contact) {
                if (angular.isDefined(contact)) {
                    this.listing.contacts.contact = contact;
                }
                if (platformService.isMobilePlatform() == false) {
                    return this.listing.contacts && vm.hasPhone(this.listing.contacts.contact);
                } else {
                    return false;
                }
            };


      /**
       * @memberof bdpHeader
       * @method hasPhoneMobile
       * @name hasPhoneMobile
       * @description Returns true if current platform is mobile and business have phone.
       * @returns {Boolean} havePhone && isMobile
       */
            this.hasPhoneMobile = function(contact) {
                if (angular.isDefined(contact)) {
                    this.listing.contacts.contact = contact;
                }
                if (platformService.isMobilePlatform()) {
                    return vm.hasPhone(contact);
                } else {
                    return false;
                }
            };


      /**
       * @memberof bdpHeader
       * @method _formatPhone
       * @name _formatPhone
       * @description Format phone number in user friendly form.
       * @params {String} _phoneNumber
       * @returns {String} _formatedPhoneNumber
       */
            var _formatPhone = function(_phoneNumber) {
                var _formattedPhoneNumber = '';
                if (angular.isUndefined(_phoneNumber) || !_phoneNumber) {
                    return '';
                }
                if (_phoneNumber.length == 10) {
                    if (_phoneNumber.startsWith('04') || _phoneNumber.startsWith('1300') ||
            _phoneNumber.startsWith('1800') || _phoneNumber.startsWith('1900')) {
                        _formattedPhoneNumber += _phoneNumber.substr(0, 4) + ' ' + _phoneNumber.substr(4, 3) + ' ' + _phoneNumber.substr(7, 3);
                    } else {
                        _formattedPhoneNumber += '(' + _phoneNumber.substr(0, 2) + ') ';
                        _formattedPhoneNumber += _phoneNumber.substr(2, 4) + ' ' + _phoneNumber.substr(6);
                    }
                } else if (_phoneNumber.length == 6) {
                    _formattedPhoneNumber += _phoneNumber.substr(0, 2) + ' ' + _phoneNumber.substr(2, 2) + ' ' + _phoneNumber.substr(4, 2);
                } else {
                    _formattedPhoneNumber = _phoneNumber;
                }
                return _formattedPhoneNumber;
            };


            vm.phoneType = 0;


      /**
       * @memberof bdpHeader
       * @method getPhoneBase
       * @name getPhoneBase
       * @description Get base phone number if there is more available.
       * @params {Object} contact
       * @returns {String} _mobileVal
       */
            vm.getPhoneBase = function(contact) {
                if (angular.isDefined(contact)) {
                    this.listing.contacts.contact = contact;
                }
                if (vm.hasPhone(this.listing.contacts.contact) == true) {
                    var _mobileVal = '';
                    angular.forEach(vm.listing.contacts.contact, function(contact) {
                        if (contact.type == 'phone' || contact.type == 'mobile' || contact.type == 'national') {
                            _mobileVal = contact.value;
                        }
                    });
                    return _mobileVal;
                }
            };

            vm.phonePreference = '';


      /**
       * @memberof bdpHeader
       * @name originalPhone
       * @description Original phone number.
       */
            vm.originalPhone = '';


      /**
       * @memberof bdpHeader
       * @method getOriginalPhone
       * @name getOriginalPhone
       * @description Sets originalPhone value.
       * @params {Object} contact
       */
            vm.getOriginalPhone = function(contact) {
                if (angular.isDefined(contact)) {
                    this.listing.contacts.contact = contact;
                }
                if (vm.originalPhone == '') {
                    if (vm.hasPhone(this.listing.contacts.contact) == true) {
                        if (vm.phonePreference == '') {
                            if (angular.isUndefined(this.listing.preferences)) {
                                vm.phonePreference = '';
                            } else if (angular.isUndefined(this.listing.preferences.phonePreference)) {
                                vm.phonePreference = '';
                            } else {
                                vm.phonePreference = this.listing.preferences.phonePreference;
                            }
                        }

                        if (vm.phonePreference == '') {
                            angular.forEach(vm.listing.contacts.contact, function(contact) {
                                if (contact.type == 'national') {
                                    vm.phoneType = 3;
                                    vm.originalPhone = contact.value;
                                } else if (contact.type == 'mobile') {
                                    if (vm.phoneType < 2) {
                                        vm.phoneType = 2;
                                        vm.originalPhone = contact.value;
                                    }
                                } else if (contact.type == 'phone') {
                                    if (vm.phoneType < 1) {
                                        vm.phoneType = 1;
                                        vm.originalPhone = contact.value;
                                    }
                                }
                            });
                        } else {
                            if (vm.phonePreference == 'local') vm.phonePreference = 'phone';
                            angular.forEach(vm.listing.contacts.contact, function(contact) {
                                if (contact.type == vm.phonePreference) {
                                    vm.originalPhone = contact.value;
                                }
                            });
                        }
                    }
                }

                if (vm.originalPhone == '') {
                    angular.forEach(vm.listing.contacts.contact, function(contact) {
                        if (contact.type == 'national') {
                            vm.phoneType = 3;
                            vm.originalPhone = contact.value;
                        } else if (contact.type == 'mobile') {
                            if (vm.phoneType < 2) {
                                vm.phoneType = 2;
                                vm.originalPhone = contact.value;
                            }
                        } else if (contact.type == 'phone') {
                            if (vm.phoneType < 1) {
                                vm.phoneType = 1;
                                vm.originalPhone = contact.value;
                            }
                        }
                    });
                }
                return vm.originalPhone;
            };


      /**
       * @memberof bdpHeader
       * @name phone
       * @description Business phone number.
       */
            vm.phone = '';


      /**
       * @memberof bdpHeader
       * @method getPhone
       * @name getPhone
       * @description Sets and format phone value.
       * @params {Object} contact
       */
            vm.getPhone = function(contact) {
                vm.phone = _formatPhone(vm.getOriginalPhone(contact));
                return vm.phone;
            };


      /**
       * @memberof bdpHeader
       * @method showEditCoverButton
       * @name showEditCoverButton
       * @description Decide weather to show edit cover.
       * @returns {Boolean} showEditCover
       */
            vm.showEditCoverButton = function() {
                var canUpdateCoverImage = false;
                if (vm._has('bussinessDetails.canUpdateCoverImage')) {
                    canUpdateCoverImage = vm.bussinessDetails.canUpdateCoverImage;
                }
                return (
          apiConfig.userOwnsBusiness() == 1 ||
          apiConfig.userOwnsBusiness() == -11 ||
          (apiConfig.userIsAuthenticated() && vm.bussinessDetails.canUpdateCoverImage == true)
        ) ? true : false;
            };


      /**
       * @memberof bdpHeader
       * @method coverPhotoShow
       * @name coverPhotoShow
       * @description Triggers show-cover-photo event.
       */
            vm.coverPhotoShow = function() {
                $rootScope.$broadcast('show-cover-photo');
            };


      /**
       * @memberof bdpHeader
       * @method getNameClass
       * @name getNameClass
       * @description Decide title class according to number of letters in string business name.
       * @returns {String} class
       */
            vm.getNameClass = function(name) {
                if (angular.isDefined(name)) {
                    var length = name.length,
                        medium = 30,
                        small = 44,
                        xsmall = 77;

                    if (length > medium && length < small) {
                        return 'medium';
                    }
                    if (length > medium && length > small && length <= xsmall) {
                        return 'small';
                    }
                    if (length > xsmall) {
                        return 'xsmall';
                    }
                } else {
                    return 'hidden';
                }
            };

            vm.getVisualHeightStyle = function() {
                var isMobile = $window.outerWidth <= 767;
                var isTablet = $window.outerWidth <= 991;
                var shouldStylePhone = $window.outerWidth >= 871 && $window.outerWidth <= 991;
                var phoneEl = document.getElementById('mobile-phone');
                var nameEl = document.getElementById('business-name');
                var headlineHolderEl = document.querySelector('.headline-holder');
                var divHeight = nameEl.offsetHeight;
                var lineHeight;

                if (isMobile) {
                    lineHeight = 48;
                } else {
                    lineHeight = 68;
                }
                var lines = Math.ceil(divHeight / lineHeight);

                switch (lines) {
                case 1:
                    if (isMobile) {
                        nameEl.style.lineHeight = '30px';
                        return {height: '262px'};
                    } else if (isTablet) {
                        return {height: '378px'};
                    } else {
                        return {height: '443px'};
                    }
                case 2:
                    if (isMobile) {
                        nameEl.style.lineHeight = '25px';
                        return {height: '262px'};
                    } else if (isTablet) {
                        nameEl.style.lineHeight = '50px';
                        if (shouldStylePhone) {
                            phoneEl.style.marginBottom = '20px';
                        }
                        return {height: '428px'};
                    } else {
                        nameEl.style.lineHeight = '60px';
                        headlineHolderEl.style.height = '260px';
                        return {height: '503px'};
                    }
                case 3:
                    if (isMobile) {
                        nameEl.style.lineHeight = '25px';
                        return {height: '262px'};
                    } else if (isTablet) {
                        nameEl.style.lineHeight = '50px';
                        return {height: '580px'};
                    } else {
                        nameEl.style.lineHeight = '60px';
                        headlineHolderEl.style.height = '320px';
                        return {height: '580px'};
                    }
                default:
                    break;
                }
            };

      /**
       * @memberof bdpHeader
       * @method setClassByTitle
       * @name setClassByTitle
       * @description Decide title class according to number of letters in string business name.
       * @returns {String} class
       */
            vm.setClassByTitle = function(name) {
                if (angular.isUndefined(name)) {
                    return '';
                } else if (angular.isDefined(name) && name.length > 22 && name.length <= 65) {
                    return 'bigText';
                } else if (angular.isDefined(name) && name.length > 65) {
                    return 'hugeText';
                }
                return '';
            };


      /**
       * @memberof bdpHeader
       * @name location
       * @description Current url.
       */
            vm.location = $location.absUrl();


      /**
       * @memberof bdpHeader
       * @method trackingLink
       * @name trackingLink
       * @description trackingLink.
       * @params {String} name
       */
            vm.trackingLink = function(name) {
                if(angular.isUndefined(name) || name === '') return;
                DTM.trackAnalyticOnClick(name);
                return true;
            };


      /**
       * @memberof bdpHeader
       * @method generateName
       * @name generateName
       * @description Returns empty string if name is not defined.
       * @returns {String} name
       */
            vm.generateName = function(name) {
                var newName = '';
                if (angular.isDefined(name)) {
                    newName = name;
                }
                return newName;
            };


      /**
       * @memberof bdpHeader
       * @method getCoverImage
       * @name getCoverImage
       * @description Returns url to business cover image.
       * @returns {String} name
       */
            vm.getCoverImage = function() {
                if (angular.isDefined(vm.bussinessDetails)) {
                    if (apiConfig.isSensitiveCategory(vm.bussinessDetails.categoryId) == false) {
                        return vm.bussinessDetails.cover;
                    } else {
                        if (
              (angular.isDefined(vm.bussinessDetails.coverImage) && vm.bussinessDetails.coverImage.status.publishStatus == 'active') ||
              ((angular.isDefined(vm.bussinessDetails.newCover)) && vm.bussinessDetails.newCover != null) ||
              apiConfig.userOwnsBusiness() == 1
            ) {
                            return vm.bussinessDetails.cover;
                        }
                    }
                }
            };

            $scope.$on('show-new-cover-photo', function() {
                $log.debug('trigger show new photo cover');
                vm.backgroundStyle();
            });

      /**
       * @memberof bdpHeader
       * @method fbSharedPicture
       * @name fbSharedPicture
       * @description Returns facebook shared Thumb if cover image is undefined.
       * @returns {String} name
       */
            vm.fbSharedPicture = function() {
                var img = vm.getCoverImage();
                if (angular.isUndefined(img) || img == '') {
                    img = vm.listing.shareThumb;
                }
                return img;
            };


      /**
       * @memberof bdpHeader
       * @method isLoaded
       * @name isLoaded
       * @description Returns true if business details are loaded.
       * @returns {Boolean} isLoaded
       */
            vm.isLoaded = function() {
                if (angular.isUndefined(vm.bussinessDetails)) return false;
                else if (angular.isUndefined(vm.bussinessDetails.name)) return false;
                else return true;
            };


      /**
       * @memberof bdpHeader
       * @method backgroundStyle
       * @name backgroundStyle
       * @description Returns object with style for background.
       * @returns {Object} backgroundStyle
       */
            vm.backgroundStyle = function() {
                return {
                    background: 'url(' + vm.getCoverImage() + ') 50% 0 no-repeat',
                    backgroundSize: 'cover',
          // backgroundAttachment: 'fixed',
                    backgroundPosition: 'center',
                };
            };

      /**
       * @memberof bdpHeader
       * @name phoneAPIType
       * @description Type of phone API.
       */
            vm.phoneAPIType = '';

      /**
       * @memberof bdpHeader
       * @method phoneClickTrack
       * @name phoneClickTrack
       * @description Omniture tracking for mobile phone click.
       */
            vm.phoneClickTrack = function(listing) {
                DTM.trackAnalyticOnClick('phone number click');
            };

      /**
       * @memberof bdpHeader
       * @method updatePhoneType
       * @name updatePhoneType
       * @description Sets phone type (local/mobile/national).
       * @returns {Object} phoneAPIType
       */
            var updatePhoneType = function(contact) {
                if (angular.isDefined(contact)) {
                    vm.listing.contacts.contact = contact;
                }
                if (vm.hasPhone(vm.listing.contacts.contact)) {
                    angular.forEach(vm.listing.contacts.contact, function(contact) {
                        if (contact.type == 'phone') {
                            vm.phoneAPIType = 'LOCAL';
                        } else if (contact.type == 'mobile') {
                            vm.phoneAPIType = 'MOBILE';
                        } else if (contact.type == 'national') {
                            vm.phoneAPIType = 'NATIONAL';
                        }
                    });

                    $log.debug('vm.phoneAPIType -> ' + vm.phoneAPIType);
                    return vm.phoneAPIType;
                }
            };


      /**
       * @memberof bdpHeader
       * @method tracking
       * @name tracking
       * @description Tracks user interaction with bdp header.
       */
            vm.tracking = function(id) {
                DTM.trackAnalyticOnClick(id);
            };

            vm.listing.shareThumb = '';

            $scope.$watch('vm.listing.featureImage', function(newVal) {
                if (angular.isDefined(newVal)) {
                    if (newVal.status.moderationStatus === 'approved' && newVal.status.publishStatus === 'active') {
                        vm.listing.shareThumb = newVal.url;
                    } else {
                        var find = vm.listing.businessImages.image.filter(function(img) {
                            return img.status.moderationStatus === 'approved' && img.status.publishStatus === 'active';
                        }).pop();
                        if (angular.isDefined(find)) {
                            vm.listing.shareThumb = find.url;
                        }
                    }
                }
            }, true);

            vm.hasEmail = businessService.hasContactEmail(vm.listing);
            vm.hasWebsite = businessService.getHavePrimaryWebsite(vm.listing);

            vm.toolsHidden = function() {
                return !vm.hasEmail && !vm.hasWebsite && !vm.showEditCoverButton();
            };

      /**
       * @memberof bdpHeader
       * @method getToolsWidth
       * @name getToolsWidth
       * @description Calculate tools width based on screen size and available details.
       * @returns {Object} elementPercent
       */
            vm.getToolsWidth = function() {
          // tablet view
                if ($window.outerWidth < 992 && $window.outerWidth > 600) { // 854
                    if (vm.bussinessDetails.managed == false && apiConfig.userOwnsBusiness() != -11) {
                        vm.bussinessDetails.widthButton = 50;
                        vm.bussinessDetails.widthBtnVisitWebsite = '50%';
                        vm.bussinessDetails.widthBtnEmailBusiness = '50%';
                        if (!vm.hasEmail) {
                            vm.bussinessDetails.widthButton = 100;
                            vm.bussinessDetails.widthBtnVisitWebsite = '100%';
                            return {
                                width: '100%',
                            };
                        }
                        if (!vm.hasWebsite) {
                            vm.bussinessDetails.widthButton = 100;
                            vm.bussinessDetails.widthBtnEmailBusiness = '100%';
                            return {
                                width: '100%',
                            };
                        }
                        return {
                            width: '50%',
                        };
                    } else {
                        if (!vm.showEditCoverButton()) {
                            vm.bussinessDetails.widthButton = 50;
                            vm.bussinessDetails.widthBtnVisitWebsite = '50%';
                            vm.bussinessDetails.widthBtnEmailBusiness = '50%';
                            if (!vm.hasEmail) {
                                vm.bussinessDetails.widthButton = 100;
                                vm.bussinessDetails.widthBtnVisitWebsite = '100%';
                                return {
                                    width: '100%',
                                };
                            }
                            if (!vm.hasWebsite) {
                                vm.bussinessDetails.widthButton = 100;
                                vm.bussinessDetails.widthBtnEmailBusiness = '100%';
                                return {
                                    width: '100%',
                                };
                            }
                            return {
                                width: '50%',
                            };
                        /** Styling if has edit cover photo button */
                        } else {
                            vm.bussinessDetails.widthButton = '33.33%';
                            vm.bussinessDetails.widthBtnVisitWebsite = '33.33%';
                            vm.bussinessDetails.widthBtnEmailBusiness = '33.33%';
                            if (!vm.hasEmail && !vm.hasWebsite) {
                                vm.bussinessDetails.widthButton = 100;
                                return {
                                    width: '100%',
                                };
                            }
                            if (!vm.hasEmail) {
                                vm.bussinessDetails.widthButton = 50;
                                vm.bussinessDetails.widthBtnVisitWebsite = '50%';
                                return {
                                    width: '50%',
                                };
                            }
                            if (!vm.hasWebsite) {
                                vm.bussinessDetails.widthButton = 50;
                                vm.bussinessDetails.widthBtnEmailBusiness = '50%';
                                return {
                                    width: '50%',
                                };
                            }
                            return {
                                width: '33.33%',
                            };
                        }
                    }
                }

          // mobile view
                else if ($window.outerWidth < 601) {
                    if (vm.bussinessDetails.managed == false && apiConfig.userOwnsBusiness() != -11) {
                        vm.bussinessDetails.widthButton = (vm.showEditCoverButton()) ? 50 : 33.33;
                        vm.bussinessDetails.widthBtnVisitWebsite = '50%';
                        vm.bussinessDetails.widthBtnEmailBusiness = (vm.showEditCoverButton()) ? '100%' : '50%';
                        if (!vm.hasEmail) {
                            vm.bussinessDetails.widthButton = 100;
                            vm.bussinessDetails.widthBtnVisitWebsite = '100%';
                            return {width: '100%'};
                        }
                        if (!vm.hasWebsite) {
                            vm.bussinessDetails.widthButton = 100;
                            vm.bussinessDetails.widthBtnEmailBusiness = '100%';
                            return {width: '100%'};
                        }
                        return (vm.showEditCoverButton()) ? {width: '50%'} : {width: '33.33%'};
                    } else {
                        if (!vm.showEditCoverButton()) {
                            vm.bussinessDetails.widthButton = 50;
                            vm.bussinessDetails.widthBtnVisitWebsite = '50%';
                            vm.bussinessDetails.widthBtnEmailBusiness = '50%';
                            if (!vm.hasEmail) {
                                vm.bussinessDetails.widthBtnVisitWebsite = '100%';
                                return {
                                    width: '100%',
                                };
                            }
                            if (!vm.hasWebsite) {
                                vm.bussinessDetails.widthBtnEmailBusiness = '100%';
                                return {
                                    width: '100%',
                                };
                            }
                            return {
                                width: '50%',
                            };
                        /** Styling if has edit cover photo button */
                        } else {
                            vm.bussinessDetails.widthButton = 50;
                            vm.bussinessDetails.widthBtnVisitWebsite = '50%';
                            vm.bussinessDetails.widthBtnEmailBusiness = '100%';
                            if (!vm.hasEmail && !vm.hasWebsite) {
                                vm.bussinessDetails.widthButton = 100;
                                return {
                                    width: '100%',
                                };
                            }
                            if (!vm.hasWebsite) {
                                vm.bussinessDetails.widthBtnEmailBusiness = '50%';
                                return {
                                    width: '50%',
                                };
                            }
                            return {
                                width: '50%',
                            };
                        }
                    }
                }
          // desktop view
                if ($window.outerWidth > 992) { // 854
                    vm.bussinessDetails.widthButton = -1;
                    vm.bussinessDetails.widthBtnVisitWebsite = 'auto';
                    vm.bussinessDetails.widthBtnEmailBusiness = 'auto';
                    return {};
                }
            };


      /**
       * @memberof bdpHeader
       * @method getButtonsVisible
       * @name getButtonsVisible
       * @description Returns proper css class to display details as buttons if screen is smaler than 651px.
       * @returns {Object} style
       */
            vm.getButtonsVisible = function() {
        // mobile view
                if ($window.outerWidth < 651) {
                    if (vm.bussinessDetails.widthButton >= 35) {
                        return {
                            display: 'inline',
                        };
                    }
                }
            };

      /**
       * @memberof bdpHeader
       * @method toolsStyle
       * @name toolsStyle
       * @description Sets tool style width based on current screen width.
       * @returns {Object} style
       */
            vm.toolsStyle = function() {
                var style = {};
                if (vm.platformService.getWidth() > 868) {
                    if (vm.showEditCoverButton()) {
                        style.width = '403px';
                    }
                }
                return style;
            };

            vm.scrollToReviews = function(reviews) {
                if (angular.isUndefined(reviews.reviews) || reviews.reviews == 0) {
                    if (apiConfig.userIsAuthenticated()) {
                        setTimeout(function() {
                            angular.element(document.querySelector('#add-review-btn')).triggerHandler('click');
                            setTimeout(function() {
                                windowScroll.scrollToElement(angular.element(document.querySelector('#reviews')));
                            });
                        });
                    } else {
                        windowScroll.scrollToElement(angular.element(document.querySelector('#reviews')));
                        setTimeout(function() {
                            angular.element(document.querySelector('#add-review-btn')).triggerHandler('click');
                        });
                    }
                } else {
                    windowScroll.scrollToElement(angular.element(document.querySelector('#reviews')));
                }
            };

            vm.hasDeals = function() {
                if (angular.isUndefined(vm.listing) || angular.isUndefined(vm.listing.deals) || angular.isUndefined(vm.listing.deals.deal) ||
          vm.listing.deals.deal.length == 0) {
                    return false;
                } else {
                    return true;
                }
            };

            vm.gotoDeals = function() {
                if (platformService.getWidth() < 768) {
                    windowScroll.scrollToElement(angular.element(document.querySelector('.dealsWrapper')));
                } else {
                    windowScroll.scrollToElement(angular.element(document.querySelector('#bdp-deals')));
                }
            };

            /* vm.anchorState = {
                details: 'active',
                gallery: '',
                reviews: '',
                similar: '',
            };

            vm.anchorState = {
                details: 'active',
                gallery: '',
                reviews: '',
                similar: '',
            };*/

      /**
       * @memberof bdpHeader
       * @method getPosition
       * @name getPosition
       * @description Calculate position of element for given selector.
       * @parm {String} element selector
       * @return {Number} pisition in pixels
       */
            vm.getPosition = function(selector) {
                var position = {
                    top: 0,
                    bottom: 0,
                    height: 0,
                };
                var queryResult = document.querySelector(selector);
                if (typeof angular.element(queryResult)[0] != 'undefined') {
                    position.height = angular.element(queryResult)[0].clientHeight;
                    position.top = angular.element(queryResult).prop('offsetTop');
                    position.bottom = position.top + position.height;
                }

                return position;
            };

      /**
       * @memberof bdpHeader
       * @method getAnchorVisibility
       * @name getAnchorVisibility
       * @description Calculate is anchor in visible space.
       * @parm {Number} navPosition
       * @parm {Number} anchorPositon
       * @return {Boolean}
       */
            vm.getAnchorVisibility = function(navPosition, anchorPositon) {
                var pointer = navPosition.bottom;

                if (pointer >= (anchorPositon.top - 100) && pointer <= (anchorPositon.bottom - 85)) {
                    return true;
                }
                return false;
            };

            vm.getLocationTip = function() {
                return vm.listing.locationTips || null;
            };

            vm.coverPhotoText = function() {
                return window.outerWidth <= 767 ? 'Edit Cover Photo' : 'EDIT COVER PHOTO';
            };

      /**
       * @memberof bdpHeader
       * @eventType emit
       * @name scroll
       * @description Scrolling watcher, calculate according anchor point.
       * For now not using this method - duplicate bind causing page flickering - Swathi
       */
            angular.element($window).bind('', function() {
                if (scrollDetectWithTimeout.getValue() == true) {
                    setTimeout(function() {
                        vm.anchorPosition = {
                            details: vm.getPosition('bdp-details div'),
                            gallery: vm.getPosition('bdp-gallery div'),
                            reviews: vm.getPosition('bdp-reviews div'),
                            similar: vm.getPosition('bdp-similar div'),
                        };
                        vm.navPositon = vm.getPosition('.wrap-anchors.bbb .anchors.fixed');
                        if ($window.scrollY <= (vm.anchorPosition.details.top - vm.navPositon.bottom)) {
                            vm.anchorState = {
                                details: 'active',
                                gallery: '',
                                reviews: '',
                                similar: '',
                            };
                        } else {
                            vm.anchorState = {
                                details: (vm.getAnchorVisibility(vm.navPositon, vm.anchorPosition.details) ? 'active' : ''),
                                gallery: (vm.getAnchorVisibility(vm.navPositon, vm.anchorPosition.gallery) ? 'active' : ''),
                                reviews: (vm.getAnchorVisibility(vm.navPositon, vm.anchorPosition.reviews) ? 'active' : ''),
                                similar: (vm.getAnchorVisibility(vm.navPositon, vm.anchorPosition.similar) ? 'active' : ''),
                            };
                        }
                        $rootScope.$apply();
                        scrollDetectWithTimeout.disable();
                    });
                } else {
                    vm.anchorPosition = {
                        details: vm.getPosition('bdp-details div'),
                        gallery: vm.getPosition('bdp-gallery div'),
                        reviews: vm.getPosition('bdp-reviews div'),
                        similar: vm.getPosition('bdp-similar div'),
                    };
                    vm.navPositon = vm.getPosition('.anchors');
                    if ($window.scrollY <= (vm.anchorPosition.details.top - vm.navPositon.bottom)) {
                        vm.anchorState = {
                            details: 'active',
                            gallery: '',
                            reviews: '',
                            similar: '',
                        };
                    } else {
                        vm.anchorState = {
                            details: (vm.getAnchorVisibility(vm.navPositon, vm.anchorPosition.details) ? 'active' : ''),
                            gallery: (vm.getAnchorVisibility(vm.navPositon, vm.anchorPosition.gallery) ? 'active' : ''),
                            reviews: (vm.getAnchorVisibility(vm.navPositon, vm.anchorPosition.reviews) ? 'active' : ''),
                            similar: (vm.getAnchorVisibility(vm.navPositon, vm.anchorPosition.similar) ? 'active' : ''),
                        };
                    }
                    $rootScope.$apply();
                }
            });
        }
    }
})();
