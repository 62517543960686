(function() {
    'use strict';

    angular
        .module('truelocal')
        .controller('BrowseController', BrowseController);

    /** @ngInject */
    function BrowseController($scope, $timeout, platformService, categoriesService, $document, $location, Seo, $rootScope, headerService, appService) {
        var vm = this;

        headerService.showSearchForm = true;
        headerService.logoClass = '';
        headerService.dynamicShowHideSearchForm = false;

        Seo.pageTitle.set('Browse by Category - True Local');
        Seo.description.set('All of Australia\'s businesses listed online - everything from Mechanics, Electricians, Hair Salons and much more. Find Quotes, Reviews and Directions at True Local.');

        vm.isMobilePlatform = platformService.isMobilePlatform;

        if(angular.isUndefined($rootScope.isBrowseByCategoriesPage)) {
            $rootScope.isBrowseByCategoriesPage = true;
        }


        vm.isListLoaded = function() {
            return !angular.isUndefined(vm.categories);
        };

        vm.isListEmpty = function() {
            return !vm.isListLoaded() || !vm.categories.length;
        };

        var categoriesModel = categoriesService.getCategoriesModel(); //Getting model allows us to create common functions over the category list data

        //Get processed category list after the raw list is populated
        $scope.$watch(categoriesModel.getCategories, function(value) { //I change here
            var val = value || null;
            if (val && val.length > 0)
                vm.categories = categoriesModel.getProcessedCategories();
        });

        appService.displayFooter();
    }
})();
