(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name BodyController
     * @description BodyController
     * that display specific areas.
     */
    .controller('BodyController', BodyController);

    /** @ngInject */
    function BodyController($rootScope, apiConfig, $interval, arrowService, platformService, footerUrls, $timeout, runnningCampaign, $location, $cookies) {
        var vm = this;
        vm.loaded = false;
        vm.footerUrls = footerUrls;
        vm.userPending = false;

        vm.isHome = ($location.path() === "/");
        vm.campaignRunning = runnningCampaign.steve;
        vm.isPanelOpen = !$cookies.get('tl_campaign_seen') &&
                         ((navigator.userAgent.indexOf("Firefox")>=0 ||
                           navigator.userAgent.indexOf("Edge")>=0) ? false : true);
        vm.isMobile = (platformService.getWidth() < 768 && !platformService.isIPad());

        // Added for the Competition, this is needed for loading the API
        vm.updateCompRunning = function() {
            vm.compRunning = apiConfig.compToggle();
            if (angular.isDefined(vm.compRunning)) {
                $interval.cancel(vm.timer);
            }
        };

        vm.timerForComp = function() {
            vm.timer = $interval(vm.updateCompRunning, 5);
        }();

        vm.initAdv = function() {
            arrowService.initBdp(vm.apidata);
        }();

        angular.element(window).on('user-pending', function() {
            vm.userPending = true;
        });

        angular.element(window).on('user-unauthenticated', function() {
            vm.userPending = false;
        });

        angular.element(window).on('user-approved', function() {
            vm.userPending = false;
        });

        // cfpLoadingBar:loading
      /*  $rootScope.$on('cfpLoadingBar:completed', function() {
          console.log('in compl');
            vm.loaded = true;
        });

        $rootScope.$on('$locationChangeStart', function(event, toState, toStateParams) {
            vm.loaded = false;
       });
*/         $rootScope.$on('$locationChangeSuccess', function(event, toState, toStateParams) {
            vm.loaded = true;
            if($location.path() !== "/"){
                vm.isHome = false;
            } else {
                vm.isHome = true;
            }
        });

    }
})();
