(function() {
    'use strict';

    angular
        .module('truelocal')
        .constant('MAX_MOBILE_PORTRAIT_WIDTH', 500)
        .directive('tradieHeroImage', tradieHeroImage);

    /** @ngInject */
    function tradieHeroImage(apiConfig) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/tradiepage/heroimage/heroimage.html',
            scope: {
                city: '=',
            },
            controller: tradieHeroIMGController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */

        function tradieHeroIMGController($scope, $timeout, footerUrls, $location, $http, $window, MAX_MOBILE_PORTRAIT_WIDTH, DTM) {
            var vm = this;
            var windowWidth = angular.element(window)[0].outerWidth;

            vm.heroBackgrounUrl = getTradieHeroImageUrl();
            vm.footerUrls = footerUrls;
            vm.linkText, vm.businessLink;

            angular.element($window)
                .on('resize.tradieBackground', function() {
                    var oldWidth = windowWidth;
                    windowWidth = angular.element(window)[0].outerWidth;
                    if (oldWidth <= MAX_MOBILE_PORTRAIT_WIDTH) {
                        if (windowWidth > MAX_MOBILE_PORTRAIT_WIDTH) {
                            $scope.$apply(function() {
                                vm.heroBackgrounUrl = getTradieHeroImageUrl();
                            });
                        }
                    } else {
                        if (windowWidth <= MAX_MOBILE_PORTRAIT_WIDTH) {
                            $scope.$apply(function() {
                                vm.heroBackgrounUrl = getTradieHeroImageUrl();
                            });
                        }
                    }
                });

            $scope.$on('$destroy', function() {
                angular.element($window).off('resize.tradieBackground');
            });

            function getTradieHeroImageUrl() {
                if (windowWidth <= MAX_MOBILE_PORTRAIT_WIDTH) return 'initial';
                return 'url(https://media.truelocal.com.au/tradies/tradies-' + vm.city + '.jpg)';
            }

            vm.getBusinessDeatils = function() {
                switch (vm.city.toLowerCase()) {
                case 'sydney':
                    vm.businessLink = '/business/ausmotion-removals/campbelltown';
                    vm.linkText = 'meet neal from ausmotion';
                    break;

                case 'melbourne':
                    vm.businessLink = '/business/omzen-electric-co/melbourne';
                    vm.linkText = 'meet justin from omzen electric';
                    break;

                case 'brisbane':
                    vm.businessLink = '/business/snc-automotive-pty-ltd/brendale';
                    vm.linkText = 'meet jordan from snc automotive';
                    break;
                }
            }();

            vm.titleCase = function(text) {
                if (angular.isUndefined(text)) return;
                return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
            };

            vm.meetOurTradies = function() {
                DTM.trackAnalyticOnClick('meet our tradies');
            };
        }
    }
})();
