(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name MainController
     * @description MainController.
     */
    .controller('MainController', MainController);

  /** @ngInject */
    function MainController(Seo, gmapService) {

    }
})();
