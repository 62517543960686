(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpStars
     * @description Directive display business stars, with yellow colored as much stars as it current business rating.
     * It displays in bdp header, on item (banner), and in review section.
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {constant}  constantsClasses          Constant classes
     * @param {constant}  constantsSettings         Constant settings
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *
     * @example
     * <bdp-stars reviews="[object]" show-seo-props="[boolean]" category="[object]" bdp-header="[boolean]"></bdp-stars>
     */
    .directive('bdpStars', bdpStars);

  /** @ngInject */
    function bdpStars() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/bdpstars/bdpstars.html',
            scope: {
                reviews: '=',
                showSeoProps: '=',
                noReviewsText: '=',
                category: '=',
                bdpHeader: '=',
            },
            controller: ['constantsPlaceholders', 'constantsClasses', 'constantsSettings', 'DTM', BdpStarsController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpStarsController(constantsPlaceholders, constantsClasses, constantsSettings, DTM) {
      /**
       * @memberof bdpStars
       * @method reviewsText
       * @name resetAllStates
       * @description Get the text placeholder
       *
       * @param {object} reviewsObj Object from statistics with reviews
       */
            this.reviewsText = function(reviewsObj) {
                if (angular.isUndefined(reviewsObj)) {
                    reviewsObj = this.reviews;
                }
                if (angular.isUndefined(reviewsObj)) {
                    return '';
                }
                if (reviewsObj.reviews > 0) {
                    return reviewsObj.reviews + ' ' + ((reviewsObj.reviews == 1) ? constantsPlaceholders.bdpStars._review : constantsPlaceholders.bdpStars._reviews);
                }
                return constantsPlaceholders.bdpStars._noReviews;
            };

      /**
       * @memberof bdpStars
       * @method active
       * @name active
       * @description Get the active status from 1 to 5 for each star (return including if half star
       *
       * @param {integer} number Current star
       * @param {reviews} object  Reviews stats object
       *
       * @returns {string} The proper class from constantsClasses
       */
            this.active = function(number, reviews) {
                if (angular.isUndefined(reviews)) {
                    reviews = this.reviews;
                }
                if (angular.isUndefined(reviews)) {
                    if (this.accommodation()) {
                        return constantsClasses.bdpStars.iconCircle;
                    }
                    return constantsClasses.bdpStars.iconStar;
                }
                if (angular.isUndefined(reviews.rating)) {
                    reviews.rating = reviews.averageRating;
                }
                if (angular.isDefined(reviews.rating) && reviews.rating >= (number - 1 + 0.25)) {
                    if (reviews.rating >= (number - 1 + 0.25) && reviews.rating <= (number - 1 + 0.74)) {
                        if (this.accommodation()) {
                            return constantsClasses.bdpStars.activeIconCircleHalf;
                        }
                        return constantsClasses.bdpStars.iconStarHalf;
                    } else if (reviews.rating > (number - 1 + 0.74)) {
                        if (this.accommodation()) {
                            return constantsClasses.bdpStars.activeIconCircle;
                        }
                        return constantsClasses.bdpStars.activeIconStar;
                    }
                }
                if (this.accommodation()) {
                    return constantsClasses.bdpStars.iconCircle;
                }
                return constantsClasses.bdpStars.iconStar;
            };

      /**
       * @memberof bdpStars
       * @method type
       * @name type
       * @description Get the star type (for hotels are diferent)
       * @returns {string} The proper class from constantsClasses
       */
            this.type = function() {
                if (this.accommodation()) {
                    return constantsClasses.bdpStars.iconCircle2Star;
                }
                return constantsClasses.bdpStars.iconRankArmyStar;
            };

      /**
       * @memberof bdpStars
       * @method accommodation
       * @name accommodation
       * @description Get if is with hotel type stars
       * @returns {boolean} true/false
       */
            this.accommodation = function() {
                var category;
                if (angular.isUndefined(this.category)) {
                    category = '';
                } else {
                    category = this.category;
                }
                if (constantsSettings.categoriesWithTicks.indexOf(category.name) > -1) {
                    return true;
                }
                return false;
            };

      /**
       * @memberof bdpStars
       * @method tracking
       * @name tracking
       * @description True Local tracking
       */
            this.tracking = function(id) {
                DTM.trackAnalyticOnClick(id);
            };

      /**
       * @memberof bdpStars
       * @method reviewsCount
       * @name reviewsCount
       * @description Get the number of reviews
       */
            this.reviewsCount = function() {
                if (angular.isUndefined(this.reviews) || angular.isUndefined(this.reviews.reviews)) {
                    return 0;
                } else {
                    return this.reviews.reviews;
                }
            };

      /**
       * @memberof bdpStars
       * @method showStars
       * @name showStars
       * @description Get if stars should be showned
       */
            this.showStars = function() {
                return (this.reviewsCount() > 0 && this.bdpHeader) || this.bdpHeader != true;
            };

        /**
         * @memberof bdpStars
         * @method applyAggregateRating
         * @name applyAggregateRating
         * @description Evaluate if the aggregateRating schema should be used
         * @returns {boolean}
         */
            this.applyAggregateRating = function() {
                return (this.showSeoProps && this.reviewsCount() > 0);
            }

        }
    }
})();
