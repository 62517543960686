(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('pendingBanner', pendingBanner);

    /** @ngInject */
    function pendingBanner() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/pendingbanner/pendingbanner.html',
            scope: {
                creationDate: '=',
            },
            controller: pendingBannerController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function pendingBannerController(footerUrls, searchConfigManager, apiConfig, windowScroll, $location, $rootScope, $http) {
            var vm = this;
            vm.isHome = ($location.path() === "/");

          $rootScope.$on('$locationChangeSuccess', function(event, toState, toStateParams) {
            if($location.path() !== "/"){
              vm.isHome = false;
            } else {
              vm.isHome = true;
            }
          });

            vm.resendEmail = function () {
                var email = {
                    email: apiConfig.userData.data.data.email
                };

                return $http.post(apiConfig.generate('auth', 'resend-activation'), email, apiConfig.getXAuthToken())
                    .then(resendEmailSuccess)
                    .catch(resendEmailFail);

                function resendEmailSuccess(response) {
                    vm.resendActivationSuccess = true;
                }

                function resendEmailFail(error) {
                    $http.get(apiConfig.generate('users','me','email-verification-requests'), email, apiConfig.getXAuthToken())
                        .then(emailVerificationSuccess)

                    function emailVerificationSuccess(response) {
                        setTimer(response.data.data.nextPossibleRequestDate);
                    }
                }

                function setTimer(timeExpiration){
                    var timeout = setInterval(function() {
                        var currentTime = new Date().getTime();

                        var timer = timeExpiration - currentTime;

                        var minutes = Math.floor((timer % (1000 * 60 * 60)) / (1000 * 60));
                        var seconds = Math.floor((timer % (1000 * 60)) / 1000);
                        vm.countdown = minutes + "m " + seconds + "s ";

                        vm.resendActivationTimeout = true;

                        if (timer < 0) {
                            clearInterval(timeout);
                            vm.resendActivationTimeout = false;
                        }
                    }, 1000);
                }
            }
        }
    }
})();
