(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bsNoResults
     * @description Directive report user that there is no search results, and suggest better search.
     *
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {service}   searchConfigManager       Service for building and handlig parameters for search
     *
     * @example
     * <bs-no-results suggestions-list="[object]"></bs-no-results>
     */
    .directive('bsNoResults', bsNoResults);

  /** @ngInject */
    function bsNoResults() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/bsnoresults/bsnoresults.html',
            scope: {
                searchListing: '=',
                suggestionsList: '=',
            },
            controller: ['$modal', 'searchConfigManager', 'DTM', '$window', '$location', BsNoResultsController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BsNoResultsController($modal, searchConfigManager, DTM, $window, $location) {
            var _searchType = searchConfigManager.getProperty('type');

            this.locationValue = searchConfigManager.getProperty('location').capitalizeSentence().capitalizeSuburb();
            this.keywordValue = searchConfigManager.getProperty('keyword').capitalizeSentence();

      /**
       * @memberof bsNoResults
       * @method isOnlyKeyword
       * @name isOnlyKeyword
       * @description Return if is only keyword
       * @returns {Boolean} onlyLocation
       */

            this.getHTMLMessage = function() {
                var _message = '&nbsp';
                if(this.locationValue && this.keywordValue) {
                    _message = 'We can’t find any matches for <b>' + this.keywordValue + '</b> near <b>' + this.locationValue + '</b>';
                } else if(this.locationValue && !this.keywordValue) {
                    _message = 'We can’t find any matches near <b>' + this.locationValue + '</b>';
                } else if(!this.locationValue && this.keywordValue) {
                    _message = 'We can’t find any matches for <b>' + this.keywordValue + '</b>';
                }
                return _message;
            };

            this.addBusiness = addBusiness;


      /**
       * @memberof bsNoResults
       * @method addBusiness
       * @name addBusiness
       * @description Open modal to add business (option suggested if user search for business that doesn't exist).
       */
            function addBusiness() {
                $window.location = $window.location.origin + '/add-business';
                DTM.trackAnalyticOnClick('add business:search');
            }
        }
    }
})();
