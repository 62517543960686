(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name autoComplete
     * @description autoComplete provides method search that gets auto complete suggestions for searching business.
     * @param  {service}  $http                     angular http service
     * @param  {factory}  apiConfig                 Api configuration factory
     * @param {service}   $log                      Angular console log wrapper
     */
    .factory('autoComplete', ['$http', 'apiConfig', '$log', autoComplete]);

  /** @ngInject */
  // authService.$inject = ['$modal'];
    function autoComplete($http, apiConfig, $log) {
        var service = {
            search: search,
        };

    /**
     * @memberof autoComplete
     * @method search
     * @name search
     * @description make the $http request to get the autocomplete sugestions
     *
     * @param {object} option params for api call
     */

        function search(options) {
            if (angular.isUndefined(options)) {
                options = {};
            }

            return $http.get(apiConfig.generate('autocomplete', options), {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
        .then(getSearchSuccess)
        .catch(getSearchFailed);

            function getSearchSuccess(response) {
                $log.debug(response.data.data.autocomplete);
                return response.data.data.autocomplete;
            }


            function getSearchFailed(error) {
                $log.debug(error);
            }
        }

        return service;
    }
})();
