(function() {
    'use strict';

    var MAX_TABLET_SIZE = 991;

    angular
    .module('truelocal')
    .directive('browseCategoriesList', function() {
        return {
            restrict: 'EA',
            scope: {
                browseCategoriesList: '=',
            },
            templateUrl: '/app/components/browse-categories-list/browse-categories-list.html',
            controller: BrowseListController,
            controllerAs: 'vm',
            bindToController: true,
        };
    });

  /** @ngInject */
    function BrowseListController($window, $timeout) {
        var vm = this;

        vm.toggleOpen = function(category) {
            category.isOpened = !category.isOpened;
        };

        vm.isOpened = function(category) {
            return category.isOpened;
        };

        vm.getTitle = function(category) {
            return (vm.isOpened(category) ? 'Close ' : 'Open ') + category.name;
        };

        angular.element($window).on('resize', updateItemsColumns);
        updateItemsColumns();

        function updateItemsColumns() {
            $timeout(function() {
                var width = $window.innerWidth;
                var columns = width > MAX_TABLET_SIZE ? 3 : 2;
                vm.itemsColumns = splitByColumns(vm.browseCategoriesList, columns);
            });
        }
    }

    function splitByColumns(items, num) {
        if (num < 2) return [items];

        var minColumnLength = Math.floor(items.length / num);
        var restItemsLength = items.length % num;

        var map = [];
        for (var i = num; i--;) {
            if (restItemsLength-- > 0) map.push(minColumnLength + 1);
            else map.push(minColumnLength);
        }

        var columns = map.reduce(function(res, size, index) {
            var nextChunk = res.skip + size;
            res.cols.push(items.slice(res.skip, nextChunk));
            res.skip = nextChunk;
            return res;
        }, {cols: [], skip: 0}).cols;

        return columns;
    }
})();
