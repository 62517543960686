(function() {
    'use strict';
    angular
        .module('truelocal')
        .config(function(localStorageServiceProvider) {
            localStorageServiceProvider.setPrefix('truelocal');
        })

    /**
     * @memberof truelocal
     * @ngdoc service
     * @name apiConfig
     * @description Service responsible for establishing communication with API and config app based on returned values.
     *
     * @param {service}   $cookies                  angular cookies service wrapper
     * @param {service}   $http                     angular http service
     * @param {module}    localStorageService       An Angular module that gives you access to the browsers local storage
     * @param {constant}  env                       Environmental constants
     * @param {constant}  constantsSettings         Constant settings
     * @param {service}   $location                 Angular window.location service wrapper
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $q                        A service that helps you run functions asynchronously, and use their return values (or exceptions) when they are done processing.
     */
    .factory('apiConfig', ['$cookies', '$http', 'localStorageService', 'env', 'constantsSettings', '$location', '$log',
                            '$q', 'runningCompetition', '$rootScope', '$localstorage', '$window', apiConfig])
        .config(apiConf);

    function apiConf($httpProvider) {
        $httpProvider.defaults.withCredentials = true;
    }

    function apiConfig($cookies, $http, localStorageService, env, constantsSettings, $location, $log, $q,
                       runningCompetition, $rootScope, $localstorage, $window) {
        var configuration = {
            'apiHost': env.api.url,
            // 'apiHost' : 'http://truelocal.cryoutsolutions.com/rest/',//cors gone wild :)
            // 'apiHostExtend': 'https://api.neon.for.truelocal.com.au/rest',
            'apiHostExtend': env.api.url.substring(0, env.api.url.length - 1),
            'token': getToken, // '?passToken=aDNqWE9ncVU1WQ==',
            'generate': generator,
            'hasUrl': hasUrl,
            'updateCredentials': updateCredentials,
            'userIsAuthenticated': userIsAuthenticated,
            'updateListReviewDefinition': updateListReviewDefinition,
            'getReviewListOptions': getReviewListOptions,
            'userData': '',
            'ownedListing': '',
            'recallUserAuthData': recallUserAuthData,
            'destroySession': destroySession,
            'userOwnsBusiness': userOwnsBusiness,
            'setListing': setListing,
            'getListing': getListing,
            'checkIfMine': checkIfMine,
            'getXAuthToken': getXAuthToken,
            'getXAuthTokenShort': getXAuthTokenShort,
            'isSensitiveCategory': isSensitiveCategory,
            'debug': debug,
            'businessAddress': '',
            'setBusinessAddress': setBusinessAddress,
            'getBusinessAddress': getBusinessAddress,
            'getHeaderVersion': getHeaderVersion,
            'setHeaderVersion': setHeaderVersion,
            'setUserApiCall': setUserApiCall,
            'getUserApiCall': getUserApiCall,
            'getHeaderByVersion': getHeaderByVersion,
            'userPending': userPending,
            'compToggle': compToggle,
	        'setToken': setToken,
            'searchFilteredbyState': searchFilteredbyState,
            'searchFilteredbyRegion': searchFilteredbyRegion,
            'searchFilteredbySuburb': searchFilteredbySuburb,
            'getBacklink': getBacklink,
            'backlink': {},
        };

        var _headerVersion = 1,
            _compActive = runningCompetition.competition.active,
            _compRunningNow, _bdpListing = null,
            _callUserApi = false,
            searchFilteredbyState = false,
            searchFilteredbyRegion = false,
            searchFilteredbySuburb = false;


        /**
         * @memberof apiConfig
         * @method setHeaderVersion
         * @name setHeaderVersion
         * @description Set the header version
         */
        function setHeaderVersion(version) {
            _headerVersion = version;
        }

        /**
         * @memberof apiConfig
         * @memberof apiConfig
         * @method Competition
         * @name competition
         * @description check if is competition running
         */

        // this part is for Competition
        if (_compActive) {
            var compUrl = configuration.apiHost + runningCompetition.competition.competitionURL + getToken();
            $http.get(compUrl).then(function(response) {
                var compData = response.data.data.promotion[0];
                _compRunningNow = compData.status === 'ACTIVE';
                angular.element(window).triggerHandler('comp-running');
            });
        }


        /**
         * @memberof apiConfig
         * @method getHeaderVersion
         * @name getHeaderVersion
         * @description Get the header version
         */
        function getHeaderVersion() {
            return _headerVersion;
        }


        /**
         * @memberof apiConfig
         * @method setToken
         * @name setToken
         * @description Set the token in cookie
         */

 	function setToken(token) {
            // set cookie
     $cookies.put('token', token);
     $window.localStorage.setItem('token', token);
 }


        /**
         * @memberof apiConfig
         * @method getHeaderByVersion
         * @name getHeaderByVersion
         * @description Get the header by version (header for http-requests)
         */
        function getHeaderByVersion(version) {
            if (angular.isUndefined(version) || version != 2) {
                return {
                    headers: {
                        'Accept': 'application/truelocal-1.0+json',
                    },
                };
            }
            return {
                headers: {
                    'Accept': 'application/truelocal-2.0+json',
                },
            };
        }

        /**
         * @memberof apiConfig
         * @method setBusinessAddress
         * @name setBusinessAddress
         * @description Set the business address
         *
         * @param {object} address Object containing the address
         */
        function setBusinessAddress(address) {
            configuration.businessAddress = address;
            return configuration.businessAddress;
        }
        /**
         * @memberof apiConfig
         * @method getBusinessAddress
         * @name getBusinessAddress
         * @description get the business address
         */
        function getBusinessAddress() {
            return configuration.businessAddress;
        }

        /**
         * @memberof apiConfig
         * @method debug
         * @name debug
         * @description debug mode
         */
        function debug() {
            var regex = /debug=(full|product)/;
            var $debug = regex.exec($location.absUrl());
            if ($debug != null) {
                return $debug[1];
            } else {
                return false;
            }
        }

        var _listReviewOptionsDefinition = {
                order: 'date',
                sort: 'descending',
                offset: 0,
                limit: 10,
            },
            _userAuthenticated = !isDefaultToken(),
            _userPending,
            _sensitiveCategories = constantsSettings.sensitiveCategories;

        /**
         * @memberof apiConfig
         * @method isSensitiveCategory
         * @name isSensitiveCategory
         * @description check if is sensitive category in object
         */
        function isSensitiveCategory(categoryId) {
            var status = false;
            angular.forEach(_sensitiveCategories, function(category) {
                if (category == categoryId) {
                    status = true;
                }
            });
            return status;
        }


        /**
         * @memberof apiConfig
         * @method getUserDataComplete
         * @name getUserDataComplete
         * @description The success handler for geting user/me
         */

        function getUserDataComplete(response) {
            configuration.userData = response;
            if(response.data.data.statistics.freeListingsOwned > 0 ||
                        response.data.data.statistics.paidListingsOwned > 0) {
                getOwnedListing();
            }
            _userAuthenticated = true;
            $log.debug('user-authenticated');
            setUserApiCall(true);
            angular.element(window).triggerHandler('user-authenticated');
            angular.element(window).triggerHandler('refreshListing');
            checkIfUserPending(response);
            return true;
        }

        /**
         * @memberof apiConfig
         * @method getOwnedListing
         * @name getOwnedListing
         * @description api call for getting user owned listing data
         */
        function getOwnedListing() {
          // this call can be configured with limit and next page fetch when required.

            var apiUrl = configuration.apiHost + 'users/me/owned-listings'+ configuration.token() +'&offset=0&limit=1';

            $http.get(apiUrl, {
                ignoreLoadingBar: true,
            }, getXAuthToken())
                .then(getUserOwnerListingComplete)
                .catch(getUserOwnerListingFailed);
        }

        /**
         * @memberof apiConfig
         * @method getUserOwnerListingComplete
         * @name getUserOwnerListingComplete
         * @description success callback for getting user owned listing data
         */
        function getUserOwnerListingComplete(response) {
            $log.debug('ownedListing api success response');
            configuration.ownedListing = response.data;
        }

        /**
         * @memberof apiConfig
         * @method getUserOwnerListingComplete
         * @name getUserOwnerListingComplete
         * @description error callback for getting user owned listing data
         */
        function getUserOwnerListingFailed(response) {
            $log.debug('owned listing api failure response');
        }

        /**
         * @memberof apiConfig
         * @method userPending
         * @name userPending
         * @description The success handler for geting user/me
         */
        function checkIfUserPending(response) {
            var UD = configuration.userData;
            if (angular.isDefined(UD)) {
                if (UD.data.data.status.moderationStatus === 'pending') {
                    angular.element(window).triggerHandler('user-pending');
                    $rootScope.$emit('user-pending');
                    _userPending = true;
                    $log.debug('user-authenticated but pending');
                } else {
                    angular.element(window).triggerHandler('user-approved');
                    $rootScope.$broadcast('updatedUser', UD);
                    _userPending = false;
                }
            }
        }

        if ((!getUserApiCall() || getUserApiCall() === 'false') && !isDefaultToken()) {
            $http.get(configuration.generate('users', 'me'), {
                ignoreLoadingBar: true,
            }, getXAuthToken())
                .then(getUserDataComplete)
                .catch(getUserDataFailed);
        }

        /**
         * @memberof apiConfig
         * @method setListing
         * @name setListing
         * @description Set the listing to be accesible to getter
         *
         * @param {object} _listing Listing object
         */

        function setListing(_listing) {
            _bdpListing = _listing;
        }
        /**
         * @memberof apiConfig
         * @method getListing
         * @name getListing
         * @description Get the listing
         */
        function getListing() {
            return _bdpListing;
        }
        /**
         * @memberof apiConfig
         * @method recallUserAuthData
         * @name recallUserAuthData
         * @description Make the user/me endpoint request. We use this for API check if user is authenticated
         */
        function recallUserAuthData() {
            return $http.get(configuration.generate('users', 'me'), getXAuthToken())
                .then(getUserDataComplete)
                .catch(getUserDataFailed);
        }


        /**
         * @method checkIfMine
         * @name checkIfMine
         * @description Check if _userId is the same with the authenticated user ID
         *
         * @param {string} _userId  The id what need to be cheked
         */
        function checkIfMine(_userId) {
            if (angular.isUndefined(configuration.userData)) {
                return false;
            } else if (configuration.userData && configuration.userData.data.data.id == _userId) {
                return true;
            }
            return false;
        }


        /**
         * @memberof apiConfig
         * @method getUserDataFailed
         * @name getUserDataFailed
         * @description The fail handler for geting user/me
         */
        function getUserDataFailed() {
            configuration.userData = '';
            angular.element(window).triggerHandler('user-unauthenticated');
            _userAuthenticated = false;
            setUserApiCall(false);
            return _userAuthenticated;
        }

        /**
         * @memberof apiConfig
         * @method userIsAuthenticated
         * @name userIsAuthenticated
         * @description getter for user authenticated status
         */
        function userIsAuthenticated() {
            return _userAuthenticated;
        }


        /**
         * @memberof apiConfig
         * @method getToken
         * @name getToken
         * @description get the token ready to be integrated into api request
         */
        function getToken() {
          var token = $cookies.get('token');
	        return '?passToken=' + (angular.isUndefined(token) ? env.api.token : token);
        }

        /**
         * @memberof apiConf
         * @method isDefaultToken
         * @description check if the token is the default env token
         * @returns bool
         */
        function isDefaultToken() {
            var userToken = $window.localStorage.getItem('token') || $cookies.get('token');
            return angular.isUndefined(userToken) || userToken === env.api.token;
        }

        /**
         * @memberof apiConfig
         * @method userOwnsBusiness
         * @name userOwnsBusiness
         * @description check if user owns business
         */
        function userOwnsBusiness(id) {
            // not logged in
            var owned = -1;
            var listing = _bdpListing;
            if (configuration._has('ownedListing') && listing != null && listing._has('isBusinessOwner')) {
                owned = 0;
                if (listing.isBusinessOwner == true && listing.managed == true) {
                    // owned
                    owned = 1;
                } else if (listing.isBusinessOwner == true) {
                    // pending
                    owned = -11;
                }
            }
            return owned;
        }


        /**
         * @memberof apiConfig
         * @method generator
         * @name generator
         * @description generate the api endpoint for $http requests from object
         */

        function destroySession() {
            if (localStorageService.isSupported) {
                localStorageService.remove(env.cookie.name);
            } else if (localStorageService.cookie.isSupported) {
                localStorageService.cookie.remove(env.cookie.name);
            } else {
                $cookies.remove(env.cookie.name, {
                    path: '/',
                });
            }
	        $cookies.remove('token');
	        $window.localStorage.removeItem('token');
            setUserApiCall(false);
            _userAuthenticated = false;
            angular.element(window).triggerHandler('user-unauthenticated');
            angular.element(window).triggerHandler('refreshListing');
            configuration.userData = '';
            $rootScope.$broadcast('updatedUser', configuration.userData);
            window.location.reload(true);
            return configuration.userData;
            // return recallUserAuthData();
        }


        /**
         * @memberof apiConfig
         * @method generator
         * @name generator
         * @description generate the api endpoint for $http requests from object
         */
        function generator() {
            var _argumentsListSize = arguments.length - 1,
                _argumentLineArray = [];


            for (var i = 0; i <= _argumentsListSize; i++) {
                _argumentLineArray.push(arguments[i]);
            }


            var _lastArgument = _argumentLineArray[_argumentsListSize];

            if (angular.isObject(_lastArgument)) {
                _argumentLineArray.pop();
            } else {
                _lastArgument = null;
            }

            var _generateSizeOffset = function() {
                var _definedby = '?';
                if (!_lastArgument)
                    return _definedby;


                for (var k in _lastArgument)
                    _definedby += ( k + '=' + _lastArgument[k] +'&');

                return _definedby;
            };

            var apiUrl =  _argumentLineArray.join('/') + _generateSizeOffset();

            return apiUrl;
        }


        /**
         * @memberof apiConfig
         * @method getReviewListOptions
         * @name getReviewListOptions
         * @description get review list options
         */
        function getReviewListOptions() {
            return _listReviewOptionsDefinition;
        }


        /**
         * @memberof apiConfig
         * @method updateCredentials
         * @name updateCredentials
         * @description after successfull auth response this function will be called to get the user data.
         *
         * @param {string} _userToken  User token - used only in non tl enviroments
         */
        function updateCredentials(_userToken) {
            setXAuthToken(_userToken);

            _userAuthenticated = $http.get(configuration.generate('users', 'me'), getXAuthToken())
                .then(getUserDataComplete)
                .catch(getUserDataFailed);
        }


        /**
         * @memberof apiConfig
         * @method updateListReviewDefinition
         * @name updateListReviewDefinition
         * @description update review list definitions
         */
        function updateListReviewDefinition(_options) {
            angular.extend(_listReviewOptionsDefinition, _options);
        }

        /**
         * @memberof apiConfig
         * @method hasUrl
         * @name hasUrl
         * @description handler for urls
         *
         * @param {string} _preparedUrl  Url
         */
        function hasUrl(_preparedUrl) {
            var _prepareUrl = _preparedUrl.split('?'),
                _configuredUrl = configuration.apiHost + _prepareUrl[0] + configuration.token() + '&' + _prepareUrl[1];

            return _configuredUrl;
        }


        /**
         * @memberof apiConfig
         * @method getXAuthToken
         * @name getXAuthToken
         * @description get auth token and return prepared headers to be attached to $http requests
         */
        function getXAuthToken() {
            if (env.auth == 'headers') {
                if (localStorageService.isSupported) {
                    if (localStorageService.get(env.cookie.name)) {
                        return {
                            headers: {
                                'x-auth-token': localStorageService.get(env.cookie.name),
                            },
                        };
                    } else {
                        return '';
                    }
                } else if (localStorageService.cookie.isSupported) {
                    if (localStorageService.cookie.get(env.cookie.name)) {
                        return {
                            headers: {
                                'x-auth-token': localStorageService.cookie.get(env.cookie.name),
                            },
                        };
                    } else {
                        return '';
                    }
                } else if ($cookies.get(env.cookie.name)) {
                    return {
                        headers: {
                            'x-auth-token': $cookies.get(env.cookie.name),
                        },
                    };
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }

        /**
         * @memberof apiConfig
         * @method getXAuthTokenShort
         * @name getXAuthTokenShort
         * @description get auth token and return prepared headers to be attached to $http requests - short version
         */
        function getXAuthTokenShort() {
            if (env.auth == 'headers') {
                if (localStorageService.isSupported) {
                    if (localStorageService.get(env.cookie.name)) {
                        return {
                            'x-auth-token': localStorageService.get(env.cookie.name),
                        };
                    } else {
                        return '';
                    }
                } else if (localStorageService.cookie.isSupported) {
                    if (localStorageService.cookie.get(env.cookie.name)) {
                        return {
                            'x-auth-token': localStorageService.cookie.get(env.cookie.name),
                        };
                    } else {
                        return '';
                    }
                } else if ($cookies.get(env.cookie.name)) {
                    return {
                        'x-auth-token': $cookies.get(env.cookie.name),
                    };
                } else {
                    return '';
                }
            } else {
                return '';
            }
        }

        /**
         * @memberof apiConfig
         * @method setXAuthToken
         * @name setXAuthToken
         * @description store auth token
         */
        function setXAuthToken(_token) {
            if (env.auth == 'headers') {
                if (localStorageService.isSupported) {
                    return localStorageService.set(env.cookie.name, _token);
                } else if (localStorageService.cookie.isSupported) {
                    return localStorageService.cookie.set(env.cookie.name, _token);
                } else {
                    return $cookies.put(env.cookie.name, _token, {
                        path: env.cookie.path,
                    });
                }
            } else {
                return true;
            }
        }


        /**
         * @memberof apiConfig
         * @method setUserApiCall
         * @name setUserApiCall
         * @description set calling api for user or not
         */

        function setUserApiCall(_status) {
            _callUserApi = _status;
            _status ? $cookies.remove('userApiCall') : $cookies.put('userApiCall', _status, {
                path: '/',
            });
        }

        /**
         * @memberof apiConfig
         * @method getUserApiCall
         * @name getUserApiCall
         * @description get calling api for user or not
         */

        function getUserApiCall() {
            _callUserApi = $cookies.get('userApiCall');
            return _callUserApi;
        }

        /**
         * @memberof apiConfig
         * @method userPending
         * @name userPending
         * @description Check if the user Pending
         */

        function userPending() {
            return _userPending;
        }

        /**
         * @memberof apiConfig
         * @method compToggle
         * @name compToggle
         * @description Check if the comp Running
         */

        function compToggle() {
            return _compRunningNow && _compActive;
        }

        /**
         * @memberof apiConfig
         * @method getBacklink
         * @name getBacklink
         * @description api call for getting the backlink content
         */
        
        function getBacklink(path) {
            return $http.get(configuration.generate('backlink', {url: path}), getXAuthToken())
                .then(function(response) {
                    if (response.data) {
                        configuration.backlink = response.data.data;
                    }
                    return configuration.backlink;
                })
                .catch(function(error){
                    return configuration.backlink;
                });
        }

        return configuration;
    }
})();
