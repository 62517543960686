(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReportReview
     * @description Directive enable user to report review or comment as improper.
     *
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   reviewsService            Factory what handle the API requests for reviews
     * @param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.@param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {constant}  constantsApiErrorCodes    Constant API error codes
     * @param {factory}   DTM           TL tracking services
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     *
     * @example
     *
     * <bdp-report-review review-item="[object]" is-comment="[boolean]"></bdp-report-review>
     *
     */
    .directive('bdpReportReview', bdpReportReview);

  /** @ngInject */
    function bdpReportReview() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreportreview/bdpreportreview.html',
            scope: {
                reviewItem: '=',
                isComment: '=',
            },
            controller: ['$modal', 'modalFactory', 'authService', 'apiConfig', 'reviewsService', 'ModalLikeDislikeFactory', 'constantsPlaceholders', 'constantsApiErrorCodes', 'DTM', 'windowScroll', BdpReportReviewController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /**
     * @memberof bdpReportReview
     * @name bdpReportReview_BdpReportReviewController
     * @method bdpReportReview_BdpReportReviewController
     * @description Depending of the response on review report, show appropriate modal and cancel report
     * if necessary.

     *
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   reviewsService            Factory what handle the API requests for reviews
     * @param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.@param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {constant}  constantsApiErrorCodes    Constant API error codes
     * @param {factory}   DTM           TL tracking services
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     *
     *
     */

    /** @ngInject */
        function BdpReportReviewController($modal, modalFactory, authService, apiConfig, reviewsService, ModalLikeDislikeFactory, constantsPlaceholders, constantsApiErrorCodes, DTM, windowScroll) {
            var vm = this;
            this.reportedReview = false;
            this.waitingResponse = false;
            this.reasonText = '';


      /**
       * @memberof bdpReportReview
       * @event _manageReportReviewResponse
       * @name _manageReportReviewResponse
       * @description Depending of the response on review report, show appropriate modal and cancel report
       * if necessary.
       */
            var _manageReportReviewResponse = angular.bind(this, function(_resp) {
                if (angular.isUndefined(_resp)) {
                    this.waitingResponse = false;
                    this.reportedReview = false;
                    this.reviewItem.reportingReview = false;
                    return;
                }
                this.waitingResponse = false;

                if (_resp.status == 'error') {
                    if (_resp.code && parseInt(_resp.code) == constantsApiErrorCodes.reportReviewComment.bazaarVoiceNotRegisteredError) {
                        var _modalInstance = $modal.open({
                            templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                            controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                            controllerAs: 'vm',
                            size: 'dialog_size_small',
                            resolve: {
                                storedData: function() {
                                    return _resp.data.data.meta.errors.reason;
                                },
                            },
                        });
                        _modalInstance.opened.then(function() {
                            windowScroll.mobileModalScroll();
                        });
                        _modalInstance.result.finally(function() {
                            windowScroll.mobileModalCloseHandler();
                        });
                    } else {
                        this.cancelReport();

                        $modal.open({
                            templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                            controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                            controllerAs: 'vm',
                            size: 'dialog_size_small',
                            resolve: {
                                storedData: function() {
                                    return _resp.data.data.meta.messages[0];
                                },
                            },
                        });
                    }
                } else {
                    this.reportedReview = true;
                }
            });


      /**
       * @memberof bdpReportReview
       * @method hideIfMine
       * @name hideIfMine
       * @description Returns true if current review is of the current user.
       * @returns {Boolean} isMine
       */
            this.hideIfMine = function() {
                if (angular.isUndefined(this.reviewItem)) {
                    return true;
                }
                if (apiConfig.checkIfMine(this.reviewItem.user.id)) {
                    return true;
                }
                return false;
            };


      /**
       * @memberof bdpReportReview
       * @method reportThisReview
       * @name reportThisReview
       * @description Report current review and set waitingResponse to true.
       */
            this.reportThisReview = function() {
                if (this.waitingResponse) {
                    return;
                }

                this.waitingResponse = true;
                if (apiConfig.userIsAuthenticated()) {
                    var _reportType = (this.isComment) ? constantsPlaceholders.bdpReportReview.reportTypeComment : constantsPlaceholders.bdpReportReview.reportTypeReview;

                    if (!apiConfig.checkIfMine(vm.reviewItem.user.id)) {
                        this.reasonText = constantsPlaceholders.bdpReportReview.reasonText;
                    }

                    if (typeof (_reviewItem) != 'undefined') {
                        reviewsService.reportReview(_reviewItem.id, _reviewItem, _reportType, this.reasonText, true).then(angular.bind(this, _manageReportReviewResponse));
                    } else {
                        reviewsService.reportReview(vm.reviewItem.id, vm.reviewItem, _reportType, this.reasonText, true).then(angular.bind(this, _manageReportReviewResponse));
                    }

                    if(!this.reviewItem.isApproved) {
                        angular.element(document.querySelector('#review-' + this.reviewItem.id)).css({'display': 'none'});
                    }
                    vm.reviewReported = true;
                    DTM.trackAnalyticOnClick('report review:completed');


                } else {
                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                        controller: 'ModalAuthPromptController',
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                    });
    
                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });
                }
            };


      /**
       * @memberof bdpReportReview
       * @method cancelReport
       * @name cancelReport
       * @description Set flag on current review as reported.
       */
            this.cancelReport = function() {
                if (angular.isUndefined(this.reviewItem)) {
                    return;
                }
                angular.extend(this.reviewItem, {reportingReview: false});
            };


      /**
       * @memberof bdpReportReview
       * @method closeReportReview
       * @name closeReportReview
       * @description Set flag on current review as already reported to prevent displaying it.
       */
            this.closeReportReview = function() {
                angular.extend(this.reviewItem, {alreadyReported: true});
            };
        }
    }
})();
