(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name homeTabs
         * @description Homepage tabs
         *
         * @param {constant} homePageTabsParameters constant with homepage tabs parameters
         * @param {factory}   apiConfig                 Api configuration factory
         *
         * @example
         * <home-tabs></home-tabs>
         */
        .directive('homeTabs', homeTabs);


    /** @ngInject */
    function homeTabs() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/homepage/homepagetabs/homepagetabs.html',
            scope: {
                creationDate: '=',
            },
            controller: HomeTabsController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function HomeTabsController(apiConfig, homePageTabsParameters, DTM, $modal, windowScroll, env) {
            var vm = this;

            vm.tabActive = 1;
            vm.getLocalTabUrl = homePageTabsParameters.local;

            vm.setTab = function(id) {
                switch (id) {
                case 'for locals':
                    vm.tabActive = 1;
                    DTM.trackAnalyticOnClick(id);
                    break;
                case 'for business owners':
                    vm.tabActive = 2;
                    DTM.trackAnalyticOnClick(id);
                }
            };

            /**
             * @memberof homeTabs
             * @method getBussinessTabUrl
             * @name getBussinessTabUrl
             * @description get the url according to the auth status
             */

            vm.getBussinessTabUrl = function() {
                if(apiConfig.userIsAuthenticated()) {
                    return homePageTabsParameters.business.loggedin;
                } else {
                    if(!env.addBussLive) {
                        return homePageTabsParameters.business.notloggedinOld;
                    }
                    return homePageTabsParameters.business.notloggedin;
                }
            };

            var nextAnimation = 250;

            angular.element(document.querySelector('.trigger-local-tab')).bind('click', function(e) {
                e.preventDefault();

              angular.element(this).addClass('active');
              angular.element(document.querySelector('.trigger-business-tab')).removeClass('active');

              angular.element(document.querySelector('.tab-business')).addClass('slide-right-fade');

                setTimeout(function() {
                  angular.element(document.querySelector('.tab-local')).addClass('active');
                }, nextAnimation);

                setTimeout(function() {
                  angular.element(document.querySelector('.tab-business')).removeClass('active slide-right-fade');
                }, 500);
            });

          angular.element(document.querySelector('.trigger-business-tab')).bind('click', function(e) {
              e.preventDefault();

              angular.element(this).addClass('active');
              angular.element(document.querySelector('.trigger-local-tab')).removeClass('active');

              angular.element(document.querySelector('.tab-local')).addClass('slide-left-fade');

                setTimeout(function() {
                  angular.element(document.querySelector('.tab-business')).addClass('active');
                }, nextAnimation);

                setTimeout(function() {
                  angular.element(document.querySelector('.tab-local')).removeClass('active slide-left-fade');
                }, 500);
            });

            vm.newsletterModal = function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/shared/modalNewsletter/modalnewsletter.html',
                    controller: 'newsletterController',
                    controllerAs: 'vm',
                    size: 'dialog_size_middle'
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });

                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });

                DTM.trackAnalyticOnClick('newsletter modal opened');
            }
        }
    }
})();
