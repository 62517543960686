(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReviewDate
     * @description Directive display date of review in user friendly format, like "2 months ago",
     * "over a year ago", etc.
     */
    .directive('bdpReviewDate', bdpReviewDate);

  /** @ngInject */
    function bdpReviewDate(moment) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreviewdate/bdpreviewdate.html',
            scope: {
                reviewDate: '=',
                hasSpecialClass: '=',
            },
            controller: BdpReviewDateController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpReviewDateController() {
      /**
       * @memberof bdpReviewDate
       * @method getClass
       * @name getClass
       * @description Returns date class if doesn't already have owns.
       * @returns {String} dateClass
       */
            this.getClass = function() {
                if (angular.isDefined(this.hasSpecialClass)) {
                    return 'hidden';
                }
                return 'date';
            };


      /**
       * @memberof bdpReviewDate
       * @method setFormatedTime
       * @name setFormatedTime
       * @description Returns date of review as string in user friendly format.
       * @param {String} _timestamp, if not entered, use current review date.
       * @returns {String} formatDate
       */
            this.setFormatedTime = function(_timestamp) {
                if (angular.isUndefined(_timestamp) || _timestamp == '') {
                    _timestamp = this.reviewDate;
                }

                var _currentDate = (new Date()).getTime(),
                    _createdDate = (new Date(_timestamp)).getTime(),
                    _dateOffset = (_currentDate - _createdDate);
                var _months = moment.duration(_dateOffset).asMonths();

                if (_months < 1.00) {
                    return 'UNDER 1 MONTH AGO';
                }

                for (var i = 1, l = 12; i < l; i++) {
                    if (_months >= i && _months < i + 1) {
                        return moment.duration(parseInt(i, 10), 'months').humanize() + ' AGO';
                    }
                }

                var _yearNumber = 0;

                if (_months % 12 == 0) {
                    _yearNumber = _months / 12;

                    return moment.duration(parseInt(_yearNumber, 10), 'years').humanize() + ' AGO';
                } else {
                    _yearNumber = _months / 12;
                    _yearNumber = parseInt(_yearNumber);

                    return 'OVER ' + moment.duration(parseInt(_yearNumber, 10), 'years').humanize() + ' AGO';
                }

                return '';
            };
        }
    }
})();
