(function() {
    'use strict';

    angular
    .module('truelocal')
    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReviews
     * @description Directive is element holder for user reviews.
     *
     * @param {factory}   serviceListings           Factory what handle the API requests for listing
     * @param {service}   $location                 Angular window.location service wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {factory}   DTM           TL tracking services
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     * @param {service}   $log                      Angular console log wrapper
     *
     * @example
     * <bdp-reviews bussiness-reviews="[object]" listing="[object]"></bdp-reviews>
     *
     */
    .directive('bdpReviews', BdpReviews);

  /** @ngInject */
    function BdpReviews($location, $rootScope) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreviews/bdpreviews.html',
            scope: {
                bussinessReviews: '=',
                listing: '=',
            },

            controller: ['serviceListing', '$timeout', 'DTM', 'platformService', 'windowScroll', '$log', '$location', BdpReviewsController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /**
     * @memberof bdpReviews
     * @method bdpReviews_BdpReviewsController
     * @name bdpReviews_BdpReviewsController
     * @description Directive controller
     *
     * @param {factory}   serviceListings           Factory what handle the API requests for listing
     * @param {service}   $location                 Angular window.location service wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {factory}   DTM           TL tracking services
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     * @param {service}   $log                      Angular console log wrapper
     *
     */

    /** @ngInject */
        function BdpReviewsController(serviceListing, $timeout, DTM, platformService, windowScroll, $log, $location) {
            this.loadingMore = false;
            var vm=this;
            
            this.formatReviewsComments = function() {
                if(angular.isDefined(this.bussinessReviews)) {
                    if(angular.isDefined(this.bussinessReviews.reviewList)) {
                        for(var key in this.bussinessReviews.reviewList) {
                            if(angular.isDefined(this.bussinessReviews.reviewList[key].userComment)) {
                                this.bussinessReviews.reviewList[key].userComment = this.bussinessReviews.reviewList[key].userComment.replace(/[\n\r]/g, '<br>');
                            }
                            if(angular.isDefined(this.bussinessReviews.reviewList[key].comments)) {
                                for(var commentKey in this.bussinessReviews.reviewList[key].comments) {
                                    if(angular.isDefined(this.bussinessReviews.reviewList[key].comments[commentKey].userComment)) {
                                        this.bussinessReviews.reviewList[key].comments[commentKey].userComment = this.bussinessReviews.reviewList[key].comments[commentKey].userComment.replace(/[\n\r]/g, '<br>');
                                    }
                                }
                            }    
                        }
                    }
                    if(angular.isDefined(this.bussinessReviews.pendingReviews) && angular.isDefined(this.bussinessReviews.pendingReviews.review)) {
                        for(var key in this.bussinessReviews.pendingReviews.review) {
                            if(angular.isDefined(this.bussinessReviews.pendingReviews.review[key]) && angular.isDefined(this.bussinessReviews.pendingReviews.review[key].text)) {
                                this.bussinessReviews.pendingReviews.review[key].text = this.bussinessReviews.pendingReviews.review[key].text.replace(/[\n\r]/g, '<br>');
                            }
                        }
                    } 
                    if(angular.isDefined(this.bussinessReviews.userReview) && angular.isDefined(this.bussinessReviews.userReview.userComment)) {
                        this.bussinessReviews.userReview.userComment = this.bussinessReviews.userReview.userComment.replace(/[\n\r]/g, '<br>');
                    } 
                }
            }

            this.formatReviewsComments();
            
      /**
       * @memberof bdpReviews
       * @method loadMoreReviews
       * @name loadMoreReviews
       * @description Tracks user interaction with currently displayed reviews, and display next set of reviews.
       */
            this.loadMoreReviews = function() {
                DTM.trackAnalyticOnClick('load more reviews');
                if (this.loadingMore) {
                    return;
                }
                this.loadingMore = true;
                return serviceListing.getNextReviews(this.bussinessReviews.reviewsNext, this.bussinessReviews).then(angular.bind(this, function() {
                    this.loadingMore = false;
                    this.formatReviewsComments();
                }));
            };

      /**
       * @memberof bdpReviews
       * @method checkReviewsOffset
       * @name checkReviewsOffset
       * @description Check does it have more reviews to display.
       */
            this.checkReviewsOffset = function() {
                if (this.bussinessReviews && this.bussinessReviews.reviewsNext && this.bussinessReviews.reviewsNext != '-') {
                    return false;
                }
                return true;
            };


      /**
       * @memberof bdpReviews
       * @method noReviews
       * @name noReviews
       * @description Check does it have reviews and returns true if there is no.
       * @returns {Boolean} noReviews
       */
            this.noReviews = function() {
                if (this.bussinessReviews && this.bussinessReviews.reviewList && this.bussinessReviews.reviewList.length) {
                    return false;
                }
                return true;
            };
        }
    }
})();
