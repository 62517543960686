(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name homepageNewsletter
         * @description homepage newsletter
         *
         * @param {factory}   windowScroll              windowScroll service provides functions that can adopt window behavior when scrolling view
         *
         * @example
         * <homepage-newsletter></homepage-newsletter>
         */
        .directive('homepageNewsletter', homepageNewsletter);


    /** @ngInject */
    function homepageNewsletter() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/homepage/homepagenewsletter/homepagenewsletter.html',
            scope: {
            },
            controller: ['windowScroll', HomepageNewsletterController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function HomepageNewsletterController(windowScroll) {
            var vm = this;
            vm.subscriberEmail = '';
            vm.subscribing = false;
            vm.validateError = false;
            vm.success = false;
            vm.showFormsMobile = false;
            vm.typeBusiness = false;
            vm.typeLocal = false;

            /**
             * @memberof homepageNewsletter
             * @method subscribe
             * @name subscribe
             * @description subscribe action
             */
            vm.subscribe = function() {
                vm.subscribing = true;
                vm.validateError = false;
                // let's validate
                if(vm.typeLocal == true) {
                    if(angular.isUndefined(vm.checked) || vm.checked.length == 0) {
                        vm.validateError = true;
                        vm.subscribing = false;
                    }
                    var _noStateSelected = false;
                    angular.forEach(vm.checked, function(value, key) {
                        if(value == true) _noStateSelected = true;
                    });
                    if(_noStateSelected == false) {
                        vm.validateError = true;
                        vm.subscribing = false;
                    }
                }
                if(vm.validateError == false) {
                    // @todo after api is complete the http post
                    vm.success = true;
                    setTimeout(function() {
                        windowScroll.scrollToElement(angular.element(document.querySelector('#homapagenewsletter__success')));
                    }, 100);
                }
            };

            /**
             * @memberof homepageNewsletter
             * @method chouseBusinessType
             * @name chouseBusinessType
             * @description chouse business/locals
             */
            vm.chouseBusinessType = function(elem) {
                if(elem == 'business') {
                    vm.typeBusiness = !vm.typeBusiness;
                } else if(elem == 'locals') {
                    vm.typeLocal = !vm.typeLocal;
                }
            };

            /**
             * @memberof homepageNewsletter
             * @method clearEmailInput
             * @name clearEmailInput
             * @description clear email address input
             */
            vm.clearEmailInput = function() {
                vm.subscriberEmail = '';
            };
        }
    }
})();
