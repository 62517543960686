(function() {
    'use strict';

    angular
    .module('truelocal')
    .directive('ssSorting', ssSorting);

  /** @ngInject */
    function ssSorting($timeout, $window, serviceConfigManager, platformService) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/servicepage/sssorting/sssorting.html',
            scope: {
                searchListing: '=',
            },
            controller: ssSortingController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function ssSortingController() {
            var vm = this;
            vm.sortingActiveSelection = 'Best Match';
            vm.sortingOptions = [{title: 'Best Match', sort: '', active: true},
        // {title : "Rating", sort : "rating", active : false} ,
        {title: 'Reviews', sort: 'reviews', active: false},
        {title: 'Deals', sort: 'deals', active: false}];

            this.sortingBox = false;
            vm.hide = false;
            vm.listShow = true;


            var sortingBoxTimeout;

            this.mouseLeftSorting = function() {
                if(this.sortingBox) {
                    sortingBoxTimeout = $timeout(function() {
                        this.toggleSortingBox();
                    }.bind(this));
                }
            };


            this.mouseEnteredSorting = function() {
                if(sortingBoxTimeout) {
                    $timeout.cancel(sortingBoxTimeout);
                }
            };

            this.toggleSortingBox = function() {
                this.sortingBox = !this.sortingBox;
                if(this.sortingBox && this.isMobile()) {
                    angular.element(document.querySelector('#sortDropdownFader')).css('display', 'block');
                } else if (!this.sortingBox && this.isMobile()) {
                    angular.element(document.querySelector('#sortDropdownFader')).css('display', 'none');
                }
                if(sortingBoxTimeout) {
                    $timeout.cancel(sortingBoxTimeout);
                }
            };

            this.isMobile = function() {
                return platformService.isMobile();
            };

            function forceCloseDropdown() {
                if(sortingBoxTimeout) {
                    $timeout.cancel(sortingBoxTimeout);
                }
                $timeout(angular.bind(this, function() {
                    this.sortingBox = false;
                    angular.element(document.querySelector('#sortDropdownFader')).css('display', 'none');
                }));
            };

            angular.element($window).bind('scroll force-toggle-sort', angular.bind(this, forceCloseDropdown));
            angular.element(document.querySelector('#sortDropdownFader')).on('click', angular.bind(this, forceCloseDropdown));

            this.sortingOptionsInactive = function() {
                if(this.isMobile()) {
                    return this.sortingOptions;
                };

                var _permList = [];
                for(var i = 0, l = this.sortingOptions.length; i < l; i ++) {
                    if(!this.sortingOptions[i].active)
                        _permList.push(this.sortingOptions[i]);
                }
                return _permList;
            };

            this.manageSort = function(_sortingOption) {
                for (var i = 0, l = this.sortingOptions.length, _listElement; i < l; i++) {
                    _listElement = this.sortingOptions[i];
                    if (_listElement.active) {
                        _listElement.active = false;
                    }
                    if (_listElement.title == _sortingOption.title) {
                        _listElement.active = true;
                        this.sortingActiveSelection = _listElement.title;
                        serviceConfigManager.setProperty('sort', _listElement.sort);
                    }
                }

                this.toggleSortingBox();
                serviceConfigManager.displayItemsForChangedSortType();
            };

        }
    }
})();
