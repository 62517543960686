(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name mainNavbar
         * @description Main navigation bar directive.
         *
         * @param {factory}   apiConfig                 Api configuration factory
         * @param {factory}   authService               Factory what handle API request for authentication
         * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
         * @param {service}   $location                 Angular window.location service wrapper
         * @param {directive} $modal                    ui-bootstrap modal wrapper
         * @param {service}   $rootScope                Angular app root scope
         * @param {factory}   DTM                       TL tracking services
         * @param {factory}   windowScroll              windowScroll service provides functions that can adopt window behavior when scrolling view
         * @param {factory}   headerService             service used for handling how header will show in diferent pages; eg: bigger logo, don't show search form
         * @param {service}   $timeout                  Angular window.setTimeout service
         * @param  {factory}  platformService           Factory service that handle detection of platform and screen size that current user use
         *
         * @example
         * <main-navbar></main-navbar>
         */
        .directive('mainNavbar', mainNavbar)

        /**
         * @memberof truelocal
         * @ngdoc factory
         * @name headerService
         * @description service used for handling how header will show in diferent pages; eg: bigger logo, don't show search form
         */
        .factory('headerService', HeaderService);


    /** @ngInject */
    function mainNavbar() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/mainHeader/mainheader.html',
            scope: {},
            controller: NavbarController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function NavbarController(apiConfig, authService, modalFactory, $location, $modal,
                                  $rootScope, DTM, windowScroll, headerService, $timeout,
                                  platformService, $cookies, env, listingModels, $window, $state) {
            var vm = this;
            vm.isCollapsed = false;
            vm.headerService = headerService;
            vm.baseUrl = $location.protocol() + '://' + $location.host();
            if ($location.port() != '80') {
                vm.baseUrl = vm.baseUrl + ':' + $location.port();
            }

            vm.goToLogin = function() {
                DTM.trackAnalyticOnClick('login clicked');
                if (vm.isAlreadyOnSignUp()) {
                    $window.open('/login', '_self');
                    return;
                }
                $window.open('/login', '_self');
            };

            vm.goToSignUp = function() {
                DTM.trackAnalyticOnClick('sign up clicked');
                if (vm.isAlreadyOnLogin()) {
                    $window.open('/signup', '_self');
                    return;
                }

                $window.open('/signup', '_self');
            };

            vm.gotoHomePage = function() {
                $window.open('/', '_self');
            };

            /**
             * @memberof mainNavbar
             * @method authenticated
             * @name authenticated
             * @description Is user authenticated.
             * @returns {Boolean} authenticated
             */
            vm.authenticated = function() {
                vm.userData = apiConfig.userData;
                if (angular.isUndefined(vm.userData)) {
                    return false;
                } else if (vm.userData === '') {
                    return false;
                } else {
                    return true;
                }
            };

            vm.isAlreadyOnLogin = function() {
                return $location.url().startsWith('/login');
            };

            vm.isAlreadyOnSignUp = function() {
                return $location.url().startsWith('/signup');
            };

            /**
             * @memberof mainNavbar
             * @method collapse
             * @name collapse
             * @description Collapse dropdown.
             * @returns {Boolean} isCollapsed
             */
            vm.collapse = function() {
                vm.isCollapsed = !vm.isCollapsed;
                return vm.isCollapsed;
            };

            vm.authDecided = 'undefined';

            /**
             * @memberof mainNavbar
             * @method isAuthDecided
             * @name isAuthDecided
             * @description auth status getter
             * @returns {Boolean} authenticated
             */

            vm.isAuthDecided = function() {
                vm.authDecided = apiConfig.userIsAuthenticated();
                return vm.authDecided;
            };


            /**
             * @memberof mainNavbar
             * @method hasAvatar
             * @name hasAvatar
             * @description checkf if user has avatar
             * @returns {Boolean} true/false
             */
            vm.hasAvatar = function() {
                if (angular.isUndefined(vm.userData)) {
                    return false;
                } else if (angular.isUndefined(vm.userData.data)) {
                    return false;
                } else if (angular.isUndefined(vm.userData.data.data)) {
                    return false;
                } else if (angular.isUndefined(vm.userData.data.data.avatars)) {
                    return false;
                } else if (angular.isUndefined(vm.userData.data.data.avatars.image)) {
                    return false;
                } else if (vm.userData.data.data.avatars.image.length === 0) {
                    return false;
                } else if (angular.isUndefined(vm.userData.data.data.avatars.image[0])) {
                    return false;
                } else if (angular.isUndefined(vm.userData.data.data.avatars.image[0].url)) {
                    return false;
                } else {
                    return true;
                }
            };

            /**
             * @memberof mainNavbar
             * @method getUserAvatar
             * @name getUserAvatar
             * @description get the user avatar
             * @returns {string} user avatar img url
             */
            vm.getUserAvatar = function() {
                var avatar = vm.userData.data.data.avatars.image[0].urls['thumbnail'];
                if (avatar == 'https://media.truelocal.com.au/avatars/-50x50.jpg') {
                    return 'https://media.truelocal.com.au/avatars/-66x66.jpg';
                }
                return vm.userData.data.data.avatars.image[0].urls['thumbnail'];
            };

            /**
             * @memberof mainNavbar
             * @method getProfileUrl
             * @name getProfileUrl
             * @description get the profile url for auth user
             * @returns {string} user profile url
             */
            vm.getProfileUrl = function() {
                if (angular.isUndefined(vm.userData)) {
                    return '#';
                } else if (angular.isUndefined(vm.userData.data)) {
                    return '#';
                } else if (angular.isUndefined(vm.userData.data.data)) {
                    return '#';
                } else if (angular.isUndefined(vm.userData.data.data.profileUrl)) {
                    return '#';
                } else {
                    if (env.name === 'local') {
                        return $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/member/' + vm.userData.data.data.profileUrl;
                    } else {
                        return $location.protocol() + '://' + $location.host() + '/member/' + vm.userData.data.data.profileUrl;
                    }
                }
            };

            /**
             * @memberof mainNavbar
             * @method getProfileUrl
             * @name getProfileUrl
             * @description get the profile url for auth user
             * @returns {string} user profile url
             */
            vm.setLoggedInUser = function() {
                listingModels.setUserIdToVisit((_.has(vm.userData, 'data.data')) ? vm.userData.data.data.id : '');
                if(vm.menuMobile) {
                    vm.closeMobileMenu();
                }
            };

            /**
             * @memberof mainNavbar
             * @method getUserName
             * @name getUserName
             * @description get user name
             * @returns {string} user name
             */
            vm.getUserName = function() {
                return vm.userData.data.data.displayName;
            };

            /**
             * @memberof mainNavbar
             * @method loginModal
             * @name loginModal
             * @description open the login modal
             */

            vm.loginModal = function() {
                var _modalInstance = authService.authModal(modalFactory);
                _modalInstance.result.then(function() {
                    return apiConfig.recallUserAuthData();
                });

                DTM.trackAnalyticOnClick('login modal clicked');
            };

            /**
             * @memberof mainNavbar
             * @method signupModal
             * @name signupModal
             * @description open the signup modal
             */

            vm.signupModal = function() {
                var _modalInstance = authService.authModalSignup(modalFactory);

                _modalInstance.result.then(function() {
                    return apiConfig.recallUserAuthData();
                });
            };

            vm.switchLogin = function() {
                modalInstance.dismiss('cancel');

                // vm.signupModal();
            };

            /**
             * @memberof mainNavbar
             * @method signOut
             * @name signOut
             * @description Sign out the auth user
             */

            vm.signOut = function() {
                var userAvatar = vm.getUserAvatar();
                $cookies.put('user_avatar', userAvatar);
                $cookies.put('returning_user', 'true');
                var userProfileName = angular.isDefined(vm.userData.data.data.displayName) ? vm.userData.data.data.displayName : '';
                $cookies.put('welcome_name', userProfileName);
                var userProfileEmail = angular.isDefined(vm.userData.data.data.email) ? vm.userData.data.data.email : '';
                $cookies.put('welcome_mail', userProfileEmail);

                DTM.trackAnalyticOnClick('user logged out');

                vm.userData = '';

                if (angular.isDefined($rootScope.justSignedUp) && $rootScope.justSignedUp === true) {
                    $cookies.remove('justSignedUp');
                    $rootScope.justSignedUp = false;
                }

                return authService.signOut();
            };

            /**
             * @memberof mainNavbar
             * @method getBaseUrl
             * @name getBaseUrl
             * @description Get the base url using $location service
             * @return {string} url
             *
             * @example
             * https://truelocal.com.au
             */
            vm.getBaseUrl = function() {
                return $location.protocol() + '://' + $location.host();
            };

            vm.menuMobile = false;

            /**
             * @memberof mainNavbar
             * @method showMenu
             * @name showMenu
             * @description Displayes the fullscreen mobile menu
             *
             */

            vm.showMenu = function() {
                vm.menuMobile = true;
                angular.element(document.querySelector('body')).addClass('modal-open');
                angular.element(document.querySelector('html')).addClass('_menu-open');
                angular.element(window).scrollTop = 0;
                return vm.menuMobile;
            };

            vm.find = function(name) {
                vm.closeMobileMenu();
                $location.url('/find/' + name).search({
                    page: null,
                });
            };

            vm.openCategoriesPage = function() {
                vm.closeMobileMenu();
                $location.url('/business-type');
            };

            /**
             * @memberof mainNavbar
             * @method trackingCategory
             * @name trackingCategory
             * @description tracker for category click
             *
             */
            vm.trackingCategory = function() {
                DTM.trackAnalyticOnClick('category click');
            };


            /**
             * @memberof mainNavbar
             * @method swipeLeft
             * @name swipeLeft
             * @description action when user will swipe right over the fullscreen menu on mobile/tablet
             *    Will call the close menu function
             *    vm.closeMobileMenu();
             *
             */
            vm.swipeLeft = function() {
                return vm.closeMobileMenu();
            };

            /**
             * @memberof mainNavbar
             * @method closeMobileMenu
             * @name closeMobileMenu
             * @description will close the fullscreen menu on mobile/tablet
             *
             */
            vm.closeMobileMenu = function() {
                angular.element(document.querySelector('html')).removeClass('_menu-open');
                angular.element(document.querySelector('body')).removeClass('modal-open');

                vm.menuMobile = false;
                vm.isCollapsed = false;
                return vm.menuMobile;
            };

            vm.showSearch = true;

            /**
             * @memberof mainNavbar
             * @method getShowSearch
             * @name getShowSearch
             * @description Getter to check if the search form in header should be displayed.
             *
             */

            vm.getShowSearch = function() {
                return headerService.showSearchForm;
            };


            angular.element(window).bind('scroll', function() {
                // Changed to angular scroll function
                if (headerService.dynamicShowHideSearchForm === true) {
                    var homeHeadSearchWrapper = angular.element(document.querySelector('.home-page__search-wrapper'));
                    var homeHeadSearchElement = angular.element(document.querySelector('.home-page__search-wrapper fieldset'));
                    var headerHolderHeight = angular.element(document.querySelector('.header-holder'))[0].clientHeight;
                    var homeHeadSearchElementOffset = 0;
                    var homeHeadSearchElementOffset = (offset(homeHeadSearchElement) ? offset(homeHeadSearchElement).top : 0) + homeHeadSearchElement[0].clientHeight;
                    var ref = homeHeadSearchElementOffset - headerHolderHeight;
                    if ($window.scrollY > ref) {
                        if (headerService.showSearchForm === false) {
                            $timeout(function() {
                                headerService.showSearchForm = true;
                                headerService.logoClass = '';
                                homeHeadSearchWrapper.addClass('invisible'); // this is needed for Tabbing
                            }, 1);
                        }
                    } else {
                        if (headerService.showSearchForm === true) {
                            $timeout(function() {
                                headerService.showSearchForm = false;
                                headerService.logoClass = 'big';
                                homeHeadSearchWrapper.removeClass('invisible'); // this is needed for Tabbing
                            }, 1);
                        }
                    }
                }
            });


            function offset(elm) {
                var rawDom = elm[0];
                var _x = 0;
                var _y = 0;
                var body = document.documentElement || document.body;
                var scrollX = window.pageXOffset || body.scrollLeft;
                var scrollY = window.pageYOffset || body.scrollTop;
                _x = rawDom.getBoundingClientRect().left + scrollX;
                _y = rawDom.getBoundingClientRect().top + scrollY;
                return {
                    left: _x,
                    top: _y,
                };
            }

            this.addBusiness = addBusiness;


            vm.resetLoginPwd = function() {
                // angular.element(document).ready(function () {
                $rootScope.$on('$viewContentLoaded', function() {
                    var trigger = getParameterByName('trigger');
                    var hash = getParameterByName('hash');
                    var email = getParameterByName('email');
                    if (trigger === 'reset') {
                        var _modalInstance = authService.resetLoginPwd(modalFactory, null, email, hash);
                    }
                });
            };


            /**
             * @memberof mainNavbar
             * @method addBusiness
             * @name addBusiness
             * @description Opens Add business modal.
             */
            function addBusiness() {
                vm.addBussLive = env.addBussLive;
                if (env.addBussLive) {
                    $window.location = $window.location.origin + '/add-business';
                } else {
                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/shared/mainHeader/addbusinessmodal.html',
                        controller: ['$modalInstance', '$window', '$location', 'DTM', AddBusinessController],
                        controllerAs: 'vm',
                        size: 'dialog_add_business',
                    });

                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });

                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });
                }
                DTM.trackAnalyticOnClick('add missing business');
            }

            function AddBusinessController($modalInstance, $window, $location, DTM) {
                var vm = this;
                vm.trackAnalyticOnClick = function(p1, p2, location) {
                    DTM.trackAnalyticOnClick(p2);
                    $window.location.href = location;
                };
                /*  vm.navigateToAsb = function() {
                                         $window.location = $location.protocol() + '://' + $location.host() + '/add-business';

                     $window.location = $window.location.origin + '/add-business';
                 } */
            }

            $rootScope.$on('loginmodal:show', function() {
                vm.loginModal();
            });
            $rootScope.$on('loggedin', function() {
                // $log.info('user loggeIn');
                DTM.trackAnalyticOnClick('login modal clicked');
                DTM.trackAnalyticOnClick('member login:completed');
                apiConfig.userData = {
                    data: {},
                };
                apiConfig.userData.data.data = $rootScope.cachedUser;
                vm.authenticated();
            });

            $rootScope.$on('$locationChangeStart', function(event, toState, toStateParams) {
                vm.menuMobile = false;
            });

            vm.toggleCategoriesMenu = function() {
                var categoriesMenu = document.querySelector('.categories-dropdown-header');

                if (categoriesMenu) {
                    categoriesMenu.addEventListener('touchstart', function() {
                        var categoriesMenuList = document.querySelector('.drop_type_categories');
                        if (categoriesMenuList.style.display == 'none' || categoriesMenuList.style.display == '') {
                            categoriesMenuList.style.display = 'block';
                        } else {
                            categoriesMenuList.style.display = 'none';
                        }
                    });
                }
            };
        }
    }

    function HeaderService() {
        var service = {
            showSearchForm: false,
            logoClass: '',
            dynamicShowHideSearchForm: false,
        };


        return service;
    }

    function getParameterByName(name) {
        var url = window.location.href;

        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2]);
    }
})();
