(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpGallery
     * @description Directive display business photo gallery. Main image in gallery will be display larger. Clicking on
     * image open it in theatre view.  User can add photo. On every image is displayed sign weather it is approved by
     * business owner. For paid business gallery can contain video.
     *
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $window                   Angular window wrapper
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {factory}   DTM           TL tracking services
     * @param {factory}   scrollDetectWithTimeout   Factory what provide possibility to set option of detecting of scrolling with timeout to true or false.
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     *
     *
     * @example
     * <bdp-gallery bussiness-images="[object]" listing="[object]"></bdp-gallery>
     *
     */
    .directive('bdpGallery', bdpGallery)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name gallerySizeHandler
     * @description Directive handle dimensions of gallery element holder according to current view option and
     * available screen size.
     *
     * @param  {service}  $window                   Angular window wrapper
     * @param  {service}  $timeout                  Angular window.setTimeout service
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *
     */
    .directive('gallerySizeHandler', ['$window', '$timeout', 'platformService', '$document', gallerySizeHandler]);

    function gallerySizeHandler($window, $timeout, platformService, $document) {
        return {
            restrict: 'A',
            link: function(scope) {
                var _setActiveViewPort = function(_s) {
                    var _currentWindowWidth = platformService.getWidth();
                    if (_currentWindowWidth < 768) {
                        _s.vm.viewOption = 0;
                    } else if (_currentWindowWidth >= 768 && _currentWindowWidth <= 1085) {
                        _s.vm.viewOption = 1;
                    } else if (_currentWindowWidth > 1085 && _currentWindowWidth < 1296) {
                        _s.vm.viewOption = 2;
                    } else {
                        _s.vm.viewOption = 3;
                    }
                    $timeout(angular.bind(_s, _updateList, _s), 5);
                };

                var _updateList = function(_s) {
                    _s.vm.viewOption = _s.vm.viewOption || 0;
                };

                angular.element($window).on('resize', angular.bind(scope, _setActiveViewPort, scope));

                _setActiveViewPort(scope);
            },
            scope: {
                vm: '=',
            },
        };
    }

  /**
   /**
   * @memberof bdpGallery
   * @method isPaidListing_bdpGallery
   * @name isPaidListing_bdpGallery
   * @description directive controller
   *
   *
   *
   * @param {service}   $log                      Angular console log wrapper
   * @param {service}   $window                   Angular window wrapper
   * @param {factory}   authService               Factory what handle API request for authentication
   * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
   * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
   * @param {factory}   DTM           TL tracking services
   * @param {factory}   scrollDetectWithTimeout   Factory what provide possibility to set option of detecting of scrolling with timeout to true or false.
   * @param {directive} $modal                    ui-bootstrap modal wrapper
   */
  /** @ngInject */
    function bdpGallery() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpgallery/bdpgallery.html',
            scope: {
                bussinessImages: '=',
                listing: '=',
            },
            controller: ['$log', '$window', 'authService', 'modalFactory', 'platformService', 'DTM', 'scrollDetectWithTimeout', '$modal', '$document', 'windowScroll', BdpGalleryController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpGalleryController($log, $window, authService, modalFactory, platformService, DTM, scrollDetectWithTimeout, $modal, $document, windowScroll) {
            var _self = this;
            var vm = this;
            
      /**
       * @memberof bdpGallery
       * @name isPaidListing
       * @description Is it paid business.
       * @returns {Boolean} isPaidListing
       */

            vm.platformService = platformService;
            this.isPaidListing = false; // if it is paid setting true


      /**
       * @memberof bdpGallery
       * @name orderBy
       * @description OrderBy is set to date, and opposite in case when business is paid by order number.
       * @returns {String} date
       */
            this.orderBy = 'date';


      /**
       * @memberof bdpGallery
       * @name isOversized
       * @description Default false. Set to true in case when there are more images than currently displayed on board,
       * which cause that view all button comes in scope and add photo has 50% of height.
       * @returns {String} date
       */
            this.isOversized = false;


            this.viewOption = 0;

            angular.element($window).bind('reported-image', angular.bind(this, function(e, _imageId) {
                if (angular.isDefined(_imageId) && _imageId) {
                    for (var imageIndex = 0, imagesListLenght = this.bussinessImages.image.length; imageIndex < imagesListLenght; imageIndex++) {
                        if (this.bussinessImages.image[imageIndex].id == _imageId) {
                            this.bussinessImages.image.splice(imageIndex, 1);
                            this.bussinessImages.size -= 1;
                            imageIndex = imagesListLenght;
                        }
                    }
                }
            }));


      /**
       * @memberof bdpGallery
       * @method getLastToShow
       * @name getLastToShow
       * @description Switching view option based on number of photos (>2, >4, >6). Returning url to last photo.
       * @returns {String} lastToShow
       */
            this.getLastToShow = function() {
                if (angular.isUndefined(this.bussinessImages) || angular.isUndefined(this.bussinessImages.image)) {
                    return '';
                }
                switch (this.viewOption) {
                case 1:
                    if (this.getNumberOfAllPhotos() >= 2) {
                        if (angular.isUndefined(this.bussinessImages.image[1]) ||
                angular.isUndefined(this.bussinessImages.image[1].urls) ||
                angular.isUndefined(this.bussinessImages.image[1].urls.medium)) {
                            return '';
                        } else {
                            return this.bussinessImages.image[1].urls.medium;
                        }
                    }
                    break;
                case 2:
                    if (this.getNumberOfAllPhotos() >= 4) {
                        if (angular.isUndefined(this.bussinessImages.image[3]) ||
                angular.isUndefined(this.bussinessImages.image[3].urls) ||
                angular.isUndefined(this.bussinessImages.image[3].urls.medium)) {
                            return '';
                        } else {
                            return this.bussinessImages.image[3].urls.medium;
                        }
                    }
                    break;
                case 3:
                    if (this.getNumberOfAllPhotos() >= 6) {
                        if (angular.isUndefined(this.bussinessImages.image[5]) ||
                angular.isUndefined(this.bussinessImages.image[5].urls) ||
                angular.isUndefined(this.bussinessImages.image[5].urls.medium)) {
                            return '';
                        } else {
                            return this.bussinessImages.image[5].urls.medium;
                        }
                    }
                    break;
                default:
                    return '';
                }
            };

      /**
       * @memberof bdpGallery
       * @method canUpload
       * @name canUpload
       * @description Return true if a non-business owner can upload images
       * @returns {Boolean} 
       */
            this.canUpload = function() {
                if(DTM.getTopLevelCategory() == 'Adult')
                    return false;
                return true;
            };

      /**
       * @memberof bdpGallery
       * @method getAddTypeSize
       * @name getAddTypeSize
       * @description Return type size as string "big" if there is no more photos.
       * @returns {String} lastToShow
       */
            this.getAddTypeSize = function() {
                if (this.hasMorePhotos()) {
                    return '';
                }
                return 'big';
            };


      /**
       * @memberof bdpGallery
       * @method noPhotos
       * @name noPhotos
       * @description Return true if no more photos to display.
       * @returns {Boolean}
       */
            this.noPhotos = function() {
                if (angular.isUndefined(this.bussinessImages)) {
                    return true;
                }
                if (angular.isDefined(this.bussinessImages.size) && this.bussinessImages.size > 0) {
                    return false;
                }
                return true;
            };


      /**
       * @memberof bdpGallery
       * @method hasMorePhotos
       * @name hasMorePhotos
       * @description Check for displaying of more photos based on number of all photos and current view options.
       * @returns {Boolean}
       */
            this.hasMorePhotos = function() {
                var numberOfAllPhotos = this.getNumberOfAllPhotos();
                if (angular.isUndefined(this.viewOption) || angular.isUndefined(numberOfAllPhotos)) {
                    return false;
                }
                switch (true) {
                case (this.viewOption == 0 && numberOfAllPhotos != 1) ||
          (this.viewOption == 1 && numberOfAllPhotos > 1) ||
          (this.viewOption == 2 && numberOfAllPhotos > 3) ||
          (this.viewOption == 3 && numberOfAllPhotos > 5) :
                    return true;
                case (this.viewOption == 0 && numberOfAllPhotos == 1) ||
          (this.viewOption == 1 && numberOfAllPhotos <= 1) ||
          (this.viewOption == 2 && numberOfAllPhotos <= 3) ||
          (this.viewOption == 3 && numberOfAllPhotos <= 5) :
                    return false;
                default:
                    return false;
                }
            };


      /**
       * @memberof bdpGallery
       * @method getNumberOfAllPhotos
       * @name getNumberOfAllPhotos
       * @description Return number of photos.
       * @returns {Number}
       */
            this.getNumberOfAllPhotos = function() {
                if (this.bussinessImages && this.bussinessImages.size) {
                    return this.bussinessImages.size;
                }
                if (this.bussinessImages && this.bussinessImages.image) {
                    return this.bussinessImages.image.length;
                }
                return 5;
            };


      /**
       * @memberof bdpGallery
       * @method openTheatre
       * @name openTheatre
       * @description Method for opening modal with theatre view for listing of photos.
       * @params {_index} default image index
       */
            this.openTheatre = function(_index) {
                var scrollNo = 0;
                if (angular.isUndefined(_index)) {
                    return;
                }

                $log.debug('openTheatre');

                DTM.trackAnalyticOnClick('view images');

                var galleryTheatre = $modal.open({
                    templateUrl: '/app/components/bdp/bdpgallerynavigator/bdpgallerynavigator.html',
                    controller: 'BdpGalleryNavigatorController',
                    controllerAs: 'gallery',
                    size: 'dialog_size_fullscreen gallery-modal-open',
                    resolve: {
                        images: function() {
                            return _self.bussinessImages;
                        },
                        listing: function() {
                            return _self.listing;
                        },
                        selectedImage: function() {
                            return _index;
                        },
                    },
                });
                galleryTheatre.opened.then(function() {
                    scrollNo = $window.scrollY;
                });

                galleryTheatre.result.then(function(selectedItem) {
                    document.body.classList.remove('noHorizontalScroll');
                    scrollDetectWithTimeout.enable();
                    $document.scrollTop(scrollNo);
                }, function() {
                    $log.debug('Modal dismissed at: ' + new Date());
                });
            };


      /**
       * @memberof bdpGallery
       * @method showAuthPrompt
       * @name showAuthPrompt
       * @description Method for opening user login modal if unlogged user want to action that request login.
       * @params {_index} default image index
       */
            this.showAuthPrompt = function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                    controller: 'ModalAuthPromptController',
                    controllerAs: 'vm',
                    size: 'dialog_size_small',
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
            };
        }
    }
})();
