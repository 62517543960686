(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('dmsSignupDirective', dmsSignupDirective);

    function dmsSignupDirective() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/dms/signup.html',
            controller: ['$log', 'swear', '$http', 'apiConfig', '$stateParams', 'env', POSignupController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function POSignupController($log, swear, $http, apiConfig, $stateParams, env) {
            var vm = this;

            vm.env = env;
            vm.userData = apiConfig.userData;
            vm.validApiPhone = false;
            vm.marketing = false;
            vm.alreadyRegistered = false;
            vm.errorMessage = '';
            vm.vCitaToken = '';

            vm.contents = {
                errors: [],
            };

            vm.stateList = ["NSW", "VIC", "TAS", "QLD", "NT", "ACT", "WA", "SA"];

            vm.states = {
                signingIn: false,
                httpError: false,
                showForm: true,
                showErrorMessage: false,
            };

            $http.get(apiConfig.generate('dms/' + $stateParams.listingId + '/signup'), { ignoreLoadingBar: true }, apiConfig.getXAuthToken())
                .then(function(response) {

                    vm.signupPrepopulate = response.data;

                    vm.form = {
                        businessName: vm.signupPrepopulate.hasOwnProperty('data') ? vm.signupPrepopulate.data.businessName : '',
                        streetNumber: vm.signupPrepopulate.hasOwnProperty('data') ? vm.signupPrepopulate.data.address.streetNumber : '',
                        streetName: vm.signupPrepopulate.hasOwnProperty('data') ? vm.signupPrepopulate.data.address.streetName : '',
                        suburb: vm.signupPrepopulate.hasOwnProperty('data') ? vm.signupPrepopulate.data.address.suburb : '',
                        postCode: vm.signupPrepopulate.hasOwnProperty('data') ? vm.signupPrepopulate.data.address.postCode : '',
                        state: vm.signupPrepopulate.hasOwnProperty('data') ? vm.signupPrepopulate.data.address.state : '',
                        email: vm.signupPrepopulate.hasOwnProperty('data') ? vm.signupPrepopulate.data.email : '',
                        phone: vm.signupPrepopulate.hasOwnProperty('data') ? vm.signupPrepopulate.data.phone : '',
                    };

                }).catch(function(response) {

                var errors = [];
                vm.errors = errors;

                if (response.data) {
                    if (response.data.meta.errors) {
                        Object.keys(response.data.meta.errors).forEach(function(key) {
                            if (key !== 'length') {
                                errors = response.data.meta.errors[key];
                            }
                        });
                    }

                    errors = Object.values(errors);
                    var messages = response.data.meta.messages || [];
                    vm.contents.errors = errors.concat(messages);
                    vm.errorMessage = vm.contents.errors.toString();
                    vm.states.showErrorMessage = true;

                    if(vm.errorMessage == 'Already registered for the service' || response.data.meta.code == 410760023){
                        vm.alreadyRegistered = true;
                        vm.states.showForm = false;
                    } else if(vm.errorMessage == '') {
                        vm.errorMessage = 'An unexpected error has occurred.';
                    }

                    $(window).scrollTop(0);
                }
            });

            vm.allSwars = 0;

            var test = {
                '$viewValue': 'fs',
                '$validators': {},
                '$asyncValidators': {},
                '$parsers': [],
                '$formatters': [null],
                '$viewChangeListeners': [],
                '$untouched': false,
                '$touched': false,
                '$pristine': false,
                '$dirty': true,
                '$valid': false,
                '$invalid': true,
                '$error': { 'pattern': true, 'email': true },
                '$name': 'senderEmail',
                '$options': null,
            };

            vm.specialFocus = function() {
                vm.validApiPhone = false;
            };

            vm.actions = {

                isValid: function(field) {
                    if (field) {
                        return field.$touched && field.$invalid;
                    } else {
                        return false;
                    }
                },

                hasSwear: function(field) {
                    if (field) {
                        var value = field.$viewValue || '';
                        var isSwear = swear.hasSwearing(value);
                        return isSwear;
                    } else {
                        return false;
                    }
                },

                submit: function() {

                    vm.states.signingIn = true;
                    vm.states.httpError = false;
                    vm.states.showErrorMessage = false;
                    vm.contents.errors.length = 0;

                    if(!vm.form.streetNumber){
                        vm.form.streetNumber = '';
                    }

                    if(!vm.form.streetName){
                        vm.form.streetName = '';
                    }

                    $http.post(
                        apiConfig.generate('dms/' + $stateParams.listingId + '/signup'), {
                            email: vm.form.email,
                            lastName: vm.form.lastName,
                            firstName: vm.form.firstName,
                            businessName: vm.form.businessName,
                            phone: vm.form.phone,
                            subscriptions: {
                                marketing: vm.marketing
                            },
                            address: {
                                suburb: vm.form.suburb,
                                state: vm.form.state,
                                streetNumber: vm.form.streetNumber,
                                streetName: vm.form.streetName,
                                postCode: vm.form.postCode
                            }
                        },
                        apiConfig.getXAuthToken()
                    )
                        .then(function(response) {

                            $(window).scrollTop(0);
                            vm.vCitaToken = response.data.data.vCitaToken;
                            vm.states.signingIn = false;
                            vm.states.showErrorMessage = false;
                            vm.states.showForm = false;

                        }, function(response) {

                            vm.states.signingIn = false;
                            vm.states.httpError = true;
                            var errors = [];
                            vm.errors = errors;

                            if (response.data) {
                                if (response.data.meta.errors) {
                                    Object.keys(response.data.meta.errors).forEach(function(key) {
                                        if (key !== 'length') {
                                            errors[key] = response.data.meta.errors[key];
                                        }
                                    });

                                    if (angular.isDefined(response.data.meta.errors.phone)) {
                                        vm.validApiPhone = true;
                                    }
                                }

                                errors = Object.values(errors);
                                var messages = response.data.meta.messages || [];
                                vm.contents.errors = errors.concat(messages);
                                vm.errorMessage = vm.contents.errors.toString();
                                vm.states.showErrorMessage = true;
                                $(window).scrollTop(0);

                                if(vm.errorMessage == '') {
                                    vm.errorMessage = 'An unexpected error has occurred.';
                                }
                            }
                        });
                },
                activate: function() {
                    window.open("https://app.pocketoffice.com.au/my/account_activation?auth_token=" + vm.vCitaToken, "_blank");
                },
                alreadyRegistered: function() {

                    if(vm.env.dms){
                        vm.poLoginUrl = vm.env.dms.po.loginUrl;
                    } else {
                        vm.poLoginUrl = "http://pocketoffice.com.au/login";
                    }
                    window.open(vm.poLoginUrl, "_blank");
                },
            };
        }
    }
})();