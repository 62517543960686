(function() {

    'use strict';

    /**
     * @memberOf Directive 
     * @namespace SearchFacet
     * @description Faceted search for related business types and popular suburbs
     * @param
     * @example <search-facet facet-data="main.refineData"></search-facet>
     */
    angular
        .module('truelocal')
        .directive('searchFacet', searchFacet);

    /** @ngInject */
    function searchFacet () {

        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/searchfacet/searchfacet.html',
            scope: {
                searchData: '=',
                facetData: '='
            },
            controller: SearchFacetController,
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;


        /**
         * @memberOf Directive.SearchFacet
         * @name SearchFacetController
         * @description Controller for this view
         * @param {Service} searchConfigManager
         */
        /** @ngInject */
        function SearchFacetController(searchConfigManager, apiConfig, platformService, DTM, $location) {
            var vm = this;

            vm.getFacets = getFacets;
            vm.getFacetName = getFacetName;
            vm.getFacetUrl = getFacetUrl;
            vm.getFacetClass = getFacetClass;
            vm.requestFacet = requestFacet;
            vm.searchFacet = searchFacet;
            vm.isMobile = isMobile;
            vm.trackAnalytics = trackAnalytics;

            vm.facets = [];
            vm.showMoreValue;
            vm.showCount = 6;
            vm.prevIndex = -1;
            vm.refinedSearch = false;
            vm.pageType = $location.path().split("/")[1];

            vm.category = '/' + searchConfigManager.getProperty('category');
            vm.category = (vm.category === "/") ? "" : vm.category;
            vm.category = vm.category.replace(/[ ]/gi, '-');

            vm.facetTypes = {
                state_facet: {
                    name: 'States',
                    class: 'states',
                    order: 0
                },              
                region_facet: {
                    name: 'Regions',
                    class: 'regions',
                    order: 1
                },
                suburb_facet: {
                    name: 'Popular Suburbs',
                    class: 'suburbs',
                    order: 2
                },
                location_facet: {
                    name: 'Popular Suburbs',
                    class: 'locations',
                    order: 3
                },
                categories_facet: {
                    name: 'Related Business Types',
                    class: 'related-businsess',
                    order: 4
                },
                popular_categories_facet: {
                    name: 'Popular Business Types',
                    class: 'popular-business',
                    order: 5,
                },
            };

            vm.stateFacets = [
                {name: 'NSW', seoUrl: vm.category + '/nsw'},
                {name: 'SA', seoUrl: vm.category + '/sa'},
                {name: 'VIC', seoUrl: vm.category + '/vic'},
                {name: 'TAS', seoUrl: vm.category + '/tas'},
                {name: 'QLD', seoUrl: vm.category + '/qld'},
                {name: 'ACT', seoUrl: vm.category + '/act'},
                {name: 'WA', seoUrl: vm.category + '/wa'},
                {name: 'NT', seoUrl: vm.category + '/nt'}
            ];

            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @name getFacets
             * @description Return the list of facets
             * @returns {Object Array}
             */
            function getFacets() {
                vm.facets.length = 0;

                for (var i = 0; i < vm.facetData.length; i++) {
                    var facetType = vm.facetData[i].type;
                    var facetTypeIndex = vm.facetTypes[facetType] && vm.facetTypes[facetType].order;
                    
                    if (angular.isDefined(facetTypeIndex) && facetTypeIndex !== null) {
                        if (facetTypeIndex == 0 && vm.facetData[i].element && vm.facetData[i].element.length) {
                            vm.facetData[i].element = [];
                            angular.extend(vm.facetData[i].element, vm.stateFacets);
                        }
                        if (vm.prevIndex >= 0) {
                            if (facetTypeIndex > vm.prevIndex) {
                                if (vm.facetData[i].element && vm.facetData[i].element.length) {
                                    vm.facets.push(vm.facetData[i]);
                                }
                            } else {
                                if (vm.facetData[i].element && vm.facetData[i].element.length) {
                                    vm.facets.unshift(vm.facetData[i]);
                                }
                            }
                        } else {
                            vm.prevIndex = facetTypeIndex;
                            vm.facets.push(vm.facetData[i]);
                        }
                    }
                }
                return vm.facets;
            };

            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @name getFacetClass
             * @description Return the css class for this facet
             * @return {String}
             */
            function getFacetClass(index) {
                if((angular.isUndefined(index) || !index) && index !== 0) {
                    return;
                }
                var facetType = vm.facets[index].type;
                return vm.facetTypes[facetType].class;
            }

            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @name getFacetName
             * @description Return the name of the facet
             * @param {Integer} index
             * @returns {String}
             */
            function getFacetName(index) {
                if((angular.isUndefined(index) || !index) && index !== 0) {
                    return;
                }
                var facetType = vm.facets[index].type;
                return vm.facetTypes[facetType].name;
            };

            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @name getFacetUrl
             * @description Return the url of the facet
             * @param {String} seoUrl 
             * @returns {String}
             */
            function getFacetUrl(seoUrl) {
                var protocol = $location.protocol() + "://";
                var host = $location.host();
                var pageType = "/" + $location.path().split("/")[1];
                var port = "";

                if(host === 'localhost' || host === '127.0.0.1') {
                    port = ":" + $location.port();
                }

                return protocol + host + port + pageType + seoUrl.replace(' ', '-');
            }

            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @name requestFacet
             * @description sends a request for this facet
             * @param {Object} facetItem
             * @param {String} facetType
             */
            function requestFacet($event, item, facetType) {
                if (angular.isUndefined($event)) {
                    $event.preventDefault();
                }
                var paths = $location.path().split("/");
                var pageType = paths[1];
                var searchTarget = paths[2];
                var seoUrlPaths = item.seoUrl ? item.seoUrl.split("/") : "";

                if(pageType === 'search'){
                    if(["location_facet", "categories_facet", "suburb_facet"].indexOf(facetType) > -1){
                        if(["location_facet", "suburb_facet"].indexOf(facetType) > -1){
                            apiConfig.searchFilteredbySuburb = true;
                        } 
                        $location.path('/search' + item.seoUrl);
                    } else {
                        if("state_facet" === facetType){
                            apiConfig.searchFilteredbyState = true;
                        } else if ("region_facet" === facetType){
                            apiConfig.searchFilteredbyRegion = true;
                        }
                        $location.path('/search' + (("state_facet" === facetType && searchTarget) ? "/" + searchTarget : "") + item.seoUrl);
                    }
                } else if(pageType === 'search-location') {
                    switch(facetType) {
                        case "location_facet":
                        case "suburb_facet":
                            $location.path('/search-location/' + item.filterByName.toLowerCase().replace(",","").split(" ").join('-'));
                            break;
                        case "state_facet":
                            $location.path('/search-location' + item.seoUrl);
                            break;
                        case "region_facet":
                            $location.path('/search-location/' + seoUrlPaths[2] + "-" + seoUrlPaths[1] );
                            break;
                        default:
                            $location.path('/search' + item.seoUrl);
                    }
                }

                vm.trackAnalytics(facetType);
            }

            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @name searchFacet
             * @description Make a query for this facet
             * @param {String} facetType 
             * @param {Object} item 
             */
            function searchFacet(facetType, item) {
                if (vm.refinedSearch) {
                    return;
                }
                vm.refinedSearch = true;

                searchConfigManager.refineSearchResults(function() {
                    vm.refinedSearch = false;
                    vm.facetData = vm.searchData.facets;
                }, facetType, item);
            };

            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @name isMobile()
             * @description Check if viewport is mobile
             */
            function isMobile() {
                if (platformService.getWidth() < 768 && !platformService.isIPad()) {
                    return true;
                }
                return false;
            }

            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @name trackAnalytics
             * @description Track clicks on facets
             * @param {String} facetType 
             */
            function trackAnalytics(facetType) {
                switch(facetType){
                    case "categories_facet":
                        DTM.trackAnalyticOnClick('refine by related business');
                        break;
                    case "location_facet":
                    case "suburb_facet":
                        DTM.trackAnalyticOnClick('refine by suburb');
                        break;
                    case "state_facet":
                    case "region_facet":
                        DTM.trackAnalyticOnClick('refine by region');
                        break;
                }
            }
            
            /**
             * @memberof Directive.SearchFacet.SearchFacetController
             * @method checkResultsOffset
             * @name checkResultsOffset
             * @description Checks is it more result to be loaded available, and return true if it is.
             * @returns {Boolean} resultsOffset
             */
            vm.checkResultsOffset = function() {
                if (angular.isDefined(vm.searchData) && angular.isDefined(vm.searchData.size) && vm.searchData.size) {
                    return searchConfigManager.hideLoadMoreButton();
                }
                return true;
            };
        }
    }

})();