/**
 * Created by adu on 30/01/16.
 */
Object.defineProperty( Object.prototype, '_has', {value: function( needle ) {
    var obj = this;
    var needles = needle.split( '.' );
    var needles_full=[];
    var needles_square;
    for( var i = 0; i<needles.length; i++ ) {
        needles_square = needles[i].split( '[' );
        if(needles_square.length>1) {
            for( var j = 0; j<needles_square.length; j++ ) {
                if(needles_square[j].length) {
                    needles_full.push(needles_square[j]);
                }
            }
        }else{
            needles_full.push(needles[i]);
        }
    }
    for( var i = 0; i<needles_full.length; i++ ) {
        var res = needles_full[i].match(/^((\d+)|"(.+)"|'(.+)')\]$/);
        if (res != null) {
            for (var j = 0; j < res.length; j++) {
                if (res[j] != undefined) {
                    needles_full[i] = res[j];
                }
            }
        }

        if( typeof obj[needles_full[i]]=='undefined') {
            return false;
        }
        obj = obj[needles_full[i]];
    }
    return true;
}});

String.prototype.capitalizeSentence = function() {
    var _strToCapitalize = this.toLowerCase();
    return _strToCapitalize.replace(/((^|[^a-zA-Z])+([a-zA-Z]{0})+?([^a-zA-Z]|[a-zA-Z]))/gi, function(char) {
        return char.toUpperCase();
    });
};

String.prototype.capitalizeSuburb = function() {
    return this.replace(/([,]+?\s)+[a-zA-Z]{1,}/g, function(chars) {
        return chars.toUpperCase();
    });
};

String.prototype.capitalize = function() {
    var _str = this.toLowerCase();
    return _str.charAt(0).toUpperCase() + _str.slice(1);
};

(function() {
    'use strict';

    angular
        .module('truelocal')

        // executes a function or opens a link
        .directive('ngClickOpen', function() {
            return {
                restrict: 'A',
                link: function(scope, elm, attrs) {
                    var ontouchFn = scope.$eval(attrs.ngClickOpen);
                    elm.bind('touchstart', function(evt) {
                        scope.$apply(function() {
                            if(typeof ontouchFn == 'function') {
                                ontouchFn.call(scope, evt.which);
                            }else{
                                window.open(ontouchFn, '_self');
                            }
                        });
                    });
                    elm.bind('click', function(evt) {
                        scope.$apply(function() {
                            if(typeof ontouchFn == 'function') {
                                ontouchFn.call(scope, evt.which);
                            }else{
                                window.open(ontouchFn, '_self');
                            }
                        });
                    });
                },
            };
        });
})();

