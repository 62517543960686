(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpPendingReview
     * @description Directive handle displaying of user review that is still on pending status.
     *
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *
     * @example
     * <bdp-pending-review user-review="[object]"></bdp-pending-review>
     */
    .directive('bdpPendingReview', bdpPendingReview);

  /** @ngInject */
    function bdpPendingReview() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdppendingreview/bdppendingreview.html',
            scope: {
                userReview: '=',
                enteredComp: '=',
                primaryProduct: '=',
            },
            controller: BdpPendingReviewController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    /**
     * @memberof bdpPendingReview
     * @name BdpPendingReviewController
     * @method BdpPendingReviewController
     * @description directive controller
     *
     * @param {factory}   apiConfig                 Api configuration factory
     *
     * @example
     * <bdp-pending-review user-review="[object]"></bdp-pending-review>
     */

    /** @ngInject */
        function BdpPendingReviewController(apiConfig, platformService, DTM) {
      /** this is for Comp banner */
            this.compRunning = apiConfig.compToggle();
      /**
       * @memberof bdpPendingReview
       * @method userHasReview
       * @name userHasReview
       * @description Returns true if current user already added review.
       * @returns {Boolean} userHasReview
       */
            this.userHasReview = function() {
                if (angular.isDefined(this.userReview) && this.userReview.userReview) {
                    return true;
                }
                return false;
            };


      /**
       * @memberof bdpPendingReview
       * @method getUserAvatar
       * @name getUserAvatar
       * @description Get link to user avatar thumbnail, or empty string if it doesn't exist.
       * @returns {String} avatar url
       */
            this.getUserAvatar = function() {
                if (apiConfig.userData &&
          apiConfig.userData.data &&
          apiConfig.userData.data.data &&
          apiConfig.userData.data.data.avatars &&
          apiConfig.userData.data.data.avatars.image &&
          apiConfig.userData.data.data.avatars.image.length) {
                    return apiConfig.userData.data.data.avatars.image[0].urls.thumbnail;
                }
                return '';
            };


      /**
       * @memberof bdpPendingReview
       * @method getUserName
       * @name getUserName
       * @description Get current user display name.
       * @returns {String} userName
       */
            this.getUserName = function() {
                if (apiConfig.userData &&
          apiConfig.userData.data &&
          apiConfig.userData.data.data &&
          apiConfig.userData.data.data.displayName) {
                    return apiConfig.userData.data.data.displayName;
                }
                return '';
            };

      /**
       * @memberof bdpPendingReviewComment
       * @method isTouchDevice
       * @name isTouchDevice
       * @description Return true for screen width < 991 and false if screen width > 991 and browser agent is mobile
       * @returns {bool} true/false
       */

            this.isTouchDevice = function() {
                if (platformService.isMobilePlatform() && platformService.getWidth() < 991) {
                    return true;
                }
                return false;
            };

      /**
       * @memberof bdpPendingReviewComment
       * @method triggerTooltip
       * @name triggerTooltip
       * @description show tooltip in mobiles and small devices
       */
            this.triggerTooltip = function() {
                if(this.isTouchDevice()) {
                    angular.element(document.querySelector('#tooltip-pendingmod')).hasClass('hide') ? angular.element(document.querySelector('#tooltip-pendingmod')).removeClass('hide') : angular.element(document.querySelector('#tooltip-pendingmod')).addClass('hide');
                }
            };

      /**
       * @memberof bdpPendingReviewComment
       * @method trackingPreModReviewTooltip
       * @name trackingPreModReviewTooltip
       * @description tracker for pre-moderated review: params retrieve event to catch
       *
       */
            this.trackingPreModReviewTooltip = function(id) {
                DTM.trackAnalyticOnClick(id);
            };
            
            if(angular.isDefined(this.userReview)) {
                if(angular.isDefined(this.userReview.userReview) && angular.isDefined(this.userReview.userReview.userComment)) {
                    this.userReview.userReview.userComment = this.userReview.userReview.userComment.replace(/[\n\r]/g, '<br>');
                } 
            }
        }
    }
})();
