(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name locationBar
     * @description trade location bar
     */
    .directive('locationBar', locationBar);

  /** @ngInject */
    function locationBar($log) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/tradiepage/locationbar/locationbar.html',
            controller: ['$state', locationBarController],
            scope: {
                city: '=',
            },
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function locationBarController($state) {
            var vm = this;

            vm.isActiveCity = function(city) {
                return city === vm.city;
            };

            vm.goToCity = function(event, city) {
                event.preventDefault();
                event.stopPropagation();
                $log.debug('go to', city);
                $state.go('tradies', {path: city});
            };
        }
    }
})();
