(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpCoverphoto
     * @description Directive hold tools for business owner to edit cover photo. It's showed only when business owner
     * is signed in and located on his business page. "Edit cover photo" button is showed next to
     * "bookmarks" buttons Clicking button opens modal with options to upload new photo, or select from existent
     * source.
     *
     * @param {service}   $scope                    Angular scope service
     * @param {service}   $log                      Angular console log wrapper
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {service}   $http                     angular http service
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     *
     * @example
     * <bdp-coverphoto show="[bool]" details="[object]"></bdp-coverphoto>
     */
    .directive('bdpCoverphoto', bdpCoverPhoto);

  /** @ngInject */
    function bdpCoverPhoto() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpcoverphoto/bdpcoverphoto.html',
            scope: {
                show: '=',
                details: '=',
            },
            controller: ['$scope', '$log', '$modal', '$http', 'apiConfig', 'platformService', 'windowScroll', 'errorService', '$rootScope', 'DTM', BdpCoverPhotoController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */

        function BdpCoverPhotoController($scope, $log, $modal, $http, apiConfig, platformService, windowScroll, errorService, $rootScope, DTM) {
            var vm = this;
            vm.isMobile = platformService.isMobile();
            $scope.$on('show-cover-photo', function() {
                $log.debug('trigger show photo cover');
                vm.coverPhotoModal(vm.details);
            });


      /**
       * @memberof bdpCoverphoto
       * @method coverPhotoModal
       * @name coverPhotoModal
       * @description Opens "Edit cover photo" modal.
       *
       * @param {Object} details  Listing details from scope
       *
       */
            this.coverPhotoModal = coverPhotoModal;


      /**
       * @memberof bdpCoverphoto
       * @method coverPhotoModalThankYou
       * @name coverPhotoModalThankYou
       * @description Shows "Thank you" modal after user finished editing.
       */
            this.coverPhotoModalThankYou = coverPhotoModalThankYou;


      /**
       * @memberof bdpCoverphoto
       * @method save
       * @name save
       * @description Save cover photo by making and HTTP post request to the API
       */
            this.save = function() {
                vm.saving = true;
                var id = vm.details.newCover.id;
                $http.post(apiConfig.generate('listings', vm.details.id, 'images/' + id + '/set-cover'), null, apiConfig.getXAuthToken()).then(function successCallback(response) {
                    vm.details.coverImage = vm.details.newCover;
                    vm.details.newCover = null;
                    vm.details.oldCover = null;
                    DTM.trackAnalyticOnClick('add cover image complete');
          // comment this to still show the cover photo buttons
                    vm.show = !vm.show;
                    vm.details.newCover = null;
                    vm.saving = false;
                    vm.coverPhotoModalThankYou(vm.details, apiConfig);
                    $rootScope.$broadcast('show-new-cover-photo');
                }, function errorCallback(response) {
                    vm.saving = false;
                    alert(errorService.getErrorMessage(response));
                });
            };


      /**
       * @memberof bdpCoverphoto
       * @method cancel
       * @name cancel
       * @description Cancel changing cover photo. In case that a photo was uploaded will make an HTTP post request to API to delete the photo
       */
            this.cancel = function() {
                if(vm._has('show')) {
                    vm.show=!vm.show;
                }
                if(vm._has('details')) {
                    vm.details.newCover = null;
                    if(vm._has('details.oldCover') && vm.details.oldCover!=null) {
                        vm.details.cover=vm.details.oldCover;
                    }
                }

                if(vm._has('details.uploadedCover.id') && vm.details.uploadedCover && vm.details.uploadedCover.id!=vm.details.coverImage.id) {
                    var forceDelete = {};
                    if(vm.details.uploadedCover && vm.details.uploadedCover.status && vm.details.uploadedCover.status.moderationStatus === 'pending') {
                        forceDelete = {
                            'forceDelete': true,
                        };
                    }
                    $http.post(apiConfig.generate('images', vm.details.uploadedCover.id, '/delete', forceDelete), null, apiConfig.getXAuthToken()).then(function(response) {
                        vm.details.uploadedCover=null;
                    }, function errorCallback(response) {
                        alert(errorService.getErrorMessage(response));
                    });
                }else if(vm._has('details.uploadedCover.id')) {
                    vm.details.uploadedCover=null;
                }
            };

      // modal
            function coverPhotoModal(details) {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/bdpcoverphoto/coverphotomodal.html',
                    controller: ['$modalInstance', 'details', 'Upload', '$timeout', 'apiConfig', '$http', '$interval', 'platformService', 'errorService', CoverPhotoModalController],
                    controllerAs: 'vm',
                    size: 'dialog_size_middle',
                    resolve: {
                        details: function() {
                            return details;
                        },
                    },
                });
                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
            }

            function coverPhotoModalThankYou(details, apiConfig) {
                if (apiConfig.isSensitiveCategory(details.categoryId) == true) {
                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/bdpcoverphoto/coverphotomodalthankyou.html',
                        controller: ['$modalInstance', 'details', function($modalInstance, details) {
                            var vm = this;
                            vm.details = details;
                            vm.moderation = function() {
                                if (apiConfig.isSensitiveCategory(details.categoryId) == true) {
                                    return true;
                                }
                                return false;
                            };
                        }],
                        controllerAs: 'vm',
                        size: 'dialog_size_middle',
                        resolve: {
                            details: function() {
                                return details;
                            },
                        },
                    });

                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });
                }
            }

      /**
       * @memberof bdpCoverphoto
       * @method CoverPhotoModalController
       * @name CoverPhotoModalController
       * @description
       *  Controller function to handle the actions into the change photo modal
       *
       *  @param  {service}   $modalInstance    ui-bootstrap modal event handler wrapper
       *  @param  {object}    details           listing details from parent-scope
       *  @param  {service}   Upload            https://github.com/danialfarid/ng-file-upload
       *  @param  {service}   $timeout          Angular window.setTimeout service
       *  @param  {factory}   apiConfig         Api configuration factory
       *  @param  {service}   $http             angular http service
       *  @param  {service}   $interval         Angular setInterval service
       *  @param  {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
       */

            function CoverPhotoModalController($modalInstance, details, Upload, $timeout, apiConfig, $http, $interval, platformService, errorService) {
                var vm = this;
                vm.details = details;
                vm.uploading = false;
                vm.selectedCover = 0;
                vm.errorMsg = '';
                vm.coverImagesLoaded = false;
                vm.isMobile = platformService.isMobile();

                if (angular.isUndefined(vm.details.oldCover)) {
                    vm.details.newCover = null;
                    vm.details.oldCover = null;
                }
        /**
         * @memberof bdpCoverphoto
         * @method uploadPhoto
         * @name uploadPhoto
         * @description Member of CoverPhotoModalController
         * Makes the files upload using Upload service
         * @param {object} file   File object from upload input
         *
         */
                vm.uploadPhoto = function(file) {
                    vm.uploading = true;
                    vm.errorMsg = '';
                    file.upload = Upload.upload({
                        url: apiConfig.generate('listings', details.id, 'images/add'),
                        data: {file: file, validfor: 'cover'},
                        headers: apiConfig.getXAuthTokenShort(),
                    });

                    file.upload.then(function(response) {
                        $timeout(function() {
                            file.result = response.data;
                            var i = 0;
                            var test = $interval(function() {
                                $http({
                                    method: 'HEAD',
                                    url: file.result.data.image[0].urls.cover,
                                }).then(function successCallback() {
                                    $interval.cancel(test);
                                    $timeout(function() {
                                      vm.uploading = false;
                                      if (vm.details.oldCover == null) {
                                        vm.details.oldCover = vm.details.cover;
                                    }
                                      vm.details.cover = file.result.data.image[0].urls.cover;
                                      vm.details.newCover = file.result.data.image[0];
                                      vm.details.uploadedCover=file.result.data.image[0];
                                      $log.debug('show' + vm.details.oldCover);
                                      $modalInstance.close();
                                  });
                                }, function errorCallback() {
                                  if (i > 7) {
                                    $interval.cancel(test);
                                    vm.uploading = false;
                                    if (vm.details.oldCover == null) {
                                      vm.details.oldCover = vm.details.cover;
                                  }
                                    vm.details.cover = file.result.data.image[0].urls.cover;
                                    vm.details.newCover = file.result.data.image[0];
                                    vm.details.uploadedCover=file.result.data.image[0];
                                    $log.debug('show' + vm.details.oldCover);
                                    $modalInstance.close();
                                }
                                  i++;
                              });
                            }.bind(this), 3000);
                            });
                    }, function(response) {
                        vm.uploading = false;
                        vm.errorMsg=errorService.getErrorMessage(response);
            /* if (angular.isDefined(response.data.meta.errors)) {
              vm.errorMsg = response.status + ': ' + response.data.meta.errors.file;
            }
            else if (angular.isDefined(response.data.meta.messages)) {
              vm.errorMsg = response.data.meta.messages[0];
            } else {
              vm.errorMsg = JSON.stringify(response.data.meta);
            }*/
                    }, function(evt) {
            // Math.min is to fix IE which reports 200% sometimes
                        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                    });
                };


        /**
         * @memberof bdpCoverphoto
         * @method setCoverImage
         * @name setCoverImage
         * @description Set cover photo from list
         * @param {object} image   Selected image for cover photo
         *
         */

                vm.setCoverImage = function(image) {
                    if (vm.details.oldCover == null) {
                        vm.details.oldCover = vm.details.cover;
                    }
                    if (angular.isUndefined(image.urls.cover)) {
                        image.urls.cover = image.urls.original;
                    }
                    vm.details.cover = image.urls.cover;
                    vm.details.newCover = image;
                    $log.debug('show' + vm.details.oldCover);
                    $modalInstance.close();
                };

                vm.getCoverImages = function($page) {
                    if (angular.isUndefined($page)) {
                        $page = 1;
                    }
                    vm.coverCurrentPage = $page;
                    var offset = vm.coverCurrentPage * 10;
                    $http({
                        method: 'GET',
                        url: apiConfig.generate('listings', vm.details.id, 'images/') + '&offset=' + offset + '&validfor=cover',
                    }).then(function successCallback(response) {
                        var images = response.data.data.image;
                        vm.coverImages = images;
                        vm.coverImages;
                    }, function errorCallback(response) {
                        alert(errorService.getErrorMessage(response));
                    });
                };

        // set up the init collection
                vm.images = [];
                vm.imagesShown = [];

                vm.size = 0;

        // set up default index
                vm.active = 0;

                vm.config = {
                    visibleElements: platformService.getWidth() < 768 ? 2 : 3,
                };

                vm.range = {
                    from: 0,
                    to: vm.config.visibleElements,
                };

                vm.css = {
                    selectors: {
                        navigation: 'div.modal-vm--collection',
                        thumbs: 'div.modal-vm--collection li',
                        screen: 'div.modal-vm--screen',
                        notificator: 'div.modal-vm--details-notificator',
                        bottonNavigation: 'div.modal-vm--collection-nav',
                    },
                };

                vm.states = {
                    notificator: false,
                    isFormDisabled: false,
                };

                vm.contents = {
                    maxLength: 5000,
                    currentLength: 0,
                };

                vm.notificator = {};

                $timeout(function() {
                    if(document.querySelector(vm.css.selectors.notificator)){
                        vm.notificator.height = angular.element(document.querySelector(vm.css.selectors.notificator))[0].clientHeight;
                    }
                });


                vm.actions = {
                    events: {
                        keyWatch: function(event) {
                            vm.contents.currentLength = event.target.value.length;
                        },
                    },
                    scrollTo: function(index) {
                        var i = 0;
                        vm.imagesShown = [];
                        angular.forEach(vm.images, function(image) {
                            if (i > (index - vm.config.visibleElements) && vm.imagesShown.length < vm.config.visibleElements) {
                                image.coverId = i;
                                vm.imagesShown.push(image);
                            }
                            i++;
                        });
                    },
                    setActive: function(index) {
                        $log.debug('Selected image index: %d', index);
                        vm.active = index;
                        vm.actions.scrollTo(vm.active);
                    },
                    getMomentAgo: function(timestamp) {
                        return moment(moment(timestamp), 'YYYYMMDD').fromNow();
                    },
                    getCurrentUrl: function() {
                        return document.location.href;
                    },
                    load: {
                        all: function() {
                            $http({
                                method: 'GET',
                                url: apiConfig.generate('listings', vm.details.id, 'images') + '&limit=1000&validfor=cover',
                            }).then(function(response) {
                                vm.images = response.data.data.image;
                                var i = 0;
                                angular.forEach(vm.images, function(image) {
                                    if (vm.details.coverImage != null) {
                                      if (image.id == vm.details.coverImage.id) {
                                        vm.active = i;
                                        vm.selectedCover = image;
                                    }
                                  }
                                    i++;
                                });
                                $timeout(function() {
                                    vm.actions.setActive(vm.active);
                                });
                                vm.coverImagesLoaded = true;
                            }, function(response) {
                                alert(errorService.getErrorMessage(response));
                            });
                        },
                    },
                    navigate: {
                        next: function(event) {
                            $log.debug('Navigate NEXT');
                            if (vm.active < vm.size - 1) {
                                vm.actions.setActive(vm.active + 1);
                            } else {
                                vm.actions.setActive(0);
                            }

                            event.stopPropagation();
                        },
                        prev: function(event) {
                            $log.debug('Navigate PREV');
                            if (vm.active > 0) {
                                vm.actions.setActive(vm.active - 1);
                            } else {
                                vm.actions.setActive(vm.size - 1);
                            }

                            event.stopPropagation();
                        },
                        page: {
                            next: function() {
                                $log.debug('Go to next page.');

                                var page = Math.floor((vm.active) / vm.config.visibleElements);
                                var nextIndex = (page + 1) * vm.config.visibleElements;

                                if (nextIndex >= 0 && nextIndex < vm.images.length) {
                                    vm.actions.setActive(nextIndex);
                                } else {
                                    vm.actions.setActive(0);
                                }
                            },
                            prev: function() {
                                $log.debug('Go to prev page.');

                                var page = Math.floor((vm.active) / vm.config.visibleElements);
                                var prevIndex = (page - 1) * vm.config.visibleElements;
                                if (prevIndex >= 0 && prevIndex <= vm.images.length) {
                                    vm.actions.setActive(prevIndex);
                                } else {
                                    vm.actions.setActive(vm.images.length - vm.config.visibleElements);
                                }
                            },
                        },
                    },
                };

                vm.actions.load.all();


                vm.imagesExist = function() {
                    return (angular.isDefined(vm.images) && vm.images.length > 0) ? true : false;
                };
            }
        }
    }
})();
