/* global moment:false */
(function () {
    'use strict';
    try {
        angular
            .module('truelocal')

            /**
             * @memberof truelocal
             * @method applicationConstants_moment
             * @name applicationConstants_moment
             * @description Global moment object.
             */
            .constant('moment', moment)

            /**
             * @memberof truelocal
             * @method applicationConstants_constantsClasses
             * @name applicationConstants_constantsClasses
             * @description Object with class names for Stars, Rating, Gallery and Review.
             */
            .constant('constantsClasses', {
                'bdpStars': {
                    'iconCircle': 'tl-icon icon-check-circle',
                    'activeIconCircleHalf': 'active tl-icon icon-check-circle-half',
                    'iconStar': 'tl-icon icon-check-star',
                    'iconStarHalf': 'tl-icon icon-check-star-half',
                    'activeIconCircle': 'active tl-icon icon-check-circle-active',
                    'activeIconStar': 'tl-icon icon-check-star-active',
                    'iconCircle2Star': 'tl-icon icon-check-circle-3 star',
                    'iconRankArmyStar': 'tl-icon icon-rank-army-star-22 star',
                },
                'srpStars': {
                    'iconCircle': 'tl-icon icon-check-circle',
                    'activeIconCircleHalf': 'active tl-icon icon-check-circle-half-secondary',
                    'iconStar': 'tl-icon icon-check-star',
                    'iconStarHalf': 'tl-icon icon-check-star-half-secondary',
                    'activeIconCircle': 'active tl-icon icon-check-circle-active-secondary',
                    'activeIconStar': 'tl-icon icon-check-star-active-secondary',
                    'iconCircle2Star': 'tl-icon icon-check-circle-3 star',
                    'iconRankArmyStar': 'tl-icon icon-rank-army-star-22 star',
                },
                'bdpRatingStars': {
                    'iconCircle': 'tl-icon icon-check-circle icon-check-circle-3 star',
                    'activeIconCircleHalf': 'tl-icon icon-check-circle-half icon-check-circle-3 star',
                    'iconStar': 'tl-icon icon-check-star icon-rank-army-star-22 star',
                    'iconStarHalf': 'tl-icon icon-check-star-half icon-rank-army-star-22 star',
                    'activeIconCircle': 'tl-icon icon-check-circle-active icon-check-circle-3 star',
                    'activeIconStar': 'tl-icon icon-check-star-active icon-rank-army-star-22 star',
                    'iconCircle2Star': 'tl-icon icon-check-circle-3 star',
                    'iconRankArmyStar': 'tl-icon icon-rank-army-star-22 star',
                },
                'bdpGalleryAddPhoto': {
                    'iconCheck': 'tl-icon icon-check',
                    'iconRemove': 'tl-icon icon-remove-1',
                },
                'bdpGallerySingleImageBox': {
                    'verified': 'verified',
                    'user': 'user',
                    'small': 'small',
                    'big': 'big',
                    'fullSize': 'full-size',
                    'opposite': 'oposite',
                    'medium': 'medium',
                },
                'bdpPendingReviewComment': {
                    'commentPending': 'comment-pending',
                },
                'bdpSingleReviewCommentBlock': {
                    tolCenteredText: 'text-align-center',
                    defaultLeftAlignment: 'text-align-left',
                },
            })

            /**
             * @memberof truelocal
             * @method applicationConstants_constantsPlaceholders
             * @name applicationConstants_constantsPlaceholders
             * @description Object with constants string placeholders for Stars, Rating, Gallery,
             *     Review, OpeningHours, Comments, User details etc.
             */
            .constant('constantsPlaceholders', {
                'bdpRatingStars': {
                    'rateLabelTitles': ['Very poor', 'Poor', 'Average', 'Good', 'Very good'],
                    'showRateLabel': 'Rollover, then click to rate',
                    'showRateLabelMobile': 'Touch to rate',
                },
                'bdpStars': {
                    '_reviews': 'Reviews',
                    '_review': 'Review',
                    '_noReviews': 'No Reviews Yet',
                },
                'openingHours': {
                    'open': 'Open',
                    'closed': 'Closed',
                    'openToday': 'Open today',
                    'openCurrently': 'Currently open',
                },
                'bdpReportReview': {
                    'reasonText': 'Reporting review/comment cover text.',
                    'reportTypeComment': 'comments',
                    'reportTypeReview': 'reviews',
                },
                'bdpReasonBox': {
                    placeholderComment: 'Enter your reason for reporting this comment.',
                    placeholderReview: 'Enter your reason for reporting this review.',
                    maxCharacters: 1000,
                    minCharacters: 1,
                    errorRequired: 'You have to enter your reason.',
                    errorMaxLength: 'Your comment needs to be maximum 1000 characters long.',
                },
                'bdpReviewCommentAnswer': {
                    'alreadyReportedText': 'You have already reported this comment',
                },
                'bdpSingleReview': {
                    'alreadyReportedText': 'You have already reported this review',
                    'active': 'active',
                    'inactive': 'inactive',
                },
                'bdpGalleryAddPhoto': {
                    trueLocalSupport: 'https://support.truelocal.com.au/hc/en-us/requests/new?ticket_form_id=195338',
                },
                'bdpCommentBox': {
                    maxCharacters: 5000,
                    minCharacters: 50,
                    placeholder: 'We like reviews and comments which are helpful, interesting, funny and authentic.',
                    errorRequired: 'Your review needs to be at least 50 characters long.',
                    errorMinLength: 'Your review needs to be at least 50 characters long.',
                    errorMaxLength: 'Your review needs to be maximum 5000 characters long.',
                    errorSwear: 'Your message contains inappropriate language. Please try again.',
                },
                'bdpCommentBoxSmall': {
                    maxCharacters: 1000,
                    minCharacters: 1,
                    placeholder: 'Enter your comment for this review.',
                    errorRequired: 'Your comment needs to be at least 1 character long.',
                    errorMinLength: 'Your comment needs to be at least 1 character long.',
                    errorMaxLength: 'Your comment needs to be maximum 1000 characters long.',
                    errorSwear: 'Your message contains inappropriate language. Please try again.',
                },
                'bdpReviewSorting': {
                    defaultActiveLabel: 'Latest',
                    defaultActiveSortingSetup: {
                        title: 'Latest',
                        type: 'date',
                        sortby: 'desc',
                        active: true,
                    },
                    defaultSortingOptions: [{
                        title: 'Latest',
                        type: 'date',
                        sortby: 'desc',
                        active: true,
                    }, {
                        title: 'Oldest',
                        type: 'date',
                        sortby: 'asc',
                        active: false,
                    }, {
                        title: 'Rating Ascending',
                        type: 'rating',
                        sortby: 'asc',
                        active: false,
                    }, {
                        title: 'Rating Descending',
                        type: 'rating',
                        sortby: 'desc',
                        active: false,
                    }],
                },
                'bdpReadMoreHandler': {
                    labelWithoutText: ' ... ',
                    labelWithText: ' ...read more ',
                    defaultSeparatorElementTagName: 'span',
                    defaultActionElementTagName: 'a',
                    defaultSeparatorElement: '<span class="link-color"> ...</span>',
                    defaultActionElement: '<a href="javascript:" class="link-color">read more</a>',
                },
                'bdpReviewUserDetails': {
                    joinDateFormat: ' DD MMMM, YYYY',
                    defaultAvatar: 'https://media.truelocal.com.au/avatars/-101x76.jpg',
                },
                'focusActionBox': {
                    animationSpeed: 'slow',
                    animationTime: 200,
                    defaultFloatingHeaderHeight: 220,
                },
                'bdpFeedback': {
                    learnMoreUrl: 'https://truelocal.com.au/learn-more-about-update',
                },
            })

            .constant('constantsIcons', {
                'commonIcons': {
                    callBusinessIcon: 'https://media.truelocal.com.au/icons/call_business_mobile.svg',
                },
                'srpIcons': {
                    emailBusinessIcon: 'https://media.truelocal.com.au/icons/email_business.svg',
                    visitWebsiteIcon: 'https://media.truelocal.com.au/icons/visit_website.svg',
                    callBusinessIcon: 'https://media.truelocal.com.au/icons/call_business.svg',
                    mapLocationIcon: 'https://media.truelocal.com.au/icons/map_location.svg',
                    openingHoursIcon: 'https://media.truelocal.com.au/icons/opening_hours.svg',
                },
            })

            /**
             * @memberof truelocal
             * @method applicationConstants_constantsSettings
             * @name applicationConstants_constantsSettings
             * @description Object with constants values for app settings.
             * Comments, User details etc.
             */

            .constant('constantsSettings', {
                'defaultProfileBackground': 'https://media.truelocal.com.au/avatars/-50x50.jpg',
                'categoriesWithTicks': ['Accommodation', 'Hotels', 'Motels'],
                'daysOfWeek': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday',
                               'Saturday'],
                'daysOfWeekShort': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                'listLimits': {
                    bdpsimilar: 4,
                    bdpothers: 4,
                },
                'bdpReviewUserDetails': {
                    localStarBadgeName: 'Local Star',
                },
                'sensitiveCategories': [
                    '4C97F00C-AFA2-42B7-A091-12B6BAA16458', // Local Government
                    'BF2DB08C-CA53-4332-AA05-388EB8385FF6', // Pregnancy Termination Service
                    'A4EDB6AB-2F92-49BF-8B3A-3C4CD9CE2120', // Escorts Agencies
                    '686DD08C-3B36-466E-BE45-42A65BA8FC16', // Adult Services
                    '27D73494-04C9-4EFA-AF7A-97B56D7CA426', // Adult Novelties & Products Retail
                    'F11F8C37-B563-4A5F-BB36-BABBA04FB6FE', // Federal Government
                    '95F3567B-BD70-42C6-9BEB-FBAC686C7FD4', // State Government
                    'FF49791E-EF6C-46F6-BDA5-6543992667F4', // Lingerie Retailers
                ],
            })

            /**
             * @memberof truelocal
             * @method applicationConstants_constantsApiErrorCodes
             * @name applicationConstants_constantsApiErrorCodes
             * @description Object with Api error codes.
             */
            .constant('constantsApiErrorCodes', {
                'reportReviewComment': {
                    'bazaarVoiceNotRegisteredError': 411930001,
                },
            })

            /**
             * @memberof truelocal
             * @method applicationConstants_runningCompetition
             * @name applicationConstants_runningCompetition
             * @description Object for Competition
             */
            .constant('runningCompetition', {
                'competition': {
                    'competitionURL': 'promotions/review-to-win-2016',
                    'name': 'review-to-win-2016',
                    'year': '2016',
                    'active': false,
                },
            })

            .constant('runnningCampaign', {
                'steve': true
            })

            /**
             * @memberof truelocal
             * @method applicationConstants_trackingConstants
             * @name applicationConstants_trackingConstants
             * @description Object with trackingConstants.
             */
            .constant('trackingConstants', {
                'reviewsConstants': {
                    'likeButton': 'like button',
                    'dislikeButton': 'dislike button',
                    'reportCommentComplete': 'report comment complete',
                    'reportReviewComplete': 'report review complete',
                    'reportPreModReviewComplete': 'report pre-moderated review complete',
                    'writeCommentComplete': 'write comment complete',
                },
            })

            /**
             * @memberof truelocal
             * @method applicationConstants_footerUrls
             * @name applicationConstants_footerUrls
             * @description Object with footerUrls.
             */
            .constant('footerUrls', {
                'cmsAboutUs': {
                    'url': window.location.protocol + '//' + window.location.host
                           + '/cms/about-us',
                    'label': 'About',
                },
                'blog': {
                    'url': window.location.protocol + '//' + window.location.host + '/blog/',
                    'label': 'Blog',
                },
                'cmsRatings': {
                    'url': window.location.protocol + '//' + window.location.host
                           + '/cms/ratings-reviews',
                    'label': 'Reviews',
                },
                'careers': {
                    'url': 'https://corporate.thryv.com.au/careers ',
                    'label': 'Thryv Careers',
                },
                'cmsAdvertise': {
                    'url': 'https://www.truelocal.com.au/cms/business-centre-products',
                    'label': 'Advertise with Us',
                },
                'cmsContactUs': {
                    'url': 'https://www.truelocal.com.au/cms/contact-us',
                    'label': 'Contact Us',
                },
                'addBusinessOld': {
                    'url': 'https://www.truelocal.com.au',
                    'label': 'Add a Business',
                },
                'addBusiness': {
                    'url': window.location.protocol + '//' + window.location.host
                        + '/add-business',
                    'label': 'Add a Business',
                },
                'businessCentre': {
                    'url': window.location.protocol + '//' + window.location.host
                           + '/login/business-centre',
                    'label': 'Business Centre',
                },
                'faqs': {
                    'url': 'https://www.truelocal.com.au/cms/faq ',
                    'label': 'FAQs',
                },
                'supportCentre': {
                    'url': 'https://www.truelocal.com.au/cms/support',
                    'label': 'Help Centre',
                },
                'termsOfUse': {
                    'url': 'https://corporate.thryv.com.au/terms-of-use',
                    'label': 'Terms of Use',
                },
                'cmsPrivacy': {
                    'url': 'https://www.thryv.com/privacy/',
                    'label': 'Privacy Policy',
                },
                'cmsPayment': {
                    'url': 'https://www.truelocal.com.au/payment',
                    'label': 'Account Payment',
                }
            })

            /**
             * @memberof truelocal
             * @method applicationConstants_footerFeatured
             * @name applicationConstants_footerFeatured
             * @description Object with footerFeatured.
             */
            .constant('footerFeatured', {
                'categories': {
                    'featured': 'categories',
                    'text': 'Featured categories',
                },
                'businesses': {
                    'featured': 'businesses',
                    'text': 'Featured businesses',
                },
                'siteIndex': {
                    'text': 'Site Index',
                },
                'active': {
                    'featured': 'categories',
                    'running': true,
                },
            })

            /**
             * @memberof truelocal
             * @method applicationConstants_arrowConstants
             * @name applicationConstants_arrowConstants
             * @description arrowConstants - generic name for ads;
             */
            .constant('arrowConstants', {
                geminiSectionCode: '24dc1b0f-8610-45dc-beae-04a3f6e57cf1',
            })
            /**
             * @memberof truelocal
             * @method applicationConstants_homePageImagesWordsFeeder
             * @name applicationConstants_homePageImagesWordsFeeder
             * @description homePageImagesWordsFeeder - feeder for homepage random images and words
             */
            .constant('homePageImagesWordsFeeder', {
                images: [
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_1.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_2.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_3.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_4.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_5.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_8.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_10.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_11.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_12.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_13.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_14.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_16.jpg',
                    'https://media.truelocal.com.au/homepage-backgrounds/HOME_BG_17.jpg',
                ],
                words: [
                    'RELIABLE MECHANICS',
                    'PROMPT PLUMBERS',
                    'BEST TAKEAWAYS',
                    'AWESOME HAIRDRESSERS',
                    'RELAXING MASSAGES',
                    'GREAT COFFEE',
                ],
            })
            /**
             * @memberof truelocal
             * @method applicationConstants_homePageTabsParameters
             * @name applicationConstants_homePageTabsParameters
             * @description homePageTabsParameters - parameters for homepage tabs
             */
            .constant('homePageTabsParameters', {
                business: {
                    loggedin: 'https://www.truelocal.com.au/cms/business-centre-products',
                    notloggedin: '/add-business',
                    notloggedinOld: '/submit-business/signup.do',
                },
                local: '/cms/ratings-reviews',
            })
            .constant('homePageSeo', {
                title: 'True Local - Local Business Directory for Australian Businesses',
                description: 'Find Restaurant Reviews, Pet Shops, Medical Centres & more anywhere in Australia. Let True Local help you connect with local businesses near you.',
            })
            .constant('tradiesPageSeo', createTradiesPageSeo())
            .constant('searchPageSeo',createNoindexNofollowMeta())
            .constant('userProfilePageSeo',createNoindexNofollowMeta())
            .constant('metaHeaders', {
                'homepage': {
                    'robots': 'index,follow',
                },
            })
            .constant('gmapKey', 'AIzaSyCU94AJwowOeFxG67cb26sUxDipTXOpLfw');

    } catch (e) {
        console.debug('*** Caught exception in defining constants : ' + e);
    }
    function createTradiesPageSeo() {
        var title = function (city) {
            return (city ? city + ' ' : '') + ' Tradies | True Local';
        };
        title.toString = title;

        var description = function (city) {
            return 'Find, contact and review reliable local tradies and other Businesses online'
                   + (city ? ' in ' + city : '') + ' - True Local';
        };
        description.toString = description;

        return {
            title: title,
            description: description,
            meta: {
                'robots': 'index,follow,noarchive',
            },
        };
    }
    function createNoindexNofollowMeta(){
      return {
          meta: {
              'robots': 'noindex,nofollow',
          },
      };
    }
})();
