(function() {
    'use strict';

    angular
    .module('truelocal')

    .service('getCopyPointsFromListing', getCopyPointsFromListing)

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpCopyPoints
     * @description Handles copy points.
     *  * build the entire copypoints object including brands, service locations, downloads
     *
     *  @example
     *  <bdp-copy-points listing="[object]"></bdp-copy-points>

     */
    .directive('bdpCopyPoints', bdpCopyPoints);

  /** @ngInject */
    function getCopyPointsFromListing() {
        return function(listing) {
            if (!listing) return null;
            if (!listing.copypoints || !listing.copypoints.copypoint) return [];

            return listing.copypoints.copypoint
        .reduce(function(points, point) {
            if (!point.values || !point.values.length) {
                return points;
            }
            var nameLabel = transformCopyPointLabels(point.name);

            return points.concat({
                label: nameLabel,
                value: point.values[0],
            });
        }, []);
        };
    }

  /** @ngInject */
    function bdpCopyPoints() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/bdpcopypoints/bdpcopypoints.html',
            scope: {
                listing: '=',
            },
            controller: ['getCopyPointsFromListing', BdpCopyPointsController],
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

        function BdpCopyPointsController(getCopyPointsFromListing) {
            var vm = this;
            vm.points = getCopyPointsFromListing(vm.listing);
        }
    }

    function transformCopyPointLabels(label)
    {
        if(label === 'Opening Hours Detail'){
           return 'Contact Options';
        }
        return label;
    }
})();
