(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name searchItems
     * @description Directive holds search results items and display them according to chosen view option.
     *
     * @param {service}   $location                 Angular window.location service wrapper
     * @param {service}   $scope                    Angular scope service
     * @param {constant}  env                       Environmental constants
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {service}   $compile                  Angular compile service
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {service}   $q                        A service that helps you run functions asynchronously, and use their return values (or exceptions) when they are done processing.
     * @param {service}   $log                      Angular console log wrapper
     * @param {factory}   uriService                uriService provide uriEncode and uriDecode methods that are responsible to unify different uri
     *
     * @example
     * <search-items search-data="[object]" expanded-search="[object]" expanded-search-message="[string]" grid-class="[string]" show-marker-id="[string]" view-on-page="[string]" on-map="string"></search-items>
     */
    .directive('searchItems', searchItems);

  /** @ngInject */
    function searchItems() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/searchitems/searchitems.html',
            scope: {
                searchData: '=',
                gridClass: '=',
                viewOnPage: '@',
                onMap: '&',
                showMarkerId: '&',
                expandedSearch: '=',
                expandedSearchMessage: '=',
            },


      /**
       * @memberof truelocal
       * @ngdoc controller
       * @name BsSearchItemsController
       * @description BsSearchItemsController.
       */
            controller: ['$location', '$scope', 'env', 'platformService', '$compile', '$timeout', '$q', '$log', 'uriService', '$window', BsSearchItemsController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BsSearchItemsController($location, $scope, env, platformService, $compile, $timeout, $q, $log, uriService, $window) {
            var vm = this;

            var _baseurl = $location.protocol() + '://' + $location.host();

            if ($location.port() != '80' || $location.port() != '') {
                _baseurl = _baseurl + ':' + $location.port();
            }


      /**
       * @memberof searchItems
       * @method calculateSloganHeight
       * @name calculateSloganHeight
       * @description Return the slogan height based on diferent screen sizes - used to cover edgecases
       * @returns {integer} height for the slogan container
       */
            vm.calculateSloganHeight = function() {
                if (platformService.isMobile() === true && platformService.getWidth() < 768) {
                    vm.sloganHeightLimit = 50;
                } else {
                    vm.sloganHeightLimit = 25;
                }
                return vm.sloganHeightLimit;
            };

            vm.sloganHeightLimit = vm.calculateSloganHeight();


      /**
       * @memberof searchItems
       * @name getCategory
       * @description Object containing category names.
       */
            vm.categoryNames = {};


      /**
       * @memberof searchItems
       * @method getCategory
       * @name getCategory
       * @description Returns searched category.
       * @param {Object} listing
       * @returns {String} categoryName
       */
            vm.getCategory = function(listing) {
                if (typeof vm.categoryNames[listing.id] != 'undefined') {
                    return vm.categoryNames[listing.id];
                } else if (typeof listing.categories == 'undefined') {
                    return '';
                } else if (typeof listing.categories.category == 'undefined') {
                    return '';
                } else if (typeof listing.categories.category[0] == 'undefined') {
                    return '';
                } else if (typeof listing.categories.category[0].name == 'undefined') {
                    return '';
                } else {
                    vm.categoryNames[listing.id] = listing.categories.category[0].name;
                    return vm.categoryNames[listing.id];
                }
            };

            vm.havePhoneNumber = [];


      /**
       * @memberof searchItems
       * @method hasPhoneIsMobile
       * @name hasPhoneIsMobile
       * @description Returns true if business have phone, and it is mobile view.
       * @param {Object} listing
       * @returns {Boolean} hasPhoneIsMobile
       */
            vm.hasPhoneIsMobile = function(listing) {
                if (vm.getHavePhoneNumber(listing)) {
                    if (platformService.isMobilePlatform()) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            };


      /**
       * @memberof searchItems
       * @method getHavePhoneNumber
       * @name getHavePhoneNumber
       * @description Returns true if business has phone number.
       * @param {Object} listing
       * @returns {Boolean} havePhoneNumber
       */
            vm.getHavePhoneNumber = function(listing) {
                if (typeof vm.havePhoneNumber[listing.id] == 'undefined') {
                    if (typeof listing == 'undefined') vm.havePhoneNumber[listing.id] = false;
                    else if (typeof listing.contacts == 'undefined') vm.havePhoneNumber[listing.id] = false;
                    else if (typeof listing.contacts.contact == 'undefined') vm.havePhoneNumber[listing.id] = false;
                    else if (listing.contacts.contact.length == 0) vm.havePhoneNumber[listing.id] = false;
                    else {
                        var found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (typeof contact.type != 'undefined') {
                                if (contact.type == 'phone' || contact.type == 'national' || contact.type == 'mobile') {
                                    if(contact.value != '0200000000') {
                                        found = 1;
                                    }
                                }
                            }
                        });

                        if (found == 0) {
                            vm.havePhoneNumber[listing.id] = false;
                        } else {
                            vm.havePhoneNumber[listing.id] = true;
                        }
                    }
                }
                return vm.havePhoneNumber[listing.id];
            };


      /**
       * @memberof searchItems
       * @method getPhoneNumber
       * @name getPhoneNumber
       * @description Returns business phone number.
       * @param {Object} listing
       * @returns {String} phoneNumber
       */
            vm.getPhoneNumber = function(listing) {
                if (vm.getHavePhoneNumber(listing) == true) {
                    var _phoneNumber = null;
          // check first for mobile
                    var found = 0;
                    angular.forEach(listing.contacts.contact, function(contact) {
                        if (contact.type == 'mobile' && found == 0) {
                            found = 1;
                            _phoneNumber = contact.value;
                        }
                    });

                    if (_phoneNumber == null) {
                        found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (contact.type == 'phone' && found == 0) {
                                found = 1;
                                _phoneNumber = contact.value;
                            }
                        });
                    }

                    if (_phoneNumber == null) {
                        found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (contact.type == 'national' && found == 0) {
                                found = 1;
                                _phoneNumber = contact.value;
                            }
                        });
                    }

                    if(_phoneNumber == '0200000000') {
                        _phoneNumber = null;
                    }

                    return _phoneNumber;
                }
            };

            $scope.$on('$destroy', function() {
                angular.element($window).off('resize', _resizeHandler);
            });

            var _resizeHandler = function(e) {
        // todo handle resize
                vm.titleTruncationHeight = calcTitleTruncationHeight();
                vm.sloganHeightLimit = vm.calculateSloganHeight();
                angular.element($window).triggerHandler('update-truncate');
            };

      /**
       * @memberof searchItems
       * @method getPhoneNumberFormated
       * @name getPhoneNumberFormated
       * @description Returns business phone number in user friendly view format.
       * @param {Object} listing
       * @returns {String} phoneNumberFormated
       */
            vm.getPhoneNumberFormated = function(listing) {
                var _phoneNumber = vm.getPhoneNumber(listing);
                return _formatPhone(_phoneNumber);
            };

            vm.getPageNumber = function(index) {
                var _page = parseInt(index / 12);
                _page += 1;
                return _page;
            };

      /**
       * @memberof searchItems
       * @method _formatPhone
       * @name _formatPhone
       * @description Format phone number in user friendly view format.
       * @param {String} _phoneNumber
       * @returns {String} phoneNumberFormated
       */
            var _formatPhone = function(_phoneNumber) {
                var _formattedPhoneNumber = '';
                if (typeof _phoneNumber == 'undefined' || !_phoneNumber) {
                    return '';
                }
                if (_phoneNumber.length == 10) {
                    if (_phoneNumber.startsWith('04') || _phoneNumber.startsWith('1300') ||
            _phoneNumber.startsWith('1800') || _phoneNumber.startsWith('1900')) {
                        _formattedPhoneNumber += _phoneNumber.substr(0, 4) + ' ' + _phoneNumber.substr(4, 3) + ' ' + _phoneNumber.substr(7, 3);
                    } else {
                        _formattedPhoneNumber += '(' + _phoneNumber.substr(0, 2) + ') ';
                        _formattedPhoneNumber += _phoneNumber.substr(2, 4) + ' ' + _phoneNumber.substr(6);
                    }
                } else if (_phoneNumber.length == 6) {
                    _formattedPhoneNumber += _phoneNumber.substr(0, 2) + ' ' + _phoneNumber.substr(2, 2) + ' ' + _phoneNumber.substr(4, 2);
                } else {
                    _formattedPhoneNumber = _phoneNumber;
                }
                return _formattedPhoneNumber;
            };


      /**
       * @memberof searchItems
       * @method getListingUrl
       * @name getListingUrl
       * @description Returns business url.
       * @param {Object} listing
       * @returns {String} url
       */
            vm.getListingUrl = function(listing) {
                return _baseurl + env.searchsettings.businessUrl + '/' + listing.seoUrl;
            };

            vm.hasImage = {};
            vm.testedImage = [];

      /**
       * @memberof searchItems
       * @method getHasImage
       * @name getHasImage
       * @description Returns true if business has image.
       * @param {Object} listing
       * @returns {Boolean} hasImage
       */
            vm.checkImage = function(listing) {
                if (typeof vm.hasImage[listing.id] == 'undefined') {
                    if (typeof listing == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage.urls == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage.urls.small == 'undefined') vm.hasImage[listing.id] = false;
                    else vm.hasImage[listing.id] = true;
                }
            };


      /**
       * @memberof searchItems
       * @method imageInServer
       * @name imageInServer
       * @description Load image with src provided as argument.
       * @param {String} src
       */
            function imageInServer(src) {
                var deferred = $q.defer();
                var image = new Image();
                image.onerror = function() {
                    deferred.resolve(false);
                };
                image.onload = function() {
                    deferred.resolve(true);
                };
                image.src = src;
                return deferred.promise;
            }

      /**
       * @memberof searchItems
       * @method getImage
       * @name getImage
       * @description Returns style object with business image as background.
       * @param {Object} listing
       * @returns {Object} style
       */
            vm.getImage = function(listing) {
                if (vm.hasImage[listing.id] == true) {
                    return {
                        'background-image': 'url(' + listing.featureImage.urls.small + ')',
                        'background-size': 'contain',
                    };
                }
                return {};
            };

      /**
       * @memberof searchItems
       * @method getLastClass
       * @name getLastClass
       * @description Returns class for display last search item.
       * @param {Number} index
       * @param {Boolean} last
       * @returns {String} class
       */
            vm.getLastClass = function(index, last) {
                if (last == true && (index + 1) % 3 == 2) {
                    return 'last-search-item';
                }
            };

            vm.haveCoords = {};
            vm.coords = {};

      /**
       * @memberof searchItems
       * @method getHaveCoords
       * @name getHaveCoords
       * @description Returns true if business has entered coordinates.
       * @param {Object} listing
       * @returns {Boolean} hasImage
       */
            vm.getHaveCoords = function(listing) {
                if (typeof vm.haveCoords[listing.id] == 'undefined') {
                    if(angular.isUndefined(listing.addresses) || angular.isUndefined(listing.addresses.address)
            || angular.isUndefined(listing.addresses.address[0]) || angular.isUndefined(listing.addresses.address[0].longitude)
            || angular.isUndefined(listing.addresses.address[0].latitude)) {
                        vm.haveCoords[listing.id] = false;
                    } else {
            // check if is full address
                        var address = listing.addresses.address[0];
                        if((angular.isUndefined(address.streetName) || address.streetName.length==0)
              && ((angular.isUndefined(address.addressLine1) || address.addressLine1.length==0 || address.addressLine1.length>50) &&
              (angular.isUndefined(address.addressLine2) || address.addressLine2.length==0 || address.addressLine2.length>50))) {
                            vm.haveCoords[listing.id] = false;
                        }
                        if(angular.isUndefined(vm.haveCoords[listing.id])) {
                            var founded = false;
                            angular.forEach(vm.coords, function(coord) {
                                if (coord.latitude == listing.addresses.address[0].latitude && coord.logitude == listing.addresses.address[0].logitude) {
                                    founded = true;
                                }
                            });
                            if (founded == false) vm.haveCoords[listing.id] = true;
                            else {
                                coords.push({
                                    latitude: listing.addresses.address[0].latitude,
                                    longitude: listing.addresses.address[0].logitude,
                                });
                                vm.haveCoords[listing.id] = false;
                            }
                        }
                    }
                }

                return vm.haveCoords[listing.id];
            };


      /**
       * @memberof searchItems
       * @method showOnMap
       * @name showOnMap
       * @description Sets viewOnPage to map.
       */
            vm.showOnMap = function(listing) {
                vm.viewOnPage = 'map';
        /* $cookies.put('tl_srcp_vop','map', {path:'/'});
         $cookies.remove('tl_srcp_grdc');*/
            };


      /**
       * @memberof searchItems
       * @method onMapSelect
       * @name onMapSelect
       * @description Sets marker id to select on map.
       * @param {Number} id
       */
            vm.onMapSelect = function(id) {
                angular.element(window).triggerHandler('show-on-map', id);
            };

            vm.tiles = [];
            vm.tilesLimit = 6;


      /**
       * @memberof searchItems
       * @method getTiles
       * @name getTiles
       * @description Returns array of tiles from inventories.
       * @param {Object} inventories
       * @returns {Array} tiles
       */
            vm.getTiles = function(inventories) {
                if (typeof inventories != 'undefined') {
                    for (var i = 0; i < inventories.length; i++) {
                        if (inventories[i].type == 'TILES') {
                            return inventories[i].inventory;
                        }
                    }
                } else {
                    return [];
                }
            };

      /**
       * @memberof searchItems
       * @method hasSlogan
       * @name hasSlogan
       * @description Returns true if business has description (slogan).
       * @param {Object} listing
       * @returns {Boolean} hasSlogan
       */
            vm.hasSlogan = function(listing) {
                vm.isMobile = (platformService.getWidth()>767) ? false:true;
                if (typeof listing == 'undefined') {
                    return false;
                }
                if (listing.paid == true) {
                    if (typeof listing.slogan != 'undefined') {
                        if (listing.slogan.length > 0) return true;
                        else return false;
                    } else if (typeof listing.description != 'undefined') {
                        if (listing.description.length > 0) return true;
                        else return false;
                    } else {
                        return false;
                    }
                } else {
                    if(vm.isMobile == true && listing.sponsored==true) {
                        if (typeof listing.description != 'undefined') {
                            if (listing.description.length > 0) return true;
                            else return false;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
                return false;
            };


      /**
       * @memberof searchItems
       * @method getSlogan
       * @name getSlogan
       * @description Returns business description (slogan).
       * @param {Object} listing
       * @returns {String} slogan
       */
            vm.getSlogan = function(listing) {
                vm.isMobile = (platformService.getWidth()>768) ? false:true;
                if (listing.paid == true) {
                    if (typeof listing.slogan != 'undefined') {
                        return '"' + listing.slogan.slice(0, 200) + '"';
                    } else {
                        return '"' + listing.description.slice(0, 200) + '"';
                    }
                } else {
                    if(vm.isMobile == true) return '"' + listing.description.slice(0, 200) + '"';
                }
            };

            vm.getSloganLength = function(listing) {
                vm.isMobile = (platformService.getWidth()>768) ? false:true;
                if (listing.paid == true) {
                    if (typeof listing.slogan != 'undefined') {
                        return listing.slogan.length;
                    } else {
                        return listing.description.length;
                    }
                } else {
                    if(vm.isMobile == true) return listing.description.length;
                }
            };

      /**
       * @memberof searchItems
       * @method getButtonsHelperClass
       * @name getButtonsHelperClass
       * @description Returns display class depending weather listings exists and does it have coordinates.
       * @param {Object} listing
       */
            vm.getButtonsHelperClass = function(listing) {
                if (typeof listing == 'undefined') {
                    return 'col-sm-4';
                } else {
                    if (vm.getHaveCoords(listing) == false) {
                        return 'col-sm-6';
                    } else {
                        return 'col-sm-4';
                    }
                }
            };


      /**
       * @memberof searchItems
       * @event onSearchDataInventoriesChange
       * @name onSearchDataInventoriesChange
       * @description Refresh tiles.
       */
            $scope.$watch(
        'vm.searchData.inventories',
        function handleFooChange(newValue, oldValue) {
            vm.tiles = vm.getTiles(vm.searchData.inventories);
        }
      );


      /**
       * @memberof searchItems
       * @method getClassById
       * @name getClassById
       * @description Decide class based on entered index.
       * @param {Number} index
       * @param {String} curClass
       */
            vm.getClassById = function(index) {
                var curClass = '';
                if (typeof index != 'undefined') {
                    if ((index + 2) % 3 == 0) curClass += ' middle';
                    if (index % 2 != 0) curClass += ' even';
                    if (index % 2 == 0) curClass += ' odd';
                }
                return curClass;
            };


      /**
       * @memberof searchItems
       * @method showSlogan
       * @name showSlogan
       * @description Show slogan if current view is grid and slogan exists.
       * @param {Object} listing
       * @param {Boolean} showSlogan
       */
            vm.showSlogan = function(listing) {
                if(angular.isUndefined(listing) && listing!==null) {
                    return;
                }
                if (vm.gridClass == '') {
                    return vm.hasSlogan(listing);
                } else {
                    return false;
                }
            };


      /**
       * @memberof searchItems
       * @method appendTiles
       * @name appendTiles
       * @description Add tiles after every 11 rows starting from fifth.
       * @param {Object} index
       */
            vm.appendTiles = function(index) {
                if (angular.isUndefined(vm.tiles) || (angular.isDefined(vm.tiles) && !vm.tiles.length)) return;
                index += (index / 11) - 6;
                angular.element(document.querySelector('.search-item:nth-child(' + index + ')')).after($compile('<search-tile tiles="vm.tiles" index="index"></search-tile>')($scope));
            };


      /**
       * @memberof searchItems
       * @method getExpandedSearchMessage
       * @name getExpandedSearchMessage
       * @description Returns message that suggest user to expand search (or it is in progress).
       */
            this.getExpandedSearchMessage = function() {
                if (angular.isUndefined(this.expandedSearchMessage) || !this.expandedSearchMessage) {
                    return 'We\'ve expanded your search';
                }
                return this.expandedSearchMessage;
            };


      /**
       * @memberof searchItems
       * @name titleTruncationHeight
       * @description Returns element's row height to truncate if above that.
       */
            vm.titleTruncationHeight = calcTitleTruncationHeight();

            function calcTitleTruncationHeight() {
                if (angular.element(window)[0].outerWidth < 768) {
                    return 50;
                } else {
                    return 70;
                }
            }


      /**
       * @memberof searchItems
       * @name buttonsClass
       * @description Return specific grid class
       * @param  {string} button - 'read-review', 'write-reviews', 'view-on-map', 'phone', 'go-to-business'
       * @param {object} listing
       */
            vm.buttonClass=[];
            vm.getButtonsClass = function(button, listing) {
                var _buttonCount = 0;
                if(platformService.getWidth()>767) {
                    if(angular.isUndefined(vm.buttonClass[listing.id + '0' + button])) {
                        _buttonCount = 1;
                        if (listing.statistics.reviews != 0) _buttonCount++;
                        if (vm.getHaveCoords(listing) == true) _buttonCount++;
                        if (_buttonCount == 1) _buttonCount++;
                        if (_buttonCount == 2) {
                            vm.buttonClass[listing.id + '0' + button] = 'col-lg-6 col-sm-6 col-md-6';
                        } else if (_buttonCount == 3) {
                            vm.buttonClass[listing.id + '0' + button] = 'col-lg-4 col-sm-4 col-md-4';
                        }
                    }
                    return vm.buttonClass[listing.id + '0' + button];
                } else {
                    if(angular.isUndefined(vm.buttonClass[listing.id + '1' + button])) {
                        _buttonCount = 0;
                        if (vm.getHaveCoords(listing) == true) _buttonCount++;
                        if(vm.getHavePhoneNumber(listing) == true) _buttonCount++;
                        if(_buttonCount == 0 ) _buttonCount++;
                        if (_buttonCount == 1) {
                            vm.buttonClass[listing.id + '1' + button] = 'col-xs-12';
                        } else {
                            vm.buttonClass[listing.id + '1' + button] = 'col-xs-6';
                        }
                    }
                    return vm.buttonClass[listing.id + '1' + button];
                }
            };

      /**
       * @memberof searchItems
       * @name getCategoryUrl
       * @description return category url
       * @param {object} listing
       */
            vm.categoriesUrls = [];
            vm.getCategoryUrl = function(listing) {
                if(angular.isUndefined(vm.categoriesUrls[listing.id])) {
                    var _url = $location.url();
                    var _finalUrl;
                    var _location='';
                    var _params, listingCategory;

                    if (_url.indexOf('search')!= -1) {
                        _params = _url.split('search');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            if (angular.isUndefined(_params[2])) {
                                _location='australia';
                            }
                            listingCategory = vm.getCategory(listing, index).toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                var _encodedURLName = listingCategory.toLowerCase() + '';
                                _encodedURLName = _encodedURLName.replace(/[\&]/g, 'and');
                                _encodedURLName = _encodedURLName.replace(/[^a-zA-Z\-\_\,]/g, ' ');
                                _encodedURLName = _encodedURLName.replace(/[ ]/g, '-');
                                _finalUrl = _url.replace(_params[1], _encodedURLName);
                                if (_location!='') {
                                    _finalUrl = _finalUrl + '/' + _location;
                                }
                            } else {
                                _finalUrl = _url + ((_location!='') ? '/' + _location: '');
                            }
                        }
                    } else {
                        _params = _url.split('find');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            listingCategory = vm.getCategory(listing, index).toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                _finalUrl = _url.replace(_params[1], uriService.uriEncode(listingCategory));
                            } else {
                                _finalUrl = _url;
                            }
                        }
                    }
                    vm.categoriesUrls[listing.id] = _finalUrl;
                }

                return vm.categoriesUrls[listing.id];
            };

      /**
       * @memberof searchItems
       * @name haveButton
       * @description Return if button exist
       * @param  {string} button - 'read-review', 'write-reviews', 'view-on-map', 'phone', 'go-to-business'
       * @param {object} listing
       */

            vm.haveButton = function(button, listing) {
                switch (button) {
                case 'write-reviews':
                    return true;
                    break;
                case 'read-reviews':
                    return listing.statistics.reviews!=0;
                    break;
                case 'view-on-map':
                    return vm.getHaveCoords(listing);
                case 'phone':
                    return vm.getHavePhoneNumber(listing) && platformService.isMobilePlatform();
                case 'go-to-business':
                    if(platformService.getWidth()>767) {
                        if(vm.getHaveCoords(listing)==false && listing.statistics.reviews==0) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        if(vm.getHaveCoords(listing)==false && vm.getHavePhoneNumber(listing)==false) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                    break;
                }
            };

      /**
       * @memberof searchItems
       * @event onWindowResize
       * @name onWindowResize
       * @description Update element truncation height as window resize.
       */
            angular.element($window).on('resize', _resizeHandler);
        }
    }
})();
