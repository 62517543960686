(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name scrollOnClick
     * @description Directive enable smooth scrolling to anchored element. Since HTML5 limit anchoring to element
     * only once for given page, and doesn't enable animation, directive bypass this HTML5 limit.
     */
    .directive('scrollOnClick', ScroolOnClick);

    function ScroolOnClick(platformService, $window, $log, windowScroll) {
        return {
            restrict: 'A',
            link: function(scope, $elm, attrs) {
                $elm.on('click', function(e) {
                    action(e);
                });

                function action(e) {
                    var idToScroll;
                    if (angular.isDefined(attrs.href)) {
                        idToScroll = attrs.href;
                    } else {
                        idToScroll = attrs.scrollOnClick;
                    }

                    e.preventDefault();
                    var $target;
                    if (idToScroll) {
                        if (idToScroll == 'parent-target') {
                            $target = $elm.parent()[0];
                        } else {
                            $target = angular.element(document.querySelector(idToScroll));
                        }
                    } else {
                        $target = $elm;
                    }
          // $log.debug('click',$target.offset().top,$('.promo_theme_primary-brand').outerHeight(),$('.anchors').position(),$(document).scrollTop())
                    windowScroll.scrollToElement($target);

                    if (angular.isDefined(attrs.execclick)) {
                        angular.element($target).find(attrs.execclick).triggerHandler('click');
                    }
                }
            },
        };
    }
})();
