(function() {
    'use strict';

    angular
    .module('truelocal')
    .factory('DataModel', DataModel);

    function DataModel($log, formatGenerator) {
    /** *
     * arguments list accepting only Objects
     * first index is a model,
     * last index is api-data
     * middle binds
     *
     * @returns {*}
     */
        function dataModel() {
            this._argumentList = [];

            angular.extend(this._argumentList, arguments);

      // checks if list length is less than 2 arguments
      // prints error for missing argument
      // returns empty Object
            if(!this._argumentList || this._argumentList.length < 2) {
                $log.debug('Missing arguments for DataModel');
                return {};
            }

      // checks all list arguments types accepting only Objects
      // otherwise returns error for expected format
      // and typeof wrong argument and type + index (position) of argument
      // returns empty Object
            for(var i = 0, l = this._argumentList.length; i < l; i++) {
                var _argumentOnIndex = this._argumentList[i];

                if(!(angular.isArray(_argumentOnIndex) || angular.isObject(_argumentOnIndex))) {
                    $log.debug('Argument format invalid on index "' + i + '". Expected formats Array or Object, got "' + (typeof _argumentOnIndex) + '"');
                    return {};
                }
            }

      // sets apidata from argument list
      // (last element in argument list)
            var _apidata = this._argumentList[this._argumentList.length - 1];

      // generates the first level Model
      // using orignal API data
            this.model = formatGenerator.generate(_apidata, this._argumentList[0]);

      // removes last and first element from list
      // other arguments in list are bindings
            this._argumentList.pop();   // removing apidata from arg list
            this._argumentList.shift(); // removing model from arg list

            if (this._argumentList.length) {
                for(var i1 = 0, l1 = this._argumentList.length; i1 < l1; i1++) {
          // goes over the argument list
          // (if there is any element inside)
                    var _bindingLevel = this._argumentList[i1];

          // first checking if key value exists in current model
                    for (var key1 in _bindingLevel) {
            // if the 'key' from current level
            // already exist in base model
            // 'deletes' it from current level
                        if (this.model[key1]) {
                            $log.debug('Binded key "' + key1 + '" exists in Model');
                            delete _bindingLevel[key1];
                        }
                    }
          // after all validations are passed
          // extending base model with new level binding
          // using base model as apidata! Since its going true
          // previous extension (defined) model level
                    angular.extend(this.model, formatGenerator.generate(this.model, _bindingLevel));
                }
            }
            return this.model;
        }

        return dataModel;
    }
})();
