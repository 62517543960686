angular.module('truelocal').controller('MobileController', MobileController);

function MobileController($scope, storedData, $http, apiConfig, $templateCache, $compile, $parse, DTM, $log, env) {
    var vm = this;

    vm.storedData = storedData;
    vm.isMessageSent = false;

    /**
     * @memberof MobileController
     * @method sendMessagesendMessage
     * @name sendMessage
     * @description send the message to the user.
     * @returns {Boolean}
     */

    vm.sendMessage = function(form, phone) {
        if (form.$valid && !angular.isUndefined(phone) && phone !== "") {
            vm.phoneNumber = phone;
            vm.requestError = false;
            angular.element(document.querySelector("#sendSMS")).addClass("btn-disabled");

            var requestData = {
                destination: phone,
                messagingType: "SMS"
            };

            $http.post(apiConfig.generate('listing', vm.storedData.id, 'share-details'), requestData, apiConfig.getXAuthToken())
                .then(function successCallback(response) {
                    if (response.data.statusCode == "OK") {
                        vm.isMessageSent = true;
                    } else {
                        vm.requestError = true;
                        vm.sendMessage.statusDescription = response.data.statusDescription;
                        angular.element(document.querySelector("#sendSMS")).removeClass("btn-disabled");
                    }
                })
                .catch(function errorCallback(response) {
                    vm.requestError = true;
                    vm.sendMessage.statusDescription = "Sorry, we ran into an error. Try again shortly.";
                    angular.element(document.querySelector("#sendSMS")).removeClass("btn-disabled");
                });
        }
    };

    /**
     * @memberof MobileController
     * @method loadFormTemplate
     * @name loadFormTemplate
     * @description change variable isMessageSent to false in order to go back and load previous modal screen
     * @returns {Boolean}
     */

    vm.loadFormTemplate = function() {
        vm.isMessageSent = false;
    };

    /**
     * @memberof  MobileController
     * @method getName
     * @name getName
     * @description Get name as string.
     * @param {Object} addresses
     * @returns {String} name
     **/
    vm.getName = function() {
        return vm.storedData.name;
    };

    /**
     * @memberof  MobileController
     * @method getPhone
     * @name getPhone
     * @description Get phone as string.
     * @param {Object} addresses
     * @returns {String} phone
     **/
    vm.getPhone = function() {
        if (angular.isDefined(vm.storedData.phone) && vm.storedData.phone.length == 10) {
            return '(' + vm.storedData.phone.slice(0, 2) + ') ' + vm.storedData.phone.slice(2, 6) + ' ' + vm.storedData.phone.slice(6, 10);
        } else if (vm.storedData.phone != '-') {
            return vm.storedData.phone;
        } else {
            return '';
        }
    };

    vm.getAddressObject = function() {
        if (angular.isDefined(vm.storedData.addresses) && vm.storedData.addresses.length > 0) {
            return vm.storedData.addresses[0];
        }
        return "";
    };

    /**
     * @memberof MobileController
     * @method trackingSendToMobile
     * @name trackingSendToMobile
     * @description tracker for send to mobile: params retrieve event to catch
     *
     */
    vm.trackingSendToMobile = function(params) {
        if(vm.storedData.isTol){
            DTM.trackAnalyticOnClick("top:" + params);
        } else if(vm.storedData.isBol) {
            DTM.trackAnalyticOnClick("bottom:" + params);
        } else {
            DTM.trackAnalyticOnClick(params);
        }
    };

    $scope.$on('$destroy', function(_listing) {
        vm.trackingSendToMobile('send to mobile abandon');
    });
}
