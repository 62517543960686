(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name videoSlider
     * @description homepage local stars
     */
    .directive('videoSliderItem', videoSliderItem);


    /** @ngInject */
    function videoSliderItem() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/app/components/tradiepage/videoslider/videoslider-item.html',
            scope: {
                tradie: '=',
                tradieIndex: '=index',
                onExpose: '&',
                city: '=',
            },
            link: function(scope, element) {
                scope.$emit('slide.register', element);
            },
            controller: ['$modal', '$log', 'DTM', '$window','$document', videoSliderItemController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function videoSliderItemController($modal, $log, DTM, $window, $document) {
            var vm = this;

            /**
             * @memberof bdpGallery
             * @method openTheatre
             * @name openTheatre
             * @description Method for opening modal with theatre view for listing of photos.
             * @params {_index} default image index
             */
            vm.openTheatre = function() {
                var scrollNo = 0;

                var galleryTheatre = $modal.open({
                    templateUrl: '/app/components/tradiepage/videonavigator/videonavigator.html',
                    controller: 'TradieGalleryNavigatorController',
                    controllerAs: 'gallery',
                    size: 'dialog_size_fullscreen gallery-modal-open',
                    resolve: {
                        images: function() {
                            return vm.tradie.featureVideo.previewImageUrl;
                        },
                        listing: function() {
                            return vm.tradie.featureVideo.organisationId;
                        },
                        selectedImage: function() {
                            return vm.tradieIndex;
                        },
                        city: function() {
                            return vm.city;
                        },
                    },
                });
                galleryTheatre.opened.then(function() {
                    scrollNo = $window.scrollY;
                });

                galleryTheatre.result.then(function(selectedItem) {
                    document.body.classList.remove('noHorizontalScroll');
                    scrollDetectWithTimeout.enable();
                    $document.scrollTop(scrollNo);
                }, function() {
                    $log.debug('Modal dismissed at: ' + new Date());
                });
            };


           /**
           * @memberof videoSlider
           * @method videoPopup
           * @name videoPopup
           * @description Returns url for item.
           * @params {Object} listing
           * @params {String} target
           */
            vm.videoPopup = function() {
                if (!vm.hasVideo()) return;
                vm.openTheatre();
                DTM.trackAnalyticOnClick('video opened');
                vm.onExpose();
            };


            /**
             * @memberof videoSlider
             * @method getUrl
             * @name getUrl
             * @description Returns url for item.
             * @params {Object} listing
             * @params {String} target
             * @returns {String} getUrl
             */
            this.getBDPurl = function() {
                DTM.trackAnalyticOnClick('get in touch');

                window.open(
                    'business/' + vm.tradie.seoUrl,
                    '_blank'
                );
            };

            vm.getRating = function(review) {
                var rating = review;
                if (!rating) {
                    return 'no reviews';
                } else if (rating == 1) {
                    return rating + ' review';
                } else {
                    return rating + ' reviews';
                }
            };

            vm.hasVideo = function() {
                return !!vm.tradie.featureVideo.url;
            };
        }
    }
})();
