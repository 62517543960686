(function () {
  'use strict';
  angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpAddress
     * @description Directive displays business address in bdp page header. Depending which address data is available
     * and how address is long, directive decide which display option (case) for address will choose:
     *
     *      Case 1 - If business have street address (address lines), and each line have
     *               less than 55 characters:
     *          First line:  streetAddress (street and house number)
     *          Second line: suburb, state, post code
     *
     *      Case 2 - If business doesn't have street address (address lines), and each line have
     *               less than 55 characters:
     *          First line:  suburb
     *          Second line: state, post code
     *
     *      Case 3 - If any of lines ordered as for case 1 or case 2, exceed 55 characters, then address will be
     *               displayed in two lines with truncation applied when text doesn't fit in element.
     *          First and second line:  streetAddress (street and house number), suburb, state, post code
     *
     *
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {factory}   DTM                       TL tracking services
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $scope                    Angular scope service
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     * @param {factory}   gmapService               Google map service
     * @param {service}   sharedService             Provides methods that handles initialization values for google maps.
     *
     * @example
     *
     * <bdp-address addresses="[object]" with-item-props=[bool]></bdp-address>
     *
     */
      .directive('bdpAddress', bdpAddress);

  /** @ngInject */
  function bdpAddress() {
    var directive = {
      restrict: 'E',
      templateUrl: '/app/components/bdp/bdpaddress/bdpaddress.html',
      scope: {
        addresses: '=',
        addressesTip: '=',
        withItemProps: '='
      },
      controller: BdpAddressController,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

    /** @ngInject */
    function BdpAddressController($element, $modal, DTM, $log, $scope, $timeout, $document, apiConfig, platformService, windowScroll, gmapService, $window, sharedService) {

      var vm = this;
      vm.getAddressCase = getAddressCase;
      vm.getFullAddress = getFullAddress;
      vm.suppressedFirstLine = suppressedFirstLine;
      vm.suppressedSecondLine = suppressedSecondLine;
      vm.getSuburb = getSuburb;
      vm.getState = getState;
      vm.getPostCode = getPostCode;
      vm.haveLocation = haveLocation;
      vm.haveCoord = haveCoord;
      vm.suppressedFirstLineSuburbCheck = suppressedFirstLineSuburbCheck;
      vm.getStreetAddress=getStreetAddress;
      vm.limitLength = 55; //if address is longer than 110 chars in two lines it is considered as long address, case 3
      vm.locationModal = locationModal;
      vm.getTooltipAddress = getTooltipAddress;
      vm.templateUrl = 'popoverTemplate.html';
      vm.addresWidth = 100; //initial, opHours in DOM
      vm.addressCase = 0; //
      vm.popOver = false;
      vm.boxIsHidden = true;
      vm._isMobile = platformService.isMobile();
      var searchGMap = sharedService.getSearchGMap();


      vm.isMobileScreenSize = function() {
        return window.outerWidth <= 767;
      };

      vm.hoverActive = function (state) {
        vm.popOver = state;
      };

      vm.getLocationTip = function() {
        return vm.addressesTip;
      };

      vm.toggleTooltip = function() {
        if (!vm.getLocationTip()) {
          vm.locationModal(vm.addresses);
          return;
        }

        vm.boxIsHidden = !vm.boxIsHidden;
        if (!vm.boxIsHidden) { scrollTooltip(); }
      };

      function scrollTooltip() {
        var toolTip = angular.element(document.querySelector('.address-tip.tooltip'));
        var toolTipOffset = offset(toolTip);
        var modalBottom = toolTipOffset.top + toolTip[0].offsetHeight;
        var shouldScrool = modalBottom > $window.outerHeight;
        if (shouldScrool || vm._isMobile) {
          $document.scrollTop(250, 1000);
        }
      }

      function offset(elm) {
        try {
          return elm.offset();
        } catch (e) {
          $log.debug('inconsecvent error');
        }
        var rawDom = elm[0];
        var _x = 0;
        var _y = 0;
        var body = document.documentElement || document.body;
        var scrollX = window.pageXOffset || body.scrollLeft;
        var scrollY = window.pageYOffset || body.scrollTop;
        _x = rawDom.getBoundingClientRect().left + scrollX;
        _y = rawDom.getBoundingClientRect().top + scrollY;
        return {
          left: _x,
          top: _y
        };
      }

      var documentClickHandler = function (event) {
        if (!vm.boxIsHidden) {
          var element = $element;
          var eventOutsideTarget = (element[0] !== event.target) && (0 === element.find(event.target).length);
          if (eventOutsideTarget) {
            vm.boxIsHidden = true;
          }
        }
      };

      $document.on("click", documentClickHandler);
      $scope.$on('$destroy', function () {
        $document.off("click", documentClickHandler);
      });

      /**
       * @memberof  bdpAddress
       * @method getAddressCase
       * @name getAddressCase
       * @description Based on 1 what address fields are fulfilled and how long they are, method will calculate how to
       * display address (which case to apply): 1 - normal address, 2 - empty address, 3 - long address
       * @param {Object} addresses
       * @returns {Number}
       */
      function getAddressCase(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return 0;
        }
        else if (angular.isUndefined(vm.addresses[0])) {
          return 0;
        }
        if (vm.addressCase > 0) {
          return vm.addressCase;
        }
        var addressLines = _breaklines(getAddressLines(vm.addresses), vm.limitLength);
        var secLineLength = vm.getSuburb().length + vm.getState().length + vm.getPostCode().length + 5;
        if (addressLines.length === 1 && addressLines[0] !== '' && secLineLength < vm.limitLength) { //normal address lines, normal sec lines
          vm.addressCase = 1;
          apiConfig.setBusinessAddress(vm.getFullAddress() + '\n' + vm.getSuburb()+ ' ' + vm.getState()+ ' ' + vm.getPostCode());
        } else if (getFullAddress().length > 0 && getFullAddress().length < 100) {
          vm.addressCase = 1;
          apiConfig.setBusinessAddress(vm.getFullAddress() + '\n' + vm.getSuburb()+ ' ' + vm.getState()+ ' ' + vm.getPostCode());
        } //check if we have addressline1 and addressline2 and we
        else if (addressLines.length === 1 || addressLines[0] === '') { //address empty, sec line normal
          vm.addressCase = 2;
          apiConfig.setBusinessAddress(vm.getSuburb()+ ' ' + vm.getState()+ ' ' + vm.getPostCode());

        } else { // in all other cases truncate necessary
          vm.addressCase = 3;
          apiConfig.setBusinessAddress(vm.getTooltipAddress());
        }
        //vm.addressCase = 4;
        return vm.addressCase;
      }


      /**
       * @memberof  bdpAddress
       * @method getTooltipAddress
       * @name getTooltipAddress
       * @description Get full address with all fields, as displayed on tooltip.
       * @returns {String}
       */
      function getTooltipAddress() {
        var ttText = getAddressLines(vm.addresses);
        if (getStreetAddress().length > 0) {
          ttText += '';
        } else if (vm.getSuburb().length > 0 || vm.getState().length > 0 || vm.getPostCode().length > 0) {
          ttText += ', ';
        }
        ttText += getStreetAddress().trim() + ' ' + vm.getSuburb() + ' ' + vm.getState() + ' ' + vm.getPostCode();
        return ttText.trim();
      }


      /**
       * @memberof  bdpAddress
       * @method
       * @name _breaklines
       * @description Split input text on lines with max charaters.
       * @param {String} text
       * @param {Number} max
       * @returns {String}
       */
      function _breaklines(text, max) {
        function explode() {
          text = text.replace(/  +/g, " ").replace(/^ /, "").replace(/ $/, "");
          if (angular.isUndefined(text)) return "";
          if (angular.isUndefined(max)) max = 50;
          if (text.length <= max) return text;
          var exploded = text.substring(0, max);
          text = text.substring(max);
          if (text.charAt(0) !== " ") {
            while (exploded.charAt(exploded.length - 1) !== " " && exploded.length > 0) {
              text = exploded.charAt(exploded.length - 1) + text;
              exploded = exploded.substring(0, exploded.length - 1);
            }
            if (exploded.length === 0) {
              exploded = text.substring(0, max);
              text = text.substring(max);
            } else {
              exploded = exploded.substring(0, exploded.length - 1);
            }
          } else {
            text = text.substring(1);
          }
          return exploded + "\n" + explode(text);
        }

        var breaked = explode(text, max);
        breaked = breaked.trim() === '' ? [''] : breaked.split('\n');
        return breaked;
      }


      /**
       * @memberof  bdpAddress
       * @method getStreetAddress
       * @name getStreetAddress
       * @description Get street address. Empty string if empty.
       * @param {Object} addresses
       * @returns {String}
       */
      function getStreetAddress(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0])) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0].streetName) && angular.isUndefined(vm.addresses[0].streetNumber)) {
          return '';
        }
        else if (vm.addresses[0].streetName === '' && vm.addresses[0].streetNumber === '') {
          return '';
        } else {
          if (getAddressLines(vm.addresses) !== '' && angular.isDefined(getAddressLines(vm.addresses))) {
            return ', ' + ((angular.isDefined(vm.addresses[0].streetNumber)) ? vm.addresses[0].streetNumber : '') + ' ' + ((angular.isDefined(vm.addresses[0].streetName)) ? vm.addresses[0].streetName + ' ' + ((angular.isDefined(vm.addresses[0].streetType)) ? vm.addresses[0].streetType : '') : '');
          } else {
            return ((angular.isDefined(vm.addresses[0].streetNumber)) ? vm.addresses[0].streetNumber : '') + ' ' + ((angular.isDefined(vm.addresses[0].streetName)) ? vm.addresses[0].streetName + ' ' + ((angular.isDefined(vm.addresses[0].streetType)) ? vm.addresses[0].streetType : '') : '');
          }
        }
      }


      /**
       * @memberof  bdpAddress
       * @method getAddressLines
       * @name getAddressLines
       * @description Get main address fields as string.
       * @param {Object} addresses
       * @returns {String} 'addressLine1, addressLine2'
       */
      function getAddressLines(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0])) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0].addressLine1) && angular.isUndefined(vm.addresses[0].addressLine2)) {
          return '';
        }
        else if (vm.addresses[0].addressLine1 === '' && vm.addresses[0].addressLine2 === '') {
          return '';
        } else {
          if (angular.isDefined(vm.addresses[0].addressLine2) && vm.addresses[0].addressLine2 !== '') {
            if (angular.isDefined(vm.addresses[0].addressLine1) && vm.addresses[0].addressLine1 !== '') {
              return ((angular.isDefined(vm.addresses[0].addressLine1)) ? vm.addresses[0].addressLine1 : '') + ', ' + ((angular.isDefined(vm.addresses[0].addressLine2)) ? vm.addresses[0].addressLine2 : '');
            } else {
              return ((angular.isDefined(vm.addresses[0].addressLine2)) ? vm.addresses[0].addressLine2 : '');
            }
          } else {
            return ((angular.isDefined(vm.addresses[0].addressLine1)) ? vm.addresses[0].addressLine1 : '');
          }
        }
      }


      /**
       * @memberof  bdpAddress
       * @method suppressedFirstLine
       * @name suppressedFirstLine
       * @description Return string "hidden" if address is not present, or empty string otherwise, to enable to hide
       * empty element.
       * @param {Object} addresses
       * @returns {String} 'hidden' or ''
       */
      function suppressedFirstLine(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return 'hidden';
        } else if (getStreetAddress(vm.addresses) === '' && getAddressLines(vm.addresses) === '' && vm.getAddressCase(vm.addresses)!==2) {
          return 'hidden';
        } else {
          return '';
        }
      }


      /**
       * @memberof  bdpAddress
       * @method suppressedFirstLineSuburbCheck
       * @name suppressedFirstLineSuburbCheck
       * @description Return string "hidden" if address or suburb is not present, or empty string otherwise,
       * to enable to hide empty element.
       * @param {Object} addresses
       * @returns {String} 'hidden' or ''
       */
      function suppressedFirstLineSuburbCheck(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return 'hidden';
        } else if (getSuburb(vm.addresses) === '') {
          return 'hidden';
        } else {
          return '';
        }
      }


      /**
       * @memberof  bdpAddress
       * @method suppressedSecondLine
       * @name suppressedSecondLine
       * @description Return string "hidden" if suburb, state or postal code is not present,
       * or empty string otherwise, to enable to hide empty element.
       * @param {Object} addresses
       * @returns {String} 'hidden' or ''
       */
      function suppressedSecondLine(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return 'hidden';
        } else if (vm.getSuburb(vm.addresses) === '' || vm.getState(vm.addresses) === '' || vm.getPostCode(vm.addresses) === '') {
          return 'hidden';
        } else {
          return '';
        }
      }


      /**
       * @memberof  bdpAddress
       * @method getSuburb
       * @name getSuburb
       * @description Get suburb as string.
       * @param {Object} addresses
       * @param {Boolean} nocoma weather to add comma at the end.
       * @returns {String} suburb
       */
      function getSuburb(addresses, nocoma) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return '';  //will return undefined
        }
        else if (angular.isUndefined(vm.addresses[0])) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0].suburb)) {
          return '';
        } else {
          if (vm.getState(vm.addresses) !== '' && angular.isUndefined(nocoma)) {
            return vm.addresses[0].suburb + ',';
          } else {
            return vm.addresses[0].suburb;

          }
        }
      }


      /**
       * @memberof  bdpAddress
       * @method getState
       * @name  getState
       * @description Get state as string.
       * @param {Object} addresses
       * @returns {String} state
       */
      function getState(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0])) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0].state)) {
          return '';
        }
        else if (vm.addresses[0].state.length === 0) {
          return '';
        } else {
          if (vm.getPostCode(vm.addresses) !== '') {
            return vm.addresses[0].state + ',';
          } else {
            return vm.addresses[0].state;
          }
        }
      }


      /**
       * @memberof  bdpAddress
       * @method getPostCode
       * @name getPostCode
       * @description Get postCode as string.
       * @param {Object} addresses
       * @returns {String} postCode
       */
      function getPostCode(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0])) {
          return '';
        }
        else if (angular.isUndefined(vm.addresses[0].postCode)) {
          return '';
        }
        else if (vm.addresses[0].postCode.length === 0) {
          return '';
        } else {

          return vm.addresses[0].postCode;
        }
      }


      /**
       * @memberof  bdpAddress
       * @method haveLocation
       * @name haveLocation
       * @description Return true if address, suburb, state and postal code are present, or false otherwise.
       * @param {Object} addresses
       * @returns {Boolean} have location
       */
      function haveLocation(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return false;
        } else if (vm.suppressedSecondLine(vm.addresses) == 'hidden' && vm.suppressedFirstLine(vm.addresses) == 'hidden') {
          return false;
        } else {
          return true;
        }
      }


      /**
       * @memberof  bdpAddress
       * @method haveCoord
       * @name haveCoord
       * @description Return true if address have latitude and longitude.
       * @param {Object} addresses
       * @returns {Boolean} have location
       */
      function haveCoord(addresses) {
        if (angular.isDefined(addresses)) {
          vm.addresses = addresses;
        }
        if (angular.isUndefined(vm.addresses)) {
          return false;
        }
        else if (angular.isUndefined(vm.addresses[0])) {
          return false;
        }
        else if (angular.isUndefined(vm.addresses[0].latitude)) {
          return false;
        }
        else if (angular.isUndefined(vm.addresses[0].longitude)) {
          return false;
        }
        else if (vm.addresses[0].latitude.length === 0) {
          return false;
        }
        else if (vm.addresses[0].longitude.length === 0) {
          return false;
        } else {
          return true;
        }
      }


      /**
       * @memberof  bdpAddress
       * @method getFullAddress
       * @name getFullAddress
       * @description Return full address as string.
       * @returns {String} full address
       */
      function getFullAddress() {
        return getAddressLines() + getStreetAddress();
      }


      /**
       * @memberof  bdpAddress
       * @method locationModal
       * @name locationModal
       * @description Open modal with map view displaying exact location if present.
       */
      var _modalInstance = null;

      function locationModal(addresses) {
        if (_modalInstance) {
          return;
        }

        if (vm.haveCoord() === false) {
          _modalInstance = $modal.open({
            templateUrl: '/app/components/bdp/bdpaddress/nomap.html',
            controller: ['$modalInstance', '$window', 'addresses', '$timeout', LocationModalConttroller],
            size: 'dialog_size_small dialog-special-mobile',
            controllerAs: 'vm',
            resolve: {
              addresses: function () {
                return addresses;
              }

            }
          });
          _modalInstance.opened.then(function () {
            windowScroll.mobileModalScroll();
            angular.element(document.querySelector('html')).addClass('map-modal');
            angular.element(document.querySelector('html')).addClass('_modal-open');
          });
          _modalInstance.result.finally(function () {
            windowScroll.mobileModalCloseHandler();
            angular.element(document.querySelector('html')).removeClass('_modal-open');
            _modalInstance = null;
          });
        } else {
          _modalInstance = $modal.open({
            templateUrl: '/app/components/bdp/bdpaddress/locationmodal.html',
            controller: ['$modalInstance', '$window', 'addresses', '$timeout', 'gmapService', LocationModalConttroller],
            controllerAs: 'vm',
            resolve: {
              addresses: function () {
                return addresses;
              }
            }
          });
          _modalInstance.opened.then(function () {
            windowScroll.mobileModalScroll();
            angular.element(document.querySelector('html')).addClass('map-modal');
            angular.element(document.querySelector('html')).addClass('_modal-open');
          });
          _modalInstance.result.finally(angular.bind(_modalInstance, function () {
            windowScroll.mobileModalCloseHandler();
            angular.element(document.querySelector('html')).removeClass('_modal-open');
            _modalInstance = null;
          }));
        }

        DTM.trackAnalyticOnClick('show map');
      }

      $scope.$on('$destroy', function () {
        windowScroll.mobileModalCloseHandler();
        angular.element(document.querySelector('html')).removeClass('_modal-open');
        _modalInstance = null;
      });


      /**
       * @memberof truelocal
       * @ngdoc controller
       * @name LocationModalConttroller
       * @description Open modal with map view displaying exact location if present.
       */
      function LocationModalConttroller($modalInstance, $window, addresses, $timeout, gmapService, $state) {
        this.getCoord = function () {
          return vm.addresses[0].latitude + ',' + vm.addresses[0].longitude;
        };
        var _mapView, _mapInstance, _bounds, _latlng, _marker, _zoomLevelForSingleMarker;
        this.getLocationTip = vm.getLocationTip;

        this.address = vm.addresses[0];
        this.close = function (_close) {
          this.destroyMap();
          $modalInstance.dismiss('cancel');
        };
        gmapService.getInstance();

        angular.element($window).on("url-changed", angular.bind(this, this.close));

        this.destroyMap = function () {
          _mapInstance = gmapService.getInstance();
          if (_marker && _marker.setMap) {
            _marker.setMap(null);
            _marker = null;
          }
          if (_mapInstance) {
            _mapInstance.setOptions({
              zoomControlOptions: {
                position: gmapService.getConstants().ControlPosition.LEFT_TOP
              },
              maxZoom: null
            });
          }
          angular.element(window).off('map-is-ready', _completeMapSetup);
        };

        var _completeMapSetup = function () {
          _mapInstance = gmapService.getInstance();
          _mapView = gmapService.getMap();
          _mapView.style.height = "100%";
          _mapView.style.width = "100%";


          _bounds = new google.maps.LatLngBounds();
          _latlng = new google.maps.LatLng(vm.addresses[0].latitude, vm.addresses[0].longitude);
          _bounds.extend(_latlng);

          _marker = new google.maps.Marker({
            position: _latlng,
            icon: searchGMap.markerSelectedIcon,
            map: _mapInstance,
            options: searchGMap.defaultMarkerOptions
          });

          angular.element(document.querySelector('.map-holder')).append(_mapView);
          google.maps.event.trigger(_mapInstance, 'resize');
          _zoomLevelForSingleMarker = (vm.showMobileWindow) ? searchGMap.singleListSelectedMarkerZoomLevelForMobile : searchGMap.singleListSelectedMarkerZoomLevelForDesktop;
          _mapInstance.setOptions({
            zoomControlOptions: {
              position: gmapService.getConstants().ControlPosition.RIGHT_BOTTOM
            },
            maxZoom: _zoomLevelForSingleMarker
          });
          _mapInstance.fitBounds(_bounds);
        };

        var ctrl = this;
        ctrl.initVar = false;
        this.init = function () {
          return ctrl.initVar;
        };
        $timeout(function () {
          ctrl.initVar = true;
          //$broadcast('init-map');
          $timeout(function () {
            if (gmapService.isready()) {
              _completeMapSetup();
            } else {
              angular.element(window).on('map-is-ready', angular.bind(ctrl, _completeMapSetup));
            }
          });
        });
      }

      function setAddresWdt() {
        var opHours = angular.element(document.querySelector('#opening-hours-1'));
        var phone = angular.element(document.querySelector('#phone-1'));
        var reqAddrWidth = phone[0].offsetWidth - 390 - opHours[0].offsetWidth;
        return reqAddrWidth;
      }

      function calculateAddress() {
        var totalWidth, phoneWidth, phoneMargin,
          hrsArea, hrsWidth, hrsMargin;

        if (platformService.getWidth() > 868) {
          phoneWidth = 0;
          phoneMargin = 0;
          var phoneArea = angular.element(document.querySelector('#phone-1 .phone'));

          if (phoneArea.length > 0) {
            phoneWidth = phoneArea[0].clientWidth;
            phoneMargin = phoneArea.css("margin-right");
            if (angular.isDefined(phoneMargin)) {
              phoneMargin = phoneMargin.replace('px', '');
              phoneMargin = phoneMargin.replace('rem', '');
              phoneMargin = parseInt(phoneMargin);
            } else {
              phoneMargin = 0;
            }
          }

          hrsWidth = 0;
          hrsMargin = 0;
          hrsArea = angular.element(document.querySelector('#hrs-area'));

          if (hrsArea.length > 0) {
            hrsWidth = hrsArea[0].clientWidth;
            hrsMargin = hrsArea.css("margin-right");
            if (angular.isDefined(hrsMargin)) {
              hrsMargin = hrsMargin.replace('px', '');
              hrsMargin = hrsMargin.replace('rem', '');
              hrsMargin = parseInt(hrsMargin);
            } else {
              hrsMargin = 0;
            }
          }

          var phoneElement = angular.element(document.querySelector('#phone-1'))[0];
          totalWidth = phoneElement ? phoneElement.clientWidth : 0;

          return totalWidth - hrsWidth - hrsMargin - phoneWidth - phoneMargin - 120;
        } else {
          phoneWidth = 0;
          phoneMargin = 0;

          hrsWidth = 0;
          hrsMargin = 0;
          hrsArea = angular.element(document.querySelector('#hrs-area'));

          if (hrsArea.length > 0) {
            hrsWidth = hrsArea[0].clientWidth;
            hrsMargin = hrsArea.css("margin-right");
            if (angular.isDefined(hrsMargin)) {
              hrsMargin = hrsMargin.replace('px', '');
              hrsMargin = hrsMargin.replace('rem', '');
              hrsMargin = parseInt(hrsMargin);
            } else {
              hrsMargin = 0;
            }
          }

          var contactHolderElement = angular.element(document.querySelector('#phone-1 > .contact-holder'))[0];
          totalWidth = contactHolderElement ? contactHolderElement.clientWidth : 0;

          return totalWidth - hrsWidth - hrsMargin - phoneWidth - phoneMargin - 120;
        }
      }


      vm.getaddresWidth = function () {
        if (angular.isDefined(vm.addresses)) {
          return calculateAddress();
        } else {
          return 200;
        }
      };

      angular.element($window).bind('resize', function(){
        calculateAddress();
        vm.addresWidth = calculateAddress();
      });
    }
  }
})();
