(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name HeadController
     * @description HeadController.
     */
    .controller('HeadController', ["Seo", "appService", "searchConfigManager", "scriptLoaderService", "$window", "env", function HeadController(Seo, appService, searchConfigManager, scriptLoaderService, $window, env) {
      var vm = this;

      vm.Seo = Seo;
      vm.appService=appService;
      vm.SeoService = Seo.seoInService();
      vm.searchConfigManager = searchConfigManager;
      vm.injectMap = false;

      $window.onload = function() {
        var src = "https://www.google.com/recaptcha/api.js?render=" + env.google.recaptcha.v3;

        scriptLoaderService.load(src);
      }
  }]);

})();
