(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpCommentBoxSmall
     * @description Textarea input with special handling used in add comment
     *
     * @param {constant} constantsPlaceholders  Constant with placeholders
     *
     * @example
     * <bdp-comment-box-small commented-text="[string]"></bdp-comment-box-small>
     */
    .directive('bdpCommentBoxSmall', bdpCommentBoxSmall)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name ngKeystrokeSmall
     * @description Directive handle tracking of user interaction with small comment box.
     */
    .directive('ngKeystrokeSmall', ['DTM', '$timeout', keyStokeHandlerSmall]);

    function keyStokeHandlerSmall(DTM, $timeout) {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                elem.bind('focus', function() {
                    DTM.trackAnalyticOnClick('write comment start');
                });
                elem.bind('input', function() {
                    $timeout(function() {
                        scope.vm.numberOfCharactersLeft = (1000 - angular.element(elem).val().length);
                        if(scope.vm.numberOfCharactersLeft < 0) {
                            angular.element(elem)[0].blur();
                        }
                    });
                });
            },
            scope: {
                vm: '=',
            },
        };
    }

  /** @ngInject */
    function bdpCommentBoxSmall() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpcommentboxsmall/bdpcommentboxsmall.html',
            scope: {
                commentedText: '=',
            },
            controller: ['constantsPlaceholders', BdpCommentBoxSmallController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */

        function BdpCommentBoxSmallController(constantsPlaceholders) {
            this.constantsPlaceholders = constantsPlaceholders.bdpCommentBoxSmall;
            this.commentedText = '';
            this.numberOfCharactersLeft = this.constantsPlaceholders.maxCharacters;
        }
    }
})();
