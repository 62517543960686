(function () {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc service
         * @name seoService
         * @description seoService provides interface to set/get SEO values (page title, description and keywords).
         */
        .factory('Seo', seoService);

    /** @ngInject */
    function seoService($location, $document, $state) {
        var service = {
            pageTitle: {
                value: 'True Local - Loading ...',
                set: setPageTitle,
                get: getPageTitle,
            },
            description: {
                value: '',
                set: setMetaDescription,
                get: getMetaDescription,
            },
            keywords: {
                value: '',
                set: setMetaKeyWords,
                get: getMetaKeyWords,
            },
            customMeta: customMeta,
            cleanCustomMeta: cleanCustomMeta,
            seoInService: seoInService,
        };

        function setPageTitle(value) {
            service.pageTitle.value = value;
        }

        function getPageTitle() {
            return service.pageTitle.value;
        }

        function setMetaDescription(value) {
            service.description.value = value;
        }

        function getMetaDescription() {
            return service.description.value;
        }

        function setMetaKeyWords(value) {
            service.keywords.value = value;
        }

        function getMetaKeyWords() {
            return service.keywords.value;
        }

        //Moved the code from search.config.manager.js to here as this code will be used for generating canonical links
        // for all the pages and not specific to search and find
        function seoInService() {
            var _seoService = {
                isActive: function () {
                    return $state.current.name == 'search' || $state.current.name == 'find' || $state.current.name
                                                                                               == 'search-location'
                           || $state.current.name == 'truelocal-advertising' || $state.current.name == 'homepage'
                           || $state.current.name == 'business' || $state.current.name == 'browse';
                },
                hasNext: function () {
                    if ($state.current.name != 'search' && $state.current.name != 'find') {
                        return false;
                    }
                    return !!(parseCurrentPage() + 1);
                },
                hasPrev: function () {
                    if ($state.current.name != 'search' && $state.current.name != 'find') {
                        return false;
                    }
                    return !!(parseCurrentPage() - 1);
                },
                nextPath: function () {
                    var page = parseCurrentPage() + 1;
                    return $location.absUrl().split('?')[0] + '?page=' + page;
                },
                prevPath: function () {
                    var page = parseCurrentPage() - 1;
                    return $location.absUrl().split('?')[0] + (page === 1 ? '' : '?page=' + page);
                },
                currentPath: function () {
                    var page = parseCurrentPage();
                    return $location.absUrl().split('?')[0] + (page === 1 ? '' : '?page=' + page);
                },
                canonicalPath: function () {
                    if ($state.current.name === 'search' || $state.current.name === 'find') {
                        var page = parseCurrentPage();
                        var findUrl = $location.absUrl().split('?')[0];

                        if(transformCanonicalUrlTest(findUrl)) {
                            findUrl = transformCanonicalUrl(findUrl + (page === 1 ? '' : '?page=' + page));
                        }
                        return findUrl;
                    }
                    function transformCanonicalUrl (canonicalUrl) {
                        var canonicalMajorCities = [
                            {orgUrl:"/nsw/sydney-city", targetUrl:"/nsw/sydney-city/sydney"},
                            {orgUrl:"/vic/melbourne-city", targetUrl:"/vic/melbourne-city/melbourne"},
                            {orgUrl:"/qld/brisbane-city", targetUrl:"/qld/brisbane-city/brisbane"},
                            {orgUrl:"/sa/adelaide-city", targetUrl:"/sa/adelaide-city/adelaide"},
                            {orgUrl:"/wa/perth-city", targetUrl:"/wa/perth-city/perth"},
                            {orgUrl:"/tas/hobart-central", targetUrl:"/tas/hobart-central/hobart"},
                            {orgUrl:"/act/canberra-city", targetUrl:"/act/canberra-city/canberra"},
                            {orgUrl:"/nt/darwin-city", targetUrl:"/nt/darwin-city/darwin"}
                        ];

                        angular.forEach(canonicalMajorCities, function(canonicalValue) {
                            if (canonicalUrl.indexOf(canonicalValue.orgUrl) > -1 && canonicalUrl.indexOf(canonicalValue.targetUrl) == -1) {
                                canonicalUrl = canonicalUrl.replace(canonicalValue.orgUrl, canonicalValue.targetUrl);
                            }
                        });
                        return canonicalUrl;
                    }

                    // Request url's location path for /find must only be two levels deep for it to pass the test.
                    function transformCanonicalUrlTest(requestUrl) {
                        var requestPath = requestUrl.substring((requestUrl.indexOf($state.current.name)+$state.current.name.length)+1);
                        return (requestPath.substring((requestPath.indexOf('/'))+1).split('/').length == 2);
                    }

                    return transformCanonicalUrl($location.absUrl().split('?')[0]);
                },

            };

            return _seoService;
        }

        function parseCurrentPage() {
            return parseInt($location.search().page) || 1;
        }

        function customMeta(name, value, replace) {
            if (replace) {
                cleanCustomMeta(name);
            }
            var _tpl = '<meta name="' + name + '" content="' + value + '">';
            $document.find('head').append(_tpl);
        }

        function cleanCustomMeta(metaName) {
            angular.forEach($document.find('meta'), function(metaElement){
                if(angular.isDefined(angular.element(metaElement)[0]) &&
                    angular.element(metaElement)[0].name == metaName){
                  angular.element(metaElement).remove();
                }
            });
        }

        return service;
    }
})();
