(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name homeHeader
         * @description Homepage header directive
         *  Includes the random image feature based on cookie so everytime displays new images
         *  Includes the random word animation
         *
         *
         * @param {constant}  homePageImagesWordsFeeder Collection with words for homepage
         * @param {service}   $cookies                  angular cookies service wrapper
         * @param {service}   $interval                 Angular setInterval service
         * @param  {service}  $timeout                  Angular window.setTimeout service
         */
        .directive('homeHeader', homeHeader);


    /** @ngInject */
    function homeHeader() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/homepage/homeheader/homeheader.html',
            scope: {
                creationDate: '=',
            },


            /**
             * @memberof truelocal
             * @ngdoc controller
             * @name HomeHeaderController
             * @description HomeHeaderController.
             */
            controller: ['homePageImagesWordsFeeder',
                '$cookies', '$interval', '$timeout', '$rootScope', '$location', 'apiConfig',
                HomeHeaderController
            ],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function HomeHeaderController(homePageImagesWordsFeeder,
            $cookies, $interval, $timeout, $rootScope, $location, apiConfig) {
            var vm = this;
            // CHOUSE A RANDOM IMAGE
            vm.headerBgStyle = '';
            vm.animateWords = false;
            vm.userPending = angular.isDefined(apiConfig.userPending()) ? apiConfig.userPending() : false;
            vm.isHome = ($location.path() === "/");

            /**
             * @memberof homeHeader
             * @method randomizeHeaderBg
             * @name randomizeHeaderBg
             * @description random select an image from constant and also compare with a cookie so will be different than the previous
             */
            vm.randomizeHeaderBgAndWord = function() {
                var _imgCount = homePageImagesWordsFeeder.images.length,
                    _lastImage = 0;

                if ($cookies.get('tl_lastImage')) {
                    _lastImage = $cookies.get('tl_lastImage');
                }
                var _currentImageIndex = _lastImage;
                do {
                    _currentImageIndex = Math.floor(Math.random() *
                        parseInt(_imgCount)) + 1;
                    $cookies.put('tl_lastImage', _currentImageIndex, {
                        path: '/'
                    });
                } while (_currentImageIndex == _lastImage);

                vm.headerBgStyle = {
                    'background-image': 'url(' +
                        homePageImagesWordsFeeder.images[
                            _currentImageIndex - 1] + ')',
                };

                if (angular.isUndefined(vm.words)) {
                    vm.words = homePageImagesWordsFeeder.words;
                }
                if (angular.isUndefined(vm.currentWordIndex)) {
                    vm.currentWordIndex = Math.floor(Math.random() *
                        parseInt(vm.words.length)) + 1;
                }

                vm.currentWord = vm.words[vm.currentWordIndex - 1];

                /*$interval(function() {
                    var _lastIndex = vm.currentWordIndex;
                    do {
                        vm.currentWordIndex = Math.floor(Math.random() * parseInt(vm.words.length)) + 1;
                    } while (_lastIndex == vm.currentWordIndex);
                    vm.currentWord = vm.words[vm.currentWordIndex-1];
                    vm.animateWords = true;

                    $timeout(function() {
                        vm.animateWords = false;
                    }, 2500);
                }, 5000);*/
            };

            /**
             * @memberof homeHeader
             * @method getCurrentWord
             * @name getCurrentWord
             * @description get the current word
             *
             * @return {string} the current word
             */
            vm.getCurrentWord = function() {
                return vm.currentWord;
            };

          angular.element(window).on('user-pending', function() {
            vm.userPending = true;
          });

          angular.element(window).on('user-unauthenticated', function() {
            vm.userPending = false;
          });

          angular.element(window).on('user-approved', function() {
            vm.userPending = false;
          });

          $rootScope.$on('$locationChangeSuccess', function(event, toState, toStateParams) {
            if($location.path() !== "/"){
              vm.isHome = false;
            } else {
              vm.isHome = true;
            }
          });
        }
    }
})();
