(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsContactWebsite
     * @description Directive will display business website link. It will format link to hide unnecessary parts,
     * and display website icon with text. Link will opens in new tab.
     *
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {service}   $log                      Angular console log wrapper
     * @param {constant}  env                       Environmental constants
     * @param {service}   $window                   Angular wrapper for window
     *
     */
    .directive('bdpDetailsContactWebsite', bdpDetailsContactWebsite);

  /** @ngInject */
    function bdpDetailsContactWebsite() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetailscontactwebsite/bdpdetailscontactwebsite.html',
            scope: {
                listing: '=',
                contacts: '=',
            },

            controller: ['apiConfig', '$log', 'env', '$window', 'DTM', BdpDetailsContactWebsiteController],
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

    /** @ngInject */
        function BdpDetailsContactWebsiteController(apiConfig, $log, env, $window, DTM) {
            var vm = this;

            vm.primaryWebsite = '';
            vm.havePrimaryWebsite = false;
            vm.primaryIconDisplay = false;


      /**
       * @memberof bdpDetailsContactWebsite
       * @method getHavePrimaryWebsite
       * @name getHavePrimaryWebsite
       * @description Check does business have website.
       * @param {Object} contact
       * @returns {Boolean}
       */
            vm.getHavePrimaryWebsite = function(contact) {
                if (angular.isDefined(contact)) {
                    this.contacts.contact = contact;
                }
                if (angular.isDefined(this.contacts)) {
                    if (angular.isDefined(this.contacts.contact)) {
                        angular.forEach(this.contacts.contact, function(contact) {
                            if (contact.type == 'website' && angular.isUndefined(contact.label)) {
                                vm.havePrimaryWebsite = true;
                            }
                        });
                    }
                }
                return vm.havePrimaryWebsite;
            };

            vm.show = '';

      /**
       * @memberof bdpDetailsContactWebsite
       * @method showSecIcon
       * @name showSecIcon
       * @description showSecIcon.
       * @param {String} value
       * @returns {Boolean}
       */
            vm.showSecIcon = function(value) {
                if (vm.havePrimaryWebsite == true) return false;
                else {
                    if (vm.show == '') {
                        vm.show = value;
                        return true;
                    } else if (vm.show == value) {
                        return true;
                    } else {
                        return false;
                    }
                }
            };


      /**
       * @memberof bdpDetailsContactWebsite
       * @method getPrimaryWebsite
       * @name getPrimaryWebsite
       * @description Return business primary website.
       * @param {Object} contact
       * @returns {String}
       */
            vm.getPrimaryWebsite = function(contact) {
                if (angular.isDefined(contact)) {
                    this.contacts.contact = contact;
                }
                if (angular.isDefined(this.contacts)) {
                    if (angular.isDefined(this.contacts.contact)) {
                        angular.forEach(this.contacts.contact, function(contact) {
                            if (contact.type == 'website' && angular.isUndefined(contact.label)) {
                                vm.primaryWebsite = contact.value;
                            }
                        });
                    }
                }
                return vm.primaryWebsite;
            };

            vm.secondaryWebsites = [];

            vm.haveSecondaryWebsites = false;

      /**
       * @memberof bdpDetailsContactWebsite
       * @method getHaveSecondaryWebsites
       * @name getHaveSecondaryWebsites
       * @description Check does business have secondary website.
       * @param {Object} contact
       * @returns {Boolean}
       */
            vm.getHaveSecondaryWebsites = function(contact) {
                if (angular.isDefined(contact)) {
                    this.contacts.contact = contact;
                }
                if (angular.isDefined(this.contacts)) {
                    if (angular.isDefined(this.contacts.contact)) {
                        angular.forEach(this.contacts.contact, function(contact) {
                            if (contact.type == 'website' && angular.isDefined(contact.label)) {
                                vm.haveSecondaryWebsites = true;
                            }
                        });
                    }
                }
                return vm.haveSecondaryWebsites;
            };


      /**
       * @memberof bdpDetailsContactWebsite
       * @method getSecondaryWebsites
       * @name getSecondaryWebsites
       * @description Return business secondary website.
       * @param {Object} contact
       * @returns {String}
       */
            vm.getSecondaryWebsites = function(contact) {
                if (angular.isDefined(contact)) {
                    this.contacts.contact = contact;
                }
                vm.secondaryWebsites = [];
                if (angular.isDefined(this.contacts)) {
                    if (angular.isDefined(this.contacts.contact)) {
                        angular.forEach(this.contacts.contact, function(contact) {
                            if (contact.type == 'website' && angular.isDefined(contact.label)) {
                                vm.secondaryWebsites.push(contact);
                            }
                        });
                    }
                }
                return vm.secondaryWebsites;
            };


      /**
       * @memberof bdpDetailsContactWebsite
       * @method getReadableUrl
       * @name getReadableUrl
       * @description Format URL in readable format.
       * @param {String} value
       * @returns {String}
       */
            vm.getReadableUrl = function(value) {
                if (value.indexOf('http://') == -1 && value.indexOf('https://') == -1) {
                    return 'http://' + value;
                } else {
                    return value;
                }
            };

      /**
       * @memberof bdpDetailsContactWebsite
       * @method trackWebsiteClick
       * @name trackWebsiteClick
       * @description Track the website link click event with DTM
       * @param {String} url
       */
      vm.trackWebsiteClick = function(url) {
        var finalTrackingUrl = vm.getReadableUrl(url);
        DTM.trackAnalyticOnClick('visit advertiser website');
        window.open(finalTrackingUrl, "_blank");
      };


      /**
       * @memberof bdpDetailsContactWebsite
       * @method getLabel
       * @name getLabel
       * @description Get label.
       * @param {Object} contact
       * @returns {String}
       */
            vm.getLabel = function(contact) {
                if (angular.isUndefined(contact)) {
                    return '';
                } else if (angular.isUndefined(contact.label)) {
                    return (angular.isUndefined(contact.value)) ? '' : contact.value;
                } else if (contact.label.length == 0) {
                    return (angular.isUndefined(contact.value)) ? '' : contact.value;
                } else {
                    return (angular.isUndefined(contact.label)) ? '' : contact.label;
                }
            };


      /**
       * @memberof bdpDetailsContactWebsite
       * @method bdpEventTracking
       * @name bdpEventTracking
       * @description bdpEventTracking.
       */
            vm.bdpEventTracking = function() {
                DTM.trackAnalyticOnClick('visit advertiser website');
            };


      /**
       * @memberof bdpDetailsContactWebsite
       * @method openLink
       * @name openLink
       * @description interface to window open link..
       */
            this.openLink = function($link, $target) {

                $window.open($link, angular.isDefined($target) ? $target : '_self');
            };
        }
    }
})();
