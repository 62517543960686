(function() {
    'use strict';
    angular
        .module('truelocal')
        .controller('BdpCategoriesDialogController', BdpCategoriesDialogController);
    /** @ngInject */
    function BdpCategoriesDialogController(categoriesList, searchByCategoryName, prepareSearchUrl) {
        var vm = this;
        vm.categories = categoriesList.category;
        vm.prepareSearchUrl = prepareSearchUrl;
        vm.searchByCategoryName = searchByCategoryName;
    }
})();
