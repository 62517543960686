(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name serviceConfig
     * @description serviceConfig service expose methods for getting/setting search configuration properties, parameters
     * and options.
     */
    .factory('serviceConfig', serviceConfig)


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name serviceConfigManager
     * @description serviceConfigManager service expose methods for manipulation with search results data. Results data
     * can be shown in three main view options (list, grid and map view), whose look vary with change of screen size,
     * access platform and number of results. serviceConfigManager provide data for desired view option.
     */
    .service('serviceConfigManager', serviceConfigManager);

    function serviceConfig($window) {
    /**
     * @memberof serviceConfig
     * @name uiConfig
     * @description UI configuration params store UI size limit block per view.
     */
        var uiConfig = {
            viewType: 'serviceView',
            serviceView: {
                desktop: 8,
                tablet: 6,
                mobile: 4,
            },
        };


    /**
     * @memberof serviceConfig
     * @name viewConfig
     * @description
     * SERVER request configuration
     * stores request definitions
     * filter definitions
     * type, keyword/location or both
     */
        var viewConfig = {
            limit: getLoadingLimit(),
            loadMore: 1,
        };

        var pullConfiguration = {
            limit: 20,
            offset: 0,
        };

        function getLoadMoreCount() {
            return viewConfig.loadMore;
        }

        function increaseLoadMoreCounter() {
            viewConfig.loadMore += 1;
        }

        function resetLoadMoreCounter() {
            viewConfig.loadMore = 1;
        }

        function getCurrentViewLimit() {
            return viewConfig.limit;
        }

        function setCurrentViewType(viewType) {
            uiConfig.viewType = viewType;
            viewConfig.limit = getLoadingLimit();
        }

        function getLoadingListLimit() {
            var _currentWidth = angular.element(window)[0].outerWidth;
            var _viewType = 'serviceView';
            var _platform = 'desktop';

            if (_viewType == 'serviceView') {
                if (_currentWidth <= 320) {
                    _platform = 'mobile';
                } else if (_currentWidth > 320 && _currentWidth <= 768) {
                    _platform = 'tablet';
                } else {
                    _platform = 'desktop';
                }
            }

            return uiConfig[_viewType][_platform];
        }

        function getLoadingLimit() {
            var _currentWidth = angular.element(window)[0].outerWidth;
            var _viewType = uiConfig.viewType;
            var _platform = 'desktop';

            if (_viewType == 'serviceView') {
                if (_currentWidth <= 768) {
                    _platform = 'mobile';
                } else if (_currentWidth > 768 && _currentWidth <= 991) {
                    _platform = 'tablet';
                } else {
                    _platform = 'desktop';
                }
            } else {
                if (_currentWidth > 768 && _currentWidth <= 991) {
                    _platform = 'tablet';
                } else {
                    _platform = 'desktop';
                }
            }

            return uiConfig[_viewType][_platform];
        }

        function getProperty() {
            var _keyValues = arguments;
            for (var argumentIndex = 0, argumentsSize = _keyValues.length; argumentIndex < argumentsSize; argumentIndex++) {
                var _indexedKey = _keyValues[argumentIndex];
                if (angular.isDefined(_indexedKey) && angular.isDefined(pullConfiguration[_indexedKey]) && pullConfiguration[_indexedKey] !== null && pullConfiguration[_indexedKey] !== '') {
                    return pullConfiguration[_indexedKey];
                }
            }
            return '';
        }

        function resetAllProperty() {
            pullConfiguration = null;
            pullConfiguration = {
                limit: 20,
                offset: 0,
            };
        }

        function setProperty(key, value) {
            if (angular.isDefined(value) && value === null) {
                delete pullConfiguration[key];
            } else {
                pullConfiguration[key] = value;
            }
        }

        function getConfiguration(onlySponsored) {
            var _tempConfig = angular.copy(pullConfiguration);

            for(var k in _tempConfig) {
                if(k == 'category' || k == 'location' || k == 'state' || k == 'suburb' || k == 'region' || k == 'keyword') {
                    _tempConfig[k] = _tempConfig[k].replace(/[ ]/gi, '-');
                }
            }

            if (angular.isDefined(onlySponsored) && onlySponsored) {
                _tempConfig.inventory = true;
            }

            return _tempConfig;
        }

        function getNextBlockConfig() {
            var _newStartPosition = parseInt(getProperty('offset')) + parseInt(getProperty('limit'));
            setProperty('offset', _newStartPosition);
            setProperty('limit', 20);

            return getConfiguration();
        }

        function getNewSortConfig() {
            var _offset = parseInt(getProperty('offset'));
            var _limit = parseInt(getProperty('limit'));
            var _limitSize = _offset + _limit;

            if (_limitSize > 20) {
                _limitSize = 20;
                viewConfig.loadMore = 2;
            }

            var _tempConfig = angular.copy(pullConfiguration);
            _tempConfig.offset = 0;
            _tempConfig.limit = _limitSize;

            for(var k in _tempConfig) {
                if(k == 'category' || k == 'location' || k == 'state' || k == 'suburb' || k == 'region' || k == 'keyword') {
                    _tempConfig[k] = _tempConfig[k].replace(/[ ]/gi, '-');
                }
            }

            return _tempConfig;
        }

        function getNumberOfBlocksAndLimit() {
            return [parseInt(viewConfig.loadMore), parseInt(viewConfig.limit)];
        }

        function getNumberOfViewableItems() {
            var _totalNumberOfViewableItems = parseInt(viewConfig.loadMore) * parseInt(viewConfig.limit);
            return _totalNumberOfViewableItems;
        }

        function fetchMoreStatus(_resultsPulledSize) {
            var _checkingSizeDifference = parseInt(_resultsPulledSize) - (parseInt(viewConfig.loadMore) * 10);
            var _fetchMoreData = _checkingSizeDifference < 10 ? true : false;

            return _fetchMoreData;
        }

        var service = {
            getProperty: getProperty,
            setProperty: setProperty,
            resetAllProperty: resetAllProperty,
            getConfiguration: getConfiguration,
            getNewSortConfig: getNewSortConfig,
            getNextBlockConfig: getNextBlockConfig,

            setCurrentViewType: setCurrentViewType,

            getNumberOfViewableItems: getNumberOfViewableItems,
            fetchMoreStatus: fetchMoreStatus,

            getLoadMoreCount: getLoadMoreCount,
            increaseLoadMoreCounter: increaseLoadMoreCounter,
            resetLoadMoreCounter: resetLoadMoreCounter,
            getCurrentViewLimit: getCurrentViewLimit,

            getNumberOfBlocksAndLimit: getNumberOfBlocksAndLimit,
            getLoadingListLimit: getLoadingListLimit,
        };

        return service;
    }

  /** @ngInject */
    function serviceConfigManager($window, uriService, serviceConfig, serviceListing, apiConfig, $stateParams, $state) {
        var resultsData,
            sponsoredData;

        var service = {
            initializeBaseParams: initializeBaseParams,

            getProperty: serviceConfig.getProperty,
            setProperty: serviceConfig.setProperty,
            resetAllProperty: serviceConfig.resetAllProperty,
            getSearchBreadcrump: getSearchBreadcrump,

            getConfiguration: serviceConfig.getConfiguration,
            getNewSortConfig: serviceConfig.getNewSortConfig,
            getNextBlockConfig: serviceConfig.getNextBlockConfig,

            setCurrentViewType: setCurrentViewType,

            displayNextResultsBlock: displayNextResultsBlock,
            displayItemsForChangedSortType: displayItemsForChangedSortType,
            hideLoadMoreButton: hideLoadMoreButton,

            getNumberOfViewableItems: serviceConfig.getNumberOfViewableItems,
            getNumberOfBlocksAndLimit: serviceConfig.getNumberOfBlocksAndLimit,

            getLoadingListLimit: serviceConfig.getLoadingListLimit,
        };

        function getListForSelectedFilterOption(_option) {
      // applying new filter option in base config
      // applying offset 0 to current limit not bigger then 100
      // pulling data from server
      // applying data in local store variable
      // preparing list for client based on UI config & viewConfig
      // returning list to client
        }

        function getSearchBreadcrump() {
            var keyword = serviceConfig.getProperty('keyword', 'category').capitalizeSentence();
            keyword = keyword ? keyword : 'Search';
            var location = serviceConfig.getProperty('location', 'suburb', 'region', 'state').capitalizeSentence().capitalizeSuburb();
            location = location ? ' in ' + location : (keyword) ? '' : ' in Australia';
            var _createdTitle = keyword + location;

            if(_createdTitle == 'Search') {
                return '';
            }

            return _createdTitle;
        }


    /**
     * @memberof serviceConfigManager
     * @method displayItemsForChangedSortType
     * @name displayItemsForChangedSortType
     * @description
     * Applying new sort option in base config,
     * applying offset 0 to current limit not bigger then 100,
     * pulling data from server,
     * applying data in local store variable,
     * preparing list for client based on UI config & viewConfig,
     * returning list to client.
     */
        function displayItemsForChangedSortType(_option) {
            serviceListing.listingSearch(
        resultsData,
        null,
        serviceConfig.getNewSortConfig(),
        apiConfig.getHeaderVersion()
      ).then(function() {
          if (angular.isDefined(sponsoredData) && sponsoredData !== null) {
              resultsData.listing.unshift(sponsoredData);
              removeDuplicate();
          }
          displayItemsForCurrentViewType();
      });
        }

        function setCurrentViewType(_viewType) {
            serviceConfig.setCurrentViewType(_viewType);
            displayItemsForCurrentViewType(true);
        }


    /**
     * @memberof serviceConfigManager
     * @method displayNextResultsBlock
     * @name displayNextResultsBlock
     * @description
     * First checking for current results list size,
     * if list size is last block of 10 calculated by loaded * 10 - 10 = currentlist size,
     * pulling new block of 20 results,
     * applying data in local store variable and
     * preparing list...
     */
        function displayNextResultsBlock(_callback) {
            serviceConfig.increaseLoadMoreCounter();

            if (serviceConfig.fetchMoreStatus(resultsData.listing.length)) {
                serviceListing.searchListingNextBlock(resultsData, serviceConfig.getNextBlockConfig(), apiConfig.getHeaderVersion()).then(angular.bind(this, function(_cb) {
                    removeDuplicate();
                    displayItemsForCurrentViewType();
                    _cb();
                }, _callback));
            } else {
                displayItemsForCurrentViewType();
                _callback();
            }
        }


    /**
     * @memberof serviceConfigManager
     * @method displayItemsForCurrentViewType
     * @name displayItemsForCurrentViewType
     * @description
     * Updating list of items with is_in_scope boolean flag
     * based on UI config & viewConfig.
     */
        function displayItemsForCurrentViewType(_forceLimit) {
            if (!resultsData.listing || !resultsData.listing.length) {
                return;
            }

            var _preCheckForSize = (serviceConfig.getNumberOfViewableItems() > resultsData.listing.length)
        ? resultsData.listing.length
        : serviceConfig.getNumberOfViewableItems();

            for (var i = 0, l = _preCheckForSize; i < l; i++) {
                resultsData.listing[i].is_in_scope = true;
            }

            for (var m = _preCheckForSize, ml = resultsData.listing.length; m < ml; m++) {
                resultsData.listing[m].is_in_scope = false;
            }
        }

        function hideLoadMoreButton() {
            var _currentlyShowing = serviceConfig.getLoadMoreCount() * serviceConfig.getCurrentViewLimit();
            var _thereAreMoreResults = false;
            if (resultsData) {
                _thereAreMoreResults = (parseInt(resultsData.size) - _currentlyShowing > 0) ? false : true;
            }

            return _thereAreMoreResults;
        }

        function removeDuplicate() {
            if (sponsoredData) {
                var _removeRepeatedAt;
                for (var checkIndex = 1, listSize = resultsData.listing.length; checkIndex < listSize; checkIndex++) {
                    if (resultsData.listing[checkIndex].id == sponsoredData.id) {
                        _removeRepeatedAt = checkIndex;
                    }
                }
                if (angular.isDefined(_removeRepeatedAt)) {
                    resultsData.listing.splice(_removeRepeatedAt, 1);
                }
            }
        }

        function initializeBaseParams(_baseModel, _metaModel, _sponsoredModel, versionNumber, _callback) {
      // var _pathArray = $window.location.pathname.split('/');
            angular.element($window).triggerHandler('url-changed');

            var _pathArray = $stateParams.path;
            _pathArray = _pathArray.split('/');

            serviceConfig.resetAllProperty();

            var _keywordWordValue, _locationWordValue;

            if ($state.current.name == 'search') {
                serviceConfig.setProperty('type', 'keyword');
                _keywordWordValue = uriService.uriDecode(_pathArray[0]);
                _keywordWordValue = _keywordWordValue.trim();
                serviceConfig.setProperty('keyword', _keywordWordValue);
                if(_keywordWordValue == '') {
                    _callback();
                    return;
                }
                if (_pathArray[1] && _pathArray.length == 2) {
                    _locationWordValue = uriService.uriDecode(_pathArray[_pathArray.length - 1]);
                    _locationWordValue = _locationWordValue.trim();
                    serviceConfig.setProperty('location', _locationWordValue);
                } else if(_pathArray.length > 2) {
                    var _findParametersUrlIndexed1 = ['', 'state', 'region', 'suburb'];

                    for (var pathnameIndex1 = 1; pathnameIndex1 < _pathArray.length; pathnameIndex1++) {
                        var _paramKey1 = _findParametersUrlIndexed1[pathnameIndex1];
                        serviceConfig.setProperty(_paramKey1, uriService.uriDecode(_pathArray[pathnameIndex1]));
                    }
                }
            } else if ($state.current.name == 'search-location') {
                serviceConfig.setProperty('type', 'location');
                if(_pathArray.length == 1) {
                    serviceConfig.setProperty('location', uriService.uriDecode(_pathArray[_pathArray.length - 1]));
                } else if(_pathArray.length > 2) {
                    serviceConfig.setProperty('location', uriService.uriDecode(_pathArray[_pathArray.length - 1]));
                    var _findParametersUrlIndexed2 = ['state', 'region', 'suburb'];

                    for (var pathnameIndex2 = 0; pathnameIndex2 < _pathArray.length; pathnameIndex2++) {
                        var _paramKey2 = _findParametersUrlIndexed2[pathnameIndex2];
                        serviceConfig.setProperty(_paramKey2, uriService.uriDecode(_pathArray[pathnameIndex2]));
                    }
                }
            } else if ($state.current.name == 'find') {
                serviceConfig.setProperty('type', 'browse');
                var _findParametersUrlIndexed3 = ['category', 'state', 'region', 'suburb'];

                for (var pathnameIndex3 = 0; pathnameIndex3 < _pathArray.length; pathnameIndex3++) {
                    var _paramKey3 = _findParametersUrlIndexed3[pathnameIndex3];
                    serviceConfig.setProperty(_paramKey3, uriService.uriDecodeFind(_pathArray[pathnameIndex3]));
                }
            } else if ($state.current.name == 'service') {
                serviceConfig.setProperty('type', 'keyword');
                _keywordWordValue = uriService.uriDecodeFind(_pathArray[0]);
                _keywordWordValue = _keywordWordValue.trim();
                serviceConfig.setProperty('keyword', _keywordWordValue);
                if(_keywordWordValue == '') {
                    _callback();
                    return;
                }
                if (_pathArray[1] && _pathArray.length == 2) {
                    _locationWordValue = uriService.uriDecodeFind(_pathArray[_pathArray.length - 1]);
                    _locationWordValue = _locationWordValue.trim();
                    serviceConfig.setProperty('location', _locationWordValue);
                } else if(_pathArray.length > 2) {
                    var _findParametersUrlIndexed4 = ['', 'state', 'region', 'suburb'];

                    for (var pathnameIndex4 = 1; pathnameIndex4 < _pathArray.length; pathnameIndex4++) {
                        var _paramKey4 = _findParametersUrlIndexed4[pathnameIndex4];
                        serviceConfig.setProperty(_paramKey4, uriService.uriDecodeFind(_pathArray[pathnameIndex4]));
                    }
                }
            }

            serviceListing.listingSearch(_baseModel, _metaModel, serviceConfig.getConfiguration(true), versionNumber).then(angular.bind(this, function(_cb) {
                resultsData = _baseModel;

                if (angular.isDefined(resultsData.inventories) &&
          angular.isDefined(resultsData.inventories.length) &&
          resultsData.inventories.length) {
                    for (var iInv = 0, lInv = resultsData.inventories.length; iInv < lInv; iInv++) {
                        if (resultsData.inventories[iInv].type == 'TOL' &&
              resultsData.inventories[iInv].size > 0) {
                            for (var indInv = 0, levInv = resultsData.inventories[iInv].inventory.length; indInv < levInv; indInv++) {
                                if (resultsData.inventories[iInv].inventory[indInv].listing) {
                                    sponsoredData = resultsData.inventories[iInv].inventory[indInv].listing;
                                    sponsoredData.sponsored = true;
                                    _sponsoredModel = sponsoredData;
                                    resultsData.listing.unshift(sponsoredData);
                                }
                            }
                        } else if(!sponsoredData) {
                            sponsoredData = null;
                        }
                    }
                    removeDuplicate();
                } else {
                    sponsoredData = null;
                }

                displayItemsForCurrentViewType();
                _cb();
            }, _callback));
        }

        return service;
    }
})();
