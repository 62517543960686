(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc service
     * @name newsletterService
     * @description newsletterService provides methods that handles logic for posting newsletter subscriptions
     */
    .service('newsletterService', newsletterService);

  /** @ngInject */
    function newsletterService($http, apiConfig) {
        var newsletterService = {
            postNewsletterSubscriptions: postNewsletterSubscriptions,
        };

        /**
        * @name postNewsletterSubscriptions
        * @description posts newsletter subscriptions
        */
        function postNewsletterSubscriptions(subscribeData) {
            return $http.post(apiConfig.generate('news-letter/subscribe'), subscribeData, {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
            .then(newsletterSubscribeSuccess)
            .catch(newsletterSubscribeFail);

            function newsletterSubscribeSuccess(response) {
                return response;
            }

            function newsletterSubscribeFail(error) {
                return error;
            }
        }

        return newsletterService;
    }
})();
