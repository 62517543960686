(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('serviceItems', serviceItems);

    /** @ngInject */
    function serviceItems() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/servicepage/serviceitems/serviceitems.html',
            scope: {
                searchData: '=',
                gridClass: '=',
                viewOnPage: '@',
                onMap: '&',
                showMarkerId: '&',
                expandedSearch: '=',
                expandedSearchMessage: '=',
            },
            controller: ServiceItemsController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function ServiceItemsController($location, $scope, env, platformService, $compile, $timeout, $q, $log, DTM) {
            var vm = this;

            var _baseurl = $location.protocol() + '://' + $location.host();
            if ($location.port() != '80' || $location.port() != '') {
                _baseurl = _baseurl + ':' + $location.port();
            }
            if (platformService.isMobile() == true) {
                vm.sloganHeightLimit = 50;
            } else {
                vm.sloganHeightLimit = 25;
            }
            vm.categoryNames = {};

            vm.getCategory = function(listing, index) {
                if (angular.isDefined(vm.categoryNames[listing.id])) {
                    return vm.categoryNames[listing.id];
                } else if (angular.isUndefined(listing.categories)) {
                    return '';
                } else if (angular.isUndefined(listing.categories.category)) {
                    return '';
                } else if (angular.isUndefined(listing.categories.category[0])) {
                    return '';
                } else if (angular.isUndefined(listing.categories.category[0].name)) {
                    return '';
                } else if(angular.isUndefined(listing.name)) {
                    return '';
                }else {
                    vm.categoryNames[listing.id] = listing.categories.category[0].name;
                    return vm.categoryNames[listing.id];
                }
            };

            vm.havePhoneNumber = [];

            vm.getSuburb = function(addresses) {
                if (angular.isUndefined(addresses)) return '';
                else if (angular.isUndefined(addresses)) return '';
                else if (angular.isUndefined(addresses.address)) return '';
                else if (angular.isUndefined(addresses.address[0])) return '';
                else if (angular.isUndefined(addresses.address[0].suburb)) return '';
                else return addresses.address[0].suburb;
            };

            vm.hasPhoneIsMobile = function(listing, $index) {
                if (vm.getHavePhoneNumber(listing, $index)) {
                    if (platformService.isMobile()) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            };

            vm.heightLimit = function(height) {
                if (angular.isDefined(height)) {
                    return height;
                }
                return 30;
            };

            vm.getHavePhoneNumber = function(listing, index) {
                if (angular.isUndefined(vm.havePhoneNumber[listing.id])) {
                    if (angular.isUndefined(listing)) vm.havePhoneNumber[listing.id] = false;
                    else if (angular.isUndefined(listing.contacts)) vm.havePhoneNumber[listing.id] = false;
                    else if (angular.isUndefined(listing.contacts.contact)) vm.havePhoneNumber[listing.id] = false;
                    else if (listing.contacts.contact.length == 0) vm.havePhoneNumber[listing.id] = false;
                    else {
                        var found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (angular.isDefined(contact.type)) {
                                if (contact.type == 'phone' || contact.type == 'national' || contact.type == 'mobile') {
                                    found = 1;
                                }
                            }
                        });

                        if (found == 0) {
                            vm.havePhoneNumber[listing.id] = false;
                        } else {
                            vm.havePhoneNumber[listing.id] = true;
                        }
                    }
                }
                return vm.havePhoneNumber[listing.id];
            };

            vm.getPhoneNumber = function(listing, index) {
                if (vm.getHavePhoneNumber(listing, index) == true) {
                    var _phoneNumber = null;
                    // check first for mobile
                    var found = 0;
                    angular.forEach(listing.contacts.contact, function(contact) {
                        if (contact.type == 'mobile' && found == 0) {
                            found = 1;
                            _phoneNumber = contact.value;
                        }
                    });

                    if (_phoneNumber == null) {
                        found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (contact.type == 'phone' && found == 0) {
                                found = 1;
                                _phoneNumber = contact.value;
                            }
                        });
                    }

                    if (_phoneNumber == null) {
                        found = 0;
                        angular.forEach(listing.contacts.contact, function(contact) {
                            if (contact.type == 'national' && found == 0) {
                                found = 1;
                                _phoneNumber = contact.value;
                            }
                        });
                    }

                    return _phoneNumber;
                }
            };

            vm.getPhoneNumberFormated = function(listing, index) {
                var _phoneNumber = vm.getPhoneNumber(listing, index);
                return _formatPhone(_phoneNumber);
            };

            var _formatPhone = function(_phoneNumber) {
                var _formattedPhoneNumber = '';
                if (typeof _phoneNumber == 'undefined' || !_phoneNumber) {
                    return '';
                }
                if (_phoneNumber.length == 10) {
                    if (_phoneNumber.startsWith('04') || _phoneNumber.startsWith('1300') ||
                        _phoneNumber.startsWith('1800') || _phoneNumber.startsWith('1900')) {
                        _formattedPhoneNumber += _phoneNumber.substr(0, 4) + ' ' + _phoneNumber.substr(4, 3) + ' ' + _phoneNumber.substr(7, 3);
                    } else {
                        _formattedPhoneNumber += '(' + _phoneNumber.substr(0, 2) + ') ';
                        _formattedPhoneNumber += _phoneNumber.substr(2, 4) + ' ' + _phoneNumber.substr(6);
                    }
                } else if (_phoneNumber.length == 6) {
                    _formattedPhoneNumber += _phoneNumber.substr(0, 2) + ' ' + _phoneNumber.substr(2, 2) + ' ' + _phoneNumber.substr(4, 2);
                } else {
                    _formattedPhoneNumber = _phoneNumber;
                }
                return _formattedPhoneNumber;
            };

            vm.getListingUrl = function(listing) {
                return _baseurl + env.searchsettings.businessUrl + '/' + listing.seoUrl;
            };

            vm.hasImage = {};
            vm.getHasImage = function(listing, index) {
                if (typeof vm.hasImage[listing.id] == 'undefined') {
                    if (typeof listing == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage.urls == 'undefined') vm.hasImage[listing.id] = false;
                    else if (typeof listing.featureImage.urls.small == 'undefined') vm.hasImage[listing.id] = false;
                    else vm.hasImage[listing.id] = true;
                }
                if (vm.hasImage[listing.id] == true) {
                    imageInServer(listing.featureImage.urls.small).then(function(test) {
                        vm.hasImage[listing.id] = test;
                    });
                }
                return vm.hasImage[listing.id];
            };

            function imageInServer(src) {
                var deferred = $q.defer();
                var image = new Image();
                image.onerror = function() {
                    deferred.resolve(false);
                };
                image.onload = function() {
                    deferred.resolve(true);
                };
                image.src = src;
                return deferred.promise;
            }

            vm.getImage = function(listing, index) {
                if (angular.isDefined(listing.name) && vm.getHasImage(listing, index) == true) {
                    return {
                        'background-image': 'url(' + listing.featureImage.urls.small + ')',
                        'background-size': 'cover',
                    };
                }

                return {};
            };

            vm.dayWorkingHours = function(day) {
                var status = [];
                status[0] = constantsPlaceholders.openingHours.closed;
                if (angular.isUndefined(vm.openingHours)) {
                    status[0] = constantsPlaceholders.openingHours.closed;
                    return status[0];
                    // return 'Closed';
                } else if (vm.openingHours === '-') {
                    status[0] = constantsPlaceholders.openingHours.closed;
                    return status[0];
                    // return 'Closed';
                } else {
                    angular.forEach(vm.openingHours, function(objectDay) {
                        if (objectDay.day === day) {
                            if (objectDay.hasOwnProperty('24Hours') && objectDay['24Hours']) {
                                status[0] = '12:00 am - 11:59 pm';
                            } else {
                                status[0] = objectDay.hours[0].open.toLowerCase() + ' - ' + objectDay.hours[0].close.toLowerCase();
                                if (objectDay.hours.length > 1) {
                                    if (objectDay.day == vm.getNowDay()) {
                                        vm.doubleShifts = true; // have two pair of working hours
                                    }
                                    status[1] = objectDay.hours[1].open.toLowerCase() + ' - ' + objectDay.hours[1].close.toLowerCase();
                                }
                            }
                        }
                    });
                    return status;
                }
            };

            vm.getLastClass = function(index, last) {
                if (last == true && (index + 1) % 3 == 2) {
                    return 'last-search-item';
                }
            };

            vm.haveCoords = {};
            vm.coords = {};
            vm.getHaveCoords = function(listing, index) {
                if (typeof vm.haveCoords[listing.id] == 'undefined') {
                    if (typeof listing.addresses == 'undefined') vm.haveCoords[listing.id] = false;
                    else if (typeof listing.addresses.address == 'undefined') vm.haveCoords[listing.id] = false;
                    else if (typeof listing.addresses.address[0] == 'undefined') vm.haveCoords[listing.id] = false;
                    else if (typeof listing.addresses.address[0].latitude == 'undefined' || typeof listing.addresses.address[0].longitude == 'undefined') vm.haveCoords[listing.id] = false;
                    else {
                        var founded = false;
                        angular.forEach(vm.coords, function(coord) {
                            if (coord.latitude == listing.addresses.address[0].latitude && coord.logitude == listing.addresses.address[0].logitude) {
                                founded = true;
                            }
                        });
                        if (founded == false) vm.haveCoords[listing.id] = true;
                        else {
                            coords.push({
                                latitude: listing.addresses.address[0].latitude,
                                longitude: listing.addresses.address[0].logitude,
                            });
                            vm.haveCoords[listing.id] = false;
                        }
                    }
                }

                return vm.haveCoords[listing.id];
            };

            vm.showOnMap = function(listing) {
                vm.viewOnPage = 'map';
                /* $cookies.put('tl_srcp_vop','map', {path:'/'});
                $cookies.remove('tl_srcp_grdc');*/
            };

            vm.onMapSelect = function(id) {
                vm.showMarkerId = id;
                return vm.onMap({
                    message: id,
                });
            };

            vm.tiles = [];
            vm.tilesLimit = 6;

            vm.getTiles = function(inventories) {
                if (typeof inventories != 'undefined') {
                    for (var i = 0; i < inventories.length; i++) {
                        if (inventories[i].type == 'TILES') {
                            return inventories[i].inventory;
                        }
                    }
                } else {
                    return [];
                }
            };

            vm.hasSlogan = function(listing) {
                if (typeof listing == 'undefined') {
                    return false;
                }
                if (listing.paid == true) {
                    if (typeof listing.slogan != 'undefined') {
                        if (listing.slogan.length > 0) return true;
                        else return false;
                    } else if (typeof listing.description != 'undefined') {
                        if (listing.description.length > 0) return true;
                        else return false;
                    } else {
                        return false;
                    }
                } else {
                    if (typeof listing.description != 'undefined') {
                        if (listing.description.length > 0) return true;
                        else return false;
                    } else {
                        return false;
                    }
                }
                return false;
            };

            vm.getSlogan = function(listing) {
                if (listing.paid == true) {
                    if (typeof listing.slogan != 'undefined') {
                        return '"' + listing.slogan + '"';
                    } else {
                        return '"' + listing.description + '"';
                    }
                } else {
                    return '"' + listing.description + '"';
                }
            };

            vm.getButtonsHelperClass = function(listing, index) {
                if (typeof listing == 'undefined') {
                    return 'col-sm-4';
                } else {
                    if (vm.getHaveCoords(listing, index) == false) {
                        return 'col-sm-6';
                    } else {
                        return 'col-sm-4';
                    }
                }
            };

            $scope.$watch(
                'vm.searchData.inventories',
                function handleFooChange(newValue, oldValue) {
                    vm.tiles = vm.getTiles(vm.searchData.inventories);
                }
            );

            vm.getClassById = function(index) {
                var curClass = '';
                if (typeof index != 'undefined') {
                    if ((index + 2) % 3 == 0) curClass += ' middle';
                    if (index % 2 != 0) curClass += ' even';
                    if (index % 2 == 0) curClass += ' odd';
                }
                return curClass;
            };

            vm.showSlogan = function(listing) {
                if (vm.gridClass == '') {
                    return vm.hasSlogan(listing);
                } else {
                    return false;
                }
            };

            vm.limitWidth = function(str) {
                if(!angular.isUndefined(str)) {
                    var selector = /[\w\d]{14,}/g,
                        limit = 12,
                        d = str.replace(selector, function(str) {
                            return str.substring(0, limit) + '...<br>';
                        });
                    return d;
                }
                return '';
            };

            vm.makeHeight = function(height) {
                if (angular.isDefined(height)) {
                    return height;
                }
                return 40;
            };

            vm.appendTiles = function(index) {
                if (angular.isUndefined(vm.tiles) || (angular.isDefined(vm.tiles) && !vm.tiles.length)) return;
                index += (index / 11) - 6;
                angular.element(document.querySelector('.search-item:nth-child(' + index + ')')).after($compile('<search-tile tiles="vm.tiles" index="index"></search-tile>')($scope));
            };

            this.getExpandedSearchMessage = function() {
                if (angular.isUndefined(this.expandedSearchMessage) || !this.expandedSearchMessage) {
                    return 'We\'ve expanded your search';
                }
                return this.expandedSearchMessage;
            };

            vm.titleTruncationHeight = calcTitleTruncationHeight();

            function calcTitleTruncationHeight() {
                if (angular.element(window)[0].outerWidth < 768) {
                    $log.debug('smaller than 768');
                    return 50;
                } else {
                    $log.debug('wider than 768');
                    return 70;
                }
            }

            angular.element(window).bind('resize', function() {
                vm.titleTruncationHeight = calcTitleTruncationHeight();
            });

            vm.trackMePlease = function(name, url) {
                DTM.trackAnalyticOnClick(name);
                window.location.href = url;
            };
        }
    }
})();
