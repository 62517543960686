(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name mainFooter
     * @description Display main page footer. It consist of truelocal logo, copyright and site index.
     *
     * @params {constant} footerUrls  Constant with footer urls
     *
     * @example
     * <main-footer ></main-footer>
     */
    .directive('mainFooter', mainFooter);

    /** @ngInject */
    function mainFooter() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/mainFooter/mainfooter.html',
            scope: {
                creationDate: '=',
            },
            controller: MainFooterController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function MainFooterController(apiConfig, footerUrls, footerFeatured, DTM, $modal, windowScroll, $window, env, $document) {
            var vm = this;
            vm.footerUrls = footerUrls;
            vm.footerFeatured = footerFeatured;
            vm.runningFeature = footerFeatured.active.featured;
            vm.featureIsActive = footerFeatured.active.running;
            vm.addBussLive = env.addBussLive;
            vm.showHomeSiteIndex = false;

            vm.navigateTo = function(route) {
                $window.location.href = route;
            };


            /**
             * @memberof mainFooter
             * @method authenticated
             * @name authenticated
             * @description Is user authenticated.
             * @returns {Boolean} authenticated
             */
            vm.authenticated = function() {
                vm.userData = apiConfig.userData;
                if (angular.isUndefined(vm.userData)) {
                    return false;
                } else if (vm.userData == '') {
                    return false;
                } else {
                    return true;
                }
            };

            /**
             * @memberof mainFooter
             * @method swipeLeft
             * @name swipeLeft
             * @description swipe left action
             */
            vm.swipeLeft = function() {
                var elem = angular.element(document.querySelector('#paging_footer .active'));
                var page = elem.attr('tl-paging-footer');
                if (page == 1) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="2"]')).triggerHandler('click');
                } else if (page == 2) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="3"]')).triggerHandler('click');
                } else if (page == 3) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="1"]')).triggerHandler('click');
                }
            };

            /**
             * @memberof mainFooter
             * @method swipeRight
             * @name swipeRight
             * @description swipe right action
             */
            vm.swipeRight = function() {
                var elem = angular.element(document.querySelector('#paging_footer .active'))
                var page = elem.attr('tl-paging-footer');
                if (page == 1) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="3"]')).triggerHandler('click');
                } else if (page == 2) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="1"]')).triggerHandler('click');
                } else if (page == 3) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="2"]')).triggerHandler('click');
                }
            };

            vm.collapsed = true;

            /**
             * @memberof mainFooter
             * @method siteIndex
             * @name siteIndex
             * @description mobile collapse site index
             */
            vm.siteIndex = function() {
                if (vm.collapsed == true) {
                    angular.element(document.querySelector('#collapseFooter')).addClass('in');
                    /*angular.element(document.querySelector('body,html'))[0].animate({
                        scrollTop: angular.element(document.querySelector('#collapseFooter')).prop('offsetTop') - 208,
                    }, 'fast');*/
                    $document.scrollTop(angular.element(document.querySelector('#collapseFooter')).prop('offsetTop') - 208, 1000);
                    vm.collapsed = false;
                    return vm.collapsed;
                } else {
                    angular.element(document.querySelector('#collapseFooter')).removeClass('in');
                    vm.collapsed = true;
                    return vm.collapsed;
                }
            };

            vm.toggleHomeSiteIndex = function() {
                vm.showHomeSiteIndex = !vm.showHomeSiteIndex;

                var footerLogo = document.querySelector('.footer-logo');

                if (footerLogo) {
                    $document.scrollTop(angular.element(footerLogo).prop('offsetTop') - 208, 1000);
                }
            };

            /**
             * @memberof mainFooter
             * @method getSupportUrl
             * @name getSupportUrl
             * @description returns support url dependent on authenticated status
             */
            vm.getSupportUrl = function(event) {
            	if (vm.authenticated()) {
                var firstName = vm.userData.data.data.firstName;
                var lastName = vm.userData.data.data.lastName;
                var email = vm.userData.data.data.email;
         	      event.target.href = vm.footerUrls.support.url + '&firstName=' + firstName + '&lastName=' + lastName + '&email=' + email;
            	}
            	return true;
            };

            /**
             * @memberof mainFooter
             * @method getHelperClass
             * @name getHelperClass
             * @description get specific class if vm.collapsed == true
             */
            vm.getHelperClass = function() {
                if (vm.collapsed == true) {
                    return '';
                } else {
                    return 'rotated-icon';
                }
            };

            /**
             * @memberof mainFooter
             * @method featuredLinks
             * @name featuredLinks
             * @description get what faetured
             */
            vm.featuredLinks = function(featured) {
                return vm.runningFeature === featured;
            };
            /**
             * @memberof mainFooter
             * @method itIsHome
             * @name itIsHome
             * @description itIsHome
             */
            vm.isHome = function() {
                var root = window.location.origin, url = window.location.href;
                return root + '/' === url || root === url;
            };

            vm.trackOpenReviews = function() {
                DTM.trackAnalyticOnClick('ratings and reviews');
            };

            vm.newsletterModal = function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/shared/modalNewsletter/modalnewsletter.html',
                    controller: 'newsletterController',
                    controllerAs: 'vm',
                    size: 'dialog_size_middle'
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });

                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });

                DTM.trackAnalyticOnClick('newsletter modal opened');
            }
        }
    }
})();
