(function() {
    'use strict';

    angular
        .module('truelocal')
        .filter('trustUrl', ['$sce', function($sce) {
            return function(recordingUrl) {
                return $sce.trustAsResourceUrl(recordingUrl);
            };
        }]);
})();

