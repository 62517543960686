(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('serviceHeader', serviceHeader);

    /** @ngInject */
    function serviceHeader(apiConfig) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/servicepage/serviceitems/header.html',
            scope: {
                searchPath: '=',
            },
            controller: serviceHeadingController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** controller */
        function serviceHeadingController() {
            var vm = this;
            vm.getServiceTitle = function() {
                var title = vm.searchPath[2];
                if (angular.isUndefined(title)) {
                    title = 'cleaning';
                }
                title = title.replace(/-/g, ' ');
                return title;
            };

            vm.getServiceLocation = function() {
                var location = vm.searchPath[3];
                if (angular.isUndefined(location)) {
                    location = 'Australia';
                }
                location = location.replace(/-/g, ' ');
                return location;
            };
        }
    }
})();
