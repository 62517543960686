(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bsLoadMore
     * @description Directive offer user option to load more results if available.
     *
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {service}   searchConfigManager       Service for building and handlig parameters for search
     *
     * @example
     * <bs-load-more search-listing="[object]"></bs-load-more>
     *
     */
    .directive('bsLoadMore', bsLoadMore);

  /** @ngInject */
    function bsLoadMore() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/bsloadmore/bsloadmore.html',
            scope: {
                searchListing: '=',
            },
            controller: BsLoadMoreController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BsLoadMoreController($timeout, searchConfigManager, DTM, $location, $document, $state) {
            var vm = this;

            vm.loadingMore = false;

      /**
       * @memberof bsLoadMore
       * @method checkResultsOffset
       * @name checkResultsOffset
       * @description Checks if there are more results to be loaded
       * @returns {Boolean} TRUE if there are no more results so hide the button. Otherwise, FALSE.
       */
            vm.checkResultsOffset = function() {
                if (angular.isDefined(vm.searchListing) && angular.isDefined(vm.searchListing.size) && vm.searchListing.size) {
                    return searchConfigManager.hideLoadMoreButton();
                }
                return true;
            };

      /**
       * @memberof bsLoadMore
       * @method loadMoreResults
       * @name loadMoreResults
       * @description If loading is not in progress, load more results.
       */
            vm.loadMoreResults = function() {
                if (vm.loadingMore) {
                    return;
                }

                vm.loadingMore = true;

                $timeout(function() {
                    searchConfigManager.displayNextResultsBlock(function(itemsPerPage) {
                        vm.loadingMore = false;
                        itemsPerPage = (angular.isUndefined(itemsPerPage)) ? 12 : itemsPerPage;

                        //Scroll to the first result of new page
                        var breadcrumbsWrapperHeight = angular.isDefined(angular.element(document.querySelector('breadcrumbsWrapper'))[0]) ?
                            angular.element(document.querySelector('breadcrumbsWrapper'))[0].clientHeight : 0;
                        var firstResultNextPage = (angular.isDefined(vm.searchListing.listing[vm.searchListing.listing.length - (itemsPerPage)])) ?
                                                  angular.element(document.querySelector('#list-item-' +
                                                      vm.searchListing.listing[vm.searchListing.listing.length - (itemsPerPage)].id)) : 0;
                        /*angular.element(document.querySelector("body"))[0].animate({scrollTop: firstResultNextPage.prop('offsetTop')-
                                                        (angular.element(document.querySelector('header'))[0].clientHeight + 70 +
                                                         angular.element(document.querySelector('breadcrumbsWrapper'))[0].clientHeight) }, "slow");*/
                        $document.scrollTop(firstResultNextPage.prop('offsetTop')-
                            (angular.element(document.querySelector('header'))[0].clientHeight + 70 +
                                breadcrumbsWrapperHeight), 1000);
                        vm.hideLoadMore = vm.checkResultsOffset();
                    });
                    if (DTM.hasOneTrustScript()) {
                        DTM.fireEventAtPageLoad($state.current.name);
                    } else {
                        DTM.trackPage();
                    }
                    DTM.trackAnalyticOnClick('load more results');
                });
            };

            vm.hideLoadMore = vm.checkResultsOffset();


      /**
       * @memberof bsLoadMore
       * @method noResults
       * @name noResults
       * @description Returns true if no more results.
       * @returns {Boolean} noResults
       */
            vm.noResults = function() {
                if (angular.isDefined(vm.searchListing) && angular.isDefined(vm.searchListing.size) && vm.searchListing.size) {
                    return false;
                }
                return true;
            };
        }
    }
})();
