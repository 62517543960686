(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name janrainSocialLogin
     * @description Directive integrate janrain social login plugin that enable users to login trough social
     * network account. After user clicks link to open plugin, new janrain window opens asking user to enter social
     * network credentials. Directive listen for events regarding janrain authentication progress, and in case
     * of bad respond to social network, reset itself to default view:
     *
     *     user click on social login btn (google)
     *          new janrain window appear asking user to enter login info
     *          on login modal social login buttons hides and message "Signing in ..." appear
     *
     *     user successfully authenticated
     *          janrain window disappear
     *          modal login disappear, and user is logged in
     *
     *     user auth fail (bad user data etc.)
     *          janrain window handle error
     *          modal login doesn't change, "Signing in ..." remain on page
     *
     *     user close janrain window
     *          janrain window disappear
     *          modal login returning to default state displaying social buttons
     *
     * @param {service}   $window                   Angular window wrapper
     * @param {service}   $http                     angular http service
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {constant}  env                       Environmental constants
     *
     * @example
     *
     * <janrain-social-login token-url="{string}" social-register="{boolean}" social-register-data="{object}" application-id="{string}"></janrain-social-login>
     */
    .directive('janrainSocialLogin', ['$window', '$http', 'apiConfig', 'env', '$document', function($window, $http, apiConfig, env, $document) {
        return {
            restrict: 'E',
            scope: {
                tokenUrl: '=',
                applicationId: '=',
                socialRegister: '=',
                socialRegisterData: '=',
            },
            template: '<div id="janrainEngageEmbed"></div>',
            link: function(scope, element) {
                if (typeof $window.janrain !== 'object') {
                    $window.janrain = {};
                }
                if (typeof $window.janrain.settings !== 'object') {
                    $window.janrain.settings = {};
                }

                $window.janrain.settings.tokenUrl = scope.tokenUrl;
                $window.janrain.settings.tokenAction = 'event';
          // $window.janrain.settings.

                $window.janrain.ready = true;

                var scriptElement = document.createElement('script');
                scriptElement.type = 'text/javascript';
                scriptElement.id = 'janrainAuthWidget';
                if (document.location.protocol === 'https:') {
                    scriptElement.src = env.socialAuth.securePath;
                } else {
                    scriptElement.src = env.socialAuth.path;
                }
                element.prepend(scriptElement);

                if ($window.janrain) {
            /**
             * @memberof janrainSocialLogin
             * @event janrainWidgetOnload
             * @name janrainWidgetOnload
             * @description Listener function for janrainWidgetOnload.
             */
                    $window.janrainWidgetOnload = function() {
              /**
               * @memberof janrainSocialLogin
               * @event janrainOnProviderLoginStart
               * @name janrainOnProviderLoginStart
               * @description Listener function for onProviderLoginStart.
               */
                        $window.janrain.events.onProviderLoginStart.addHandler(function(response) {
                            document.getElementById('janrainEngageEmbed').innerHTML = 'Signing in ...';
                        });


              /**
               * @memberof janrainSocialLogin
               * @event janrainOnProviderLoginComplete
               * @name janrainOnProviderLoginComplete
               * @description Listener function for onProviderLoginComplete.
               */
                        $window.janrain.events.onProviderLoginComplete.addHandler(function(response) {
                            document.getElementById('janrainEngageEmbed').innerHTML = '';
                            $document.scrollTop(0);
                        });


              /**
               * @memberof janrainSocialLogin
               * @event janrainOnProviderLoginToken
               * @name janrainOnProviderLoginToken
               * @description Listener function for onProviderLoginToken.
               */
                        $window.janrain.events.onProviderLoginToken.addHandler(function(response) {
                            return $http.post(apiConfig.generate('auth', 'social') + '&token=' + response.token, apiConfig.getXAuthToken())
                  .then(getSocialLoginSuccess)
                  .catch(getSocialLoginFailed);

                            function getSocialLoginSuccess(response) {
                                if (angular.isDefined(response.data.data.actions)) {
                    // here we handle any fail from the social login
                    // can be the case when actualy user is not yet registered with TL
                                    scope.socialRegister = true;
                                    scope.socialRegisterData = response.data.data;

                                    return scope;
                                } else {
		    apiConfig.setToken(response.data.data.passToken);
                                    apiConfig.updateCredentials(response.headers('x-auth-token'));
                                }
                            }

                            function getSocialLoginFailed(error) {

                            }
                        });
                    };
                }
                if ($window.janrain.engage) {
                    $window.janrain.engage.signin.widget.init();
                }
            },
        };
    }]);
})();

