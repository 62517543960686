(function () {
  'use strict';

  angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name modalFactory
     *
     * @description modalFactory service provide methods for building custom modals.
     * NOTE: the factory is shared also as controller for the modal-login directive
     *
     * @param  {service}  $http                     angular http service
     * @param {service}   $log                      Angular console log wrapper
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {constant}  env                       Environmental constants
     * @param {service}   $location                  Angular window.location service wrapper
     * @param {service}   $rootScope                 Angular app root scope
     *
     *
     */
    .factory('modalFactory', ModalFactory)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name modalsignup
     * @description Directive holds user login modal.
     * NOTE: the directive controller is shared also as factory
     *
     * @param  {service}  $http                     angular http service
     * @param {service}   $log                      Angular console log wrapper
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {constant}  env                       Environmental constants
     * @param {service}   $location                  Angular window.location service wrapper
     * @param {service}   $rootScope                 Angular app root scope
     *
     */
    .directive('modalSignup', ModalSignup)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name equals
     * @description Attribute directive equals enable form element validation so that element can have exact
     * provided value.
     */
    .directive('equals', function () {
      return {
        restrict: 'A', // only activate on element attribute
        require: '?ngModel', // get a hold of NgModelController
        link: function (scope, elem, attrs, ngModel) {
          if (!ngModel) return; // do nothing if no ng-model

          // watch own value and re-validate on change
          scope.$watch(attrs.ngModel, function () {
            validate();
          });

          // observe the other value and re-validate on change
          attrs.$observe('equals', function (val) {
            validate();
          });

          var validate = function () {
            // values
            var val1 = ngModel.$viewValue;
            var val2 = attrs.equals;

            // set validity
            ngModel.$setValidity('equals', !val1 || !val2 || val1 === val2);
          };
        }
      }
    });

  /** @ngInject */
  function ModalFactory($http, $log, authService, apiConfig, env, $location, $rootScope, $timeout, $cookies, $localstorage, $window) {


    function signUpController(modalInstance, storedData) {
      var vm = this;
      vm.initedJainRain = false;
      vm.email = '';
      vm.password = '';
      vm.signingIn = false;
      vm.recovering = false;
      vm.remember = false;
      vm.errorMessages = "";
      vm.errorMessagesRecover = "";
      vm.recover = false;
      vm.recovered = false;
      vm.signupWithEmail = false;

      vm.abandonSignup = false;
      vm.validationConfig = {
        displayName: {
          min_length: 2,
          max_length: 20,
          required: true
        },
        password: {
          min_length: 6,
          required: true
        },
        confirmPassword: {
          min_length: 6,
          required: true
        }
      };



      vm.signinup = false;
      vm.socialRegister = false;
      vm.socialRegisterData = [];
      vm.displayNameError = false;
      vm.passwordFieldError = false;

      /**
       * @memberof modalsignup
       * @name signIn
       * @method signIn
       * @description execute sign in
       */

      vm.signIn = function (loginform) {
        var _userCredentials;
        if (vm.remember == false) {
          _userCredentials = {
            email: vm.email,
            password: vm.password
          };
        } else {
          _userCredentials = {
            email: vm.email,
            password: vm.password,
            type: 'REMEMBER_ME'
          };
        }


        vm.signingIn = true;
        vm.errorMessages = "";
        authService.signUp(_userCredentials).then(function (returned) {
          if (angular.isDefined(returned)) {
            $log.debug(returned.data);
            var messages = returned.data.meta.messages;
            messages = messages.join('<br/>');
            vm.errorMessages = messages;
            vm.signingIn = false;
            vm.email = '';
            vm.password = '';
            loginform.$setUntouched();
          }
        });
      };





      /**
       * @memberof modalSignup
       * @name trackSignup
       * @method trackSignup
       * @description tracker for signup
       */
      vm.cancel = function () {
        //modalInstance.dismiss('cancel');
        vm.abandonSignup = true;
        alert("1111");
      };

      vm.cancel2 = function()
      {
        alert("ok");
      };

      /**
       * @memberof modalSignup
       * @name toSignupFirstScreen
       * @method toSignupFirstScreen
       * @description back to Sign up
       */
      vm.toSignupFirstScreen = function()
      {

      };
    }

    return signUpController;
  }

  /** @ngInject */
  function ModalSignup() {
    var directive = {
      restrict: 'E',
      templateUrl: '/app/components/modalSignup/modalsignup.html',
      scope: {
        login: '='
      },
      controller: ModalFactory,
      controllerAs: 'vm',
      bindToController: true
    };
    return directive;
  }
})();
