(function() {
    'use strict';
    angular
        .module('truelocal')
        .controller('ModalAuthPromptController', ModalAuthPromptController);
    /** @ngInject */
    function ModalAuthPromptController($modal, windowScroll, modalFactory, $modalInstance, $timeout, DTM, $window, $location, $cookies) {
        var vm = this;
        
        vm.goToLogin = function () {
            $window.open('/login' + $location.url(), '_self');
        }
        
        vm.goToSignUp = function () {
            $window.open('/signup' + $location.url(), '_self');
        }
    }
})();
