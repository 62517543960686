(function() {
    'use strict';

    angular
  .module('truelocal')

  /**
   * @memberof truelocal
   * @ngdoc service
   * @name businessService
   * @description businessService provides methods that handles logic for posting newsletter subscriptions
   */
  .service('businessService', businessService);

/** @ngInject */
    function businessService($log, $timeout, $modal, DTM, windowScroll) {
        var businessService = {
            emailABusiness: emailABusiness,
            hasContactEmail: hasContactEmail,
            getHavePrimaryWebsite: getHavePrimaryWebsite,
            getReadableUrl: getReadableUrl,
            getPrimaryWebsite: getPrimaryWebsite,
            trackWebsiteClick: trackWebsiteClick,
        };

    /**
      * @name emailABusiness
      * @param $event the event service
      * @param listing the business details
      * @description sends email to a business
      */
        function emailABusiness($event, page, listing) {
            $timeout(function() {
                $log.debug('BdpDetailsController -> open BdpEmailABusiness');
                var eventName = 'send advertiser email:start';
                if (page === 'BDP') {
                    DTM.trackAnalyticOnClick(eventName);
                } else {
                    DTM.trackSearchEvents(eventName, listing);
                }
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/bdpemailabusiness/bdpemailabusiness.html',
                    controller: 'BdpEmailABusinessController',
                    controllerAs: 'eb',
                    size: 'dialog_size_smallest',
                    resolve: {
                        listing: function() {
                            return listing;
                        },
                    },
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
            });
            $event.preventDefault();
        }

    /**
      * @name hasContactEmail
      * @param listing the business details
      * @description checks if the business has an email address
      */
        function hasContactEmail(listing) {
            return listing.contacts.contact.some(function(contact) {
                return (contact.type === 'email');
            });
        }

    /**
      * @name hasContactEmail
      * @param listing the business details
      * @description checks if the business has an email address
      */
        function getReadableUrl(url) {
            var finalUrl = url;
            if (url.indexOf('http://') == -1 && url.indexOf('https://') == -1) {
                finalUrl = 'http://' + url;
            }
            return finalUrl;
        }

    /**
      * @name trackWebsiteClick
      * @param url the business website URL
      * @param page the name of the page i.e. BDP or SRP
      * @param listing the listing details if page is SRP
      * @description tracks the business website clicks
      */
        function trackWebsiteClick(url, page, listing) {
            var finalTrackingUrl = getReadableUrl(url);
            var eventName = 'visit advertiser website';
            if (page === 'BDP') {
                DTM.trackAnalyticOnClick(eventName);
            } else {
                DTM.trackSearchEvents(eventName, listing);
            }
            window.open(finalTrackingUrl, '_blank');
        };

    /**
      * @name getHavePrimaryWebsite
      * @param listing the business details
      * @description checks if the business has a primary website
      */
        function getHavePrimaryWebsite(listing) {
            var havePrimaryWebsite = false;

            if (angular.isDefined(listing.contacts)) {
                if (angular.isDefined(listing.contacts.contact)) {
                    angular.forEach(listing.contacts.contact, function(contact) {
                        if (contact.type == 'website' && angular.isUndefined(contact.label)) {
                            havePrimaryWebsite = true;
                        }
                    });
                }
            }

            return havePrimaryWebsite;
        }

     /**
      * @name getPrimaryWebsite
      * @param listing the business details
      * @description retrieves the primary websire of the business
      */
        function getPrimaryWebsite(listing) {
            var primaryWebsite = '';
            if (angular.isDefined(listing.contacts)) {
                if (angular.isDefined(listing.contacts.contact)) {
                    angular.forEach(listing.contacts.contact, function(contact) {
                        if (contact.type == 'website' && angular.isUndefined(contact.label)) {
                            primaryWebsite = getReadableUrl(contact.value);
                        }
                    });
                }
            }
            return primaryWebsite;
        }

        return businessService;
    }
})();
