(function() {
    'use strict';

    angular
    .module('truelocal')


  /**
   * @memberof truelocal
   * @ngdoc directive
   * @name bdpTol
   * @description Directive displays business featured user review or sponsored listings, depending on available data,
   * for paid business in business details area.
   *
   * @param {service}   $window                   Angular window wrapper
   * @param {service}   $location                  Angular window.location service wrapper
   * @param {factory}   apiConfig                 Api configuration factory
   *
   * @example
   * <bdp-tol similar="[object]" listing="[object]"></bdp-tol>
   */
  .directive('bdpTol', bdpTol)


  /**
   * @memberof truelocal
   * @ngdoc directive
   * @name scrollToTopList
   * @description Directive scroll view to top list.
   *
   * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
   *
   * @example
   *
   * <a href="..." scroll-to-top-list"></a>
   */
  .directive('scrollToTopList', ['platformService', scrollToTopList]);

    function scrollToTopList(platformService, $document) {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                elem.on('click', function() {
                    var _isMobile = platformService.isMobile();
                    var _defaultScrollOffset = (_isMobile) ? 113 : 222;
                    var _currentWinScroll = window.scrollY;

                    if (_currentWinScroll <= 340) {
                        var _speedUpdate = (_currentWinScroll >= 0 && _currentWinScroll <= 150) ? '100' : '500';
                        $document.scrollTop(340, _speedUpdate).then(function(){
                          var _elementId = elem.attr('idinlist');
                          var _scrollTo = (angular.element(document.querySelector(_elementId)).prop('offsetTop') - _defaultScrollOffset);
                          /*angular.element(document.querySelector('body,html'))[0].animate({
                                scrollTop: _scrollTo,
                            }, 'slow');*/
                          $document.scrollTop(_scrollTo, 1000);
                        });
                    } else {
                        var _elementId = elem.attr('idinlist');
                        var _scrollTo = (angular.element(document.querySelector(_elementId)).prop('offsetTop') - _defaultScrollOffset);
                        /*angular.element(document.querySelector('body,html'))[0].animate({
                            scrollTop: _scrollTo,
                        }, 'slow');*/
                        $document.scrollTop(_scrollTo, 1000);
                    }
                });
            },
            scope: {
                vm: '=',
            },
        };
    }


  /** @ngInject */
    function bdpTol() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetails/bdptol.html',
            scope: {
                listing: '=',
                similar: '=',
            },


      /**
       * @memberof truelocal
       * @ngdoc controller
       * @name BdpTolController
       * @description BdpTolController.
       */
            controller: BdpTolController,
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

    /** @ngInject */
        function BdpTolController($window, $location, apiConfig, platformService, DTM) {
            var vm = this;

            vm._isPaid = true;
            vm._hasTol = false;


      /**
       * @memberof bdpTol
       * @method getCategoryVertical
       * @name getCategoryVertical
       * @description Check does business have category vertical.
       * @returns {Object}
       */
            this.getCategoryVertical = function() {
                var _listing = apiConfig.getListing();
                if (angular.isUndefined(_listing)) {
                    return {
                        name: '',
                    };
                }
                return (angular.isDefined(_listing.bussinessDetails)) ?
                       ((angular.isDefined(_listing.bussinessDetails.categoryVertical)) ?
                        _listing.bussinessDetails.categoryVertical : {name: ''}) : {name: ''};
            };


      /**
       * @memberof bdpTol
       * @method hasFeatured
       * @name hasFeatured
       * @description Check is business paid and does it have featured review.
       * @returns {Object}
       */
            vm.hasFeatured = function(listing) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }
                if (angular.isDefined(vm.listing)) {
                    if (angular.isDefined(vm.listing.paid) && angular.isDefined(vm.listing.featureReview) &&
            vm.listing.featureReview.status.publishStatus === 'active') {
                        vm._isPaid = vm.listing.paid;
                        return true;
                    }
                }
                return false;
            };

      /**
       * @memberof bdpTol
       * @method hasFeatured
       * @name hasFeatured
       * @description Return for featureReview id.
       * @returns {String}
       */
            vm.getFeatureId = function() {
                if (angular.isUndefined(vm.listing) ||
          angular.isUndefined(vm.listing.featureReview) ||
          angular.isUndefined(vm.listing.featureReview.id)) {
                    return '';
                }
                return '#' + vm.listing.featureReview.id;
            };


      /**
       * @memberof bdpTol
       * @method hasTol
       * @name hasTol
       * @description Return true if listing is not paid and have Tol.
       * @params {Object} listing
       * @params {Object} similar
       * @returns {Boolean}
       */
            vm.hasTol = function(listing, similar) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }
                if (angular.isDefined(similar)) {
                    vm.similar = similar;
                }
                if (angular.isDefined(vm.listing)) {
                    if (angular.isDefined(vm.listing.paid)) {
                        vm._isPaid = vm.listing.paid;
                    }
                }
                if (vm._isPaid == false) {
                    if (angular.isUndefined(vm.similar)) {
                        vm._hasTol = false;
                    } else if (angular.isUndefined(vm.similar.data)) {
                        vm._hasTol = false;
                    } else if (angular.isUndefined(vm.similar.data.inventories)) {
                        vm._hasTol = false;
                    } else if (vm.similar.data.inventories.length != 0) {
                        var tolCount = 0;
                        angular.forEach(vm.similar.data.inventories, function(inventory) {
                            if (inventory.type == 'TOL') {
                              if (inventory.size > 0) {
                                tolCount = tolCount + 1;
                            }
                          }
                        });
                        if (tolCount > 0) {
                            vm._hasTol = true;
                        } else {
                            vm._hasTol = false;
                        }
                    }
                }
                return vm._hasTol;
            };


            vm.allTols = [];
            vm.currentTol = [];


      /**
       * @memberof bdpTol
       * @method getTol
       * @name getTol
       * @description Return tol if exist.
       * @params {Object} similar
       * @returns {Object} currentTol
       */
            vm.getTol = function(similar) {
                if (angular.isDefined(similar)) {
                    vm.similar = similar;
                }
                if (vm.currentTol.length == 0 &&
          angular.isDefined(vm.similar) &&
          angular.isDefined(vm.similar.data) &&
          angular.isDefined(vm.similar.data.inventories)) {
                    angular.forEach(vm.similar.data.inventories, function(inventory) {
                        if (inventory.type == 'TOL') {
                            vm.allTols = inventory.inventory;
                        }
                    });
                    var totalObj = vm.allTols.length;
                    if (totalObj > 0) {
                        vm.currentTol = vm.allTols[0];
                    }
                }
                return vm.currentTol;
            };

            vm.category = '';


      /**
       * @memberof bdpTol
       * @method getCategory
       * @name getCategory
       * @description Return current tol category.
       * @params {Object} similar
       * @returns {String} category
       */
            vm.getCategory = function(similar) {
                if (angular.isDefined(similar)) {
                    vm.similar = similar;
                }
                if (vm.currentTol.length == 0) {
                    vm.currentTol = vm.getTol(similar);
                } else {
                    if (angular.isUndefined(vm.currentTol.category)) {
                        vm.category = '';
                    } else {
                        vm.category = vm.currentTol.category;
                    }
                }
                return vm.category;
            };


            vm.name = '';


      /**
       * @memberof bdpTol
       * @method getName
       * @name getName
       * @description Return current tol name.
       * @params {Object} similar
       * @returns {String} category
       */
            vm.getName = function(similar) {
                if (angular.isDefined(similar)) {
                    vm.similar = similar;
                }
                if (vm.currentTol.length == 0) {
                    vm.currentTol = vm.getTol(similar);
                } else {
                    if (angular.isUndefined(vm.currentTol.listing)) {
                        vm.name = '';
                    } else if (angular.isUndefined(vm.currentTol.listing.name)) {
                        vm.name = '';
                    } else {
                        vm.name = vm.currentTol.listing.name;
                    }
                }
                return vm.name;
            };

            vm.seoUrl = '#';


      /**
       * @memberof bdpTol
       * @method getUrl
       * @name getUrl
       * @description Return search optimized URL.
       * @params {Object} similar
       * @returns {String} url
       */
            vm.getUrl = function(similar) {
                if (angular.isDefined(similar)) {
                    vm.similar = similar;
                }
                var locationHost = $location.protocol() + "://" + (($location.host() === 'localhost') ? $location.host()+':'+ $location.port(): $location.host());
                if (vm.currentTol.length == 0) {
                    vm.currentTol = vm.getTol(similar);
                } else {
                    if (angular.isUndefined(vm.currentTol.listing)) {
                        vm.seoUrl = '#';
                    } else if (angular.isUndefined(vm.currentTol.listing.seoUrl)) {
                        vm.seoUrl = '#';
                    } else {
                        vm.seoUrl = locationHost + '/business/' + vm.currentTol.listing.seoUrl;
                    }
                }
                return vm.seoUrl;
            };

            vm.address = '';


      /**
       * @memberof bdpTol
       * @method getAddress
       * @name getAddress
       * @description Return current tol address.
       * @params {Object} similar
       * @returns {String} url
       */
            vm.getAddress = function(similar) {
                if (angular.isDefined(similar)) {
                    vm.similar = similar;
                }
                if (vm.currentTol.length == 0) {
                    vm.currentTol = vm.getTol(similar);
                } else {
                    if (angular.isUndefined(vm.currentTol.listing)) {
                        vm.address = '';
                    } else if (angular.isUndefined(vm.currentTol.listing.addresses)) {
                        vm.address = '';
                    } else if (angular.isUndefined(vm.currentTol.listing.addresses.address)) {
                        vm.address = '';
                    } else if (angular.isUndefined(vm.currentTol.listing.addresses.address[0])) {
                        vm.address = '';
                    } else {
                        if (angular.isDefined(vm.currentTol.listing.addresses.address[0].suburb) && angular.isDefined(vm.currentTol.listing.addresses.address[0].state)) {
                            if (vm.currentTol.listing.addresses.address[0].suburb == '' && vm.currentTol.listing.addresses.address[0].state == '') {
                              vm.address = '';
                          } else if (vm.currentTol.listing.addresses.address[0].suburb == '' && vm.currentTol.listing.addresses.address[0].state != '') {
                            vm.address = vm.currentTol.listing.addresses.address[0].state;
                        } else if (vm.currentTol.listing.addresses.address[0].suburb != '' && vm.currentTol.listing.addresses.address[0].state == '') {
                          vm.address = vm.currentTol.listing.addresses.address[0].suburb;
                      } else {
                          vm.address = vm.currentTol.listing.addresses.address[0].suburb + ', ' + vm.currentTol.listing.addresses.address[0].state;
                      }
                        } else {
                            vm.address = '';
                        }
                    }
                }
                return vm.address;
            };

            vm.statistics = [];


      /**
       * @memberof bdpTol
       * @method getStatistics
       * @name getStatistics
       * @description Return current tol listing statistics.
       * @params {Object} similar
       * @returns {Array} statistics
       */
            vm.getStatistics = function(similar) {
                if (angular.isDefined(similar)) {
                    vm.similar = similar;
                }
                if (vm.currentTol.length == 0) {
                    vm.currentTol = vm.getTol(similar);
                } else {
                    if (angular.isUndefined(vm.currentTol.listing)) {
                        vm.statistics = [];
                    } else if (angular.isUndefined(vm.currentTol.listing.statistics)) {
                        vm.statistics = [];
                    } else {
                        vm.statistics = vm.currentTol.listing.statistics;
                    }
                }
                return vm.statistics;
            };

      /**
       * @memberof bdpTol
       * @method tracking
       * @name tracking
       * @description Tracking statistics.
       * @params {Object} id
       * @returns {Array} statistics
       */
            vm.tracking = function(id) {
                DTM.trackAnalyticOnClick(id);
            };


      /**
       * @memberof bdpTol
       * @method openLink
       * @name openLink
       * @description Open link.
       * @params {$link} link
       * @params {target} target
       * @returns {Array} statistics
       */
            this.openLink = function($link, target) {
                if (angular.isDefined(target)) {
                    return $location.path($link).search({
                        target: target,
                    });
                } else {
                    return $location.path($link);
                }
            };


      /**
       * Check is it current tol same as current business (does it displaying itself as sponsored listing),
       * and return true if it is.
       * @returns {boolean} displayingItself
       */
            vm.displayingItself = function() {
                if (vm.hasTol() == true) {
                    if (angular.isDefined(vm.currentTol) && angular.isDefined(vm.currentTol.listing.id)) {
                        if (vm.listing.id === vm.currentTol.listing.id) {
                            return true;
                        }
                    }
                }
                return false;
            };
        }
    }
})();
