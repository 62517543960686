(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bsSorting
     * @description Directive displays menu with sorting options and invoice sorting.
     *
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {service}   $window                   Angular window wrapper
     * @param {service}   searchConfigManager       Service for building and handlig parameters for search
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *
     * @example
     * <bs-sorting search-listing="[object]"></bs-sorting>
     *
     */
    .directive('bsSorting', bsSorting);

  /** @ngInject */
    function bsSorting() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/bssorting/bssorting.html',
            scope: {
                searchListing: '=',
            },
            controller: ['$timeout', '$window', 'searchConfigManager', 'platformService', 'DTM', BsSortingController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BsSortingController($timeout, $window, searchConfigManager, platformService, DTM) {
            var vm = this;
            this.sortingActiveSelection = 'Best Match';
            this.sortingOptions = [{title: 'Best Match', sort: '', active: true},
        // {title : "Rating", sort : "rating", active : false} ,
        {title: 'Reviews', sort: 'reviews', active: false},
        {title: 'Deals', sort: 'deals', active: false}];

            this.sortingBox = false;

            var sortingBoxTimeout;


      /**
       * @memberof bsSorting
       * @method mouseLeftSorting
       * @name mouseLeftSorting
       * @description Shows sorting options dropdown menu after clicking element if not opened already.
       */
            this.mouseLeftSorting = function() {
                if (this.sortingBox) {
                    sortingBoxTimeout = $timeout(function() {
                        this.toggleSortingBox();
                    }.bind(this));
                }
            };


      /**
       * @memberof bsSorting
       * @method mouseEnteredSorting
       * @name mouseEnteredSorting
       * @description Hide sorting options dropdown menu on mouse enter.
       */
            this.mouseEnteredSorting = function() {
                if (sortingBoxTimeout) {
                    $timeout.cancel(sortingBoxTimeout);
                }
            };


      /**
       * @memberof bsSorting
       * @method toggleSortingBox
       * @name toggleSortingBox
       * @description Open/Close searc dropdown menu.
       */

            var
                _isChrome = platformService.isChrome(),
                _isSafari = platformService.isSafari(),
                _bodyWindowEl = (_isChrome || _isSafari) ? angular.element(document.querySelector('body,window')) : angular.element(document.querySelector('body,html')),
                _sortMenuElement = angular.element(document.querySelector('.dropdown-menu')),
                _sortMenuPosition;

            this.customSortPosition = {};
            this.setCustomPosition = function() {
                return this.customSortPosition;
            };

            this.toggleSortingBox = function() {
                this.sortingBox = !this.sortingBox;

                if(platformService.getWidth() < 768 && !platformService.isIPad()) {
                    if (this.sortingBox && !_sortMenuPosition) {
                        _sortMenuPosition = parseInt(_sortMenuElement.css('top'));
                    }

                    if (_bodyWindowEl[0].scrollTop < 60) {
                        this.customSortPosition = {top: (_sortMenuPosition + 55) + 'px'};
                    } else {
                        this.customSortPosition = {top: (_sortMenuPosition + 7) + 'px'};
                    }
                }

                if (this.sortingBox && this.isMobile()) {
                    //showSortingBox(); Commenting this out temporarily to fix CON-1367 (Sticky Header changes)
                } else if (!this.sortingBox && this.isMobile()) {
                    //hideSortingBox(); Commenting this out temporarily to fix CON-1367
                }
                if (sortingBoxTimeout) {
                    $timeout.cancel(sortingBoxTimeout);
                }
            };

            function showSortingBox() {
                angular.element(window).triggerHandler('open-sorting-box');
                angular.element(document.querySelector('#sortDropdownFader')).css('display', 'block');
            }

            function hideSortingBox() {
                angular.element(window).triggerHandler('close-sorting-box');
                angular.element(document.querySelector('#sortDropdownFader')).css('display', 'none');
            }

      /**
       * @memberof bsSorting
       * @method isMobile
       * @name isMobile
       * @description Returns true if mobile.
       * @returns {Boolean} isMobile
       */
            this.isMobile = function() {
                return platformService.isMobile();
            };


      /**
       * @memberof bsSorting
       * @method forceCloseDropdown
       * @name forceCloseDropdown
       * @description Close dropdown no mather of current state.
       */
            function forceCloseDropdown() {
                if (sortingBoxTimeout) {
                    $timeout.cancel(sortingBoxTimeout);
                }
                $timeout(angular.bind(this, function() {
                    this.sortingBox = false;
                    hideSortingBox();
                }));
            };

            angular.element($window).bind('scroll force-toggle-sort', angular.bind(this, forceCloseDropdown));
            angular.element(document.querySelector('#sortDropdownFader')).on('click', angular.bind(this, forceCloseDropdown));


      /**
       * @memberof bsSorting
       * @method sortingOptionsInactive
       * @name sortingOptionsInactive
       * @description Returns list of sorting options except one currently used to display results.
       * @returns {Array} sortinOpts
       */
            this.sortingOptionsInactive = function() {
                if (this.isMobile()) {
                    return this.sortingOptions;
                }
                var _permList = [];
                for (var i = 0, l = this.sortingOptions.length; i < l; i++) {
                    if (!this.sortingOptions[i].active)
                        _permList.push(this.sortingOptions[i]);
                }
                return _permList;
            };


      /**
       * @memberof bsSorting
       * @method manageSort
       * @name manageSort
       * @description Starts sorting after sort option is chosen.
       */
            this.manageSort = function(_sortingOption) {
                var isSortTypeChanged = true;
                for (var i = 0, l = this.sortingOptions.length, _listElement; i < l; i++) {
                    _listElement = this.sortingOptions[i];
                    if (_listElement.active) {
                        if (_listElement.title == _sortingOption.title) {
                            isSortTypeChanged = false;
                        }
                        _listElement.active = false;
                    }
                    if (_listElement.title == _sortingOption.title) {
                        _listElement.active = true;
                        this.sortingActiveSelection = _listElement.title;
                        searchConfigManager.setProperty('sort', _listElement.sort);
                    }
                }

                if (TL.metrics && TL.metrics.search) {
                    TL.metrics.search.sorttype = _sortingOption.sort;
                }

                switch(_sortingOption.sort){
                    case 'reviews':
                        DTM.trackAnalyticOnClick('sort by:reviews');
                        break;
                    case 'deals':
                        DTM.trackAnalyticOnClick('sort by:deals');
                        break;
                    default:
                        if (TL.metrics && TL.metrics.search) {
                            TL.metrics.search.sorttype = 'best match';
                        }
                        DTM.trackAnalyticOnClick('sort by:best match');
                };

                if(isSortTypeChanged){  //No need to call api if sort type is already active
                    searchConfigManager.displayItemsForChangedSortType();
                    angular.element(window).scrollTop = 0; //Since the new results are updated when display is reset we need to take user to top of page
                }
                $timeout(this.toggleSortingBox());
            };
        }
    }
})();
