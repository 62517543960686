(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsVisitWebsite
     * @description Directive will display email business link. It will display the email business modal.
     *
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {service}   $log                      Angular console log wrapper
     * @param {constant}  env                       Environmental constants
     * @param {service}   $window                   Angular wrapper for window
     *
     * @example
     * <bdp-details-email-business listing="[object]" copypoint="[boolean]"></bdp-details-email-business>
     */
    .directive('bdpDetailsVisitWebsite', bdpDetailsVisitWebsite);

  /** @ngInject */
    function bdpDetailsVisitWebsite() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetailsvisitwebsite/bdpdetailsvisitwebsite.html',
            scope: {
                listing: '=',
                details: '=',
                displayedfrom: '=',
            },

            controller: BdpDetailsVisitWebsiteController,
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

    /** @ngInject */
        function BdpDetailsVisitWebsiteController(businessService) {
            var vm = this;
            vm.displayedfrom = this.displayedfrom;
            vm.isDisplayedInDesktop = vm.displayedfrom == 'contactholder' || vm.displayedfrom == 'scroll';
            vm.isDisplayedInMobile = vm.displayedfrom == 'tools' || vm.displayedfrom == 'scroll';

            vm.getHavePrimaryWebsite = function() {
                return businessService.getHavePrimaryWebsite(vm.listing);
            };

            vm.websiteText = function() {
                return window.outerWidth <= 767 ? 'Website' : 'VISIT WEBSITE';
            };

            vm.trackWebsiteClick = function() {
                var url = businessService.getPrimaryWebsite(vm.listing);
                return businessService.trackWebsiteClick(url, 'BDP');
            };
        }
    }
})();
