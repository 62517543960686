(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name tagsService
     * @description tagsService provides methods for get, like and unlike listing tag.
     */
    .factory('tagsService', tagsService);

  /** @ngInject */
    function tagsService($log, $http, apiConfig) {
        var tagService = {
            getTags: getTags,
            likeTag: likeTag,
            unLikeTag: unLikeTag,
        };


    /**
     * @memberof tagsService
     * @method getTags
     * @name getTags
     * @description Get listing tags.
     * @param {String} id
     * @returns {Object} getTags - promise
     */
        function getTags(id) {
      // $httpProvider.defaults.headers.post = {'Content-Type': 'text/plain'};
            return $http.get(apiConfig.generate('listings', id, 'tags'), {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
        .then(getTagDetails)
        .catch(getTagDetailsFailed);

            function getTagDetails(response) {
                $log.debug('IMP');
                $log.debug(response);
                $log.debug('IMP__END');

        // var _data = response.data.data;
        // var _metadata = response.data.meta;
            }


            function getTagDetailsFailed(error) {
                var message = '';
                if (error.status == '500') {
                    message = 'XHR Failed for getListing ';
                } else if (error.status == '404') {
                    message = 'XHR NotFound for getListing ';
                }
                message = message + id + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
            }
        }


    /**
     * @memberof tagsService
     * @method likeTag
     * @name likeTag
     * @description Like listing tag.
     * @param {String} id
     * @returns {Object} likeTag - promise
     */
        function likeTag(id) {
      // sProvider.defaults.headers.post = {'Content-Type': 'text/plain'};
            return $http.get(apiConfig.generate('tags', id, 'like'), apiConfig.getXAuthToken())
        .then(likeTagSuccess)
        .catch(likeTagFail);

            function likeTagSuccess(response) {
                $log.debug('LIKETAG');
                $log.debug(response);
                $log.debug('LIKETAG_END');

        // var _data = response.data.data;
        // var _metadata = response.data.meta;
            }


            function likeTagFail(error) {
                var message = '';
                if (error.status == '500') {
                    message = 'XHR Failed for getListing ';
                } else if (error.status == '404') {
                    message = 'XHR NotLogedIn';
                } else if (error.status == '403') {
                    message = 'XHR You cannot like or unlike your own tag';
                } else if (error.status == '400') {
                    message = 'XHR You already like this tag';
                }
                message = message + id + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
            }
        }


    /**
     * @memberof tagsService
     * @method unLikeTag
     * @name unLikeTag
     * @description Unlike listing tag.
     * @param {String} id
     * @returns {Object} unLikeTag - promise
     */
        function unLikeTag(id) {
      // $httpProvider.defaults.headers.post = {'Content-Type': 'text/plain'};
            return $http.get(apiConfig.generate('tags', id, 'unlike'), apiConfig.getXAuthToken())
        .then(unLikeTagSuccess)
        .catch(unLikeTagFail);

            function unLikeTagSuccess(response) {
                $log.debug('UNLIKETAG');
                $log.debug(response);
                $log.debug('UNLIKETAG_END');

        // var _data = response.data.data;
        // var _metadata = response.data.meta;
            }


            function unLikeTagFail(error) {
                var message = '';
                if (error.status == '500') {
                    message = 'XHR Failed for getListing ';
                } else if (error.status == '401') {
                    message = 'XHR You are not loged in';
                } else if (error.status == '400') {
                    message = 'XHR You already unliked this tag';
                }
                message = message + id + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
            }
        }

        return tagService;
    }
})();
