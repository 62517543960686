(function () {
    'use strict';

    var ITEMS_PER_PAGE = 12;

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc service
         * @name searchConfig
         * @description searchConfig service expose methods for getting/setting search
         *     configuration properties, parameters and options.
         */
        .factory('searchConfig', searchConfig)


        /**
         * @memberof truelocal
         * @ngdoc service
         * @name searchConfigManager
         * @description searchConfigManager service expose methods for manipulation with search
         *     results data. Results data can be shown in three main view options (list, grid and
         *     map view), whose look vary with change of screen size, access platform and number of
         *     results. searchConfigManager provide data for desired view option.
         */
        .service('searchConfigManager', searchConfigManager)

        .service('searchSeoScrollManager', searchSeoScrollManager);

    function searchConfig($window, $timeout) {
        /**
         * @memberof searchConfig
         * @name uiConfig
         * @description UI configuration params store UI size limit block per view.
         */
        var uiConfig = {
            viewType: 'listView',
            listView: {
                desktop: ITEMS_PER_PAGE,
                tablet: ITEMS_PER_PAGE,
                mobile: ITEMS_PER_PAGE,
            },
            gridView: {
                desktop: ITEMS_PER_PAGE,
                tablet: ITEMS_PER_PAGE,
            },
            serviceView: {
                desktop: 8,
                tablet: 6,
                mobile: 4,
            },
        };

        /**
         * @memberof searchConfig
         * @name viewConfig
         * @description
         * SERVER request configuration
         * stores request definitions
         * filter definitions
         * type, keyword/location or both
         */
        var viewConfig = {
            limit: getLoadingLimit(),
            loadMore: 1,
            startIndex: 1,
            maxPageNumber: 50,
        };

        var pullConfiguration = {
            limit: ITEMS_PER_PAGE,
            offset: 0,
        };

        function getMaxPageNumber() {
            return viewConfig.maxPageNumber;
        }

        function getLoadMoreCount() {
            return viewConfig.loadMore;
        }

        function getStartIndex() {
            return viewConfig.startIndex;
        }

        function increaseLoadMoreCounter() {
            viewConfig.loadMore += 1;
        }

        function decreaseLoadPrevCounter() {
            viewConfig.startIndex -= 1;
            viewConfig.loadMore += 1;
        }

        function resetLoadMoreCounter() {
            viewConfig.loadMore = 1;
        }

        function getCurrentViewLimit() {
            return viewConfig.limit;
        }

        function setCurrentViewType(viewType) {
            uiConfig.viewType = viewType;
            viewConfig.limit = getLoadingLimit();
        }

        function getCurrentViewType(viewType) {
            return uiConfig.viewType;
        }

        function setCurrentLoadIndex(loadIndex) {
            viewConfig.startIndex = loadIndex || 1;
            viewConfig.loadMore = 1;
        }

        function getLoadingListLimit() {
            var _currentWidth = angular.element(window)[0].outerWidth;
            var _viewType = 'listView';
            var _platform = 'desktop';

            if (_viewType == 'listView') {
                if (_currentWidth <= 320) {
                    _platform = 'mobile';
                } else if (_currentWidth > 320 && _currentWidth <= 768) {
                    _platform = 'tablet';
                } else {
                    _platform = 'desktop';
                }
            }

            return uiConfig[_viewType][_platform];
        }

        function getLoadingLimit() {
            var _currentWidth = angular.element(window)[0].outerWidth;
            var _viewType = uiConfig.viewType;
            var _platform = 'desktop';

            if (_viewType == 'listView' || _viewType == 'serviceView') {
                if (_currentWidth <= 768) {
                    _platform = 'mobile';
                } else if (_currentWidth > 768 && _currentWidth <= 991) {
                    _platform = 'tablet';
                } else {
                    _platform = 'desktop';
                }
            } else {
                if (_currentWidth > 768 && _currentWidth <= 991) {
                    _platform = 'tablet';
                } else {
                    _platform = 'desktop';
                }
            }

            return uiConfig[_viewType][_platform];
        }

        function getProperty() {
            var _keyValues = arguments;
            for (var argumentIndex = 0, argumentsSize = _keyValues.length;
                 argumentIndex < argumentsSize; argumentIndex++) {
                var _indexedKey = _keyValues[argumentIndex];
                if (angular.isDefined(_indexedKey) && angular.isDefined(
                        pullConfiguration[_indexedKey]) && pullConfiguration[_indexedKey] !== null
                    && pullConfiguration[_indexedKey] !== '') {
                    return pullConfiguration[_indexedKey];
                }
            }
            return '';
        }

        // TODO
        function resetAllProperty(_page) {
            pullConfiguration = null;
            pullConfiguration = {
                limit: ITEMS_PER_PAGE,
                offset: 0,
            };

            setCurrentLoadIndex(_page || 1);

            if (_page && !isNaN(_page) && _page > 1) {
                var _pageIndexed = _page - 1;
                var _loadingListLimit = getLoadingListLimit();
                var _currentPosition = _pageIndexed * _loadingListLimit;
                var _startPosition = Math.floor(_currentPosition / ITEMS_PER_PAGE) * ITEMS_PER_PAGE;
                pullConfiguration.offset = _startPosition;
            }
        }

        function setProperty(key, value) {
            if (angular.isDefined(value) && value === null) {
                delete pullConfiguration[key];
            } else {
                pullConfiguration[key] = value;
            }
        }

        function getConfiguration(onlySponsored) {
            var _tempConfig = angular.copy(pullConfiguration);

            for (var k in _tempConfig) {
                if (k == 'category' || k == 'location' || k == 'state' || k == 'suburb' || k
                                                                                           == 'region'
                    || k == 'keyword') {
                    _tempConfig[k] = _tempConfig[k].replace(/[ ]/gi, '-');
                } else if(k == 'rbt') {
		   _tempConfig[k] = _tempConfig[k].replace('&','%26');
            	}
	    }

            if (angular.isDefined(onlySponsored) && onlySponsored) {
                _tempConfig.inventory = true;
            }

            return _tempConfig;
        }

        function getNextBlockConfig() {
            var _newStartPosition = parseInt(getProperty('offset')) + parseInt(
                    getProperty('limit'));

            setProperty('offset', _newStartPosition);
            setProperty('limit', ITEMS_PER_PAGE);

            return getConfiguration();
        }

        // TODO
        function getPrevBlockConfig() {
            var _newStartPosition = (viewConfig.startIndex - 1) * ITEMS_PER_PAGE;

            var _configForPrev = angular.copy(getConfiguration());
            _configForPrev.offset = _newStartPosition;
            _configForPrev.limit = ITEMS_PER_PAGE;

            return _configForPrev;
        }

        function getNewSortConfig() {
            setCurrentLoadIndex(1);

            setProperty('offset', 0);
            setProperty('limit', ITEMS_PER_PAGE);

            var _tempConfig = angular.copy(getConfiguration(true));

            for (var k in _tempConfig) {
                if (k == 'category' || k == 'location' || k == 'state' || k == 'suburb' || k
                                                                                           == 'region'
                    || k == 'keyword') {
                    _tempConfig[k] = _tempConfig[k].replace(/[ ]/gi, '-');
                }
            }

            return _tempConfig;
        }

        function getRefineSearchConfig(facetType, item) {
            //Reset ss and rbt properties
            delete pullConfiguration['rbt'];
            delete pullConfiguration['ss'];

            if(facetType === "categories_facet"){
                setProperty('rbt', '"' + item + '"');
            } else if(["location_facet", "suburb_facet"].indexOf(facetType) > -1){
                setProperty('ss', '"' + item + '"');
            }
            setProperty('inventory',true);
            return getConfiguration();
        }

        function getNumberOfBlocksAndLimit() {
            return [parseInt(viewConfig.loadMore), parseInt(viewConfig.limit)];
        }

        function getNumberOfViewableItems() {
            var _totalNumberOfViewableItems = parseInt(viewConfig.loadMore) * parseInt(
                    viewConfig.limit);
            if (parseInt(viewConfig.startIndex) > 1) {
                return _totalNumberOfViewableItems +=
                    (parseInt(viewConfig.startIndex) - 1) * parseInt(viewConfig.limit);
            }
            return _totalNumberOfViewableItems;
        }

        function fetchMoreStatus(_resultsPulledSize) {
            var _checkingSizeDifference = parseInt(_resultsPulledSize) - (parseInt(
                    viewConfig.loadMore) * 10);
            var _fetchMoreData = _checkingSizeDifference < 10 ? true : false;

            return _fetchMoreData;
        }

        // TODO
        function fetchPrevStatus() {
            if (viewConfig.startIndex > 0) {
                return true;
            }
            return false;
        }

        var service = {
            getProperty: getProperty,
            setProperty: setProperty,
            resetAllProperty: resetAllProperty,
            getConfiguration: getConfiguration,
            getNewSortConfig: getNewSortConfig,
            getNextBlockConfig: getNextBlockConfig,
            getPrevBlockConfig: getPrevBlockConfig,
            getRefineSearchConfig: getRefineSearchConfig,
            setCurrentViewType: setCurrentViewType,
            getCurrentViewType: getCurrentViewType,
            setCurrentLoadIndex: setCurrentLoadIndex,

            getNumberOfViewableItems: getNumberOfViewableItems,
            fetchMoreStatus: fetchMoreStatus,
            fetchPrevStatus: fetchPrevStatus,

            getMaxPageNumber: getMaxPageNumber,
            getLoadMoreCount: getLoadMoreCount,
            getStartIndex: getStartIndex,
            increaseLoadMoreCounter: increaseLoadMoreCounter,
            decreaseLoadPrevCounter: decreaseLoadPrevCounter,
            resetLoadMoreCounter: resetLoadMoreCounter,
            getCurrentViewLimit: getCurrentViewLimit,

            getNumberOfBlocksAndLimit: getNumberOfBlocksAndLimit,
            getLoadingListLimit: getLoadingListLimit,
        };

        return service;
    }

    /** @ngInject */
    function searchConfigManager($window, uriService, searchConfig, serviceListing, apiConfig,
                                 $stateParams, $state, $location, $anchorScroll, $timeout,
                                 searchSeoScrollManager) {
        var resultsData;

        var service = {
            initializeBaseParams: initializeBaseParams,

            getProperty: searchConfig.getProperty,
            setProperty: searchConfig.setProperty,
            resetAllProperty: searchConfig.resetAllProperty,
            getSearchBreadcrump: getSearchBreadcrump,

            getConfiguration: searchConfig.getConfiguration,
            getNewSortConfig: searchConfig.getNewSortConfig,
            getNextBlockConfig: searchConfig.getNextBlockConfig,
            getPrevBlockConfig: searchConfig.getPrevBlockConfig,

            setCurrentViewType: setCurrentViewType,
            setCurrentLoadIndex: searchConfig.setCurrentLoadIndex,
            refineSearchResults: refineSearchResults,
            displayNextResultsBlock: displayNextResultsBlock,
            displayPrevResultsBlock: displayPrevResultsBlock,
            displayItemsForChangedSortType: displayItemsForChangedSortType,
            hideLoadPrevButton: hideLoadPrevButton,
            hideLoadMoreButton: hideLoadMoreButton,

            getNumberOfViewableItems: searchConfig.getNumberOfViewableItems,
            getNumberOfBlocksAndLimit: searchConfig.getNumberOfBlocksAndLimit,

            getStartIndex: searchConfig.getStartIndex,

            getLoadingListLimit: searchConfig.getLoadingListLimit,
        };



        function getSearchBreadcrump() {
            var keyword = searchConfig.getProperty('keyword', 'category').capitalizeSentence();
            keyword = keyword ? keyword : 'Search';
            var location = searchConfig.getProperty('location', 'suburb', 'region', 'state')
                .capitalizeSentence().capitalizeSuburb();
            location = location ? ' in ' + location : (keyword) ? '' : ' in Australia';
            var _createdTitle = keyword + location;

            if (_createdTitle == 'Search') {
                return '';
            }

            return _createdTitle;
        }

        /**
         * @memberof searchConfigManager
         * @method displayItemsForChangedSortType
         * @name displayItemsForChangedSortType
         * @description
         * Applying new sort option in base config,
         * applying offset 0 to current limit not bigger then 100,
         * pulling data from server,
         * applying data in local store variable,
         * preparing list for client based on UI config & viewConfig,
         * returning list to client.
         */
        function displayItemsForChangedSortType(_option) {
            var _tempConfig = angular.copy(searchConfig.getNewSortConfig());

            angular.element(document.querySelector('body')).addClass('no-animate');
            window.changingSearch = true;
            $location.search('page', null);

            serviceListing.listingSearch(
                resultsData,
                null,
                _tempConfig,
                apiConfig.getHeaderVersion()
            );
        }

        function setCurrentViewType(_viewType) {
            searchConfig.setCurrentViewType(_viewType);
        }

        /**
         * @memberof searchConfigManager
         * @method displayNextResultsBlock
         * @name displayNextResultsBlock
         * @description
         * First checking for current results list size,
         * if list size is last block of 10 calculated by loaded * 10 - 10 = currentlist size,
         * pulling new block of 20 results,
         * applying data in local store variable and
         * preparing list...
         */
        function displayNextResultsBlock(_callback) {
            var _nextPage = parseInt(searchConfig.getStartIndex()) + parseInt(
                    searchConfig.getLoadMoreCount());

            window.changingSearch = true;

            if (_nextPage === 1) {
                $location.search('page', null);
            } else {
                $location.search('page', _nextPage);
            }

            searchConfig.increaseLoadMoreCounter();

            if (searchConfig.fetchMoreStatus(resultsData.listing.length)) {
                var _tempConfig = angular.copy(searchConfig.getNextBlockConfig());

                serviceListing.searchListingNextBlock(resultsData, _tempConfig,
                                                      apiConfig.getHeaderVersion())
                    .then(function () {
                        $timeout(function(){
                            _callback(ITEMS_PER_PAGE);
                        });
                    });
            } else {
                _callback();
            }
        }

        function refineSearchResults(_callback, facetType, item){
            var _tempConfig = angular.copy(searchConfig.getRefineSearchConfig(facetType, item));
            serviceListing.listingSearch(resultsData, null, _tempConfig, apiConfig.getHeaderVersion())
                .then(function () {
                    $timeout(function(){
                        _callback();
                    });
                });
        }

        // TODO
        function displayPrevResultsBlock(_callback) {
            searchConfig.decreaseLoadPrevCounter();

            window.changingSearch = true;
            var _prevPage = searchConfig.getStartIndex();
            if (_prevPage === 1) {
                $location.search('page', null);
            } else {
                $location.search('page', _prevPage);
            }

            if (searchConfig.fetchPrevStatus()) {
                serviceListing.searchListingPrevBlock(resultsData,
                                                      searchConfig.getPrevBlockConfig(),
                                                      apiConfig.getHeaderVersion())
                    .then(function () {
                        _callback();
                    });
            } else {
                _callback();
            }
        }

        // TODO
        function hideLoadPrevButton() {
            var _currentlyShowing = searchConfig.getLoadMoreCount()
                                    * searchConfig.getCurrentViewLimit();
            var _thereAreNoMoreResults = true;
            if(resultsData) {
                _thereAreNoMoreResults = (parseInt(resultsData.size) - _currentlyShowing > 0) ? false : true;
            }

            return _thereAreNoMoreResults;
        }

        function hideLoadMoreButton() {
            var mustHide = false;
            var defaultLimit = searchConfig.getCurrentViewLimit();
            var maxPageNumber = searchConfig.getMaxPageNumber();
            var _currentlyShowing = searchConfig.getLoadMoreCount() * defaultLimit;
            
            if (angular.isUndefined(resultsData)) {
                resultsData = apiConfig.getListing();
            }

            var totalResults = 0;
            var limit = defaultLimit;
            var offset = 0;
            var numListings = 0;

            if (resultsData) {
                totalResults = parseInt(resultsData.size);
                mustHide = (totalResults - _currentlyShowing > 0) ? false : true;
                limit = resultsData.limit ? resultsData.limit : defaultLimit;
                offset = resultsData.offset;
                
                if (resultsData.listing) {
                    numListings = resultsData.listing.length;
                }
            }

            var maxNumListings = maxPageNumber * limit;
            var lastListingIndex = offset + numListings;
            var isLastPage = lastListingIndex >= totalResults || lastListingIndex >= maxNumListings;
            
            if (isLastPage) {
                mustHide = true;
                //console.log('Last page reached totalResults = ', totalResults, ', lastListingIndex = ', lastListingIndex);
            }

            return mustHide;
        }

        function initializeBaseParams(_baseModel, _metaModel, _sponsoredModel, versionNumber,
                                      _callback, bypassApiCall) {
            angular.element($window).triggerHandler('url-changed');

            if(($state.current.name==='find-default' || $state.current.name==='search-default' )  && angular.isUndefined($stateParams.path)){
              $stateParams.path="australia";
            }

            var _pathArray = $stateParams.path.split('/'),
                _searchMark = parseCurrentPage();

            if (!_searchMark || isNaN(_searchMark)) {
                _searchMark = 1;
            }

            searchConfig.resetAllProperty(_searchMark);
            var _findParametersUrlIndexed;

            if ($state.current.name == 'search') {
                searchConfig.setProperty('type', 'keyword');
                var _keywordWordValueSearch = uriService.uriDecode(_pathArray[0]);
                _keywordWordValueSearch = _keywordWordValueSearch.trim();
                searchConfig.setProperty('keyword', _keywordWordValueSearch);
                if (_keywordWordValueSearch == '') {
                    searchConfig.setProperty('location', "Australia");
                    _callback();
                    return;
                }
                if (_pathArray[1] && _pathArray.length == 2) {
                    var _locationWordValue = uriService.uriDecode(
                        _pathArray[_pathArray.length - 1]);
                    _locationWordValue = _locationWordValue.trim();
                    searchConfig.setProperty('location', _locationWordValue);
                } else if (_pathArray.length > 2) {
                    _findParametersUrlIndexed = ['keyword', 'state', 'region', 'suburb'];
                    for (var pathnameIndexSearch = 1; pathnameIndexSearch < _pathArray.length;
                         pathnameIndexSearch++) {
                        var _paramKey = _findParametersUrlIndexed[pathnameIndexSearch];
                        searchConfig.setProperty(_paramKey, uriService.uriDecode(
                            _pathArray[pathnameIndexSearch]));
                    }
                }
            } else if ($state.current.name == 'search-location' || $state.current.name==='find-default' || $state.current.name==='search-default') {
               if($state.current.name==='find-default'){
                 $state.current.name='find'
               }
               else if($state.current.name==='search-default'){
                 $state.current.name='search'
               }
                searchConfig.setProperty('type', 'location');
                if (_pathArray.length == 1) {
                    searchConfig.setProperty('location', uriService.uriDecode(
                        _pathArray[_pathArray.length - 1]));
                } else if (_pathArray.length > 2) {
                    searchConfig.setProperty('location', uriService.uriDecode(
                        _pathArray[_pathArray.length - 1]));
                    _findParametersUrlIndexed = ['state', 'region', 'suburb'];

                    for (var pathnameIndex1 = 0; pathnameIndex1 < _pathArray.length;
                         pathnameIndex1++) {
                        var _paramKey1 = _findParametersUrlIndexed[pathnameIndex1];
                        searchConfig.setProperty(_paramKey1,
                                                 uriService.uriDecode(_pathArray[pathnameIndex1]));
                    }
                }
            } else if ($state.current.name == 'find') {
                searchConfig.setProperty('type', 'browse');
                var _findParametersUrlIndex = ['category', 'state', 'region', 'suburb'];

                for (var pathnameIndex2 = 0; pathnameIndex2 < _pathArray.length; pathnameIndex2++) {
                    var _paramKey2 = _findParametersUrlIndex[pathnameIndex2];
                    if(_pathArray.length===1 && _pathArray[pathnameIndex2]===""){
                      searchConfig.setProperty('location', 'Australia');
                    }
                    else{
                      searchConfig.setProperty(_paramKey2,
                                               uriService.uriDecodeFind(_pathArray[pathnameIndex2]));
                    }
                }
            } else if ($state.current.name == 'service') {
                searchConfig.setProperty('type', 'keyword');
                var _keywordWordValueService = uriService.uriDecodeFind(_pathArray[0]);
                _keywordWordValueService = _keywordWordValueService.trim();
                searchConfig.setProperty('keyword', _keywordWordValueService);
                if (_keywordWordValueService == '') {
                    _callback();
                    return;
                }
                if (_pathArray[1] && _pathArray.length == 2) {
                    var _locationWordValueService = uriService.uriDecodeFind(
                        _pathArray[_pathArray.length - 1]);
                    _locationWordValueService = _locationWordValueService.trim();
                    searchConfig.setProperty('location', _locationWordValueService);
                } else if (_pathArray.length > 2) {
                    var _findParametersUrlIndexedService = ['', 'state', 'region', 'suburb'];

                    for (var pathnameIndexService = 1, pathnamePartsLengthService = _pathArray.length;
                         pathnameIndexService < pathnamePartsLengthService;
                         pathnameIndexService++) {
                        var _paramKeyService = _findParametersUrlIndexedService[pathnameIndexService];
                        searchConfig.setProperty(_paramKeyService, uriService.uriDecodeFind(
                            _pathArray[pathnameIndexService]));
                    }
                }
            }
            searchConfig.setProperty('showCopyPoints', true);

            if(!bypassApiCall){
                serviceListing.listingSearch(_baseModel, _metaModel,
                                             searchConfig.getConfiguration(true), versionNumber)
                    .then(function () {
                        listingSearchSuccess();
                    });
            } else {
                listingSearchSuccess();
            }

            function listingSearchSuccess () {
                resultsData = _baseModel;
                _callback();

                $timeout(function () {
                    searchSeoScrollManager.start();
                });
            }

        }

        function parseCurrentPage() {
            return parseInt($location.search().page) || 1;
        }

        return service;
    }

    function searchSeoScrollManager($window, searchConfig, platformService, $location) {
        var service = {
            start: start,
            stop: stop,
        };

        var _configParams,
            _searchItemsList,
            _scrolledFromTop = 0,
            _newScrollPos = 0,
            _numberOfPages,
            _numberOfBlocks,
            _lastPagePosition = 1,
            _resizeTimeout,
            _isChrome = platformService.isChrome(),
            _isSafari = platformService.isSafari(),
            _bodyWindowEl = (_isChrome || _isSafari) ? angular.element(document.querySelector('body,window'))
                : angular.element(document.querySelector('body,html'));

        var _configParamChangeHandler = function () {
            updateConfig();
        };

        // grid default 312 - desktop

        var _scrollHandler = function () {
            _newScrollPos = _bodyWindowEl[0].scrollTop;
            if (_scrolledFromTop - _newScrollPos > 200 || _newScrollPos - _scrolledFromTop > 200) {
                _scrolledFromTop = _newScrollPos;
            } else {
                return;
            }

            _numberOfBlocks =
                (_scrolledFromTop - _configParams.containerOffsetTop)
                / _configParams.itemHeightSize;
            _numberOfPages =
                Math.floor(
                    (Math.floor(_numberOfBlocks) * _configParams.gridCounter) / ITEMS_PER_PAGE);
            _numberOfPages = (_numberOfPages <= 0) ? 0 : _numberOfPages;
            _numberOfPages += parseInt(searchConfig.getStartIndex());
            _numberOfPages =
                (_numberOfPages > searchConfig.getLoadMoreCount() + (searchConfig.getStartIndex()
                                                                     - 1))
                    ? searchConfig.getLoadMoreCount() + (searchConfig.getStartIndex() - 1)
                    : _numberOfPages;

            if (_lastPagePosition != _numberOfPages && _numberOfPages >= 1) {
                _lastPagePosition = _numberOfPages;

                window.changingSearch = true;
                if (_lastPagePosition === 1) {
                    $location.search('page', null);
                } else {
                    $location.search('page', _lastPagePosition);
                }
            }
        };

        var _resizeHandler = function () {
            /*if (angular.element(window)[0].outerWidth == window.outerWidth) {
                return;
            } else {
                window.outerWidth = angular.element(window)[0].outerWidth;
            }*/
            angular.element(window).off('scroll', _scrollHandler);

            if (_resizeTimeout) {
                clearTimeout(_resizeTimeout);
                _resizeTimeout = null;
            }

            _resizeTimeout = setTimeout(function () {
                updateConfig();
                angular.element(window).on('scroll', _scrollHandler);
            }, 100);
        };

        function updateConfig() {
            var searchResults = angular.element(document.querySelector('.search-results'));
            _configParams = {
                view: searchConfig.getCurrentViewType(),
                gridCounter: (searchConfig.getCurrentViewType() == "gridView") ? (angular.element(
                                                                                   window)[0].outerWidth
                                                                                  <= 991) ? 2 : 3
                    : 1,
                containerOffsetTop: searchResults.length && searchResults.prop('offsetTop'),
                itemHeightSize: (searchConfig.getCurrentViewType() == "gridView") ?
                                312 : (angular.element(window)[0].outerWidth <= 991) ? 315 : 354
            };
        }

        function start() {
            _searchItemsList = angular.isDefined(angular.element(document.querySelector('.search-results'))[0]) ? angular.element(document.querySelector('.search-results'))[0].clientHeight : 0;
            updateConfig();

            angular.element(window).on('config-param-changed', _configParamChangeHandler);
            angular.element(window).on('scroll', _scrollHandler);
            angular.element(window).on('resize', _resizeHandler);
        }

        function stop() {
            angular.element(window).off('config-param-changed', _configParamChangeHandler);
            angular.element(window).off('scroll', _scrollHandler);
            angular.element(window).off('resize', _resizeHandler);
        }

        return service;
    }
})();
