(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsContactPhone
     * @description Directive displays business primary phone number. It first check available phones and detect which
     * is primary. Then format phone number in user friendly view. Phone number is displayed with phone icon.
     *
     * When detecting primary phone, directive will look on API priorityNumber.phonePreference.
     *
     * When formatting phone number, directive will apply next rules:
     *
     *    if phone number have 10 digits:
     *      if starts with 04 / 1300 / 1800 or 1900, then it wont have brackets, and it will be divided as eg. 1800 123 123,
     *      , and otherwise it will starts with brackets, eg.: (12) 1234 123456
     *    if phone number have 6 digits
     *      digits will be arranged without brackets, as example: 12 12 12
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     */
    .directive('bdpDetailsContactPhone', bdpDetailsContactPhone);

  /** @ngInject */
    function bdpDetailsContactPhone() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetailscontactphone/bdpdetailscontactphone.html',
            scope: {
                contacts: '=',
                priorityNumber: '=',
            },
            controller: ['platformService', BdpDetailsContactPhoneController],
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

    /** @ngInject */

    /**
     * @memberof truelocal
     * @method bdpDetailsContactPhone_BdpDetailsContactPhoneController
     * @name bdpDetailsContactPhone_BdpDetailsContactPhoneController
     * @description BdpDetailsContactPhoneController.
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *
     */
        function BdpDetailsContactPhoneController(platformService) {
            var vm = this;
            vm.isMobile = platformService.isMobilePlatform();

            vm.haveNumber = false;
            vm.numbers = [];

            if (angular.isUndefined(vm.priorityNumber) ||
        angular.isUndefined(vm.priorityNumber.phonePreference)) {
                vm.priorityNumber = {phonePreference: 'phone'};
            }

            vm.priorityNumber = (vm.priorityNumber.phonePreference == 'local') ? 'phone' : vm.priorityNumber.phonePreference;


      /**
       * @memberof bdpDetailsContactPhone
       * @method cleanup
       * @name cleanup
       * @description Check for duplicates in phone numbers.
       */
            vm.cleanup = function() {
                vm.numbers.sort(function(a, b) {
                    return b.priority - a.priority;
                });

                if (vm.numbers.length > 1) {
                    for (var m = 0, lm = vm.numbers.length; m < lm; m++) {
                        var _comparingMainNumberForDuplicates = vm.numbers[m].value;
                        for (var i = vm.numbers.length - 1, l = (m + 1); i >= l; i--) {
                            if (vm.numbers[i].value == _comparingMainNumberForDuplicates) {
                                vm.numbers[i].remove = true;
                            }
                        }
                    }
                }
                for (var f = vm.numbers.length - 1, fi = 0; f > fi; f--) {
                    if (angular.isDefined(vm.numbers[f].remove)) {
                        vm.numbers.splice(f, 1);
                    }
                }
            };


      /**
       * @memberof bdpDetailsContactPhone
       * @method getNumbers
       * @name getNumbers
       * @description Check for duplicates in phone numbers.
       * @param {Object} contact  Contact object from listing
       * @returns {String} number raw phone number
       */
            vm.getNumbers = function(contact) {
                if (angular.isDefined(contact)) {
                    this.contacts.contact = contact;
                }
                if (angular.isDefined(this.contacts)) {
                    if (angular.isDefined(this.contacts.contact)) {
                        vm.numbers = [];
                        angular.forEach(this.contacts.contact, function(contact) {
                            if (contact.type == vm.priorityNumber) {
                                contact.priority = true;
                            } else {
                                contact.priority = false;
                            }
                            if (contact.type == 'mobile' || contact.type == 'phone' || contact.type == 'national') {
                                vm.numbers.push(contact);
                            }
                        });

                        vm.haveNumbers = !!vm.numbers.length;

                        vm.cleanup();
                    }
                }
                return vm.numbers;
            };


      /**
       * @memberof bdpDetailsContactPhone
       * @method formatNumber
       * @name formatNumber
       * @description Format phone number with spaces.
       * @param {String} _phoneNumber The raw phone number
       * @returns {String} _formattedPhoneNumber  Formated number
       */
            vm.formatNumber = function(_phoneNumber) {
                var _formattedPhoneNumber = '';
                if (angular.isUndefined(_phoneNumber) || !_phoneNumber) {
                    return '';
                }

                if (_phoneNumber.length == 10) {
                    if (_phoneNumber.startsWith('04') || _phoneNumber.startsWith('1300') ||
            _phoneNumber.startsWith('1800') || _phoneNumber.startsWith('1900')) {
                        _formattedPhoneNumber += _phoneNumber.substr(0, 4) + ' ' + _phoneNumber.substr(4, 3) + ' ' + _phoneNumber.substr(7, 3);
                    } else {
                        _formattedPhoneNumber += '(' + _phoneNumber.substr(0, 2) + ') ';
                        _formattedPhoneNumber += _phoneNumber.substr(2, 4) + ' ' + _phoneNumber.substr(6);
                    }
                } else if (_phoneNumber.length == 6) {
                    _formattedPhoneNumber += _phoneNumber.substr(0, 2) + ' ' + _phoneNumber.substr(2, 2) + ' ' + _phoneNumber.substr(4, 2);
                } else {
                    _formattedPhoneNumber = _phoneNumber;
                }
                return _formattedPhoneNumber;
            };
        }
    }
})();
