(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name reviewsService
     * @description reviewsService provides methods that handles logic for liking, disliking, reporting and adding
     * comments to review.
     */
    .factory('reviewsService', reviewsService);

  /** @ngInject */
    function reviewsService($log, $http, $modal, apiConfig, ModalLikeDislikeFactory, trackingConstants, DTM, windowScroll, $q, errorService) {
        var reviewService = {
            likeReview: likeReview,
            dislikeReview: dislikeReview,
            reportReview: reportReview,
            addComment: addComment,
            getTradies: getTradies,
        };

        function likeReview(id, data, actionInProgress) {
      // $httpProvider.defaults.headers.post = {'Content-Type': 'text/plain'};
            return $http.post(apiConfig.generate('reviews', id, 'helpful'), null, {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
        .then(likeReviewSuccess)
        .catch(likeReviewFailed);

            function likeReviewSuccess(response) {
                var _dataFeedback = response.data.data;

                if(_dataFeedback == 'ADDED'){
                    data.statisticsLike += 1;
                } else if (_dataFeedback == 'REMOVED'){
                    data.statisticsLike -= 1;
                } else if (_dataFeedback == 'REPLACED'){
                    data.statisticsLike += 1;
                    data.statisticsDislike -= 1;
                }

                actionInProgress = false;
                DTM.trackAnalyticOnClick(trackingConstants.reviewsConstants.likeButton);
            }


            function likeReviewFailed(error) {
                var message = '';
                if (error.status == '400') {
                    message = 'XHR Already voted ';
                    var _actionInProgress = false;
                    angular.copy(_actionInProgress, actionInProgress);
                    $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                        controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                        resolve: {
                            storedData: function() {
                                return errorService.getErrorMessage(error);
                            },
                        },
                    });
                }
                message = message + id + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
            }
        }

        function dislikeReview(id, data, actionInProgress) {
      // $httpProvider.defaults.headers.post = {'Content-Type': 'text/plain'};
            return $http.post(apiConfig.generate('reviews', id, 'unhelpful'), null, {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
        .then(dislikeReviewSuccess)
        .catch(dislikeReviewFail);

            function dislikeReviewSuccess(response) {
                var _dataFeedback = response.data.data;

                if(_dataFeedback == 'ADDED'){
                    data.statisticsDislike += 1;
                } else if (_dataFeedback == 'REMOVED'){
                    data.statisticsDislike -= 1;
                } else if (_dataFeedback == 'REPLACED'){
                    data.statisticsDislike += 1;
                    data.statisticsLike -= 1;
                }

                var _actionInProgress = false;
                angular.copy(_actionInProgress, actionInProgress);
                DTM.trackAnalyticOnClick(trackingConstants.reviewsConstants.dislikeButton);
            }


            function dislikeReviewFail(error) {
                var message = '';
                var _actionInProgress = false;
                angular.copy(_actionInProgress, actionInProgress);
                if (error.status == '400') {
                    message = 'XHR Already voted ';

                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                        controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                        resolve: {
                            storedData: function() {
                                return error.data.meta.messages[0];
                            },
                        },
                    });

                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });
                }
                message = message + id + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
            }
        }

        function reportReview(id, data, type, reason, isActive) {
            if (angular.isUndefined(reason) || !reason) {
                reason = '';
            }

      // $httpProvider.defaults.headers.post = {'Content-Type': 'text/plain'};
            return $http.post(apiConfig.generate(type, id, 'report'), {reason: reason}, {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
        .then(reportReviewSuccess)
        .catch(reportReviewFailed);

            function reportReviewSuccess(response) {
                var _data = (response.data.meta.messages[0] == 'SUCCESS') ? true : false;

                if (_data) {
                    data.reported = true;
                }
                if (type!='reviews') {
                    DTM.trackAnalyticOnClick(trackingConstants.reviewsConstants.reportCommentComplete);
                }
                return {status: 'success'};
            }


            function reportReviewFailed(error) {
                var _code = null;
                if (error.data && error.data.meta && error.data.meta.code) {
                    _code = error.data.meta.code;
                }

                return {status: 'error', status_code: error.status, code: _code, data: error};
            }
        }

        function addComment(id, options) {
            if (angular.isUndefined(options)) {
                options = {};
            }

            return $http.post(apiConfig.generate('reviews', id, 'comments', 'add'), options, {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
        .then(addCommentComplete)
        .catch(addCommentFailed);

            function addCommentComplete(response) {
                var _data = response.data.data.comment[0],
                    _config = response.config;
                DTM.trackAnalyticOnClick(trackingConstants.reviewsConstants.writeCommentComplete);
                return {status: 'success', data: _data, config: _config};
            }


            function addCommentFailed(error) {
                return {status: 'error', status_code: error.status, code: error.data.meta.code, data: error};
            }
        }

    /**
     *
     * @name getTradies
     * @description returns a promise with get Tradies
     */
        function getTradies(city) {
            var path = apiConfig.generate('listings') + apiConfig.getXAuthToken() + '&type=campaign&location=' + city + '&campaign=nofacelessquotes';
            return $http.get(path);
        }

        return reviewService;
    }
})();
