(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name bdpHeaderSearchForm
         * @description Directive displays search form that persist in main page header on all pages, enabling user to
         * search for a business. User can search business by business name and address. If address is left empty then
         * it is searched in whole Australia. While typing users see search suggestions that are displayed in dropdown
         * bellow search box.
         *
         * @param {service}   $timeout                   Angular window.setTimeout service
         * @param {service}   $window                    Angular window wrapper
         * @param {service}   searchConfigManager        searchConfigManager service expose methods for manipulation with search results data. Results data can be shown in three main view options (list, grid and map view), whose look vary with change of screen size, access platform and number of results. searchConfigManager provide data for desired view option.
         * @param {factory}   DTM            TL tracking services
         * @param {factory}   trackTradies               TL tracking services
         * @param {service}   $location                  Angular window.location service wrapper
         * @param {service}   HeaderSearchDropDownSevice Service for handling position of the directive in homepage
         * @param {constant}  env                        Routing configuration
         * @param {factory}   platformService            Factory service that handle detection of platform and screen size that current user use
         * @param {service}   Geo                        Service for handling google geocode location from user readed geolocation
         * @param {service}   $cookies                  angular cookies service wrapper
         *
         *
         * @example
         * <bdp-header-search-form data-placeholder-live="[bool]"></bdp-header-search-form>
         */
        .directive('bdpHeaderSearchForm', bdpHeaderSearchForm)


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name ngSearchInputChange
         * @description Directive initiate gathering of search suggestions from API based on current user input while
         * user typing.
         *
         *
         */
        .directive('ngSearchInputChange', ngSearchInputChange)


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name ngGoInputSearch
         * @description Directive return focus on search box when suggestion is appeared.
         */
        .directive('ngGoInputSearch', ngGoInputSearch)

        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name setFocusIf
         * @description Directive set focus on field.
         */

        .directive('setFocusIf', function($interval) {
            return {
                link: function($scope, $element, $attr) {
                    $scope.$watch($attr.setFocusIf, function(value) {
                        if (value) {
                            $interval(function() {
                                $element[0].focus();
                            }, 500);
                        }
                    });
                },
            };
        })

        /**
         * @memberof truelocal
         * @ngdoc service
         * @name HeaderSearchDropDownSevice
         * @description HeaderSearchDropDownSevice provides methods for gathering of search suggestions from API.
         */
        .factory('HeaderSearchDropDownSevice', HeaderSearchDropDownSevice);


    /**
     * @memberof truelocal
     * @method String.replaceSpecialCharacters
     * @name String.replaceSpecialCharacters
     * @description Extend string prototype with replaceSpecialCharacters method that enable encoding string into
     * ASCII encoding, URL compatible string.
     */
    String.prototype.replaceSpecialCharacters = function(_watch) {
        var _generatedWord = this.replace(new RegExp('[/]|[\\\\]', 'gi'),
            ' ');
        _generatedWord = _generatedWord.replace(new RegExp('\\s+&\\s+',
            'gi'), ' and ');
        // Removed replacement of parentheses in the window.location URL because this will result to double encoding of percentage sign
        /*    
        _generatedWord = _generatedWord.replace(new RegExp('[(]', 'gi'),
            '%28');
        _generatedWord = _generatedWord.replace(new RegExp('[)]', 'gi'),
            '%29');
        */
        // _generatedWord = _generatedWord.replace(new RegExp("[']", "gi"), "%27");
        // _generatedWord = _generatedWord.replace(new RegExp("[\\$]", "gi"), "%24");
        // cannot reuse this to encode keyword with # character because it only encodes the window.location URL and not the API request URL
        // this does not work with Load More/Previous Results button click
        // _generatedWord = _generatedWord.replace(new RegExp("[\\#]", "gi"), "%23");
        // _generatedWord = _generatedWord.replace(new RegExp("[,]", "gi"), "%2c");
        _generatedWord = _generatedWord.replace(new RegExp("(\\d+)%", "gi"), "$1%25");
        _generatedWord = _generatedWord.replace(new RegExp(
            '[^a-zA-Z0-9\\%\\-\\s.\\,\\$\\#\\\'\\,\\@\\+]', 'g'
        ), ' ');
        _generatedWord = _generatedWord.trim();
        if (angular.isUndefined(_watch) && _watch != true) {
            _generatedWord = _generatedWord.replace(new RegExp('[\\-]',
                'gi'), '_');
        }
        _generatedWord = _generatedWord.replace(new RegExp('\\s+', 'gi'),
            '-');
        _generatedWord = _generatedWord.replace(new RegExp(',', 'g'),
            '-');
        _generatedWord = _generatedWord.replace(new RegExp(
                '[^a-zA-Z0-9\\%\\-\\s.\\,\\$\\#\\\'\\,\\@\\+]$'),
            ' ');
        _generatedWord = _generatedWord.replace(new RegExp('-+', 'g'),
            '-');
        _generatedWord = _generatedWord.replace(new RegExp('_+', 'g'),
            '_');

        return _generatedWord;
    };

    function ngGoInputSearch() {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                scope.vm.focusSearchButton = angular.bind(this,
                    function(_el) {
                        angular.element(_el)[0].focus();
                    }, elem);
            },
            scope: {
                vm: '=',
            },
        };
    }

    function ngSearchInputChange($log, $timeout, $filter, autoComplete,
        HeaderSearchDropDownSevice) {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                if (angular.element(elem).attr('name') != 'LOCATION') {
                    scope.vm.searchCategory = angular.bind(this,
                        function(_s, _e, _value) {
                            _s.vm.keyword = _value;
                            angular.element(_e).focus();
                            _startAutoSearch(scope, {
                                keyCode: 0,
                            });
                        }, scope, elem);
                }

                var _generateUrl = function(_data) {
                    // var _redirectToLive = "www.neon.for.truelocal.com.au";
                    var _redirectToLive = '';
                    var _baseUrl = '/search/';
                    var _term = _data.term.toLowerCase().replaceSpecialCharacters();
                    var _location = '';

                    if (_data.searchModel == 'location') {
                        _baseUrl = '/search-location/';
                    }

                    if (_data.type == 'Business' && _data.locations
                        .length == 1) {
                        // _redirectToLive = "";
                        _baseUrl = '/search/';
                        _term = _data.term.toLowerCase().replaceSpecialCharacters(
                            true);

                        var _locationStr = '';
                        _locationStr += (_data.locations[0].suburb !=
                                'null') ? _data.locations[0].suburb :
                            '';
                        _locationStr += (_locationStr) ? ((_data.locations[
                                0].state != 'null') ? ', ' +
                            _data.locations[0].state : '') : '';
                        _locationStr += (_locationStr) ? '' :
                            'Australia';

                        _location += '/' + _locationStr.replaceSpecialCharacters(
                            true);
                    } else if ((_data.type == 'Business' && (!_data
                            .locations || _data.locations.length >
                            1)) || _data.type != 'Business') {
                        return {
                            locations: _data.locations,
                            term: _data.term,
                        };
                    }

                    var _searchUrl = _redirectToLive + _baseUrl +
                        _term + _location;
                    return {
                        pathonly: (_redirectToLive) ? false : true,
                        url: _searchUrl,
                    };
                };

                var _wrapTheValue = function(_original, _model, _type) {
                    var _originalToLower = _original.toLowerCase(),
                        _modelToLower = _model.toLowerCase(),
                        _indexOfSearch = _originalToLower.indexOf(
                            _modelToLower),
                        _lengthOfSearchedModel = _modelToLower.length,
                        _firstBlock, _searchedBlock, _lastBlock,
                        _converted;

                    if (_indexOfSearch >= 0 && _type != 'Category') {
                        _firstBlock = _original.substr(0,
                            _indexOfSearch);
                        _searchedBlock =
                            '<span class=\'link-color\'>' +
                            _original.substr(_indexOfSearch,
                                _lengthOfSearchedModel) + '</span>';
                        _lastBlock = _original.substr(
                            _indexOfSearch +
                            _lengthOfSearchedModel, _original.length
                        );
                        _converted = _firstBlock + _searchedBlock +
                            _lastBlock;
                    } else if (_type != 'Category') {
                        var _separatedModels = _modelToLower.replace(
                            /[^a-zA-Z0-9]/g, '').trim().split(
                            ' ');

                        var _searchBlocks = [];

                        for (var i = 0, l = _separatedModels.length; i <
                            l; i++) {
                            var _wordChars = _separatedModels[i].split(
                                    ''),
                                _regexpString = _wordChars.join(
                                    '([\\[\\]\\.\/\'\\|&\:;\\$%@"<>()\\+,\\!\\?\\*\\-\\=\\_ ])?'
                                ),
                                _regexp = new RegExp(_regexpString,
                                    'gi');

                            if (_wordChars.length > 1) {
                                _searchBlocks.push(_regexp);
                            }
                        }

                        for (var i2 = 0, l2 = _searchBlocks.length; i2 <
                            l2; i2++) {
                            _original = _original.replace(
                                _searchBlocks[i2],
                                function(match) {
                                    return '<span class=\'link-color\'>' +
                                        match + '</span>';
                                });
                        }
                        _converted = _original;
                    } else {
                        _converted = _original;
                    }

                    return _converted;
                };

                var _sortAndCleanUp = function(_s, _respData) {
                    var _dataInputModel = angular.element(elem).attr(
                            'name').toLowerCase(),
                        _dataListModel = 'sortedObject' +
                        _dataInputModel,
                        _dataSizeModel = _dataInputModel + 'Size';

                    _s.vm[_dataListModel] = null;
                    _s.vm[_dataSizeModel] = 0;

                    if (_respData && _respData.length) {
                        if (_dataInputModel == 'keyword') {
                            _s.vm[_dataListModel] = {
                                Keywords: [],
                                Business: [],
                            };
                        } else if (_dataInputModel == 'location') {
                            _s.vm[_dataListModel] = {
                                Suburb: [],
                                Regions: [],
                            };
                        }

                        var orderBy = $filter('orderBy');

                        var _ordered = orderBy(_respData, 'type',
                            false);

                        for (var i = 0, l = _ordered.length; i < l; i++) {
                            var _catergoryType = _ordered[i].type;
                            _catergoryType = _catergoryType ==
                                'Business' ? _catergoryType :
                                (_catergoryType != 'Suburb' &&
                                    _catergoryType != 'Region') ?
                                'Keywords' : _catergoryType;

                            if (!_s.vm[_dataListModel][
                                    _catergoryType
                                ]) {
                                _s.vm[_dataListModel][
                                    _catergoryType
                                ] = [];
                            }

                            _ordered[i].searchModel =
                                _dataInputModel;
                            _ordered[i].wrapped = _wrapTheValue(
                                _ordered[i].term, _s.vm[
                                    _dataInputModel],
                                _catergoryType);
                            _ordered[i].searchUrl = _generateUrl(
                                _ordered[i]);
                            _s.vm[_dataListModel][_catergoryType].push(
                                _ordered[i]);
                        }
                        for (var k in _s.vm[_dataListModel]) {
                            if (_s.vm[_dataListModel][k].length ==
                                0) {
                                delete _s.vm[_dataListModel][k];
                            }
                        }

                        if (Object.keys(_s.vm[_dataListModel]).length ==
                            0) {
                            _s.vm[_dataListModel] = null;
                        }

                        var _indCover = 0;
                        for (var kk in _s.vm[_dataListModel]) {
                            for (var i3 = 0, l3 = _s.vm[
                                    _dataListModel][kk].length; i3 <
                                l3; i3++) {
                                _s.vm[_dataListModel][kk][i3].localId =
                                    _indCover;
                                _indCover++;
                            }
                        }

                        _s.vm[_dataSizeModel] = _respData.length;
                        _s.vm.focusedSize = _respData.length;
                    }
                };

                // scope.vm.

                scope.vm.sortAndCleanUp = angular.bind(scope, function(
                    _s, _e, _respData, _forceFocus) {
                    var _dataInputModel = angular.element(_e).attr(
                            'name').toLowerCase(),
                        _dataListModel = 'sortedObject' +
                        _dataInputModel,
                        _dataSizeModel = _dataInputModel +
                        'Size';

                    _s.vm[_dataListModel] = null;
                    _s.vm[_dataSizeModel] = 0;

                    if (_respData && _respData.locations &&
                        _respData.locations.length) {
                        _s.vm[_dataListModel] = {
                            'Suggestions': [],
                        };

                        for (var i = 0, l = _respData.locations
                                .length; i < l; i++) {
                            _respData.locations[i].localId = i;
                            _respData.locations[i].searchModel =
                                'Business';

                            var _locationStr = '';
                            _locationStr += (_respData.locations[
                                    i].suburb != 'null') ?
                                _respData.locations[i].suburb :
                                '';
                            _locationStr += (_locationStr) ? ((
                                    _respData.locations[i].state !=
                                    'null') ? ', ' +
                                _respData.locations[i].state :
                                '') : '';
                            _locationStr += (_locationStr) ? '' :
                                'Australia';

                            _respData.locations[i].wrapped =
                                _locationStr;
                            _respData.locations[i].searchUrl =
                                _generateUrl({
                                    type: 'Business',
                                    locations: [_respData.locations[
                                        i]],
                                    term: _respData.term,
                                });
                            _s.vm[_dataListModel].Suggestions.push(
                                _respData.locations[i]);
                        }

                        for (var k in _s.vm[_dataListModel]) {
                            if (_s.vm[_dataListModel][k].length ==
                                0) {
                                delete _s.vm[_dataListModel][k];
                            }
                        }

                        if (Object.keys(_s.vm[_dataListModel]).length ==
                            0) {
                            _s.vm[_dataListModel] = null;
                        }

                        _s.vm[_dataSizeModel] = _respData.locations
                            .length;
                        _s.vm.focusedSize = _respData.locations
                            .length;
                    }

                    if (_forceFocus) {
                        $timeout(angular.bind(this, function(
                            __e) {
                            angular.element(__e)[0].focus();
                        }, _e), 10);
                    }
                }, scope, elem);

                var _timeout = null;
                var _fetchDataFromAPI = function(_s) {
                    var _type = angular.element(elem).attr('name'),
                        _termValue = _type.toLowerCase(),
                        _dataListModel = 'sortedObject' +
                        _termValue,
                        _dataSizeModel = _termValue + 'Size';

                    _s.vm.setCurrentSelected(null, null, null);

                    var _clearVerLenString = _s.vm[_termValue].replace(
                        /[^a-zA-Z0-9]/g, '+').trim();

                    if (!_s.vm[_termValue] || _clearVerLenString.length <
                        4) {
                        _s.vm[_dataListModel] = null;
                        _s.vm[_dataSizeModel] = 0;
                        _s.$apply();
                        return;
                    }

                    autoComplete
                        .search({
                            type: _type,
                            term: _clearVerLenString,
                        })
                        .then(angular.bind(this, _sortAndCleanUp,
                            scope));
                };

                var _startAutoSearch = function(_s, e) {
                    HeaderSearchDropDownSevice.setEnabled();
                    var _type = angular.element(elem).attr('name'),
                        _termValue = _type.toLowerCase(),
                        _dataInputModel = _type.toLowerCase(),
                        _dataListModel = 'sortedObject' +
                        _dataInputModel,
                        _dataSizeModel = _dataInputModel + 'Size';

                    if (e.keyCode >= 16 && e.keyCode <= 20 || e.keyCode >=
                        33 && e.keyCode < 38 || e.keyCode == 39) {
                        return;
                    }

                    if (_s.vm[_termValue].length == 0) {
                        if (e.keyCode == 16 || e.keyCode == 17 || e
                            .keyCode == 18 ||
                            e.keyCode == 19 || e.keyCode == 27 || e
                            .keyCode == 33 ||
                            e.keyCode == 34 || e.keyCode == 35 || e
                            .keyCode == 36 ||
                            e.keyCode == 37 || e.keyCode == 39 || e
                            .keyCode == 45 ||
                            e.keyCode == 46 || e.keyCode == 32) {
                            e.preventDefault();
                            e.stopPropagation();
                            return;
                        }
                    } else {
                        var _strLength = _s.vm[_termValue].length;
                        if (_s.vm[_termValue][_strLength - 1] ==
                            ' ' && e.keyCode == 32) {
                            e.preventDefault();
                            e.stopPropagation();
                            return;
                        }
                    }

                    if (e.keyCode == 9) {
                        return;
                    }

                    if (e.keyCode == 38) {
                        if (_s.vm.getCurrentSelected() != null) {
                            _s.vm.setCurrentSelected(null, null,
                                true);
                            _s.$apply();
                        }
                        return;
                    }

                    if (e.keyCode == 40) {
                        _s.vm.setCurrentSelected(null, true, null);
                        _s.$apply();
                        return;
                    }

                    if (e.keyCode == 8 || (e.ctrlKey && e.keyCode ==
                            88)) {
                        var selectedText = null;
                        if (window.getSelection) {
                            selectedText = window.getSelection();
                        } else if (document.getSelection) {
                            selectedText = document.getSelection();
                        } else if (document.selection) {
                            selectedText = document.selection.createRange()
                                .text;
                        }

                        if (selectedText != '') {
                            selectedText = selectedText.toString();
                            if (selectedText.length == _s.vm[
                                    _termValue].length) {
                                _s.vm[_dataListModel] = null;
                                _s.vm[_dataSizeModel] = 0;
                                _s.vm[_termValue] = '';
                                _s.vm.focusedSize = 0;
                                _s.$apply();
                            }
                        }
                    }

                    if (_timeout) {
                        clearTimeout(_timeout);
                        _timeout = null;
                    }

                    _timeout = setTimeout(angular.bind(null,
                        _fetchDataFromAPI, _s), 200);
                };

                angular.element(elem).on('keydown', angular.bind(null,
                    _startAutoSearch, scope));
            },
            scope: {
                vm: '=',
            },
        };
    }

    /** @ngInject */
    function bdpHeaderSearchForm() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/bdpheadersearchform/bdpheadersearchform.html',
            controller: BdpHeaderSearchFormController,
            scope: {
                isHome: '=',
                tradieCity: '=',
            },
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
        /**
         * @memberof bdpHeaderSearchForm
         * @method bdpHeaderSearchForm_BdpHeaderSearchFormController
         * @name bdpHeaderSearchForm_BdpHeaderSearchFormController
         * @description Directive controller
         *
         * @param {service}   $timeout                   Angular window.setTimeout service
         * @param {service}   $window                    Angular window wrapper
         * @param {service}   searchConfigManager        searchConfigManager service expose methods for manipulation with search results data. Results data can be shown in three main view options (list, grid and map view), whose look vary with change of screen size, access platform and number of results. searchConfigManager provide data for desired view option.
         * @param {factory}   DTM            TL tracking services
         * @param {factory}   trackTradies               TL tracking services
         * @param {service}   $location                  Angular window.location service wrapper
         * @param {service}   HeaderSearchDropDownSevice Service for handling position of the directive in homepage
         * @param {constant}  env                        Routing configuration
         * @param {factory}   platformService            Factory service that handle detection of platform and screen size that current user use
         * @param {service}   Geo                        Service for handling google geocode location from user readed geolocation
         * @param {service}   $cookies                  angular cookies service wrapper
         *
         */


        /** @ngInject */
        function BdpHeaderSearchFormController($timeout, $window,
            searchConfigManager, DTM, $location, HeaderSearchDropDownSevice,
            env, platformService, Geo, $cookies, $log, $state, $rootScope, $document) {
            $log.debug('BdpHeaderSearchFormController loaded');
            var vm = this;
            this.placeholderKeyword = (this.isHome || this.tradieCity) ?
                'What are you looking for?' : 'Search for...';
            this.placeholderLocation = (this.isHome || this.tradieCity) ?
                'Suburb, city or postcode' : 'Location';

            /**
             * @memberof bdpHeaderSearchForm
             * @name location
             * @description location (determined from geolocation if posible, and after search stored into a cookie as default location)
             */

            if ($cookies.get('tl_s_loc')) {
                this.location = $cookies.get('tl_s_loc');
            } else {
                this.location = searchConfigManager.getProperty('location',
                    'suburb', 'region', 'state').capitalizeSentence().capitalizeSuburb();
            }

            /**
             * @memberof bdpHeaderSearchForm
             * @name keyword
             * @description Keyword placeholder
             */

            this.keyword = searchConfigManager.getProperty('keyword',
                'category').capitalizeSentence();

            this.sizeForMobile = function() {
                var _height30Perc = '';
                var _autocompleteSearchHeight = angular.element(
                    document.querySelector('#autocomplete-search'))[0].clientHeight;
                var _isChrome = platformService.isChrome();
                var _isSafari = platformService.isSafari();
                var _bodyWindowEl = (_isChrome || _isSafari) ? angular.element(
                    document.querySelector('body,window')) : angular.element(document.querySelector('body,html'));
                var _halfSize = _bodyWindowEl[0].clientHeight / 2;
                if (platformService.getWidth() < 768) {
                    _height30Perc = _halfSize - 172;

                    if (_height30Perc < 130) {
                        _height30Perc = 130;
                    }

                    _height30Perc /= 10;
                    _height30Perc = Math.floor(_height30Perc);
                    _height30Perc += 'rem';

                    return {
                        'height': _height30Perc,
                    };
                }
                return {};
            };

            angular.element($window).on('update-search-fields', angular.bind(
                this,
                function() {
                    this.location = searchConfigManager.getProperty(
                        'location', 'suburb', 'region', 'state'
                    ).capitalizeSentence().capitalizeSuburb();
                    this.keyword = searchConfigManager.getProperty(
                        'keyword', 'category').capitalizeSentence();
                }));

            angular.element($window).on('clear-search-fields', angular.bind(
                this,
                function() {
                    searchConfigManager.setProperty('location', '');
                    searchConfigManager.setProperty('keyword', '');
                    this.keyword = '';
                    this.location = '';
                }));

            if (angular.isDefined(this.keyword)) {
                this.keyword = this.keyword.capitalizeSentence();
            }

            if (vm.tradieCity) {
                switch (vm.tradieCity) {
                    case 'sydney':
                        vm.currentCity = 'Sydney, NSW';
                        break;
                    case 'melbourne':
                        vm.currentCity = 'Melbourne, VIC';
                        break;
                    case 'brisbane':
                        vm.currentCity = 'Brisbane, QLD';
                        break;
                }
                searchConfigManager.setProperty('location', vm.currentCity);
                this.location = vm.currentCity;
            }

            this.sortedObjectkeyword = null;
            this.sortedObjectlocation = null;
            this.keywordSize = 0;
            this.locationSize = 0;
            this.focusedSize = 0;

            /**
             * @memberof bdpHeaderSearchForm
             * @method startSearch
             * @name startSearch
             * @description Launch page redirection with search url formed according to rules
             * @returns Location Redirect
             */
            this.startSearch = angular.bind(this, function(e) {
                e.preventDefault();
                /** IPad cover for fixing the issue with keyboard*/
              if(angular.isDefined(angular.element($document[0].activeElement))){
                angular.element($document[0].activeElement)[0].blur();
              }

                if (!this.location.toLowerCase() && this.listItemSelected === null) {
                    this.location = 'Australia';
                }

                if (this.listItemSelected !== null) {
                    var _selectedUrl = '';
                    if (_keywordInputState) {
                        for (var m in this.sortedObjectkeyword) {
                            for (var i = 0, l = this.sortedObjectkeyword[
                                    m].length; i < l; i++) {
                                if (this.sortedObjectkeyword[m][i].localId ==
                                    this.listItemSelected) {
                                    _selectedUrl = this.sortedObjectkeyword[
                                        m][i];
                                }
                            }
                        }
                    } else if (_locationInputState) {
                        for (var m2 in this.sortedObjectlocation) {
                            for (var i2 = 0, l2 = this.sortedObjectlocation[
                                    m2].length; i2 < l2; i2++) {
                                if (this.sortedObjectlocation[m2][
                                        i2
                                    ].localId == this.listItemSelected) {
                                    _selectedUrl = this.sortedObjectlocation[
                                        m2][i2];
                                }
                            }
                        }
                    }
                    if (_selectedUrl) {
                        if (_selectedUrl.searchUrl && _selectedUrl.searchUrl
                            .locations) {
                            this.populateFields(_selectedUrl.searchUrl);
                            return;
                        } else {
                            var _locationStr = '';
                            if (_keywordInputState) {
                                this.keyword = _selectedUrl.term;
                                if (_selectedUrl.locations &&
                                    _selectedUrl.locations.length) {
                                    var _locationBlock =
                                        _selectedUrl.locations[0];
                                    _locationStr += (_locationBlock
                                            .suburb != 'null') ?
                                        _locationBlock.suburb : '';
                                    _locationStr += (_locationStr) ?
                                        ((_locationBlock.state !=
                                                'null') ? ', ' +
                                            _locationBlock.state :
                                            '') : '';
                                    _locationStr += (_locationStr) ?
                                        '' : 'Australia';
                                    this.location = _locationStr;
                                }
                            } else if (_locationInputState) {
                                if (_selectedUrl.term) {
                                    this.location = _selectedUrl.term;
                                } else {
                                    _locationStr += (_selectedUrl.suburb !=
                                            'null') ? _selectedUrl.suburb :
                                        '';
                                    _locationStr += (_locationStr) ?
                                        ((_selectedUrl.state !=
                                                'null') ? ', ' +
                                            _selectedUrl.state : ''
                                        ) : '';
                                    _locationStr += (_locationStr) ?
                                        '' : 'Australia';
                                    this.location = _locationStr;
                                }
                            }
                            this.focusSearchButton();
                            return;
                        }
                    }
                }

                var _redirectToLive = '';
                var _baseUrl = '/search/';
                var _searchingUrl = '';

                var _keyword = this.keyword.toLowerCase().replaceSpecialCharacters();
                var _location = this.location.toLowerCase().replaceSpecialCharacters();

                if (_keyword) {
                    _searchingUrl += _redirectToLive + _baseUrl +
                        _keyword;
                }

                if (_location) {
                    if (!_searchingUrl) {
                        _baseUrl = '/search-location/';
                        _searchingUrl += _redirectToLive + _baseUrl +
                            _location;
                    } else {
                        _searchingUrl += '/' + _location;
                    }
                }
                DTM.trackAnalyticOnClick('search submit');
                $rootScope.$emit('search-submit', {
                    keyword: _keyword,
                });


                // window.location = _searchingUrl;
                // send kill signal for the dropdown list
                HeaderSearchDropDownSevice.setDisable();
                angular.element(document.querySelector('#header--search-submit'))[0].focus();
                $cookies.put('tl_s_loc', this.location, {
                    path: '/',
                });
                if (env.states.search === true) {
                    $location.path(_searchingUrl).search('page',
                        null);
                } else {
                    (!vm.tradieCity) ? $location.path(_searchingUrl):
                        window.open(_searchingUrl, '_blank');
                }
            });

            /**
             * @memberof bdpHeaderSearchForm
             * @method focusButtonSearch
             * @name focusButtonSearch
             * @description Action for focus search button. Automaticaly settup location if not selected from dropdown
             */
            this.focusButtonSearch = function(_selectedItem) {
                if (angular.isUndefined(_selectedItem)) {
                    _selectedItem = {};
                }
                var _locationStr = '';
                if (_selectedItem.locations && _selectedItem.locations.length) {
                    this.keyword = _selectedItem.term;
                    _selectedItem = _selectedItem.locations[0];
                }

                if (_selectedItem) {
                    _locationStr += (_selectedItem.suburb != 'null') ?
                        _selectedItem.suburb : '';
                    _locationStr += (_locationStr) ? ((_selectedItem.state !=
                            'null') ? ', ' + _selectedItem.state :
                        '') : '';
                    _locationStr += (_locationStr) ? '' : 'Australia';
                }

                this.location = _locationStr;
                this.focusSearchButton();
            };


            /**
             * @memberof bdpHeaderSearchForm
             * @method populateFields
             * @name populateFields
             * @description Populate dropdowns
             * single result location , populate location and focus "go"
             * multy result location, populate location and focus location input
             * if only location selected , populate location field and focus "go"
             */
            this.populateFields = function(_dataObj) {
                // single result location , populate location and focus "go"
                // multy result location, populate location and focus location input
                // if only location selected , populate location field and focus "go"

                if (!_dataObj.locations || _dataObj.locations.length ==
                    0) {
                    this.location = _dataObj.term;
                    this.focusSearchButton();
                } else {
                    if (_dataObj.locations.length == 1) {
                        var _locationObj = _dataObj.locations[0];
                        var _locationStr = '';
                        _locationStr += (_locationObj.suburb != 'null') ?
                            _locationObj.suburb : '';
                        _locationStr += (_locationStr) ? ((_locationObj
                                .state != 'null') ? ', ' +
                            _locationObj.state : '') : '';
                        _locationStr += (_locationStr) ? '' :
                            'Australia';

                        if (_locationStr != 'Australia') {
                            this.location = _locationStr;
                        }

                        this.keyword = _dataObj.term;
                        this.focusSearchButton();
                    } else if (_dataObj.locations.length > 1) {
                        this.keyword = _dataObj.term;
                        this.location = '';
                        this.sortAndCleanUp(_dataObj, true);
                    } else {
                        this.keyword = _dataObj.term;
                        this.focusSearchButton();
                    }
                }
            };

            var _keywordInputState = false,
                _keywordFocusTimeout;

            this.keywordInputState = function(_state, _force) {
                if (_state) {
                    $timeout.cancel(_keywordFocusTimeout);
                    _keywordFocusTimeout = null;
                    _keywordInputState = _state;
                    this.focusedSize = this.keywordSize;
                } else {
                    if (_force) {
                        _keywordInputState = _state;
                        this.listItemSelected = null;
                    } else {
                        _keywordFocusTimeout = $timeout(angular.bind(
                            this,
                            function(__state) {
                                this.listItemSelected = null;
                                _keywordInputState = __state;
                            }, _state), 200);
                    }
                }
            };

            var _locationInputState = false,
                _locationFocusTimeout;

            /**
             * @memberof bdpHeaderSearchForm
             * @method locationInputState
             * @name locationInputState
             * @description setter for the location input state true/false
             */


            this.locationInputState = function(_state, _force) {
                if (_state) {
                    $timeout.cancel(_locationFocusTimeout);
                    _locationFocusTimeout = null;
                    _locationInputState = _state;
                    this.focusedSize = this.locationSize;
                } else {
                    if (_force) {
                        this.listItemSelected = null;
                        _locationInputState = _state;
                    } else {
                        _locationFocusTimeout = $timeout(angular.bind(
                            this,
                            function(__state) {
                                this.listItemSelected = null;
                                _locationInputState = __state;
                            }, _state), 200);
                    }
                }
            };

            /**
             * @memberof bdpHeaderSearchForm
             * @method keywordFocused
             * @name keywordFocused
             * @description getter for the keyword input state true/false
             */


            this.keywordFocused = function() {
                return _keywordInputState;
            };

            /**
             * @memberof bdpHeaderSearchForm
             * @method locationFocused
             * @name locationFocused
             * @description getter for the location input state true/false
             */


            this.locationFocused = function() {
                return _locationInputState;
            };

            this.listItemSelected = null;
            /**
             * @memberof bdpHeaderSearchForm
             * @method isSelected
             * @name isSelected
             * @description getter if selected
             */

            this.isSelected = angular.bind(this, function(_localId) {
                if (this.listItemSelected === null) {
                    return false;
                }
                if (this.listItemSelected == _localId) {
                    return true;
                }
                return false;
            });


            /**
             * @memberof bdpHeaderSearchForm
             * @method getCurrentSelected
             * @name getCurrentSelected
             * @description getter to get current selected
             */

            this.getCurrentSelected = angular.bind(this, function() {
                return this.listItemSelected;
            });

            /**
             * @memberof bdpHeaderSearchForm
             * @method setCurrentSelected
             * @name setCurrentSelected
             * @description setter to select curent
             */

            this.setCurrentSelected = function(_selected, _next, _prev) {
                if (_next || _prev) {
                    if (this.listItemSelected === null) {
                        this.listItemSelected = -1;
                    }

                    if (_next) {
                        this.listItemSelected += 1;
                    } else {
                        this.listItemSelected -= 1;
                    }

                    if (this.listItemSelected >= this.focusedSize) {
                        this.listItemSelected = 0;
                    } else if (this.listItemSelected <= -1) {
                        this.listItemSelected = this.focusedSize - 1;
                    }
                } else {
                    this.listItemSelected = _selected;
                }
            };

            vm.geoLocating = false;
            vm.errorGeoLocating = false;
            /**
             * @memberof bdpHeaderSearchForm
             * @method geoLocate
             * @name geoLocate
             * @description Get user location based on his geolocation from browser
             *
             */

            this.geoLocate = function() {
                if (vm.geoLocating == false) {
                    vm.geoLocating = true;
                    var _locationCallback = function(response) {
                        vm.location = response;
                        vm.geoLocating = false;
                    };

                    Geo.getCoords().then(function(geoLocation) {
                        var currentLocation = Geo.getLocation(
                            geoLocation, _locationCallback);
                    }).catch(function(error) {
                        vm.geoLocating = false;
                        vm.errorGeoLocating = true;
                    });
                }
            };

            angular.element($window).on('renderComplete', function() {
                if(vm.shouldGeoLocate() && platformService.isMobile() && !$cookies.get('tl_s_loc')) {
                    vm.geoLocate();
                }
            });

            var currentPage = $location.url();
            this.shouldGeoLocate = function (){
                if(vm.isHome || currentPage.indexOf('search') > 0 || currentPage.indexOf('find') > 0) {
                    return true;
                }
                return false;
            }

            /**
             * @memberof bdpHeaderSearchForm
             * @method clearKeyword
             * @name clearKeyword
             * @description Clean keyword
             *
             */

            this.clearKeyword = function() {
                vm.keyword = '';
                vm.sortedObjectkeyword = null;
                vm.keywordNeedFocus = true;
                $timeout(function() {
                    vm.keywordNeedFocus = false;
                }, 500);
            };

            /**
             * @memberof bdpHeaderSearchForm
             * @method clearLocation
             * @name clearLocation
             * @description Clean keyword
             *
             */


            this.clearLocation = function() {
                vm.location = '';
                vm.sortedObjectlocation = null;
                vm.locationNeedFocus = true;
                $timeout(function() {
                    vm.locationNeedFocus = false;
                }, 500);
            };
        }
    }

    function HeaderSearchDropDownSevice() {
        var service = {
            status: true,
            getStatus: getStatus,
            setDisable: setDisable,
            setEnabled: setEnabled,
            mobileButtonStatus: false,
            getMobileButtonStatus: getMobileButtonStatus,
            setMobileButtonStatus: setMobileButtonStatus,
        };

        function getStatus() {
            return service.status;
        }

        function setDisable() {
            service.status = false;
        }

        function setEnabled() {
            service.status = true;
        }

        function getMobileButtonStatus() {
            return service.mobileButtonStatus;
        }

        function setMobileButtonStatus(value) {
            service.mobileButtonStatus = value;
        }

        return service;
    }
})();
