(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReviewUserDetails
     * @description Display user details of author of review.
     *
     * @param {service}   moment                    Parse, validate, manipulate, and display dates in JavaScript.
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {constant}  constantsSettings         Constant settings
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {service}   $window                   Angular window wrapper
     * @param {service}   $location                  Angular window.location service wrapper
     *
     * @example
     * <bdp-review-user-details user-data="[object]"></bdp-review-user-details>
     */
    .directive('bdpReviewUserDetails', bdpReviewUserDetails);

  /** @ngInject */
    function bdpReviewUserDetails() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreviewuserdetails/bdpreviewuserdetails.html',
            scope: {
                userData: '=',
            },
            controller: ['moment', 'platformService', 'constantsSettings', '$timeout', '$window',
                         '$location', 'constantsPlaceholders', 'listingModels',
                         BdpReviewUserDetailsController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpReviewUserDetailsController(moment, platformService, constantsSettings, $timeout,
                                                $window, $location, constantsPlaceholders,
                                                listingModels) {
            this.hovered = false;
            this.tooltipHovered = false;

            var _timeoutUser, _timeoutDetails;


      /**
       * @memberof bdpReviewUserDetails
       * @method getUserAvatar
       * @name getUserAvatar
       * @description Returns user avatar url.
       * @returns {String} avatarUrl
       */
            this.getUserAvatar = function() {
                return ( angular.isDefined(this.userData) &&
          angular.isDefined(this.userData.avatars) &&
          angular.isDefined(this.userData.avatars.image) &&
          this.userData.avatars.image.length > 0 &&
          angular.isDefined(this.userData.avatars.image[0]) &&
          angular.isDefined(this.userData.avatars.image[0].urls) &&
          angular.isDefined(this.userData.avatars.image[0].urls.xsmall)

        ) ? this.userData.avatars.image[0].urls.xsmall : constantsPlaceholders.bdpReviewUserDetails.defaultAvatar;
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method getJoinDate
       * @name getJoinDate
       * @description Returns user avatar url.
       * @returns {String} joinDateFormat
       */
            this.getJoinDate = function() {
                if (angular.isUndefined(this.userData) || angular.isUndefined(this.userData.dateCreated)) {
                    return '';
                }
                var _joinDate = moment(this.userData.dateCreated);
                if (!isFinite(_joinDate._d.getTime())) {
                    return '';
                }
                return _joinDate.format(constantsPlaceholders.bdpReviewUserDetails.joinDateFormat);
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method userHasAddress
       * @name userHasAddress
       * @description Returns true if user have address.
       * @returns {Boolean} hasAddress
       */
            this.userHasAddress = function() {
                if (this.getUserAddress() != '') {
                    return true;
                }
                return false;
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method getUserAddress
       * @name getUserAddress
       * @description Returns user address.
       * @returns {String} userAddress
       */
            this.getUserAddress = function() {
                if (angular.isUndefined(this.userData) || angular.isUndefined(this.userData.address)) {
                    return '';
                }
                var _address = '';
                if (angular.isDefined(this.userData.address.suburb) && this.userData.address.suburb != '') {
                    _address += this.userData.address.suburb;
                }
                if (angular.isDefined(this.userData.address.state) && this.userData.address.state != '') {
                    _address += (_address != '') ? ', ' + this.userData.address.state : this.userData.address.state;
                }
                return _address;
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method getUserComments
       * @name getUserComments
       * @description Returns number of user comments.
       * @returns {String} userCommentsNumber
       */
            this.getUserComments = function() {
                return (
          angular.isDefined(this.userData) &&
          angular.isDefined(this.userData.statistics) &&
          angular.isDefined(this.userData.statistics.reviews)
        ) ? this.userData.statistics.reviews : '0';
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method getUserPhotoUploads
       * @name getUserPhotoUploads
       * @description Returns number of user images.
       * @returns {String} userImagesNumber
       */
            this.getUserPhotoUploads = function() {
                return (
          angular.isDefined(this.userData) &&
          angular.isDefined(this.userData.statistics) &&
          angular.isDefined(this.userData.statistics.userImages)
        ) ? this.userData.statistics.userImages : '0';
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method getUserBadges
       * @name getUserBadges
       * @description Returns number of user favourites.
       * @returns {String} userFavouritesNumber
       */
            this.getUserBadges = function() {
                return (
          angular.isDefined(this.userData) &&
          angular.isDefined(this.userData.statistics) &&
          angular.isDefined(this.userData.statistics.favourites)
        ) ? this.userData.statistics.favourites : '0';
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method clickAvatar
       * @name clickAvatar
       * @description Handle clicking on avatar, on mobile will hover, on desktop link to user profile.
       */
            this.clickAvatar = function() {
                if (platformService.isMobile()) {
                    this.hovered = !this.hovered;
                } else {
                    $location.path('/member/' + this.userData.profileUrl);
                }
            };

        /**
         * @memberof bdpReviewUserDetails
         * @method setUserToVisit
         * @name setUserToVisit
         * @description Sets Id of the profile user has clicked
         * @params {String} userId
         */
            this.setUserToVisit = function(userId) {
                listingModels.setUserIdToVisit(userId);
            };

      /**
       * @memberof bdpReviewUserDetails
       * @method avatarBlur
       * @name avatarBlur
       * @description Handle avatar blur on mobile.
       */
            this.avatarBlur = function() {
                if (platformService.isMobile()) {
                    if (this.hovered) {
                        this.hovered = false;
                    }
                }
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method hoverActive
       * @name hoverActive
       * @description Handle avatar hover on desktop.
       * @param {Object} _state
       */
            this.hoverActive = function(_state) {
                if (platformService.isMobile()) {
                    return;
                }

                if (!_state) {
                    if (_timeoutUser) {
                        return;
                    }
                    _timeoutUser = $timeout(angular.bind(this, function() {
                        this.hovered = _state;
                        _timeoutUser = null;
                    }), 200);
                    return;
                }
                if (_timeoutUser) {
                    $timeout.cancel(_timeoutUser);
                    _timeoutUser = null;
                }
                this.hovered = _state;
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method hoverTooltipActive
       * @name hoverTooltipActive
       * @description Show avatar tooltip.
       * @param {Object} _state
       */
            this.hoverTooltipActive = function(_state) {
                if (!_state) {
                    if (_timeoutDetails) {
                        return;
                    }
                    _timeoutDetails = $timeout(angular.bind(this, function() {
                        this.tooltipHovered = _state;
                        _timeoutDetails = null;
                    }), 200);
                    return;
                }
                if (_timeoutDetails) {
                    $timeout.cancel(_timeoutDetails);
                    _timeoutDetails = null;
                }
                this.tooltipHovered = _state;
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method tooltipIsHovered
       * @name tooltipIsHovered
       * @description Check if tooltip is hovered.
       * @returns {Boolean} tooltipIsHovered
       */
            this.tooltipIsHovered = function() {
                return (angular.isDefined(this.tooltipHovered)) ? this.tooltipHovered : false;
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method isHovered
       * @name isHovered
       * @description Check if avatar is hovered.
       * @returns {Boolean} avatarIsHovered
       */
            this.isHovered = function() {
                return (angular.isDefined(this.hovered)) ? this.hovered : false;
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method isLocalStar
       * @name isLocalStar
       * @description Check if user is local star.
       * @returns {Boolean} userIsLocalStar
       */
            this.isLocalStar = function() {
                if (angular.isDefined(this.userData) && angular.isDefined(this.userData.primaryBadge) && angular.isDefined(this.userData.primaryBadge.name) && this.userData.primaryBadge.name == constantsSettings.bdpReviewUserDetails.localStarBadgeName) {
                    return true;
                } else {
                    return false;
                }
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method getUserAvatarStyle
       * @name getUserAvatarStyle
       * @description Return user avatar style.
       * @returns {String} userAvatarStyle
       */
            this.getUserAvatarStyle = function() {
                return {
                    'background': 'url(' + this.getUserAvatar() + ') no-repeat center/cover',
                    '-ms-filter': 'progid:DXImageTransform.Microsoft.AlphaImageLoader(src=\'' + this.getUserAvatar() + '\',sizingMethod=\'scale\')',
                };
            };


      /**
       * @memberof bdpReviewUserDetails
       * @method goToProfile
       * @name goToProfile
       * @description Redirect to user profile.
       */
            this.goToProfile = function() {
                return $location.path('/member/' + this.userData.profileUrl);
            };


      /**
       * @memberof bdpReviewUserDetails
       * @event onWindowScroll
       * @name onWindowScroll
       * @description Not hovered while scrolling.
       */
            angular.element($window).bind('scroll', angular.bind(this, function() {
                if (this.hovered) {
                    this.hovered = false;
                }
            }));
        }
    }
})();
