(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpFeedback
     * @description Directive display temporary banner at the bottom of the page offering user to give feedback on new
     * pages, and provide link to old pages. User can collapse banner, and page should be able to remember banner state
     * in case of page refresh. If feeback is given and user choose to close banner, it will disappear from screen.
     *
     *  @param {service}   $scope                    Angular scope service
     *  @param {service}   $log                      Angular console log wrapper
     *  @param {factory}   apiConfig                 Api configuration factory
     *  @param {service}   $http                      angular http service
     *  @param {constant}  env                       Environmental constants
     *  @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *  @param {constant}  constantsPlaceholders     Constant placeholders
     *  @param {service}   $window                   Angular window wrapper
     */
    .directive('bdpFeedback', bdpFeedback);

  /** @ngInject */
    function bdpFeedback() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpfeedback/bdpfeedback.html',
            scope: {
                show: '=',
                details: '=',
            },

            controller: BdpFeedbackController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    }
  /** @ngInject */
    function BdpFeedbackController($scope, $log, apiConfig, $cookies, $http, env, platformService, constantsPlaceholders, $window, DTM) {
        var vm = this;

        vm.constantsPlaceholders = constantsPlaceholders.bdpFeedback;
        vm.form = false;
        vm.text = '';
        vm.platformService = platformService;
        vm.getBusinessUrl = getBusinessUrl;
        vm.tracking = trackAnalyticOnClick;
        vm.isClosed = false; // !!getCookie('feedbackhide');
        vm.close = close;
        vm.feedbackSended = false; // !!getCookie('feedbackhide');
        vm.sendFeedback = sendFeedback;
        vm.isCollapsed = getCookie('feedbackCollapsed') === 'true' ? true : false;
        vm.collapse = collapse;
        vm._isMobile = platformService.getWidth() < 780;

        function close() {
            vm.isClosed = true;
        }

    /**
     * @memberof bdpFeedback
     * @method sendFeedback
     * @name sendFeedback
     * @description Collapse banner and send feedback.
     */
        function sendFeedback() {
            if (vm.text.length > 0) {
                vm.feedbackSended = true;
                vm.form = false;
                setCookie('feedbackCollapsed', true);
                postToGoogle();
                DTM.trackAnalyticOnClick('beta banner feedback sent');
                vm.text = ''; // clear form
            }
        }

    /**
     * @memberof bdpFeedback
     * @method collapse
     * @name collapse
     * @description Collapse banner.
     */
        function collapse() {
            vm.isCollapsed = !vm.isCollapsed;
            if (vm.isCollapsed) {
                addFooterPadding();
                setCookie('feedbackCollapsed', true);
            } else {
                clearFooterPadding();
                setCookie('feedbackCollapsed', false);
                vm.form = false;
                vm.feedbackSended = false;
            }
        }

    /**
     * @memberof bdpFeedback
     * @method postToGoogle
     * @name postToGoogle
     * @description Send user feedback to google server.
     */
        function postToGoogle() {
            var field3 = vm.text;
            $.ajax({
                url: 'https://docs.google.com/forms/d/1JX1-IfmYObiWjoJk9Rq2c9nMV9yCMd00qw0XjsrSmyA/formResponse',
                data: {
                    'entry.59393157': field3,
                },
                type: 'POST',
                dataType: 'xml',
                statusCode: {
                    0: function() {
            // Success message
                    },
                    200: function() {
            // Success Message
                    },
                },
            });
        }

    /**
     * @memberof bdpFeedback
     * @method getBusinessUrl
     * @name getBusinessUrl
     * @description Get business url.
     * @returns {String}
     */
        function getBusinessUrl() {
            var listing = apiConfig.getListing();
            if (listing != null) {
        // var $url=env;
                return env.url + 'business/' + listing.seoUrl;
            }
            return '#';
        }

        function tracking(id) {
        }
    /**
     * @memberof bdpFeedback
     * @method getCookie
     * @name getCookie
     * @description Get cookie if exist by cookieName.
     * @param {String} cookieName
     * @returns {String}
     */
        function getCookie(cookieName) {
            return $cookies.get(cookieName);
        }

    /**
     * @memberof bdpFeedback
     * @method setCookie
     * @name setCookie
     * @description Set cookie.
     * @param {String} cookieName
     * @param {String} value
     */
        function setCookie(cookieName, value) {
            var expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + 3);
            $cookies.put(cookieName, value, {
                'expires': expireDate,
                'path': '/',
            });
        }

        function removeCookie(cookieName) {
            $cookies.remove(cookieName);
        }

    /**
     * @memberof bdpFeedback
     * @method addFooterPadding
     * @name addFooterPadding
     * @description Add bottom padding to footer to have site index link available on mobile.
     */
        function addFooterPadding() {
            var footer = angular.element(document.querySelector('.footer-bottom'));
            var bottomHolder = angular.element(document.querySelector('.footer-bottom div.footer-holder'));
            bottomHolder.css('padding-bottom', '8.5rem');
        }

    /**
     * @memberof bdpFeedback
     * @method clearFooterPadding
     * @name clearFooterPadding
     * @description Remove bottom padding when not needed.
     */
        function clearFooterPadding() {
            var footer = angular.element(document.querySelector('.footer-bottom'));
            var bottomHolder = angular.element(document.querySelector('.footer-bottom div.footer-holder'));
            bottomHolder.css('padding-bottom', 'inherit');
        }

        setTAreaHgt();

    /**
     * @memberof bdpFeedback
     * @method setTAreaHgt
     * @name setTAreaHgt
     * @description Calculate and set height of text area to hold whole screen.
     */
        function setTAreaHgt() {
            var tArea = angular.element(document.querySelector('#bdp-feedback-textarea'));
            vm._isMobile = angular.element(window)[0].outerWidth < 780;
            if (vm._isMobile) {
                var height = angular.element(window)[0].outerWidth - 180;
                height = height + 'px';
                tArea.css('height', height);
            } else {
                tArea.css('height', 'inherit');
            }
        }

        angular.element($window).bind('resize', function() {
            setTAreaHgt();
        });

    /**
     * @memberof bdpFeedback
     * @method openLink
     * @name openLink
     * @description Open link.
     */
        vm.openLink = function($link, $target) {
            $window.open($link, angular.isDefined($target) ? $target : '_self');
        };
    }
})();
