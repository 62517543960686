(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpUpgrade
     * @description Directive holds page with business owner option to upgrade listing.
     *
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {service}   $http                     angular http service
     * @param {service}   $cookies                  angular cookies service wrapper
     *
     * @example
     * <bdp-upgrade listing="[object]"></bdp-upgrade>
     */
    .directive('bdpUpgrade', BdpUpgrade);

  /** @ngInject */
    function BdpUpgrade() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdpupgrade/bdpupgrade.html',
            scope: {
                bussinessDetails: '=',
                listing: '=',
            },
            controller: ['apiConfig', '$http', '$cookies', BdpUpgradeController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */

        function BdpUpgradeController(apiConfig, $http, $cookies) {
            var vm = this;
            vm.showBanner = false;
            vm.closed = false;
            vm.currentpage = 1;
            vm.phone = '';
            vm.errorMessage = '';
            vm.sending = false;
            vm.phoneInvalid = false;
            vm.bussinessOwnerName = '';


      /**
       * @memberof bdpUpgrade
       * @method getOwnerName
       * @name getOwnerName
       * @description Returns owners name.
       * @returns {String} ownerName
       */
            vm.getOwnerName = function() {
                return apiConfig.userData.data.data.displayName;
            };


      /**
       * @memberof bdpUpgrade
       * @method showUpgradeBanner
       * @name showUpgradeBanner
       * @description Sets showBanner option to true.
       */
            vm.showUpgradeBanner = function() {
                if (!apiConfig.userIsAuthenticated()) {
                    vm.showBanner = false;
                } else {
          // check if user own this bussiness

                    if (angular.isDefined(apiConfig.userData) && angular.isDefined(apiConfig.userData.data) && angular.isDefined(apiConfig.userData.data.data) && $cookies.get('bdpupgrade' + apiConfig.userData.data.data.id)) {
                        if (vm.currentpage == 3) {
                            return true;
                        } else {
                            return false;
                        }
                    } else if (apiConfig.userOwnsBusiness() == 1) {
            // check if user has no paid listings

                        if (apiConfig.userData.data.data.statistics.paidListingsOwned == 0) {
                            vm.showBanner = true;
                        } else {
                            vm.showBanner = false;
                        }
                    } else {
                        vm.showBanner = false;
                    }
                }
                if (vm.closed == true) {
                    vm.showBanner = false;
                }
                return vm.showBanner;
            };


      /**
       * @memberof bdpUpgrade
       * @method closeBanner
       * @name closeBanner
       * @description Sets showBanner option to false.
       */
            vm.closeBanner = function() {
                vm.closed = true;
                vm.showBanner = false;
                vm.currentpage = 1;
                return vm;
            };


      /**
       * @memberof bdpUpgrade
       * @method getUpgradeForm
       * @name getUpgradeForm
       * @description Display upgrade options form.
       */
            vm.getUpgradeForm = function() {
                vm.currentpage = 2;
                return vm.currentpage;
            };


            vm.getFormGroupClass = function() {
                if (vm.phoneInvalid == true) {
                    return 'has-error';
                } else {
                    return '';
                }
            };


      /**
       * @memberof bdpUpgrade
       * @method submitPhone
       * @name submitPhone
       * @description Confirm business phone and handle server respond.
       */
            vm.submitPhone = function() {
                vm.phoneInvalid = false;
                if (vm.sending == true) return false;
                vm.sending = true;
                var data = {
                    phone: vm.phone,
                };
                return $http.post(apiConfig.generate('listings', vm.listing.id, 'upgrade'), data, apiConfig.getXAuthToken())
          .then(function(response) {
              vm.sending = false;
              vm.currentpage = 3;
              $cookies.put('bdpupgrade' + apiConfig.userData.data.data.id, 'true');
          })
          .catch(function(error) {
              if (error.status == '404') {
                  vm.errorMessage = 'Connection could not be established. Please try again!';
              } else if (error.status == '400') {
                  vm.errorMessage = error.data.meta.errors.phone;
              }
              vm.phoneInvalid = true;
              vm.sending = false;
          });
            };
        }
    }
})();
