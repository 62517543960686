(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpFavReview
     * @description Directive displays featured user review.
     *
     * @param  {directive} $modal                    ui-bootstrap modal wrapper
     * @param  {service}   $timeout                  Angular window.setTimeout service
     * @param  {factory}   apiConfig                 Api configuration factory
     * @param  {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.
     * @param  {factory}   authService               Factory what handle API request for authentication
     * @param  {constant}  constantsSettings         Constant settings
     * @param  {constant}  constantsPlaceholders     Constant placeholders
     * @param  {constant}  constantsClasses          Constant classes
     * @param  {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     *
     * @example
     * <bdp-fav-review listing="[object]" category="[object]"></bdp-fav-review>
     *
     */
    .directive('bdpFavReview', bdpFavReview);

  /** @ngInject */
    function bdpFavReview() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpfavreview/bdpfavreview.html',
            scope: {
                listing: '=',
            },
            controller: ['$modal', '$timeout', 'apiConfig', 'ModalLikeDislikeFactory', 'modalFactory', 'authService', 'constantsSettings', 'constantsPlaceholders', 'constantsClasses', 'windowScroll', '$document', BdpFavReviewController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;


    /**
     * @memberof bdpFavReview
     * @method bdpFavReview_BdpFavReviewController
     * @name BdpFavReviewController
     * @description Directive controller
     *
     * @param  {directive} $modal                    ui-bootstrap modal wrapper
     * @param  {service}   $timeout                  Angular window.setTimeout service
     * @param  {factory}   apiConfig                 Api configuration factory
     * @param  {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.
     * @param  {factory}   authService               Factory what handle API request for authentication
     * @param  {constant}  constantsSettings         Constant settings
     * @param  {constant}  constantsPlaceholders     Constant placeholders
     * @param  {constant}  constantsClasses          Constant classes
     * @param  {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     * 
     */

    /** @ngInject */
        function BdpFavReviewController($modal, $timeout, apiConfig, ModalLikeDislikeFactory, modalFactory, authService, constantsSettings, constantsPlaceholders, constantsClasses, windowScroll, $document) {
            var vm = this;

            vm.reviewsPanelStatus = [];

      /**
       * @memberof bdpFavReview
       * @method hasFeature
       * @name hasFeature
       * @description Check does listing have featured review.
       * @returns {Boolean} hasFeature
       */
            vm.hasFeature = function() {
                if(angular.isUndefined(vm.listing) ||
          angular.isUndefined(vm.listing.featuredReview) || !vm.listing.featuredReview) {
                    return false;
                } else if( vm.listing.featuredReview.status.publishStatus === 'active') {
                    return true;
                }
            };


      /**
       * @memberof bdpFavReview
       * @method getCategoryVertical
       * @name getCategoryVertical
       * @description Returns category vertical object.
       * @returns {Object} categoryVertical
       */
            this.getCategoryVertical = function() {
                var _listing = apiConfig.getListing();
                if (angular.isUndefined(_listing)) {
                    return {name: ''};
                }
                return (angular.isDefined(_listing.bussinessDetails)) ?
                       ((angular.isDefined(_listing.bussinessDetails.categoryVertical)) ?
                        _listing.bussinessDetails.categoryVertical : {name: ''}) : {name: ''};
            };


      /**
       * @memberof bdpFavReview
       * @method _getUserAvatar
       * @name _getUserAvatar
       * @description Returns user avatar url.
       * @param {Object} listing  listing object
       * @returns {String} avatarUrl  url
       */
            vm._getUserAvatar = function(listing) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }
                if (angular.isUndefined(vm.listing.featuredReview)) {
                    return constantsSettings.defaultProfileBackground;
                } else if (angular.isUndefined(vm.listing.featuredReview.user)) {
                    return constantsSettings.defaultProfileBackground;
                } else if (angular.isUndefined(vm.listing.featuredReview.user.avatars)) {
                    return constantsSettings.defaultProfileBackground;
                } else if (angular.isUndefined(vm.listing.featuredReview.user.avatars[0])) {
                    return constantsSettings.defaultProfileBackground;
                } else if (angular.isUndefined(vm.listing.featuredReview.user.avatars[0].urls)) {
                    return constantsSettings.defaultProfileBackground;
                } else if (angular.isUndefined(vm.listing.featuredReview.user.avatars[0].urls.thumbnail)) {
                    return constantsSettings.defaultProfileBackground;
                } else {
                    return vm.listing.featuredReview.user.avatars[0].urls.thumbnail;
                }
            };


      /**
       * @memberof bdpFavReview
       * @method getUsername
       * @name getUsername
       * @description Returns user display name.
       * @param {Object} listing  Listing object from API
       * @returns {String} userName User who added review name
       */
            vm.getUsername = function(listing) {
                if (angular.isUndefined(listing)) {
                    vm.listing = listing;
                }

                if (angular.isUndefined(vm.listing.featuredReview)) {
                    return '';
                }
                if (angular.isUndefined(vm.listing.featuredReview.user)) {
                    return '';
                } else if (angular.isUndefined(vm.listing.featuredReview.user.displayName)) {
                    return '';
                } else {
                    return vm.listing.featuredReview.user.displayName;
                }
            };


      /**
       * @memberof bdpFavReview
       * @method getCommentsCounter
       * @name getCommentsCounter
       * @description Returns number of user comments.
       * @param {Object} listing
       * @returns {Number} commentsLength
       */
            vm.getCommentsCounter = function(listing) {
                if (angular.isUndefined(listing)) {
                    vm.listing = listing;
                }
                if (angular.isUndefined(vm.listing.featuredReview)) {
                    return 0;
                }
                if (angular.isUndefined(vm.listing.featuredReview.comment)) {
                    return 0;
                } else {
                    return vm.listing.featuredReview.comment.length;
                }
            };

            vm.ratings = [];


      /**
       * @memberof bdpFavReview
       * @method getRating
       * @name getRating
       * @description Adds ratings to ratings array.
       * @param {Object} listing
       * @returns {Array} ratings
       */
            vm.getRating = function(listing) {
                if (angular.isUndefined(listing)) {
                    vm.listing = listing;
                }
                if (angular.isUndefined(vm.listing.featuredReview)) {
                    return vm.ratings;
                }
                if (angular.isUndefined(vm.listing.featuredReview.rating)) {
                    return vm.ratings;
                } else {
                    vm.ratings = {rating: vm.listing.featuredReview.rating};
                    return vm.ratings;
                }
            };

            vm._showText = false;


      /**
       * @memberof bdpFavReview
       * @method showText
       * @name showText
       * @description Sets _showText to true if featuredText exist.
       * @returns {Boolean} _showText
       */
            vm.showText = function() {
                if (angular.isUndefined(vm.listing.featuredReview)) {
                    return vm._showText;
                }
                if (angular.isUndefined(vm.listing.featuredReview.text)) {
                    return vm._showText;
                } else {
                    vm._showText = true;
                    return vm._showText;
                }
            };


      /**
       * @memberof bdpFavReview
       * @method hideIfMine
       * @name hideIfMine
       * @description Returns true if current review is of current user.
       * @param {Boolean} _reviewItem
       * @returns {Boolean} notMine
       */
            this.hideIfMine = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return true;
                }
                if (angular.isDefined(_reviewItem.user) && apiConfig.checkIfMine(_reviewItem.user.id)) {
                    return false;
                }
                return true;
            };


      /**
       * @memberof bdpFavReview
       * @method reportNow
       * @name reportNow
       * @description Call modal that enable report now option.
       * @params {Object} _reviewItem
       */
            this.reportNow = function(_reviewItem) {
                this.resetAllStates();

                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                var _modalInstance;
                if (_reviewItem.reported) {
                    _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                        controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                        resolve: {
                            storedData: function() {
                                return constantsPlaceholders.bdpSingleReview.alreadyReportedText;
                            },
                        },
                    });

                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });

                    return false;
                } else if (apiConfig.userIsAuthenticated()) {
                    $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpmodalreportpendingmoderation/bdpmodalreportpendingmoderation.html',
                        controller: ['_reviewItem', 'apiConfig', 'constantsPlaceholders', 'reviewsService', 'DTM', 'constantsApiErrorCodes', modalReportPendingModeration],
                        controllerAs: 'vm',
                        size: 'dialog_size_big',
                        resolve: {
                            _reviewItem: function() {
                                return _reviewItem;
                            },
                        },
                    });
                } else {
                    this.showAuthModal();
                }
            };

            
            this.showAuthModal = function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                    controller: 'ModalAuthPromptController',
                    controllerAs: 'vm',
                    size: 'dialog_size_small',
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
            };

      /**
       * @memberof bdpFavReview
       * @method isReportNow
       * @name isReportNow
       * @description Check does it current user reported comment.
       * @params {Object} _reviewItem
       * @returns {Boolean} notReported
       */
            this.isReportNow = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }
                if (angular.isUndefined(_reviewItem.reportingReview)) {
                    return false;
                }
                if (_reviewItem.reportingReview) {
                    return true;
                }
                return false;
            };


      /**
       * @memberof bdpFavReview
       * @method addComment
       * @name addComment
       * @description Handle adding comment.
       */
            this.addComment = function(_reviewItem) {
                this.resetAllStates();

                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                if (apiConfig.userIsAuthenticated()) {
                    angular.extend(_reviewItem, {expandedDropdownState: false, startAddingComment: true});
                    this.focusBlock(_reviewItem.id);
                } else {
                    this.showAuthModal();
                }
            };


      /**
       * @memberof bdpFavReview
       * @method isAddingComment
       * @name isAddingComment
       * @description Check is adding comments in progress.
       * @returns {Boolean} isAddingComment
       */
            this.isAddingComment = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }
                if (angular.isUndefined(_reviewItem.startAddingComment) && angular.isUndefined(_reviewItem.addedCommentThankYouMessage)) {
                    return false;
                }
                if (_reviewItem.startAddingComment || _reviewItem.addedCommentThankYouMessage) {
                    return true;
                }
                return false;
            };


      /**
       * @memberof bdpFavReview
       * @method isExpanded
       * @name isExpanded
       * @description Returns dropdown state open if it is open or empty string otherwise.
       * @params {Object} _reviewItem
       * @returns {String} isExpanded
       */
            this.isExpanded = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return '';
                }
                return (_reviewItem.expandedDropdownState) ? 'open' : '';
            };


      /**
       * @memberof bdpFavReview
       * @method collapseDropdown
       * @name collapseDropdown
       * @description Collapse dropdown.
       * @params {Object} _reviewItem
       */
            this.collapseDropdown = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                _reviewItem.timeout = $timeout(angular.bind(this, function(_rItem) {
                    angular.extend(_rItem, {expandedDropdownState: false});
                }, _reviewItem), 800);
            };


      /**
       * @memberof bdpFavReview
       * @method notReported
       * @name notReported
       * @description Check is review already reported.
       * @params {Object} _reviewItem
       * @returns {Boolean} notReported
       */
            this.notReported = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }
                if (_reviewItem.alreadyReported) {
                    return false;
                }
                return true;
            };


      /**
       * @memberof bdpFavReview
       * @method cancelTimeout
       * @name cancelTimeout
       * @description Cancel current progress.
       * @params {Object} _reviewItem
       */
            this.cancelTimeout = function(_reviewItem) {
                $timeout.cancel(_reviewItem.timeout);
                _reviewItem.timeout = null;
            };

            this.preventProp = function(event) {
                event.preventDefault();
                return;
            };


      /**
       * @memberof bdpFavReview
       * @method expandDropdown
       * @name expandDropdown
       * @description Expand dropdown.
       * @params {Object} _reviewItem
       */
            this.expandDropdown = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                if (_reviewItem.expandedDropdownState) {
                    angular.extend(_reviewItem, {expandedDropdownState: false});
                    return;
                }

                angular.extend(_reviewItem, {expandedDropdownState: true});
            };


      /**
       * @memberof bdpFavReview
       * @method resetAllStates
       * @name resetAllStates
       * @description Reset all states to default values.
       */
            this.resetAllStates = function() {
                var _bussinessModel = apiConfig.getListing();

                if (angular.isDefined(_bussinessModel) && angular.isDefined(_bussinessModel.featureReview) && _bussinessModel.featureReview) {
                    angular.extend(_bussinessModel.featureReview,
                        {
                            expandedDropdownState: false,
                            startAddingComment: false,
                            reportingReview: false,
                        });

                    if (_bussinessModel.featureReview.comments && _bussinessModel.featureReview.comments.length) {
                        for (var featuredCommentsIndex = 0, featuredCommentsListSize = _bussinessModel.featureReview.comments.length; featuredCommentsIndex < featuredCommentsListSize; featuredCommentsIndex++) {
                            angular.extend(_bussinessModel.featureReview.comments[featuredCommentsIndex], {
                                reportingReview: false,
                                expandedDropdownStateSmall: false,
                            });
                        }
                    }
                }

                if (angular.isDefined(_bussinessModel) && angular.isDefined(_bussinessModel.bussinessDetails) && _bussinessModel.bussinessDetails.reviewList) {
                    for (var reviewsIndex = 0, reviewsListSize = _bussinessModel.bussinessDetails.reviewList.length; reviewsIndex < reviewsListSize; reviewsIndex++) {
                        angular.extend(_bussinessModel.bussinessDetails.reviewList[reviewsIndex],
                            {
                                expandedDropdownState: false,
                                startAddingComment: false,
                                reportingReview: false,
                            });

                        if (_bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments && _bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments.length) {
                            for (var revCommentsIndex = 0, revCommentsListSize = _bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments.length; revCommentsIndex < revCommentsListSize; revCommentsIndex++) {
                                angular.extend(_bussinessModel.bussinessDetails.reviewList[reviewsIndex].comments[revCommentsIndex], {
                                    reportingReview: false,
                                    expandedDropdownStateSmall: false,
                                });
                            }
                        }
                    }
                }
            };
        }
    }
})();
