(function() {
    'use strict';
    angular
        .module('truelocal')
        .directive('bdpDetailsContactInformation', BdpDetailsContactInformation);

    /**
     *
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsContactInformation
     * @description Directive will display contact information namely, mobile phone number, fax number
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {constant}  constantsClasses          Constant classes
     * @param {constant}  constantsSettings         Constant settings
     * @param {service}   @window                   Angular window wrapper
     *
     * @example
     * <bdp-details-contact-information listing="[object]" contacts="[object]"></bdp-details-contact-information>
     */

    /** @ngInject */
    function BdpDetailsContactInformation() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetailscontactinformation/bdpdetailscontactinformation.html',
            scope: {
                listing: '=',
            },
            controller: BdpDetailsContactInformationController,
            controllerAs: 'vm',
            bindToController: true,
        };
        return directive;
    }

    /**
     *
     * @memberof bdpDetailsContactInformation
     * @method bdpDetailsContactInformation_BdpDetailsContactInformationController
     * @name bdpDetailsContactInformation_BdpDetailsContactInformationController
     * @description Directive controller
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {constant}  constantsClasses          Constant classes
     * @param {constant}  constantsSettings         Constant settings
     * @param {service}   @window                   Angular window wrapper
     *
     */

    /** @ngInject */
    function BdpDetailsContactInformationController($scope, platformService, $log, $timeout, constantsPlaceholders, constantsSettings, $document, DTM, $window) {
        var vm = this;
        vm.boxIsHidden = true;
        vm.toggleContactInfoBox = toggleContactInfoBox;
        vm._isMobile = platformService.isMobile();
        vm.hover = false;
        vm.setHover = setHover;
        // vm.windowWidth
        // vm.generateTooltip = generateTooltip;//OLD
        vm.scrollTooltip = scrollTooltip;
        vm.shrinked = shouldItShrink(); // screen is shrinked to hide op hours
        vm.platformService = platformService;

        /**
         * @memberof bdpDetailsContactInformation
         * @method hasContactInfo
         * @name hasContactInfo
         * @description Returns true if business have opening hours.
         * @param {Object} contacts
         * @returns {Boolean}
         */
        vm.hasContactInfo = function() {
            var hasContact = false;
            if (angular.isUndefined(vm.listing.contacts)) {
                return hasContact;
            } else {
                angular.forEach(vm.listing.contacts.contact, function(contact) {
                    if (contact.type == 'phone' || contact.type == 'mobile' || contact.type == 'national' || contact.type == 'fax') {
                        hasContact = true;
                        return;
                    }
                });
            }
            return hasContact;
        };

         /**
         * @memberof bdpDetailsContactInformation
         * @method toggleContactInfoBox
         * @name toggleContactInfoBox
         * @description Show/hide contact information modal..
         */
        function toggleContactInfoBox(name) {
            vm.boxIsHidden = !vm.boxIsHidden;
            if (vm.boxIsHidden == false) {
                vm.scrollTooltip();
                DTM.trackAnalyticOnClick(name);
            }
        }

        $scope.$on('open-contact-information', function(event, data) {
            vm.boxIsHidden = false;
            vm.scrollTooltip();
        });

        /**
         * @memberof bdpDetailsContactInformation
         * @method setHover
         * @name setHover
         * @description User hover opening hours.
         */
        function setHover(val) {
            if (angular.isUndefined(val)) {
                return;
            }
            vm.hover = val;
            vm.scrollTooltip();
        }

        /**
         * @memberof bdpDetailsContactInformation
         * @method documentClickHandler
         * @name documentClickHandler
         * @description Called on mouse click.
         */
        var documentClickHandler = function(event) {
            if (vm.boxIsHidden == false) {
                var element = angular.element(document.querySelector('.wrapperHours .tooltip'));
                var eventOutsideTarget = (element[0] !== event.target) && (0 === element.find(event.target).length);
                if (eventOutsideTarget) {
                    $timeout(function() {
                        vm.boxIsHidden = true;
                    });
                }
            }
        };

        $document.on('click', documentClickHandler);
        // $document.on("touchstart", documentClickHandler);


        /**
         * @memberof bdpDetailsContactInformation
         * @method offset
         * @name offset
         * @description Calculate element dimensions.
         */
        function offset(elm) {
            try {
                return elm.offset();
            } catch (e) {
                $log.debug('inconsecvent error');
            }
            var rawDom = elm[0];
            var _x = 0;
            var _y = 0;
            var body = document.documentElement || document.body;
            var scrollX = window.pageXOffset || body.scrollLeft;
            var scrollY = window.pageYOffset || body.scrollTop;
            _x = rawDom.getBoundingClientRect().left + scrollX;
            _y = rawDom.getBoundingClientRect().top + scrollY;
            return {
                left: _x,
                top: _y,
            };
        }

        /**
         * @memberof bdpDetailsContactInformation
         * @method scrollTooltip
         * @name scrollTooltip
         * @description scroll opening hours toltip.
         */
        function scrollTooltip() {
            var toolTip = angular.element(document.querySelector('#wrapperContactInfoTooltip'));
            var toolTipOffset = offset(toolTip);
            var modalBottom = toolTipOffset.top + toolTip[0].offsetHeight;
            var shouldScrool = modalBottom > $window.outerHeight;
            if (shouldScrool || vm._isMobile) {
                $document.scrollTop(250, 1000);
            }
        }

        /**
         * @memberof bdpDetailsContactInformation
         * @method shouldItShrink
         * @name shouldItShrink
         * @description Calculate does opening hours tooltip fit on page..
         */
        function shouldItShrink() {
            var opHours = angular.element(document.querySelector('#opening-hours-1'));
            var address = angular.element(document.querySelector('#address-1'));
            var phone = angular.element(document.querySelector('#phone-1'));
            var contact = angular.element(document.querySelector('#contact'));
            var reqWidth1 = ((angular.isDefined(address) && address.length > 0) ? address[0].offsetWidth : 0 ) +
                            ((angular.isDefined(opHours) && opHours.length > 0) ? opHours[0].offsetWidth : 0 ) +
                            ((angular.isDefined(phone) && phone.length > 0) ? phone[0].offsetWidth : 0 ) + 155;
            var windowW = $window.outerWidth;

            if (platformService.getWidth() < 640) {
                return true;
            }
            return (windowW < reqWidth1);
        }

        angular.element($window).bind('resize', function() {
            vm._isMobile = platformService.isMobile();
            vm.shrinked = shouldItShrink();
        });
    }
})();
