(function() {
    'use strict';

    angular
        .module('truelocal')
        .controller('AdvertisingController', AdvertisingController);

    /** @ngInject */
    function AdvertisingController(Seo, $rootScope, $location, DTM, env, $timeout, $state, appService) {
        var vm = this;

        if (DTM.hasOneTrustScript()) {
            DTM.fireEventAtPageLoad($state.current.name);
        } else {
            DTM.trackPage();
        }

        Seo.pageTitle.set('True Local Listing Products - True Local');
        Seo.description.set('Find the best Listings product from True Local that suit your business needs and increase your business exposure online.');

        // Default image
        var mediaBucket = 'cover/advertising/';

        // Get URL category parameter
        var getParameter = $location.search().category;

        switch (getParameter) {
            case 'electricians':
                vm.categoryParameter = true;
                vm.categorySearches = '13,248';
                vm.categoryProfession = 'electricians';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'electricians.jpg';
                break;
            case 'plumbers':
                vm.categoryParameter = true;
                vm.categorySearches = '15,880';
                vm.categoryProfession = 'plumbers';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'plumbers.jpg';
                break;
            case 'mechanic':
                vm.categoryParameter = true;
                vm.categorySearches = '23,228';
                vm.categoryProfession = 'mechanic';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'mechanics.jpg';
                break;
            case 'building-construction':
                vm.categoryParameter = true;
                vm.categorySearches = '11,253';
                vm.categoryProfession = 'building construction';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'building-construction.jpg';
                break;
            case 'computer-services-repair':
                vm.categoryParameter = true;
                vm.categorySearches = '5,707';
                vm.categoryProfession = 'computer services';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'computer-services-repair.jpg';
                break;
            case 'cleaners':
                vm.categoryParameter = true;
                vm.categorySearches = '9,315';
                vm.categoryProfession = 'cleaners';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'cleaners.jpg';
                break;
            case 'hairdressers':
                vm.categoryParameter = true;
                vm.categorySearches = '22,897';
                vm.categoryProfession = 'hairdressers';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'hairdressers.jpg';
                break;
            case 'painters':
                vm.categoryParameter = true;
                vm.categorySearches = '6,598';
                vm.categoryProfession = 'painters';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'painters.jpg';
                break;
            case 'gardeners':
                vm.categoryParameter = true;
                vm.categorySearches = '5,844';
                vm.categoryProfession = 'gardeners';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'gardeners.jpg';
                break;
            case 'restaurants':
                vm.categoryParameter = true;
                vm.categorySearches = '24,641';
                vm.categoryProfession = 'restaurants';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'restaurants.jpg';
                break;
            case 'beauty-salons':
                vm.categoryParameter = true;
                vm.categorySearches = '13,774';
                vm.categoryProfession = 'beauty salons';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'beauty-salons.jpg';
                break;
            case 'landscapers':
                vm.categoryParameter = true;
                vm.categorySearches = '3,935';
                vm.categoryProfession = 'landscapers';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'landscapers.jpg';
                break;
            case 'photographers':
                vm.categoryParameter = true;
                vm.categorySearches = '2,661';
                vm.categoryProfession = 'photographers';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'photographers.jpg';
                break;
            case 'web-design':
                vm.categoryParameter = true;
                vm.categorySearches = '1,010';
                vm.categoryProfession = 'web design';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'web-design.jpg';
                break;
            case 'medical-centres':
                vm.categoryParameter = true;
                vm.categorySearches = '15,416';
                vm.categoryProfession = 'medical centres';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'medical-centres.jpg';
                break;
            case 'clothing-retailers':
                vm.categoryParameter = true;
                vm.categorySearches = '11,918';
                vm.categoryProfession = 'clothing retailers';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'clothing-retailers.jpg';
                break;
            case 'takeaways':
                vm.categoryParameter = true;
                vm.categorySearches = '30,531';
                vm.categoryProfession = 'takeaways';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'takeaways.jpg';
                break;
            case 'professional-services':
                vm.categoryParameter = true;
                vm.categorySearches = '1,965';
                vm.categoryProfession = 'professional services';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'professional-services.jpg';
                break;
            case 'cafes':
                vm.categoryParameter = true;
                vm.categorySearches = '11,840';
                vm.categoryProfession = 'cafes';
                vm.categoryBackgroundImage = env.media + mediaBucket + 'cafes.jpg';
                break;
            default:
                vm.categoryParameter = false;
                vm.categoryBackgroundImage = env.media + mediaBucket + 'default.jpg';
        }

        // Default active tab for mobiles
        vm.activeTab = 'priority';

        // Function to change tabs
        vm.activeTabButton = function(tab) {
            vm.activeTab = tab;
        }

        vm.phoneClick = function() {
            DTM.trackAnalyticOnClick('phone number click');
        }

        appService.displayFooter();
    }
})();
