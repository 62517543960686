(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name ScriptImporter
     * @description ScriptImporter service handles the injection of external scripts
     * or embed script snippets.
     *
     * @param {service} $timeout Angular setTimeout wrapper
     */
    .factory('scriptImporter', [ScriptImporter]);

    /** @ngInject */
    function ScriptImporter() {
        var service = {
            importScript: importScript,
        };
        
        /**
        * @memberof ScriptImporter
        * @method importScript(scImportOb)
        * @name importScript
        * @description dynamically import external script or embed script snippet.
        *
        * @param {object} scImportOb is an object having {src}, {snippet}, and {delay} properties.
        */
        function importScript(scImportOb) {
            injectOnBody(scImportOb.src, scImportOb.snippet);
        }
        
        function injectOnBody(scriptSrc, scriptSnippet) {
            var scriptElement = document.createElement('script');
            if (scriptSrc) {
                scriptElement.src = scriptSrc;
            }
            if (scriptSnippet) {
                scriptElement.innerHTML = scriptSnippet;
            }
            angular.element(document.querySelector('body')).append(scriptElement);
        }

        return service;
    }
})();
