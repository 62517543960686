(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bsLoadMore
     * @description Directive offer user option to load more results if available.
     *
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {service}   searchConfigManager       Service for building and handlig parameters for search
     *
     * @example
     * <bs-load-more search-listing="[object]"></bs-load-more>
     *
     */
    .directive('bsLoadPrev', bsLoadPrev);

  /** @ngInject */
    function bsLoadPrev() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/bsloadprev/bsloadprev.html',
            scope: {
                searchListing: '=',
            },
            controller: ['$timeout', 'searchConfigManager', BsLoadPrevController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BsLoadPrevController($timeout, searchConfigManager) {
            this.loadingMore = false;


      /**
       * @memberof bsLoadMore
       * @method loadMoreResults
       * @name loadMoreResults
       * @description If loading is not in progress, load more results.
       */
            this.loadPrevResults = function() {
                if (this.loadingMore) {
                    return;
                }

                this.loadingMore = true;

                $timeout(angular.bind(this, function() {
                    searchConfigManager.displayPrevResultsBlock(angular.bind(this, function() {
                        this.loadingMore = false;
                    }));
                }), 0);
            };


      /**
       * @memberof bsLoadMore
       * @method checkResultsOffset
       * @name checkResultsOffset
       * @description Checks is it more result to be loaded available, and return true if it is.
       * @returns {Boolean} resultsOffset
       */
            this.checkResultsOffset = function() {
                if (searchConfigManager.getStartIndex() > 1) {
                    return false;
                }
                return true;
            };


      /**
       * @memberof bsLoadMore
       * @method noResults
       * @name noResults
       * @description Returns true if no more results.
       * @returns {Boolean} noResults
       */
            this.noResults = function() {
                if (angular.isDefined(this.searchListing) && angular.isDefined(this.searchListing.size) && this.searchListing.size) {
                    return false;
                }
                return true;
            };
        }
    }
})();
