(function() {
    'use strict';

    angular
    .module('truelocal')


  /**
   * @memberof truelocal
   * @ngdoc directive
   * @name bdpCommentBox
   * @description Textarea input with special handling used in add review
   *
   * @param {constant} constantsPlaceholders  Constant with placeholders
   *
   * @example
   * <bdp-comment-box commented-text="[string]" parent-scope="[object:this]"></bdp-comment-box>
   */
  .directive('bdpCommentBox', bdpCommentBox)


  /**
   * @memberof truelocal
   * @ngdoc directive
   * @name ngKeystroke
   * @description Directive handle tracking of user interaction with comment box.
   */
  .directive('ngKeystroke', keyStokeHandler);

    function keyStokeHandler(DTM, $timeout) {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                elem.bind('focus', function() {
                    DTM.trackAnalyticOnClick('write comment start');
                });

                elem.bind('input', function() {
                    $timeout(function() {
                        if (angular.isUndefined(scope.vm.parentScope)) {
                            scope.vm.parentScope = {};
                        }
                        if (angular.element(elem).val().length >= scope.vm.constantsPlaceholders.minCharacters) {
                            scope.vm.characterLimitHit = true;
                            angular.extend(scope.vm.parentScope, {
                                typedComment: angular.element(elem).val()
                            });
                        } else {
                            scope.vm.characterLimitHit = false;
                            angular.extend(scope.vm.parentScope, {
                                typedComment: angular.element(elem).val()
                            });
                        }
                    });
                });
            },
            scope: {
                vm: '=',
            },
        };
    }

  /** @ngInject */
    function bdpCommentBox() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpcommentbox/bdpcommentbox.html',
            scope: {
                commentedText: '=',
                textNotValid: '=',
                submitt: '=',
                parentScope: '=',
            },
            controller: BdpCommentBoxController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */

        function BdpCommentBoxController(constantsPlaceholders, $cookies, $timeout, $scope, $localstorage) {
            if ( $localstorage.isSupported()) {
                var saveReviewObjectGet = $localstorage.getItem('save_review');

                if (saveReviewObjectGet) {
                    saveReviewObjectGet = JSON.parse(saveReviewObjectGet);
                    var currentPath = window.location.href;

                    if (currentPath == saveReviewObjectGet.path) {
                        this.commentedText = saveReviewObjectGet.review;
                        var saveReviewRating = $localstorage.getItem('save_review_rating');
                    } else {
                        this.commentedText = '';
                    }
                } else {
                    this.commentedText = '';
                }
            } else {
                this.commentedText = '';
            }


            this.constantsPlaceholders = constantsPlaceholders.bdpCommentBox;
            this.numberOfCharactersLeft = this.constantsPlaceholders.maxCharacters;
            this.characterLimitHit = false;
            $scope.autoSaving = false;

            if (angular.isUndefined(this.parentScope)) {
                this.parentScope = {};
            }

      // Triggers when typing is stopped for 1 second
            this.textEntered = function() {
                if ($localstorage.isSupported) {
          // If over 50 characters then store
                    if (this.characterLimitHit) {
                        $scope.autoSaving = true;

                        this.commentedText = this.parentScope.typedComment;
                        var saveReviewObjectPut = {
                            'path': window.location.href,
                            'review': this.commentedText,
                        };
                        $localstorage.setItem('save_review', JSON.stringify(saveReviewObjectPut));
                        $localstorage.removeItem('save_review_submitted');

                        $timeout(function() {
                            $scope.autoSaving = false;
                        },1000);//This is the time for which text 'Auto Saving' is displayed
                    }
                }
            };

            this.focusedArea = function() {
                angular.extend(this.parentScope, {
                    textNotValid: false,
                });
            };


      /**
       * @memberof bdpCommentBox
       * @method isInvalid
       * @name isInvalid
       * @description Validate the textarea input
       */
            this.isInvalid = function() {
                return (
          angular.isDefined(this.parentScope) &&
          angular.isDefined(this.parentScope.textNotValid)
        ) ? this.parentScope.textNotValid : false;
            };
        }
    }
})();
