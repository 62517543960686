(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDebug
     * @description Directive used for debuging purpose. It display all business details on begining of bdp page
     * if "debug=full" property is added to current business address, eg.:
     *
     *      Business name         Dodds G J Renovations
     *      Primary category      Bathroom Renovation
     *      Organisation ID       24F385C1-2B5F-4413-B6F4-B3CD4FFA2928
     *      Bazaar Voice          true
     *      Suburb                Launceston
     *      State                 TAS
     *      Postcode              7250
     *      Display Address       true
     *      TOL Region            7250
     *      Product Name          Sensis Basic Listing
     *      BC Product ID         498401516
     *      Product Version       3
     *      About ID              0000527cdb138cf1cde38327acc7155209cf2ba697894ae212df563f8ee20b1b
     *      Owner                 N/A
     *
     *
     *  @param  {factory}   apiConfig         Api configuration factory
     *
     */
    .directive('bdpDebug', bdpDebug);

  /** @ngInject */
    function bdpDebug() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdebug/bdpdebug.html',
            scope: {
                listing: '=',
            },
            controller: ['apiConfig', BdpDebugController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */

        function BdpDebugController(apiConfig) {
            var vm = this;
            vm.apiConfig = apiConfig;

            vm.show = function() {
                if (apiConfig.debug() == 'full') {
                    return true;
                }
                return false;
            };

            vm.category = function() {
                if (angular.isDefined(vm.listing.name)) {
                    return JSON.stringify(vm.listing.addresses.address[0], null, 4);
                }
            };

            vm.getTOL = function() {
                var tol = 'N/A';
                if ('regions' in vm.listing) {
                    angular.forEach(vm.listing.regions, function(region) {
                        if (region.type == 'TOL Region') {
                            tol = region.name;
                        }
                    });
                }
                return tol;
            };
        }
    }
})();
