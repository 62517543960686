(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpOther
     * @description Directive displays "other nearby services". At the bottom of the page as suggestion to user
     * in banner items are displayed four business that are nearby. Sponsored business are displayed with special
     * style. If screen goes tight on desktop, listing are displayed as carousel, and as column items on mobile.
     *
     * Other nearby services on the BDP enable user to be informed of what's around. This are rules for displaying:
     *
     *    Business can be any category
     *    Business to be ranked in order of distance from current BDP location
     *    Business must have atleast one review
     *    Business must have 3.5 or greater star rating average
     *    Non managed business: Business image should be any image which can be used for background image size
     *    Managed business: Business image should be the selected feature image, if no feature image, any business
     *    owned image (never a user generated image)
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {constant}  constantsSettings         Constant settings
     * @param {factory}   DTM           TL tracking services
     * @param {constant}  env                       Environmental constants
     * @param {service}   $location                 Angular window.location service wrapper
     *
     * @example
     * <bdp-other other-listings="[object]""></bdp-other>
     */
    .directive('bdpOther', bdpOther);

  /** @ngInject */
    function bdpOther(DTM) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpother/bdpother.html',
            scope: {
                otherListings: '=',
            },
            controller: BdpOtherController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;
    /**
     * @memberof bdpOther
     * @name bdpOther_BdpOtherController
     * @method bdpOther_BdpOtherController
     * @description directive controller
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {constant}  constantsSettings         Constant settings
     * @param {factory}   DTM           TL tracking services
     * @param {constant}  env                       Environmental constants
     * @param {service}   $location                 Angular window.location service wrapper
     *
     * @example
     * <bdp-other other-listings="[object]""></bdp-other>
     */
    /** @ngInject */
        function BdpOtherController(platformService, constantsSettings, DTM, env, $location, $log) {
      /**
       * @memberof bdpOther
       * @name listLimit
       * @description Limit of items to display defined in application constants settings.
       */
            this.listLimit = constantsSettings.listLimits.bdpothers;
            this.isMobile = platformService.isMobile();
            this.getHeightLimit = function() {
                if(platformService.getWidth()<768) {
                    return 94;
                } else {
                    return 50;
                }
            };

            $log.debug('otherListings', this.otherListings);
      /**
       * @memberof bdpOther
       * @method primaryCategory
       * @name primaryCategory
       * @description Returns nearby item primary category.
       * @params {Object} categories
       * @returns {String} categoryName
       */
            this.primaryCategory = function(categories) {
                if (angular.isUndefined(categories)) return '';

                else if (angular.isUndefined(categories[0])) return '';
                else if (angular.isUndefined(categories[0].name)) return '';
                else return categories[0].name;
            };


      /**
       * @memberof bdpOther
       * @method getSuburb
       * @name getSuburb
       * @description Returns suburb from addresses.
       * @params {Object} addresses
       * @returns {String} suburb
       */
            this.getSuburb = function(addresses) {
                if (angular.isUndefined(addresses)) return '';
                else if (angular.isUndefined(addresses)) return '';
                else if (angular.isUndefined(addresses.address)) return '';
                else if (angular.isUndefined(addresses.address[0])) return '';
                else if (angular.isUndefined(addresses.address[0].suburb)) return '';
                else return addresses.address[0].suburb;
            };


      /**
       * @memberof bdpOther
       * @method titleClass
       * @name titleClass
       * @description Returns proper css size class based on number of letters in entered text.
       * @params {String} text
       * @returns {String} class
       */
            this.titleClass = function(text) {
                if (angular.isUndefined(text)) return '';
                if (text.length > 13 && text.length < 18) {
                    return 'small';
                } else if (text.length >= 18 && text.length < 25) {
                    return 'xsmall';
                } else if (text.length >= 25) {
                    return 'xxsmall';
                } else {
                    return '';
                }
            };

            this.page = 1;
            this.left = 0;


      /**
       * @memberof bdpOther
       * @method swipeLeft
       * @name swipeLeft
       * @description Swipe on nearby items event. Available if screen is bigger that 480px, and items doesn't
       * fit on screen.
       * @returns {Object} page
       */
            this.swipeLeft = function() {
                var width = platformService.getWidth();
                if (width < 480) return false;
                var lwidth = 0;

                angular.forEach(angular.element(document.querySelector('ul#other-area')).find('li'), function(elem, index) {
                    lwidth = lwidth + parseInt(angular.element(elem)[0].clientWidth);
                });

                while (this.left > (parseInt(width) - parseInt(lwidth))) {
                    this.left = this.left - 1;
                }
                angular.element(document.querySelector('ul#other-area')).css({
                    'margin-left': (this.left - 106) + 'px',
                });
                if (this.page == 1) {
                    this.page = 2;
                }
                return this.page;
            };


      /**
       * @memberof bdpOther
       * @method swipeRight
       * @name swipeRight
       * @description Swipe on nearby items event. Available if screen is bigger that 480px, and items doesn't
       * fit on screen.
       * @returns {Object} page
       */
            this.swipeRight = function() {
                var width = platformService.getWidth();
                if (width < 480) return false;
                this.left = 0;
                angular.element(document.querySelector('ul#other-area')).css({
                    'margin-left': (this.left) + 'px',
                });
                if (this.page == 2) {
                    this.page = 1;
                }
                return this.page;
            };


      /**
       * @memberof bdpOther
       * @method hasPlaces
       * @name hasPlaces
       * @description Returns true if other listings exists.
       * @returns {Boolean} hasPlace
       */
            this.hasPlaces = function() {
                if (angular.isDefined(this.otherListings) && angular.isDefined(this.otherListings.data) && angular.isDefined(this.otherListings.data.listing)) {
                    if (this.otherListings.data.listing.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            };


      /**
       * @memberof bdpOther
       * @method getUrl
       * @name getUrl
       * @description Returns url for item. Target present part of bdp page that link should enable scrolling to
       * when page is opened.
       * @params {Object} listing
       * @params {String} target
       * @returns {String} getUrl
       */
            this.getUrl = function(listing, target) {
                if (angular.isDefined(target)) {
                    return env.urlStructure + listing.seoUrl + '?target=' + target;
                } else {
                    return env.urlStructure + listing.seoUrl;
                }
            };


      /**
       * @memberof bdpOther
       * @method trackingNearByBiz
       * @name trackingNearByBiz
       * @description Tracks user interaction with nearby business.
       * @params {Number} index
       * @params {Object} listing
       * @params {String} target
       * @returns {String} url
       */
            this.trackingNearByBiz = function(index, listing, target) {
                DTM.trackAnalyticOnClick('other nearby services ' + (parseInt(index) + 1));
                if (angular.isDefined(target)) {
                    return $location.path(this.getUrl(listing)).search({target: target});
                } else {
                    return $location.path(this.getUrl(listing));
                }
            };


      /**
       * @memberof bdpOther
       * @method hasImage
       * @name hasImage
       * @description Returns true if nearby business have image.
       * @params {Object} listing
       * @returns {Boolean} hasImage
       */
            this.hasImage = function(listing) {
                return (
          angular.isDefined(listing) &&
          angular.isDefined(listing.featureImage) &&
          angular.isDefined(listing.featureImage.urls) &&
          angular.isDefined(listing.featureImage.urls.small)
                );
            };


            this.pagingClass = function(page) {
                return (angular.isDefined(page) && this.page == page) ? 'active' : '';
            };

      /**
       * @memberof bdpOther
       * @method getElemClass
       * @name getElemClass
       * @description Returns nearby item class after checking is it paid service and does it have image.
       * @params {Object} listing
       * @returns {String} class
       */
            this.getElemClass = function(listing) {
                var _class = '';
                if (angular.isDefined(listing)) {
                    if (angular.isDefined(listing.paid) && listing.paid == true) _class += 'sponsored-item';
                    if (angular.isUndefined(listing.featureImage)) {
                        var listingNoImage = 'listing-no-image';
                        _class += _class != '' ? ' ' + listingNoImage : listingNoImage;
                    }
                }
                return _class;
            };
        }
    }
})();
