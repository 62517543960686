(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('campaignBanner', campaignBanner);

    /** @ngInject */
    function campaignBanner() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/campaign/campaignbanner-default.html',
            controller: CampaignBannerController,
            scope:{
                isPanelOpen: '='
            }
        };

        return directive;

        /** @ngInject */
        function CampaignBannerController($scope,runnningCampaign, platformService, $window, $cookies) {
            $scope.campaignRunning = runnningCampaign.steve;
            $scope.isMobile = (platformService.getWidth() < 768 && !platformService.isIPad());
            $scope.isDesktop = !platformService.isMobilePlatform();
            $scope.businessHubUrl = "https://www.truelocal.com.au/cms/business-centre-products";
            $cookies.put('tl_campaign_seen', true);
            $scope.dontExpand = $scope.isPanelOpen ? true: false; //do not show arrow buttons in initial loading
            $scope.closeClick = function() {
                $scope.isPanelOpen = false;
                $scope.dontExpand = false;
            }

            $scope.openClick = function() {
                if(!$scope.dontExpand) {
                    $scope.isPanelOpen = true;
                }
                $scope.dontExpand = true; //hide arrow buttons when close button is clicked
            }

            $scope.buttonClick = function() {
                $window.open($scope.businessHubUrl, '_blank');
            }
        }
    }
})();
