(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name BdpGalleryNavigatorController
     * @description BdpGalleryNavigatorController handle displaying of gallery in full screen mode.
     */
    .controller('BdpGalleryNavigatorController', BdpGalleryNavigatorController);

  /** @ngInject */
    function BdpGalleryNavigatorController($scope, $log, images, listing, $http, apiConfig, $timeout, selectedImage, $q, $window, $rootScope, $modalInstance, $location, errorService) {
        var gallery = this;
        $rootScope.$on('$locationChangeStart', function() {
            $modalInstance.close();
        });

    /**
     * @memberof BdpGalleryNavigatorController
     * @name css
     * @description BdpGalleryNavigator tag.
     */
        gallery.TAG = '[BdpGalleryNavigator]';


    /**
     * @memberof BdpGalleryNavigatorController
     * @name listing
     * @description BdpGalleryNavigatorController root data object.
     */
        gallery.listing = listing;


    /**
     * @memberof BdpGalleryNavigatorController
     * @name selectedImage
     * @description Currently selected image.
     */
        gallery.selectedImage = selectedImage;


    /**
     * @memberof BdpGalleryNavigatorController
     * @name images
     * @description Array of images in gallery.
     */
        gallery.images = [];
        gallery.imagesTemp = [];
        gallery.imagesOwner = [];
        gallery.imagesVideo = [];
        gallery.imagesUser = [];


        gallery.size = 0;
        gallery.userIsAuthenticated = apiConfig.userIsAuthenticated();
        gallery.showVideo=false;
        gallery.done=false;


    /**
     * @memberof BdpGalleryNavigatorController
     * @name css
     * @description css object holds classes for gallery style.
     */
        gallery.css = {
            selectors: {
                thumbnails: 'div.modal-gallery-navigator--thumbnails',
                list: 'div.modal-gallery-navigator div.modal-gallery-navigator--thumbnails ul',
                textarea: '.modal-gallery-navigator textarea',
            },
        };


    /**
     * @memberof BdpGalleryNavigatorController
     * @name settings
     * @description settings object.
     */
        gallery.settings = {
            review: {
                maxLength: 1000,
            },
        };

        gallery.form = {};

        gallery.contents = {};


    /**
     * @memberof BdpGalleryNavigatorController
     * @name states
     * @description Gallery states defined by number of images, width of thumbs, lodaing state etc.
     */
        gallery.states = {
            activeIndex: 0,
            activePage: 0,
            thumbWidth: 250,
            totalPages: 0,
            perView: 0,
            isSubmitPending: false,
        };

        gallery.visibility = {
            reporting: false,
        };


    /**
     * @memberof BdpGalleryNavigatorController
     * @method openPinterestUrl
     * @name openPinterestUrl
     * @description Opens pinterest service with gallery url, large image, and business title to create gallery.
     * @params {String} url
     * @params {String} large
     * @params {String} caption
     * @returns {Boolean} false
     */
        gallery.openPinterestUrl = function(url, large, caption) {
            window.open('https://www.pinterest.com/pin/create/button/?url=' + url + '&media=' + large + '&description=' + caption);
            return false;
        };


    /**
     * @memberof BdpGalleryNavigatorController
     * @method getAvatar
     * @name getAvatar
     * @description Get link to avatar from current image if available, or default otherwise.
     * @params {Object} image
     * @returns {String} avatar url
     */
        gallery.getAvatar = function(image) {
            if (angular.isDefined(image) && image._has('user.avatars.image[0].urls[\'xsmall\']')) {
                return image.user.avatars.image[0].urls['xsmall'];
            } else if (gallery._has('listing.logo.urls.xsmall')) {
                return gallery.listing.logo.urls.xsmall;
            }
            return 'https://media.truelocal.com.au/avatars/-50x50.jpg';
        };


    /**
     * @memberof BdpGalleryNavigatorController
     * @name events
     * @description Events on gallery.
     * @params {Object} event
     */
        gallery.events = {
            onResize: function(event) {
                if(gallery.showVideo==true) {
                    return;
                }
                var width = angular.element(window)[0].outerWidth,
        // var width = ($window).width(),
                    height = angular.element(window)[0].outerHeight,
                    thumbContainer = document.querySelector(gallery.css.selectors.thumbnails),
                    onPage = 10;
        // $log.debug('width',$('.modal-gallery-navigator--details').width(),($window).width());

                onPage = Math.ceil(width / (thumbContainer.offsetHeight + 50));

                gallery.states.thumbWidth = (width / onPage).toFixed(5);
                gallery.states.perView = (width / Math.round(width / onPage)).toFixed(5);
                gallery.states.totalPages = Math.ceil((gallery.images.length * gallery.states.thumbWidth) / width) - 1;

                gallery.actions.updateNavigation();

        // $log.debug('%s Thumb width: %s, Number of visible items: %d, Total pages: %d', gallery.TAG, gallery.states.thumbWidth, gallery.states.perView, gallery.states.totalPages);
            },
        };

        gallery.imageStyle = '';


    /**
     * @memberof BdpGalleryNavigatorController
     * @name actions
     * @description Actions on gallery.
     */
        gallery.actions = {
            setup: function() {
                $log.debug('%s Setup, all loaded', gallery.TAG);
                gallery.images=gallery.imagesVideo.concat(gallery.imagesOwner, gallery.imagesUser);
                gallery.events.onResize();
        // if we have video
                if(gallery._has('images[0].type')) {
                    var done=false;
                    gallery.images.forEach(function(image, key) {
                        if(!image._has('type') && !done) {
                            done=true;
                            gallery.images.splice(0, 0, gallery.images.splice(key, 1)[0]);
                        }
                    });
                }

        // document.body.classList.add('noHorizontalScroll');

                gallery.images = gallery.images.filter(function(img) {
                    return img.status.publishStatus !== 'inactive';
                });

        // $log.debug('%s $watch gallery.selectedImage: %d', gallery.TAG, gallery.selectedImage);
                $timeout(function() {
                    if (!$rootScope.anchored && angular.isDefined($location.search().target) && angular.isDefined($location.search().id)) {
                        if($location.search().target=='gallery-show') {
                            var done=false;
                            gallery.images.forEach(function(image, key) {
                                if(image.id ==$location.search().id) {
                                    done=true;
                                    gallery.selectedImage=key;
                                }
                            });
                        }
                        $rootScope.anchored=true;
                    }
                    gallery.actions.setActive(gallery.selectedImage);
                });
            },
            getCurrentUrl: function() {
                return document.location.href;
            },
            getDescription: function() {
                if (angular.isDefined(gallery.images[gallery.states.activeIndex].caption)) {
                    return gallery.images[gallery.states.activeIndex].caption;
                } else {
                    return '';
                }
            },
            load: function() {
                gallery.cleanBgImg();

                $log.debug('%s Loading API data...', gallery.TAG);

                var owner, user, videos;

                videos = $http({
                    method: 'GET',
                    url: apiConfig.generate('listings', gallery.listing.id, 'videos') + '&limit=1000',
                }).then(function(response) {
                    $log.debug('%s Loaded: %s videos.', gallery.TAG, 'BUSINESS');
                    if(response.data.data._has('video[0]')) {
                        response.data.data.video.forEach(function(video) {
                            video.type='video';
                            video.urls={};
                            var url='/www-js/img/bdp-play-video-placeholder.jpg';
                            if(gallery.listing._has('coverImage.url')) {
                                url=gallery.listing.coverImage.url;
                            }
                            video.urls.large=url;
                            video.urls.medium=url;
                            video.urls.original=url;
                            video.urls.small=url;
                            video.urls.thumbnail=url;
                            video.urls.xsmall=url;
                            video.caption='';
                            if(video._has('title')) {
                                video.caption=video.title;
                            }
                            if(video._has('description')) {
                                if(video.caption.length>0) {
                                    video.caption=video.caption+' - '+video.description;
                                }else{
                                    video.caption=video.description;
                                }
                            }
                        });
                    }
                    gallery.imagesVideo = angular.isDefined(response.data.data.video) ? response.data.data.video : [];
                }, function(response) {
                    $log.debug('%s Could not load: %s videos.', gallery.TAG, 'BUSINESS');
                    alert(errorService.getErrorMessage(response));
                });

                owner = $http({
                    method: 'GET',
                    url: apiConfig.generate('listings', gallery.listing.id, 'images') + '&limit=1000',
                }).then(function(response) {
                    $log.debug('%s Loaded: %s photos.', gallery.TAG, 'BUSINESS');

                    gallery.imagesOwner = angular.isDefined(response.data.data.image) ? response.data.data.image : [];
                }, function(response) {
                    $log.debug('%s Could not load: %s photos.', gallery.TAG, 'BUSINESS');
                    alert(response.data.meta.messages[0]);
                });

                user = $http({
                    method: 'GET',
                    url: apiConfig.generate('listings', gallery.listing.id, 'user-images') + '&limit=1000',
                }).then(function(response) {
                    $log.debug('%s Loaded: %s photos.', gallery.TAG, 'USER');

                    gallery.imagesUser = response.data.data.image;
          // gallery.events.onResize();
                }, function(response) {
                    $log.debug('%s Could not load: %s photos.', gallery.TAG, 'USER');
                    alert(response.data.meta.messages[0]);
                });

                $q.all([
                    videos,
                    owner,
                    user,
                ]).then(function() {
                    gallery.actions.setup();
                });
            },
            scrollToIndex: function(index) {
                var page = Math.floor(index / gallery.states.perView);
        // $log.debug('%s scrollToIndex: %d, page: %d', gallery.TAG, index, page);

                gallery.actions.goToPage(page);
            },
            setActive: function(index) {
                index = index || 0;

        // $log.debug('%s setActive to %d', gallery.TAG, index);
                gallery.states.activeIndex = index;
                gallery.actions.scrollToIndex(gallery.states.activeIndex);

                gallery.actions.toggleReport(false);
                if (gallery._has('images[' + gallery.states.activeIndex + '].urls.large')) {
                    gallery.imageStyle = gallery.images[gallery.states.activeIndex].urls.large;
                }
                gallery.showVideo=false;
                $timeout(function() {
                    gallery.done=true;
                });
            },
            nextPhoto: function() {
                gallery.states.activeIndex = gallery.states.activeIndex + 1;

                if (gallery.states.activeIndex > gallery.images.length - 1) {
                    gallery.states.activeIndex = 0;
                }

                gallery.actions.setActive(gallery.states.activeIndex);
                gallery.showVideo=false;
            },
            prevPhoto: function() {
                gallery.states.activeIndex = gallery.states.activeIndex - 1;

                if (gallery.states.activeIndex < 0) {
                    gallery.states.activeIndex = gallery.images.length - 1;
                }

                gallery.actions.setActive(gallery.states.activeIndex);
            },
            goToPage: function(index, setFirstActive) {
                setFirstActive = setFirstActive || false;

                var width = angular.element(window)[0].outerWidth,
                    list;

                list = document.querySelector(gallery.css.selectors.list);

                gallery.states.activePage = index;

                if (gallery.states.activePage > gallery.states.totalPages) {
                    gallery.states.activePage = gallery.states.totalPages;
          // gallery.states.activePage = 0;
                }

                if (gallery.states.activePage < 0) {
                    gallery.states.activePage = 0;
          // gallery.states.activePage = gallery.states.totalPages;
                }

                list.style.marginLeft = ['-', Math.round(gallery.states.activePage * width), 'px'].join('');

        // $log.debug('%s Navigate', gallery.TAG);
            },
            navigate: function(direction) {
                gallery.actions.goToPage(gallery.states.activePage + direction);
            },
            updateNavigation: function() {
                var width = angular.element(window)[0].outerWidth;

                var list = document.querySelector(gallery.css.selectors.list);
                list.style.marginLeft = '0px';
                gallery.states.activePage = 0;
                gallery.actions.setActive(0);
            },
            getMomentAgo: function(timestamp) {
                return angular.isDefined(timestamp) ? moment(moment(timestamp), 'YYYYMMDD').fromNow() : '';
            },
            toggleReport: function(visible) {
                visible = angular.isDefined(visible) ? visible : !gallery.visibility.reporting;
                gallery.form.message = '';
                if (visible && !gallery.userIsAuthenticated) {
                    $log.warn('%s You need to sign-in first!', gallery.TAG);
                    $rootScope.$broadcast('loginmodal:show');
                    return;
                }

                gallery.visibility.reporting = visible;
            },
            submit: function() {
                $log.debug('%s form submit.', gallery.TAG);
                gallery.contents.successMessage = '';
                gallery.contents.errorMessage = '';
                gallery.states.isSubmitPending = true;
                $http.post(apiConfig.generate('user-images', gallery.images[gallery.states.activeIndex].id, 'report'), JSON.stringify({
                    reason: gallery.form.message.substring(0, gallery.settings.review.maxLength),
                }), apiConfig.getXAuthToken())
        /* $http({
         url: apiConfig.generate('user-images', gallery.images[gallery.states.activeIndex].id, 'report'),
         method: 'POST',
         data: JSON.stringify({
         reason: gallery.form.message.substring(0, gallery.settings.review.maxLength)
         }),
         headers: {
         'Content-Type': 'application/json'
         }
         })*/.success(function(data, status, headers, config) {
             $log.debug('%s form submit -> success', gallery.TAG);
             var index = gallery.states.activeIndex;
             gallery.contents.successMessage = 'Success!';
             gallery.states.isSubmitPending = false;
             gallery.form.message = '';

             gallery.images[index].status.publishStatus = 'inactive';

             var prev = (index > 1) ? --index : 0;

             angular.element($window).triggerHandler('reported-image', gallery.images[index].id);
             gallery.actions.setActive(prev);
         }).error(function(data) {
             $log.debug('%s form submit -> error -> details: %O', gallery.TAG, data);

             gallery.contents.errorMessage = data.meta.messages[0];
             gallery.states.isSubmitPending = false;
         });
            },
            showVideo: function() {
        // I moved the playing of the video in the parent element activated with onclick because otherwise ios doesn't play the video good
        /* if(!gallery.showVideo){
         $timeout(function(){
         var video = $('.video').get(0);
         $log.debug(video,video.paused);
         if (video.paused){
         //$log.debug('should play');
         //video.play();
         }
         else{
         //$log.debug('should pause');
         //video.pause();
         }

         },300);
         }*/
                gallery.showVideo=true;
            },
        };


    /**
     * @memberof BdpGalleryNavigatorController
     * @method getImageState
     * @name getImageState
     * @description Get url to large (main) image to display it in gallery.
     * @returns {String} url
     */
        gallery.getImageState = function() {
            if (angular.isDefined(gallery.images) && angular.isDefined(gallery.images[gallery.states.activeIndex]) && angular.isDefined(gallery.images[gallery.states.activeIndex].urls) && angular.isDefined(gallery.images[gallery.states.activeIndex].urls.large)) {
                return gallery.images[gallery.states.activeIndex].urls.large;
            } else {
                return '';
            }
        };

      /**
       * @memberof BdpGalleryNavigatorController
       * @method getAltText
       * @name getAltText
       * @description Constructs the alternate text for the image.
       * @returns {String} text
       */
      gallery.getAltText = function() {
          var activeImage = gallery.images[gallery.states.activeIndex];
          var businessName = activeImage ? activeImage.businessName : '';
          var caption = activeImage ? activeImage.caption : '';
          var picCtr = gallery.states.activeIndex + 1;
          if(angular.isDefined(caption)) {
              caption = caption.replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
              caption = caption.substring(0, 255);
              return businessName + ' Pic ' + picCtr + ' - ' + caption;
          }
          return businessName + ' Pic ' + picCtr;
      };

        gallery.currentVideo = function() {
            if(angular.isDefined(gallery.images) && angular.isDefined(gallery.images[gallery.states.activeIndex]) && angular.isDefined(gallery.images[gallery.states.activeIndex].urls) && angular.isDefined(gallery.images[gallery.states.activeIndex].urls.large)) {
                if(gallery.images[gallery.states.activeIndex]._has('type') && gallery.images[gallery.states.activeIndex].type=='video') {
                    gallery.images[gallery.states.activeIndex].webm=gallery.images[gallery.states.activeIndex].url.substr(0, gallery.images[gallery.states.activeIndex].url.lastIndexOf('.')) + '.webm';
                    return gallery.images[gallery.states.activeIndex];
                }
            } else {
                return false;
            }
        };


        gallery.isVideo = function() {
            if(angular.isDefined(gallery.images) && angular.isDefined(gallery.images[gallery.states.activeIndex]) && angular.isDefined(gallery.images[gallery.states.activeIndex].urls) && angular.isDefined(gallery.images[gallery.states.activeIndex].urls.large)) {
                if(gallery.images[gallery.states.activeIndex]._has('type') && gallery.images[gallery.states.activeIndex].type=='video') {
                    return true;
                }else{
                    return false;
                }
            } else {
                return false;
            }
        };

        gallery.imageState = '';


    /**
     * @memberof BdpGalleryNavigatorController
     * @method cleanBgImg
     * @name cleanBgImg
     * @description Clear image state for IE <= 9.
     */
        gallery.cleanBgImg = function() {
            var ua = detect.parse(navigator.userAgent);
            if (ua.browser.family == 'IE' && ua.browser.version <= 9) return;
            gallery.imageStyle = '';
        };

        gallery.show = false;


    /**
     * @memberof BdpGalleryNavigatorController
     * @event imagesOnChange
     * @name imagesOnChange
     * @description Images change watcher.
     */
        $scope.$watch('gallery.images', function(newVal) {
            if (angular.isDefined(newVal)) {
                $log.debug('%s Delete inactive pictures.', gallery.TAG);
        // gallery.cleanBgImg();
                gallery.images = gallery.images.filter(function(image) {
                    return image.status.publishStatus === 'active';
                });

        // $log.debug('%s Current collection: %O', gallery.TAG, gallery.images);
            }
        }, true);


    /**
     * @memberof BdpGalleryNavigatorController
     * @event statesActiveIndexOnChange
     * @name statesActiveIndexOnChange
     * @description States ActiveIndex change watcher.
     */
        $scope.$watch('gallery.states.activeIndex', function(newVal) {
            if (angular.isDefined(newVal)) {
                $log.debug('%s $watch gallery.states.activeIndex -> details: %O', gallery.TAG, gallery.images[newVal]);
            }
        });


    /**
     * @memberof BdpGalleryNavigatorController
     * @event imagesUrlsLargeChange
     * @name statesActiveIndexWatcher
     * @description Large img url change watcher.
     */
        $scope.$watch('gallery.images[gallery.states.activeIndex].urls.large', function(newVal, prevVal) {
            if (angular.isDefined(newVal) && newVal != prevVal) {
                $timeout(function() {
                    gallery.imageState = gallery.getImageState();
                    gallery.show = true;
                });
            }
        });


    /**
     * @memberof BdpGalleryNavigatorController
     * @event destroyEvent
     * @name destroyEvent
     * @description States ActiveIndex change watcher.
     */
        $scope.$on('$destroy', function() {
            $log.debug('%s $destroy', gallery.TAG);
            angular.element($window).off('resize', gallery.events.onResize);
            document.body.classList.remove('noHorizontalScroll');
        });


    /**
     * @memberof BdpGalleryNavigatorController
     * @event windowOnResize
     * @name windowOnResize
     * @description Transfer window resize event to gallery.
     */
        angular.element($window).on('resize', gallery.events.onResize);


    /**
     * @memberof BdpGalleryNavigatorController
     * @event windowOnUserAuthenticated
     * @name windowOnUserAuthenticated
     * @description User Authenticated event: focus gallery if user is authenticated.
     */
        angular.element(window).on('user-authenticated', function() {
            $log.debug('% Signed-in. Ready to report the image.', gallery.TAG);
            gallery.userIsAuthenticated = apiConfig.userIsAuthenticated();
            $timeout(function() {
                gallery.actions.toggleReport(true);
                $timeout(function() {
                    $(gallery.css.selectors.textarea).focus();
                });
            });
        });

        gallery.actions.load();
    }
})();
