(function () {
    'use strict';

    angular
        .module('truelocal')

        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name tooltipNoLocation
         * @description Directive display number of business that are not showed on map because
         *     they don't have exact location.
         *
         * @param {service}   $window                   Angular window wrapper
         *
         *
         */
        .directive('tooltipNoLocation', tooltipNoLocation)

        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name searchMap
         * @description Directive display search results on map. Every result item have marker that
         *     can display popup with business info and link to business details page.
         *
         * @param {service}   $window                   Angular window wrapper
         * @param {service}   $location                 Angular window.location service wrapper
         * @param {service}   $timeout                  Angular window.setTimeout service
         * @param {service}   $scope                    Angular scope service
         * @param {constant}  env                       Environmental constants
         * @param {factory}   platformService           Factory service that handle detection of
         *     platform and screen size that current user use
         * @param {constant}  searchGMap                Constant with google maps specific settings
         * @param {factory}   gmapService               Google map service
         * @param {service}   searchConfigManager       Service for building and handlig parameters
         *     for search
         *
         * @example
         * <search-map search-data="[object]" show-marker="[string]"></search-map>
         *
         */
        .directive('searchMap', searchMap);

    function tooltipNoLocation($window) {
        return {
            restrict: 'A',
            link: function (scope, $elm, attrs) {
                scope.timeoutShow = null;

                scope.$on('$destroy', angular.bind(scope, function () {
                    angular.element(document.querySelector('#showList')).off('click', _showList);
                    angular.element(document.querySelector('.icon-close')).off('click', _closePopup);
                    angular.element($window).off('close-missing-tooltip', _closeMissingTooltip);
                }));

                var _showList = angular.bind(scope, function (_elem) {
                    _elem.removeClass("active");
                    angular.element($window).triggerHandler('change-view');
                    window.truncateCounter = 0;
                }, $elm);

                var _closePopup = angular.bind(scope, function (_elem) {
                    if (scope.timeoutShow) {
                        clearTimeout(scope.timeoutShow);
                        scope.timeoutShow = null;
                    }
                    _elem.removeClass("active");
                }, $elm);

                var _closeMissingTooltip = angular.bind(scope, function (_elem) {
                    if (scope.timeoutShow) {
                        clearTimeout(scope.timeoutShow);
                        scope.timeoutShow = null;
                    }
                    _elem.removeClass("active");
                }, $elm);

                angular.element(document.querySelector('#showList')).on('click', _showList);
                angular.element(document.querySelector('.icon-close')).on('click', _closePopup);
                angular.element($window).on('close-missing-tooltip', _closeMissingTooltip);
            }
        }
    }

    /** @ngInject */
    function searchMap() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/searchmap/searchmap.html',
            scope: {
                searchData: '=',
                showMarker: '=',
                showList: '&showList'
            },
            controller: BsSearchMapController,
            controllerAs: 'vm',
            bindToController: true
        };

        return directive;

        /** @ngInject */
        function BsSearchMapController($window, $location, $timeout, $scope, env, platformService,
                                       gmapService, searchConfigManager, $cookies, sharedService) {
            var vm = this;

            var searchGMap = sharedService.getSearchGMap();

            vm.numberOfRemainingItems = null;

            var _isMobile = shouldMobilelook();

            /**
             * @memberof searchMap
             * @method shouldMobilelook
             * @name shouldMobilelook
             * @description Returns true if screen size larger than map mobile look limit..
             * @returns {Boolean} isMobileOk
             */
            function shouldMobilelook() {
                if (platformService.getWidth() > 767) {
                    return false;
                } else {
                    return platformService.isMobile();
                }
            }

            vm.selectedListing = {};
            vm.showMobileWindow = false;

            /**
             * @memberof searchMap
             * @method enableCallBtn
             * @name enableCallBtn
             * @description Returns true of is mobile or screen soze < 768px.
             * @returns {Boolean} callBtnEnabled
             */
            var _enableCallBtn = function () {
                return platformService.isMobilePlatform();
            };

            var _baseurl = $location.protocol() + "://" + $location.host();
            if ($location.port() != '80' || $location.port() != '') {
                _baseurl = _baseurl + ':' + $location.port()
            }

            /**
             * @memberof searchMap
             * @method getListingUrl
             * @name getListingUrl
             * @description Returns business url.
             * @returns {String} businessUrl
             */
            var _getListingUrl = function (listing) {
                return _baseurl + env.searchsettings.businessUrl + '/' + listing.seoUrl;
            };

            var _havePhoneNumber = [];

            /**
             * @memberof searchMap
             * @name getCategory
             * @description Object containing category names.
             */
            var _categoryNames = [];

            /**
             * @memberof searchMap
             * @method getCategory
             * @name getCategory
             * @description Returns searched category.
             * @param {Object} listing
             * @param {Number} index
             * @returns {String} categoryName
             */
            var _getCategory = function (listing, index) {
                if (typeof _categoryNames[index] != 'undefined') {
                    return _categoryNames[index];
                } else if (
                    typeof listing.categories == 'undefined' ||
                    typeof listing.categories.category == 'undefined' ||
                    typeof listing.categories.category[0] == 'undefined' ||
                    typeof listing.categories.category[0].name == 'undefined') {
                    return '';
                } else {
                    _categoryNames[index] = listing.categories.category[0].name;
                    return _categoryNames[index];
                }
            };

            /**
             * @memberof searchMap
             * @method getHavePhoneNumber
             * @name getHavePhoneNumber
             * @description Returns true if business has phone number.
             * @param {Object} listing
             * @param {Number} index
             * @returns {Boolean} havePhoneNumber
             */
            var _getHavePhoneNumber = function (listing, index) {
                if (typeof _havePhoneNumber[index] == 'undefined') {
                    if (
                        typeof listing == 'undefined' ||
                        typeof listing.contacts == 'undefined' ||
                        typeof listing.contacts.contact == 'undefined' ||
                        listing.contacts.contact.length == 0) {
                        _havePhoneNumber[index] = false;
                    } else {
                        var found = 0;
                        angular.forEach(listing.contacts.contact, function (contact) {
                            if (typeof contact.type != 'undefined') {
                                if (contact.type == 'phone' || contact.type == 'national'
                                    || contact.type == 'mobile') {
                                    if (contact.value != "0200000000") {
                                        found = 1;
                                    }
                                }
                            }
                        });

                        if (found == 0) {
                            _havePhoneNumber[index] = false;
                        } else {
                            _havePhoneNumber[index] = true;
                        }
                    }
                }
                return _havePhoneNumber[index];
            };

            /**
             * @memberof searchMap
             * @method getPhoneNumber
             * @name getPhoneNumber
             * @description Returns business phone number.
             * @param {Object} listing
             * @param {Number} index
             * @returns {String} phoneNumber
             */
            var _getPhoneNumber = function (listing, index) {
                if (_getHavePhoneNumber(listing, index) == true) {
                    var _phoneNumber = null;
                    //check first for mobile
                    var found = 0;
                    angular.forEach(listing.contacts.contact, function (contact) {
                        if (contact.type == 'mobile' && found == 0) {
                            found = 1;
                            _phoneNumber = contact.value;
                        }
                    });

                    if (_phoneNumber == null) {
                        found = 0;
                        angular.forEach(listing.contacts.contact, function (contact) {
                            if (contact.type == 'phone' && found == 0) {
                                found = 1;
                                _phoneNumber = contact.value;
                            }
                        });
                    }

                    if (_phoneNumber == null) {
                        found = 0;
                        angular.forEach(listing.contacts.contact, function (contact) {
                            if (contact.type == 'national' && found == 0) {
                                found = 1;
                                _phoneNumber = contact.value;
                            }
                        });
                    }

                    if (_phoneNumber == "0200000000") {
                        _phoneNumber = null;
                    }

                    return _phoneNumber;
                }
            };

            /**
             * @memberof searchMap
             * @method getPhoneNumberFormated
             * @name getPhoneNumberFormated
             * @description Returns business phone number in user friendly view format.
             * @param {Object} listing
             * @param {Number} index
             * @returns {String} phoneNumberFormated
             */
            var _getPhoneNumberFormated = function (listing, index) {
                var _phoneNumber = _getPhoneNumber(listing, index);
                return _formatPhone(_phoneNumber);
            };

            /**
             * @memberof searchMap
             * @method _formatPhone
             * @name _formatPhone
             * @description Format phone number in user friendly view format.
             * @param {String} _phoneNumber
             * @returns {String} phoneNumberFormated
             */
            var _formatPhone = function (_phoneNumber) {
                var _formattedPhoneNumber = "";
                if (typeof _phoneNumber == 'undefined' || !_phoneNumber) {
                    return "";
                }
                if (_phoneNumber.length == 10) {
                    if (_phoneNumber.startsWith("04") || _phoneNumber.startsWith("1300") ||
                        _phoneNumber.startsWith("1800") || _phoneNumber.startsWith("1900")) {
                        _formattedPhoneNumber +=
                            _phoneNumber.substr(0, 4) + " " + _phoneNumber.substr(4, 3) + " "
                            + _phoneNumber.substr(7, 3);
                    } else {
                        _formattedPhoneNumber += "(" + _phoneNumber.substr(0, 2) + ") ";
                        _formattedPhoneNumber +=
                            _phoneNumber.substr(2, 4) + " " + _phoneNumber.substr(6);
                    }
                } else if (_phoneNumber.length == 6) {
                    _formattedPhoneNumber +=
                        _phoneNumber.substr(0, 2) + " " + _phoneNumber.substr(2, 2) + " "
                        + _phoneNumber.substr(4, 2);
                } else {
                    _formattedPhoneNumber = _phoneNumber;
                }
                return _formattedPhoneNumber;
            };

            vm.showMobileWindow = _isMobile;

            /**
             * @memberof searchMap
             * @name mapContainerOffsetHeight
             * @description Define map offset based on screen size.
             */
            var _mapContainerOffsetHeight = (vm.showMobileWindow) ? searchGMap.mobileMapHeight
                : searchGMap.desktopMapHeight;

            var _windowElement = angular.element($window),
                _headerHolder = angular.element(document.querySelector('header'))[0].clientHeight,
                _breadcrumbs = (angular.isDefined(angular.element(document.querySelector('.breadcrumbs')))[0]) ? angular.element(document.querySelector('.breadcrumbs'))[0].clientHeight : 0,
                _viewHolder = (angular.element(document.querySelector('.view-holder'))[0].clientHeight + parseInt(
                    angular.element(document.querySelector('.view-tools')).css('paddingTop'))),
                _calculatedBaseOffset = (_headerHolder + _breadcrumbs + _viewHolder);

            vm.mapContainerDimensions = {width: "100px", height: "100px"};

            var _breadCrumbsCustm = (angular.element(document.querySelector(".breadcrumbsWrapper")).length == 2)
                ? angular.element(document.querySelector(".breadcrumbsWrapper")).first()[0].clientHeight : 0;
            var windowHeight = window.outerHeight!=0 ? window.outerHeight : screen.height;
            var windowWidth = window.outerWidth!=0 ? window.outerWidth : screen.width;
            var _mapContainerDimensions = {
                height: (windowHeight - (angular.element(document.querySelector('header'))[0].clientHeight
                                                             + _breadCrumbsCustm + parseInt(
                    angular.element(document.querySelector('.view-tools'))[0].clientHeight) + _mapContainerOffsetHeight)) + "px",
                width: windowWidth + "px"
            };
            angular.extend(vm.mapContainerDimensions, _mapContainerDimensions);

            /**
             * @memberof searchMap
             * @method mapContainerFit
             * @name mapContainerFit
             * @description Sets map dimensions.
             */

            var _resizeHandlerTimeout = null,
                _previousRequest = null,
                _lastRequest = null;
            var _resizeHandler = function () {
                if (_resizeHandlerTimeout) {
                    _lastRequest = true;
                    return;
                }
                _lastRequest = null;
                vm.handleResizeOfBox();
                _resizeHandlerTimeout = setTimeout(function () {
                    $timeout(function () {
                        google.maps.event.trigger(googleMap, 'resize');
                    }).then(function () {
                        $timeout(function () {
                            if (_markers && _markers.length) {
                                markerCluster.fitMapToMarkers();
                            } else {
                                googleMap.setZoom(4);
                                googleMap.setCenter(searchGMap.defaultMapOptions.center);
                            }
                            clearTimeout(_resizeHandlerTimeout);
                            _resizeHandlerTimeout = null;
                        }, 100).then(function () {

                            if (_lastRequest) {
                                _resizeHandler();
                            }
                        });
                    });
                }, 170);
            };

            /**
             * @memberof searchMap
             * @event onWindowResize
             * @name onWindowResize
             * @description Returns all business that have coordinates.
             * @returns {Array} coordsObj
             */
            var _getAllCoordsObj = function () {
                var _obj = [];
                var _type = 's'; //string

                angular.forEach(vm.searchData.listing, function (listing, index) {
                    if (vm.showMarker == null) {
                        if (_getHaveCoords(listing, index) == true) {

                            _obj.push(_getCoords(listing, index, _type).split(','));
                        }
                    } else {
                        if (listing.id == vm.showMarker) {
                            if (_getHaveCoords(listing, index) == true) {

                                _obj.push(_getCoords(listing, index, _type).split(','));
                            }
                        }
                    }
                });
                return _obj;
            };

            var _haveCoords = [];

            /**
             * @memberof searchMap
             * @method getHaveCoords
             * @name getHaveCoords
             * @description Returns true if business has entered coordinates.
             * @param {Object} listing
             * @param {Number} index
             */

            var _getHaveCoords = function (listing, index) {

                if (typeof _haveCoords[listing.id] == 'undefined') {
                    if (angular.isUndefined(listing.addresses) || angular.isUndefined(
                            listing.addresses.address)
                        || angular.isUndefined(listing.addresses.address[0]) || angular.isUndefined(
                            listing.addresses.address[0].longitude)
                        || angular.isUndefined(listing.addresses.address[0].latitude)) {
                        _haveCoords[listing.id] = false;
                    } else {
                        //check if is full address
                        var address = listing.addresses.address[0];
                        if ((angular.isUndefined(address.streetName) || address.streetName.length
                                                                        == 0)
                            && ((angular.isUndefined(address.addressLine1)
                                 || address.addressLine1.length == 0 || address.addressLine1.length
                                                                        > 50) &&
                            (angular.isUndefined(address.addressLine2)
                             || address.addressLine2.length == 0 || address.addressLine2.length
                                                                    > 50))) {
                            _haveCoords[listing.id] = false;
                        }
                        if (angular.isUndefined(_haveCoords[listing.id])) {

                            var founded = false;
                            angular.forEach(vm.coords, function (coord) {
                                if (coord.latitude == listing.addresses.address[0].latitude
                                    && coord.logitude == listing.addresses.address[0].logitude) {
                                    founded = true;
                                }
                            });
                            if (founded == false) {
                                _haveCoords[listing.id] = true;
                            } else {
                                coords.push({
                                                latitude: listing.addresses.address[0].latitude,
                                                longitude: listing.addresses.address[0].logitude
                                            });
                                _haveCoords[listing.id] = false;
                            }
                        }
                    }
                }

                return _haveCoords[listing.id];
            };

            //var _getHaveCoords = function (listing, index) {
            //  if (typeof _haveCoords[listing.id] == 'undefined') {
            //    if (typeof listing.addresses == 'undefined') {
            //      _haveCoords[listing.id] = false;
            //    } else if (typeof listing.addresses.address == 'undefined') {
            //      if (typeof listing.addresses[0] == 'undefined') {
            //        _haveCoords[listing.id] = false;
            //      } else if (typeof listing.addresses[0].latitude == 'undefined' || typeof
            // listing.addresses[0].longitude == 'undefined') { _haveCoords[listing.id] = false; }
            // else { _haveCoords[listing.id] = true; } } else if (typeof
            // listing.addresses.address[0] == 'undefined') { _haveCoords[listing.id] = false; }
            // else if (typeof listing.addresses.address[0].latitude == 'undefined' || typeof
            // listing.addresses.address[0].longitude == 'undefined') { _haveCoords[listing.id] =
            // false; } else { _haveCoords[listing.id] = true; } }  return _haveCoords[listing.id];
            // };

            /**
             * @memberof searchMap
             * @method getCoords
             * @name getCoords
             * @description Get coordinates .
             * @param {Object} listing
             */
            var _getCoords = function (listing, index, type) {
                if (_getHaveCoords(listing, index) == true) {
                    if (typeof type == 'undefined') {
                        if (typeof listing.addresses.address != 'undefined') {
                            return {
                                latitude: listing.addresses.address[0].latitude,
                                longitude: listing.addresses.address[0].longitude
                            }
                        } else {
                            return {
                                latitude: listing.addresses[0].latitude,
                                longitude: listing.addresses[0].longitude
                            }
                        }
                    } else {
                        if (typeof listing.addresses.address
                            != 'undefined') {
                            return listing.addresses.address[0].latitude + ','
                                   + listing.addresses.address[0].longitude;
                        } else {
                            return listing.addresses[0].latitude + ','
                                   + listing.addresses[0].longitude;
                        }
                    }
                }
            };

            var _markers = [];
            var _coords = [];

            /**
             * @memberof searchMap
             * @method inCoords
             * @name inCoords
             * @description Get coordinates.
             * @param {Object} _lat
             * @param {Object} _lng
             */
            var _inCoords = function (_lat, _lng) {
                var _found = false;
                angular.forEach(_coords, function (coord) {
                    if (coord.lat == _lat && coord.lng == _lng) {
                        _found = true;
                    }
                });
                if (!_found) {
                    _coords.push({lat: _lat, lng: _lng});
                }
                return _found;
            };

            var _coordsOffest = [];

            /**
             * @memberof searchMap
             * @method getCoordsOffset
             * @name getCoordsOffset
             * @description Get coordinates .
             * @param {Object} listing
             * @returns {Array} hasImage
             */
            var _getCoordsOffset = function (_lat, _lng) {
                var index = String(_lat) + String(_lng);
                if (typeof _coordsOffest[index] == 'undefined') {
                    _coordsOffest[index] = {offsetlat: 0.00004, offsetlng: 0.00004, case: 1};
                } else {

                    switch (_coordsOffest[index].case) {
                        case 1:
                            _coordsOffest[index].case = 2;
                            break;
                        case 2:
                            _coordsOffest[index].case = 3;
                            break;
                        case 3:
                            _coordsOffest[index].case = 4;
                            break;
                        case 4:
                            _coordsOffest[index].offsetlat =
                                _coordsOffest[index].offsetlat + 0.00004;
                            _coordsOffest[index].offsetlng =
                                _coordsOffest[index].offsetlng + 0.00004;
                            _coordsOffest[index].case = 1;
                            break;
                    }
                }

                return _coordsOffest[index];
            };

            var _clickOpening = null;

            function getMarkerPositionOnScreen(_marker) {
                var scale = Math.pow(2, googleMap.getZoom());
                var nw = new google.maps.LatLng(
                    googleMap.getBounds().getNorthEast().lat(),
                    googleMap.getBounds().getSouthWest().lng()
                );
                var worldCoordinateNW = googleMap.getProjection().fromLatLngToPoint(nw);
                var worldCoordinate = googleMap.getProjection()
                    .fromLatLngToPoint(_marker.getPosition());
                var pixelOffset = new google.maps.Point(
                    Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
                    Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale)
                );
                return pixelOffset;
            }

            var _isChrome, _isSafari, _bodyWindowEl, _initialPositionOfMain = angular.element(
                document.querySelector('#main'))[0].offsetTop;

            function modifyInfoBoxToFitViewPort(_marker, _infoBox, _infowindowPointer) {
                //check marker position
                //check infowindow (if priority fits as default) and checks if default fits
                var _scale = Math.pow(2, (21 - googleMap.getZoom()));
                var _markerPos = getMarkerPositionOnScreen(_marker);
                var _screenSize = {width: _windowElement[0].outerWidth, height: _windowElement[0].outerHeight};
                var _infoBoxElement = angular.element(document.querySelector(_infoBox)).children(0);
                var _infoBoxHeight = _infoBoxElement[0].outerHeight;
                var _infoBoxDim = {width: 309, height: _infoBoxHeight};
                var _breadCrumbs = (angular.element(document.querySelector(".breadcrumbsWrapper")).length == 2)
                    ? angular.element(document.querySelector(".breadcrumbsWrapper")).first()[0].clientHeight : 0;
                var _right = false;
                var _specialSwitch = 0;

                if (_infoBoxDim.width + _markerPos.x + 30 <= _screenSize.width) {
                    //_location.right = true;
                    _right = false;
                } else {
                    //_location.right = false;
                    _right = true;
                }

                var __x = (angular.element(document.querySelector('#main'))[0].offsetTop + parseInt(angular.element(document.querySelector('body')).css('padding-top')))
                          - _windowElement[0].scrollY;

                var _x = (-1) * __x;

                var _topMarginMin = (angular.element(document.querySelector('header'))).prop('offsetHeight') + angular.element(document.querySelector('.view-tools')).prop('offsetHeight')
                                    + _x;
                _topMarginMin = (_topMarginMin <= 0 ) ? 0 : _topMarginMin;
                var _bottomMarginMax = angular.element(document.querySelector('#main'))[0].clientHeight;

                if (_topMarginMin >= _markerPos.y - 77) {
                    //console.log('mora da ide sa donje');
                    _specialSwitch = 2;
                } else if (_bottomMarginMax < _markerPos.y + 10) {
                    //console.log('mora da ide sa gornje');
                    _specialSwitch = 3;
                } else if (_bottomMarginMax < (_markerPos.y) + (_infoBoxHeight - 17)) {
                    //console.log('mora da ide sa gornje 2');
                    _specialSwitch = 1;
                } else {
                    _specialSwitch = 0;
                }

                if (_right) {
                    switch (_specialSwitch) {
                        case 0:
                            _infowindowPointer.setOptions({
                                                              pixelOffset: new google.maps.Size(-337, -67),
                                                              boxClass: 'infoBox arrowRight'
                                                          });
                            break;
                        case 1:
                            _infowindowPointer.setOptions({
                                                              pixelOffset: new google.maps.Size(-337, (-1
                                                                                                       * (_infoBoxHeight
                                                                                                          - 17))),
                                                              boxClass: 'infoBox arrowRight bottom'
                                                          });
                            break;
                        case 2:
                            _infowindowPointer.setOptions({
                                                              pixelOffset: new google.maps.Size(-287, 15),
                                                              boxClass: 'infoBox arrowTop right-align'
                                                          });
                            break;
                        case 3:
                            _infowindowPointer.setOptions({
                                                              pixelOffset: new google.maps.Size(-287, (-1
                                                                                                       * (_infoBoxHeight
                                                                                                          + 50))),
                                                              boxClass: 'infoBox arrowBottom right-align'
                                                          });
                            break;
                    }
                } else {
                    switch (_specialSwitch) {
                        case 0:
                            _infowindowPointer.setOptions({
                                                              pixelOffset: new google.maps.Size(30, -67),
                                                              boxClass: 'infoBox arrowLeft'
                                                          });
                            break;
                        case 1:
                            _infowindowPointer.setOptions({
                                                              pixelOffset: new google.maps.Size(30, (-1
                                                                                                     * (_infoBoxHeight
                                                                                                        - 17))),
                                                              boxClass: 'infoBox arrowLeft bottom'
                                                          });
                            break;
                        case 2:
                            _infowindowPointer.setOptions({
                                                              pixelOffset: new google.maps.Size(-20, 15),
                                                              boxClass: 'infoBox arrowTop left-align'
                                                          });
                            break;
                        case 3:
                            _infowindowPointer.setOptions({
                                                              pixelOffset: new google.maps.Size(-20, (-1
                                                                                                      * (_infoBoxHeight
                                                                                                         + 50))),
                                                              boxClass: 'infoBox arrowBottom left-align'
                                                          });
                            break;
                    }
                }

            }

            /**
             * @memberof searchMap
             * @event markerMouseOver
             * @name markerMouseOver
             * @description Behavior, user hover marker.
             * @param {Object} _googleMarker
             */
            function markerMouseOver(_googleMarker, e) {
                if (isIpad) {
                    return;
                }

                if (_isdown) {
                    return;
                }
                _googleMarker.setIcon(searchGMap.markerSelectedIcon);
            }

            /**
             * @memberof searchMap
             * @event markerMouseOut
             * @name markerMouseOut
             * @description Behavior, user hover marker.
             */
            function markerMouseOut(_googleMarker, e) {
                if (isIpad) {
                    return;
                }

                if (_isdown) {
                    return;
                }

                if (!vm.selectedListing ||
                    (vm.selectedListing && vm.selectedListing.listing
                     && vm.selectedListing.listing.id != _googleMarker.data.listing.id) ||
                    (vm.selectedListing && !vm.selectedListing.listing)) {
                    _googleMarker.setIcon(searchGMap.markerDefaultIcon);
                }
            }

            /**
             * @memberof searchMap
             * @event markerMouseDown
             * @name markerMouseDown
             * @description Behavior, markerMouseDown.
             */
            function markerMouseDown(_googleMarker, e) {
                if (isIpad) {
                    if (currentSelectedMarker && currentSelectedMarker.setIcon) {
                        currentSelectedMarker.setIcon(searchGMap.markerDefaultIcon);
                    }

                    if (vm.selectedListing && vm.selectedListing.listing
                        && _googleMarker.data.listing.id == vm.selectedListing.listing.id) {
                        if (_clickOpening) {
                            return;
                        }

                        currentSelectedMarker = null;
                        _googleMarker.setIcon(searchGMap.markerDefaultIcon);

                        closeInfoWindow();
                    } else {
                        if (_clickOpening) {
                            $timeout.cancel(_clickOpening);
                            _clickOpening = null;
                        }

                        currentSelectedMarker = _googleMarker;
                        currentSelectedMarker.setIcon(searchGMap.markerSelectedIcon);

                        _createTooltipWindow(currentSelectedMarker)
                    }
                    return false;
                } else {
                    _isdown = true;
                }
            }

            /**
             * @memberof searchMap
             * @event markerMouseUp
             * @name markerMouseUp
             * @description Behavior, markerMouseUp.
             */
            function markerMouseUp(_googleMarker, e) {
                if (!isIpad) {
                    _isdown = false;
                }
            }

            var infowindow, currentSelectedMarker, _isdown = false;

            function closeInfoWindow() {
                if (infowindow && infowindow.div_ && infowindow.close) {
                    infowindow.close();
                }
                $timeout(function () {
                    vm.selectedListing = {};
                });
            }

            /**
             * @memberof searchMap
             * @event markerClick
             * @name markerClick
             * @description Behavior, markerClick.
             */
            function markerClick(_googleMarker, e) {
                if (currentSelectedMarker && currentSelectedMarker.setIcon) {
                    currentSelectedMarker.setIcon(searchGMap.markerDefaultIcon);
                }

                if (vm.selectedListing && vm.selectedListing.listing
                    && _googleMarker.data.listing.id == vm.selectedListing.listing.id) {
                    if (_clickOpening) {
                        return;
                    }

                    currentSelectedMarker = null;
                    _googleMarker.setIcon(searchGMap.markerDefaultIcon);

                    closeInfoWindow();
                } else {
                    if (_clickOpening) {
                        $timeout.cancel(_clickOpening);
                        _clickOpening = null;
                    }

                    currentSelectedMarker = _googleMarker;
                    currentSelectedMarker.setIcon(searchGMap.markerSelectedIcon);

                    _createTooltipWindow(currentSelectedMarker);
                }
                return false;
            }

            var _positions = _getAllCoordsObj();

            /**
             * @memberof searchMap
             * @method getBoundsForMarkers
             * @name getBoundsForMarkers
             * @description Return bounds to display marker to be inside of visible area.
             * @param {Object} clusterBlockMarkers
             * @returns {Object} _bounds
             */
            function getBoundsForMarkers(clusterBlockMarkers) {
                var _bounds = new google.maps.LatLngBounds();
                var latlng;
                if (clusterBlockMarkers) {
                    for (var i1 = 0; i1 < clusterBlockMarkers.length; i1++) {
                        latlng =
                            new google.maps.LatLng(clusterBlockMarkers[i1].getPosition()
                                .lat(), clusterBlockMarkers[i1].getPosition().lng());
                        _bounds.extend(latlng);
                    }
                } else {
                    _positions = _getAllCoordsObj();
                    for (var i2 = 0; i2 < _positions.length; i2++) {
                        latlng = new google.maps.LatLng(_positions[i2][0], _positions[i2][1]);
                        _bounds.extend(latlng);
                    }
                }
                return _bounds;
            }

            var ua = navigator.userAgent,
                isIpad = (ua.match(/iPad/i)) ? true : false;

            var _initiated = false;

            var _infoBoxProp = searchGMap.infoboxProperties,
                _zoomLevelForSingleMarker = (vm.showMobileWindow)
                    ? searchGMap.singleListSelectedMarkerZoomLevelForMobile
                    : searchGMap.singleListSelectedMarkerZoomLevelForDesktop,
                googleMap, googleMapView, markerCluster;

            /**
             * @memberof searchMap
             * @method createMarker
             * @name createMarker
             * @description Return bounds to display marker to be inside of visible area.
             * @param {Object} _markerPosition
             * @param {Object} _listingData
             * @param {Object} isSelected
             * @returns {Object} marker
             */

            function createMarker(_markerPosition, _listingData, isSelected) {
                var _markerSelectedUrl = searchGMap.markerSelectedIcon,
                    _markerDefaultUrl = searchGMap.markerDefaultIcon,
                    _markerIcon = (isSelected) ? _markerSelectedUrl : _markerDefaultUrl;

                var marker = new google.maps.Marker({
                    position: _markerPosition,
                    data: _listingData,
                    icon: _markerIcon,
                    options: searchGMap.defaultMarkerOptions
                });
                gmapService.getInstance();

                google.maps.event.addListener(marker, 'mouseover',
                                              angular.bind(marker, markerMouseOver, marker));
                google.maps.event.addListener(marker, 'mouseout',
                                              angular.bind(marker, markerMouseOut, marker));
                google.maps.event.addListener(marker, 'mousedown',
                                              angular.bind(marker, markerMouseDown, marker));
                google.maps.event.addListener(marker, 'mouseup',
                                              angular.bind(marker, markerMouseUp, marker));
                if (!isIpad) {
                    google.maps.event.addListener(marker, 'click',
                                                  angular.bind(marker, markerClick, marker));
                }

                return marker;
            }

            this.resizeHappened = false;
            this.handleResizeOfBox = function () {
                //if(vm.showMobileWindow == shouldMobilelook() &&
                //  _breadCrumbsCustm == ((angular.element(".breadcrumbsWrapper").length == 2) ?
                // angular.element(".breadcrumbsWrapper").first().height() : 0) &&
                // _mapContainerOffsetHeight == (vm.showMobileWindow) ? searchGMap.mobileMapHeight
                // : searchGMap.desktopMapHeight){ return; }
                vm.showMobileWindow = shouldMobilelook();
                _breadCrumbsCustm =
                    (angular.element(document.querySelector(".breadcrumbsWrapper")).length == 2) ? angular.element(
                                                                                document.querySelector(".breadcrumbsWrapper"))
                                                                             .first()[0].clientHeight : 0;
                _mapContainerOffsetHeight =
                    (vm.showMobileWindow) ? searchGMap.mobileMapHeight
                        : searchGMap.desktopMapHeight;
                var windowHeight = window.outerHeight!=0 ? window.outerHeight : screen.height;
                var windowWidth = window.outerWidth!=0 ? window.outerWidth : screen.width;
                _mapContainerDimensions = {
                    height: (windowHeight - (angular.element(document.querySelector('header'))[0].clientHeight
                                                                 + _breadCrumbsCustm + parseInt(
                        angular.element(document.querySelector('.view-tools'))[0].clientHeight) + _mapContainerOffsetHeight))
                            + "px",
                    width: windowWidth + "px"
                };
                angular.extend(vm.mapContainerDimensions, _mapContainerDimensions);
                vm.resizeHappened = true;
            }

            /**
             * @memberof searchMap
             * @method prepareData
             * @name prepareData
             * @description Preparation of data to update info modal.
             */
            function prepareData() {
                var _searchConfigParams = searchConfigManager.getNumberOfBlocksAndLimit(),
                    _numberOfBlocks = _searchConfigParams[0],
                    _numberOfLimit = searchConfigManager.getLoadingListLimit(),
                    _totalToBeShown = _numberOfBlocks * _numberOfLimit,
                    _firstLoadingBlock = (_totalToBeShown > 20) ? _totalToBeShown : 20;

                angular.forEach(vm.searchData.listing, function (listing, index) {

                    if (index >= _firstLoadingBlock) {
                        return;
                    }
                    if (listing.type === 'TILES') {
                        return;
                    }

                    //if (angular.isUndefined(listing.is_in_scope) || !listing.is_in_scope) {
                    //  console.log('blocked');
                    //  return;
                    //}
                    //console.log(index);

                    var _lat = 0, _lng = 0, offset, listingdata;

                    if (angular.isDefined(listing.addresses)) {
                        if (angular.isDefined(listing.addresses.address)) {
                            _lat = listing.addresses.address[0].latitude;
                            _lng = listing.addresses.address[0].longitude
                        } else {
                            _lat = listing.addresses[0].latitude;
                            _lng = listing.addresses[0].longitude;
                        }
                    }

                    if (_inCoords(_lat, _lng)) {
                        offset = _getCoordsOffset(_lat, _lng);
                        switch (offset.case) {
                            case 1:
                                _lat = _lat + offset.offsetlat;
                                _lng = _lng + offset.offsetlng;
                                break;
                            case 2:
                                _lat = _lat - offset.offsetlat;
                                _lng = _lng + offset.offsetlng;
                                break;
                            case 3:
                                _lat = _lat - offset.offsetlat;
                                _lng = _lng - offset.offsetlng;
                                break;
                            case 4:
                                _lat = _lat + offset.offsetlat;
                                _lng = _lng - offset.offsetlng;
                                break;
                        }
                    }

                    listingdata = {
                        listing: listing,
                        hasPhone: _getHavePhoneNumber(listing, index),
                        phone: _getPhoneNumberFormated(listing, index),
                        category: _getCategory(listing, index),
                        listingUrl: _getListingUrl(listing),
                        listingUrlReviews: _getListingUrl(listing) + '#target=reviews',
                        isMobile: _isMobile,
                        enableCallBtn: _enableCallBtn
                    };

                    /*If user was on map with one marker and had gone to BDP
                      set the same marker when user clicks back from BDP */
                    if($cookies.get('tl_map_mrkr')){
                        vm.showMarker = $cookies.get('tl_map_mrkr');
                        $cookies.remove('tl_map_mrkr', {path: '/'});
                    }

                    if (vm.showMarker == null) {
                        if (_getHaveCoords(listing, index)) {
                            _markers.push(
                                createMarker(new google.maps.LatLng(_lat, _lng), listingdata,
                                             false));
                        }

                    } else {
                        if (listing.id == vm.showMarker && isFinite(_lat) && isFinite(_lng)) {
                            _markers.push(
                                createMarker(new google.maps.LatLng(_lat, _lng), listingdata,
                                             true));
                        }
                    }
                });
            }

            var _zoomChangedEvent;

            function zoomChanged() {
                if (!vm.showMarker) {
                    _isdown = false;

                    if (_clickOpening) {
                        $timeout.cancel(_clickOpening);
                        _clickOpening = null;
                    }

                    if (currentSelectedMarker && currentSelectedMarker.setIcon) {
                        currentSelectedMarker.setIcon(searchGMap.markerDefaultIcon);
                    }
                    currentSelectedMarker = null;
                    closeInfoWindow();
                }
            }

            /**
             * @memberof searchMap
             * @method unbindAndRemovePreviousMarkers
             * @name unbindAndRemovePreviousMarkers
             * @description Remove old markers before updating view.
             */
            function unbindAndRemovePreviousMarkers() {
                if (_markers && _markers.length) {
                    for (var _markerIndex = 0, _markersListSize = _markers.length;
                         _markerIndex < _markersListSize; _markerIndex++) {
                        _markers[_markerIndex].setMap(null);
                        google.maps.event.clearInstanceListeners(_markers[_markerIndex]);
                    }
                }
                _markers.length = 0;
            }

            function getMaxZoom() {
                var maximumZoom = searchGMap.markerClusterOptions.maxZoom;
                if (vm.showMarker) {
                    maximumZoom = _zoomLevelForSingleMarker;
                } else {
                    if(_markers.length == 1) {
                        maximumZoom = _zoomLevelForSingleMarker;
                    } else {
                        maximumZoom = searchGMap.markerClusterOptions.maxZoom;
                    }
                }
                return maximumZoom;
            }

            var _refreshMapAndMarkers = function (e, _markerId, _linkReference) {
                if (!_initiated && angular.element(document.querySelector("#map"))[0].hidden) {
                    vm.showMarker = _markerId;
                    $timeout(function () {
                        _completeMapSetup(true);
                    }, 100, false);
                    return;
                }

                //if(_markerId && vm.showMarker && vm.showMarker == _markerId){
                //  currentSelectedMarker = _markers[0];
                //  currentSelectedMarker.setIcon(searchGMap.markerSelectedIcon);
                //  _createTooltipWindow(currentSelectedMarker);
                //  return;
                //} else {
                vm.showMarker = _markerId || null;
                //}

                unbindAndRemovePreviousMarkers();
                currentSelectedMarker = null;
                closeInfoWindow();

                if (markerCluster) {
                    markerCluster.clearMarkers();
                }

                prepareData();

                $timeout(function () {
                    vm.handleResizeOfBox();
                }).then(function () {
                    if (vm.resizeHappened) {
                        google.maps.event.trigger(googleMap, 'resize');
                        vm.resizeHappened = false;
                    }

                    $timeout(function () {
                        if (markerCluster && _markers.length) {
                            googleMap.setOptions({maxZoom: getMaxZoom()});

                            markerCluster.addMarkers(_markers, false);
                            markerCluster.fitMapToMarkers();

                            if (vm.showMarker) {
                                currentSelectedMarker = _markers[0];
                                currentSelectedMarker.setIcon(searchGMap.markerSelectedIcon);
                                _createTooltipWindow(currentSelectedMarker);
                            }

                        } else {
                            googleMap.setZoom(4);
                            googleMap.setCenter(searchGMap.defaultMapOptions.center);
                        }
                    }, 50);
                });
                if(!_markerId)
                {
                    vm.openRestOfItems();
                }
            };

            var _handleMapClick = angular.bind(this, function () {
                angular.element(document.querySelector('.icon-close.map-tooltip')).off('click');

                if (currentSelectedMarker && currentSelectedMarker.setIcon) {
                    currentSelectedMarker.setIcon(searchGMap.markerDefaultIcon);
                }
                currentSelectedMarker = null;
                closeInfoWindow();
            });

            var _redirectToBdp = function (e) {
                /*This is will be used to place single marker on the last searched location
                 if user chooses to go back from BDP to search page which was on map view */
                $cookies.put('tl_map_mrkr',vm.showMarker, {path:'/'});

                var isRatingEvent = e.originalEvent.target.parentNode.className == "rating-holder"
                                    || e.originalEvent.target.className == "rating-holder"
                                    || e.originalEvent.target.className.indexOf("star") != -1;
                if (!isRatingEvent) {
                    $window.location = $location.absUrl().split($location.absUrl())[0] +
                                       "/business/" + vm.selectedListing.listing.seoUrl;
                }
            };

            $scope.$on('$destroy', function () {
                if (googleMap) {
                    googleMap.setOptions({maxZoom: null});
                }

                if (markerCluster) {
                    markerCluster.clearMarkers();
                }

                google.maps.event.removeListener(_zoomChangedEvent);

                if (googleMap) {
                    google.maps.event.clearListeners(googleMap, 'click');
                }

                unbindAndRemovePreviousMarkers();
                closeInfoWindow();

                if (currentSelectedMarker && currentSelectedMarker.setMap) {
                    currentSelectedMarker.setMap(null);
                    currentSelectedMarker = null;
                }

                angular.element(document.querySelector('.gm-style-iw')).off('click');
                angular.element(document.querySelector('.icon-close.map-tooltip')).off('click');
                angular.element($window).off('refresh-map', _refreshMapAndMarkers);
                angular.element($window).off('map-is-ready', _completeMapSetup);
                angular.element($window).off('resize', _resizeHandler);
            });

            var _createTooltipWindow = function (_marker) {
                $timeout(function () {
                    vm.selectedListing = {};
                }).then(function () {
                    $timeout(function () {
                        angular.extend(vm.selectedListing, _marker.data);
                    }).then(function () {
                        if (!vm.showMobileWindow) {
                            _clickOpening = $timeout(angular.bind(this, function (_m) {
                                var template = angular.element(document.querySelector("#clickedItem")).html();
                                if (!template || !_clickOpening) {
                                    _clickOpening = null;
                                    return;
                                }
                                modifyInfoBoxToFitViewPort(_m, "#clickedItem", infowindow);
                                infowindow.setContent(template);
                                infowindow.open(googleMap, _m);
                                _clickOpening = null;
                            }, _marker), 150).then(function () {
                                $timeout(function () {
                                    angular.element(document.querySelector('.gm-style-iw')).on('click', _redirectToBdp);
                                });
                            });
                        } else {
                            $timeout(function () {
                                angular.element(document.querySelector('.icon-close.map-tooltip'))
                                    .on('click', _handleMapClick);
                            })
                        }
                    });
                });
            };

            var _completeMapSetup = function (_forceRedraw) {
                if (!_initiated && !angular.element(document.querySelector("#map"))[0].hidden && vm.searchData) {
                    _initiated = true;
                }
                /* Maps loads without this code.
                 ** So commenting it before regression to make sure this repeated call is not required. It fills the stack
                 */
                //else {
                //     if (!_initiated && _forceRedraw) {
                //         $timeout(function () {
                //             _completeMapSetup(true);
                //         }, 1000);
                //     }
                //     return;
                // }

                googleMap = gmapService.getInstance();

                _isChrome = platformService.isChrome();
                _isSafari = platformService.isSafari();
                _bodyWindowEl =
                    (_isChrome || _isSafari) ? angular.element(document.querySelector('body,window')) : angular.element(document.querySelector(
                                                 'body,html'));

                googleMapView = gmapService.getMap();

                googleMapView.style.height = "100%";
                googleMapView.style.width = "100%";
                angular.element(document.querySelector('#map')).append(googleMapView);

                vm.handleResizeOfBox();

                markerCluster = gmapService.getCluster();
                
                prepareData();

                googleMap.setOptions(
                    {styles: searchGMap.defaultPointOfInterestStyling, maxZoom: getMaxZoom()});
                google.maps.event.addListener(googleMap, 'click', _handleMapClick);
                infowindow = new InfoBox(_infoBoxProp);

                markerCluster.addMarkers(_markers, false);

                if (vm.showMarker) {
                    googleMap.setOptions({maxZoom: _zoomLevelForSingleMarker});
                    currentSelectedMarker = _markers[0];
                    if(currentSelectedMarker)
                        currentSelectedMarker.setIcon(searchGMap.markerSelectedIcon);
                    _createTooltipWindow(currentSelectedMarker);
                } else if (!_forceRedraw) {
                    if (_markers && _markers.length) {
                        markerCluster.fitMapToMarkers();
                    } else {
                        googleMap.setZoom(4);
                        googleMap.setCenter(searchGMap.defaultMapOptions.center);
                    }
                }

                if (_forceRedraw) {
                    google.maps.event.trigger(googleMap, 'resize');
                    $timeout(function () {
                        if (_markers && _markers.length) {
                            markerCluster.fitMapToMarkers();
                        } else {
                            googleMap.setZoom(4);
                            googleMap.setCenter(searchGMap.defaultMapOptions.center);
                        }
                    }, 170).then(function () {
                        _zoomChangedEvent =
                            google.maps.event.addListener(googleMap, 'zoom_changed', zoomChanged);
                        angular.element($window).on('refresh-map', _refreshMapAndMarkers);
                        angular.element($window).on('resize', _resizeHandler);
                        window.truncateCounter = 0;
                    });
                } else {
                    angular.element($window).on('resize', _resizeHandler);
                }

                if (_initiated) {
                    vm.openRestOfItems();
                }
            }

            vm.openRestOfItems = function () {
                var viewportWidth = angular.element(window)[0].outerWidth;
                var offset = angular.isDefined(angular.element(document.querySelector('.view-tools .view-options'))) ?
                                angular.element(document.querySelector('.view-tools .view-options')).prop('offsetLeft') : 0;
                offset = (offset ? 100 / viewportWidth * offset : 10) + '%';
                vm.remainingPopupOffset = offset;
                vm.numberOfRemainingItems = vm.searchData.size - _markers.length;
                angular.element(document.querySelector('.rest-of-items')).css('display', 'block');

                $timeout(function () {
                    vm.closeRestOfItems();
                }, 5000);//This timeout is on purpose to close the rest of items after 5 seconds(Business requirement)
            };

            vm.closeRestOfItems = function () {
                angular.element(document.querySelector('.rest-of-items')).css('display', 'none');
            };

            if (gmapService.isready()) {
                $timeout(function () {
                    _completeMapSetup(true);
                }, 150);
            } else {
                angular.element(window).on('map-is-ready', angular.bind(this, _completeMapSetup));
            }
        }
    }
})();