(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('bushfiresupportBanner', bushfiresupportBanner);

    /** @ngInject */
    function bushfiresupportBanner() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bushfiresupport/banner/bushfiresupportbanner.html'
        };

        return directive;
    }

})();
