(function() {
    'use strict';

    angular
        .module('truelocal')
        .controller('WebsitesController', WebsitesController);

    /** @ngInject */
    function WebsitesController(Seo, DTM, env, footerUrls, $state, appService) {
        var vm = this;

        if (DTM.hasOneTrustScript()) {
            DTM.fireEventAtPageLoad($state.current.name);
        } else {
            DTM.trackPage();
        }

        Seo.pageTitle.set('Business Builder Website | True Local');
        Seo.description.set('Get 10% off when you sign up to our True Local Priority Listing Product and the all-new Business Builder Website and give your business a boost with its online presence.');

        var mediaBucket = 'cover/advertising/';
        vm.heroBackgroundImage = env.media + mediaBucket + 'websites.jpg';

        vm.phoneClick = function() {
            DTM.trackAnalyticOnClick('phone number click');
        }

        vm.cmsGetHelpAdvertising = footerUrls.cmsGetHelpAdvertising.url;

        appService.displayFooter();
    }
})();
