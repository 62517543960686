(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('serviceResult', ['serviceConfigManager', function(serviceConfigManager) {
            var keyword = serviceConfigManager.getProperty('keyword');
            var _createdTitle = ' recommended businesses for <b class=\'text-capitalize\'>' + keyword + '</b>';

            return {
                restrict: 'E',
                replace: true,
                template: '<span>' + _createdTitle + '</span>',
            };
        }]);
})();
