(function() {
    'use strict';

    angular
    .module('truelocal')
    .factory('formatGenerator', formatGenerator);

  /** @ngInject */
    function formatGenerator() {
    // 50DE9F70-AAD9-4583-9D37-0FBAA7D76E7D

    /** ***
     * Receives Data Model and API Data
     * @param _model
     * @param _data
     * @returns {*}
     * @private
     */

        function _formatAndPack(_model, _data) {
      // checks if 'field detail' key
      // exists in rootData & returns value or string "-"

            var _checkSingleFieldInstance = function(_fieldDetail) {
                return _rootData[_fieldDetail] || '-';
            };

      // getting fierlds List with custom definitions

            var _validateAndCheckTheFields = function(_fields) {
                var _objectFields = {}, // this is returning object
                    _fieldItem,         // field Item will hold definition for single field
                    _identifierPrefix = ' as ', // default separator for identifier
                    _hasIdentifier,     // if field has custom validation
                              // and returns any field with value,
                              // it will return value under identifier name
                    _splitFieldHolder,  // holds searching values String block and identifier
                    _hasMany,           // checks if field contains more comparing fields
                    _fieldsToCheck,     // holds optional values separated by  |
                    _identifier;        // holds identifier name


                var _createObjectFields = function(_fItem) {
                    _fieldItem = _fItem;
                    _hasIdentifier = _fieldItem.search(_identifierPrefix);// checks if fieldItem contains identifier
                    _hasMany = _fieldItem.search(/\|/);  // checks if there are optional fields
                    _splitFieldHolder = _fieldItem.split(_identifierPrefix);

          // sets as base identifier _field default
          // or if there is identifier first index value

                    _identifier = _hasIdentifier > 0 ? _splitFieldHolder[1].concat() : _fieldItem;

          // if item has options

                    if(_hasMany > 0) {
                        _fieldsToCheck = _hasIdentifier > 0 ? _splitFieldHolder[0].split('|') : _fieldItem.split('|');

                        for(var k = 0, kl = _fieldsToCheck.length; k < kl; k++) {
                            var _hasValue = _checkSingleFieldInstance(_fieldsToCheck[k]);
                            if (_hasValue && _hasValue != '-') {
                                _objectFields[_identifier] = _hasValue;
                                k = kl;
                            }
                        }
                        if(!_objectFields[_identifier]) {
                            _objectFields[_identifier] = '-';
                        }

            // if item has no options
            // simply return value under default / defined identifier
                    } else {
            // otherwise if it is just a simple field
            // simply returns value under default / defined identifierd

                        _fieldItem = _hasIdentifier > 0 ? _splitFieldHolder[0] : _fieldItem;
                        _objectFields[_identifier] = _checkSingleFieldInstance(_fieldItem);
                    }
                };

        // checks if fields are array
        // which means that in DataModel is expected to return
        // more than one value under custom format or as Object

                if( angular.isArray(_fields)) {
                    for(var i = 0, l = _fields.length; i < l; i++) {
                        _createObjectFields(_fields[i].concat());
                    }
                    return _objectFields;
                }

                _createObjectFields(_fields);

                return _objectFields;
            };

      // First validates model.root
      // if valid returns data from root-path
      // if invalid returns 0

            var _checkLevelAndValidate = function(_splitRoot, _dataFromRoot) {
                if(_splitRoot.length) {
                    var _watchingPath = _splitRoot.shift(); // takes first element from the list and shifting
                    if( _watchingPath.search(/[\[\]]/, 'g') > 0) {
 // checking if root part is defined as Array on specific index

                        var regExp = /\[([^)]+)\]/,              // RegExp for getting index value
                            matches = regExp.exec(_watchingPath), // checking for match in root part
                            exists = !!(matches && matches[1] && !isNaN(matches[1])), // boolean flag for index availability
                            index = exists ? parseInt(matches[1]) : 0,  // sets index value or 0 if there is no match ( case when dev. can add [a]
                            keyvalue= _watchingPath.split('[')[0]; // splits path in order to get the key value of root part (typeof) Array

            // checks if key value exists in watching data
            // and if list has some items inside
            // finally the specific variable on specified index

                        if( _dataFromRoot[keyvalue] &&
              _dataFromRoot[keyvalue].length &&
              _dataFromRoot[keyvalue][index] ) {
                            _dataFromRoot = _dataFromRoot[keyvalue][index]; // gets data on specified index
                            return _checkLevelAndValidate(_splitRoot, _dataFromRoot); // goes recursive until _splitRoot List is empty
                        }

                        return 0; // if there is no further paths returns 0
                    } else { // if root is not an element on specific index in List
                        if( _dataFromRoot[_watchingPath] ) {
 // checking if that element key exists

                            _dataFromRoot = _dataFromRoot[_watchingPath];
                            return _checkLevelAndValidate(_splitRoot, _dataFromRoot);
                        }
                        return 0;
                    }
                }

        // if splited root List is empty
        // returns default provided Data

                return _dataFromRoot;
            };

      // checking and validating the provided root
      // if root is defined returns data or null
            var _validateAndCheckTheRoot = function(_root) {
                var _splitRoot = _root && _root.split('.') || null;
                if (!_splitRoot) {
                    return _data;
                }
                return _checkLevelAndValidate(_splitRoot, _data);
            };

      // based on provided format in model
      // replace values by defined model.fields
      // returns expected format

            var _formatTypeStringReplace = function(_format) {
                for(var k in _fieldValues) {
 // checks all field values

                    var _fieldRegEx = new RegExp('\\b' + k + '\\b', 'g');  // creating RegExp for each field
                    _format = _format.replace(_fieldRegEx, _fieldValues[k]); // using replace updates format
                }

                return _format;
            };

      // format in model doesn't exist === undefined
      // creating Object{} based on model.fields
      // returns Object{}

            var _formatTypeObjectSet = function() {
                var _formatObject = {};

                for(var k in _fieldValues) {
                    _formatObject[k] = _fieldValues[k];
                }

                return _formatObject;
            };

      // validating model.root and getting the data from root

            var _rootData = _validateAndCheckTheRoot(_model.root);

      // if root is invalid or data doesn't exist
      // it will return defult value defined in DataModel

            if(!_rootData) {
                return _model.default;
            }

      // checks if fields are defined
      // getting fields & value from validatiors
      // if _model.fields is undefined returns original structure
      // from API Data

            var _formatedValue = '';

            if(_model.fields) {
                var _fieldValues = _validateAndCheckTheFields(_model.fields);

                if(_model.format && typeof _model.format == 'function') {
                    _formatedValue = _model.format.call(this, _fieldValues);
                } else {
                    _formatedValue = _model.format ? _formatTypeStringReplace(_model.format) : _formatTypeObjectSet();
                }
            } else if(_model.format && typeof _model.format == 'function') {
                _formatedValue = _model.format.call(this, _rootData);
            }

      // finally returns value by format or root data

            return _formatedValue || _rootData;
        }

    /** **
     * Receives API data & DataModel
     * @param _apiData
     * @param _expectedModel
     * @returns {*}
     */

        function formatGenerator(_apiData, _expectedModel) {
      // creating pack object
      // where each key will get formated data
      // based on model fileds & format
      // if fields & format are 'null' returns original data structure
      // this data can be used for binding

            var _packedObject = {};
            for (var k in _expectedModel) {
                _packedObject[k] = _formatAndPack(_expectedModel[k], _apiData);
            }

            return _packedObject;
        }

        var _generator = {
            generate: formatGenerator,
        };

        return _generator;
    }
})();
