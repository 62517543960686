(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc service
     * @name windowScroll
     * @description windowScroll service provides functions that can adopt window behavior when scrolling view, and
     * that customize scrolling according to current user platform and screen size. It add possibility to display
     * modal according to current platform.
     */
    .factory('windowScroll', windowScroll);

  /** @ngInject */
    function windowScroll(platformService, $window, $log, $location, $timeout, apiConfig, HeaderSearchDropDownSevice, $rootScope, DTM, $state) {
        var service = {
            onBdpScroll: onBdpScroll,
            scrollToElement: scrollToElement,
            mobileModalScroll: mobileModalScroll,
            mobileModalCloseHandler: mobileModalCloseHandler,
            customSetter: customSetter,
            customGetter: customGetter,
            customContainer: [],
            compBannerInit: compBannerInit,
            modalSelector: angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent(),
            readAnchors: readAnchors,
            modalPosition: 0,
        };

        (function updateCompRunning() {
      // Added for the Competition, this is needed for loading the API
            if (angular.isUndefined(apiConfig.compToggle())) {
                $timeout(updateCompRunning, 5);
            }
        })();


    /**
     * @memberof windowScroll
     * @method competition Banner
     * @name competition Banner
     * @description This is would be called when competition is running
     */
        function compBannerInit(isCampaign) {
            var opened = false,
                timing = 1500,
                timeoutHandle,
                bannerHeight = angular.element(document.querySelector('.com-banner'))[0].clientHeight,
                bannerPosition = 56;

            angular.element(document.querySelector('.com-open-btn')).bind('click', function() {
                compOpenBanner();
            });

            angular.element(document.querySelector('.com-open-btn')).bind('mouseenter', function() {
              if(!isCampaign) angular.element(document.querySelector('.com-banner')).addClass('com-banner-white');
            });

            angular.element(document.querySelector('.com-open-btn')).bind('mouseleave', function() {
              if(!isCampaign) angular.element(document.querySelector('.com-banner')).removeClass('com-banner-white');
            });

            angular.element(document.querySelector('.com-close-btn-default')).bind('click', function() {
                compCloseBanner();
            });

            angular.element(document.querySelector('.com-close-btn')).bind('click', function() {
                compCloseBanner();
            });

            function compCloseBanner() {
                opened = !opened;
                angular.element(document.querySelector('.com-open-btn')).css('display', 'block');
                angular.element(document.querySelector('.translate')).removeClass(isCampaign ? 'campaign-translated' : 'translated');
                angular.element(document.querySelector('.review-wrapper')).removeClass('com-opacity-0');
                angular.element(document.querySelector('.cityline')).removeClass('com-opacity-1');
                angular.element(document.querySelector('.com-banner')).removeClass('comp-opened-banner');
                angular.element(document.querySelector('.com-banner')).removeClass('comp-opened-banner-image');
                angular.element(window).triggerHandler('banner-change', false);
                if(isCampaign){
                  angular.element(document.querySelector('.campaignContentDefault')).removeClass('campOpened');
                  angular.element(document.querySelector('.campaignContent')).removeClass('campOpened');
                }
            }

            function compOpenBanner() {
                var year = new Date().getFullYear();
                opened = !opened;
                angular.element(document.querySelector('.com-open-btn')).css('display', 'none');
                if(isCampaign){
                  angular.element(document.querySelector('.campaignContentDefault')).addClass('campOpened');
                  angular.element(document.querySelector('.campaignContent')).addClass('campOpened');
                }
                angular.element(document.querySelector('.translate')).addClass(isCampaign ? 'campaign-translated' : 'translated');
                angular.element(document.querySelector('.review-wrapper')).addClass('com-opacity-0');
                angular.element(document.querySelector('.cityline')).addClass('com-opacity-1');
                angular.element(document.querySelector('.com-banner')).addClass('comp-opened-banner');
                angular.element(document.querySelector('.com-banner')).addClass('comp-opened-banner-image');
                DTM.trackAnalyticOnClick('Competition banner on BDP opened - ' + String(year));
                angular.element(window).triggerHandler('close-refine-toolbar');
                angular.element(window).triggerHandler('banner-change', true);
            }


    // This is smoother than Animating Scroll, but just close the Banner first
            angular.element(document.querySelector('.get-started')).bind('click', function() {
                compCloseBanner();
                $timeout(function() {
                    if (angular.element(document.querySelector('.js-header-search-form'))[0].hidden) {
                        angular.element(document.querySelector('.btn-search:visible')).triggerHandler('click');
                    }
                    angular.element(document.querySelector('.com-search-border')).addClass('com-add-border');
                });

                $timeout(function() {
                    angular.element(document.querySelector('.com-search-border')).removeClass('com-add-border');
                    angular.element(document.querySelector('[name=\'KEYWORD\'], [name=\'search.term\']'))[0].focus();
                });
            });

            angular.element(window).bind('scroll', function() {
                if (angular.element(document.querySelector('.relative-positioned')).length) {
                    var scrollTop = $window.scrollY,
                        elementOffset = angular.element(document.querySelector('.relative-positioned')).prop('offsetTop'),
                        distance = elementOffset - scrollTop;
                }

                if (distance < 0) {
                    angular.element(document.querySelector('.loged')).removeClass('relative-positioned');
                    angular.element(document.querySelector('body')).removeClass(isCampaign ? 'translate campaign-translated' : 'translate translated');
                    compCloseBanner();
                } else if (($window.scrollY < bannerPosition)) {
                    angular.element(document.querySelector('.loged')).addClass('relative-positioned');
                    angular.element(document.querySelector('body')).addClass('translate');
                    angular.element(document.querySelector('.com-open-btn')).css('display', 'block');
                    angular.element(document.querySelector('.com-search-border')).removeClass('com-add-border');
                }

                // Im just going to chuck all the comp scrolling stuff in here
                var promoBox = angular.element(document.querySelector('.promo'));
                var header = angular.element(document.querySelector('header'));
                var promoTextBox = angular.element(document.querySelector('.promo .text-box'));

                function getFixedPartHeight() {
                    var result;
                    if (promoBox.hasClass('fixed')) {
                        result = promoTextBox.prop('offsetHeight') + header.prop('offsetHeight');
                    } else {
                        promoBox.addClass('fixed')
                        result = promoTextBox.prop('offsetHeight');
                        promoBox.removeClass('fixed');
                    }
                    return result;
                }

                function getPromoHeight() {
                    var result;

                    if (promoBox.hasClass('fixed')) {
                        if (platformService.getWidth() < 500) {
                            return null;
                        } else {
                            return promoTextBox.prop('offsetHeight');
                        }
                    } else {
                        promoBox.addClass('fixed');
                        if (platformService.isMobile() == true && platformService.getWidth() < 500) {
                            result = angular.element(document.querySelector('.promo .text-box .text-holder')).prop('offsetHeight');
                        } else {
                            result = promoTextBox.prop('offsetHeight');
                        }

                        promoBox.removeClass('fixed');
                    }

                    return result;
                }

                function getPromoBoxFixedPosition() {
                    if (header.hasClass('fixed')) {
                        return header[0].clientHeight;
                    }
                }

                // this part is added for the Competition
                var bannerHeight;
                if (angular.element(document.querySelector('.com-banner')).hasClass('comp-opened-banner')
                    || angular.element(document.querySelector('.com-banner')).hasClass('comp-opened-banner-image')) {
                    bannerHeight = angular.element(document.querySelector('.com-banner'))[0].clientHeight - Number(angular.element(document.querySelector('body')).css('padding-top').replace('px', ' '));
                } else {
                    bannerHeight = 0;
                }

                var businessUpgrade = angular.element(document.querySelector('.business-upgrade')).length ? angular.element(document.querySelector('.business-upgrade')).prop('offsetHeight') : 0;
                var anchorsBox = angular.element(document.querySelector('.anchors'));
                var promoHeight;
                var promoVisual = angular.element(document.querySelector('.promo .visual'));
                if (platformService.isMobile() == true && platformService.getWidth() < 500) {
                    promoHeight = promoVisual.prop('offsetHeight') + businessUpgrade;
                    if (!promoBox.hasClass('fixed')) {
                        promoHeight = promoHeight - businessUpgrade - 43;
                    } else {
                        promoHeight = promoHeight - businessUpgrade + 115;
                    }
                } else {
                    promoHeight = promoBox.prop('offsetHeight');
                }

                var topOffset = +angular.element(document.querySelector('header')).prop('offsetHeight') + promoHeight;

                if (angular.element(document.querySelector('.promo .tools')).css('position') === 'static') {
                    topOffset -= angular.element(document.querySelector('.promo .tools')).prop('offsetHeight');
                }

                if (angular.element(document.querySelector('.promo .address')).css('display') === 'table') {
                    topOffset -= angular.element(document.querySelector('.promo .address')).prop('offsetHeight');
                }

                if (topOffset - getPromoHeight() - angular.element(document.querySelector('header')).prop('offsetHeight')+ 24 + businessUpgrade + bannerHeight < $window.scrollY) {
                    angular.element(document.querySelector('header')).addClass('fixed');
                    promoBox.addClass('fixed');
                    promoVisual.css({'top': getPromoBoxFixedPosition() - 1});
                    promoTextBox.css({'top': getPromoBoxFixedPosition() - 1});
                    anchorsBox.addClass('fixed').css({'top': getFixedPartHeight() - 2});
                } else {
                    if ($window.scrollY < 28) {
                        angular.element(document.querySelector('header')).removeClass('fixed');
                        promoBox.removeClass('fixed');
                        promoVisual.css('top', 0);
                        promoTextBox.css('top', '2.1rem');
                        anchorsBox.removeClass('fixed expanded-h').css('top', 0);
                    } else {
                        angular.element(document.querySelector('header')).addClass('fixed');
                        promoBox.removeClass('fixed');
                        promoVisual.css('top', 0);
                        promoTextBox.css('top', '2.1rem');
                        anchorsBox.removeClass('fixed expanded-h').css('top', 0);
                    }
                }
            });

            angular.element(window).on('close-top-banner', compCloseBanner);
        }


    /**
     * @memberof windowScroll
     * @method mobileModalCloseHandler
     * @name mobileModalCloseHandler
     * @description Removes class attached to background when modal is opened.
     */
        function mobileModalCloseHandler() {
            angular.element(document.querySelector('html')).removeClass('_modal-open');

            if (angular.element(document.querySelector('html')).hasClass('_menu-open')) {
                angular.element(document.querySelector('html')).addClass('modal-open');
            }

            if (service.customGetter('noHorizontalScroll') == true) {
                angular.element(document.querySelector('body')).addClass('noHorizontalScroll');
                service.customSetter('noHorizontalScroll', false);
            }
        }

        function customSetter(key, value) {
            service.customContainer[key] = value;
        }

        function customGetter(key) {
            if (angular.isUndefined(service.customContainer[key])) {
                return false;
            }
            return service.customContainer[key];
        }


    /**
     * @memberof windowScroll
     * @method mobileModalScroll
     * @name mobileModalScroll
     * @description Add class to background when modal is opened. Open modal as natural for mobile platform.
     */
        function mobileModalScroll() {
            angular.element(document.querySelector('html')).addClass('_modal-open');
            if (platformService.isMobile()) {
                $timeout(function() {
                    var _top = angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent()[0].offsetTop;
                    var _height = angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent()[0].clientHeight;
                    var _width = angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent()[0].clientWidth;

                    if (!platformService.isAndroid()) {
                        angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)'))
              .parent()
              .css({
                  'position': 'absolute',
                  'top': _top,
                  'min-height': _height,
                  'width': _width,
                  'overflow-y': 'auto',
              });
                    }

                    angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))
            .css({'overflow': 'auto'});

                    var padding = 0;
                    if (platformService.isAndroid()) {
                        padding = parseInt(_height * 50 / 100);
                        angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))
              .css({'padding-bottom': padding});
                    }

                    angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))[0].scrollTop = 1;

                    angular.element(document.querySelector('.modal-backdrop'))
            .css({
                'position': 'absolute',
                'top': _top,
                'min-height': _height,
                'width': _width,
            });

                    service.modalPosition = $window.scrollY;

                    if (angular.element(document.querySelector('body')).hasClass('noHorizontalScroll')) {
                        service.customSetter('noHorizontalScroll', true);
                        angular.element(document.querySelector('body')).removeClass('noHorizontalScroll');
                    }
                    if(platformService.isFirefox()){ // Firefox specific scroll bug workaround
                        $window.scrollTo(0,0);
                    }
                }, 100);

                angular.element($window).bind('orientationchange', function() {
                    $timeout(function() {
                        var _top = angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent()[0].offsetTop;
                        var _height = $window.outerHeight;
                        var _width = $window.outerWidth;

                        angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)'))
              parent()
              .css({
                  'position': 'absolute',
                  'top': _top,
                  'min-height': _height,
                  'width': _width,
                  'overflow': 'auto',
              });

                        angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))
              .css({'overflow': 'auto'});

                        if (platformService.isAndroid()) {
                            var padding = parseInt(_height * 50 / 100);
                            angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))
                .css({'padding-bottom': padding});
                        }

                        angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))
              .scrollTop = 1;

                        angular.element(document.querySelector('.modal-backdrop'))
              .css({
                  'position': 'absolute',
                  'top': _top,
                  'min-height': _height,
                  'width': _width,
              });
                    }, 500);
                });
            }
        }

        var lastY, lastYModal;
        angular.element(document.querySelector('body')).bind('touchmove', function(e) {
            if (HeaderSearchDropDownSevice.getMobileButtonStatus()) {
                angular.element(window).triggerHandler('url-changed');
            }

            var elem = angular.element(this);
            var currentY = e.touches[0].clientY;
            if (elem.parent().hasClass('_menu-open') || elem.parent().hasClass('_modal-open')) {
        // check if there are inputs focuses
                var _windowHeight = $window.outerHeight;
                var _modalHeight = angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))
          .prop('offsetHeight');
                var _extraKeyboardOffset = 0;
                var _barsOffset = 20;

                if (_modalHeight > _windowHeight - _extraKeyboardOffset - _barsOffset && platformService.getWidth() < 500) {
                    if (e.target.localName == 'textarea' && angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea')[0].focus) {
                        if (!angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea')[0].focus) {
                            angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea')[0].focus();
                            angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea')[0].scrollTop = 1;
                            e.preventDefault();
                        } else {
                            if (angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea')[0].scrollTop + angular.element('.modal-dialog:not(.gallery-modal-open)').parent().find('textarea')[0].clientHeight >= angular.element('.modal-dialog:not(.gallery-modal-open)').parent().find('textarea')[0].scrollHeight) {
                                angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea')[0].scrollTop = (angular.element('.modal-dialog:not(.gallery-modal-open)').parent().find('textarea').scrollTop - 3);
                                e.preventDefault();
                            } else if (angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea')[0].scrollTop == 0) {
                                angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea')[0].scrollTop = 1;
                                e.preventDefault();
                            }
                        }
                    } else if (angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))[0].scrollTop + angular.element('.modal-dialog:not(.gallery-modal-open) > .modal-content')[0].clientHeight >= angular.element('.modal-dialog:not(.gallery-modal-open) > .modal-content')[0].scrollHeight && currentY < lastY) {
                        angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))[0].scrollTop = (angular.element('.modal-dialog:not(.gallery-modal-open) > .modal-content')[0].scrollTop - 3);
                        e.preventDefault();
                    } else if (angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content')).scrollTop == 0 && currentY > lastY) {
                        angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))[0].scrollTop = 1;
                        e.preventDefault();
                    } else {
                        if (angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))[0].scrollTop == 0) {
                            angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open) > .modal-content'))[0].scrollTop = 1;
                        }
                    }
                    lastY = currentY;
                } else {
                    var _widthLimit = 500;
                    var _scrollLimit = 236;

                    if (window.matchMedia('(orientation: landscape)').matches) {
                        _widthLimit = 1000;
                        _scrollLimit = 500;
                    }

                    if (angular.element(document.querySelector('#categories-list')).hasClass('in') && platformService.getWidth() < _widthLimit) {
                        if (currentY > lastY && angular.element(document.querySelector('#main-nav'))[0].scrollTop == 0) {
                            e.preventDefault();
                        } else if (currentY < lastY && angular.element(document.querySelector('#main-nav'))[0].scrollTop > _scrollLimit) {
                            e.preventDefault();
                        }
                        lastY = currentY;
                    } else {
                        if (e.originalEvent.target.localName == 'textarea') {
                            if (!angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea').is(':focus')) {
                                angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea').focus();
                                angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea').scrollTop(1);
                                e.preventDefault();
                            } else {
                                if (angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea').scrollTop() + angular.element('.modal-dialog:not(.gallery-modal-open)').parent().find('textarea').innerHeight() >= angular.element('.modal-dialog:not(.gallery-modal-open)').parent().find('textarea')[0].scrollHeight) {
                                    angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea').scrollTop(angular.element('.modal-dialog:not(.gallery-modal-open)').parent().find('textarea').scrollTop() - 3);
                                    e.preventDefault();
                                } else if (angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea').scrollTop() == 0) {
                                    angular.element(document.querySelector('.modal-dialog:not(.gallery-modal-open)')).parent().find('textarea').scrollTop(1);
                                    e.preventDefault();
                                }
                            }
                        } else {
                            e.preventDefault();
                        }
                    }
                }
            }
        });

    /**
     * @memberof windowScroll
     * @method onBdpScroll
     * @name onBdpScroll
     * @description All the BDP scroll events will go in here
     */
    function onBdpScroll() {
        var pageSplit = window.location.pathname.split('/');
        var pageType = pageSplit[1];
        if(['find', 'search', 'search-location'].indexOf(pageType) < 0 && pageType !=''){
          angular.element(document.querySelector('body')).removeClass('sub-menu-fixed');
          angular.element($window).triggerHandler('sub-menu-changed');
          var subMenu = angular.element(document.querySelector('.bdp-sub-menu')), // Sub menu element
              header = angular.element(document.querySelector('header')), // Header menu element
              body = angular.element(document.querySelector('body')), // Body element

              headerHeight = header[0].clientHeight, // Header height
              subMenuFromTop = subMenu.prop('offsetTop') - headerHeight, // Get distance from top minus header height as it is sticky
              lastScrollTop = 0;
        }
        angular.element($window).bind("scroll", function() { // Using angulars way of triggering scroll
            if(['find', 'search', 'search-location'].indexOf(pageType) < 0 && pageType !=''){
                var scrollToTop     = $window.scrollY; // Get scroll position
                if(angular.isUndefined(scrollToTop) || platformService.isIE()) scrollToTop = window.pageYOffset;
                if (scrollToTop > subMenuFromTop - 3) { // When top of sub menu has been reached
                    body.addClass('sub-menu-fixed');
                    angular.element($window).triggerHandler('sub-menu-changed');

                    if (lastScrollTop > scrollToTop) { // If scrolled up
                        body.addClass('header-show');
                        if(!platformService.isMobilePlatform()){
                          var breadcrumbHeight = (headerHeight + angular.element(document.querySelector('.anchors'))[0].clientHeight) + 'px';
                          angular.element(document.querySelector('.breadcrumbs')).attr('style', 'top: '+breadcrumbHeight+'!important');
                        }
                    } else {
                        body.removeClass('header-show');
                    }
                    lastScrollTop = scrollToTop;

                } else {
                    body.removeClass('sub-menu-fixed');
                    angular.element($window).triggerHandler('sub-menu-changed');
                }

            }
        });

    }

    /**
     * @memberof windowScroll
     * @method scrollToElement
     * @name scrollToElement
     * @description Animate scrolling to argument element.
     * @param {Object} element
     * @param {Integer} timeout
     */
     function getCurrentHeaderHeight(header) {
        var NAVIGATION_HEIGHT = 60;
        if (header.hasClass('fixed-sticky')) return NAVIGATION_HEIGHT;
        return header[0].offsetHeight;
    }

    function _scroll(element, header, timeout, _step) {
        if (_step > 16) return;
        _step = _step || 1;
        timeout = timeout / 2;
        timeout = timeout < 50 ? 50 : timeout;

        var body = angular.element(document.querySelector('html, body'));
        var view = $window;
        var bodyOffset = body[0].scrollTop;
        if(angular.isUndefined(bodyOffset) || bodyOffset==0){ bodyOffset = $window.scrollY;}
        var headerOffset = getCurrentHeaderHeight(header);
        var offsetTop = element.prop('offsetTop');

        if (apiConfig.userIsAuthenticated()) {
            if(element[0].attributes.id.value === 'reviews' && angular.element(document.querySelector('#reviewCount')).html() == '(0)') {
                offsetTop -= 100;
            }
        }
        var estimatedOffset = offsetTop - headerOffset;
        
        var completed = false;
        $window.scrollTo(0, Math.round(estimatedOffset - Math.abs(estimatedOffset - bodyOffset) / 2));
        if (completed) return;
        else{
          completed = true;
          var offset = offsetTop - headerOffset;
          if (offset === view.scrollY) return;
          _scroll(element, header, timeout, _step + 1);
        }
    }

    function scrollToElement(element, timeout) {
        timeout = angular.isUndefined(timeout) ? 500 : +timeout || 0;
        _scroll(element, angular.element(document.querySelector('header')), timeout);
    }

    /**
     * @memberof windowScroll
     * @method readAnchors
     * @name readAnchors
     * @description After page is renderer, read anchors positions of fixed parts.
     */
        function readAnchors() {
            var read = false;
            var numberOfTriesToGetMoreReviews = 0;
            $rootScope.anchored = false;
            $rootScope.triedToAnchor = false;

            angular.element(window).on('renderComplete', function() {
                // var delayForScrolling = 0;

                if (!read) {
                    $timeout(function() {
                        if (!$rootScope.triedToAnchor && angular.isDefined($location.search().target)) {
                            $rootScope.triedToAnchor = true;
                            var $target = $location.search().target;
                            var moreReviewsButtonEl = angular.element(document.querySelector('.btn.btn-primary.js-review-open'));
                            var getMoreReviews = 0;

                            if (!$rootScope.anchored && ($window.scrollY || $window.pageXOffset) <= 10) {
                                if ($target == 'reviews') {
                                    var elementToScrollTo = '#reviews';
                                    if (angular.isDefined($location.search().id)) {
                    // test if the review is on the page
                                        var reviewToScrollTo = '#review-' + $location.search().id;
                    // if element exists
                                        if (angular.element(document.querySelector(reviewToScrollTo)).length > 0) {
                                          elementToScrollTo = reviewToScrollTo;
                                      } else {
                                          if (moreReviewsButtonEl.length > 0) {
                                            getMoreReviews++;
                                            if (getMoreReviews == 1) {
                                              getMoreReviewsFunc(elementToScrollTo);
                                          }
                                        }
                                      }
                                    }

                                    if (!getMoreReviews) {
                                        $timeout(function() {
                                          service.scrollToElement(angular.element(document.querySelector(elementToScrollTo)));
                                      });
                                    }
                                } else if ($target == 'gallery') {
                                    $timeout(function() {
                                      service.scrollToElement(angular.element(document.querySelector('#gallery')));
                                  });
                                } else if ($target == 'details') {
                                  $timeout(function() {
                                    service.scrollToElement(angular.element(document.querySelector('#details')));
                                });
                              } else if ($target == 'description') {
                                $timeout(function() {
                                  service.scrollToElement(angular.element(document.querySelector('#details>.container:nth-child(1)')));
                              });
                            } else if ($target == 'additional-information') {
                              $timeout(function() {
                                service.scrollToElement(angular.element(document.querySelector('#details>.container:nth-child(2)')));
                            });
                          } else if ($target == 'write-review') {
                            var addReviewButton=angular.element(document.querySelector('.btn-add.js-review-open .icon-pencil-3'));
                  // let's trigger write reviews
                            if (apiConfig.userIsAuthenticated()) {
                              addReviewButton.triggerHandler('click');
                    // add-review-form
                              $timeout(function() {
                                service.scrollToElement(angular.element(document.querySelector('.review-form')));
                            });
                          } else {
                              $timeout(function() {
                                service.scrollToElement(angular.element(document.querySelector('#reviews')));
                                addReviewButton.triggerHandler('click');
                            });
                          }
                        } else if ($target == 'deals') {
                          $timeout(function() {
                            if (platformService.getWidth() < 768) {
                              service.scrollToElement(angular.element(document.querySelector('.dealsWrapper')));
                          } else {
                              service.scrollToElement(angular.element(document.querySelector('#bdp-deals')));
                          }
                        });
                      } else if ($target == 'data-points') {
                        $timeout(function() {
                          service.scrollToElement(angular.element(document.querySelector('bdp-details-copy-point')));
                      });
                    } else if ($target == 'gallery-show') {
                      var imageId = $location.search().id;

                      var imageToScrollToEl = angular.element(document.querySelector('.gallery-single-image:nth-child(1)'));
                      $timeout(function() {
                        service.scrollToElement(angular.element(document.querySelector('#gallery')));
                        $timeout(function() {
                            if (imageToScrollToEl.length > 0) {
                                imageToScrollToEl.triggerHandler('click');
                                if (imageId) {
                          // TODO: scroll to image ?
                                }
                            }
                        });
                    });
                  } else if ($target == 'address' || $target == 'map') {
                    $timeout(function() {
                        var addressEl = angular.element(document.querySelector('#address-2 .icon-location'));
                        if (addressEl.offset().top <= 0) {
                            addressEl = angular.element(document.querySelector('#address-1 .icon-location'));
                        }

                        service.scrollToElement(addressEl);

                        if ($target=='map') {
                            $timeout(function() {
                                if (addressEl.length > 0) {
                                    addressEl.triggerHandler('click');
                                    $rootScope.anchored = true;
                                }
                            });
                        }
                    });
                } else if ($target == 'opening-hours' || $target == 'contact-information') {
                    $timeout(function() {
                        $rootScope.$broadcast('open-' + $target);
                        $rootScope.anchored = true;
                    });
                } else if ($target == 'featured-review') {
                    $timeout(function() {
                        service.scrollToElement(angular.element(document.querySelector('bdp-fav-review')));
                    });
                } else {
                    $rootScope.anchored=true;
                }
                            }
                        }
                    }, 1500);

                    read = true;
                }
            });
        }

        return service;
    }
})();
