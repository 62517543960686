(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name bdpPhone
         * @description Directive displays business primary phone number. It first check available phones and detect which
         * is primary. Then format phone number in user friendly view. Phone number is displayed with phone icon.
         *
         * When detecting primary phone, directive will look on API priorityNumber.phonePreference.
         *
         * When formatting phone number, directive will apply next rules:
         *
         *    if phone number have 10 digits:
         *      if starts with 04 / 1300 / 1800 or 1900, then it wont have brackets, and it will be divided as eg. 1800 123 123,
         *      , and otherwise it will starts with brackets, eg.: (12) 1234 123456
         *    if phone number have 6 digits
         *      digits will be arranged without brackets, as example: 12 12 12
         *
         * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
         */
        .directive('bdpPhone', bdpPhone);

    /** @ngInject */
    function bdpPhone() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpphone/bdpphone.html',
            scope: {
                contacts: '=',
                priorityNumber: '=',
                listing: '='
            },
            controller: bdpPhoneController,
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

        /** @ngInject */

        /**
         * @memberof truelocal
         * @method bdpPhone_bdpPhoneController
         * @name bdpPhone_bdpPhoneController
         * @description bdpPhoneController.
         *
         * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
         *
         */
        function bdpPhoneController(platformService, apiConfig, DTM, constantsIcons) {
            var vm = this;
            vm.callBusinessIcon = constantsIcons.commonIcons.callBusinessIcon;
            vm.phPref = vm.listing.preferences.phonePreference;
            if(vm.phPref === 'local'){
                vm.phPref = 'phone';
            }

            /**
             * @memberof bdpHeader
             * @name havePhone
             * @description Does business have phone.
             */
            vm.havePhone = false;


            /**
             * @memberof bdpHeader
             * @method hasPhone
             * @name hasPhone
             * @description Check does current business has phone and set havePhone accordingly.
             * @returns {Boolean} havePhone
             */
            vm.hasPhone = function(contact) {
                if (angular.isDefined(contact)) {
                    vm.contacts.contact = contact;
                }
                if (angular.isUndefined(vm.contacts) || angular.isUndefined(vm.contacts.contact)) {
                    return false;
                } else {
                    angular.forEach(vm.contacts.contact, function(contact) {
                        if (contact.type == 'phone' || contact.type == 'mobile' || contact.type == 'national') {
                            if(contact.type === vm.phPref){
                                vm.havePhone = true;
                            }
                        }
                    });
                }
                return vm.havePhone;
            };

            vm.getPreferredPhoneNumber = function(){
                var contactValue = "";
                if(angular.isDefined(vm.contacts) && angular.isDefined(vm.contacts.contact) ){
                    angular.forEach(vm.contacts.contact, function(contact){
                        if(contact.type === vm.phPref){
                            contactValue = contact.value;
                        }
                    })

                }
             return _formatPhone(contactValue);
            }


            /**
             * @memberof bdpHeader
             * @method isMobile
             * @name isMobile
             * @description Check is current platform or screen size mobile.
             * @returns {Boolean} isMobile
             */
            vm.isMobile = platformService.isMobile();

            /**
             * @memberof bdpHeader
             * @method isMobileScreenSize
             * @name isMobileScreenSize
             * @description Check if screen size is mobile.
             * @returns {Boolean} isMobileScreenSize
             */
            vm.isMobileScreenSize = function() {
                return window.outerWidth <= 767;
            };

            vm.mobilePhoneNumberText = function() {
                return vm.isMobileScreenSize() ? 'CALL' : vm.getPreferredPhoneNumber();
            };

            /**
             * @memberof bdpHeader
             * @method hasPhoneDesktop
             * @name hasPhoneDesktop
             * @description Returns true if current platform is desktop and business have phone.
             * @returns {Boolean} havePhone && isDesktop
             */
            vm.hasPhoneDesktop = function(contact) {
                if (angular.isDefined(contact)) {
                    vm.contacts.contact = contact;
                }
                if (platformService.isMobilePlatform() == false) {
                    return vm.contacts && vm.hasPhone(vm.contacts.contact);
                } else {
                    return false;
                }
            };


            /**
             * @memberof bdpHeader
             * @method hasPhoneMobile
             * @name hasPhoneMobile
             * @description Returns true if current platform is mobile and business have phone.
             * @returns {Boolean} havePhone && isMobile
             */
            vm.hasPhoneMobile = function(contact) {
                if (angular.isDefined(contact)) {
                    vm.contacts.contact = contact;
                }
                if (platformService.isMobilePlatform()) {
                    return vm.hasPhone(contact);
                } else {
                    return false;
                }
            };


            /**
             * @memberof bdpHeader
             * @method _formatPhone
             * @name _formatPhone
             * @description Format phone number in user friendly form.
             * @params {String} _phoneNumber
             * @returns {String} _formatedPhoneNumber
             */
            var _formatPhone = function(_phoneNumber) {
                var _formattedPhoneNumber = '';
                if (angular.isUndefined(_phoneNumber) || !_phoneNumber) {
                    return '';
                }
                if (_phoneNumber.length == 10) {
                    if (_phoneNumber.startsWith('04') || _phoneNumber.startsWith('1300') ||
                        _phoneNumber.startsWith('1800') || _phoneNumber.startsWith('1900')) {
                        _formattedPhoneNumber += _phoneNumber.substr(0, 4) + ' ' + _phoneNumber.substr(4, 3) + ' ' + _phoneNumber.substr(7, 3);
                    } else {
                        _formattedPhoneNumber += '(' + _phoneNumber.substr(0, 2) + ') ';
                        _formattedPhoneNumber += _phoneNumber.substr(2, 4) + ' ' + _phoneNumber.substr(6);
                    }
                } else if (_phoneNumber.length == 6) {
                    _formattedPhoneNumber += _phoneNumber.substr(0, 2) + ' ' + _phoneNumber.substr(2, 2) + ' ' + _phoneNumber.substr(4, 2);
                } else {
                    _formattedPhoneNumber = _phoneNumber;
                }
                return _formattedPhoneNumber;
            };


            vm.phoneType = 0;


            /**
             * @memberof bdpHeader
             * @method getPhoneBase
             * @name getPhoneBase
             * @description Get base phone number if there is more available.
             * @params {Object} contact
             * @returns {String} _mobileVal
             */
            vm.getPhoneBase = function(contact) {
                if (angular.isDefined(contact)) {
                    vm.contacts.contact = contact;
                }
                if (vm.hasPhone(vm.contacts.contact) == true) {
                    var _mobileVal = '';
                    angular.forEach(vm.contacts.contact, function(contact) {
                        if (contact.type == 'phone' || contact.type == 'mobile' || contact.type == 'national') {
                            _mobileVal = contact.value;
                        }
                    });
                    return _mobileVal;
                }
            };

            vm.phonePreference = '';


            /**
             * @memberof bdpHeader
             * @name originalPhone
             * @description Original phone number.
             */
            vm.originalPhone = '';


            /**
             * @memberof bdpHeader
             * @method getOriginalPhone
             * @name getOriginalPhone
             * @description Sets originalPhone value.
             * @params {Object} contact
             */
            vm.getOriginalPhone = function(contact) {
                if (angular.isDefined(contact)) {
                    vm.contacts.contact = contact;
                }
                if (vm.originalPhone == '') {
                    if (vm.hasPhone(vm.contacts.contact) == true) {
                        if (vm.phonePreference == '') {
                            if (angular.isUndefined(vm.preferences)) {
                                vm.phonePreference = '';
                            } else if (angular.isUndefined(vm.preferences.phonePreference)) {
                                vm.phonePreference = '';
                            } else {
                                vm.phonePreference = vm.preferences.phonePreference;
                            }
                        }

                        if (vm.phonePreference == '') {
                            angular.forEach(vm.contacts.contact, function(contact) {
                                if (contact.type == 'national') {
                                    vm.phoneType = 3;
                                    vm.originalPhone = contact.value;
                                } else if (contact.type == 'mobile') {
                                    if (vm.phoneType < 2) {
                                        vm.phoneType = 2;
                                        vm.originalPhone = contact.value;
                                    }
                                } else if (contact.type == 'phone') {
                                    if (vm.phoneType < 1) {
                                        vm.phoneType = 1;
                                        vm.originalPhone = contact.value;
                                    }
                                }
                            });
                        } else {
                            if (vm.phonePreference == 'local') vm.phonePreference = 'phone';
                            angular.forEach(vm.contacts.contact, function(contact) {
                                if (contact.type == vm.phonePreference) {
                                    vm.originalPhone = contact.value;
                                }
                            });
                        }
                    }
                }

                if (vm.originalPhone == '') {
                    angular.forEach(vm.contacts.contact, function(contact) {
                        if (contact.type == 'national') {
                            vm.phoneType = 3;
                            vm.originalPhone = contact.value;
                        } else if (contact.type == 'mobile') {
                            if (vm.phoneType < 2) {
                                vm.phoneType = 2;
                                vm.originalPhone = contact.value;
                            }
                        } else if (contact.type == 'phone') {
                            if (vm.phoneType < 1) {
                                vm.phoneType = 1;
                                vm.originalPhone = contact.value;
                            }
                        }
                    });
                }
                return vm.originalPhone;
            };


            /**
             * @memberof bdpHeader
             * @name phone
             * @description Business phone number.
             */
            vm.phone = '';


            /**
             * @memberof bdpHeader
             * @method getPhone
             * @name getPhone
             * @description Sets and format phone value.
             * @params {Object} contact
             */
            vm.getPhone = function(contact) {
                vm.phone = _formatPhone(vm.getOriginalPhone(contact));
                return vm.phone;
            };

            /**
             * @memberof bdpHeader
             * @name phoneAPIType
             * @description Type of phone API.
             */
            vm.phoneAPIType = '';


            /**
             * @memberof bdpHeader
             * @method phoneClickTrack
             * @name phoneClickTrack
             * @description Omniture tracking for mobile phone click.
             */
            vm.phoneClickTrack = function(listing) {
                DTM.trackAnalyticOnClick('phone number click', vm.phone);
            };

            /**
             * @memberof bdpHeader
             * @method updatePhoneType
             * @name updatePhoneType
             * @description Sets phone type (local/mobile/national).
             * @returns {Object} phoneAPIType
             */
            var updatePhoneType = function(contact) {
                if (angular.isDefined(contact)) {
                    vm.contacts.contact = contact;
                }
                if (vm.hasPhone(vm.contacts.contact)) {
                    angular.forEach(vm.contacts.contact, function(contact) {
                        if (contact.type == 'phone') {
                            vm.phoneAPIType = 'LOCAL';
                        } else if (contact.type == 'mobile') {
                            vm.phoneAPIType = 'MOBILE';
                        } else if (contact.type == 'national') {
                            vm.phoneAPIType = 'NATIONAL';
                        }
                    });

                    return vm.phoneAPIType;
                }
            };
        }
    }
})();
