(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name MainController
     * @description MainController.
     */
    .controller('HomepageController', HomepageController);

  /** @ngInject */
    function HomepageController(Seo, headerService, windowScroll, homePageSeo, $scope, $rootScope, DTM, $location, $modal, listingModels, $state, appService) {
        var vm = this;
        headerService.showSearchForm = false;
        headerService.logoClass = 'big';
        headerService.dynamicShowHideSearchForm = true;
        Seo.pageTitle.set(homePageSeo.title);
        Seo.description.set(homePageSeo.description);
        listingModels.setUserIdToVisit("");

        var header = angular.element( document.querySelector( 'body' ));
        if(header.hasClass('services-page-body')) header.removeClass('services-page-body');

        if (DTM.hasOneTrustScript()) {
            DTM.fireEventAtPageLoad($state.current.name);
        } else {
            DTM.trackPage();
        }

        // Trigger newsletter modal by parameter in URL
        if ($location.search().newsletter) {
            var _modalInstance = $modal.open({
                templateUrl: '/app/components/shared/modalNewsletter/modalnewsletter.html',
                controller: 'newsletterController',
                controllerAs: 'vm',
                size: 'dialog_size_middle'
            });

            _modalInstance.opened.then(function() {
                windowScroll.mobileModalScroll();
            });

            _modalInstance.result.finally(function() {
                windowScroll.mobileModalCloseHandler();

                // Remove parameter on close
                $location.search('newsletter', null);
            });

            DTM.trackAnalyticOnClick('newsletter modal opened');
        }

        appService.displayFooter();
    }
})();
