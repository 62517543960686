(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name BdpGalleryTheatreController
     * @description BdpGalleryTheatreController menage data for full screen displaying of gallery images in
     * theatre mode.
     */
    .controller('BdpGalleryTheatreController', BdpGalleryTheatreController);

  /** @ngInject */
    function BdpGalleryTheatreController($scope, $log, $window, images, listing, $http, apiConfig, $timeout, selectedImage, $q, errorService) {
        var theatre = this;


    /**
     * @memberof BdpGalleryTheatreController
     * @name listing
     * @description BdpGalleryTheatreController root data object.
     */
        theatre.listing = listing;


    /**
     * @memberof BdpGalleryTheatreController
     * @name selectedImage
     * @description Currently selected image.
     */
        theatre.selectedImage = selectedImage;


    /**
     * @memberof BdpGalleryTheatreController
     * @name images
     * @description Array of images in gallery.
     */
        theatre.images = [];

        theatre.size = 0;


    /**
     * @memberof BdpGalleryTheatreController
     * @name images
     * @description Default index.
     */
        theatre.active = 0;

        theatre.config = {
            visibleElements: 5,
        };

        theatre.range = {
            from: 0,
            to: theatre.config.visibleElements,
        };


    /**
     * @memberof BdpGalleryTheatreController
     * @name css
     * @description CSS object holds classes for theatre gallery style.
     */
        theatre.css = {
            selectors: {
                navigation: 'div.modal-theatre--collection',
                thumbs: 'div.modal-theatre--collection li',
                screen: 'div.modal-theatre--screen',
                notificator: 'div.modal-theatre--details-notificator',
                bottonNavigation: 'div.modal-theatre--collection-nav',
            },
        };


    /**
     * @memberof BdpGalleryTheatreController
     * @name states
     * @description Theatre gallery initial states.
     */
        theatre.states = {
            notificator: false,
            isFormDisabled: false,
            initialPreloader: true,
        };

        theatre.contents = {
            maxLength: 1000,
            currentLength: 0,
            errorMessage: '',
        };

        theatre.notificator = {};

        $timeout(function() {
            theatre.notificator.height = angular.element(document.querySelector(theatre.css.selectors.notificator))[0].clientHeight;
        });


    /**
     * @memberof BdpGalleryTheatreController
     * @name actions
     * @description Actions on theatre view.
     */
        theatre.actions = {
            deactivate: function(currentIndex) {
                $log.debug('[BdpGalleryTheatre] theatre.actions.deactivate');
                theatre.images[currentIndex].status.publishStatus = 'inactive';

                var prev = (currentIndex > 1) ? --currentIndex : 0;

                theatre.actions.setActive(prev);
            },
            events: {
                keyWatch: function(event) {
                    theatre.contents.currentLength = event.target.value.length;
                },
            },
            form: {
                submit: function() {
                    $log.debug('Form > Submit');
                    theatre.states.isFormDisabled = true;
                    theatre.contents.errorMessage = '';

                    var comment = theatre.contents.comment;
                    theatre.contents.confirmation = '';
                    theatre.contents.comment = '';

                    $http({
                        url: apiConfig.generate('user-images', theatre.images[theatre.active].id, 'report'),
                        method: 'POST',
                        data: JSON.stringify({
                            reason: comment.substring(0, theatre.contents.maxLength),
                        }),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).success(function(data, status, headers, config) {
                        angular.element($window).triggerHandler('reported-image', theatre.images[theatre.active].id);
                        theatre.states.isFormDisabled = false;
                        theatre.contents.confirmation = 'Success!';
                        theatre.contents.comment = '';
                        theatre.contents.currentLength = 0;
                        theatre.actions.deactivate(theatre.active);
                    }).error(function(data, status, headers, config) {
                        theatre.states.isFormDisabled = false;
                        theatre.contents.errorMessage = data.meta.messages[0];
                    });
                },
            },
            notification: {
                show: function(id, event) {
                    event.preventDefault();
                    event.stopPropagation();

                    var screen = $(theatre.css.selectors.screen);

                    theatre.notificator.id = id;

                    if (theatre.states.notificator) {
                        screen.css({
                            height: [screen.outerHeight() + theatre.notificator.height, 'px'].join(''),
                        });
                        theatre.states.notificator = false;
                        theatre.contents.comment = '';
                        theatre.contents.currentLength = 0;
                        return;
                    }

                    theatre.states.notificator = true;

                    screen.css({
                        height: [screen.outerHeight() - theatre.notificator.height, 'px'].join(''),
                    });

                    theatre.contents.errorMessage = '';
                },
            },
            scrollTo: function(index) {
                var thumbs = angular.element(document.querySelector(theatre.css.selectors.thumbs))[0].eq(index || 0);
                var navigation = document.getElementById('modal-theatre--collection');

                var page = Math.floor((index) / theatre.config.visibleElements);
                var bottomNav = angular.element(document.querySelector(theatre.css.selectors.bottonNavigation));
                navigation.scrollTop = page * navigation.clientHeight;

                bottomNav.css({
                    top: [navigation.scrollTop + (navigation.clientHeight / 2) - (bottomNav.outerHeight() / 2), 'px'].join(''),
                });

                $log.debug('Visible page: %d', index++);
            },
            setActive: function(index) {
                $log.debug('Selected image index: %d', index);
                theatre.active = index;
                theatre.actions.scrollTo(theatre.active);
            },
            getMomentAgo: function(timestamp) {
                return moment(moment(timestamp), 'YYYYMMDD').fromNow();
            },
            getCurrentUrl: function() {
                return document.location.href;
            },
            load: {
                all: function() {
                    var owner, user;

                    owner = $http({
                        method: 'GET',
                        url: apiConfig.generate('listings', theatre.listing.id, 'images') + '&limit=1000',
                    }).then(function(response) {
                        theatre.images = angular.isDefined(response.data.data.image) ? response.data.data.image.concat(theatre.images) : theatre.images;// theatre.images.concat(response.data.data.image);
                        theatre.size += response.data.data.size;
                    }, function(response) {
                        alert(errorService.getErrorMessage(response));
                    });

                    user = $http({
                        method: 'GET',
                        url: apiConfig.generate('listings', theatre.listing.id, 'user-images') + '&limit=1000',
                    }).then(function(response) {
                        theatre.images = theatre.images.concat(response.data.data.image);
                        theatre.size += response.data.data.size;
                    }, function(response) {
                        alert(errorService.getErrorMessage(response));
                    });

                    $q.all([
                        owner,
                        user,
                    ]).then(function() {
                        $timeout(function() {
                            theatre.actions.setActive(theatre.selectedImage);
                            $timeout(function() {
                                theatre.states.initialPreloader = false;
                            });
                        });
                    });
                },
            },
            navigate: {
                next: function(event) {
                    $log.debug('Navigate NEXT');
                    if (theatre.active < theatre.size - 1) {
                        theatre.actions.setActive(theatre.active + 1);
                    } else {
                        theatre.actions.setActive(0);
                    }

                    event.stopPropagation();
                },
                prev: function(event) {
                    $log.debug('Navigate PREV');
                    if (theatre.active > 0) {
                        theatre.actions.setActive(theatre.active - 1);
                    } else {
                        theatre.actions.setActive(theatre.size - 1);
                    }

                    event.stopPropagation();
                },
                page: {
                    next: function() {
                        $log.debug('Go to next page.');

                        var page = Math.floor((theatre.active) / theatre.config.visibleElements);
                        var nextIndex = (page + 1) * theatre.config.visibleElements;

                        if (nextIndex >= 0 && nextIndex < theatre.images.length) {
                            theatre.actions.setActive(nextIndex);
                        } else {
                            theatre.actions.setActive(0);
                        }
                    },
                    prev: function() {
                        $log.debug('Go to prev page.');

                        var page = Math.floor((theatre.active) / theatre.config.visibleElements);
                        var prevIndex = (page - 1) * theatre.config.visibleElements;
                        if (prevIndex >= 0 && prevIndex <= theatre.images.length) {
                            theatre.actions.setActive(prevIndex);
                        } else {
                            theatre.actions.setActive(theatre.images.length - theatre.config.visibleElements);
                        }
                    },
                },
            },
        };


    /**
     * @memberof BdpGalleryTheatreController
     * @event theatreImagesOnChange
     * @name theatreImagesOnChange
     * @description Theatre images change watcher, keep only 'active' pictures on any change.
     */
        $scope.$watch('theatre.images', function(newVal) {
            if (angular.isDefined(newVal)) {
                $log.debug('[bdpgallerytheatre] Delete inactive pictures.');
                theatre.images = theatre.images.filter(function(image) {
                    return image.status.publishStatus === 'active';
                });
            }
        }, true);

        theatre.actions.load.all();
    }
})();
