(function() {
    'use strict';
    angular
        .module('truelocal')
        .directive('bdpCategories', BdpCategories);
    /** @ngInject */
    function BdpCategories() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpcategories/bdpcategories.html',
            scope: {
                categoriesList: '=',
                primaryCategory: '=',
                breadcrumbs: '=',
                area: '=',
                hamburgerMenuActive: '=',
            },
            controller: BdpCategoriesController,
            controllerAs: 'vm',
            bindToController: true,
        };
        return directive;
    }

    /** @ngInject */
    function BdpCategoriesController(platformService, $log, $timeout, constantsPlaceholders, constantsSettings, $document, $location, $window, $scope, $modal) {
        var vm = this;
        vm.hamburgerIndex = 10;
        vm._isMobile = platformService.getWidth() < 768;
        vm.isMobilePlatform = platformService.isMobilePlatform();
        vm.boxIsHidden = true;
        vm.viewAllCategories = false;
        vm.defQuantity = 3;
        vm.categoryQuantity = vm.defQuantity;
        vm.categoryLimit = 5;
        vm.categoryLimitMobile = [];

        vm.toggleTooltip = function() {
            vm.boxIsHidden = !vm.boxIsHidden;
        };

        vm.showAllCategories = function() {
            vm.categoryQuantity = vm.categoriesList.category.length;
            vm.viewAllCategories = true;
        };


        vm.prepareSearchUrl = function() {
            var port = $window.location.port;
            port = port != '8080' && port != '80' ? ':' + port : '';
            return $location.protocol() + '://' + $location.host() + port + '/search/';
        };

        vm.getCategoryUrl = function() {
            var url = '#';
            if (angular.isDefined(vm.breadcrumbs) && angular.isDefined(vm.breadcrumbs.category) && vm.breadcrumbs.category != null) {
                url = vm.prepareSearchUrl() + vm.breadcrumbs.category.value;
            }
            if ( angular.isDefined(vm.breadcrumbs) &&
                angular.isDefined(vm.breadcrumbs.category) &&
                vm.breadcrumbs.category != null &&
                angular.isDefined(vm.breadcrumbs.suburb) &&
                vm.breadcrumbs.suburb != null ) {
                url = url + '/' + vm.breadcrumbs.suburb.value;
            }
            return url;
        };

        vm.searchByCategoryName = function(name, place) {
            if(angular.isUndefined(name)) return;
            var path = encodeURIComponent(name.toLowerCase());
            if (place) path += '/' + encodeURIComponent(place.toLowerCase());
            $window.location.href = vm.prepareSearchUrl() + path;
        };

        vm.clickOnTitle = function() {
            vm._isMobile || vm.categoriesList.category.length == 1 ? $window.location.href = vm.getCategoryUrl() : vm.toggleTooltip();
        };

        vm.hamburgerSelect = function(index) {
            if (angular.isUndefined(index)) {
                return;
            }
            if (this.hamburgerMenuActive == index) this.hamburgerMenuActive = -1;
            else this.hamburgerMenuActive = index;
        };

        vm.openFullListPopup = function($event, $index) {
            setTimeout(function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/bdpcategories/bdpcategoriesdialog.html',
                    controller: 'BdpCategoriesDialogController',
                    controllerAs: 'vm',
                    size: 'viewAllCategories',
                    resolve: {
                        categoriesList: function() {
                            return vm.categoriesList;
                        },
                        searchByCategoryName: function() {
                            return vm.searchByCategoryName;
                        },
                        prepareSearchUrl: function() {
                            return vm.prepareSearchUrl;
                        },
                    },
                });
                _modalInstance.opened.then(function() {
                    angular.element(document.querySelector('body')).addClass('noHorizontalScroll');
                });
                _modalInstance.result.finally(function() {
                    angular.element(document.querySelector('body')).removeClass('noHorizontalScroll');
                });
                return _modalInstance;
            });
            $event.preventDefault();
        };

        vm.totalCategories = 1;
        vm.categoriesTooltipWidth = platformService.getWidth() < 992 ? 210 : 240;

        vm.setTotalCategories = function() {
            vm.totalCategories = vm.categoriesList.category.length < vm.categoryQuantity ? vm.categoriesList.category.length : vm.categoryQuantity;
            vm.categoriesTooltipWidth = {'min-width': (vm.categoriesTooltipWidth * vm.totalCategories) + 'px'};
        };

        vm.hasMore = function() {
            var _hasMore = false;
            if(vm.categoriesList.category.length > vm.categoryQuantity) {
                _hasMore = true;
            } else {
                angular.forEach(vm.categoriesList.category, function(cat) {
                    if(cat._has('services.service')) {
                        var services = (cat.services.service || cat.discoverCategories.discoverCategory);
                        if(services.length > vm.categoryLimit) {
                            _hasMore = true;
                        }
                    }
                });
            }
            return _hasMore;
        };

        var documentClickHandler = function(event) {
            if (!vm._isMobile && vm.boxIsHidden == false) {
                var element = angular.element(document.querySelector('#categories-area .tooltip'));
                var eventOutsideTarget = (element[0] !== event.target) && (0 === element.find(event.target).length);
                if (eventOutsideTarget) {
                    $timeout(function() {
                        vm.boxIsHidden = true;
                        vm.categoryQuantity = vm.defQuantity;
                    });
                }
            }
        };

        $document.on('click', documentClickHandler);
        // $document.on("touchstart", documentClickHandler);

        angular.element($window).bind('resize', function(){
            vm._isMobile = platformService.getWidth() < 768;
        });
    }
})();
