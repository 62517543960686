(function() {
    'use strict';
    angular
    .module('truelocal')
    .directive('bdpDeals', bdpDeals)
    .filter('dealspercentnodecimal', function() {
        return function(x) {
            if (x.indexOf('null') != -1 || x.indexOf('NULL') != -1) {
                return '';
            }

            var processFloats = function(str, qualitySize) {
                var regex = /[+-]?[\d,]+(\.\d+)?/g;
                return str.replace(regex, parseFloat(str.match(regex)[0].replace(',','')).toFixed(qualitySize))
                .toLocaleString('en-AU', { minimumFractionDigits: qualitySize});
            };

            if (x.indexOf('$') != -1) {
                return processFloats(x, 2);
            } else if (x.indexOf('%') != -1) {
                return processFloats(x, 0);
            } else {
                return x;
            }
        };
    });

  /** @ngInject */
    function bdpDeals() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdeals/bdpdeals.html',
            scope: {
                listing: '=',
                hamburgerMenuActive: '=',
            },
            controller: BdpDealsController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpDealsController(platformService, $rootScope, $http, $compile, $timeout, $scope, $modal, apiConfig, Seo, $window) {
            var vm = this;

            vm._isMobile = platformService.getWidth() < 768;
            vm._isTablet = platformService.getWidth() <= 1024;
            vm.hamburgerIndex = 20;

            vm.getListOfPhones = function() {
                var phones = [];
                if(angular.isDefined(vm.listing) && angular.isDefined(vm.listing.contacts) && angular.isDefined(vm.listing.contacts.contact)) {
                    angular.forEach(vm.listing.contacts.contact, function(contact) {
                        if(angular.isDefined(contact.type) && (contact.type == 'phone' || contact.type == 'mobile' || contact.type == 'national')) {
                            if(phones.indexOf(contact.value) == -1) {
                                phones.push(contact.value);
                            }
                        }
                    });
                }
                return phones.join(', ');
            };

            var printHtml = function(html) {
                angular.element(document.querySelector('body')).append(html);
                var _pageTitle = Seo.pageTitle.get();
                Seo.pageTitle.set('GET A GREAT DEAL FROM '+vm.listing.name+' - BROUGHT TO YOU BY TRUE LOCAL');
                setTimeout(function() {
                    window.print();
                    angular.element(document.querySelector('.printable-area')).remove();
                    Seo.pageTitle.set(_pageTitle);
                });
            };

            vm.printCoupon = function(index) {
                if(vm._isMobile || vm._isTablet) {
                    vm.openFullListPopup(index);
                    return;
                }

                var printScope = angular.extend($rootScope.$new(), vm.listing);
                var element = $compile(angular.element('<div><div class="printable-area">' + angular.element(document.querySelector('#coupon-print-' + index)).html() + '</div></div>'))(printScope);
                var waitForRenderAndPrint = function() {
                    if(printScope.$$phase || $http.pendingRequests.length) {
                        $timeout(waitForRenderAndPrint);
                    } else {
                        printHtml(element.html());
                        printScope.$destroy();
                    }
                };
                waitForRenderAndPrint();
            };

            vm.hamburgerSelect = function(index) {
                if (angular.isUndefined(index)) {
                    return;
                }
                if (this.hamburgerMenuActive == index) this.hamburgerMenuActive = -1;
                else this.hamburgerMenuActive = index;
            };


            angular.element($window).bind('resize', function(){
                vm._isMobile = platformService.getWidth() < 768;
                vm._isTablet = platformService.getWidth() <= 1024;
            });


            vm.openFullListPopup = function($index) {
                setTimeout(function() {
                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/bdpdeals/bdpdealsdialog.html',
                        controller: 'BdpDealsDialogController',
                        controllerAs: 'vm',
                        resolve: {
                            listing: function() {
                                return vm.listing;
                            },
                            index: function() {
                                return $index;
                            },
                        },
                    });
                    _modalInstance.opened.then(function() {
                        angular.element(document.querySelector('html')).addClass('_modal-open');
                    });
                    _modalInstance.result.finally(function() {
                        angular.element(document.querySelector('html')).removeClass('_modal-open');
                    });
                    return _modalInstance;
                });
            };

            vm.address = apiConfig.getBusinessAddress().replace('\n', ', ');

            vm.hasValue = function(deal) {
                if(typeof deal.value == 'undefined') return false;
                else if(deal.value.indexOf('null') != -1 || deal.value.indexOf('NULL') != -1) return false;
                else return true;
            };
        }
    }
})();
