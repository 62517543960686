(function() {
  'use strict';

  angular.module('truelocal')
    .directive('searchCategoryDetails', searchCategoryDetails);

  function searchCategoryDetails() {
    var directive = {
      restrict: 'EA',
      templateUrl: '/app/components/searchcategorydetails/searchcategorydetails.html',
      scope: {},
      controller: searchCategoryDetailsController,
      controllerAs: 'vm'
    };

    function blogResponse(response)
    {
        if(!response.data && !response.data.data && !response.data.data.length >0 )
        {
            return {};
        }
        var result = {
            icon: (response.data.data[0])?response.data.data[0].slug:'',
            title: (response.data.data[0])?response.data.data[0].title.rendered:'',
            description:"description",
            content:(response.data.data[0])?response.data.data[0].content.rendered:''
           };
        return result;
    }

    return directive;

    /** @ngInject */
    function searchCategoryDetailsController($stateParams, $timeout, $q,$http,apiConfig) {
      var vm = this;

      var request = $stateParams.path.split('/')[0];

      vm.loaded = false;
      requestCategoryContent(request)
        .then(function(content) {
          if (!content) return;
          vm.loaded = true;
          vm.content = content;
        });

      vm.visibleContent = false;
      vm.toggleContent = function(event) {
        vm.visibleContent = !vm.visibleContent;
       if (!vm.visibleContent) {
           window.scrollTo(0,0);
        }
      };

      vm.getIconClass = function() {
        var iconClass;
        iconClass = vm.content.icon;
        return 'icon-service_' + iconClass;
      };

      function requestCategoryContent(search) {
        return $q(function(resolve) {
               var result; //removed API call to retrieve blog category data
               resolve(result);
        });
      }
    }
  }
})();
