(function() {
    'use strict';

    angular
    .module('truelocal')

  .service('gmapService', gmapService);

    function gmapService(sharedService, $window) {
        var googleMap, markerCluster, mapReady = false;
        // var searchGMap = null;
        var service = {
            isready: isready,
            initializeMap: initializeMap,
            getMap: getMap,
            getInstance: getInstance,
            getConstants: getConstants,
            getCluster: getCluster,
        };

        var searchGMap = sharedService.getSearchGMap();

        function initializeMap() {
            angular.element(window).off('renderComplete', initializeMap);
            if (!mapReady && $window.google) {
                googleMap = new google.maps.Map(angular.element(document.querySelector('#g-map-backup'))[0], searchGMap.defaultMapOptions);
                google.maps.event.addListenerOnce(googleMap, 'idle', function() {
                    mapReady = true;
                    markerCluster = new MarkerClusterer(googleMap, [], searchGMap.clusterOptions);
                    angular.element(window).triggerHandler('map-is-ready');
                });
            }
        }

        function getCluster() {
            markerCluster.clearMarkers();
            return markerCluster;
        }

        function getConstants() {
            return google.maps;
        }

        function getInstance() {
            return googleMap;
        }

        function isready() {
            return mapReady;
        }

        function getMap() {
            return googleMap.getDiv();
        }

        angular.element(window).on('renderComplete', initializeMap);
        initializeMap(); //This will fix the issue where the first 'renderComplete' event is not captured and the map is not loaded"
        return service;
    }
})();
