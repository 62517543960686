angular
  .module('truelocal')

  /**
   * @memberof truelocal
   * @ngdoc controller
   * @name modalPendingModeration
   * @description Directive enable user to report review or comment as improper.
   *
   * @param {factory}   apiConfig                 Api configuration factory
   * @param {constant}  constantsPlaceholders     Constant placeholders
   * @param {factory}   reviewsService            Factory what handle the API requests for reviews
   * @param {constant}  constantsApiErrorCodes    Constant API error codes
   * @param {directive} $modal                    ui-bootstrap modal wrapper
   *
   **/

  .controller( modalReportPendingModeration);

/** @ngInject */
function modalReportPendingModeration(_reviewItem, apiConfig, constantsPlaceholders, reviewsService, DTM, constantsApiErrorCodes, listing, $modal) {
    var vm = this;

   /**
    * @memberof bdpReportReview
    * @method cancelReport
    * @name cancelReport
    * @description Set flag on current review as reported.
    */
    vm.cancelReport = function() {
        if (angular.isUndefined(_reviewItem)) {
            return;
        }
    };

   /**
    * @memberof bdpmodalpendingmoderation
    * @method reportThisReview
    * @name reportThisReview
    * @description report pre moderated reviews (reviews pending to be approved)
    * @params {Object} _reviewItem
    * @returns {Boolean} notReported
    */
    vm.reportThisReview = function() {
        if (this.waitingResponse) {
            return;
        }

        this.waitingResponse = true;
        var _reportType = angular.isDefined(constantsPlaceholders.bdpReportReview) ? constantsPlaceholders.bdpReportReview.reportTypeReview : '';

        this.reasonText = angular.isDefined(constantsPlaceholders.bdpReportReview) ? constantsPlaceholders.bdpReportReview.reasonText : '';

        reviewsService.reportReview(_reviewItem.id, _reviewItem, _reportType, this.reasonText, _reviewItem.isActive).then(angular.bind(this, _manageReportReviewResponse));

        if(!this.isActiveReview()) {
            angular.element(document.querySelector('#review-' + _reviewItem.id)).css('display', 'none');
            vm.reviewReported = true;
            DTM.trackAnalyticOnClick('report pre-moderated review complete');


        } else {
            vm.reviewReported = true;
            DTM.trackAnalyticOnClick('report review complete');


        }
    };

    vm.isActiveReview = function() {
        return _reviewItem.isActive;
    };

   /**
    * @memberof bdpmodalpendingmoderation
    * @method trackingPreModReviewReport
    * @name trackingPreModReviewReport
    * @description tracker for pre-moderated review: params retrieve event to catch
    *
    */
    vm.trackingPreModReviewReport = function(id) {
        DTM.trackAnalyticOnClick(id);
    };

   /**
    * @memberof bdpmodalpendingmoderation
    * @event _manageReportReviewResponse
    * @name _manageReportReviewResponse
    * @description Depending of the response on review report, show appropriate modal and cancel report if necessary.
    *
    **/
    var _manageReportReviewResponse = angular.bind(this, function(_resp) {
        if (angular.isUndefined(_resp)) {
            this.waitingResponse = false;
            this.reportedReview = false;
            this.reviewItem.reportingReview = false;
            return;
        }
        this.waitingResponse = false;

        if (_resp.status == 'error') {
            if (!(_resp.code && parseInt(_resp.code) == constantsApiErrorCodes.reportReviewComment.bazaarVoiceNotRegisteredError)) {
                this.cancelReport();
            }
        } else {
            this.reportedReview = true;
            if(!this.isActiveReview()) {
                angular.element(document.querySelector('#review-' + _reviewItem.id)).remove();
            }
        }
    });
}
