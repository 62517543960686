(function() {
    'use strict';

    angular
        .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name tradieArticle
     * @description tradieArticle from Wordpress api
     */
    .directive('tradieArticle', tradieArticle);


    /** @ngInject */
    function tradieArticle() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/tradiepage/article/article.html',
            scope: {
                city: '=',
            },
            controller: ['$http', 'apiConfig', 'DTM', tradieArticleController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function tradieArticleController($http, apiConfig, DTM) {
            var vm = this;

            /**
             * @memberof tradieArticle
             * @method blogPostData
             * @name blogPostData
             * @description get blogPost from WP api
             */
            vm.blogPostData = function(res) {
                vm.blogPosts = res.data.data;
            };

            vm.WPendpoint = apiConfig.generate('blogs'), apiConfig.getXAuthToken();
            $http.get(vm.WPendpoint + '==&tag=noFacelessQuotes').then(vm.blogPostData);

            /**
             * @memberof tradieArticle
             * @method WPPfilter
             * @name WPPfilter
             * @description filtering data post
             */

            vm.WPPfilter = function(txt) {
                return txt.replace(/<p>|<\/p>/g, '').substring(0, 200);
            };

            vm.blockArtClick = function(text) {
                DTM.trackAnalyticOnClick('blog click');
            };
        }
    }
})();
