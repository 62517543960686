(function() {
    'use strict';
    angular
        .module('truelocal')
        .controller('BdpDealsDialogController', BdpCategoriesDialogController);
    /** @ngInject */
    function BdpCategoriesDialogController(listing, index, apiConfig) {
        var vm = this;
        vm.listing = listing;
        vm.getListOfPhones = function() {
            var phones = [];
            if(angular.isDefined(vm.listing) && angular.isDefined(vm.listing.contacts) && angular.isDefined(vm.listing.contacts.contact)) {
                angular.forEach(vm.listing.contacts.contact, function(contact) {
                    if(angular.isDefined(contact.type) && (contact.type == 'phone' || contact.type == 'mobile' || contact.type == 'national')) {
                        if(phones.indexOf(contact.value) == -1) {
                            phones.push(contact.value);
                        }
                    }
                });
            }
            return phones.join(', ');
        };
        vm.index = index;
        vm.address = apiConfig.getBusinessAddress().replace('\n', ', ');

        vm.hasValue = function(deal) {
            if(typeof deal.value == 'undefined') return false;
            else if(deal.value.indexOf('null') != -1 || deal.value.indexOf('NULL') != -1) return false;
            else return true;
        };
    }
})();
