(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsBooking
     * @description Directive will display Booking Button.
     */
    .directive('bdpDetailsBooking', bdpDetailsBooking);

    /** @ngInject */
    function bdpDetailsBooking() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetailsbooking/bdpdetailsbooking.html',
            scope: {
                listing: '=',
            },
            controller: BdpDetailsBookingController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        /**
         * @memberof truelocal
         * @method bdpDetailsBooking_BdpDetailsBookingController
         * @name bdpDetailsBooking_BdpDetailsBookingController
         * @description Controller for handling the methods for the directive
         */
        function BdpDetailsBookingController() {
            var vm = this;

            vm.hasVCitaId = false;

            vm.haveVCitaId = function(listing) {
                if (angular.isDefined(listing)) {
                    this.listing = listing;
                }

                vm.hasVCitaId = angular.isDefined(this.listing) && angular.isDefined(this.listing.vcitaId) && (this.listing.vcitaServiceEnabled == true);

                return vm.hasVCitaId;
            };

        }
    }
})();