(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('heroImage', heroImage);

    /** @ngInject */
    function heroImage(apiConfig) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/servicepage/serviceitems/heroimage.html',
            scope: {
                creationDate: '=',
                searchData: '=',
                searchPath: '=',
            },
            controller: HeroIMGController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function HeroIMGController(footerUrls, $location, $http, $document) {
            var vm = this;
            vm.footerUrls = footerUrls;
            // vm.urlLocation = $location.$$absUrl.split("/");
            // vm.category = vm.urlLocation[vm.urlLocation.length -2];
            // vm.image;

            vm.swipeLeft = function() {
                var elem = angular.element(document.querySelector('#paging_footer .active'));
                var page = elem.attr('tl-paging-footer');
                if (page == 1) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="2"]')).triggerHandler('click');
                } else if (page == 2) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="3"]')).triggerHandler('click');
                } else if (page == 3) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="1"]')).triggerHandler('click');
                }
            };

            vm.swipeRight = function() {
                var elem = angular.element(document.querySelector('#paging_footer .active'));
                var page = elem.attr('tl-paging-footer');
                if (page == 1) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="3"]')).triggerHandler('click');
                } else if (page == 2) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="1"]')).triggerHandler('click');
                } else if (page == 3) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="2"]')).triggerHandler('click');
                }
            };

            vm.getHeroImages = function($category) {
                if (angular.isUndefined($category)) {
                    $category = 'cleaning';
                }
                $category.replace(' ', '-').replace('&', 'and');
                return 'https://media.truelocal.com.au/cover/' + $category + '-1400x0.jpg';
            };

            vm.cleanUp_ = function(text) {
                if (angular.isUndefined(text)) {
                    text = 'Cleaning';
                }
                text = text.replace(/-/g, ' ');
                return text;
            };

            vm.collapsed = true;
            vm.siteIndex = function() {
                if (vm.collapsed == true) {
                    angular.element(document.querySelector('#collapseFooter')).addClass('in');
                    /*angular.element(document.querySelector('body,html'))[0].animate({
                        scrollTop: angular.element(document.querySelector('#collapseFooter')).prop('offsetTop') - 208,
                    }, 'fast');*/
                    $document.scrollTop(angular.element(document.querySelector('#collapseFooter')).prop('offsetTop') - 208, 100);
                    vm.collapsed = false;
                    return vm.collapsed;
                } else {
                    angular.element(document.querySelector('#collapseFooter')).removeClass('in');
                    vm.collapsed = true;
                    return vm.collapsed;
                }
            };

            vm.getHelperClass = function() {
                if (vm.collapsed == true) {
                    return '';
                } else {
                    return 'rotated-icon';
                }
            };
        }
    }
})();
