(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name TRController
     * @description Business Services controller contain logic of search for business by its name and location
     * option. TRController prepare search results gathered from API, for the user's preferred view option, and
     * fill searchItems directive with proper data.
     *
     *  Services is accessible on the following URLs, depending of wanted result.
     *
     *
     */
    .controller('TRController', TRController);

  /** @ngInject */
    function TRController($window, $log, serviceConfigManager, $cookies, $timeout, DTM, headerService, Seo, apiConfig, platformService, $location, tradiesPageSeo, $scope, $rootScope, $state, appService) {
        var vm = this;

    // set header
        headerService.showSearchForm = true;
        headerService.logoClass = '';
        headerService.dynamicShowHideSearchForm = false;
    // end set header

        var myEl = angular.element(document.querySelector('body'));
        myEl.addClass('services-page-body');

        vm.city = $location.url().split('/')[2];

        if (DTM.hasOneTrustScript()) {
            DTM.fireEventAtPageLoad($state.current.name);
        } else {
            DTM.trackPage();
        }

        window.truncateCounter = 0;

        vm.getViewOnPage = function() {
            return 'results';
        };

        vm.apidata = {};
        vm.metadata = {};
        vm.tagsdata = {};
        vm.sponsoreddata = {};
        vm.classLoad = 'invisible';
        vm.notfound = false;
        vm.viewOnPage = vm.getViewOnPage();
        vm.noresults = false;
        vm.suggestionsList = [];
        vm.gridClass = 'service-results_type_grid-view';

        vm.showMarkerId = null;
        vm.backToListId = null;


        vm.isLoaded = function() {
            return vm.classLoad;
        };

    // generate the url
        var arrayPath = vm.pathArray;
        var foundBusinessTag = false;
        var _arrayPath = [];

        angular.forEach(arrayPath, function(objUrls) {
            if (foundBusinessTag == true) {
                _arrayPath.push(objUrls);
            }
            if (objUrls == 'business') {
                foundBusinessTag = true;
            }
        });

        var seoUrl = _arrayPath.join('/');

        vm.getErrorClass = function() {
            if (vm.notfound == false) {
                return '';
            } else {
                return 'extra-visible';
            }
        };

        vm.noResults = function() {
            return vm.noresults;
        };

        var _bodyWindowEl, _viewToobarEl, _mainContainer, _scrolledFromTop, _scrollingUp, _breadCrumbs, _platformClassCover, _headerHeight, _findWrapper, _isChrome, _isSafari, _isIE;

        var city = vm.city[0].toUpperCase() + vm.city.slice(1);
        Seo.pageTitle.set(tradiesPageSeo.title(city));
        Seo.description.set(tradiesPageSeo.description(city));
        if (tradiesPageSeo.meta) {
            for (var metaName in tradiesPageSeo.meta) {
                Seo.cleanCustomMeta(metaName);
                Seo.customMeta(metaName, tradiesPageSeo.meta[metaName]);
            }
        }

        var destroyOnSubmit = $rootScope.$on('search-submit', function(e, data) {
            DTM.trackAnalyticOnClick('search submit');
        });

        $scope.$on('$destroy', function() {
            destroyOnSubmit();
        });

        appService.displayFooter();
    }
})();
