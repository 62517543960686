(function() {
    'use strict';

    angular
    .module('truelocal')

    .constant('MIN_DESKTOP_WIDTH', 992)
    .constant('MIN_TABLET_WIDTH', 768)

    .service('getElementTotalWidth', [
        '$window',
        function($window) {
            return function getElementTotalWidth(element) {
                var node = element[0];
                var style = node.currentStyle || $window.getComputedStyle(node);
                var width = node.clientWidth
              + parseInt(style.marginLeft, 10)
              + parseInt(style.marginRight, 10);

                return width;
            };
        },
    ])


  /**
   * @memberof truelocal
   * @ngdoc directive
   * @name videoSlider
   * @description homepage local stars
   */
  .directive('videoSlider', [
      'getElementTotalWidth',
      'MIN_DESKTOP_WIDTH',
      'MIN_TABLET_WIDTH',
      videoSlider,
  ]);


  /** @ngInject */
    function videoSlider(getElementTotalWidth, MIN_DESKTOP_WIDTH, MIN_TABLET_WIDTH) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/tradiepage/videoslider/videoslider.html',
            scope: {
                city: '=',
            },
            controller: ['reviewsService', '$scope', '$rootScope', 'platformService', videoSliderController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function videoSliderController(reviewsService, $scope, $rootScope, platformService) {
            var vm = this;
            vm.tradies = [];
            vm.containerSize = 0;
            vm.shift = 0;

            var isMobile = platformService.isMobilePlatform();

            var slides = [];
            var currentSlide = angular.element();
            var startSlideIndex = 0;
            var currentSlideIndex = 0;

            reviewsService.getTradies(vm.city)
        .then(function(response) { // Hack for not valid data
            response.data.data.forEach(function(video) {
                video.featureVideo = video.featureVideo || {};
                video.primaryCategory = video.primaryCategory || {};
                video.primaryAddress = video.primaryAddress || {};
                video.statistics = video.statistics || {};
            });
            return response;
        })
        .then(function(response) {
            $rootScope.tradies = vm.tradies = response.data.data;
        });

            $scope.$on('slide.register', function(e, slide) {
                e.stopPropagation();
                if (slides.length === 0) currentSlide = slide;
                slides.push(slide);
                updateSlidesStyle(slides);
            });

            vm.getIndexOfCurrentSlide = function() {
                return currentSlideIndex;
            };

            vm.getSlidesStyle = function() {
                return {
                    'margin-left': vm.shift + 'px',
                    'width': vm.containerSize ? vm.containerSize + 'px' : 'initial',
                };
            };

            vm.hasPrevPage = function() {
                return currentSlideIndex > startSlideIndex;
            };

            vm.hasNextPage = function() {
                var slide = currentSlide;
                var slideCtr = 0;
                while(slide.length > 0){
                    slide = slide.next();
                    slideCtr++;
                }
                return slideCtr >= countSteps();
            };

            vm.hasNavButtons = function() {
                return !isMobile;
            };

            vm.onExposeTradie = function(index) {
                $rootScope.currentTradieVideoSeclected = index;
            };

            vm.swipeLeft = function() {
                currentSlide = moveSlides(currentSlide, -countSteps());
            };

            vm.swipeRight = function() {
                currentSlide = moveSlides(currentSlide, countSteps());
            };

            function countSteps() {
                var width = window.innerWidth;
                if (width >= MIN_DESKTOP_WIDTH) return 3;
                if (width >= MIN_TABLET_WIDTH) return 2;
                return 1;
            }

            function setShift(shift) {
                vm.shift += shift;
            }

            function moveSlides(from, steps) {
                var absSteps = Math.abs(steps);
                var shift = 0;
                var shifted = 0;
                var current = from;

                if (steps < startSlideIndex) {
                    if(currentSlideIndex > startSlideIndex) {
                        while (steps++) {
                            current = getCurrentNode(currentSlideIndex--);
                            shift += getElementTotalWidth(current);
                        }
                        current = getCurrentNode(currentSlideIndex + 1);
                    }
                } else if (steps > startSlideIndex) {
                    var oldSlideIndex = currentSlideIndex;
                    while (current.next().length && steps--) {
                        shift -= getElementTotalWidth(current.next());
                        current = current.next();
                        shifted++;
                        currentSlideIndex++;
                    }
                    if (steps !== -1 && absSteps > shifted) {
                        shift = 0;
                        currentSlideIndex = oldSlideIndex;
                    }
                }

                if (shift) {
                    setShift(shift);
                    return current;
                }

                return from;
            }

            function updateSlidesStyle(slides) {
                var width = 0;
                for (var i = slides.length; i--;) {
                    width += getElementTotalWidth(slides[i]);
                }
                vm.containerSize = width;
            }

            function getCurrentNode(index){
                var nodes = document.getElementById("localstars").querySelectorAll("li.item.ng-scope.ng-isolate-scope");
                return angular.element(nodes[index]);
            }
        }
    }
})();
