(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('servNavbar', servNavbar);

    /** @ngInject */
    function servNavbar() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/mainHeader/serviceheader.html',
            scope: {
                creationDate: '=',
            },
            controller: servNavbarController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function servNavbarController(apiConfig, authService, modalFactory, $location, $modal, $rootScope, DTM) {
            var vm = this;
            vm.baseUrl = $location.protocol() + '://' + $location.host();


            vm.getBaseUrl = function() {
                return $location.protocol() + '://' + $location.host();
            };
        }
    }
})();
