(function() {
    'use strict';


    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name scrollDetectWithTimeout
     * @description Directive provide possibility to set option of detecting of scrolling with timeout to true or false.
     */
    .factory('scrollDetectWithTimeout', function() {
        var service = {
            value: false,
            disable: setDisabled,
            enable: setEnabled,
            getValue: getValue,
        };

        function setDisabled() {
            service.value = false;
        }

        function setEnabled() {
            service.value = true;
        }

        function getValue() {
            return service.value;
        }

        return service;
    })


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name tlEventsInputs
     * @description Directive transfer focus, blur and click events, on desktop platforms to element that is
     * provided trough directive attribute value (clickparent / clickchild).
     */
    .directive('tlEventsInputs', function(platformService) {
        return {
            restrict: 'A',
            link: function(scope, element, attr) {
                if (platformService.isMobile() == false) {
                    if (attr.tlEventsInputs == 'clickparent') {
                        element.on('focus', function(e) {
                            element.parent().addClass('block-focus');
                        });
                        element.on('blur', function(e) {
                            element.parent().removeClass('block-focus');
                        });
                    } else if (attr.tlEventsInputs == 'clickchild') {
                        element.on('click', function(e) {
                            element.find('input')[0].focus();
                        });
                    }
                }
            },
        };
    })


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name tlPagingFooter
     * @description Directive tlPagingFooter.
     */
    .directive('tlPagingFooter', function() {
        return {
            restrict: 'A',
            link: function(scope, elem, attr) {
                elem.on('click', function(e) {
                    if (angular.element(document.querySelector('#paging_footer')).css('display') != 'none') {
                        if (attr.tlPagingFooter == 1) {
                            angular.element(document.querySelector('.footer-holder .bott-columns .column')).css('display', 'none');
                            angular.element(document.querySelector('.footer-holder .bott-columns .column:nth-child(1)')).css('display', 'block');
                            angular.element(document.querySelector('.footer-holder .bott-columns .column:nth-child(2)')).css('display', 'block');
                            elem.parent().find('span').removeClass('active');
                            elem.addClass('active');
                        } else if (attr.tlPagingFooter == 2) {
                            angular.element(document.querySelector('.footer-holder .bott-columns .column')).css('display', 'none');
                            angular.element(document.querySelector('.footer-holder .bott-columns .column:nth-child(3)')).css('display', 'block');
                            angular.element(document.querySelector('.footer-holder .bott-columns .column:nth-child(4)')).css('display', 'block');
                            elem.parent().find('span').removeClass('active');
                            elem.addClass('active');
                        } else if (attr.tlPagingFooter == 3) {
                            angular.element(document.querySelector('.footer-holder .bott-columns .column')).css('display', 'none');
                            angular.element(document.querySelector('.footer-holder .bott-columns .column:nth-child(5)')).css('display', 'block');
                            elem.parent().find('span').removeClass('active');
                            elem.addClass('active');
                        }
                    }
                });
            },
        };
    })


})();
