(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpSingleReviewCommentBlock
     * @description Directive display singe user comment on review with user details.
     *
     * @example
     * <bdp-single-review-comment-block user-comment="[object]"></bdp-single-review-comment-block>
     */
    .directive('bdpSingleReviewCommentBlock', bdpSingleReviewCommentBlock)


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name ngCommentBlock
     * @description Directive ensure dimensions of, scrolling to and other behavior of comment
     * box holder.
     *
     * @param {factory}   DTM           TL tracking services
     * @param {constant}  constantsPlaceholders     Constant placeholders
     *
     * @example
     * <p  ng-comment-block></p>
     */
    .directive('ngCommentBlock', ngCommentBlockHandler);

    function ngCommentBlockHandler(DTM, constantsPlaceholders) {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                function _setOriginalText(_s, _e) {
                    DTM.trackAnalyticOnClick('read more review');
                    if (angular.isDefined(_s.vm.isFromTol) && _s.vm.isFromTol) {
                        return;
                    } else {
                        _s.vm.formatedBlock = _s.vm.userComment;
                        _e.find(constantsPlaceholders.bdpReadMoreHandler.defaultSeparatorElementTagName).remove();
                        _e.find(constantsPlaceholders.bdpReadMoreHandler.defaultActionElementTagName).off().remove();
                        angular.element(_e).html(_s.vm.formatedBlock);
                        _s.$apply();
                    }
                }

                function _createStringBlock(array, to) {
                    for (var i = 1, l = to, str = array[0]; i < l; i++) {
                        str += '.' + array[i];
                    }
                    return str;
                }

                setTimeout(angular.bind(this, function(_s, _e) {
                    if (angular.isUndefined(_s.heightLimit)) {
                        _s.heightLimit = 123;
                    }

                    _s.heightLimit = parseInt(_s.heightLimit);

                    var _measureBlock = (_s.vm.noReadMore) ? constantsPlaceholders.bdpReadMoreHandler.labelWithoutText : constantsPlaceholders.bdpReadMoreHandler.labelWithText;
                    var _workingElement = angular.element(_e);
                    
                    if (_workingElement[0].clientHeight > _s.heightLimit) {
                        var _indexSentence = 1;
                        _workingElement.html(_createStringBlock(_s.vm.commentBlocks, _indexSentence));

                        do {
                            _indexSentence += 1;
                            _workingElement.html(_createStringBlock(_s.vm.commentBlocks, _indexSentence));
                        } while (_workingElement[0].clientHeight < _s.heightLimit);

                        _indexSentence += 1;
                        var _tempArr = _s.vm.commentBlocks.splice(0, _indexSentence);
                        _s.vm.commentBlocks = _tempArr;

                        var _putInWordBlocks = _s.vm.commentBlocks.join('.');

                        _s.vm.commentBlocks = _putInWordBlocks.split(' ');
                        _s.vm.commentBlocks.push(_measureBlock);

                        _workingElement.html(_s.vm.commentBlocks.join(' '));

                        do {
                            _s.vm.commentBlocks.splice(_s.vm.commentBlocks.length - 2, 1);
                            var _stringBase = _s.vm.commentBlocks.join(' ');
                            _workingElement.html(_stringBase);
                        } while (_workingElement[0].clientHeight > _s.heightLimit);

                        _s.vm.formatedBlock = _s.vm.commentBlocks.join(' ');
                        _s.$apply();

                        var _indexReadMore = _s.vm.formatedBlock.indexOf(' ' + _measureBlock);
                        _s.vm.formatedBlock = _s.vm.formatedBlock.substr(0, _indexReadMore);
                        _workingElement.html(_s.vm.formatedBlock);
                        _workingElement.append(constantsPlaceholders.bdpReadMoreHandler.defaultSeparatorElement);

                        if (angular.isDefined(_s.vm.listenTo)) {
                            _workingElement.attr('id', _s.vm.listenTo);
                            _workingElement.bind(_s.vm.listenTo, angular.bind(_s, _setOriginalText, _s, _workingElement));
                        }

                        if (angular.isDefined(_s.vm.triggerTo)) {
                            _workingElement.on('click', function() {
                                var _element = '#' + _s.vm.triggerTo;
                                angular.element(document.querySelector(_element)).triggerHandler(_s.vm.triggerTo);
                            });
                        }

                        if (!_s.vm.noReadMore) {
                            _workingElement.append(constantsPlaceholders.bdpReadMoreHandler.defaultActionElement);
                            _workingElement.find(constantsPlaceholders.bdpReadMoreHandler.defaultActionElementTagName).on('click', angular.bind(_s, _setOriginalText, _s, _workingElement));
                        }
                    } else {
                        _workingElement.html(_s.vm.formatedBlock);
                    }
                }, scope, elem), 1);
            },
            scope: {
                vm: '=',
                heightLimit: '=',
            },
        };
    }

  /** @ngInject */
    function bdpSingleReviewCommentBlock(constantsClasses) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpsinglereviewcommentblock/bdpsinglereviewcommentblock.html',
            scope: {
                userComment: '=',
                noReadMore: '=',
                noItemPropDescription: '=',
                isFromTol: '=',
                listenTo: '=',
                triggerTo: '=',
            },
            controller: BdpSingleReviewCommentBlockController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpSingleReviewCommentBlockController() {
      /**
       * @memberof bdpSingleReviewCommentBlock
       * @method getCustomClass
       * @name getCustomClass
       * @description Returns custom comment class if exist, default otherwise.
       * @returns {String} specialClass
       */
            this.getCustomClass = function() {
                if (angular.isDefined(this.isFromTol) && this.isFromTol) {
                    return constantsClasses.bdpSingleReviewCommentBlock.tolCenteredText;
                }
                return constantsClasses.bdpSingleReviewCommentBlock.defaultLeftAlignment;
            };


      /**
       * @memberof bdpSingleReviewCommentBlock
       * @method showFullText
       * @name showFullText
       * @description Returns user comment full text.
       * @returns {String} formatedText
       */
            this.showFullText = function() {
                this.formatedBlock = (angular.isDefined(this.userComment)) ? this.userComment : '';
            };

            if (angular.isDefined(this.userComment)) {
                this.commentBlocks = this.userComment.split('.');
                this.formatedBlock = this.userComment + '';
            }
        }
    }
})();
