(function() {
    'use strict';

    angular
    .module('truelocal')
    .directive('greyFooter', greyFooter);

  /** @ngInject */
    function greyFooter() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/greyFooter/greyfooter.html',
            scope: {
                creationDate: '=',
            },
            controller: GreyFooterController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function GreyFooterController(footerUrls, serviceConfigManager, $document) {
            var vm = this;
            vm.footerUrls = footerUrls;
            vm.location = serviceConfigManager.getProperty('location').capitalizeSuburb();

            vm.swipeLeft = function() {
                var elem = angular.element(document.querySelector('#paging_footer .active'));
                var page = elem.attr('tl-paging-footer');
                if(page == 1) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="2"]')).triggerHandler('click');
                } else if(page == 2) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="3"]')).triggerHandler('click');
                } else if(page == 3) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="1"]')).triggerHandler('click');
                }
            };

            vm.swipeRight= function() {
                var elem = angular.element(document.querySelector('#paging_footer .active'));
                var page = elem.attr('tl-paging-footer');
                if(page == 1) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="3"]')).triggerHandler('click');
                } else if(page == 2) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="1"]')).triggerHandler('click');
                } else if(page == 3) {
                    angular.element(document.querySelector('#paging_footer span[tl-paging-footer="2"]')).triggerHandler('click');
                }
            };

            vm.collapsed = true;
            vm.siteIndex = function() {
                if(vm.collapsed==true) {
                    angular.element(document.querySelector('#collapseFooter')).addClass('in');
                    //angular.element(document.querySelector('body,html'))[0].animate({scrollTop: angular.element(document.querySelector('#collapseFooter')).prop('offsetTop')-208}, 'fast');
                    $document.scrollTop(angular.element(document.querySelector('#collapseFooter')).prop('offsetTop') - 208, 1000);
                    vm.collapsed = false;
                    return vm.collapsed;
                } else {
                    angular.element(document.querySelector('#collapseFooter')).removeClass('in');
                    vm.collapsed = true;
                    return vm.collapsed;
                }
            };

            vm.getHelperClass = function() {
                if(vm.collapsed==true) {
                    return '';
                } else {
                    return 'rotated-icon';
                }
            };
        }
    }
})();
