(function() {
    'use strict';

    /**
     * @memberof truelocal
     * @$noLocalStorage factory
     * @name $noLocalStorage
     * @description $noLocalStorage
     * will fix LocalStorage problem for older browsers and private mode browsing
     * using JS memory instead of LS when not avaialble
     *
     * @param {service}   $window                   Angular wrapper for window
     *
     */

    angular
        .module('truelocal')
        .factory('$noLocalStorage', [
            function() {
                var service = {
                    setItem: setItem,
                    getItem: getItem,
                    removeItem: removeItem,
                    clear: clear,
                    key: key,
                };

                function setItem(key, value) {
                    this[key] = value;
                }

                function getItem(key) {
                    return typeof this[key] == 'undefined' ? null : this[key];
                }

                function removeItem(key) {
                    this[key] = void(0);
                }

                function clear() {
                    for (var key in this) {
                        if (this.hasOwnProperty(key)) {
                            this.removeItem(key);
                        }
                    }
                }

                function key(index) {
                    return Object.keys(this)[index];
                }
                return service;
            },
        ])

    /**
     * @memberof truelocal
     * @$localstorage factory
     * @name $localstorage
     * @description $localstorage
     * will check if Browser support local storage, otherwsie using $localstorage
     *
     * @param {service}   $window                   Angular wrapper for window
     * @param {factory}   $noLocalStorage
     */

    .factory('$localstorage', [
        '$window', '$noLocalStorage',
        function($window, $noLocalStorage) {
            var service = {
                setItem: setItem,
                getItem: getItem,
                setObject: setObject,
                getObject: getObject,
                removeItem: removeItem,
                clear: clear,
                key: key,
                isSupported: isSupported,
            };

            var LS = $window.localStorage;

            function isStorageSupported() {
                var testLS = 'truelocal',
                    storage = LS;
                try {
                    storage.setItem(testLS, testLS);
                    storage.removeItem(testLS);
                    return true;
                } catch (error) {
                    return false;
                }
            }

            var storage = isStorageSupported() ? LS : $noLocalStorage;


            function isSupported() {
                return isStorageSupported();
            }

            function setItem(key, value) {
                storage.setItem(key, value);
            }

            function getItem(key, defaultValue) {
                return storage.getItem(key) || defaultValue;
            }

            function setObject(key, value) {
                storage.setItem(key, JSON.stringify(value));
            }

            function getObject(key) {
                return JSON.parse(storage.getItem(key) || '{}');
            }

            function removeItem(key) {
                storage.removeItem(key);
            }

            function clear() {
                storage.clear();
            }

            function key(index) {
                storage.key(index);
            }
            return service;
        },
    ]);
})();
