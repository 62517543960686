(function() {
    'use strict';

    angular
    .module('truelocal')
    .directive('loadMoreServ', loadMoreServ);

  /** @ngInject */
    function loadMoreServ($timeout, serviceConfigManager, DTM) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/servicepage/ssloadmore/loadmoreservices.html',
            scope: {
                searchListing: '=',
            },
            controller: loadMoreServController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function loadMoreServController() {
            this.loadingMore = false;

            this.loadMoreResults = function() {
                DTM.trackAnalyticOnClick('service load more');

                if(this.loadingMore) {
                    return;
                }

                this.loadingMore = true;

                $timeout(angular.bind(this, function() {
                    serviceConfigManager.displayNextResultsBlock(angular.bind(this, function() {
                        this.loadingMore = false;
                    }));
                }), 0);
            };

            this.checkResultsOffset = function() {
                if(angular.isDefined(this.searchListing) && angular.isDefined(this.searchListing.size) && this.searchListing.size) {
                    return serviceConfigManager.hideLoadMoreButton();
                }
                return true;
            };

            this.noResults = function() {
                if(angular.isDefined(this.searchListing) && angular.isDefined(this.searchListing.size) && this.searchListing.siz) {
                    return false;
                }
                return true;
            };
        }
    }
})();
