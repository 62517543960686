/* eslint-disable require-jsdoc */
(function() {
    'use strict';

    angular
  .module('truelocal')
  .service('urlValidatorService', urlValidatorService);


  /** @ngInject */
  /**
   * @memberof truelocal
   * @ngdoc service
   * @name urlValidatorService
   * @description urlValidatorService provides method that validates user inputs as URLs based on specific criteria.
   * @return {Object} returns the service
   */
    function urlValidatorService() {
        var service = {
            containsUrl: containsUrl,
        };

        /**
         * Checks whether a given input string contains any URLs.
         * @memberof validatorService
         * @method
         * @name containsUrl
         * @param {string} input - The string input that needs to be validated.
         * @returns {boolean} Returns true if the input contains URLs, false otherwise.
         * @example
         * const input = 'Check this website https://www.example.com for more information.';
         * const hasUrls = containsUrl(input);
         * console.log(hasUrls); // Output: true
         */
        function containsUrl(input) {
            if (input === null || input === undefined) {
                return false;
            }

            var words = input.split(/\s+/);
            var hasURL = false;

            words.forEach(function(word) {
                if (validator.isURL(word)) {
                    hasURL = true;
                    return;
                }
            });

            return hasURL;
        }

        return service;
    }
})();
