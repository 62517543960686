(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReviewsRatingStatistic
     * @description Directive display rating statistic information: number of each rate category individual.
     *
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {constant}  constantsClasses          Constant classes
     *
     * @example
     * <bdp-reviews-rating-statistic reviews-ratings="[object]"></bdp-reviews-rating-statistic>
     */
    .directive('bdpReviewsRatingStatistic', bdpReviewsRatingStatistic);

  /** @ngInject */
    function bdpReviewsRatingStatistic() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreviewsratingstatistics/bdpreviewsratingstatistics.html',
            scope: {
                reviewsRatings: '=',
            },
            controller: ['apiConfig', 'constantsClasses', BdpReviewsRatingStatisticController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpReviewsRatingStatisticController(apiConfig, constantsClasses) {
      /**
       * @memberof bdpReviewsRatingStatistic
       * @method getCategoryVertical
       * @name getCategoryVertical
       * @description Returns true if business have categoryVertical name "Accommodation" which mean it
       * doesn't show stars.
       * @returns {Boolean} categoryVerticalExist
       */
            this.getCategoryVertical = function() {
                if (angular.isUndefined(apiConfig) || angular.isUndefined(apiConfig.getListing)) {
                    return false;
                }
                var _listing = apiConfig.getListing();
                if (_listing != null &&
          angular.isDefined(_listing) &&
          angular.isDefined(_listing.bussinessDetails) &&
          angular.isDefined(_listing.bussinessDetails.categoryVertical) &&
          angular.isDefined(_listing.bussinessDetails.categoryVertical.name) &&
          _listing.bussinessDetails.categoryVertical.name == 'Accommodation') {
                    return true;
                } else {
                    return false;
                }
            };


      /**
       * @memberof bdpReviewsRatingStatistic
       * @method active
       * @name active
       * @description Returns icon class for each state by each category.
       * @params {String} active or not _state
       * @returns {String} icon
       */
            this.active = function(_state) {
                if (angular.isUndefined(constantsClasses) || angular.isUndefined(constantsClasses.bdpRatingStars)) {
                    return '';
                }
                var isVertical = this.getCategoryVertical();
                if (_state) {
                    if (isVertical) {
                        return (angular.isDefined(constantsClasses.bdpRatingStars.activeIconCircle)) ? constantsClasses.bdpRatingStars.activeIconCircle : '';
                    } else {
                        return (angular.isDefined(constantsClasses.bdpRatingStars.activeIconStar)) ? constantsClasses.bdpRatingStars.activeIconStar : '';
                    }
                } else {
                    if (isVertical) {
                        return (angular.isDefined(constantsClasses.bdpRatingStars.iconCircle)) ? constantsClasses.bdpRatingStars.iconCircle : '';
                    } else {
                        return (angular.isDefined(constantsClasses.bdpRatingStars.iconStar)) ? constantsClasses.bdpRatingStars.iconStar : '';
                    }
                }
            };
        }
    }
})();
