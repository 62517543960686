(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name searchButtonMobile
     * @description Directive adopt search button behavior when button is hovered or clicked, or when url is changed
     * without clicking button.
     */
    .directive('searchButtonMobile', ['$window', 'HeaderSearchDropDownSevice', '$document', searchButtonMobile]);

    function searchButtonMobile($window, HeaderSearchDropDownSevice, $document) {
        return {
            restrict: 'A',
            link: function(scope, elem) {
                angular.element($window).on('url-changed', angular.bind(this, function(_e) {
                    angular.element($window).triggerHandler('force-toggle-sort');
                    if (angular.element(_e).hasClass('active')) {
                        angular.element(_e).removeClass('active');
                        angular.element(document.querySelector('.search-form.js-header-search-form')).css('display', 'none');
          //  angular.element('body').removeClass('noHorizontalScroll');
                        angular.element(document.querySelector('html')).removeClass('_menu-open');
                        angular.element(document.querySelector('body')).removeClass('modal-open');
                        HeaderSearchDropDownSevice.setMobileButtonStatus(false);
                        angular.element(document.querySelector('header')).css({
                            'position': 'fixed',
                            'top': '0',
                        });
                    }
                }, elem));
                angular.element(elem).on('click', function() {
                    $document.scrollTop(0,500);
                    angular.element($window).triggerHandler('force-toggle-sort');
                    if (angular.element(elem).hasClass('active')) {
                        angular.element(elem).removeClass('active');
                        angular.element(document.querySelector('.search-form.js-header-search-form')).css({'transition': '0.5s linear all', 'display':'none'});
          //  angular.element('body').removeClass('noHorizontalScroll');
                        angular.element(document.querySelector('html')).removeClass('_menu-open');
                        angular.element(document.querySelector('body')).removeClass('modal-open');
                        HeaderSearchDropDownSevice.setMobileButtonStatus(false);
                        angular.element(document.querySelector('header')).css({
                            'position': 'fixed',
                            'top': '0',
                        });
                    } else {
                        angular.element(elem).addClass('active');
                        angular.element(document.querySelector('.search-form.js-header-search-form')).css({'transition': '0.5s linear all', 'display':'block'});
          //  angular.element('body').addClass('noHorizontalScroll');
                        angular.element(document.querySelector('html')).addClass('_menu-open');
                        angular.element(document.querySelector('body')).addClass('modal-open');
                        HeaderSearchDropDownSevice.setMobileButtonStatus(true);
                        var _offsetTop = angular.element(document.querySelector('header')).prop('offsetTop');
                        angular.element(document.querySelector('header')).css({
                            'position': 'absolute',
                            'top': _offsetTop + 'px',
                        });
                    }
                });
            },
            scope: {
                vm: '=',
            },
        };
    }
})();
