(function() {
    'use strict';

    angular
    .module('truelocal')

  /**
   * @memberof truelocal
   * @ngdoc directive
   * @name videoSliderNavButton
   * @description homepage local stars
   */
  .directive('videoSliderNavButton', videoSliderNavButton);

  /** @ngInject */
    function videoSliderNavButton() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: '/app/components/tradiepage/videoslider/videoslider-nav-button.html',
            scope: {
                direction: '=',
            },
            controller: videoSliderNavButtonController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function videoSliderNavButtonController() {
            var vm = this;

            switch (vm.direction) {
            case 'prev':
                vm.transformation = 'rotate(270deg)';
                break;
            default:
                vm.transformation = 'rotate(90deg)';
            }
        }
    }
})();
