(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name homeInstagramFeed
     * @description homepage Instagram image feeder
     *
     * * @example
     * <home-instagram-feed></home-instagram-feed>
     */
    .directive('homeInstagramFeed', homeInstagramFeed)
        /**
         * @memberof truelocal
         * @ngdoc factory
         * @name InstagramAPI
         * @description InstagramAPI factory - makes the http request to get the feed from instagram
         *
         * @param  {service}  $http                     angular http service
         * @param {constant}  env                       Environmental constants
         *
         */
        .factory('InstagramAPI', ['$http', 'env', 'platformService', InstagramAPI]);

    var lgDesktop = 1390, mnTablet = 767, mxTablet = 992;
    function InstagramAPI($http, env, platformService) {
        if (platformService.getWidth() > mnTablet) {
            return {
                fetchPhotos: function(callback) {
                    var endpoint = 'https://api.instagram.com/v1/users/' + env.instagram.user_id + '/media/recent/?count=10';
                    endpoint += '&access_token=1420875672.1677ed0.836faea3709843c59b5c147cbad88e06';
                    endpoint += '&callback=JSON_CALLBACK';
                    $http.jsonp(endpoint, {
                        ignoreLoadingBar: true,
                    }).success(function(response) {
                        callback(response.data);
                    });
                },
            };
        } else {
            return !!0;
        }
    }
    /** @ngInject */
    function homeInstagramFeed() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/homepage/homeinstagramfeed/homeinstagramfeed.html',
            controller: ['InstagramAPI', 'env', 'platformService', HomeInstagramFeed],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;


        function HomeInstagramFeed(InstagramAPI, env, platformService) {
            var vm = this;
            vm.env = env;
            if (platformService.getWidth() > mnTablet) {
                InstagramAPI.fetchPhotos(function(data) {
                    if (data.length > 10) {
                        vm.pics = data.slice(0, 10);
                    } else {
                        vm.pics = data;
                    }
                });
            }


            vm.getImageUrl = function(images, size) {
                // instagram api will return 3 images
                var sizeMap = {
                    'sm': 'thumbnail',
                    'md': 'low_resolution',
                    'lg': 'standard_resolution',
                };
                return images[sizeMap[size]].url;
            };
        }
    }
})();
