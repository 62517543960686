(function() {
    'use strict';

    angular
    .module('truelocal')
    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpAddComment
     * @description
     * bdp-add-comment is designed for handling handling the add comment to a review process
     *
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {service}   $log                      Angular console log wrapper
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   reviewsService            Factory what handle the API requests for reviews
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   ModalLikeDislikeFactory   Factory service provider method for setMessage for comment or review - custom messages.
     * @param {factory}   Iovation                  Factory service provider method for handle integration with Iovation platforms
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {service}   swear                     Swear service provide collection of blocked words to enable client side user's input validation.
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
     *
     *
     * @example
     * <bdp-add-comment review-data="[object]"></bdp-add-comment>
     *
     */
    .directive('bdpAddComment', ['$modal', '$log', 'apiConfig', 'modalFactory', 'reviewsService', 'authService', 'ModalLikeDislikeFactory', 'Iovation', 'platformService', 'swear', 'constantsPlaceholders', 'windowScroll', '$document','$window', bdpAddComment]);

  /** @ngInject */
    function bdpAddComment($modal, $log, apiConfig, modalFactory, reviewsService, authService, ModalLikeDislikeFactory, Iovation, platformService, swear, constantsPlaceholders, windowScroll, $document, $window) {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpaddcomment/bdpaddcomment.html',
            scope: {
                reviewData: '=',
            },
            controller: BdpAddCommentController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */
        function BdpAddCommentController() {
            var vm = this;

            this.userComment = '';
            this.addingComment = false;
            this.addedBlankComment = false;
            this.bdpCommentBoxSmall = constantsPlaceholders.bdpCommentBoxSmall;

      /**
       * @memberof bdpAddComment
       * @method loginUser
       * @name loginUser
       * @description call login modal
       */
            this.loginUser = function() {
                var _modalInstance = authService.authModal(modalFactory, {});

                _modalInstance.result.then(function() {
                }, function() {
                    $log.debug('Modal dismissed at: ' + new Date());
                });
            };


      /**
       * @memberof bdpAddComment
       * @method showThankYouBlock
       * @name showThankYouBlock
       * @description Display AddReviewBlock block.
       * @return {Boolean} show/hide thank you block
       */
            this.showThankYouBlock = function() {
                if (!apiConfig.userIsAuthenticated()) {
                    return false;
                }

                if (this.reviewData && this.reviewData.addedCommentThankYouMessage) {
                    return true;
                }

                return false;
            };


      /**
       * @memberof bdpAddComment
       * @method cancelAddingComment
       * @name cancelAddingComment
       * @description Handle cancelling of adding comment.
       */
            this.cancelAddingComment = function() {
                if (this.addingComment) {
                    return;
                }
                this.userComment = '';
                if (angular.isUndefined(this.reviewData)) {
                    return;
                }
                angular.extend(this.reviewData, {startAddingComment: false});
            };


      /**
       * @memberof bdpAddComment
       * @method manageAddCommentResponse
       * @name manageAddCommentResponse
       * @description Handle displaying of response to adding comment.
       */
            this.manageAddCommentResponse = function(_resp) {
                $log.warn('response: %O', _resp);
                if (_resp.status == 'error') {
                    this.addingComment = false;

                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                        controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                        resolve: {
                            storedData: function() {
                                return _resp.data.data.meta.messages[0];
                            },
                        },
                    });

                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });
                } else {
                    this.addingComment = false;

                    var _revObject = {
                        id: _resp.data.id,
                        isActive: 'pending',
                        isApproved: 'inactive',
                        dateCreated: '',
                        userName: '',
                        userAvatar: '',
                        userComment: _resp.config.data.text,
                    };
                    if (platformService.isMobile()) {
                        //angular.element(document.querySelector('html, body'))[0].animate({scrollTop: (angular.element(window)[0].scrollY - 70) + 'px'}, 300);
                        $document.scrollTop($window.scrollY - 70, 300);
                    }
                    angular.extend(this.reviewData, {addedCommentThankYouMessage: true, userCommentData: _revObject});
                }
            };


      /**
       * @memberof bdpAddComment
       * @method addComment
       * @name addComment
       * @description Display add comment modal and handle adding of comment, and tracking of user activity.
       */
            this.addComment = function() {
                this.addedBlankComment = false;
                if (this.addingComment) {
                    return;
                }
                var _commentData = {
                    'text': this.userComment,
                    'fp': Iovation.fingerPrint,
                };
                this.errSwear = swear.hasSwearing(this.userComment);
                if(this.errSwear == true) return;

                if(this.userComment.trim() == '') {
                    this.addedBlankComment = true;
                    return;
                }

                if (/^\s+$/.test(this.userComment)) {
                    this.errMsg = 'Your comment needs to be at least 50 characters long.';
                    return;
                }

                this.addingComment = true;
                if (apiConfig.userIsAuthenticated()) {
                    reviewsService.addComment(this.reviewData.id, _commentData, this.reviewData).then(angular.bind(this, this.manageAddCommentResponse));
                } else {
                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                        controller: 'ModalAuthPromptController',
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                    });
    
                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });
                }
            };
        }
    }
})();
