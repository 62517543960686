(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpBookmark
     * @description Directive displays button in bdp header with option to bookmark current business. User must be
     * signed in to bookmark itself current business. Saved users bookmarks should be accessible to user on special
     * page, and should not be visible on bdp page.
     *
     * @param {service}   $scope                    Angular scope service
     * @param {service}   $log                      Angular console log wrapper
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {service}   $http                     angular http service
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {service}   $window                   angular window service wrapper
     * @param {factory}   DTM           TL tracking services
     *
     * @example
     * <bdp-bookmark details="[object]"></bdp-bookmark>
     */
    .directive('bdpBookmark', bdpBookmark);

  /** @ngInject */
    function bdpBookmark() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpbookmark/bdpbookmark.html',
            scope: {
                show: '=',
                details: '=',
            },
            controller: ['$scope', '$log', '$modal', '$http', 'apiConfig', 'authService', 'modalFactory', '$window', 'DTM', 'windowScroll', BdpBookmarkController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */

        function BdpBookmarkController($scope, $log, $modal, $http, apiConfig, authService, modalFactory, $window, DTM, windowScroll) {
            var vm = this;
      // start bookmark modal controller and directive
            vm.details = this.details;
            vm.saved = false;


      /**
       * @memberof bdpBookmark
       * @method isSaved
       * @name isSaved
       * @description Check is bookmark saved.
       * @returns {Boolean}
       */
            this.isSaved = function() {
                if (!apiConfig.userIsAuthenticated()) {
                    return false;
                } else {
                    return vm.saved;
                }
            };


      /**
       * @memberof bdpBookmark
       * @method showAuthPrompt
       * @name showAuthPrompt
       * @description Call login modal.
       */
            this.showAuthPrompt = function() {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                    controller: 'ModalAuthPromptController',
                    controllerAs: 'vm',
                    size: 'dialog_size_small',
                });

                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
            };


      /**
       * @memberof bdpBookmark
       * @method save
       * @name save
       * @description Save bookmark to API.
       * @returns {Boolean}
       */
            vm.save = function() {
                if (!apiConfig.userIsAuthenticated()) {
                    this.showAuthPrompt();
                } else {
                    if (!vm.saved) {
                        vm.saved = true;
                        $http.get(apiConfig.generate('listings', this.details.id, 'save'), apiConfig.getXAuthToken())
              .then(function() {
                  vm.saved = true;

                  DTM.trackAnalyticOnClick('bookmark listing:completed');
              }, function(response) {
                  if (/You have already saved/.test(response.data.meta.messages[0])) {
                      vm.saved = true;
                  } else {
                      vm.saved = false;
                  }
              });
                    } else {
                        vm.saved = false;
                        $http.get(apiConfig.generate('listings', this.details.id, 'unsave'), apiConfig.getXAuthToken())
              .then(function() {
                  vm.saved = false;
              }, function(response) {
                  if (/You/.test(response.data.meta.messages[0])) {
                      vm.saved = false;
                  } else {
                      vm.saved = true;
                  }
              });
                    }
                }
            };


      /**
       * @memberof bdpBookmark
       * @method list
       * @name list
       * @description List saved bookmarks.
       * @returns {Boolean}
       */
            vm.list = function() {
                if (apiConfig.userIsAuthenticated()) {
                    $http.get(apiConfig.generate('users/me/saved') + '&limit=1000', apiConfig.getXAuthToken())
                .then(function(response) {
                  if(response._has('data.data.listing')){
                    angular.forEach(response.data.data.listing, function(listing) {
                        if (!vm.saved && listing.id == vm.details.id) {
                            vm.saved = true;
                        }
                    });
                  }              
                });
              }
            };

            angular.element(window).on('user-authenticated', function() {
                vm.list();
            });

            vm.list();


      /**
       * @memberof bdpBookmark
       * @method getToolsWidth
       * @name getToolsWidth
       * @description calculate holder element width.
       * @returns {Object}
       */
            vm.getToolsWidth = function() {
        // tablet view
                if ($window.outerWidth < 871 && $window.outerWidth > 650) {
                    return {width: this.details.widthButton + '%', borderRight: '0px'};
                }
        // mobile view
                else if ($window.outerWidth < 651) {
                    if (this.details.widthButton > 0) {
                        return {width: this.details.widthButton + '%'};
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }
            };


      /**
       * @memberof bdpBookmark
       * @method getButtonsVisible
       * @name getButtonsVisible
       * @description Switch element to display inline.
       * @returns {Object}
       */
            vm.getButtonsVisible = function() {
        // mobile view
                if ($window.outerWidth < 651) {
                    if (this.details.widthButton >= 35) {
                        return {display: 'inline', 'margin-left': '-0.8rem'};
                    }
                }
            };
        }
    }
})();
