(function () {
    'use strict';

    angular
        .module('truelocal')

        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name mapWindowMobile
         * @description Directive display business details on mobile view when marker from map is
         *     touched.
         *
         * @param {service}   $scope                    Angular scope service
         * @param {service}   $timeout                  Angular window.setTimeout service
         *     settings
         * @param {service}   $location                  Angular window.location service wrapper
         * @param {factory}   uriService                 uriService provide uriEncode and uriDecode
         * @param {service}   sharedService              Provides methods that handles initialization values for google maps.
         *     methods that are responsible to unify different uri
         *
         */
        .directive('mapWindowMobile', mapWindowMobile)
        .directive('tlExpandMapInfo', TlExpandMapInfo);

    /** @ngInject */
    function mapWindowMobile() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/searchmap/mapwindowmobile.html',
            scope: {
                listing: '=',
            },
            controller: ['$scope', '$timeout', 'sharedService', '$location', 'uriService',
                         BsMapWindowMobileController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function BsMapWindowMobileController($scope, $timeout, $location, uriService, sharedService) {
            var vm = this;
            vm.expanded = false;
            var searchGMap = sharedService.getSearchGMap();

            /**
             * @memberof mapWindowMobile
             * @name getClass
             * @method getClass
             * @description return the class
             */

            vm.getClass = function () {
                return (vm.expanded == true) ? searchGMap.classExpandedViewMobile : '';
            };

            /**
             * @memberof mapWindowMobile
             * @name expand
             * @method expand
             * @description expand on touch or swipe up
             */
            vm.expand = function () {
                var _infoBox = angular.element(document.querySelector('.marker-selected-info'));
                var _heightOfInfoBox = 'height:' + _infoBox[0].clientHeight
                                       + 'px !important; overflow: visible;';
                _infoBox.attr('style', _heightOfInfoBox);
                angular.element(document.querySelector('.inner-holder')).addClass('expanding');
                vm.expanded = true;
                return vm.expanded;
            };

            /**
             * @memberof mapWindowMobile
             * @name getCategoryUrl
             * @method getCategoryUrl
             * @description return category url
             * @param {object} listing
             * @param {int} index
             */
            vm.categoriesUrls = [];
            vm.getCategoryUrl = function (listing, index) {
                if (angular.isUndefined(vm.categoriesUrls[index])) {
                    var _url = $location.url();
                    var _finalUrl;
                    var _location = '';
                    var _params, listingCategory;

                    if (_url.indexOf('search') != -1) {
                        _params = _url.split('search');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            if (angular.isUndefined(_params[2])) {
                                _location = 'australia';
                            }
                            listingCategory = listing.category.toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                _finalUrl =
                                    _url.replace(_params[1], uriService.uriEncode(listingCategory));
                                if (_location != '') {
                                    _finalUrl = _finalUrl + '/' + _location;
                                }
                            } else {
                                _finalUrl = _url + ((_location != '') ? '/' + _location : '');
                            }
                        }
                    } else {
                        _params = _url.split('find');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            listingCategory = vm.listing.category.toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                _finalUrl =
                                    _url.replace(_params[1], uriService.uriEncode(listingCategory));
                            } else {
                                _finalUrl = _url;
                            }
                        }
                    }
                    vm.categoriesUrls[index] = _finalUrl;
                }

                return vm.categoriesUrls[index];
            };

            vm.hasDeals = function (listing) {
                if (listing.statistics.deals && listing.statistics.deals > 0) {
                    return true;
                } else {
                    return false;
                }
            };

            $scope.$watch('vm.listing', function () {
                vm.expanded = false;
                return vm.expanded;
            });
        }
    }

    function TlExpandMapInfo() {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                element.on('click', function (e) {
                    element.parents('div.marker-selected-info').addClass('expandActive');
                });
            },
        };
    }
})();
