(function() {
    'use strict';

    angular
  .module('truelocal')
  .service('validatorService', validatorService);


  /** @ngInject */
  /**
   * @memberof truelocal
   * @ngdoc service
   * @name validatorService
   * @description validatorService provides methods that validates user inputs
   * @return {Object} returns the service
   */
    function validatorService() {
        var service = {
            containsHtml: containsHtml,
        };

        /**
         * Checks whether a given input string contains any HTML tags.
         * @memberof validatorService
         * @method
         * @name containsHtml
         * @param {string} input - The string input that needs to be validated.
         * @returns {boolean} Returns true if the input contains HTML tags, false otherwise.
         * @example
         * const input = '&lt;a&gt; This contains html &lt;/a&gt; ';
         * const hasHtmlTags = containsHtml(input);
         * console.log(hasHtmlTags); // Output: true
         */
        function containsHtml(input) {
            var decodedInput = decodeHtmlEntities(input);
            return /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g.test(decodedInput);
        }

        /**
         * Decodes HTML entities in the input string.
         * @memberof validatorService
         * @method
         * @name decodeHtmlEntities
         * @param {string} input - The string containing HTML entities to be decoded.
         * @returns {string} The decoded string with HTML entities replaced by their corresponding characters.
         */
        function decodeHtmlEntities(input) {
            if (input === null || input === undefined) {
                return input;
            }

            return input.replace(/&lt;/g, '<')
                        .replace(/&gt;/g, '>')
                        .replace(/&amp;/g, '&')
                        .replace(/&quot;/g, '"')
                        .replace(/&apos;/g, "'");
        }

        return service;
    }
})();
