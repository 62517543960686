(function() {
    'use strict';

    angular
        .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetails
     * @description Directive display business details on bdp page. It contain business name with description,
     * contact details (links to website, send e-mail, phone, fax),  ABN/ACN (Australian business/company number)
     * and display also sponsored listing if available in category. If description is to long, then
     * "Read more/Show less" button is available to truncate text.
     *
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {service}   $window                   angular window wrapper
     *
     */
    .directive('bdpDetails', bdpDetails)

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name ngReadMoreBlock
     * @description Directive handle truncation of text that doesn't fit into element, putting "Read more" ellipsis
     * at the end, to display truncated text.
     */
    .directive('ngReadMoreBlock', ngReadMoreBlockHandler);

/** @ngInject */
    function ngReadMoreBlockHandler($compile, DTM, $document) { /* $window*/
        return {
            restrict: 'A',
            link: function(scope, elem) {
                scope.$watch(function(scope) {
                    return (
                            angular.isDefined(scope) &&
                            angular.isDefined(scope.vm) &&
                            angular.isDefined(scope.vm.listing) &&
                            angular.isDefined(scope.vm.listing.description)
                        ) ? scope.vm.listing.description : null;
                },
                    function(_old, _new) {
                        scope.vm.executed = false;
                        scope.vm.baseText = _new;
                        scope.vm.formatedBlock = scope.vm.getDescription();
                        scope.vm.textBlock = scope.vm.getDescription().split(' ');
                        scope.vm.manevra = scope.vm.getDescription().split(' ');
                        scope.vm.readed = false;
                        scope.vm.restText = [];
                        scope.vm.restFormatedText = '';

                        var itp = '<span class=\'text-wrap\'>' + scope.vm.formatedBlock + '</span>';
                        var linkFnR = $compile(itp);
                        var contentR = linkFnR(scope);
                        angular.element(elem).empty();
                        angular.element(elem).append(contentR);


                        scope.vm.readMoreShow = function() {
                            scope.vm.readed = true;

                            DTM.trackAnalyticOnClick('read more description');
                            return scope.vm.readed;
                        };

                        scope.vm.readMoreHide = function() {
                            scope.vm.readed = false;
                            $document.scrollTop(390);
                            return scope.vm.readed;
                        };


                        setTimeout(angular.bind(this, function(_s, _e) {
                            if (_s.vm.executed == false) {
                                var _wrappedElement = angular.element(_e);
                                if (_wrappedElement[0].clientHeight > _s.heightLimit) {
                                    scope.vm.textBlock.push(' &+& ');
                                    do {
                                        _s.vm.restText.push(_s.vm.manevra.splice(_s.vm.textBlock.length - 2, 1));
                                        _s.vm.textBlock.splice(_s.vm.textBlock.length - 2, 1);
                                        _s.vm.formatedBlock = _s.vm.textBlock.join(' ');
                                        var itp = '<span>' + _s.vm.formatedBlock + '</span>' + '<a href="#"  ng-show="vm.readed==false" ng-click="vm.readMoreShow()" class="link-color">...read more</a>';
                                        var linkFnR = $compile(itp);
                                        var contentR = linkFnR(_s);
                                        _wrappedElement.html(contentR);
                                    } while (_wrappedElement[0].clientHeight > 123);
                                    var _indexReadMore = _s.vm.formatedBlock.indexOf(' &+& ');


                                    _s.vm.formatedBlock = _s.vm.formatedBlock.substr(0, _indexReadMore);
                                    _s.vm.restFormatedText = _s.vm.restText.reverse().join(' ');
                                    _s.vm.formatedBlock = _s.vm.formatedBlock + '';

                                    var tp = '<span>' + _s.vm.formatedBlock + '</span><span ng-show="vm.readed==true" class="cssSlideUp"> ' + _s.vm.restFormatedText + '</span> <a href="#"  ng-show="vm.readed==false" ng-click="vm.readMoreShow()" class="link-color">...read more</a><a href="#" class="details-read-more"  ng-if="vm.readed==true" ng-click="vm.readMoreHide()"> Show less</a>';

                                    var linkFn = $compile(tp);
                                    var content = linkFn(_s);
                                    _wrappedElement.empty();
                                    _wrappedElement.append(content);
                                    _s.vm.executed = true;
                                }
                            }
                        }, scope, elem), 20);
                    }
                );
            },
            scope: {
                vm: '=',
                heightLimit: '=',
            },
        };
    }

    /** @ngInject */
    function bdpDetails() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetails/bdpdetails.html',
            scope: {
                listing: '=',
                similar: '='
            },

            controller: BdpDetailsController,
            controllerAs: 'vm',
            link: BdpDetailsLink,
            bindToController: true,
        };

        return directive;


        /**
         * @memberof bdpDetails
         * @method bdpDetails_BdpDetailsController
         * @name bdpDetails_BdpDetailsController
         * @description bdpDetails directive controller.
         *
         * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
         * @param {service}   businessService           Factory service that handle business-related functions such as sending email
         *
         *
         * @example
         * <bdp-details similar="[object]" listing="[object]"></bdp-details>
         *
         */

         /** @ngInject */
        function BdpDetailsController(platformService, $window, apiConfig, DTM, env, businessService) {
            var vm = this;
            vm.env = env;

            this.addBussLive = env.addBussLive;
            this.hamburgerMenuActive = 0;
            this.isMobile = platformService.isMobile();
            if (angular.isDefined(this.listing) && angular.isDefined(this.listing.description)) {
                this.textBlock = this.listing.description.split(' ');
            }

            /**
             * @memberof bdpDetails
             * @method getDescription
             * @name getDescription
             * @description Get business description from the listing object and replaces new lines `\n`, `\r` with <br/> html tag
             *
             */

            this.getDescription = function() {
                this.baseText = '';
                if (angular.isDefined(this.listing) && angular.isDefined(this.listing.description)) {
                    this.baseText = this.listing.description;
                }
                if (angular.isDefined(this.baseText) && this.baseText != '') {
                    this.baseText = (this.baseText).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br/>' + '$2');
                }
                return this.baseText;
            };

            this.formatedBlock = this.getDescription();

            /**
             * @memberof bdpDetails
             * @method hamburgerSelect
             * @name hamburgerSelect
             * @description This method is for changing the current active hamburger menu item (mobile)
             *
             */

            this.hamburgerSelect = function(index) {
                if (angular.isUndefined(index)) {
                    return;
                }
                if (this.hamburgerMenuActive == index) this.hamburgerMenuActive = -1;
                else this.hamburgerMenuActive = 0;
            };


            /**
             * @memberof bdpDetails
             * @method isVerified
             * @name isVerified
             * @description Accessor method to check if the business is verified and to put the check icon.
             *
             */

            this.isVerified = function(listing) {
                if (angular.isDefined(listing)) {
                    this.listing = listing;
                }
                if (angular.isUndefined(this.listing)) {
                    return 'no-icon';
                } else if (angular.isUndefined(this.listing.managed)) {
                    return 'no-icon';
                } else if (this.listing.managed == false) {
                    return 'no-icon';
                } else {
                    return '';
                }
            };


            this.isMobileScreen = function() {
                return platformService.isMobileScreen();
            };

            /**
             * @memberof bdpDetails
             * @method emailABusiness
             * @name emailABusiness
             * @description Open the modal to send an e-mail to the business owner
             *
             */
            this.emailABusiness = function($event) {
                businessService.emailABusiness($event, 'BDP', vm.listing);
            };

            /**
             * @memberof bdpDetails
             * @method getTabletClass
             * @name getTabletClass
             * @description This method is to determine if tablet class to be shown.
             *
             */

            this.getTabletClass = function() {
                if (platformService.getWidth() > 480 && platformService.getWidth() < 992) {
                    return 'contact-column';
                } else {
                    return '';
                }
            };

            /**
             * @memberof bdpDetails
             * @method showClaimBusiness
             * @name showClaimBusiness
             * @description Show claim business link if it is not manged already by user or if it is not in pending state.
             * @returns {Boolean} showClaimBusiness
             */
            vm.showClaimBusiness = function() {
                if (!angular.isUndefined(vm.listing.bussinessDetails)) {
                    if (vm.listing.bussinessDetails.managed == false) {
                  // if business is not in pending managed
                        if (apiConfig.userOwnsBusiness() != -11) {
                            return true;
                        }
                    }
                }
                return false;
            };

            /**
             * @memberof bdpDetails
             * @method trackingLink
             * @name trackingLink
             * @description trackingLink.
             * @params {String} name
             */
            vm.trackingLink = function(name) {
                if(angular.isUndefined(name) || name === '') return;
                DTM.trackAnalyticOnClick(name);
                return true;
            };

            /**
             * @memberof bdpDetails
             * @method openLink
             * @name openLink
             * @description OPen link in the same tab
             * @returns link _self
             */
            vm.openLink = function($link) {
                $window.open($link, '_self');
            };
            
            /**
             * @memberof bdpDetails
             * @method openClaim
             * @name openClaim
             * @description Open claim page of bcr
             * @returns link _self
             */
            vm.openClaim = function(link) {
                if(_.has(apiConfig.userData, 'data.data')) {
                    $window.open(link, '_self');
                    return;
                }
                $window.open('/login' + link, '_self');
            };
            vm.showRequestBooking = false;

            if(vm.listing.vcitaId && vm.listing.vcitaServiceEnabled){

                if (angular.element($window)[0].outerWidth > 767) {
                    vm.showRequestBooking = true;
                }

                (function(d, s, id){
                    var js, fjs = d.getElementsByTagName(s)[0],
                        p = 'https://',
                        r = Math.floor(new Date().getTime() / 1000000);
                    if (d.getElementById(id)) {return;}
                    js = d.createElement(s); js.id = id;
                    js.src = p + "widgets.vcdnita.com/assets/livesite.js?" + r;
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'livesite-jssdk'));

                $window.liveSiteAsyncInit = function() {

                    vm.vCitaId = vm.listing.vcitaId;

                    if(vm.env.dms){
                        vm.dmsBranding = vm.env.dms.branding;
                    } else {
                        vm.dmsBranding = '825399';
                    }

                    LiveSite.init({
                        id : vm.vCitaId,
                        activeEngage: false,
                        branding: vm.dmsBranding
                    });

                };
            }
        }
    }

    /** @ngInject */

    /**
     * @memberof bdpDetails
     * @method bdpDetails_BdpDetailsLink
     * @name bdpDetails_BdpDetailsLink
     * @description bdpDetails directive link function.
     *
     * @param {service} scope get the directive scope
     */

    function BdpDetailsLink(scope) {
        var vm = scope.vm;
        vm._isPaid = true;
        vm._hasTol = false;

        /**
         * @memberof bdpDetails
         * @method hasTol
         * @name hasTol
         * @description This method checks and return if the TOL should be displayed on current business page.
         *
         * @param {object}  listing   Current listing data
         * @param {object}  similar   Similar listings data
         *
         * @return {bool}
         */

        vm.hasTol = function(listing, similar) {
            if (angular.isDefined(listing)) {
                vm.listing = listing;
            }
            if (angular.isDefined(similar)) {
                vm.similar = similar;
            }
            if (angular.isDefined(vm.listing)) {
                if (angular.isDefined(vm.listing.paid)) {
                    vm._isPaid = vm.listing.paid;
                }
            }
            if (vm._isPaid == false) {
                if (angular.isUndefined(vm.similar) || angular.isUndefined(vm.similar.data) || angular.isUndefined(vm.similar.data.inventories)) {
                    vm._hasTol = false;
                } else if (vm.similar.data.inventories.length != 0) {
                    var tolCount = 0;
                    angular.forEach(vm.similar.data.inventories, function(inventory) {
                        if (inventory.type == 'TOL') {
                            if (inventory.size > 0) {
                                tolCount = tolCount + 1;
                            }
                        }
                    });
                    if (tolCount > 0) {
                        vm._hasTol = true;
                    } else {
                        vm._hasTol = false;
                    }
                }
            }
            return vm._hasTol;
        };

        /**
         * @memberof bdpDetails
         * @method hasCopyPoints
         * @name hasCopyPoints
         * @description This method checks and returns if the current listing has copypoint
         *
         * @return {bool}
         */

        vm.hasCopyPoints = function() {
            if (angular.isDefined(vm.listing) && (
                    angular.isDefined(vm.listing.copypoints) ||
                    angular.isDefined(vm.listing.serviceLocations) ||
                    angular.isDefined(vm.listing.documents) ||
                    (angular.isDefined(vm.listing.brands) && vm.listing.brands.length > 0)
                )) {
                return true;
            } else {
                return false;
            }
        };

        /**
         * @memberof bdpDetails
         * @method hasNameAlias
         * @name hasNameAlias
         * @description This method checks and returns if the current listing has name alias
         *
         * @return {bool}
         */

        vm.hasNameAlias = function() {
            if (angular.isUndefined(vm.listing) ||
                angular.isUndefined(vm.listing.nameAlias) || vm.listing.nameAlias === '') {
                return false;
            }
            return true;
        };

        /**
         * @memberof bdpDetails
         * @method hasFeature
         * @name hasFeature
         * @description This method checks and returns if the current listing has feature review
         *
         * @return {bool}
         */

        vm.hasFeature = function() {
            if (angular.isUndefined(vm.listing) ||
                angular.isUndefined(vm.listing.featuredReview) || !vm.listing.featuredReview) {
                return false;
            }
            return true;
        };

        /**
         * @memberof bdpDetails
         * @method tolAreaIsFree
         * @name tolAreaIsFree
         * @description This method checks if the TOL area is free or not
         *    if true that means that no tol is present in current page
         *
         * @return {bool}
         */

        vm.tolAreaStatus = true;
        vm.tolAreaIsFree = function() {
            if (angular.isUndefined(vm.listing)) {
                vm.tolAreaStatus = true;
            } else if (vm.hasTol() == true || vm.hasFeature() == true) {
                vm.tolAreaStatus = false;
            } else {
                vm.tolAreaStatus = true;
            }
            return vm.tolAreaStatus;
        };

        vm._contactBlockPosition = 1;
        /**
         * @memberof bdpDetails
         * @method contactBlockPosition
         * @name contactBlockPosition
         * @description Determine where the contact block should be positioned
         *
         * @return {bool}
         */

        vm.contactBlockPosition = function() {
            if (angular.isDefined(vm.listing.description)) {
                var _tolArea = vm.tolAreaIsFree();
                vm._contactBlockPosition = 1;
                if (vm.listing.description.length == 0 && _tolArea == false && vm.hasCopyPoints() == false) {
                    vm._contactBlockPosition = 3;
                } else if (vm.listing.description.length == 0 && _tolArea == false && vm.hasCopyPoints() == true) {
                    vm._contactBlockPosition = 4;
                } else if (vm.listing.description.length == 0 && vm.hasCopyPoints() == true) {
                    vm._contactBlockPosition = 4;
                } else if (vm.listing.paid == false && _tolArea == true && vm.listing.description.length == 0) {
                    vm._contactBlockPosition = 4;
                } else if (vm.listing.paid == false && _tolArea == true && vm.hasCopyPoints() == false) {
                    vm._contactBlockPosition = 2;
                } else if (vm.listing.paid == true && _tolArea == true && vm.hasCopyPoints() == false) {
                    vm._contactBlockPosition = 2;
                }
            }
            return vm._contactBlockPosition;
        };

        vm.isContactEmail = vm.listing.contacts.contact.some(function(contact) {
            return (contact.type === 'email');
        });
    }
})();
