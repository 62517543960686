(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name searchResult
     * @description Directive is main holder for displaying of results.
     *
     * @example
     * <search-result is-changed="[boolean]"></search-result>
     */
    .directive('searchResult', searchResult);

    function searchResult(searchConfigManager) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: '/app/components/searchpage/searchitems/searchresult.html',
            scope: {
                isChanged: '=',
            },
            controller: BsSearchResultController,
            controllerAs: 'vm',
            bindToController: true,
        };

        function BsSearchResultController() {
      /**
       * @memberof searchResult
       * @method getContent
       * @name getContent
       * @description Prepare search result report to display to user.
       * @returns {String} searchReport
       */
            this.getContent = function() {
                var states = [ 'nsw', 'sa', 'vic', 'tas', 'qld', 'act', 'wa', 'nt'];
                var keyword = searchConfigManager.getProperty('keyword', 'category').capitalizeSentence();
                keyword = keyword ? '<b>' + keyword + '</b>' : '<b>results</b>';
                var location = searchConfigManager.getProperty('location', 'suburb', 'region', 'state');
                var type = searchConfigManager.getProperty('type');
                var suburb = searchConfigManager.getProperty('suburb');
                var state = searchConfigManager.getProperty('state');
                var region = searchConfigManager.getProperty('region');

                if(type === 'browse') {
                    if(suburb) {
                        location = suburb.capitalizeSentence() + (state ? ' ' + state.toUpperCase().trim() : '');
                    }
                    else if (region) {
                        location = region.capitalizeSentence();
                    }
                    else if(state) {
                        location = state.toUpperCase().trim();
                    }
                } else {
                    var arrLoc = location.split(' ');
                    for(var i = 0; i < arrLoc.length; i++) {
                        var w = arrLoc[i];
                        if(states.includes(w)) {
                            arrLoc[i] = w.toUpperCase().trim();
                        } else {
                            arrLoc[i] = w.capitalizeSentence();
                        }
                    }
                    location = arrLoc.join(' ');
                }

                location = location ? ' found in <b>' + location + '</b>' : ' found in <b>Australia</b>';

                var _createdTitle = this.isChanged.size + '&nbsp;&nbsp;' + keyword + location;
                return _createdTitle;
            };

            this.content = this.getContent();
        }
    }
})();
