(function () {
  "use strict";

  angular
    .module("truelocal")
    /**
     * @memberof truelocal
     * @ngdoc service
     * @name grecaptchaService
     * @description grecaptchaService provides method for handling Google Recaptcha
     */
    .service("grecaptchaService", [
      "env",
      "$q",
      function grecaptchaService(env, $q) {
        var service = {
          getSiteKey: getSiteKey,
          getResponse: getResponse,
          reset: reset,
          renderCheckbox: renderCheckbox,
        };

        function getSiteKey(version) {
          if (version === "v2") {
            return env.google.recaptcha.v2;
          } else if (version === "v3") {
            return env.google.recaptcha.v3;
          } else {
            console.error("Unsupported reCaptcha version:", version);
          }
        }

        function getResponse(apiVersion) {
          if (apiVersion === "v2") {
            return grecaptcha.getResponse();
          } else if (apiVersion === "v3") {
            return getResponseV3("submit");
          } else {
            return $q.reject("Unsupported API version");
          }
        }

        function getResponseV3(actionName) {
          if (window.grecaptcha === undefined) {
            return $q.reject(
              "Recaptcha is not yet loaded. Please wait a moment."
            );
          }

          // Create a promise that resolves with the reCAPTCHA token
          return new Promise(function (resolve, reject) {
            grecaptcha.ready(function () {
              grecaptcha
                .execute(getSiteKey("v3"), { action: actionName })
                .then(resolve)
                .catch(reject);
            });
          });
        }

        function renderCheckbox() {
          var siteKey = getSiteKey("v2");

          grecaptcha.ready(function () {
            grecaptcha.render("recaptcha_checkbox", {
              sitekey: siteKey,
            });
          });
        }

        function reset() {
          grecaptcha.reset();
        }

        return service;
      },
    ]);
})();
