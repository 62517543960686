(function() {
    'use strict';

    angular
        .module('truelocal')
        .filter('removeSpecialCharacters', removeSpecialCharacters);

        /**
         * @name removeSpecialCharacters
         *
         * @description A custom AngularJS filter that removes occurrences of '<' and '>' characters from a string.
         *
         * @param {string} input - The input string to remove special characters from.
         * @returns {string} The input string with '<' and '>' characters removed.
         */
        function removeSpecialCharacters() {
            return function(input) {
                return input.replace(/[<>]/g, '');
            };
        }
})();