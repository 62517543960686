(function() {
    'use strict';

  angular.module('truelocal')
    .service('categoriesService', function($http, apiConfig, categoriesModel){
      function getCategories(options){
        var categoryList = [];
        var categoryReq = $http.get(apiConfig.generate('business-type', options), apiConfig.getHeaderByVersion(1))
        .then(function(response) {
            categoryList = (response.data && response.data.data && response.data.data.parentCategory || []);
            categoriesModel.setCategories(categoryList); //Set processed list in model to allow access from different controllers
            return categoryList;
        });

        return categoriesModel;   //Return the model to controller
      }

      function getPopularCategories() {
        var apiUrl = apiConfig.apiHost + 'home/popular-categories' + apiConfig.token();
        return $http.get(apiUrl)
          .then(function (response) {
            return response.data && response.data.data && response.data.data.popularCategories;
          })
          .catch(function (error) {
            console.error('Error fetching popular categories:', error);
            throw error;
          });
      }

      return {
        getCategoriesModel: getCategories,
        getCategories: getPopularCategories
      };
    });
})();
