(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name myMaxlength
     * @description Attribute directive enable trimming of element value text to maxLength attribute.
     */
    .directive('myMaxlength', ['$compile', '$log', function($compile, $log) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, elem, attrs, ctrl) {
                attrs.$set('ngTrim', 'false');
                var maxlength = parseInt(attrs.myMaxlength, 10);
                ctrl.$parsers.push(function(value) {
                    $log.debug('In parser function value = [' + value + '].');
                    if (value.length > maxlength) {
                        $log.debug('The value [' + value + '] is too long!');
                        value = value.substr(0, maxlength);
                        ctrl.$setViewValue(value);
                        ctrl.$render();
                        $log.debug('The value is now truncated as [' + value + '].');
                    }
                    return value;
                });
            },
        };
    }]);
})();
