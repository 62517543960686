(function() {
    'use strict';

    angular
        .module('truelocal')

        /**
         * @memberof truelocal
         * @ngdoc controller
         * @name BdpController
         * @description Business details page main controller. Responsible for distribution of
         *     business data elements that display specific areas.
         */
        .controller('BdpController', BdpController);

    /** @ngInject */

    function BdpController($window, $timeout, serviceListing, searchConfigManager, apiConfig,
                           platformService, arrowService, windowScroll, DTM, $scope, $rootScope,
                           $location, Seo, $stateParams, scrollDetectWithTimeout, headerService,
                           arrowConstants, appService, recentlyViewed, listing, listingModels,
                           $cookies, env, $document, $state, $filter) {

        var vm = this;
        vm.env = env;
        vm.listing = listing.data.data.listing[0] || {};

        //remove special characters
        if (vm.listing.name) {
            vm.listing.name = $filter('removeSpecialCharacters')(vm.listing.name);
        }

        //start set header
        headerService.showSearchForm = true;
        headerService.logoClass = '';
        headerService.dynamicShowHideSearchForm = false;

        //end set header
        searchConfigManager.resetAllProperty();

        //adding header back
        angular.element(document.body).removeClass("services-page-body");

        vm.appService = appService;
        vm.apidata = {};
        vm.metadata = {};
        vm.tagsdata = {};
        vm.similarListings = {};
        vm.nearbyOtherListings = {};
        vm.classLoad = 'invisible';
        vm.notfound = false;
        vm.geminiArrowActive = false;
        vm.compRunning = function() {
            return apiConfig.compToggle();
        };
        vm.geminiArrowActive = false;
        vm.isMobile = platformService.isMobile();
        vm.enteredComp = false; //scope CompRunning
        vm.seoImageUrl;

        vm.isLoaded = function() {
            return vm.classLoad;
        };

        /**
         * @memberof BdpController
         * @method bodyTheme
         * @name bodyTheme
         * @description Finding default body theme if listing not found.
         * @return {String} default theme if not found.
         */
        vm.bodyTheme = function() {
            return vm.notfound ? 'body_theme_grey' : '';
        };

        /**
         * @memberof BdpController
         * @method getErrorClass
         * @name getErrorClass
         * @description Returning error class name..
         * @return {String} theme if error.
         */
        vm.getErrorClass = function() {
            return vm.notfound ? 'extra-visible' : '';
        };

        /**
         * @memberof BdpController
         * @method validAddress
         * @name validAddress
         * @description Checking is current address valid.
         * @return {Boolean}
         */
        vm.validAddress = function() {
            var address = vm.apidata.addresses.address[0];

            if (angular.isUndefined(address)) return false;

            var isLatLongDefined = angular.isDefined(address.latitude) || angular.isDefined(address.longitude);

            if (!isLatLongDefined || (address.latitude === '' && address.longitude === '')) {
                return angular.isDefined(address.suburb);
            }

            return isLatLongDefined;
        };

        vm.geminiAdClass = function() {
            return 'gemini-ad-' + arrowConstants.geminiSectionCode;
        };

        var seoUrl = $stateParams.path;
        var listId = seoUrl;

        /**
         * @memberof BdpController
         * @method _chainContinueFetch
         * @name _chainContinueFetch
         * @description After the listing detail endpoint is executed add in chain the data for the
         *     similar section and other nearby.
         */
        vm.showSimilar = function() {
            var isNotPaid = angular.isDefined(vm.apidata.paid) && !vm.apidata.paid;
            return isNotPaid && (
                angular.isDefined(vm.similarListings) &&
                angular.isDefined(vm.similarListings.data) &&
                angular.isDefined(vm.similarListings.data.listing) &&
                vm.similarListings.data.listing.length > 0
            );
        };

        var _chainContinueFetch = function() {
            if (vm.validAddress()) {
                if (vm.apidata.paid !== true) {
                    serviceListing.getSimilarListings(vm.apidata.id, vm.similarListings).then(function() {
                        var listingData = [];
                        if (vm.similarListings && vm.similarListings.data && vm.similarListings.data.listing) {
                            listingData = vm.similarListings.data.listing;
                        }
                        angular.forEach(listingData, function(listing, key) {
                            if (listing.id == vm.apidata.id) {
                                vm.similarListings.data.listing.splice(key, 1);
                            }
                        });

                    });
                }
                //after the listing detail endpoint is executed add in chain the data for the similar section and other nearby
                serviceListing.getOtherNearbyListings(vm.apidata, vm.nearbyOtherListings).then(function() {
                    angular.forEach(vm.nearbyOtherListings.data.listing, function(listing, key) {
                        if (listing.id == vm.apidata.id) {
                            vm.nearbyOtherListings.data.listing.splice(key, 1);
                        }
                    });
                    angular.element(window).triggerHandler('similarOtherRenderComplete');
                });
            }
        };

        $rootScope.defer = 0;

        /**
         * @memberof BdpController
         * @eventType emit
         * @name _chainContinueFetch
         * @description RefreshListing event, defer is because  sometimes we get the refreshListing
         *     too many times too soon (ex. authentification)
         */
        angular.element(window).on('refreshListing', function() {
            //
            if ($rootScope.defer === 0) {
                getListing();
                $rootScope.defer = 1;
                $timeout(function() {
                    $rootScope.defer = 0;
                });
            }

        });


        getListing();

        function processListingData() {

            if (DTM.hasOneTrustScript()) {
                DTM.fireEventAtPageLoad($state.current.name);
            } else {
                DTM.trackPage();
            }

            recentlyViewed.add(vm.apidata.name, vm.apidata.canonicalUrl);
            angular.element($window).triggerHandler("clear-search-fields");
            Seo.pageTitle.set(vm.metadata.title);
            Seo.description.set(vm.metadata.description);

            if (vm._has('apidata.id')) {
                appService.listingId.set(vm.apidata.id);
            }

            // Address.setAddress(vm.apidata);
            //check if there is diference between seoUrl param and seoUrl from the response and
            // redirect if seoUrl not found in listing or in serviceLocations
            if (seoUrl != vm.apidata.seoUrl) {
                //check if not serviced
                if (angular.isDefined(vm.apidata.serviceLocations) && angular.isDefined(
                        vm.apidata.serviceLocations.serviceLocation)) {
                    if (vm.apidata.serviceLocations.serviceLocation.length > 0 && !/mock=true/.test(
                            $location.absUrl())) {
                        (function() {
                            var redirect = true;
                            angular.forEach(vm.apidata.serviceLocations.serviceLocation,
                                function(location) {
                                    if (location.seoUrl == seoUrl) {
                                        redirect = false;
                                    }
                                });
                            if (redirect) {
                                $location.path($location.path().replace(seoUrl, vm.apidata.seoUrl));
                            }
                        })();
                    }
                }
            }

            vm.classLoad = '';
            vm.apidata.businessImages = {};
            vm.apidata.businessImagesBackup = {};
            vm.apidata.businessImages.image = [];
            vm.apidata.businessImages.size =
                ((vm.apidata.images && vm.apidata.images.size) ? vm.apidata.images.size : 0) +
                ((vm.apidata.userImages && vm.apidata.userImages.size) ? vm.apidata.userImages.size :
                    0);

            var _paidListOfFour = [];

            apiConfig.setListing(vm.apidata);

            if (vm.apidata.paid && vm.apidata.images && vm.apidata.images.image &&
                vm.apidata.images.image.length) {
                var _length = (vm.apidata.images.image.length > 4) ? 4 :
                    vm.apidata.images.image.length;
                for (var i = 0, l = _length; i < l; i++) {
                    _paidListOfFour.push(vm.apidata.images.image.shift());
                }
            }

            if (angular.isUndefined(vm.apidata.featureImage) ||
                angular.isUndefined(vm.apidata.featureImage.urls) ||
                angular.isUndefined(vm.apidata.featureImage.urls.small)) {
                vm.seoImageUrl = 'https://media.truelocal.com.au/docs/images/logo.png';
            } else {
                vm.seoImageUrl = vm.apidata.featureImage.urls.small;
            }

            if (vm.apidata.images && vm.apidata.images.image) { // checking business images
                for (var imageIndex = 0, imagesListSize = vm.apidata.images.image.length; imageIndex < imagesListSize; imageIndex++) {
                    vm.apidata.businessImages.image.push(vm.apidata.images.image[imageIndex]); // applying
                    // images
                    // to
                    // new
                    // Array
                }
            }

            if (vm.apidata.userImages && vm.apidata.userImages.image) { // checking user "public" images
                for (var userImageIndex = 0, userImagesListSize = vm.apidata.userImages.image.length; userImageIndex < userImagesListSize; userImageIndex++) {
                    vm.apidata.businessImages.image.push(
                        vm.apidata.userImages.image[userImageIndex]); // applying images to new
                    // Array
                }
            }

            if (vm.apidata.businessImages && vm.apidata.businessImages.length) {
                vm.apidata.businessImages.image.sort(function(a, b) { // sorting images by date
                    return b.dateCreated - a.dateCreated;
                });
            }

            //first 4 images for paid business in order that are comming are the main 4 we are
            // showing, all the other users/business are showing by the upload date.
            if (_paidListOfFour && _paidListOfFour.length) {
                for (var paidListSize = _paidListOfFour.length - 1; paidListSize >= 0; paidListSize--) {
                    vm.apidata.businessImages.image.splice(0, 0, _paidListOfFour[paidListSize]);
                }
            }

            if (vm.apidata._has('videos.video[0]')) {
                vm.apidata.videos.video.forEach(function(video) {
                    video.type = 'video';
                    video.urls = {};
                    var url = '/app/assets/img/bdp-play-video-placeholder.jpg';
                    if (vm.apidata._has('coverImage.url')) {
                        url = vm.apidata.coverImage.url;
                    }
                    video.urls.large = url;
                    video.urls.medium = url;
                    video.urls.original = url;
                    video.urls.small = url;
                    video.urls.thumbnail = url;
                    video.urls.xsmall = url;
                    if (vm.apidata._has('businessImages.image')) {
                        vm.apidata.businessImages.image.unshift(video);
                        //move the first image to the beginning of the array
                        var done = false;
                        vm.apidata.businessImages.image.forEach(function(image, key) {
                            if (!image._has('type') && !done) {
                                done = true;
                                vm.apidata.businessImages.image.splice(0, 0,
                                    vm.apidata.businessImages.image.splice(
                                        key, 1)[0]);
                            }
                        });
                    }
                });
            }

            angular.copy(vm.apidata.businessImages, vm.apidata.businessImagesBackup);

            if (platformService.getWidth() > 767) {
                arrowService.initBdp(vm.apidata);
            } else if (vm.apidata.paid === false) {
                vm.geminiArrowActive = true;
                arrowService.initGemini();
            }

            setTimeout(function() { // Need to use timeout so it initialises once HTML has loaded
                windowScroll.onBdpScroll(); // Trigger BDP scrolling events
            });

            // for mobile screens, so this is main since we have only option to load more,
            // and API provides url for next block predefined with limit and offset
            // this approach covers checks on every call related to reviews
            if (platformService.isMobile()) {
                apiConfig.updateListReviewDefinition({
                    sort: 'date',
                    order: 'descending',
                    'offset': 5,
                    limit: '5'
                });

                if (vm.apidata.bussinessDetails.reviewList &&
                    vm.apidata.bussinessDetails.reviewList.length > 5) { // checking if list exists and is it ?lt 5
                    vm.apidata.bussinessDetails.reviewList.splice(5,
                        vm.apidata.bussinessDetails.reviewList.length -
                        1); // cutting the current list
                }

                var _generatedNextUrl = "/listings/" + vm.apidata.id +
                    "/reviews?sort=date&order=descending&offset=5&limit=5";
                if (vm.apidata.bussinessDetails.reviewsNext ||
                    vm.apidata.bussinessDetails.reviewsData.size > 5) {
                    vm.apidata.bussinessDetails.reviewsNext = _generatedNextUrl;
                    // this is the case when reviewList is less than 10 for initial
                    // so we are checking if it is over 5 (now we don't have reviewsNext link
                    // and we have to build it)
                }
            }

            _chainContinueFetch();
            angular.element(window).triggerHandler('renderComplete');//This is to make sure that anchoring function is bound to renderComplete
            return vm.classLoad;
        }

        angular.element(document.querySelector('body')).removeClass('hidden-scroll');

        /**
         * @memberof BdpController
         * @method getListing
         * @name getListing
         * @description Collecting bussines data from API and fill vm.apidata object.
         */
        function getListing() {
            angular.element($window).triggerHandler("url-changed");

            if (listing && angular.isDefined(listing.data.data.listing)) {
                vm.apidata = listing.data.data.listing[0];
                vm.metadata = listing.data.meta;
                vm.metadata.title = $filter('removeSpecialCharacters')(listing.data.meta.title)
                apiConfig.setListing(vm.apidata);
                vm.apidata.bussinessDetails = listingModels.businessDetailHeader(listing.data.data.listing[0]);
                vm.apidata.bussinessJSON = angular.toJson(vm.apidata.bussinessDetails, true);
                processListingData();
            } else {
                serviceListing.getListing(listId, vm.apidata, vm.metadata, vm.notfound)
                    .then(function() {
                        processListingData();
                    })
                    .catch(function() {
                        angular.element($window).triggerHandler("clear-search-fields");
                        vm.notfound = true;
                        return vm.notfound;
                    });
            }
        }

        /**
         * @memberof BdpController
         * @eventType emit
         * @name user-unauthenticated
         * @description User-unauthenticated event watcher.
         */
        angular.element(window)
            .on('user-unauthenticated', function() {
                if (angular.isDefined(vm.apidata.businessImages) && angular.isDefined(vm.apidata.businessImagesBackup)) {
                    angular.copy(vm.apidata.businessImagesBackup, vm.apidata.businessImages);
                }

                if (angular.isDefined(vm.apidata.bussinessDetails) && vm.apidata.bussinessDetails) {
                    if (angular.isDefined(vm.apidata.bussinessDetails.reviewsData) && vm.apidata.bussinessDetails.reviewsData) {
                        angular.extend(vm.apidata.bussinessDetails, {
                            alreadyAddedReview: null,
                            addedReviewThankYouMessage: null,
                            userReview: null
                        });
                    }

                    if (angular.isDefined(vm.apidata.bussinessDetails.featuredReview) && vm.apidata.bussinessDetails.featuredReview) {
                        angular.extend(vm.apidata.bussinessDetails.featuredReview, {
                            reportingReview: null,
                            alreadyReported: null,
                            startAddingComment: null,
                            expandedDropdownState: null,
                            addedCommentThankYouMessage: null,
                            userCommentData: null,
                            isMine: false,
                            reported: false
                        });

                        if (vm.apidata.bussinessDetails.featuredReview.comments && vm.apidata.bussinessDetails.featuredReview.comments.length) {
                            for (var featuredCommentIndex = 0, featuredCommentsLength = vm.apidata.bussinessDetails.featuredReview.comments.length; featuredCommentIndex < featuredCommentsLength; featuredCommentIndex++) {
                                angular.extend(vm.apidata.bussinessDetails.featuredReview.comments[featuredCommentIndex], {
                                    reportingReview: false,
                                    expandedDropdownStateSmall: false,
                                    isMine: false,
                                    reported: false
                                });
                            }
                        }
                    }

                    if (angular.isDefined(vm.apidata.bussinessDetails.reviewList) && vm.apidata.bussinessDetails.reviewList) {
                        for (var reviewIndex = 0, reviewsLength = vm.apidata.bussinessDetails.reviewList.length; reviewIndex < reviewsLength; reviewIndex++) {
                            angular.extend(vm.apidata.bussinessDetails.reviewList[reviewIndex], {
                                reportingReview: null,
                                alreadyReported: null,
                                startAddingComment: null,
                                expandedDropdownState: null,
                                addedCommentThankYouMessage: null,
                                userCommentData: null,
                                isMine: false,
                                reported: false
                            });

                            if (vm.apidata.bussinessDetails.reviewList[reviewIndex].comments && vm.apidata.bussinessDetails.reviewList[reviewIndex].comments.length) {
                                for (var reviewCommentIndex = 0, reviewCommentsLength = vm.apidata.bussinessDetails.reviewList[reviewIndex].comments.length; reviewCommentIndex < reviewCommentsLength; reviewCommentIndex++) {
                                    angular.extend(vm.apidata.bussinessDetails.reviewList[reviewIndex].comments[reviewCommentIndex], {
                                        reportingReview: false,
                                        expandedDropdownStateSmall: false,
                                        isMine: false,
                                        reported: false
                                    });
                                }
                            }
                        }
                    }
                }
            });

        vm.anchorState = {
            details: 'active',
            gallery: '',
            reviews: '',
            similar: ''
        };

        /**
        * On clicking of reviews from find/search page, BDP scrolls to reviews, but proper scrolling dependencies are not
        * applied in this scenario.
        */
        angular.element($window).on('sub-menu-changed', function(){
            setBreadcrumbsSticky();
            manageBDPScroll();
        });

        /**
         * @memberof BdpController
         * @eventType emit
         * @name scroll
         * @description Scrolling watcher, calculate according anchor point.
         */
        angular.element($window).bind("scroll", function() {
            var pageSplit = window.location.pathname.split('/');
            var pageType = pageSplit[1];
            if(['find', 'search', 'search-location'].indexOf(pageType) < 0 && pageType !='') {
              setBreadcrumbsSticky();
              if (scrollDetectWithTimeout.getValue() === true) {
                setTimeout(function() {
                  manageBDPScroll();
                  $rootScope.$apply();
                  scrollDetectWithTimeout.disable();
                });
              }
              else{
                manageBDPScroll();
                $rootScope.$apply();
              }
            }
        });

      /**
       * @memberof BdpController
       * @method manageBDPScroll
       * @name manageBDPScroll
       * @description Handles behaviour on BDP Scroll
       */
        function manageBDPScroll(){
            if(!angular.element(document.querySelector('body')).hasClass('sub-menu-fixed')){
              angular.element(document.querySelector('ul.anchors li:nth-child(1)')).addClass('active');
              return;
            }
            vm.getAnchorVisibility(getPosition(angular.element(document.querySelector('bdp-details div')))) ?
                angular.element(document.querySelector('ul.anchors li:nth-child(1)')).addClass('active'):
                angular.element(document.querySelector('ul.anchors li:nth-child(1)')).removeClass('active');
            vm.getAnchorVisibility(getPosition(angular.element(document.querySelector('bdp-gallery div')))) ?
                angular.element(document.querySelector('ul.anchors li:nth-child(2)')).addClass('active'):
                angular.element(document.querySelector('ul.anchors li:nth-child(2)')).removeClass('active');
            vm.getAnchorVisibility(getPosition(angular.element(document.querySelector('bdp-reviews')).parent())) ?
                angular.element(document.querySelector('ul.anchors li:nth-child(3)')).addClass('active'):
                angular.element(document.querySelector('ul.anchors li:nth-child(3)')).removeClass('active');
            vm.getAnchorVisibility(getPosition(angular.element(document.querySelector('bdp-similar')).parent())) ?
                angular.element(document.querySelector('ul.anchors li:nth-child(4)')).addClass('active'):
                angular.element(document.querySelector('ul.anchors li:nth-child(4)')).removeClass('active');

            if(angular.element(document.querySelector('ul.anchors li.active')).length<1){
                if($window.scrollY < getPosition(angular.element(document.querySelector('bdp-gallery div'))).top - getHeaderHeight() &&
                    $window.scrollY > getPosition(angular.element(document.querySelector('bdp-details div'))).bottom - getHeaderHeight()){
                  angular.element(document.querySelector('ul.anchors li:nth-child(2)')).addClass('active');
                }
            }
        }

        function getHeaderHeight(){
          var headerHeight = 0;
          headerHeight += angular.element(document.querySelector('body')).hasClass('sub-menu-fixed') ?
              angular.element(document.querySelector('.anchors'))[0].clientHeight : 0;
          headerHeight += angular.element(document.querySelector('.breadcrumbs')).hasClass('sticky') ?
              angular.element(document.querySelector('.breadcrumbs'))[0].clientHeight : 0;
          headerHeight += angular.element(document.querySelector('body')).hasClass('header-show') ?
              angular.element(document.querySelector('header'))[0].clientHeight : 0;
          return headerHeight;
        }

        /**
        * @memberof BdpController
        * @method setBreadcrumbsSticky
        * @name setBreadcrumbsSticky
        * @description Set sticky to Breadcrumbs
        */
        function setBreadcrumbsSticky(){
          var breadcrumbsEl   = angular.element(document.querySelector('.breadcrumbs')),
              headerHeight    = angular.element(document.querySelector('header'))[0].clientHeight,
              startStickyFrom = headerHeight,
              documentTop     = $window.scrollY;
          if(angular.isUndefined(documentTop) || platformService.isIE()) documentTop = window.pageYOffset;
          if(documentTop > startStickyFrom && !platformService.isMobilePlatform()) {
            breadcrumbsEl.addClass('sticky');
            var anchorHeight = (angular.element(document.querySelector('.anchors'))[0].clientHeight) + 'px !important';
            var breadcrumbHeight = angular.element(document.querySelector('body')).hasClass('sub-menu-fixed') ?
                anchorHeight : 'inherit';
            breadcrumbsEl.attr('style', 'top: '+breadcrumbHeight);
          }
          else{
            breadcrumbsEl.removeClass('sticky');
          }
        }
        /**
         * @memberof BdpController
         * @method getPosition
         * @name getPosition
         * @description Calculate position of element for given selector.
         * @parm {String} element selector
         * @return {Object} position in pixels
         */
        function getPosition(element) {
            var position = {
                top: 0,
                bottom: 0,
                height: 0
            };
            if (angular.isDefined(element[0])) {
                position.height = element[0].clientHeight;
                position.top = element.prop('offsetTop');
                position.bottom = position.top + position.height;
            }
            return position;
        }

        /**
         * @memberof BdpController
         * @method getAnchorVisibility
         * @name getAnchorVisibility
         * @description Calculate is anchor in visible space.
         * @parm {Number} navPosition
         * @parm {Number} anchorPositon
         * @return {Boolean}
         */
        vm.getAnchorVisibility = function(anchorPositon) {

          if ($window.scrollY >= (anchorPositon.top - getHeaderHeight()) &&
              $window.scrollY <= (anchorPositon.bottom - getHeaderHeight())) {
            return true;
          }
          return false;
        };

        vm.trackAnchor = function(name) {
            DTM.trackAnalyticOnClick(name);
        };

        vm.scrollTo = function(scrollToId){
            windowScroll.scrollToElement(angular.element(document.querySelector('#'+scrollToId)));
        }
        vm.scrollToOldFunction = function(scrollToId) {

            var startY = currentYPosition();
            var stopY = elmYPosition(scrollToId);
            var distance = stopY > startY ? stopY - startY : startY - stopY;
            if (distance < 100) {
                scrollTo(0, stopY);
                return;
            }
            var speed = Math.round(distance / 25);
            if (speed >= 20) speed = 20;
            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;
            var timer = 0;
            if (stopY > startY) {
                for (var i = startY; i < stopY; i += step) {
                    setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
                    leapY += step;
                    if (leapY > stopY) leapY = stopY;
                    timer++;
                }
                return;
            }
            for (var i = startY; i > stopY; i -= step) {
                setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
                leapY -= step;
                if (leapY < stopY) leapY = stopY;
                timer++;
            }

            function currentYPosition() {
                // Firefox, Chrome, Opera, Safari
                if (self.pageYOffset) return self.pageYOffset;
                // Internet Explorer 6 - standards mode
                if (document.documentElement && document.documentElement.scrollTop)
                    return document.documentElement.scrollTop;
                // Internet Explorer 6, 7 and 8
                if (document.body.scrollTop) return document.body.scrollTop;
                return 0;
            }

            function elmYPosition(scrollToId) {
                var elm = document.getElementById(scrollToId);
                var y = elm.offsetTop;
                var node = elm;
                while (node.offsetParent && node.offsetParent != document.body) {
                    node = node.offsetParent;
                    y += node.offsetTop;
                }

                if (currentYPosition() >= y) {
                    return y - 130; // When page scrolls up
                } else {
                    return y - 75; // When page scrolls down
                }
            }
        };

        /**
         * @memberof BdpController
         * @method hasSimilar
         * @name hasSimilar
         * @description Check does current listing have similar.
         * @return {Boolean}
         */
        vm.hasSimilar = function() {
            if (angular.isUndefined(vm.apidata)) return false;
            if (vm.apidata.paid || !getObjectLength(vm.similarListings)) return false;
            if (angular.isUndefined(vm.similarListings.data.listing)) return false;
            return !!vm.similarListings.data.listing.length;
        };

        /**
         * @memberof BdpController
         * @method hasNearbyOtherListings
         * @name hasNearbyOtherListings
         * @description Check does current listing have have nearby.
         * @return {Boolean}
         */
        vm.hasNearbyOtherListings = function() {
            if (angular.isUndefined(vm.apidata)) return false;
            return !!getObjectLength(vm.nearbyOtherListings);
        };

        var destroyOnSubmit = $rootScope.$on('search-submit', function(e, data) {
          DTM.trackAnalyticOnClick('search submit');
        });

        $scope.$on('$destroy', function() {
            destroyOnSubmit();
            if(!angular.element(document.querySelector('body')).hasClass('header-show')){
                angular.element(document.querySelector('body')).addClass('header-show')
            }
            $document.scrollTop(0);
            angular.element($window).unbind('scroll');
        });

        /*After BDP page load if user chooses any action except back button there is no further
          use of cookie 'tl_map_mrkr', so remove it. Prevents map failures after redirection*/
        angular.element(window).bind("click", function() {
            $cookies.remove('tl_map_mrkr', {path: '/'});
        });

        vm.showRequestBookingButton = false;

        if(vm.listing && vm.listing.vcitaId && vm.listing.vcitaServiceEnabled){

            if (angular.element($window)[0].outerWidth < 768) {
                vm.showRequestBookingButton = true;
            }

            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0],
                    p = 'https://',
                    r = Math.floor(new Date().getTime() / 1000000);
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = p + "widgets.vcdnita.com/assets/livesite.js?" + r;
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'livesite-jssdk'));

            $window.liveSiteAsyncInit = function() {

                vm.vCitaId = vm.listing.vcitaId;

                if(vm.env.dms){
                    vm.dmsBranding = vm.env.dms.branding;
                } else {
                    vm.dmsBranding = '825399';
                }

                LiveSite.init({
                    id : vm.vCitaId,
                    activeEngage: false,
                    branding: vm.dmsBranding
                });

            };
        }

        appService.displayFooter();
    }

    function getObjectLength(obj) {
        var length = 0;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) length++;
        }
        return length;
    }

    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function(searchString, position) {
            position = position || 0;
            return this.indexOf(searchString, position) === position;
        };
    }
})();
