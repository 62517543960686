(function() {
    'use strict';
    angular
        .module('truelocal')
        .controller('ModalAddReviewLoginController', ModalAddReviewLoginController);
    /** @ngInject */
    function ModalAddReviewLoginController($modal, windowScroll, modalFactory, $modalInstance, $timeout, DTM, $window, $location, $cookies) {
        var vm = this;
        
        vm.goToLogin = function () {
            $cookies.put('authenticateFromBdpReview',true);
            DTM.trackAnalyticOnClick('add review:opened login link');
            $window.open('/login' + $location.url(), '_self');
        }
        
        vm.goToSignUp = function () {
            $cookies.put('authenticateFromBdpReview',true);
            DTM.trackAnalyticOnClick('add review:opened signup link');
            $window.open('/signup' + $location.url(), '_self');
        }
    }
})();
