(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name homePopularCategories
     * @description Display Popular Categories by Cities on True Local
     *
     * @example
     * <home-popular-categories></home-popular-categories>
     */
    .directive('homePopularCategories', homePopularCategories);

    /** @ngInject */
    function homePopularCategories() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/homepage/homepagepopularcategories/homepagepopularcategories.html',
            scope: {},
            controller: HomePopularCategoriesController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function HomePopularCategoriesController($window, $timeout, categoriesService) {
            var vm = this;
            vm.popularCategories = [];
            vm.columnWidth;
            vm.columnWrapper;
            vm.columnLength;
            vm.currentSlide = 1;
            vm.slideLength = 0;
            var COLUMN_PER_SLIDE = 5;

            vm.isHome = function() {
                var root = window.location.origin, url = window.location.href;
                return root + '/' === url || root === url;
            };

            if (vm.isHome()) {
                categoriesService.getCategories()
                    .then(function(items) {
                        vm.popularCategories = items;
                    });
            }

            vm.activate = function() {
                $timeout(function() {
                    vm.initializeElements();
                    vm.calculateSlideProperties();
                });

                angular.element($window).on('resize', vm.onWindowResize);
            };

            vm.initializeElements = function() {
                vm.columnWidth = angular.element(document.querySelector('#footer-categories .options-columns'));
                vm.columnWrapper = angular.element(document.querySelector('#footer-categories .column-wrapper'));
                vm.columnLength = angular.element(document.querySelectorAll('#footer-categories .column'));
            };

            vm.calculateSlideProperties = function() {
                vm.slideLength = Math.ceil(vm.popularCategories.length / COLUMN_PER_SLIDE);
                vm.columnWidth = vm.columnWidth.prop('clientWidth');

                if ($window.innerWidth >= 767) {
                    vm.slideLength = Math.ceil(vm.columnLength.length / COLUMN_PER_SLIDE);
                } else {
                    vm.slideLength = vm.popularCategories.length || 0;
                }

                if ($window.innerWidth > 992) {
                    vm.columnWidth = 942;
                }
            };

            vm.onWindowResize = function() {
                vm.initializeElements();
                vm.calculateSlideProperties();

                vm.columnWrapper.css('left', 0);
                vm.currentSlide = 1;
            };

            vm.handleNext = function() {
                if (vm.currentSlide < vm.slideLength) {
                    vm.currentSlide++;
                    vm.columnWrapper.css('left', -(vm.currentSlide - 1) * vm.columnWidth + 'px');
                }
            };

            vm.handlePrev = function() {
                if (vm.currentSlide > 1) {
                    vm.currentSlide--;
                    vm.columnWrapper.css('left', -(vm.currentSlide - 1) * vm.columnWidth + 'px');
                }
            };
        }
    }
})();
