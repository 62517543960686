(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsCopyPoints
     * @description Handles copy points.
     *  * build the entire copypoints object including brands, service locations, downloads
     *  * handle the display in page, view more, view less
     *
     *  @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     *
     *  @example
     *  <bdp-details-copy-points listing="[object]" hamburger-menu-active="[integer]"></bdp-details-copy-points>

     */
    .directive('bdpDetailsCopyPoints', bdpDetailsCopyPoints);

  /** @ngInject */
    function bdpDetailsCopyPoints() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetails/bdpdetailscopypoints.html',
            scope: {
                listing: '=',
                hamburgerMenuActive: '=',
            },
            controller: ['platformService','$window', BdpDetailsCopyPointsController],
            controllerAs: 'vm',
            bindToController: true
        };


        return directive;

    /** @ngInject */
        function BdpDetailsCopyPointsController(platformService, $window) {
            var vm = this;
            vm.dataModel = [];
            vm.suburbdIndexed = false;
            vm.downloadsIndexed = false;
            vm.brandsIndexed = false;
            vm.totalItems = 0;
            vm.windowWidth = platformService.getWidth();
            if (vm.windowWidth < 992) {
                vm._isMobile = true;
            } else {
                vm._isMobile = false;
            }
            angular.element($window).bind('resize', function(){
                vm.windowWidth = platformService.getWidth();
                if (vm.windowWidth < 992) {
                    vm._isMobile = true;
                } else {
                    vm._isMobile = false;
                }
            });

      /**
       * @memberof bdpDetailsCopyPoints
       * @method getDataModel
       * @name getDataModel
       * @description Build the object with all copypoints combining if exists: copypoints object, brands string, downloads object, service locations object
       * @param {Object} listing  Current business listing object
       * @returns {Object} dataModel  final copypoints object
       */
            vm.getDataModel = function(listing) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }

                if (angular.isDefined(vm.listing) && vm.dataModel.length==0) {
          // first we check for copypoints
                    if (angular.isDefined(vm.listing.copypoints) && angular.isDefined(vm.listing.copypoints.copypoint)) {
                        if (vm.dataModel.length == 0) {
                            vm.dataModel = vm.listing.copypoints.copypoint;
                            vm.totalItems = vm.listing.copypoints.size;
                        }
                    }

          // next we add suburb services
                    if (angular.isDefined(vm.listing.serviceLocations) && angular.isDefined(vm.listing.serviceLocations.serviceLocation) && vm.listing.serviceLocations.serviceLocation.length > 0) {
                        var serviceData = {
                            name: 'Suburb Services',
                            values: vm.listing.serviceLocations.serviceLocation,
                        };
                        if (vm.suburbdIndexed == false) {
                            vm.suburbdIndexed = true;
                            vm.dataModel.push(serviceData);
                            vm.totalItems = vm.totalItems + vm.listing.serviceLocations.serviceLocation.length;
                        }
                    }

          // next we add downloads
                    if (angular.isDefined(vm.listing.documents) && angular.isDefined(vm.listing.documents.document)) {
                        var downloadData = {
                            name: 'Downloads',
                            values: vm.listing.documents.document,
                        };
                        if (vm.downloadsIndexed == false) {
                            vm.downloadsIndexed = true;
                            vm.dataModel.push(downloadData);
                            vm.totalItems = vm.totalItems + vm.listing.documents.document.lenght;
                        }
                    }
          // check for brands
                    if (angular.isDefined(vm.listing.brands)) {
                        if (vm.listing.brands.length > 0) {
                            var _brands = vm.listing.brands;
                            _brands = _brands.split('¶');
                            var brandData = {
                                name: 'Brands',
                                values: _brands,
                            };
                            if (vm.brandsIndexed == false) {
                                vm.dataModel.push(brandData);
                                vm.brandsIndexed = true;
                                vm.totalItems = vm.totalItems + _brands.length;
                            }
                        }
                    }
                    var _total = vm.totalItems;
                    vm.dataModel = vm.sortDataModel(vm.dataModel);
                    var increment = 1;
                    angular.forEach(vm.dataModel, function(copypoint, index) {
                        if (increment == 1) {
                            vm.dataModel[index].column = 1;
                            increment = increment + 1;
                        } else if (increment == 2) {
                            vm.dataModel[index].column = 2;
                            increment = increment + 1;
                            if (_total <= 4) {
                                increment = 1;
                            }
                        } else if (increment == 3) {
                            vm.dataModel[index].column = 3;
                            increment = 1;
                        }
                    });
                    return vm.dataModel;
                } else {
                    return vm.dataModel;
                }
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method sortDataModel
       * @name sortDataModel
       * @description Sort copypoints object by the number of elements in each copypoint
       * @param {Object} input  unsorted copypoints object
       * @returns {Array} sorted copypoints object
       */
            vm.sortDataModel = function(input) {
                var array = [];
                for (var i=0; i< input.length; i++) {
                    array.push(input[i]);
                }
                array.sort(function(a, b) {
                    if(angular.isDefined(b.values) && angular.isDefined(a.values)) {
                        a = parseInt(a.values.length);
                        b = parseInt(b.values.length);
                        return b - a;
                    }
                });
                return array;
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getTotalItems
       * @name getTotalItems
       * @description return length of data model if present (number of copypoints).
       * @param {Object} listing  Current business listing object
       * @returns {Number} totalItems
       */
            vm.getTotalItems = function(listing) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }
                if (angular.isUndefined(vm.listing)) {
                    vm.totalItems = 0;
                } else {
                    vm.totalItems = vm.getDataModel(vm.listing).length;
                }
                return vm.totalItems;
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getColClass
       * @name getColClass
       * @description Return bootstrap column class to change display of listing if there are less of four items in
       * listing, and otherwise.
       * @param {Object} listing Current business listing object
       * @returns {String} column class
       */
            vm.getColClass = function(listing) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }
                if (vm.getTotalItems(vm.listing) <= 4) return 'col-lg-6 col-md-6 col-sm-12';
                else return 'col-lg-4 col-md-4 col-sm-12';
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getValue
       * @name getValue
       * @description Return firstly founded value suburb / caption / name.
       * @param {Object} value Current business listing object
       * @returns {String} suburb / caption / name
       */
            vm.getValue = function(value) {
                if (angular.isUndefined(value)) return '';
                else if (angular.isDefined(value.suburb)) return value.suburb;
                else if (angular.isDefined(value.caption)) return value.caption;
                else if (angular.isDefined(value.name)) return value.name;
                else return value;
            };

            vm.visibileFirstCol = false;
            vm.visibileFirstColSet = false;


      /**
       * @memberof bdpDetailsCopyPoints
       * @method isVisibileFirstCol
       * @name isVisibileFirstCol
       * @description Check visibility of first column.
       * @param {Number} index  Current index
       * @param {Object} listing Current business listing object
       * @returns {Boolean} is visible
       */
            vm.isVisibileFirstCol = function(index, listing) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }
                index = index + 1;
                if (vm.visibileFirstColSet == true) {
                    return vm.visibileFirstCol;
                }
                var counter = vm.getTotalItems(vm.listing);
                if (counter <= 4) {
                    if (angular.isDefined(index) && index % 2 == 1) vm.visibileFirstCol = true;
                    else vm.visibileFirstCol = false;
                } else {
                    if (angular.isDefined(index) && index % 2 == 1 && index % 3 != 0) vm.visibileFirstCol = true;
                    else vm.visibileFirstCol = false;
                }
                vm.visibileFirstColSet = true;
                return vm.visibileFirstCol;
            };


            vm.visibleSecondCol = false;
            vm.visibleSecondColSet = false;


      /**
       * @memberof bdpDetailsCopyPoints
       * @method isVisibileSecondCol
       * @name isVisibileSecondCol
       * @description Check visibility of second column.
       * @param {Number} index current index
       * @param {Object} listing Current business listing object
       * @returns {Boolean} is visible
       */
            vm.isVisibileSecondCol = function(index, listing) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }
                if (vm.visibleSecondColSet == true) {
                    return vm.visibleSecondCol;
                }
                var counter = vm.getTotalItems(vm.listing);
                index = index + 1;
                if (counter <= 4) {
                    if (angular.isDefined(index) && index % 2 == 0) vm.visibleSecondCol = true;
                    else vm.visibleSecondCol = false;
                } else {
                    if (angular.isDefined(index) && index % 2 == 0 && index % 3 != 0) vm.visibleSecondCol = true;
                    else vm.visibleSecondCol = false;
                }
                vm.visibleSecondColSet = true;
                return vm.visibleSecondCol;
            };

            vm.visibileThirdCol = false;
            vm.visibileFirstColSet = false;


      /**
       * @memberof bdpDetailsCopyPoints
       * @method isVisibileThirdCol
       * @name isVisibileThirdCol
       * @description Check visibility of third column.
       * @param {Number} index  current index
       * @param {Object} listing Current business listing object
       * @returns {Boolean} is visible
       */
            vm.isVisibileThirdCol = function(index, listing) {
                if (angular.isDefined(listing)) {
                    vm.listing = listing;
                }
                index = index + 1;
                if (vm.visibileFirstColSet == true) {
                    return vm.visibileThirdCol;
                }
                var counter = vm.getTotalItems(vm.listing);
                if (counter <= 4) {
                    vm.visibileThirdCol = false;
                } else {
                    if (angular.isDefined(index) && index % 3 == 0) vm.visibileThirdCol = true;
                    else vm.visibileThirdCol = false;
                }
                vm.visibileFirstColSet = true;
                return vm.visibileThirdCol;
            };

            vm.hasMore = [];


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getHasMore
       * @name getHasMore
       * @description Check does have more points by copypoint.
       * @param {Object} copypoint copypoint object
       * @returns {Boolean} has more
       */
            vm.getHasMore = function(copypoint) {
                if (angular.isUndefined(copypoint)) return false;
                else {
                    vm.hasMore[copypoint.name] = false;
                    if (vm._isMobile == true) {
                        vm.hasMore[copypoint.name] = false;
                    } else if (vm.windowWidth <= 768) {
                        if (copypoint.values.length > 3) vm.hasMore[copypoint.name] = true;
                        else vm.hasMore[copypoint.name] = false;
                    } else {
                        if (copypoint.values.length > 6) vm.hasMore[copypoint.name] = true;
                        else vm.hasMore[copypoint.name] = false;
                    }
                    return vm.hasMore[copypoint.name];
                }
            };

            vm.showLineValue = [];


      /**
       * @memberof bdpDetailsCopyPoints
       * @method showValue
       * @name showValue
       * @description Decide weather to show value or not, depending on screen size, index and current listing obj.
       * @param {Number} index
       * @param {Object} obj
       * @returns {Boolean} has more
       */
            vm.showValue = function(index, obj) {
                if (vm._isMobile == true) {
                    return true;
                } else {
                    if (angular.isUndefined(index)) {
                        return false;
                    } else {
                        if (index < 3) {
                            return true;
                        } else {
                            if (vm.windowWidth > 768 && index < 6) {
                                return true;
                            } else {
                                if (angular.isDefined(obj) &&
                  angular.isDefined(vm.showLineValue) &&
                  angular.isDefined(vm.showLineValue[obj])) {
                                    return vm.showLineValue[obj];
                                } else {
                                    return false;
                                }
                            }
                        }
                    }
                }
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getMoreCounter
       * @name getMoreCounter
       * @description Calculate number of get more by copy points values and screen size.
       * @param {Object} copypoint
       * @returns {Number} has more
       */
            vm.getMoreCounter = function(copypoint) {
                if (angular.isUndefined(copypoint) || angular.isUndefined(copypoint.values)) return 0;
                else {
                    var counter = copypoint.values.length;
                    if (vm.windowWidth <= 768) {
                        if (counter > 3) return counter - 3;
                        else return counter;
                    } else {
                        if (counter > 6) return counter - 6;
                        else return counter;
                    }
                }
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method viewHideMore
       * @name viewHideMore
       * @description Set showLineValue for entered copypoint.
       * @param {Object} copypoint
       * @returns {Number} has more
       */
            vm.viewHideMore = function(copypoint) {
                if (angular.isUndefined(vm.showLineValue) || angular.isUndefined(copypoint) || angular.isUndefined(copypoint.name)) {
                    return;
                }
                if (vm.showLineValue[copypoint.name] == true) vm.showLineValue[copypoint.name] = false;
                else vm.showLineValue[copypoint.name] = true;
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method viewHideMore
       * @name viewHideMore
       * @description Generate proper href.
       * @param {Object} value
       * @returns {String} href
       */
            vm.getHref = function(value) {
                if (angular.isUndefined(value)) return '#';
                else if (angular.isDefined(value.url)) return value.url;
                else if (angular.isDefined(value.seoUrl)) return '/business/' + value.seoUrl;
                else return '#;';
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getMoreButtonText
       * @name getMoreButtonText
       * @description Decide sign to display (View more/View less).
       * @param {Object} copypoint
       * @returns {String} View more/View less
       */
            vm.getMoreButtonText = function(copypoint) {
                if (angular.isUndefined(copypoint) || angular.isUndefined(copypoint.name)) {
                    return 'less';
                }
                if (angular.isUndefined(vm.showLineValue[copypoint.name])) {
                    return 'View more';
                } else if (vm.showLineValue[copypoint.name] == false) {
                    return 'View more';
                } else {
                    return 'View less';
                }
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getCopyPointNameValid
       * @name getCopyPointNameValid
       * @description Get copy point name.
       * @param {String} name
       * @returns {String} Service Options / Payment options
       */
            vm.getCopyPointNameValid = function(name) {
                if (name == 'Service Options') {
                    return 'Services';
                } else if (name == 'Payment Method Accepted') {
                    return 'Payment options';
                } else {
                    return name;
                }
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getCopyPointNameValid
       * @name getCopyPointNameValid
       * @description Select / deselect hamburger menu.
       * @param {Number} index
       */
            vm.hamburgerSelect = function(index) {
                if (angular.isUndefined(index)) {
                    return;
                }
                if (this.hamburgerMenuActive == index) this.hamburgerMenuActive = -1;
                else this.hamburgerMenuActive = index;
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getClassMore
       * @name getClassMore
       * @description Return proper class.
       * @param {Object} copypoint
       * @returns {String}
       */
            vm.getClassMore = function(copypoint) {
                if (angular.isUndefined(copypoint) || angular.isUndefined(copypoint.name)) {
                    return 'services-show-less';
                }
                if (angular.isUndefined(vm.showLineValue[copypoint.name])) {
                    return 'more';
                } else if (vm.showLineValue[copypoint.name] == false) {
                    return 'more';
                } else {
                    return 'services-show-less';
                }
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method showArrowType
       * @name showArrowType
       * @description Return arrow type 1 - down; 2 - up.
       * @param {Object} copypoint
       * @returns {Number} 1 - down; 2 - up
       */
            vm.showArrowType = function(copypoint) {
                if (angular.isUndefined(copypoint) || angular.isUndefined(copypoint.name)) {
                    return 1;
                }
                if (angular.isUndefined(vm.showLineValue[copypoint.name])) {
                    return 1;
                } else if (vm.showLineValue[copypoint.name] == false) {
                    return 1;
                } else {
                    return 2;
                }
            };


      /**
       * @memberof bdpDetailsCopyPoints
       * @method getShowCounter
       * @name getShowCounter
       * @description Show or hide counter.
       * @param {Object} copypoint
       * @returns {Boolean}
       */
            vm.getShowCounter = function(copypoint) {
                if (angular.isUndefined(copypoint) || angular.isUndefined(copypoint.name)) {
                    return false;
                }
                if (angular.isUndefined(vm.showLineValue) || angular.isUndefined(vm.showLineValue[copypoint.name])) {
                    return true;
                } else if (vm.showLineValue[copypoint.name] == false) {
                    return true;
                } else {
                    return false;
                }
            };
        }
    }
})();

