(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsEmailBusiness
     * @description Directive will display email business link. It will display the email business modal.
     *
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {service}   $log                      Angular console log wrapper
     * @param {constant}  env                       Environmental constants
     * @param {service}   $window                   Angular wrapper for window
     *
     * @example
     * <bdp-details-email-business listing="[object]" copypoint="[boolean]"></bdp-details-email-business>
     */
    .directive('bdpDetailsEmailBusiness', bdpDetailsEmailBusiness);

  /** @ngInject */
    function bdpDetailsEmailBusiness() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetailsemailbusiness/bdpdetailsemailbusiness.html',
            scope: {
                listing: '=',
                details: '=',
                displayedfrom: '=',
            },

            controller: BdpDetailsEmailBusinessController,
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

    /** @ngInject */
        function BdpDetailsEmailBusinessController(constantsIcons, businessService) {
            var vm = this;
            vm.details = this.details;
            vm.displayedfrom = this.displayedfrom;
            vm.isDisplayedInDesktop = vm.displayedfrom == 'contactholder' || vm.displayedfrom == 'scroll';
            vm.isDisplayedInMobile = vm.displayedfrom == 'tools' || vm.displayedfrom == 'scroll';

            vm.hasContactEmail = function() {
                return businessService.hasContactEmail(vm.listing);
            };

            vm.emailABusiness = function($event) {
                businessService.emailABusiness($event, 'BDP', vm.listing);
            };

            vm.emailText = function() {
                return window.outerWidth <= 767 ? 'Email' : 'EMAIL THIS BUSINESS';
            };
        }
    }
})();
