(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc service
         * @name authService
         * @description authService provides methods that handles user authentication modals.
         *
         * @param {directive} $modal                    ui-bootstrap modal wrapper
         * @param  {service}  $http                     angular http service
         * @param {service}   $log                      Angular console log wrapper
         * @param {factory}   apiConfig                 Api configuration factory
         * @param {factory}   windowScroll              wndowScroll service provides functions that can adopt window behavior when scrolling view
         */
        .factory('authService', ['$modal', '$http', '$log', 'apiConfig', 'windowScroll', authService]);

    /** @ngInject */
    function authService($modal, $http, $log, apiConfig, windowScroll) {
        var service = {
            signIn: signIn,
            signUp: signUp,
            authModal: authModal,
            authModalSignup: authModalSignup,
            signOut: signOut,
            resetLoginPwd: resetLoginPwd,
        };


        /**
         * @memberof authService
         * @method authModal
         * @name authModal
         * @description User authentication (login) modal.
         */
        function authModal(modalFactory, passThroughData) {
            if (angular.element(document.querySelector('#login-modal')).length != 1) {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/modalLogin/modallogin.html',
                    controller: ['$modalInstance', 'storedData', modalFactory],
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
	                windowClass: 'authModal',
	                size: 'dialog_size_smallest',
                    resolve: {
                        storedData: function() {
                            return passThroughData;
                        }
                    }
                });
                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
                return _modalInstance;
            }
        }

        /**
         * @memberof authService
         * @method authModal
         * @name authModal
         * @description User authentication (login) modal.
         */
        function authModalSignup(modalFactory, passThroughData) {
            if (angular.element(document.querySelector('#login-modal')).length != 1) {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/modalSignup/modalsignup.html',
                    controller: ['$modalInstance', 'storedData', modalFactory],
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    size: 'dialog_size_smallest',
                    windowClass: 'authModal',
                    resolve: {
                        storedData: function() {
                            return passThroughData;
                        }
                    }
                });
                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
                return _modalInstance;
            }
        }

        /**
         * @memberof authService
         * @method resetLoginPwd
         * @name resetLoginPwd
         * @description User authentication (login) modal.
         */
        function resetLoginPwd(modalFactory, storedData, email, hash) {
            if (angular.element(document.querySelector('#login-modal')).length != 1) {
                var _modalInstance = $modal.open({
                    templateUrl: '/app/components/modalLogin/modalloginreset.html',
                    controller: ['$modalInstance', 'storedData', 'email', 'hash', modalFactory],
                    controllerAs: 'vm',
                    size: 'dialog_size_smallest',
                    resolve: {
                        storedData: function() {
                            return storedData;
                        },
                        email: function() {
                            return email;
                        },
                        hash: function() {
                            return hash;
                        }
                    }
                });
                _modalInstance.opened.then(function() {
                    windowScroll.mobileModalScroll();
                });
                _modalInstance.result.finally(function() {
                    windowScroll.mobileModalCloseHandler();
                });
                return _modalInstance;
            }
        }


        /**
         * @memberof authService
         * @method signIn
         * @name signIn
         * @description Sends server user sign in request.
         */
        function signIn(user) {
            return $http.post(apiConfig.generate('auth', 'login'), user, {
                    ignoreLoadingBar: true
                }, apiConfig.getXAuthToken())
                .then(getSignInDetails)
                .catch(getSignInFailed);

            function getSignInDetails(response) {
                apiConfig.setToken(response.data.data.passToken);
                apiConfig.updateCredentials(response.headers('x-auth-token'));
            }

            function getSignInFailed(error) {
                return error;
            }
        }

        /**
         * @memberof authService
         * @method signUp
         * @name signUp
         * @description Sends server user sign up request.
         */
        function signUp(user) {
            return $http.post(apiConfig.generate('auth', 'signup'), user, {
                    ignoreLoadingBar: true
                }, apiConfig.getXAuthToken())
                .then(getSignUpDetails)
                .catch(getSignUpFailed);

            function getSignUpDetails(response) {
                console.log(response);
            }

            function getSignUpFailed(error) {
                return error;
            }
        }


        /**
         * @memberof authService
         * @method signOut
         * @name signOut
         * @description Sends server user sign in request.
         */
        function signOut() {
            $http.get(apiConfig.generate('auth', 'logout'), {
                    ignoreLoadingBar: true
                }, apiConfig.token)
                .then(function() {
                    return apiConfig.destroySession();
                })
                .catch(function(error) {
                    $log.debug(error);
                });
        }

        return service;
    }
})();
