(function() {
    'use strict';

    angular
        .module('truelocal')

        .controller('newsletterController', newsletterController);

    /** @ngInject */
    function newsletterController(newsletterService) {
        var vm = this;

        vm.subscribing = false;
        vm.subscribeSuccess = false;

        // These next 2 functions ain't good, not sure another way to do them
        vm.locationsChange = function() {
            // If any location checkbox is checked
            if (
                vm.locationNSW === true ||
                vm.locationVIC === true ||
                vm.locationQLD === true ||
                vm.locationSA === true ||
                vm.locationWA === true ||
                vm.locationNATIONAL === true
            ) {
                vm.localNewsletter = true;
            } else {
                vm.localNewsletter = false;
            }
        };
        vm.localChange = function() {
            // If local newsletter checkbox is unchecked
            if (vm.localNewsletter === false) {
                vm.locationNSW = false;
                vm.locationVIC = false;
                vm.locationQLD = false;
                vm.locationSA = false;
                vm.locationWA = false;
                vm.locationNATIONAL = false;
            }
        };

        vm.subscribe = function() {
            vm.subscribing = true;

            var subscribeData = {
                emailAddress: vm.newsletterEmail,
                name: vm.newsletterName,
                selectedRegions: {
                    NSW: !!vm.locationNSW,
                    VIC: !!vm.locationVIC,
                    QLD: !!vm.locationQLD,
                    SA: !!vm.locationSA,
                    WA: !!vm.locationWA,
                    NATIONAL: !!vm.locationNATIONAL,
                },
                businessNewsLetter: !!vm.businessNewsletter,
                optin: !!vm.newsletterSpecialOffers,
                newSubscriber: true,
            };

            newsletterService.postNewsletterSubscriptions(subscribeData).then(function(data) {
                if (data.data.meta.httpCode === 200) {
                    // If successfull response
                    vm.subscribeSuccess = true;
                    vm.subscribing = false;
                } else {
                    // If not successfull
                    vm.subscribeErrors = data.data.meta.errors;
                    vm.subscribing = false;
                }
            });
        };
    }
})();
