(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name appService
     * @description appService provide different setings for handling integration with mobile app
     */
    .factory('appService', appService);

  /** @ngInject */
    function appService(env, $rootScope, $state, $stateParams) {
        var service = {
            listingId: {
                value: null,
                set: setListingId,
            },

            tapstreamAccountName: {
                value: env.tapstreamAccountName,
            },

            displayFooter: displayFooter,
        };

        function setListingId(value) {
            service.listingId.value=value;
        }

        /**
         * @memberof appService
         * @method displayFooter
         * @name displayFooter
         * @description change display property of footer to block to display it
         */
        function displayFooter() {
            (function waitForFooter() {
                var footer = document.querySelector('footer');
                if (footer) {
                    footer.style.display = 'block';
                } else {
                    requestAnimationFrame(waitForFooter);
                }
            })();
        }

        return service;
    }
})();
