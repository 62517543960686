(function() {
    'use strict';

    angular
        .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc controller
     * @name TradieGalleryNavigatorControllerController
     * @description TradieGalleryNavigatorControllerController handle displaying of gallery in full screen mode.
     */
    .controller('TradieGalleryNavigatorController', TradieGalleryNavigatorController);

    /** @ngInject */
    function TradieGalleryNavigatorController($scope, $log, images, listing, $http, apiConfig, $timeout, selectedImage, $q, $window, $rootScope, $modalInstance, $location, errorService, city, DTM) {
        var gallery = this;
        $rootScope.$on('$locationChangeStart', function() {
            $modalInstance.close();
        });

        gallery.loading = true;


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name css
         * @description TradieGalleryNavigatorController tag.
         */
        gallery.TAG = '[TradieGalleryNavigatorController]';


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name listing
         * @description TradieGalleryNavigatorControllerController root data object.
         */
        gallery.listing = listing;


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name selectedImage
         * @description Currently selected image.
         */
        gallery.selectedImage = selectedImage;


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name images
         * @description Array of images in gallery.
         */
        gallery.images = [];
        gallery.imagesTemp = [];
        gallery.imagesOwner = [];
        gallery.imagesVideo = [];
        gallery.imagesUser = [];

        gallery.videosData = [];
        gallery.videosURL = [];
        gallery.videosPoster = [];
        gallery.businessName = [];
        gallery.businessCategory = [];
        gallery.businessAddress = [];
        gallery.reviewsNumbers = [];
        gallery.reviews = [];
        gallery.bdpURL = [];
        gallery.currentVideo = $rootScope.currentTradieVideoSeclected || 0;
        gallery.showStars = true;

        $log.debug(gallery.currentVideo);


        gallery.size = 0;
        gallery.userIsAuthenticated = apiConfig.userIsAuthenticated();
        gallery.showVideo = false;
        gallery.done = false;


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name css
         * @description css object holds classes for gallery style.
         */
        gallery.css = {
            selectors: {
                thumbnails: 'div.modal-gallery-navigator--thumbnails',
                list: 'div.modal-gallery-navigator div.modal-gallery-navigator--thumbnails ul',
                textarea: '.modal-gallery-navigator textarea',
            },
        };


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name settings
         * @description settings object.
         */
        gallery.settings = {
            review: {
                maxLength: 1000,
            },
        };

        gallery.form = {};

        gallery.contents = {};


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name states
         * @description Gallery states defined by number of images, width of thumbs, lodaing state etc.
         */
        gallery.states = {
            activeIndex: 0,
            activePage: 0,
            thumbWidth: 250,
            totalPages: 0,
            perView: 0,
            isSubmitPending: false,
        };

        gallery.visibility = {
            reporting: false,
        };


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @method openPinterestUrl
         * @name openPinterestUrl
         * @description Opens pinterest service with gallery url, large image, and business title to create gallery.
         * @params {String} url
         * @params {String} large
         * @params {String} caption
         * @returns {Boolean} false
         */
        gallery.openPinterestUrl = function(url, large, caption) {
            window.open('https://www.pinterest.com/pin/create/button/?url=' + url + '&media=' + large + '&description=' + caption);
            return false;
        };


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name events
         * @description Events on gallery.
         * @params {Object} event
         */
        gallery.events = {
            onResize: function(event) {
                if (gallery.showVideo == true) {
                    return;
                }
                var width = angular.element(window)[0].outerWidth,
                    // var width = ($window).width(),
                    height = angular.element(window)[0].outerHeight,
                    thumbContainer = document.querySelector(gallery.css.selectors.thumbnails),
                    onPage = 10;
                // $log.debug('width',$('.modal-gallery-navigator--details').width(),($window).width());

                onPage = Math.ceil(width / (thumbContainer.offsetHeight + 50));

                gallery.states.thumbWidth = (width / onPage).toFixed(5);
                gallery.states.perView = (width / Math.round(width / onPage)).toFixed(5);
                gallery.states.totalPages = Math.ceil((gallery.images.length * gallery.states.thumbWidth) / width) - 1;

                gallery.actions.updateNavigation();

                // $log.debug('%s Thumb width: %s, Number of visible items: %d, Total pages: %d', gallery.TAG, gallery.states.thumbWidth, gallery.states.perView, gallery.states.totalPages);
            },
        };

        gallery.imageStyle = '';


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @name actions
         * @description Actions on gallery.
         */
        gallery.actions = {
            setup: function() {
                $log.debug('%s Setup, all loaded');
            },
            getDescription: function() {
                if (angular.isDefined(gallery.images[gallery.states.activeIndex].caption)) {
                    return gallery.images[gallery.states.activeIndex].caption;
                } else {
                    return '';
                }
            },
            load: function() {
                gallery.cleanBgImg();

                $log.debug('%s Loading API data...', gallery.TAG);

                var owner, user, videos;

                videos = $http({
                    method: 'GET',
                    url: apiConfig.generate('listings') + '&type=campaign&location=' + city + '&campaign=nofacelessquotes',
                }).then(function(response) { // Hack for not valid data
                    response.data.data.forEach(function(video) {
                        video.featureVideo = video.featureVideo || {};
                        video.primaryCategory = video.primaryCategory || {};
                        video.primaryAddress = video.primaryAddress || {};
                        video.statistics = video.statistics || {};
                    });
                    return response;
                }).then(function(response) {
                    gallery.loading = false;
                    $log.debug(response.data.data);
                    response.data.data.forEach(function(video) {
                        gallery.videosData.push(video);
                        gallery.videosPoster.push(video.featureVideo.previewImageUrl);
                        gallery.videosURL.push(video.featureVideo.url);
                        gallery.bdpURL.push(video.seoUrl);
                        gallery.businessName.push(video.name);
                        gallery.businessCategory.push(video.primaryCategory.name);
                        gallery.businessAddress.push(video.primaryAddress.suburb + ', ' + video.primaryAddress.state);
                        gallery.reviewsNumbers.push(video.statistics.reviews);
                        gallery.reviews.push(video);
                    });

                    gallery.imagesVideo = angular.isDefined(response.data.data.video) ? response.data.data.video : [];
                }, function(response) {
                    $log.debug('%s Could not load: %s videos.', gallery.TAG, 'BUSINESS');
                    $log.debug(errorService.getErrorMessage(response));
                });


                $q.all([
                    videos,
                ]).then(function() {
                    gallery.actions.setup();
                });
            },
            scrollToIndex: function(index) {
                var page = Math.floor(index / gallery.states.perView);
                // $log.debug('%s scrollToIndex: %d, page: %d', gallery.TAG, index, page);

                gallery.actions.goToPage(page);
            },
            setActive: function(index) {
                index = index || 0;

                // $log.debug('%s setActive to %d', gallery.TAG, index);
                gallery.states.activeIndex = index;
                gallery.actions.scrollToIndex(gallery.states.activeIndex);

                gallery.actions.toggleReport(false);
                if (gallery._has('images[' + gallery.states.activeIndex + '].urls.large')) {
                    gallery.imageStyle = gallery.images[gallery.states.activeIndex].urls.large;
                }
                gallery.showVideo = false;
                $timeout(function() {
                    gallery.done = true;
                }, 100);
            },
            nextVideo: function() {
                gallery.currentVideo = gallery.currentVideo + 1;
                gallery.showVideo = false;
                gallery.actions.reloadStars();
            },
            prevVideo: function() {
                gallery.currentVideo = gallery.currentVideo - 1;
                gallery.showVideo = false;
                gallery.actions.reloadStars();
            },

            goToPage: function(index, setFirstActive) {
                setFirstActive = setFirstActive || false;

                var width = angular.element(window)[0].outerWidth,
                    list;

                list = document.querySelector(gallery.css.selectors.list);

                gallery.states.activePage = index;

                if (gallery.states.activePage > gallery.states.totalPages) {
                    gallery.states.activePage = gallery.states.totalPages;
                    // gallery.states.activePage = 0;
                }

                if (gallery.states.activePage < 0) {
                    gallery.states.activePage = 0;
                    // gallery.states.activePage = gallery.states.totalPages;
                }

                list.style.marginLeft = ['-', Math.round(gallery.states.activePage * width), 'px'].join('');

                // $log.debug('%s Navigate', gallery.TAG);
            },
            navigate: function(direction) {
                gallery.actions.goToPage(gallery.states.activePage + direction);
            },
            updateNavigation: function() {
                var width = angular.element(window)[0].outerWidth;

                var list = document.querySelector(gallery.css.selectors.list);
                list.style.marginLeft = '0px';
                gallery.states.activePage = 0;
                gallery.actions.setActive(0);
            },
            getMomentAgo: function(timestamp) {
                return angular.isDefined(timestamp) ? moment(moment(timestamp), 'YYYYMMDD').fromNow() : '';
            },
            toggleReport: function(visible) {
                visible = angular.isDefined(visible) ? visible : !gallery.visibility.reporting;
                gallery.form.message = '';
                if (visible && !gallery.userIsAuthenticated) {
                    $log.warn('%s You need to sign-in first!', gallery.TAG);
                    $rootScope.$broadcast('loginmodal:show');
                    return;
                }

                gallery.visibility.reporting = visible;
            },
            showVideo: function(e) {
                var tradie = gallery.videosData[gallery.currentVideo];
                DTM.trackAnalyticOnClick('video watched');


                var container = angular.element(e.currentTarget);
                var player = container.find('.video').get(0);
                player.play();
                gallery.showVideo = true;
            },
            reloadStars: function (){
                gallery.showStars = false;
                $timeout(function(){
                    gallery.showStars = true;
                }, 1);
            },
        };

        gallery.openLink = function(link) {
            DTM.trackAnalyticOnClick('get in touch video button');
            $window.open($window.location.origin + '/business/' + link, '_blank');
        };

        gallery.imageState = '';


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @method cleanBgImg
         * @name cleanBgImg
         * @description Clear image state for IE <= 9.
         */
        gallery.cleanBgImg = function() {
            var ua = detect.parse(navigator.userAgent);
            if (ua.browser.family == 'IE' && ua.browser.version <= 9) return;
            gallery.imageStyle = '';
        };

        gallery.show = false;

        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @event imagesOnChange
         * @name imagesOnChange
         * @description Images change watcher.
         */
        $scope.$watch('gallery.images', function(newVal) {
            if (angular.isDefined(newVal)) {
                $log.debug('%s Delete inactive pictures.', gallery.TAG);
                // gallery.cleanBgImg();
                gallery.images = gallery.images.filter(function(image) {
                    return image.status.publishStatus === 'active';
                });

                // $log.debug('%s Current collection: %O', gallery.TAG, gallery.images);
            }
        }, true);


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @event statesActiveIndexOnChange
         * @name statesActiveIndexOnChange
         * @description States ActiveIndex change watcher.
         */
        $scope.$watch('gallery.states.activeIndex', function(newVal) {
            if (angular.isDefined(newVal)) {
                $log.debug('%s $watch gallery.states.activeIndex -> details: %O', gallery.TAG, gallery.videosData[newVal]);
            }
        });


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @event imagesUrlsLargeChange
         * @name statesActiveIndexWatcher
         * @description Large img url change watcher.
         */
        // $scope.$watch('gallery.images[gallery.states.activeIndex].urls.large', function(newVal, prevVal) {

        //     if (angular.isDefined(newVal) && newVal != prevVal) {
        //         $timeout(function() {
        //             gallery.imageState = gallery.getImageState();
        //             gallery.show = true;
        //         }, 0);
        //     }
        // });


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @event destroyEvent
         * @name destroyEvent
         * @description States ActiveIndex change watcher.
         */
        $scope.$on('$destroy', function() {
            $log.debug('%s $destroy', gallery.TAG);
            angular.element($window).off('resize', gallery.events.onResize);
            document.body.classList.remove('noHorizontalScroll');
        });


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @event windowOnResize
         * @name windowOnResize
         * @description Transfer window resize event to gallery.
         */
        angular.element($window).on('resize', gallery.events.onResize);


        /**
         * @memberof TradieGalleryNavigatorControllerController
         * @event windowOnUserAuthenticated
         * @name windowOnUserAuthenticated
         * @description User Authenticated event: focus gallery if user is authenticated.
         */
        angular.element(window).on('user-authenticated', function() {
            $log.debug('% Signed-in. Ready to report the image.', gallery.TAG);
            gallery.userIsAuthenticated = apiConfig.userIsAuthenticated();
            $timeout(function() {
                gallery.actions.toggleReport(true);
                $timeout(function() {
                    angular.element(document.querySelector(gallery.css.selectors.textarea))[0].focus();
                });
            });
        });

        gallery.actions.load();
    }
})();
