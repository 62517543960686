(function() {
    'use strict';

    /**
     * @memberof Directive
     * @namespace SrpStars
     * @description Displays the average star rating and structured data markup of the first Find page results
     * @param {service}  searchConfigManager     For retrieving search details
     * @example
     * <srp-stars search-data="main.apidata" search-meta="main.metadata"></srp-stars>
     */

    angular
        .module('truelocal')
        .directive('srpStars', srpStars);

    /** @ngInject */
    function srpStars() {

        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/srpstars/srpstars.html',
            scope: {
                searchData: '=',
                searchMeta: '='
            },
            controller: SrpStarsController,
            bindToController: true,
            controllerAs: 'vm'
        };

        return directive;

        /**
         * @memberof Directive.SrpStars
         * @name SrpStarsController
         * @description Controller for this view
         * @param {Service} searchConfigManager
         */
        /** @ngInject */
        function SrpStarsController(searchConfigManager, constantsClasses, constantsSettings) {
            var vm = this;

            vm.showAggregateRating = showAggregateRating;
            vm.ratingClass = ratingClass;
            vm.ratingTypeClass = ratingTypeClass;
            vm.isAccommodation = isAccomodation;

            vm.searchType = searchConfigManager.getProperty('type');
            vm.ratingValue = 0;
            vm.ratingCount = 0;
            vm.reviewCount = 0;

            if (vm.searchData && vm.searchData.searchRating) {
                vm.ratingValue = vm.searchData.searchRating.ratingValue;
                vm.ratingCount = vm.searchData.searchRating.ratingCount;
                vm.reviewCount = vm.searchData.searchRating.reviewCount;
            }

            vm.ratingValueText = isValidNumber(vm.ratingValue) ? vm.ratingValue.toFixed(1) : '';
            vm.ratingCategory = searchConfigManager.getProperty('category').capitalizeSentence();
            vm.ratingDescription = vm.searchMeta.description;
            vm.ratingLocation;

            var suburb = searchConfigManager.getProperty('suburb');
            var state = searchConfigManager.getProperty('state');
            var region = searchConfigManager.getProperty('region');

            if(suburb) {
                vm.ratingLocation = suburb.capitalizeSentence() + (state ? ' ' + state.toUpperCase().trim() : '');
            }
            else if (region) {
                vm.ratingLocation = region.capitalizeSentence();
            }
            else if(state) {
                vm.ratingLocation = state.toUpperCase().trim();
            }else {
                vm.ratingLocation = 'Australia';
            }

            /**
             * @memberof Directive.SrpStars.SrpStarsController
             * @name isValidNumber
             * @description Checks whether the input number is valid or not
             * @returns {Boolean}
             */
            function isValidNumber(num) {
                return !isNaN(num) && !isNaN(parseFloat(num));
            }

            /**
             * @memberof Directive.SrpStars.SrpStarsController
             * @name showAggregateRating
             * @description Displays or hides the aggregateRating based on various criteria
             * @returns {Boolean}
             */
            function showAggregateRating() {
                return (vm.searchType === 'browse' && vm.reviewCount > 0 && vm.ratingValue >= 4);
            };

            /**
             * @memberof Directive.SrpStars.SrpStarsController
             * @name ratingClass
             * @description Display the individual rating
             * @param {Integer} rating
             * @returns {String} The css style
             */
            function ratingClass(rating) {
                if (vm.reviewCount == 0) {
                    if (vm.isAccommodation()) {
                        return constantsClasses.srpStars.iconCircle;
                    }
                    return constantsClasses.srpStars.iconStar;
                }
                if (vm.ratingValue >= (rating - 1 + 0.25)) {
                    if (vm.ratingValue >= (rating - 1 + 0.25) && vm.ratingValue <= (rating - 1 + 0.74)) {
                        if (vm.isAccommodation()) {
                            return constantsClasses.srpStars.activeIconCircleHalf;
                        }
                        return constantsClasses.srpStars.iconStarHalf;
                    } else if (vm.ratingValue > (rating - 1 + 0.74)) {
                        if (vm.isAccommodation()) {
                            return constantsClasses.srpStars.activeIconCircle;
                        }
                        return constantsClasses.srpStars.activeIconStar;
                    }
                }
                if (vm.isAccommodation()) {
                    return constantsClasses.srpStars.iconCircle;
                }
                return constantsClasses.srpStars.iconStar;
            }

            /**
             * @memberof Directive.SrpStars.SrpStarsController
             * @name ratingTypeClass
             * @description Show the rating type
             * @param {Integer} rating
             * @returns {String} The css style
             */
            function ratingTypeClass() {
                if (vm.isAccommodation()) {
                    return constantsClasses.srpStars.iconCircle2Star;
                }
                return constantsClasses.srpStars.iconRankArmyStar;
            };

            /**
             * @memberof Directive.SrpStars.SrpStarsController
             * @name isAccomodation
             * @description Check whether the category is an accomodation
             * @param {Integer} rating
             * @returns {Boolean}
             */
            function isAccomodation() {
                if (constantsSettings.categoriesWithTicks.indexOf(vm.ratingCategory) > -1) {
                    return true;
                }
                return false;
            };
        }
    }

})();