(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpReasonBox
     * @description Directive enable user to enter reason for reporting review or reporting comment.
     *
     * @param {constant}  constantsPlaceholders     Constant placeholders
     *
     * @example
     * <bdp-reason-box reason-text="[string]" is-comment="[boolean]" ng-if="vm.hideIfMine()"></bdp-reason-box>
     */
    .directive('bdpReasonBox', bdpReasonBox);

  /** @ngInject */
    function bdpReasonBox() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdpreasonbox/bdpreasonbox.html',
            scope: {
                reasonText: '=',
                isComment: '=',
            },
            controller: ['constantsPlaceholders', BdpReasonBoxController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /**
     * @memberof bdpReasonBox
     * @name bdpReasonBox_BdpReasonBoxController
     * @method bdpReasonBox_BdpReasonBoxController
     * @description Directive controller
     *
     * @param {constant}  constantsPlaceholders     Constant placeholders
     */

    /** @ngInject */
        function BdpReasonBoxController(constantsPlaceholders) {
            this.constantPlaceholders = constantsPlaceholders.bdpReasonBox;
            this.reasonText = '';
            this.numberOfCharactersLeft = this.constantPlaceholders.maxCharacters;


      /**
       * @memberof bdpReasonBox
       * @method getPlaceholderTitle
       * @name getPlaceholderTitle
       * @description Returns placeholder title, example: "Enter your reason for reporting this review" or this comment.
       * @returns {String} placeholderTitle
       */
            this.getPlaceholderTitle = function() {
                var _placeholder = (this.isComment) ? this.constantPlaceholders.placeholderComment : this.constantPlaceholders.placeholderReview;
                return _placeholder;
            };
        }
    }
})();
