(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name uriService
     * @description uriService provide uriEncode and uriDecode methods that are responsible to unify different uri
     * encodings format.
     */
    .factory('uriService', uriService);

  /** @ngInject */
    function uriService() {
        var service = {


      /**
       * @memberof uriService
       * @method uriEncode
       * @name uriEncode
       * @description Defoult URI encoding.
       */
            uriEncode: uriEncode,


      /**
       * @memberof uriService
       * @method uriDecode
       * @name uriDecode
       * @description Defoult URI decoding.
       */
            uriDecode: uriDecode,

            uriDecodeFind: uriDecodeFind,
        };

        function htmlEscape(str) {
            return String(str)
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
        }

        function htmlUnescape(value) {
            return String(value)
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, '\'')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&');
        }

        function uriEncode(_stringToEncode) {
            var _strBackup = htmlEscape(_stringToEncode);
            _strBackup = _strBackup.replace(/[\$]/g, 'and');
      // _strBackup = _strBackup.replace(/[(]/g,"%28");
      // _strBackup = _strBackup.replace(/[)]/g,"%29");
      // _strBackup = _strBackup.replace(/[#]/g,"%23");
      // _strBackup = _strBackup.replace(/[$]/g,"%24");
      // _strBackup = _strBackup.replace(/[,]/g,"%2C");
      // _strBackup = _strBackup.replace(/[']/g,"%27");
            _strBackup = _strBackup.replace(/[^a-zA-Z0-9\-\_\,]/g, ' ');
            _strBackup = _strBackup.trim();
            _strBackup = encodeURIComponent(_strBackup);
            _strBackup = _strBackup.replace(/\-/g, '_');
            _strBackup = _strBackup.replace(/%20/g, '-');

            return _strBackup;
        }

        function uriDecode(_stringToDecode) {
            var _strBackup = htmlUnescape(_stringToDecode);
            _strBackup = _strBackup.replace(/(and)]/g, '&');
            _strBackup = _strBackup.replace(/(\%28)/g, '(');
            _strBackup = _strBackup.replace(/(\%29)/g, ')');
            _strBackup = _strBackup.replace(/(\%23)/g, '#');
            _strBackup = _strBackup.replace(/(\%24)/g, '$');
            _strBackup = _strBackup.replace(/(\%2C)/g, ',');
            _strBackup = _strBackup.replace(/(\%27)/g, '\'');
            _strBackup = _strBackup.replace(/[^a-zA-Z0-9\@\+\%\_\-\s\.\,\$\#\',]/g, ' ');
            _strBackup = _strBackup.trim();
            _strBackup = decodeURIComponent(_strBackup);
            _strBackup = _strBackup.replace(/\-/g, ' ');
            _strBackup = _strBackup.replace(/_/g, '-');

            return _strBackup;
        }

        function uriDecodeFind(_stringToDecode) {
            var _strBackup = htmlUnescape(_stringToDecode);
            _strBackup = _strBackup.replace(/(and)]/g, '&');
            _strBackup = _strBackup.replace(/(\%28)/g, '(');
            _strBackup = _strBackup.replace(/(\%29)/g, ')');
            _strBackup = _strBackup.replace(/(\%23)/g, '#');
            _strBackup = _strBackup.replace(/(\%24)/g, '$');
            _strBackup = _strBackup.replace(/(\%2C)/g, ',');
            _strBackup = _strBackup.replace(/(\%27)/g, '\'');
            _strBackup = _strBackup.replace(/[^a-zA-Z\-\_\,]/g, ' ');
            _strBackup = _strBackup.trim();
            _strBackup = decodeURIComponent(_strBackup);
            _strBackup = _strBackup.replace(/\-/g, ' ');
            _strBackup = _strBackup.replace(/_/g, '-');

            return _strBackup;
        }

        return service;
    }
})();
