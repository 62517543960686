
(function() {
    'use strict';

  /**
   * @ngdoc module
   * @name truelocal
   * @description TrueLocal web, main application module.
   */
    angular
    .module('truelocal', [
      'ngAnimate',
      'ngCookies',
      'ngSanitize',
      'ngMessages',
      'ngAria',
      'ngResource',
      'ui.router',
      'ui.bootstrap',
      'ngFileUpload',
      'LocalStorageModule',
      'ng-fastclick',
      'swipe',
      'pasvaz.bindonce',
      'angular-loading-bar',
      'ng.shims.placeholder',
      'ngCapsLock',
      'userProfile',
      'login',
      'duScroll',
    ]);
})();


/**
 *  @memberof docs
 *  @ngdoc overview
 *  @name  Third_party_components
 *  @description
 *
 *  First off all we use Angular version 1.4.2
 *  All the componenents are in bower.json file.
 *
 *  Full description of each componenet:
 *
 *
 *  angular<br/>
 *  https://code.angularjs.org/1.4.2/docs/api<br/>
 *  The JS framework used.<br/>
 *
 *
 *  angular-animate<br/>
 *  https://code.angularjs.org/1.4.2/docs/guide/animations<br/>
 *  AngularJS provides animation hooks for common directives such as ngRepeat, ngSwitch, and ngView, as well as custom directives via the $animate service. These animation hooks are set in place to trigger animations during the life cycle of various directives and when triggered, will attempt to perform a CSS Transition, CSS Keyframe Animation or a JavaScript callback Animation (depending on if an animation is placed on the given directive). Animations can be placed using vanilla CSS by following the naming conventions set in place by AngularJS or with JavaScript code when it's defined as a factory.<br/>
 *  Animations are not available unless you include the ngAnimate module as a dependency within your application.<br/>
 *
 *
 *  angular-aria<br/>
 *  https://code.angularjs.org/1.4.2/docs/api/ngAria<br/>
 *  The ngAria module provides support for common ARIA attributes that convey state or semantic information about the application for users of assistive technologies, such as screen readers.<br/>
 *
 *
 *  angular-bootstrap<br/>
 *  https://angular-ui.github.io/bootstrap/<br/>
 *  Bootstrap components written in pure AngularJS<br/>
 *
 *
 *  angular-cookies<br/>
 *  https://code.angularjs.org/1.4.2/docs/api/ngCookies/service/$cookies<br/>
 *  Provides read/write access to browser's cookies.<br/>
 *
 *
 *  angular-local-storage<br/>
 *  https://github.com/grevory/angular-local-storage<br/>
 *  An Angular module that gives you access to the browsers local storage<br/>
 *
 *
 *  angular-messages<br/>
 *  https://code.angularjs.org/1.4.2/docs/api/ngMessages/directive/ngMessages<br/>
 *  ngMessages is a directive that is designed to show and hide messages based on the state of a key/value object that it listens on. The directive itself complements error message reporting with the ngModel $error object (which stores a key/value state of validation errors).<br/>
 *
 *
 *  angular-resource<br/>
 *  https://code.angularjs.org/1.4.2/docs/api/ngResource/service/$resource<br/>
 *  A factory which creates a resource object that lets you interact with RESTful server-side data sources.<br/>
 *
 *
 *  angular-sanitize<br/>
 *  https://code.angularjs.org/1.4.2/docs/api/ngSanitize/service/$sanitize<br/>
 *  The input is sanitized by parsing the HTML into tokens. All safe tokens (from a whitelist) are then serialized back to properly escaped html string. This means that no unsafe input can make it into the returned string, however, since our parser is more strict than a typical browser parser, it's possible that some obscure input, which would be recognized as valid HTML by a browser, won't make it through the sanitizer. The input may also contain SVG markup. The whitelist is configured using the functions aHrefSanitizationWhitelist and imgSrcSanitizationWhitelist of $compileProvider.<br/>
 *
 *
 *  angular-toastr<br/>
 *  https://github.com/Foxandxss/angular-toastr<br/>
 *  toastr is a Javascript library for non-blocking notifications. jQuery is required. The goal is to create a simple core library that can be customized and extended.<br/>
 *
 *
 *  animate.css<br/>
 *  https://github.com/daneden/animate.css<br/>
 *  animate.css is a bunch of cool, fun, and cross-browser animations for you to use in your projects. Great for emphasis, home pages, sliders, and general just-add-water-awesomeness.<br/>
 *
 *
 *  jquery<br/>
 *  https://jquery.com/<br/>
 *
 *
 *  moment<br/>
 *  http://momentjs.com/<br/>
 *  Parse, validate, manipulate, and display dates in JavaScript.<br/>
 *
 *
 *  moment-parseformat<br/>
 *  https://github.com/gr2m/moment-parseformat<br/>
 *  A moment.js plugin to extract the format of a date/time string<br/>
 *
 *
 *  ng-file-upload-shim<br/>
 *  https://github.com/danialfarid/ng-file-upload<br/>
 *  Lightweight Angular directive to upload files.<br/>
 *
 *  jQuery.dotdotdot<br/>
 *  http://dotdotdot.frebsite.nl/<br/>
 *  Used for truncation<br/>
 *
 *
 *  lodash<br/>
 *  https://lodash.com/<br/>
 *  A modern JavaScript utility library delivering modularity, performance, & extras.<br/>
 *
 *
 *  angular-ui-router<br/>
 *  https://angular-ui.github.io/ui-router/<br/>
 *  AngularUI Router is a routing framework for AngularJS, which allows you to organize the parts of your interface into a state machine. Unlike the $route service in Angular core, which is organized around URL routes, UI-Router is organized around states, which may optionally have routes, as well as other behavior, attached.<br/>
 *
 *
 *  markerclustererplus<br/>
 *  https://github.com/mahnunchik/markerclustererplus<br/>
 *  This is an enhanced version of the MarkerClusterer library for managing large amounts of markers. It adds support for several new properties as well as support for four more events. It also allows greater control over the styling of the text that appears on the cluster marker. The documentation has been significantly improved and the overall code has been simplified and polished. Very large numbers of markers can now be managed without causing Javascript timeout errors on Internet Explorer. It is backward compatible with MarkerClusterer.<br/>
 *
 *
 *  ng-fastclick<br/>
 *  https://github.com/8bitDesigner/ng-fastclick<br/>
 *  This is a very thin (3 line!) Angular wrapper around the FT's fantastic FastClick<br/>
 *
 *
 *  angular-swipe<br/>
 *  https://code.angularjs.org/1.4.2/docs/api/ngTouch/directive/ngSwipeLeft<br/>
 *  Specify custom behavior when an element is swiped to the left on a touchscreen device. A leftward swipe is a quick, right-to-left slide of the finger. Though ngSwipeLeft is designed for touch-based devices, it will work with a mouse click and drag too.<br/>
 *
 *
 *  angular-bindonce<br/>
 *  https://github.com/Pasvaz/bindonce<br/>
 *  High performance binding for AngularJs<br/>
 *
 *
 *  angular-loading-bar<br/>
 *  https://github.com/chieffancypants/angular-loading-bar<br/>
 *  Add a loading bar / progress bar whenever an XHR request goes out in angular. Multiple requests within the same time period get bundled together such that each response increments the progress bar by the appropriate amount.<br/>
 *
 *
 *  jquery-placeholder<br/>
 *  https://github.com/mathiasbynens/jquery-placeholder<br/>
 *  Workaround for IE9 for placeholders<br/>
 *
 *
 */


// Conventions followed throughout the code

/**
 *  @memberof docs
 *  @ngdoc overview
 *  @name  Conventions
 *  @description
 *  * Always use dependecy injection (will be an example bellow)
 *  * For local variables we try to use _ before name (eg: var _example)
 *  * For cross-directive data sharing we implement services
 *  * We try to split in small directives with specific functions and with posibility of reuse
 *
 *  @example
 *      .directive('exampleDirective', ['service','factory', exampleDirective]);
 *
 *      function exampleDirective(service,factory) {
 *
 *      }
 *
 *
 * */
