(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpPendingReviewComment
     * @description Directive handle commenting on user review that is still on pending status.
     *
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {constant}  constantsClasses          Constant classes
     *
     * @example
     * <bdp-pending-review-comment comment-item="[object]" review-item="[object]"></bdp-pending-review-comment>
     *
     */
    .directive('bdpPendingReviewComment', bdpPendingReviewComment);

  /** @ngInject */
    function bdpPendingReviewComment() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/reviews/bdppendingreviewcomment/bdppendingreviewcomment.html',
            scope: {
                commentItem: '=',
                reviewItem: '=',
                primaryProduct: '=',
            },
            controller: ['$modal', 'modalFactory', '$log', '$timeout', 'apiConfig', 'platformService', 'DTM', 'authService', 'constantsClasses', 'ModalLikeDislikeFactory', 'constantsApiErrorCodes', 'constantsPlaceholders', 'windowScroll', bdpPendingReviewCommentController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /**
     * @memberof bdpPendingReviewComment
     * @name bdpPendingReviewComment_bdpPendingReviewCommentController
     * @method bdpPendingReviewComment_bdpPendingReviewCommentController
     * @description Directive controller
     *
     * @param {service}   $log                      Angular console log wrapper
     * @param {service}   $timeout                  Angular window.setTimeout service
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {constant}  constantsClasses          Constant classes
     */

    /** @ngInject */
        function bdpPendingReviewCommentController($modal, modalFactory, $log, $timeout, apiConfig, platformService, DTM, authService, constantsClasses, ModalLikeDislikeFactory, constantsApiErrorCodes, constantsPlaceholders, windowScroll) {
            this.triggeredToolTipId = '';
      /**
       * @memberof bdpPendingReviewComment
       * @method userHasComment
       * @name userHasComment
       * @description Returns true if current user already added comment to review.
       * @returns {Boolean} userHasReview
       */
            this.userHasComment = function() {
                if (angular.isUndefined(this.commentItem) || !this.commentItem) {
                    return false;
                }

                return true;
            };

      /**
       * @memberof bdpPendingReviewComment
       * @method getUserAvatar
       * @name getUserAvatar
       * @description Get link to user avatar thumbnail, or empty string if it doesn't exist.
       * @returns {String} avatar url
       */
            this.getUserAvatar = function(reviewItm) {
                if(reviewItm.user) {
                    return reviewItm.user.avatars.image[0].urls.thumbnail;
                }
            };


      /**
       * @memberof bdpPendingReviewComment
       * @method getSpecialClass
       * @name getSpecialClass
       * @description Returns special class for case when user have added comment and that comment have
       * "Thank you" message .
       * @returns {String} specialClass
       */
            this.getSpecialClass = function() {
                if (angular.isUndefined(this.reviewItem) || !this.reviewItem) {
                    return '';
                }
                if (this.userHasComment() && this.reviewItem.addedCommentThankYouMessage) {
                    return constantsClasses.bdpPendingReviewComment.commentPending;
                }
                return '';
            };


      /**
       * @memberof bdpPendingReviewComment
       * @method getUserName
       * @name getUserName
       * @description Get current user display name.
       * @returns {String} userName
       */
            this.getUserName = function(reviewItm) {
                if(reviewItm.user) {
                    return reviewItm.user.displayName;
                }
            };

      /**
       * @memberof bdpPendingReviewComment
       * @method desktopHide
       * @name desktopHide
       * @description Return true for screen width > 768 and false if screen width < 768 and browser agent is mobile
       * @returns {bool} true/false
       */

            this.desktopHide = function() {
                if (platformService.isMobile() && platformService.getWidth() < 768) {
                    return false;
                }
                return true;
            };

      /**
       * @memberof bdpPendingReviewComment
       * @method isTouchDevice
       * @name isTouchDevice
       * @description Return true for screen width < 991 and false if screen width > 991 and browser agent is mobile
       * @returns {bool} true/false
       */

            this.isTouchDevice = function() {
                if (platformService.isMobilePlatform() && platformService.getWidth() < 991) {
                    return true;
                }
                return false;
            };

            this.showReport = function(_reviewItem) {
                if(apiConfig.userData) {
                    return _reviewItem.user.id != apiConfig.userData.data.data.id;
                }

                return true;
            };

      /**
       * @memberof bdpPendingReviewComment
       * @method triggerTooltip
       * @name triggerTooltip
       * @description show tooltip in mobiles and small devices
       */
            this.triggerTooltip = function(reviewId) {
                if(this.isTouchDevice()) {
                    if(this.triggeredToolTipId == reviewId) {
                        if(angular.element(document.querySelector('#tooltip-'+reviewId)).hasClass('hide')) {
                            angular.element(document.querySelector('#tooltip-'+reviewId)).removeClass('hide');
                        } else {
                            angular.element(document.querySelector('#tooltip-'+reviewId)).addClass('hide');
                        }
                    } else {
                        if(!angular.element(document.querySelector('#tooltip-'+this.triggeredToolTipId)).hasClass('hide')) {
                            angular.element(document.querySelector('#tooltip-'+this.triggeredToolTipId)).addClass('hide');
                        }
                        if(angular.element(document.querySelector('#tooltip-'+reviewId)).hasClass('hide')) {
                            angular.element(document.querySelector('#tooltip-'+reviewId)).removeClass('hide');
                        } 
                    }
                    this.triggeredToolTipId = reviewId;
                }
            };

            this.reportNow = function(_reviewItem) {
                if (angular.isUndefined(_reviewItem)) {
                    return false;
                }

                if (apiConfig.userIsAuthenticated()) {
                    angular.extend(_reviewItem, {expandedDropdownState: false, reportingReview: true});
                    if (_reviewItem.isActive) {
                        this.focusBlock(_reviewItem.id);
                    } else {
                        $modal.open({
                            templateUrl: '/app/components/bdp/reviews/bdpmodalreportpendingmoderation/bdpmodalreportpendingmoderation.html',
                            controller: ['_reviewItem', 'apiConfig', 'constantsPlaceholders', 'reviewsService', 'DTM', 'constantsApiErrorCodes', modalReportPendingModeration],
                            controllerAs: 'vm',
                            size: 'dialog_size_big',
                            resolve: {
                                _reviewItem: function() {
                                    return _reviewItem;
                                },
                            },
                        });
                    }
                } else {
                    var _modalInstance = $modal.open({
                        templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                        controller: 'ModalAuthPromptController',
                        controllerAs: 'vm',
                        size: 'dialog_size_small',
                    });
    
                    _modalInstance.opened.then(function() {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function() {
                        windowScroll.mobileModalCloseHandler();
                    });
                }
            };

      /**
       * @memberof bdpPendingReviewComment
       * @method trackingPreModReviewTooltip
       * @name trackingPreModReviewTooltip
       * @description tracker for pre-moderated review: params retrieve event to catch
       *
       */
            this.trackingPreModReviewTooltip = function(id) {
                DTM.trackAnalyticOnClick(id);
            };
        }
    }
})();
