(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsContactFax
     * @description Directive will display business formatted FAX number with fax icon if number is available.
     *
     * @example
     * <bdp-details-contact-fax contact="[object]"></bdp-details-contact-fax>
     *
     */
    .directive('bdpDetailsContactFax', bdpDetailsContactFax);

  /** @ngInject */
    function bdpDetailsContactFax() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetailscontactfax/bdpdetailscontactfax.html',
            scope: {
                contacts: '=',
            },
            controller: BdpDetailsContactFaxController,
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

    /** @ngInject */


    /**
     * @memberof bdpDetailsContactFax
     * @method bdpDetailsContactFax_BdpDetailsContactFaxController
     * @name bdpDetailsContactFax_BdpDetailsContactFaxController
     * @description BdpDetailsContactFaxController - controller for the directive
     */
        function BdpDetailsContactFaxController() {
            var vm = this;
            vm.haveFax = false;
            vm.faxNo = '';


      /**
       * @memberof bdpDetailsContactFax
       * @method getHaveFax
       * @name getHaveFax
       * @description Check does business have fax.
       * @param {Object} contact  Contact object from the listing object
       * @returns {Boolean} true/false
       */
            vm.getHaveFax = function(contact) {
                if (angular.isDefined(contact)) {
                    this.contacts.contact = contact;
                }
                if (angular.isDefined(this.contacts)) {
                    if (angular.isDefined(this.contacts.contact)) {
                        angular.forEach(this.contacts.contact, function(contact) {
                            if (contact.type == 'fax') {
                                vm.haveFax = true;
                            }
                        });
                    }
                }
                return vm.haveFax;
            };


      /**
       * @memberof bdpDetailsContactFax
       * @method getFaxNo
       * @name getFaxNo
       * @description Return business fax number.
       * @param {Object} contact Contact object from the listing object
       * @returns {String}  Fax Number
       */
            vm.getFaxNo = function(contact) {
                if (angular.isDefined(contact)) {
                    this.contacts.contact = contact;
                }
                if (angular.isDefined(this.contacts)) {
                    if (angular.isDefined(this.contacts.contact)) {
                        angular.forEach(this.contacts.contact, function(contact) {
                            if (contact.type == 'fax') {
                                vm.faxNo = contact.value;
                            }
                        });
                    }
                }
                return vm.formatNumber(vm.faxNo);
            };


      /**
       * @memberof bdpDetailsContactFax
       * @method formatNumber
       * @name formatNumber
       * @description Format phone number with spaces.
       * @param {String} _phoneNumber The raw phone number
       * @returns {String} _formattedPhoneNumber  Formated number
       */
            vm.formatNumber = function(_phoneNumber) {
                var _formattedPhoneNumber = '';
                if (angular.isUndefined(_phoneNumber) || !_phoneNumber) {
                    return '';
                }
                if (_phoneNumber.length == 10) {
                    if (_phoneNumber.startsWith('04') || _phoneNumber.startsWith('1300') ||
            _phoneNumber.startsWith('1800') || _phoneNumber.startsWith('1900')) {
                        _formattedPhoneNumber += _phoneNumber.substr(0, 4) + ' ' + _phoneNumber.substr(4, 3) + ' ' + _phoneNumber.substr(7, 3);
                    } else {
                        _formattedPhoneNumber += '(' + _phoneNumber.substr(0, 2) + ') ';
                        _formattedPhoneNumber += _phoneNumber.substr(2, 4) + ' ' + _phoneNumber.substr(6);
                    }
                } else if (_phoneNumber.length == 6) {
                    _formattedPhoneNumber += _phoneNumber.substr(0, 2) + ' ' + _phoneNumber.substr(2, 2) + ' ' + _phoneNumber.substr(4, 2);
                } else {
                    _formattedPhoneNumber = _phoneNumber;
                }
                return _formattedPhoneNumber;
            };
        }
    }
})();


