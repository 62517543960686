(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpDetailsAbnAcn
     * @description Directive will display ABN/ACN number in business contact details.
     */
    .directive('bdpDetailsAbnAcn', bdpDetailsAbnAcn);

  /** @ngInject */
    function bdpDetailsAbnAcn() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpdetailsabnacn/bdpdetailsabnacn.html',
            scope: {
                listing: '=',
            },
            controller: BdpDetailsAbnAcnController,
            controllerAs: 'vm',
            bindToController: true,
        };


        return directive;

    /** @ngInject */
    /**
     * @memberof truelocal
     * @method bdpDetailsAbnAcn_BdpDetailsAbnAcnController
     * @name bdpDetailsAbnAcn_BdpDetailsAbnAcnController
     * @description Controller for handling the methods for the directive
     */
        function BdpDetailsAbnAcnController() {
            var vm = this;
            vm.hasAbn = false;


      /**
       * @memberof dpDetailsAbnAcn
       * @method haveAbn
       * @name haveAbn
       * @description Check if current listing have ABN num.
       * @param {Object} listing  The business from api
       * @returns {Boolean} true/false
       */
            vm.haveAbn = function(listing) {
                if (angular.isDefined(listing)) {
                    this.listing = listing;
                }
                if (angular.isUndefined(this.listing)) {
                    vm.hasAbn = false;
                } else if (angular.isDefined(this.listing.abn)) {
                    if (this.listing.abn == '') {
                        vm.hasAbn = false;
                    } else {
                        vm.hasAbn = true;
                    }
                }
                return vm.hasAbn;
            };

            vm.abn = '';


      /**
       * @memberof dpDetailsAbnAcn
       * @method getAbn
       * @name getAbn
       * @description Get current ABN.
       * @param {Object} listing  The business from api
       * @returns {string}  The ABN
       */
            vm.getAbn = function(listing) {
                if (angular.isDefined(listing)) {
                    this.listing = listing;
                }
                if (vm.haveAbn(this.listing) == true) {
                    vm.abn = this.listing.abn;
                }

                return vm.abn;
            };

            vm.hasAcn = false;


      /**
       * @memberof dpDetailsAbnAcn
       * @method haveAcn
       * @name haveAcn
       * @description Check if current listing have ACN num.
       * @param {Object} listing  The business from api
       * @returns {Boolean} true/false
       */
            vm.haveAcn = function(listing) {
                if (angular.isDefined(listing)) {
                    this.listing = listing;
                }
                if (angular.isUndefined(this.listing) || angular.isUndefined(this.listing.acn) || this.listing.acn == '') {
                    vm.hasAcn = false;
                } else {
                    vm.hasAcn = true;
                }
                return vm.hasAcn;
            };

            vm.acn = '';


      /**
       * @memberof dpDetailsAbnAcn
       * @method getAcn
       * @name getAcn
       * @description Get current ACN.
       * @param {Object} listing  The business from api
       * @returns {string}  The ACN
       */
            vm.getAcn = function(listing) {
                if (angular.isDefined(listing)) {
                    this.listing = listing;
                }
                if (vm.haveAcn(this.listing) == true) {
                    vm.acn = this.listing.acn;
                }

                return vm.acn;
            };
        }
    }
})();


