(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc service
         * @name modalFactory
         *
         * @description modalFactory service provide methods for building custom modals.
         * NOTE: the factory is shared also as controller for the modal-login directive
         *
         * @param  {service}  $http                     angular http service
         * @param {service}   $log                      Angular console log wrapper
         * @param {factory}   authService               Factory what handle API request for authentication
         * @param {factory}   apiConfig                 Api configuration factory
         * @param {constant}  env                       Environmental constants
         * @param {factory}   DTM           TL tracking services
         * @param {service}   $location                  Angular window.location service wrapper
         * @param {service}   $rootScope                 Angular app root scope
         *
         *
         */
        .factory('modalFactory', ModalFactory)


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name modalLogin
         * @description Directive holds user login modal.
         * NOTE: the directive controller is shared also as factory
         *
         * @param  {service}  $http                     angular http service
         * @param {service}   $log                      Angular console log wrapper
         * @param {factory}   authService               Factory what handle API request for authentication
         * @param {factory}   apiConfig                 Api configuration factory
         * @param {constant}  env                       Environmental constants
         * @param {factory}   DTM           TL tracking services
         * @param {service}   $location                  Angular window.location service wrapper
         * @param {service}   $rootScope                 Angular app root scope
         *
         */
        .directive('modalLogin', ModalLogin)


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name equals
         * @description Attribute directive equals enable form element validation so that element can have exact
         * provided value.
         */
        .directive('equals', function() {
            return {
                restrict: 'A', // only activate on element attribute
                require: '?ngModel', // get a hold of NgModelController
                link: function(scope, elem, attrs, ngModel) {
                    if (!ngModel) return; // do nothing if no ng-model

                    // watch own value and re-validate on change
                    scope.$watch(attrs.ngModel, function() {
                        validate();
                    });

                    // observe the other value and re-validate on change
                    attrs.$observe('equals', function(val) {
                        validate();
                    });

                    var validate = function() {
                        // values
                        var val1 = ngModel.$viewValue;
                        var val2 = attrs.equals;

                        // set validity
                        ngModel.$setValidity('equals', !val1 || !val2 || val1 === val2);
                    };
                }
            };
        });

    /** @ngInject */
    function ModalFactory($http, $log, authService, apiConfig, env, DTM, $location, $rootScope, $timeout, $cookies, $localstorage) {


        function signInController(modalInstance, storedData, email, hash) {
            var vm = this;
            vm.initedJainRain = false;
            vm.email = '';
            vm.password = '';
            vm.signingIn = false;
            vm.recovering = false;
            vm.remember = false;
            vm.errorMessages = "";
            vm.errorMessagesRecover = "";
            vm.recover = false;
            vm.recovered = false;
            vm.recoverTypeNewPassword = false;
            vm.signupEmail = false;
            vm.requestError = false;
            vm.errorDescription = "";
            vm.quitSignup = false;
            vm.quitSignupEmail = false;

            vm.screenPasswordReset = false;
            vm.screenPasswordResetSuccessful = false;

            vm.userAvatar = (angular.isDefined($cookies.get("user_avatar"))) ? $cookies.get("user_avatar") : '';
            vm.userName = (angular.isDefined($cookies.get("welcome_name"))) ? $cookies.get("welcome_name") : '';
            vm.userProfileEmail = (angular.isDefined($cookies.get("welcome_mail"))) ? $cookies.get("welcome_mail") : '';

            vm.isReturningUser = (angular.isDefined($cookies.get("returning_user"))) ? $cookies.get("returning_user") : 'false'; //true either connected thru email or social-media (fb or g+)
            vm.isReturningSocialMedia = (angular.isDefined($cookies.get("login_tab"))) ? 'true' : 'false'; //'googleplus' returns true
            vm.isReturningMail = (vm.isReturningUser == 'true' && vm.isReturningSocialMedia == 'false') ? 'true' : 'false'; //true if is returning user from email

            vm.validationConfig = {
                displayName: {
                    min_length: 2,
                    max_length: 20,
                    required: true
                },
                password: {
                    min_length: 6,
                    required: true
                },
                confirmPassword: {
                    min_length: 6,
                    required: true
                }
            };

            vm.signinup = false;
            vm.socialRegister = false;
            vm.socialRegisterData = [];
            vm.displayNameError = false;
            vm.passwordFieldError = false;

            vm.resetPasswordError = "";

            /**
             * @memberof modalLogin
             * @name signIn
             * @method signIn
             * @description execute sign in
             */

            vm.signIn = function(loginform) {
                //vm.isReturningUser

                var usermail = (vm.isReturningMail == 'true') ? vm.userProfileEmail : vm.email;
                var _userCredentials;
                if (vm.remember === false) {
                    _userCredentials = {
                        email: usermail,
                        password: vm.password
                    };
                } else {
                    _userCredentials = {
                        email: usermail,
                        password: vm.password,
                        type: 'REMEMBER_ME'
                    };
                }


                vm.signingIn = true;
                vm.errorMessages = "";
                authService.signIn(_userCredentials).then(function(returned) {
                    angular.element(window).scrollTop = 0;
                    if (angular.isDefined(returned)) {
                        $log.debug(returned.data);
                        var messages = returned.data.meta.messages;
                        messages = messages.join('<br/>');
                        vm.errorMessages = messages;
                        vm.signingIn = false;
                        loginform.$setUntouched();
                    }
                });
            };

            vm.signupWithEmail = function() {
                vm.signupEmail = true;
            };

            vm.signUp = function(loginform) {
                vm.signingIn = true;

                var signUpDetails = {
                    displayName: vm.profilename,
                    email: vm.email,
                    password: vm.password,
                    confirmPassword: vm.confirmPassword,
                    acceptMarketing: vm.acceptMarketing
                };

                return $http.post(apiConfig.generate('auth', 'signupweb'), signUpDetails, apiConfig.getXAuthToken())
                    .then(signUpSuccess)
                    .catch(signUpfail);

                function signUpSuccess(response) {
                    vm.signingIn = false;
                    angular.element(document.querySelector('html')).removeClass('_menu-open');
                    angular.element(document.querySelector('body')).removeClass('modal-open');
                    angular.element(document.querySelector('body')).removeAttr('style');
                    angular.element(window).scrollTop = 0;
                    // Now sign in using same form details
                    var userLoginDetails = {
                        email: vm.email,
                        password: vm.password
                    };

                    var options = {
                        ignoreLoadingBar: true
                    };

                    // Sign in using signIn service
                    authService.signIn(userLoginDetails).then(function(returned) {
                        $rootScope.justSignedUp = true;
                    });

                }

                function signUpfail(error) {
                    vm.signingIn = false;
                    vm.signinup = false;
                    vm.requestError = true;

                    if (error.data.meta.errors.displayName) {
                        vm.errorDescription = error.data.meta.errors.displayName;
                    } else if (error.data.meta.errors.email) {
                        vm.errorDescription = error.data.meta.errors.email;
                    } else {
                        vm.errorDescription = "Sorry, we ran into an error. Try again shortly.";
                    }

                    angular.forEach(error.data.meta.errors, function(err, controll) {
                        if (controll == 'displayName') {
                            vm.displayNameErrorMessage = err;
                            vm.displayNameError = true;
                        } else if (controll == 'password') {
                            vm.passwordFieldErrorMessage = err;
                            vm.passwordFieldError = true;
                        }
                    });
                }

            };

            /**
             * @memberof modalLogin
             * @name getCurrentHeading
             * @method getCurrentHeading
             * @description return correct string depending time of the day
             */
            vm.getCurrentHeading = function() {
                var date = new Date();
                var labelHeading = '';
                if (date.getHours() >= 0 && date.getHours() < 12) {
                    labelHeading = "GOOD MORNING!";
                } else if (date.getHours() >= 12 && date.getHours() < 18) {
                    labelHeading = "GOOD AFTERNOON!";
                } else if (date.getHours() >= 18) {
                    labelHeading = "GOOD EVENING!";
                }
                return labelHeading;
            };

            /**
             * @memberof modalLogin
             * @name recoverPassword
             * @method recoverPassword
             * @description get recorver password view
             */

            vm.recoverPassword = function() {
                vm.errorMessagesRecover = '';
                vm.recovering = true;
                var user = {
                    email: vm.email
                };
                return $http.post(apiConfig.generate('auth', 'forgot-password'), user, apiConfig.getXAuthToken())
                    .then(function(response) {
                        vm.recovering = false;
                        vm.recovered = true;
                    })
                    .catch(function(error) {
                        vm.errorMessagesRecover = 'The email address couldn\'t be found. Please try again.';
                        vm.password = '';
                        vm.recovering = false;
                        vm.recovered = false;
                    });
            };

            vm.socialSignUp = function() {
                vm.signinup = true;
                vm.displayNameError = false;
                vm.passwordFieldError = false;
                var _data = {
                    socialString: vm.socialRegisterData.socialString,
                    displayName: vm.displayName,
                    password: 'TrueLocal',
                    confirmPassword: 'TrueLocal',
                    firstName: (angular.isDefined(vm.socialRegisterData.firstName)) ? vm.socialRegisterData.firstName : '',
                    lastName: (angular.isDefined(vm.socialRegisterData.lastName)) ? vm.socialRegisterData.lastName : '',
                    email: (angular.isDefined(vm.socialRegisterData.email)) ? vm.socialRegisterData.email : '',
                    gender: (angular.isDefined(vm.socialRegisterData.gender)) ? vm.socialRegisterData.gender : '',
                    dateOfBirth: (angular.isDefined(vm.socialRegisterData.dateOfBirth)) ? vm.socialRegisterData.dateOfBirth : ''
                };

                vm.displayName = _data.firstName + ' ' + _data.lastName;

                return $http.post(apiConfig.generate('auth', 'signupweb'), _data, apiConfig.getXAuthToken())
                    .then(getSignUpDetails)
                    .catch(getSignUpFailed);

                function getSignUpDetails(response) {
                    angular.element(window).triggerHandler('user-authenticated');
                    apiConfig.updateCredentials(response.headers('x-auth-token'));
                    vm.quitSignup = false;
                }

                function getSignUpFailed(error) {
                    vm.signinup = false;
                    angular.forEach(error.data.meta.errors, function(err, controll) {
                        if (controll == 'displayName') {
                            vm.displayNameErrorMessage = err;
                            vm.displayNameError = true;
                        } else if (controll == 'password') {
                            vm.passwordFieldErrorMessage = err;
                            vm.passwordFieldError = true;
                        }
                    });
                }
            };

            vm.socialSignUpProfileChange = function() {

                var socialRegisterData = $rootScope.socialRegisterData;
                var profileName = angular.element(document.querySelector('#profileName')).val();
                var dummyPassword = "password";

                var signUpDetails = {
                    socialString: socialRegisterData.socialString,
                    displayName: profileName,
                    password: dummyPassword,
                    confirmPassword: dummyPassword,
                    email: socialRegisterData.email
                };

                return $http.post(apiConfig.generate('auth', 'signupweb'), signUpDetails, apiConfig.getXAuthToken())
                    .then(getSignUpDetails)
                    .catch(getSignUpFailed);

                function getSignUpDetails(response) {
                    apiConfig.setToken(response.data.data.passToken);
                    apiConfig.updateCredentials(response.headers('x-auth-token'));
                    angular.element(window).scrollTop = 0;
                }

                function getSignUpFailed(error) {
                    vm.signinup = false;
                    vm.requestError = true;
                    if (error.data.meta.errors.displayName) {
                        vm.errorDescription = error.data.meta.errors.displayName;
                    } else if (error.data.meta.errors.email) {
                        vm.errorDescription = error.data.meta.errors.email;
                    } else {
                        vm.errorDescription = "Sorry, we ran into an error. Try again shortly.";
                    }

                    angular.forEach(error.data.meta.errors, function(err, controll) {
                        if (controll == 'displayName') {
                            vm.displayNameErrorMessage = err;
                            vm.displayNameError = true;
                        } else if (controll == 'password') {
                            vm.passwordFieldErrorMessage = err;
                            vm.passwordFieldError = true;
                        }
                    });
                }

                return socialRegisterData;

            };

            /**
             * @memberof modalLogin
             * @name loadSignInSocialWelcome
             * @method loadSignInSocialWelcome
             * @description load social media name and surname to input
             */
            vm.loadSignInSocialWelcome = function() {
                var firstName = (angular.isDefined(vm.socialRegisterData.firstName)) ? vm.socialRegisterData.firstName : '';
                var lastName = (angular.isDefined(vm.socialRegisterData.lastName)) ? vm.socialRegisterData.lastName : '';
                //console.log("load social media");
                vm.displayName = firstName + ' ' + lastName;
            };

            /**
             * @memberof modalLogin
             * @name switchForms
             * @method switchForms
             * @description close recover password
             */
            vm.switchForms = function() {
                vm.recovered = false;
                vm.email = '';
                vm.password = '';
                vm.errorMessages = "";
                vm.errorMessagesRecover = "";
                vm.recover = !vm.recover;
                return vm.recover;
            };

            /**
             * @memberof modalLogin
             * @name trackSignup
             * @method trackSignup
             * @description tracker for signup
             */

            vm.trackSignup = function(name) {
                DTM.trackAnalyticOnClick(name);
                $cookies.put('referrerUrl', $location.absUrl());
                return true;
            };

            vm.cancelSignIn = function() {
                modalInstance.dismiss('cancel');
                angular.element(window).scrollTop = 0;
                angular.element(document.querySelector('body')).css({ 'position': 'relative' });
            };

            /**
             * @memberof modalLogin
             * @name trackSignup
             * @method trackSignup
             * @description tracker for signup
             */
            vm.cancel = function() {
                if (vm.signupEmail) {
                    vm.signupEmail = false;
                    vm.quitSignupEmail = true;
                }
                vm.quitSignup = true;
            };

            vm.continueSignup = function() {
                if (vm.quitSignupEmail) {
                    vm.quitSignupEmail = false;
                    vm.signupEmail = true;
                }
                vm.quitSignup = false;
            };

            vm.abandonSignup = function() {
                modalInstance.dismiss('cancel');
                angular.element(window).scrollTop = 0;
            };

            /**
             * @memberof modalFactory
             * @name checkLogin
             * @method checkLogin
             * @description  check login
             */
            vm.checkLogin = function() {
                modalInstance.close(storedData);

                DTM.trackAnalyticOnClick('member login:completed');

                // If review has been submitted
                var reviewSubmitted = $localstorage.getItem('save_review_submitted');
                if (reviewSubmitted) {
                    // Trigger submit review button - Don't ask why it needs a timeout, im not sure
                    $localstorage.removeItem('save_review_submitted'); // this needed to avoid sending message 2 times
                    setTimeout(function() {
                        angular.element(document.querySelector('.submit-review')).triggerHandler('click');
                    });
                }
            };

            vm.getTokenUrl = function() {
                return env.socialAuth.tokenUrl;
            };


            vm.getAplicationId = function() {
                return env.socialAuth.tokenUrl.appId;
            };
            angular.element(window).bind('user-authenticated', vm.checkLogin);

            $rootScope.$on('$locationChangeStart', function() {
                modalInstance.dismiss('cancel');
            });

            /**
             * @memberof modalLogin
             * @name showResetPassword
             * @method showResetPassword
             * @description execute showResetPassword
             */

            vm.showResetPassword = function() {
                vm.screenPasswordReset = true;
                return vm.screenPasswordReset;
            };

            vm.cancelResetDialog = function() {
                modalInstance.dismiss('cancel');
                angular.element(window).scrollTop = 0;
            };

            /**
             * @memberof modalLogin
             * @name resetPassword
             * @method resetPassword
             * @description execute resetPassword
             */

            vm.resetPassword = function() {
                vm.resetPasswordError = "";
                var loginByHashRequest = {
                    email: email,
                    hash: hash,
                    type: 'FORGOT_PASSWORD'
                };

                $http.post(apiConfig.generate('auth', 'login'), loginByHashRequest, apiConfig.getXAuthToken())
                    .then(function(response) {
                        var resetPwdRequest = {
                            newPassword: vm.password,
                            passwordRetype: vm.confirmPassword
                        };
                        apiConfig.setToken(response.data.data.passToken);
                        apiConfig.updateCredentials(response.headers('x-auth-token'));

                        return $http.post(apiConfig.generate('auth', 'reset-password'), resetPwdRequest, apiConfig.getXAuthToken())
                            .then(function(response) {
                                vm.screenPasswordReset = false;
                                vm.screenPasswordResetSuccessful = true;
                                return vm.screenPasswordResetSuccessful;
                            })
                            .catch(function(error) {
                                vm.resetPasswordError = 'Reset password failed. Please try again.';
                            });
                    })
                    .catch(function(error) {
                        vm.resetPasswordError = 'Link expired. Request a new reset password link.';
                    });
            };

            /**
             * @memberof modalLogin
             * @name loginScreen
             * @method loginScreen
             * @description execute loginScreen
             */
            vm.toLoginScreen = function() {
                vm.isReturningUser = 'false';
                vm.isReturningMail = 'false';
                vm.isReturningSocialMedia = 'false';
            };

            /**
             * @memberof modalFactory
             * @name switchModal
             * @method switchModal
             * @description execute switchModal
             */
            vm.switchModal = function(modal) {
                // Close the modal
                modalInstance.dismiss('cancel');

                // Needs timeout for modal to close
                setTimeout(function() {
                    // Trigger the next button click by id - I know, not nice but the code needs to be completely refactored to get this working properly!
                    angular.element(document.querySelector(modal)).triggerHandler('click');
                }, 500);
            };

            vm.backToSignUpScreen = function() {
                vm.signupEmail = false;
            };

            vm.resetInput = function(input, loginform) {
                if(input === 'email') {
                    loginform.email.$touched = false;
                }
                if(input === 'password') {
                    loginform.password.$touched = false;
                }
            }

        }

        return signInController;
    }

    /** @ngInject */
    function ModalLogin() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/modalLogin/modallogin.html',
            scope: {
                login: '='
            },
            controller: ModalFactory,
            controllerAs: 'vm',
            bindToController: true
        };
        return directive;
    }
})();
