(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name tlCheckBox
         * @description custom checkbox according to designs
         *
         * @example
         * <tl-check-box checekd="[2way-binding:boolean]" error="[2way-binding:boolean]" caption="[string]"></tl-check-box>
         */
        .directive('tlCheckBox', homeTabs);


    /** @ngInject */
    function homeTabs() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/shared/tlcheckbox/tlcheckbox.html',
            scope: {
                checked: '=?',
                error: '=?',
                caption: '@',
            },
            controller: TlCheckBoxController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        function TlCheckBoxController() {
            var vm = this;

            /**
             * @memberof  tlCheckBox
             * @method clickCheckbox
             * @name clickCheckbox
             * @description Action on click - reverse the value of checked, and if checked is true the error becomes false.
             */
            vm.clickCheckbox = function() {
                vm.checked = !vm.checked;
                if(vm.checked == true) {
                    vm.error = false;
                }
            };
        }
    }
})();
