(function() {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc service
         * @name swear
         * @description swear service provide collection of blocked words to enable client side user's input validation.
         */
        .service('swear', swearService);

    /** @ngInject */
    function swearService() {
        var swear = {
            collection: [
                '4r5e',
                '5h1t',
                '5hit',
                'ass',
                'a55',
                'anal',
                'anus',
                'ar5e',
                'arrse',
                'arse',
                'ass',
                'ass-fucker',
                'asses',
                'assfucker',
                'assfukka',
                'asshole',
                'assholes',
                'asswhole',
                'a_s_s',
                'b!tch',
                'b00bs',
                'b17ch',
                'b1tch',
                'ballbag',
                'ballsack',
                'bastard',
                'beastial',
                'beastiality',
                'bellend',
                'bestial',
                'bestiality',
                'bi+ch',
                'biatch',
                'bitch',
                'bitcher',
                'bitchers',
                'bitches',
                'bitchin',
                'bitching',
                'bloody',
                'blow job',
                'blowjob',
                'blowjobs',
                'boiolas',
                'bollock',
                'bollok',
                'boner',
                'boob',
                'boobs',
                'booobs',
                'boooobs',
                'booooobs',
                'booooooobs',
                'breasts',
                'buceta',
                'bugger',
                'bum',
                'bunny fucker',
                'butt',
                'butthole',
                'buttmuch',
                'buttplug',
                'c0ck',
                'c0cksucker',
                'carpet muncher',
                'cawk',
                'chink',
                'cipa',
                'cl1t',
                'clit',
                'clitoris',
                'clits',
                'cnut',
                'cock',
                'cock-sucker',
                'cockface',
                'cockhead',
                'cockmunch',
                'cockmuncher',
                'cocks',
                'cocksuck',
                'cocksucked',
                'cocksucker',
                'cocksucking',
                'cocksucks',
                'cocksuka',
                'cocksukka',
                'cok',
                'cokmuncher',
                'coksucka',
                'coon',
                'crap',
                'cum',
                'cummer',
                'cumming',
                'cums',
                'cumshot',
                'cunilingus',
                'cunillingus',
                'cunnilingus',
                'cunt',
                'cuntlick',
                'cuntlicker',
                'cuntlicking',
                'cunts',
                'cyalis',
                'cyberfuc',
                'cyberfuck',
                'cyberfucked',
                'cyberfucker',
                'cyberfuckers',
                'cyberfucking',
                'd1ck',
                'damn',
                'dick',
                'dickhead',
                'dildo',
                'dildos',
                'dink',
                'dinks',
                'dirsa',
                'dlck',
                'dog-fucker',
                'doggin',
                'dogging',
                'donkeyribber',
                'doosh',
                'duche',
                'dyke',
                'ejaculate',
                'ejaculated',
                'ejaculates',
                'ejaculating',
                'ejaculatings',
                'ejaculation',
                'ejakulate',
                'f u c k',
                'f u c k e r',
                'f4nny',
                'fag',
                'fagging',
                'faggitt',
                'faggot',
                'faggs',
                'fagot',
                'fagots',
                'fags',
                'fanny',
                'fannyflaps',
                'fannyfucker',
                'fanyy',
                'fatass',
                'fcuk',
                'fcuker',
                'fcuking',
                'feck',
                'fecker',
                'felching',
                'fellate',
                'fellatio',
                'fingerfuck',
                'fingerfucked',
                'fingerfucker',
                'fingerfuckers',
                'fingerfucking',
                'fingerfucks',
                'fistfuck',
                'fistfucked',
                'fistfucker',
                'fistfuckers',
                'fistfucking',
                'fistfuckings',
                'fistfucks',
                'flange',
                'fook',
                'fooker',
                'fuck',
                'fucka',
                'fucked',
                'fucker',
                'fuckers',
                'fuckhead',
                'fuckheads',
                'fuckin',
                'fucking',
                'fuckings',
                'fuckingshitmotherfucker',
                'fuckme',
                'fucks',
                'fuckwhit',
                'fuckwit',
                'fudge packer',
                'fudgepacker',
                'fuk',
                'fuker',
                'fukker',
                'fukkin',
                'fuks',
                'fukwhit',
                'fukwit',
                'fux',
                'fux0r',
                'f_u_c_k',
                'gangbang',
                'gangbanged',
                'gangbangs',
                'gaylord',
                'gaysex',
                'goatse',
                'God',
                'god-dam',
                'god-damned',
                'goddamn',
                'goddamned',
                'hardcoresex',
                'hell',
                'heshe',
                'hoar',
                'hoare',
                'hoer',
                'homo',
                'hore',
                'horniest',
                'horny',
                'hotsex',
                'jack-off',
                'jackoff',
                'jap',
                'jerk-off',
                'jism',
                'jiz',
                'jizm',
                'jizz',
                'kawk',
                'knob',
                'knobead',
                'knobed',
                'knobend',
                'knobhead',
                'knobjocky',
                'knobjokey',
                'kock',
                'kondum',
                'kondums',
                'kum',
                'kummer',
                'kumming',
                'kums',
                'kunilingus',
                'l3i+ch',
                'l3itch',
                'labia',
                'lust',
                'lusting',
                'm0f0',
                'm0fo',
                'm45terbate',
                'ma5terb8',
                'ma5terbate',
                'masochist',
                'master-bate',
                'masterb8',
                'masterbat*',
                'masterbat3',
                'masterbate',
                'masterbation',
                'masterbations',
                'masturbate',
                'mo-fo',
                'mof0',
                'mofo',
                'mothafuck',
                'mothafucka',
                'mothafuckas',
                'mothafuckaz',
                'mothafucked',
                'mothafucker',
                'mothafuckers',
                'mothafuckin',
                'mothafucking',
                'mothafuckings',
                'mothafucks',
                'mother fucker',
                'motherfuck',
                'motherfucked',
                'motherfucker',
                'motherfuckers',
                'motherfuckin',
                'motherfucking',
                'motherfuckings',
                'motherfuckka',
                'motherfucks',
                'muff',
                'mutha',
                'muthafecker',
                'muthafuckker',
                'muther',
                'mutherfucker',
                'n1gga',
                'n1gger',
                'nazi',
                'nigg3r',
                'nigg4h',
                'nigga',
                'niggah',
                'niggas',
                'niggaz',
                'nigger',
                'niggers',
                'nob',
                'nob jokey',
                'nobhead',
                'nobjocky',
                'nobjokey',
                'numbnuts',
                'nutsack',
                'orgasim',
                'orgasims',
                'orgasm',
                'orgasms',
                'p0rn',
                'pawn',
                'pecker',
                'penis',
                'penisfucker',
                'phonesex',
                'phuck',
                'phuk',
                'phuked',
                'phuking',
                'phukked',
                'phukking',
                'phuks',
                'phuq',
                'pigfucker',
                'pimpis',
                'piss',
                'pissed',
                'pisser',
                'pissers',
                'pisses',
                'pissflaps',
                'pissin',
                'pissing',
                'pissoff',
                'poop',
                'porn',
                'porno',
                'pornography',
                'pornos',
                'prick',
                'pricks',
                'pron',
                'pube',
                'pusse',
                'pussi',
                'pussies',
                'pussy',
                'pussys',
                'rectum',
                'retard',
                'rimjaw',
                'rimming',
                's hit',
                's.o.b.',
                'sadist',
                'schlong',
                'screwing',
                'scroat',
                'scrote',
                'scrotum',
                'semen',
                'sex',
                'sh!+',
                'sh!t',
                'sh1t',
                'shag',
                'shagger',
                'shaggin',
                'shagging',
                'shemale',
                'shi+',
                'shit',
                'shitdick',
                'shite',
                'shited',
                'shitey',
                'shitfuck',
                'shitfull',
                'shithead',
                'shiting',
                'shitings',
                'shits',
                'shitted',
                'shitter',
                'shitters',
                'shitting',
                'shittings',
                'shitty',
                'skank',
                'slut',
                'sluts',
                'smegma',
                'smut',
                'snatch',
                'son-of-a-bitch',
                'spac',
                'spunk',
                's_h_i_t',
                't1tt1e5',
                't1tties',
                'teets',
                'teez',
                'testical',
                'testicle',
                'tit',
                'titfuck',
                'tits',
                'titt',
                'tittie5',
                'tittiefucker',
                'titties',
                'tittyfuck',
                'tittywank',
                'titwank',
                'tosser',
                'turd',
                'tw4t',
                'twat',
                'twathead',
                'twatty',
                'twunt',
                'twunter',
                'v14gra',
                'v1gra',
                'vagina',
                'viagra',
                'vulva',
                'w00se',
                'wang',
                'wank',
                'wanker',
                'wanky',
                'whoar',
                'whore',
                'willies',
                'willy',
                'xrated',
                'xxx',

                'anus',
                'arse',
                'arsehole',
                'ass',
                'ass-hat',
                'ass-jabber',
                'ass-pirate',
                'assbag',
                'assbandit',
                'assbanger',
                'assbite',
                'assclown',
                'asscock',
                'asscracker',
                'asses',
                'assface',
                'assfuck',
                'assfucker',
                'assgoblin',
                'asshat',
                'asshead',
                'asshole',
                'asshopper',
                'assjacker',
                'asslick',
                'asslicker',
                'assmonkey',
                'assmunch',
                'assmuncher',
                'assnigger',
                'asspirate',
                'assshit',
                'assshole',
                'asssucker',
                'asswad',
                'asswipe',
                'axwound',
                'bampot',
                'bastard',
                'beaner',
                'bitch',
                'bitchass',
                'bitches',
                'bitchtits',
                'bitchy',
                'blowjob',
                'bollocks',
                'bollox',
                'boner',
                'brotherfucker',
                'bullshit',
                'bumblefuck',
                'buttplug',
                'butt-plug',
                'butt-pirate',
                'buttpirate',
                'buttfucka',
                'buttfucker',
                'cameltoe',
                'camel-toe',
                'carpetmuncher',
                'chesticle',
                'chinc',
                'chink',
                'choad',
                'chode',
                'clit',
                'clitface',
                'clitfuck',
                'clusterfuck',
                'cock',
                'cockass',
                'cockbite',
                'cockburger',
                'cockface',
                'cockfucker',
                'cockhead',
                'cockjockey',
                'cockknoker',
                'cockmaster',
                'cockmongler',
                'cockmongruel',
                'cockmonkey',
                'cockmuncher',
                'cocknose',
                'cocknugget',
                'cockshit',
                'cocksmith',
                'cocksmoke',
                'cocksmoker',
                'cocksniffer',
                'cocksucker',
                'cockwaffle',
                'coochie',
                'coochy',
                'coon',
                'cooter',
                'cracker',
                'cum',
                'cumbubble',
                'cumdumpster',
                'cumguzzler',
                'cumjockey',
                'cumslut',
                'cumtart',
                'cunnie',
                'cunnilingus',
                'cunt',
                'cuntass',
                'cuntface',
                'cunthole',
                'cuntlicker',
                'cuntrag',
                'cuntslut',
                'dago',
                'damn',
                'deggo',
                'dick',
                'dick-sneeze',
                'dicksneeze',
                'dickbag',
                'dickbeaters',
                'dickface',
                'dickfuck',
                'dickfucker',
                'dickhead',
                'dickhole',
                'dickjuice',
                'dickmilk',
                'dickmonger',
                'dicks',
                'dickslap',
                'dicksucker',
                'dicksucking',
                'dicktickler',
                'dickwad',
                'dickweasel',
                'dickweed',
                'dickwod',
                'dike',
                'dildo',
                'dipshit',
                'doochbag',
                'dookie',
                'douche',
                'douche-fag',
                'douchebag',
                'douchewaffle',
                'dumass',
                'dumb-ass',
                'dumbass',
                'dumbfuck',
                'dumbshit',
                'dumshit',
                'dyke',
                'fag',
                'fagbag',
                'fagfucker',
                'faggit',
                'faggot',
                'faggotcock',
                'fagtard',
                'fatass',
                'fellatio',
                'feltch',
                'flamer',
                'fuck',
                'fuckass',
                'fuckbag',
                'fuckboy',
                'fuckbrain',
                'fuckbutt',
                'fuckbutter',
                'fucked',
                'fucker',
                'fuckersucker',
                'fuckface',
                'fuckhead',
                'fuckhole',
                'fuckin',
                'fucking',
                'fucknut',
                'fucknutt',
                'fuckoff',
                'fucks',
                'fuckstick',
                'fucktard',
                'fucktart',
                'fuckup',
                'fuckwad',
                'fuckwit',
                'fuckwitt',
                'fudgepacker',
                'gayass',
                'gaybob',
                'gaydo',
                'gayfuck',
                'gayfuckist',
                'gaylord',
                'gaytard',
                'gaywad',
                'goddamn',
                'goddamnit',
                'gooch',
                'gook',
                'gringo',
                'guido',
                'handjob',
                'hardon',
                'hard-on',
                'heeb',
                'hell',
                'ho',
                'hoe',
                'homo',
                'homodumbshit',
                'honkey',
                'humping',
                'jackass',
                'jagoff',
                'jap',
                'jerkoff',
                'jerk-off',
                'jerkass',
                'jigaboo',
                'jizz',
                'jungle-bunny',
                'junglebunny',
                'kike',
                'kooch',
                'kootch',
                'kraut',
                'kunt',
                'kyke',
                'lameass',
                'lardass',
                'lesbian',
                'lesbo',
                'lezzie',
                'mcfagget',
                'minge',
                'mothafucka',
                'mothafuckin',
                'motherfucker',
                'motherfucking',
                'muff',
                'muffdiver',
                'munging',
                'negro',
                'nigaboo',
                'nigga',
                'nigger',
                'niggers',
                'niglet',
                'nut-sack',
                'nutsack',
                'paki',
                'panooch',
                'pecker',
                'peckerhead',
                'penis',
                'penisbanger',
                'penisfucker',
                'penispuffer',
                'piss',
                'pissed',
                'pissedoff',
                'pissed-off',
                'pissflaps',
                'polesmoker',
                'pollock',
                'poon',
                'poonani',
                'poonany',
                'poontang',
                'porch-monkey',
                'porchmonkey',
                'prick',
                'punanny',
                'punta',
                'pussies',
                'pussy',
                'pussylicking',
                'puto',
                'queef',
                'queer',
                'queerbait',
                'queerhole',
                'renob',
                'rimjob',
                'ruski',
                'sand-nigger',
                'sandnigger',
                'schlong',
                'scrote',
                'shit',
                'shitass',
                'shitbag',
                'shitbagger',
                'shitbrains',
                'shitbreath',
                'shitcanned',
                'shitcunt',
                'shitdick',
                'shitface',
                'shitfaced',
                'shithead',
                'shithole',
                'shithouse',
                'shitspitter',
                'shitstain',
                'shitter',
                'shittiest',
                'shitting',
                'shitty',
                'shiz',
                'shiznit',
                'skank',
                'skeet',
                'skullfuck',
                'slut',
                'slutbag',
                'smeg',
                'snatch',
                'spic',
                'spick',
                'splooge',
                'spook',
                'suckass',
                'tard',
                'testicle',
                'thundercunt',
                'tit',
                'titfuck',
                'tits',
                'tittyfuck',
                'twat',
                'twatlips',
                'twats',
                'twatwaffle',
                'unclefucker',
                'va-j-j',
                'vag',
                'vagina',
                'vajayjay',
                'vjayjay',
                'wank',
                'wankjob',
                'wetback',
                'whore',
                'whorebag',
                'whoreface',
                'wop',
            ],
        };

        swear.similarSymbols = {
            t: ['\\+', '7'],
            a: ['\\@'],
            s: ['5', '\\$'],
            i: ['1', '\\!'],
            o: ['0'],
        };

        swear.replaceSymbols = function(string) {
            if(angular.isUndefined(string) || string == '') return '';
            angular.forEach(swear.similarSymbols, function(val, key) {
                string = string.replace(new RegExp(val.join('|'), 'igm'), key);
            });
            return string.replace(/ /igm);
        };

        for(var j = 0; j < swear.collection.length; j++) {
            swear.collection[j] = swear.replaceSymbols(swear.collection[j]);
        }

        swear.hasSwearing = function(string) {
            if(angular.isUndefined(string) || string == '') return false;
            var wordArr = string.split(/[ ,.]/);
            for(var i = 0; i < wordArr.length; i++) {
                if(wordArr[i].length > 0) {
                    var word = swear.replaceSymbols(wordArr[i].toLowerCase());
                    if(swear.collection.indexOf(word) != -1) return true;
                }
            }
            return false;
        };
        return swear;
    }
})();

