(function() {
  'use strict';

  angular.module('truelocal')
    .factory('categoriesModel', function(){
      var categories = [];

      function getCategories(){
          return categories;
      }

      function setCategories(categoryList){
        categories = categoryList;
      }

      function getProcessedCategories(){
          var processedCategories = [];
          for (var i = 0; i < categories.length; i++) {
              if (!categories[i].active) continue;
              processedCategories.push({
                    name: categories[i].description,
                    icon: categories[i].seoAlias,
                    isOpened: false,
                    subcategories: (function processSubcategories(rawSubcategories) {
                        var subs = [];
                        for (var i = 0; i < rawSubcategories.length; i++) {
                            if (!rawSubcategories[i].active) continue;
                            subs.push({
                                          name: rawSubcategories[i].description,
                                          link: rawSubcategories[i].hrefForFind
                                      });
                        }
                        return subs;
                    })(categories[i].subCategories.subCategory || [])
                });
          }
          return processedCategories;
      }

      //TODO: For any manipulation of categories list create functions here

      return {
          getCategories:getCategories,
          setCategories:setCategories,
          getProcessedCategories:getProcessedCategories
      }
  });
})();
