(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name errorService
     * @description errorService will handle and get error messages based on different scenarios and return the right error
     */
    .factory('errorService', errorService);

  /** @ngInject */
    function errorService() {
        var service = {
            getErrorMessage: getErrorMessage,
        };


        function getErrorMessage(response) {
            var error='';
            if(response._has('data.meta.errors')) {
                if(response.data.meta.errors.length==1) {
                    error=response.data.meta.errors[0];
                }else{
                    error=JSON.stringify(response.data.meta.errors);
                }
            }else if(response._has('data.meta.messages')) {
                if(response.data.meta.messages.length==1) {
                    error=response.data.meta.messages[0];
                }else{
                    error=JSON.stringify(response.data.meta.messages);
                }
            }
            return error;
        }

        return service;
    }
})();
