(function () {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc service
         * @name serviceListing
         * @description serviceListing provides methods that can be done on current business listing. Since processing
         * on listing is necessary before data can be used in application, it should be accessed trough this services.
         */
        .factory('serviceListing', serviceListing);

    /** @ngInject */
    function serviceListing($log, $http, $modal, $location, $timeout, apiConfig, listingModels, ModalLikeDislikeFactory,
                            windowScroll, DTM, $window, findSearchCategories, $rootScope, $document) {
        var service = {
            getListing: getListing,
            getNextReviews: getNextReviews,
            getSimilarListings: getSimilarListings,
            getOtherNearbyListings: getOtherNearbyListings,
            getSortedReviews: getSortedReviews,
            addReview: addReview,
            listingSearch: listingSearch,
            searchListingNextBlock: searchListingNextBlock,
            searchListingPrevBlock: searchListingPrevBlock,
        };

        /**
         * @memberof serviceListing
         * @method getListing
         * @name getListing
         * @description Gets listing result.
         * If data is set then data will be populated with the result,
         * if data is not set data then we will just get the promise.
         * @param {String} id
         * @param {Object} data
         * @param {String} metadata
         * @param {Boolean} notfound
         * @returns {Object} listingData
         */
        function getListing(id, data, metadata, notfound) {
            if (angular.isDefined(id)) {
                return $http.get(apiConfig.generate('listings', id), apiConfig.getXAuthToken())
                    .then(getListingComplete)
                    .catch(getListingFailed);
            }

            function getListingComplete(response) {
                var _data = response.data.data.listing[0];
                var _metadata = response.data.meta;
                apiConfig.setListing(_data);
	
	            windowScroll.readAnchors(); //This is to bind a function on renderComplete
                
                $log.debug(_data);
                $log.debug(_metadata);
                if (typeof data != 'undefined') {
                    _data.bussinessDetails = listingModels.businessDetailHeader(_data);
                    _data.bussinessJSON = angular.toJson(_data.bussinessDetails, true);
                    $log.debug(_data.bussinessDetails);
                    angular.copy(_data, data);
                    angular.copy(_metadata, metadata);
                } else {
                    return response;
                }
            }


            function getListingFailed(error) {
                var message = '';
                removeCanonicalHeader();
                if (error.status == '500') {
                    message = 'XHR Failed for getListing ';
                } else if (error.status == '404') {
                    message = 'XHR NotFound for getListing ';
                }
                message = message + id + '\n' + angular.toJson(error.data, true);

                var _notfound = true;
                angular.copy(_notfound, notfound);
                $log.debug(message);
                if (typeof data != 'undefined') {
                    data.call(this, {'error': message}, 0);
                }
            }
        }

        /**
         * @memberof serviceListing
         * @method getNextReviews
         * @name getNextReviews
         * @description Load next listing reviews.
         * @param {String} url
         * @param {Object} data
         * @returns {Object} nextReviews
         */
        function getNextReviews(url, data) {
            return $http.get(apiConfig.hasUrl(url), apiConfig.getXAuthToken())
                .then(getReviewsComplete)
                .catch(getReviewsFailed);

            function getReviewsComplete(response) {
                var _data = response.data.data;
                var _metadata = response.data.meta;
                $log.debug(_data);
                $log.debug(_metadata);
                if (typeof data != 'undefined') {
                    var _blockModel = listingModels.getNextReviewBlock(_data);
                    if (data.reviewList && _blockModel.reviewList && _blockModel.reviewList.length) {
                        for (var i = 0, l = _blockModel.reviewList.length; i < l; i++) {
                            data.reviewList.push(_blockModel.reviewList[i]);
                        }
                    }

                    delete _blockModel.reviewList;
                    if(!data.reviewsData.actions){    data.reviewsData.actions={};}
                    angular.extend(data.reviewsData.actions, _blockModel.reviewsData.actions);
                    data.reviewsData.href = _blockModel.reviewsData.href;
                    data.reviewsData.limit = _blockModel.reviewsData.limit;
                    data.reviewsData.offset = _blockModel.reviewsData.offset;
                    data.reviewsData.order = _blockModel.reviewsData.order;
                    data.reviewsData.sort = _blockModel.reviewsData.sort;
                    data.reviewsNext = _blockModel.reviewsNext;
                    _data.bussinessJSON = angular.toJson(_data, true);
                    $log.debug(data);
                } else {
                    return _data;
                }
            }


            function getReviewsFailed(error) {
                var message = '';
                if (error.status == '500') {
                    message = 'XHR Failed for getListing ';
                } else if (error.status == '404') {
                    message = 'XHR NotFound for getListing ';
                }
                message = message + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
                if (typeof data != 'undefined') {
                    data.call(this, {'error': message}, 0);
                }
            }
        }

        /**
         * @memberof serviceListing
         * @method getSimilarListings
         * @name getSimilarListings
         * @description Get similar listings base on the id of the listing currently processed.
         * @param {String} id
         * @param {Object} data
         * @returns {Object} similarListings
         */
        function getSimilarListings(id, data) {
            return $http.get(apiConfig.generate('listings', id, 'nearby-listings'), {ignoreLoadingBar: true},
                             apiConfig.getXAuthToken())
                .then(getSimilarListingComplete)
                .catch(getSimilarListingFailed);

            // parse the data with callback to the main corespondent variable; in this case vm.similarListings
            function getSimilarListingComplete(response) {
                var _data = response.data;
                $log.debug(_data);

                if (typeof data != 'undefined') {
                    angular.copy(_data, data);
                } else {
                    return _data;
                }
            }

      // trow error
            function getSimilarListingFailed(error) {
                var message = '';
                if (error.status == '500') {
                    message = 'XHR Failed for getListing ';
                } else if (error.status == '404') {
                    message = 'XHR NotFound for getListing ';
                }
                message = message + id + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
                if (typeof data != 'undefined' && typeof data.call == 'function') {
                    data.call(this, {'error': message}, 0);
                }
            }
        }

        /**
         * @memberof serviceListing
         * @method getOtherNearbyListings
         * @name getOtherNearbyListings
         * @description Load other nearby listings.
         * @param {Object} sourceData - vm.apidata - data for the processed listing
         * @param {Object} data - callback for vm.nearbyOtherListings
         * @returns {Object} otherNearbyListings
         */
        function getOtherNearbyListings(sourceData, data) {
            /**
             * @memberof serviceListing
             * @method location
             * @name location
             * @description
             * Build the location param for the API request.
             * In case that the lat and lon are not defined use postCode and suburb
             * @returns {string}
             */
            var location = function () {
                var address = sourceData.addresses.address[0];
                if (angular.isUndefined(address)) {
                    return '';
                } else if ((angular.isUndefined(address.latitude) && angular.isUndefined(address.longitude))
                           || (address.latitude == '' && address.longitude == '')) {
                    return address.postCode + ',' + address.suburb;
                } else {
                    return address.latitude + ',' + address.longitude;
                }
            };

            var getCategoriesString = function (categories) {
                var categoryString = '';
                angular.forEach(categories, function (category) {
                    categoryString = categoryString + category.name + ' ';
                })
                return categoryString;
            }

            var category = [];
            if (sourceData.categories && sourceData.categories.category) {
                category = sourceData.categories.category;
            }

      // execute the API call
            return $http.get(apiConfig.generate('listings', {
                type: 'location',
                location: location(),
                inventory: 'false',
                sort: 'reviews',
                excludeCategory: getCategoriesString(category),
            }), {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
                .then(getOtherNearbyComplete)
                .catch(getOtherNearbyFailed);

      // parse the data with callback to the main corespondent variable; in this case vm.nearbyOtherListings
            function getOtherNearbyComplete(response) {
                var _data = response.data;

                if (angular.isDefined(data)) {
                    angular.copy(_data, data);
                } else {
                    return _data;
                }
            }


            function getOtherNearbyFailed(error) {
                var message = '';
                if (error.status == '500') {
                    message = 'XHR Failed for getListing ';
                } else if (error.status == '404') {
                    message = 'XHR NotFound for getListing ';
                }
                message = message + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
                if (angular.isDefined(data)) {
                    data.call(this, {'error': message}, 0);
                }
            }
        }

        /**
         * @memberof serviceListing
         * @method getSortedReviews
         * @name getSortedReviews
         * @description Loads and returns sorted listing reviews.
         * @param {String} id
         * @param {Object} options
         * @param {Object} data
         * @returns {Object} sortedReviews
         */
        function getSortedReviews(id, options, data) {
            if (angular.isUndefined(options)) {
                options = {};
            }
            angular.extend(options, {offset: 0});
            apiConfig.updateListReviewDefinition(options);

            return $http.get(apiConfig.generate('listings', id, 'reviews', apiConfig.getReviewListOptions()),
                             apiConfig.getXAuthToken())
                .then(getSortedReviewsComplete)
                .catch(getSortedReviewsFailed);

            function getSortedReviewsComplete(response) {
                var _data = response.data.data;
                var _metadata = response.data.meta;

                if (angular.isDefined(data)) {
                    var _sortedModel = listingModels.getNextReviewBlock(_data);
                    if (data.reviewList && _sortedModel.reviewList && _sortedModel.reviewList.length) {
                        data.reviewList.length = 0;
                        for (var i = 0, l = _sortedModel.reviewList.length; i < l; i++) {
                            data.reviewList.push(_sortedModel.reviewList[i]);
                        }
                    }

                    delete _sortedModel.reviewList;

                    angular.merge(data, _sortedModel);
                    _data.bussinessJSON = angular.toJson(_data, true);
                    $log.debug(data);
                } else {
                    return _data;
                }
            }


            function getSortedReviewsFailed(error) {
                var message = '';
                removeCanonicalHeader();
                if (error.status == '500') {
                    message = 'XHR Failed for getListing ';
                } else if (error.status == '404') {
                    message = 'XHR NotFound for getListing ';
                }
                message = message + id + '\n' + angular.toJson(error.data, true);
                $log.debug(message);
                if (angular.isDefined(data)) {
                    data.call(this, {'error': message}, 0);
                }
            }
        }

        /**
         * @memberof serviceListing
         * @method addReview
         * @name addReview
         * @description Adds review.
         * @param {String} id
         * @param {Object} options
         * @param {Object} data
         * @returns {Object} status
         */
        function addReview(id, options) {
            if (angular.isUndefined(options)) {
                options = {};
            }

            var reviewOption = 'add';

      // Not logged in, send to pending review end point
            if (!apiConfig.userIsAuthenticated()) {
                reviewOption = 'pending';
            }

            return $http.post(apiConfig.generate('listings', id, 'reviews', reviewOption), options,
                              {ignoreLoadingBar: true}, apiConfig.getXAuthToken())
                .then(addReviewsComplete)
                .catch(addReviewsFailed);

            function addReviewsComplete(response) {
                var _data = response.data.data.review[0],
                    _config = response.config;

                return {status: 'success', data: _data, config: _config};
            }


            function addReviewsFailed(error) {
                var message = '';
                if (error.status == '500') {
                    message = 'XHR Failed for adding Review ';
                } else if (error.status == '401') {
                    message = 'XHR NotFound for getListing ';
                    var _modalInstance = $modal.open({
                                                         templateUrl: '/app/components/bdp/reviews/bdpreviewlikedislike/bdplikedislikeresponsemodal.html',
                                                         controller: ['storedData', ModalLikeDislikeFactory.setMessage],
                                                         controllerAs: 'vm',
                                                         size: 'dialog_size_small',
                                                         resolve: {
                                                             storedData: function () {
                                                                 return 'User not logged in.';
                                                             },
                                                         },
                                                     });

                    _modalInstance.opened.then(function () {
                        windowScroll.mobileModalScroll();
                    });
                    _modalInstance.result.finally(function () {
                        windowScroll.mobileModalCloseHandler();
                    });
                } else if (error.status == '400') {
                    message = 'XHR Invalid review, already added';
                } else if (error.status == '200') {
                    message = 'XHR Success';
                }
                message = message + id + '\n' + angular.toJson(error.data, true);
                $log.debug(message);

                return {status: 'error', status_code: error.status, code: error.data.meta.code, data: error};
            }
        }

        function isValidSelector(selector) {
            if (angular.element(selector).length > 0) {
                return true;
            } else {
                return false;
            }
        }

        /**
         * Returns the API request URL with encoded keyword
         * @param {Object} options 
         * @returns the API request URL with encoded keyword
         */
        function encodeApiRequestUrl(options) {
            // Encode # character since this will cause malformed API request URL
            if(options.keyword && options.keyword.indexOf('#') > -1) {
                options.keyword = encodeURIComponent(options.keyword);
            }
            return apiConfig.generate('listings', options);
        }

        function redirectPage(lastPageNumber, pageType) {
            var pageNum = $location.search().page;
            var redirectPage = $location.protocol() + '://' + $location.host();

            if ($location.port()) {
                redirectPage = redirectPage + ':' + $location.port();
            }

            redirectPage = redirectPage + $location.url().replace('page=' + pageNum, 'page=' + lastPageNumber);
            
            if (pageType === 'find') {
                var _meta1 = '<meta name="prerender-status-code" content="301">';
                var _meta2 = '<meta name="prerender-header" content="Location: ' + redirectPage + '">';
                $document.find('head').append(_meta1);
                $document.find('head').append(_meta2);
            }

            $log.debug('redirecting from ', pageNum, ' to ', redirectPage);
            // add delay so prerender would return 301 right away but browser will still perform redirect
            if (pageNum > 0) {
                var _meta3 = '<meta http-equiv="refresh" content="5;url=' + redirectPage + '">';
                $document.find('head').append(_meta3);
            }
        }

    /**
     * @memberof serviceListing
     * @method listingSearch
     * @name listingSearch
     * @description Returns listing search result.
     * @param {Object} data
     * @param {String} metadata
     * @param {Object} options
     * @param {String} versionNumber
     * @returns {Object} status
     */
        function listingSearch(data, metadata, options, versionNumber) {

            updateOptionsWithPageNo(options);
            // $httpProvider.defaults.headers.post = {'Content-Type': 'text/plain'};
            var pageSplit = $location.path().split("/");
            var pageType = pageSplit[1] ? pageSplit[1].trim() : '';

            //TODO: Start - Remove after Angular Search and Find is released for all categories
            if (angular.isDefined(findSearchCategories.categoryList) && (
                (pageType === 'find' && findSearchCategories.categoryList.indexOf(options.category) < 0) ||
                (pageType === 'search' && findSearchCategories.categoryList.indexOf(options.keyword) < 0) ||
                pageType === 'search-location')
            ) {
                $window.location.reload(); //Kicks in Gateway to redirect to old search
                return;
            }
            //TODO: End - Remove after Angular Search and Find is released for all categories

            if (pageType === 'search' || pageType === 'search-location') {
                if (apiConfig.searchFilteredbyState) {
                    options.state = pageSplit[3];
                    apiConfig.searchFilteredbyState = false;
                }
                if (apiConfig.searchFilteredbyRegion) {
                    options.state = pageSplit[3];
                    options.region = pageSplit[4];
                    apiConfig.searchFilteredbyRegion = false;
                }

                if (apiConfig.searchFilteredbySuburb) {
                    options.state = pageSplit[3];
                    options.region = pageSplit[4];
                    options.suburb = pageSplit[5];
                    apiConfig.searchFilteredbySuburb = false;
                }
            }

            return $http.get(encodeApiRequestUrl(options), apiConfig.getHeaderByVersion(versionNumber))
                .then(getListingSearchSuccess)
                .catch(getListingSearchFailed);

            function getListingSearchSuccess(response) {

                var _data = response.data.data;
                var _metadata = response.data.meta;
                var maxPageNumber = 50;
                var maxOffset = (maxPageNumber * options.limit) - options.limit;

                $log.debug('pageType: "', pageType, '", options: ', JSON.stringify(options), ', _data.size: ', _data.size);
                if (options.offset > maxOffset || options.offset >= _data.size) {
                    var lastPageNumber = Math.ceil(_data.size / options.limit);
                    $log.debug('lastPageNumber: ', lastPageNumber);
                    var redirectPageNumber = lastPageNumber > maxPageNumber ? maxPageNumber : lastPageNumber;
                    redirectPage(redirectPageNumber, pageType);
                } else {
                    apiConfig.setListing(_data);
                    _data.ads = parseInventory(_data.inventories);
                    if (typeof data != 'undefined') {
                        checkDuplicates(options, _data, 'suburb');
                        checkDuplicates(options, _data, 'category');
                        angular.copy(_data, data);
                        if (metadata) {
                            angular.copy(_metadata, metadata);
                        }
                    } else {
                        checkDuplicates(options, _data, 'suburb');
                        checkDuplicates(options, _data, 'category');
                        var result = {};
                        result.data = _data;
                        result.meta = _metadata;
                        return result;
                    }
                }
            }

            function getListingSearchFailed(error) {
               removeCanonicalHeader();
                $log.debug(error);
            }
        }

        function updateOptionsWithPageNo(options) {
            var pageNum = $location.search().page;
            if (!pageNum || !parseInt(pageNum)) {
                return;
            }
            options.offset = options.limit * (pageNum - 1);
        }

        var checkDuplicates = function (options, _data, duplicateVar) {
            var checkVar = '';
            var checkVarName = '';
            
            if (duplicateVar === 'suburb') {
                if(angular.isDefined(options.suburb)) {
                    checkVar = options.suburb;
                } 
                checkVarName = 'suburb_facet';
            } else {
                if(angular.isDefined(options.category)) {
                    checkVar = options.category;
                }
                if(angular.isDefined(options.keyword)) {
                    checkVar = options.keyword;
                }
                checkVarName = 'categories_facet';
            }

            if (!angular.isUndefined(checkVar) && !angular.isUndefined(_data.facets)) {
                for (var i = 0; i < _data.facets.length; i++) {
                    if (_data.facets[i].type === checkVarName && !angular.isUndefined(_data.facets[i].element)) {
                        for (var j = 0; j < _data.facets[i].element.length; j++) {
                            //Do not show in filter if same category/keyword or suburb/location
                            if (!angular.isUndefined(_data.facets[i].element[j]) && 
                                (_data.facets[i].element[j].value === checkVar)) {
                                _data.facets[i].element.splice(j, 1);
                            }
                        }
                    }
                }
            }
        };

        /**
         * @memberof serviceListing
         * @method searchListingNextBlock
         * @name searchListingNextBlock
         * @description Returns listing next block from search result.
         * @param {Object} data
         * @param {Object} options
         * @param {String} versionNumber
         */
        function searchListingNextBlock(data, options, versionNumber) {
            return $http.get(encodeApiRequestUrl(options), apiConfig.getHeaderByVersion(versionNumber))
                .then(getListingSearchNextBlockSuccess)
                .catch(getListingSearchNextBlockFailed);

            function getListingSearchNextBlockSuccess(response) {
                var _data = response.data.data.listing;
                if (typeof data != 'undefined') {
                    for (var i = 0, l = _data.length; i < l; i++) {
                        data.listing.push(_data[i]);
                    }
                }
            }

            function getListingSearchNextBlockFailed(error) {
                removeCanonicalHeader();
                $log.info(error);
            }
        }

        function searchListingPrevBlock(data, options, versionNumber) {
            return $http.get(encodeApiRequestUrl(options), apiConfig.getHeaderByVersion(versionNumber))
                .then(getListingSearchPrevBlockSuccess)
                .catch(getListingSearchPrevBlockFailed);

            function getListingSearchPrevBlockSuccess(response) {
                var _data = response.data.data.listing;

                if (typeof data != 'undefined') {
                    for (var i = _data.length - 1, l = 0; i >= l; i--) {
                        data.listing.unshift(_data[i]);
                    }
                }
            }

            function getListingSearchPrevBlockFailed(error) {
                removeCanonicalHeader();
                $log.info(error);
            }
        }

        function parseInventory(inventories) {
            var tol, tiles;
            angular.forEach(inventories, function (inventory) {
                if (inventory.type === 'TOL' && inventory.inventory.length) {
                    tol = inventory.inventory[0].listing;
                    tol.sponsored = true;
                }
                if (inventory.type === 'TILES') {
                    tiles = inventory;
                }
            });

            return {
                tol: tol,
                tiles: tiles,
            };
        }

        function removeCanonicalHeader() {
            angular.forEach($document.find('link'), function(linkElement){
                if(angular.element(linkElement)[0].rel == 'canonical'){
                  angular.element(linkElement).remove();
                }
            });
        }

        return service;
    }
})();
