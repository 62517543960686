(function () {
    'use strict';

    angular
        .module('truelocal')

        /**
         * @memberof truelocal
         * @ngdoc controller
         * @name ProfileController
         * @description User Profile controller contains logic of header search form
         *  and going forward anything outside the main user profile component
         */
        .controller('ProfileController', ProfileController);

    /** @ngInject */
    function ProfileController($scope, headerService, apiConfig, $state, listingModels, $location,
                               Seo, $cookies, DTM, userProfilePageSeo, modalFactory, authService, appService) {
        // set header
        headerService.showSearchForm = true;
        headerService.logoClass = '';
        headerService.dynamicShowHideSearchForm = false;
        // end set header

        $scope.page = {};
        $scope.page.title = "";
        $scope.email = {};
        $scope.email.confirmed = false;
        $scope.account = {};
        $scope.account.verified = false;

        var loggedInUser = (_.has(apiConfig.userData,'data.data')) ? apiConfig.userData.data.data : "";
        var pathSplit = $location.path().split('/member/');

        if(angular.isUndefined(listingModels.getUserIdToVisit()) ||
           listingModels.getUserIdToVisit() === "" ||
           listingModels.getUserIdToVisit() === loggedInUser.id){

            $scope.showAuthMsg = (($state.current.name === 'member-edit' ||
                                   $state.current.name === 'member-settings') && (!loggedInUser.id));
            
            if($state.current.name === 'member' && $cookies.getObject('tl_user_to_visit') && $cookies.getObject('tl_user_to_visit').profileUrl.toLowerCase() === pathSplit[1].toLowerCase() &&
               (angular.isUndefined(apiConfig.userData.data) ||
                apiConfig.userData.data.data.profileUrl.toLowerCase() !== pathSplit[1].toLowerCase())){
                $scope.userToVisit = $cookies.getObject('tl_user_to_visit').user; //If userId is not available, set user id from cookie
                $scope.myProfile = false;
            } else if(_.has(apiConfig.userData,'data.data') && apiConfig.userData.data.data.profileUrl.toLowerCase() === pathSplit[1].replace('/edit', '').replace('/settings','').toLowerCase()){
                //Setting the user as logged in user
                $scope.userToVisit = loggedInUser;
                listingModels.setUserIdToVisit(_.has(apiConfig.userData,'data.data') ? apiConfig.userData.data.data.id : "");
                $scope.myProfile = true;
            } else if($state.current.name === 'member' && (!$cookies.getObject('tl_user_to_visit') || $cookies.getObject('tl_user_to_visit').profileUrl !== pathSplit[1]) ) {
                $scope.userToVisit = pathSplit[1]; //If userId is not available & cookie is not set for this user, use username
                $scope.myProfile = false;
            }
            $scope.page.title = _.has($scope.userToVisit,'displayName') ? $scope.userToVisit.displayName : "";
            if($scope.page.title !== ""){
                Seo.pageTitle.set('Profile for ' + $scope.page.title);
            }
        } else {
            $scope.userToVisit = listingModels.getUserIdToVisit();
            $scope.myProfile = false;
            $cookies.putObject('tl_user_to_visit',{'user':listingModels.getUserIdToVisit(), 'profileUrl':pathSplit[1] }, {path:'/'}); //To use when user refreshes page
        }

        //Checking if logged in user information is updated
        var updateLoggedInUser = $scope.$on('updatedUser', function(event,userData) {
            $scope.myProfile = false;
            if(_.has(userData, 'data.data') && userData.data.data !== $scope.userToVisit){
                $scope.userToVisit = userData.data.data;
                if(listingModels.getUserIdToVisit() == $scope.userToVisit.id ||
                   ($scope.userToVisit.profileUrl.toLowerCase() === pathSplit[1].replace('/edit', '').replace('/settings',''))){
                    $scope.page.title = _.has(apiConfig.userData,'data.data') ? apiConfig.userData.data.data.displayName : "";
                    if($scope.page.title !== ""){
                        Seo.pageTitle.set('Profile for ' + $scope.page.title);
                    }
                    $scope.myProfile = true;
                    $scope.showAuthMsg = false;

                    //Update hash info in component and display account verified message
                    if($state.current.name === 'member-edit' && $location.search().hash && $location.search().hash !== ''){
                        $scope.account.verified = true;
                    }
                }
            } else if (userData === "") {
                if($state.current.name === 'member-edit' ||
                   $state.current.name === 'member-settings'){
                    $state.go('member', {path: pathSplit[1].split('/')[0]});
                } else {
                    $state.go('member');
                }
            }
        });

        if($scope.showAuthMsg && $state.current.name === 'member-settings' && $location.search().hashToken && $location.search().hashToken !== ''){
            var authInstance = authService.authModal(modalFactory, {});
            authInstance.result.finally(function() {
                if (_.has(apiConfig.userData,'data.data') &&
                    apiConfig.userData.data.data.profileUrl.toLowerCase() === pathSplit[1].replace('/settings', '')) {
                    $state.params.hashToken = $location.search().hashToken;
                    $scope.showAuthMsg = false;
                }
            });
        }

        if($scope.showAuthMsg && $state.current.name === 'member-edit' && $location.search().hash && $location.search().hash !== ''){
            var activationObj = {
                                    type:'CONFIRM_USER',
                                    displayName: pathSplit[1].replace('/edit', ''),
                                    hash: $location.search().hash
                                };
            authService.signIn(activationObj).then(function (returned) {
                if(returned && returned.status !== 200){
                    $scope.showAuthMsg = true;
                    $scope.showActivationFailure = true;
                }
            });
        }

        if (userProfilePageSeo.meta) {
            for (var metaName in userProfilePageSeo.meta) {
                Seo.cleanCustomMeta(metaName);
                Seo.customMeta(metaName, userProfilePageSeo.meta[metaName]);
            }
        }

        //Used to set page title and track page when API call is done
        var updateTitleAndTrack = $scope.$watch('page.title', function(newVal) {
            if(newVal && newVal !== ""){
                Seo.pageTitle.set('Profile for ' + $scope.page.title);
                //This happens after data is available in all scenarios, track page at this event
                if (DTM.hasOneTrustScript()) {
                    DTM.fireEventAtPageLoad($state.current.name);
                } else {
                    DTM.trackPage();
                }
            }
        });

        var updateUser = $scope.$watch('email.confirmed', function(newVal) {
            if(newVal) apiConfig.recallUserAuthData();
        });

        $scope.goToEdit = function () {
            $state.go('member-edit');
        };

        $scope.$on('$destroy', function () {
            updateLoggedInUser();//Deregister listener
            updateTitleAndTrack();//Deregister watch
            updateUser();//Deregister watch
        });

        appService.displayFooter();
    }

})();

