(function() {
    'use strict';

    angular.module('truelocal')
    .directive('businessAddress', businessAddress);

    function businessAddress() {
        return {
            restrict: 'EA',
            template: '{{vm.address}}',
            scope: {
                businessAddress: '=',
            },
            controller: businessAddressController,
            controllerAs: 'vm',
            bindToController: true,
        };
    }

    function businessAddressController($filter) {
        var vm = this;
        vm.address = $filter('removeSpecialCharacters')(formatAddress(vm.businessAddress));
    }

    function formatAddress(address) {
        var fullAddress = [
            [
                address.addressLine1,
                address.addressLine2,
            ],
            [
                address.streetNumber,
                address.streetName,
                address.streetType,
            ],
      [address.suburb],
      [address.state],
      [address.postCode],
        ];

        return _.chain(fullAddress)
      .map(function(items) {
          return _.filter(items, isPresented);
      })
      .filter(function(items) {
          return !_.isEmpty(items);
      })
      .map(function(items) {
          return items.join(' ');
      })
      .value().join(', ');
    }

    function isPresented(value) {
        if (_.isNumber(value) && !_.isNaN(value)) return true;
        if (_.isString(value) && value.trim()) return true;
        return false;
    }
})();
