/**
 * Created by closcacostel on 18/04/16.
 */
(function() {
    'use strict';

    angular
        .module('truelocal')

        .service('Geo', ['$q', '$window', 'sharedService', geoService]);

    function geoService($q, $window, sharedService) {
        var service = {
            getCoords: getCoords,
            getLocation: getLocation,
        };

        function getCoords() {
            var deferred = $q.defer();

            if (!$window.navigator.geolocation) {
                deferred.reject('Geolocation not supported.');
            } else {
                $window.navigator.geolocation.getCurrentPosition(
                    function(position) {
                        deferred.resolve(position);
                    },
                    function(err) {
                        deferred.reject(err);
                    });
            }

            return deferred.promise;
        }

        function getLocation(coords, callback) {
            if (!window.google || !window.google.maps) {
              sharedService.initializeGoogleMapApi().then(function() {
                executeGeoCode();
              });
            } else {
              executeGeoCode();
            }

            function executeGeoCode() {
                var geocoder = new google.maps.Geocoder();
                var latlng = new google.maps.LatLng(coords.coords.latitude, coords.coords.longitude);
                // var latlng = new google.maps.LatLng(-29.431219, 143.761099);
                geocoder.geocode({'latLng': latlng}, function(results, status) {
                  if (status == google.maps.GeocoderStatus.OK) {
                    if(angular.isDefined(results) && angular.isDefined(results[0]) && angular.isDefined(results[0].address_components)) {
                      var _locations = results[0].address_components;
                      _locations = _locations.reverse();
                      var _foundCountry = false, _country, _foundRegion = false, _region, _foundCity = false, _city;
                      angular.forEach(_locations, function(location) {
                        if(location.types[0]=='country') {
                          _foundCountry = true;
                          _country = {
                            long_name: location.long_name,
                            short_name: location.short_name,
                          };
                        }

                        if(location.types[0] == 'administrative_area_level_1') {
                          _foundRegion = true;
                          _region = location.short_name;
                        }
                        if(location.types[0] == 'locality') {
                          _foundCity = true;
                          _city = location.short_name;
                        }
                      });
                      var _response = 'Australia';
                      if(_foundCountry == true && _country.short_name == 'AU') {
                        if(_foundCity == true && _foundRegion == true) {
                          _response = _city + ', ' + _region;
                        }
                      }
                      callback(_response);
                    }
                  }
                });
            }
        }

        return service;
    }
})();
