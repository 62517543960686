(function() {
    'use strict';

    angular
    .module('truelocal')

    /**
     * @memberof truelocal
     * @ngdoc directive
     * @name bdpGalleryaddphoto
     * @description Directive handle adding new photos to gallery.
     *
     * @param {directive} $modal                    ui-bootstrap modal wrapper
     * @param {factory}   platformService           Factory service that handle detection of platform and screen size that current user use
     * @param {factory}   apiConfig                 Api configuration factory
     * @param {factory}   authService               Factory what handle API request for authentication
     * @param {factory}   modalFactory              modalFactory service provide methods for building custom modals.
     * @param {constant}  constantsClasses          Constant classes
     * @param {constant}  constantsPlaceholders     Constant placeholders
     * @param {factory}   DTM           TL tracking services
     *
     * @example
     * <bdp-galleryaddphoto listing="[object]" class="btn btn-add" parent-scope="[object]"></bdp-galleryaddphoto>
     *
     */
    .directive('bdpGalleryaddphoto', bdpGalleryAddPhoto);

  /** @ngInject */
    function bdpGalleryAddPhoto() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/bdp/bdpgalleryaddphoto/bdpgalleryaddphoto.html',
            scope: {
                listing: '=',
                parentScope: '=',
            },
            controller: ['$modal', 'platformService', 'apiConfig', 'authService', 'modalFactory', 'constantsClasses', 'constantsPlaceholders', 'DTM', 'windowScroll', BdpGalleryAddPhotoController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

    /** @ngInject */

        function BdpGalleryAddPhotoController($modal, platformService, apiConfig, authService, modalFactory, constantsClasses, constantsPlaceholders, DTM, windowScroll) {
      /**
       * @memberof truelocal
       * @ngdoc directive
       * @name galleryAddPhotoModal
       * @description galleryAddPhotoModal handles adding of new photos to gallery.
       *
       * @param  {service}  $modalInstance            ui-bootstrap modal event handler wrapper
       * @param  {object}   listing                   inject current listing object
       * @param  {service}  Upload                    https://github.com/danialfarid/ng-file-upload
       * @param  {service}  $timeout                  Angular window.setTimeout service
       * @param  {factory}  apiConfig                 Api configuration factory
       * @param  {factory}  platformService           Factory service that handle detection of platform and screen size that current user use
       *
       */
            this.galleryAddPhotoModal = galleryAddPhotoModal;

            this.apiConfig = apiConfig;

            function galleryAddPhotoModal(listing) {
                DTM.trackAnalyticOnClick('add photo');

                $modal.open({
                    templateUrl: '/app/components/bdp/bdpgalleryaddphoto/galleryaddphotomodal.html',
                    controller: ['$modalInstance', 'listing', 'Upload', '$timeout', 'apiConfig', 'platformService', 'errorService', GalleryAddPhotoModalController],
                    controllerAs: 'vm',
                    size: 'dialog_size_big',
                    resolve: {
                        listing: function() {
                            return listing;
                        },
                    },
                });
            }

            function GalleryAddPhotoModalController($modalInstance, listing, Upload, $timeout, apiConfig, platformService, errorService) {
                var vm = this;
                vm.listing = listing;
                vm.files = [];
                vm.uploading = false;
                vm.uploaded = false;
                vm.apiConfig = apiConfig;
                vm.platformService = platformService;
                vm.maxFilesError = false;
                vm.constantsPlaceholders = constantsPlaceholders.bdpGalleryAddPhoto;


        /**
         * @memberof galleryAddPhotoModal
         * @method errors
         * @name errors
         * @description Return true if some of files that holds image to upload have error.
         * @returns {Boolean} errors
         */
                vm.errors = function() {
                    var errors = false;
                    if (!vm.files || vm.files.length == 0) {
                        errors = false;
                    }
                    angular.forEach(vm.files, function(file) {
                        if (!errors && file.errorMsg) {
                            errors = true;
                        }
                    });
                    return errors;
                };


        /**
         * @memberof galleryAddPhotoModal
         * @method userOwnsBussines
         * @name userOwnsBussines
         * @description Return id of user that owns business.
         * @returns {Number} id
         */
                vm.userOwnsBussines = function() {
                    apiConfig.userOwnsBusiness();
                    var ownedListings = apiConfig.userData.data.data.ownedListings.listing;
                    return listing.id;
                };


        /**
         * @memberof galleryAddPhotoModal
         * @method done
         * @name done
         * @description Return true if all files uploaded.
         * @returns {Boolean} done
         */
                vm.done = function() {
                    var done = true;
                    if (!vm.files || vm.files.length == 0) {
                        done = false;
                    }
                    angular.forEach(vm.files, function(file) {
                        if (!file.done || file.errorMsg) {
                            done = false;
                        }
                    });
                    return done;
                };


        /**
         * @memberof galleryAddPhotoModal
         * @method addPhotoToQueue
         * @name addPhotoToQueue
         * @description Add photo to queue to upload.
         * @params {Object} $files service
         */
                vm.addPhotoToQueue = function($files) {
                    angular.forEach($files, function($file) {
                        if ($file) {
                            $file.caption = '';
                            $file.progress = 0;
                            $file.done = false;
                            $file.rotate = 0;
                            $file.thumb = 0;
                            $file.rotatePhoto = function() {
                                if (this.rotate < 270) {
                                    this.rotate += 90;
                                } else {
                                    this.rotate = 0;
                                }
                            };
                            if (vm.files.length < 10) {
                                vm.files.push($file);
                                vm.maxFilesError = false;
                            } else {
                                vm.maxFilesError = true;
                            }
                            $timeout(function() {
                                $file.thumb = 1;
                            });
                        }
                    });
                };


        /**
         * @memberof galleryAddPhotoModal
         * @method uploadPhoto
         * @name uploadPhoto
         * @description Upload single photo file to server.
         * @params {Object} $files service
         */
                vm.uploadPhoto = function(file) {
                    vm.uploading = true;
                    file.upload = Upload.upload({
                        url: apiConfig.generate('listings', listing.id, 'images/add'),
                        data: {file: file, caption: file.caption, rotate: file.rotate},
                        headers: apiConfig.getXAuthTokenShort(),
                    });

                    file.upload.then(function(response) {
                        $timeout(function() {
                            file.result = response.data;
                            $timeout(function() {
                                vm.uploading = false;
                                file.done = true;
                                vm.startUploadingFiles();
                            });
                        });
                    }, function(response) {
                        vm.uploading = false;
                        file.errorMsg = response.status;
                        if (response.status > 0) {
                            file.errorMsg +=': ' +errorService.getErrorMessage(response);
                        } else if (response.status == -1) {
                            file.errorMsg = 'canceled';
                        }
                        file.done = true;
                        vm.startUploadingFiles();
                    }, function(evt) {
            // Math.min is to fix IE which reports 200% sometimes
                        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                        file.sizeTotal = evt.total;
                        file.sizeLoaded = evt.loaded;
                    });
                };


        /**
         * @memberof galleryAddPhotoModal
         * @method startUploadingFiles
         * @name startUploadingFiles
         * @description Start process of uploading images in queue one by one.
         * @params {Boolean} done
         */
                vm.startUploadingFiles = function(done) {
                    var uploading = false;
                    var exists = false;
                    vm.files.forEach(function(file) {
                        if (!file.done && !uploading) {
                            vm.uploadPhoto(file);
                            uploading = true;
                            vm.uploaded = true;
                            exists = true;
                        }
                    });
                    if (!uploading && angular.isDefined(done)) {
                        vm.close();
                    }
                };


        /**
         * @memberof galleryAddPhotoModal
         * @method close
         * @name close
         * @description Close upload photo modal.
         */
                vm.close = function() {
                    if (vm.uploaded) {
                        angular.element(window).triggerHandler('refreshListing');
                    }
                    $modalInstance.close();
                };


        /**
         * @memberof galleryAddPhotoModal
         * @method cancel
         * @name cancel
         * @description Cancel uploading.
         * @params {Object} file
         * @params {Object} $index
         */
                vm.cancel = function(file, $index) {
                    if (angular.isDefined(file.upload) && angular.isUndefined(file.errorMsg) && !file.done) {
                        file.upload.abort();
                        delete file.upload;
                        file.errorMsg = 'canceled';
                    } else {
                        vm.files.splice($index, 1);
                        vm.maxFilesError = false;
                    }
                };


        /**
         * @memberof galleryAddPhotoModal
         * @method statusClass
         * @name statusClass
         * @description Returns class for current state as defined in constantsClasses.bdpGalleryAddPhoto.
         * @params {Object} file
         * @returns {String} statusClass
         */
                vm.statusClass = function(file) {
                    if (file && file.done && file.upload /* && !file.errorMsg*/) {
                        return constantsClasses.bdpGalleryAddPhoto.iconCheck;
            // return 'icon-check';
                    }
                    return constantsClasses.bdpGalleryAddPhoto.iconRemove;
          // return 'icon-remove-1';
                };
            }

            this.authModalOpened = false;


      /**
       * @memberof BdpGalleryAddPhotoController
       * @method errors
       * @name errors
       * @description Call login modal if user is not authenticated.
       */
            this.showAuthPrompt = function() {
                if (this.authModalOpened == false) {
                    this.authModalOpened = true;
                    var that = this;
                    setTimeout(function() {
                        var _modalInstance = $modal.open({
                            templateUrl: '/app/components/bdp/reviews/bdpauthprompt/bdpauthprompt.html',
                            controller: 'ModalAuthPromptController',
                            controllerAs: 'vm',
                            size: 'dialog_size_small',
                        });
        
                        _modalInstance.opened.then(function() {
                            windowScroll.mobileModalScroll();
                        });
                        _modalInstance.result.finally(function() {
                            that.authModalOpened = false;
                            windowScroll.mobileModalCloseHandler();
                        });
                        return _modalInstance;
                    });
                }
            };


      /**
       * @memberof BdpGalleryAddPhotoController
       * @method noPhotos
       * @name noPhotos
       * @description Returns true if business have no photos.
       * @returns {Boolean} haveNoPhotos
       */
            this.noPhotos = function() {
                if (angular.isUndefined(this.parentScope)) {
                    return false;
                }
                if (!this.parentScope.bussinessImages) {
                    return true;
                }

                if (this.parentScope.bussinessImages.size) {
                    return false;
                }
                return true;
            };
        }
    }
})();
