(function() {
    'use strict';

    angular
    .module('truelocal')


    /**
     * @memberof truelocal
     * @ngdoc service
     * @name Iovation
     * @description Iovation service handle integration with Iovation platforms for device based authentication,
     * and enable setting and getting fingerprint based on gathered user's system info.
     *
     * @param {service} $document   Angular document wrapper
     *
     */
    .factory('Iovation', ['$document', '$interval', Iovation]);

  /** @ngInject */
    function Iovation($document, $interval) {
        var service = {
            initBlackBox: initBlackBox,
            getFingerPrint: getFingerPrint,
            fingerPrint: '',
            setFingerPrint: setFingerPrint,
        };
    /**
     * @memberof Iovation
     * @method createIovationSettings
     * @name createIovationSettings
     * @description initialize the iovation settings script
     */
        function createIovationSettings() {
            var setting = 'window.io_install_stm = false;\n';
            setting += 'window.io_exclude_stm = 12;\n';
            setting += 'window.io_install_flash = false;\n';
            setting += 'window.io_enable_rip = true;\n';
            return setting;
        }
    /**
     * @memberof Iovation
     * @method initBlackBox
     * @name initBlackBox
     * @description init the BlackBox so we get the finger print
     */
        function initBlackBox() {
            if (service.fingerPrint.length == 0) {
                var setup = document.createElement('script');
                setup.innerHTML = createIovationSettings();
                
                var script = document.createElement('script');
                script.src = "https://mpsnare.iesnare.com/snare.js";
                
                var body = angular.element(document.querySelector('body'));
                body.append(setup);
                body.append(script);
                
                service.setFingerPrint();
            }
        }
    /**
     * @memberof Iovation
     * @method getFingerPrint
     * @name getFingerPrint
     * @description get the finger print
     */
        function getFingerPrint() {
            return service.fingerPrint;
        }

        var blackboxInterval;
    /**
     * @memberof Iovation
     * @method useBlackboxString
     * @name useBlackboxString
     * @description generate the fingerPrint
     */
        function useBlackboxString() {
            if (typeof ioGetBlackbox !== 'function') {
                return;
            }

            var bbData = ioGetBlackbox();
            if (bbData.finished) {
                $interval.cancel(blackboxInterval);
                service.fingerPrint = bbData.blackbox;
            }
        }
    /**
     * @memberof Iovation
     * @method setFingerPrint
     * @name setFingerPrint
     * @description set the finger print
     */
        function setFingerPrint() {
            if (service.fingerPrint.length == 0) {
                blackboxInterval = $interval(useBlackboxString, 500);
            }
        }

        return service;
    }
})();
