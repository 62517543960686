(function () {
    'use strict';

    angular
        .module('truelocal')


        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name bfRefineToolbar
         * @description Directive enable user to refine search to get more precise results.
         *
         * @param {service}   $cookies                  angular cookies service wrapper
         * @param {factory}   platformService           Factory service that handle detection of
         *     platform and screen size that current user use
         * @param {service}   $location                 Angular window.location service wrapper
         * @param {service}   searchConfigManager       Service for building and handlig parameters
         *     for search
         *
         * @example
         * <bf-refine-toolbar refine-facets="[object]" is-expanded="[boolean]"></bf-refine-toolbar>
         *
         */
        .directive('bfRefineToolbar', bfRefineToolbar);

    /** @ngInject */
    function bfRefineToolbar() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/findpage/bfrefinetoolbar/bfrefinetoolbar.html',
            scope: {
                searchData: '=',
                refineFacets: '=',
                isExpanded: '=',
            },
            controller: BfRefineController,
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function BfRefineController($cookies, platformService, $location,
                                    searchConfigManager, recentlyViewed, env, DTM, $state, apiConfig) {
            var vm = this;
            vm.limitToNumber = 6;
            var _responseKeys = {
                'state_facet': 0,
                'region_facet': 1,
                'suburb_facet': 2,
                'location_facet': 3,
                'categories_facet': 4,
                'popular_categories_facet': 5,
            };
            var _labelTitles = ['States', 'Regions', 'Popular Suburbs','Popular Suburbs', 'Related Business Types', 'Popular Business Types'];
            var _classTitles = ['states', 'regions', 'suburbs', 'locations', 'related-businsess', 'popular-business'];
            var _searchConfigCategoryName = '/' + searchConfigManager.getProperty('category');
            _searchConfigCategoryName = (_searchConfigCategoryName === "/") ? "" : _searchConfigCategoryName;
            _searchConfigCategoryName = _searchConfigCategoryName.replace(/[ ]/gi, '-');
            var _stateCustomOrdered = [{name: 'NSW', seoUrl: _searchConfigCategoryName + '/nsw'},
                {name: 'SA', seoUrl: _searchConfigCategoryName + '/sa'},
                {name: 'VIC', seoUrl: _searchConfigCategoryName + '/vic'},
                {name: 'TAS', seoUrl: _searchConfigCategoryName + '/tas'},
                {name: 'QLD', seoUrl: _searchConfigCategoryName + '/qld'},
                {name: 'ACT', seoUrl: _searchConfigCategoryName + '/act'},
                {name: 'WA', seoUrl: _searchConfigCategoryName + '/wa'},
                {name: 'NT', seoUrl: _searchConfigCategoryName + '/nt'}];
            var _prevIndex = -1;

            vm.recentlyViewed = recentlyViewed.get();
            vm.businessLinkPrefix = env.urlStructure;

            this.compiledProposals = [];

            this.pageType = $location.path().split("/")[1];

            /**
             * @memberof bfRefineToolbar
             * @name checkIfDulicate
             * @method checkIfDulicate
             * @description check if there is duplicate
             *
             * @param {string} _confValue
             * @param {object} _element
             *
             * @returns {boolean} true/false
             *
             */

            this.checkIfDulicate = function (_confValue, _element) {
                var _elementNameFirstBlock = _element.split(' ')[0];

                if (_elementNameFirstBlock.toLowerCase().indexOf(_confValue.toLowerCase()) > -1) {
                    return true;
                } else if (_confValue.toLowerCase().indexOf(_elementNameFirstBlock.toLowerCase())
                           > -1) {
                    return true;
                }
                return false;
            };

            /**
             * @memberof bfRefineToolbar
             * @name checkTheFacets
             * @method checkTheFacets
             * @description build the facests
             *
             */
            this.checkTheFacets = function () {
                this.compiledProposals.length = 0;
                var _fallbackLocationProposals = -1;
                for (var facetIndex = 0; facetIndex < this.refineFacets.length; facetIndex++) {
                    var _keyOfCurrentFacet = this.refineFacets[facetIndex].type;
                    var _isInExpectedKeys = _responseKeys[_keyOfCurrentFacet];
                    if (angular.isDefined(_isInExpectedKeys) && _isInExpectedKeys !== null) {
                        if (_isInExpectedKeys == 0 && this.refineFacets[facetIndex].element
                            && this.refineFacets[facetIndex].element.length) {
                            this.refineFacets[facetIndex].element = [];
                            angular.extend(this.refineFacets[facetIndex].element,
                                           _stateCustomOrdered);
                        }

                        if (_isInExpectedKeys == 4) {
                            _fallbackLocationProposals = facetIndex;
                        }
                        if (_prevIndex >= 0) {
                            if (_isInExpectedKeys > _prevIndex) {
                                if (this.refineFacets[facetIndex].element
                                    && this.refineFacets[facetIndex].element.length) {
                                    this.compiledProposals.push(this.refineFacets[facetIndex]);
                                }
                            } else {
                                if (this.refineFacets[facetIndex].element
                                    && this.refineFacets[facetIndex].element.length) {
                                    this.compiledProposals.unshift(this.refineFacets[facetIndex]);
                                }
                            }
                        } else {
                            _prevIndex = _isInExpectedKeys;
                            this.compiledProposals.push(this.refineFacets[facetIndex]);
                        }
                    }
                }
                return this.compiledProposals;
            };

            /**
             * @memberof bfRefineToolbar
             * @name getListClassName
             * @method getListClassName
             * @description Get the list name
             *
             * @param {integer} _index  Current index
             */

            this.getListName = function (_index) {
                if ((angular.isUndefined(_index) || !_index) && _index !== 0) {
                    return;
                }
                ;
                var _listTypeKey = this.compiledProposals[_index].type;
                var _listTypeIndex = _responseKeys[_listTypeKey];
                return _labelTitles[_listTypeIndex];
            };

            /**
             * @memberof bfRefineToolbar
             * @name getListClassName
             * @method getListClassName
             * @description Get the list class
             *
             * @param {integer} _index  Current index
             */

            this.getListClassName = function (_index) {
                if ((angular.isUndefined(_index) || !_index) && _index !== 0) {
                    return;
                }
                ;
                var _listTypeKey = this.compiledProposals[_index].type;
                var _listTypeIndex = _responseKeys[_listTypeKey];
                return _classTitles[_listTypeIndex];
            };

            /**
             * @memberof bfRefineToolbar
             * @name refineResults
             * @method refineResults
             * @description Refine the result set
             */
            vm.refineResults = function(facetType, item) {
                if (vm.refiningMore) {
                    return;
                }
                vm.refiningMore = true;

                searchConfigManager.refineSearchResults(function() {
                    vm.refiningMore = false;
                    vm.refineFacets = vm.searchData.facets;
                },facetType, item);
            };

            vm.getSeoUrl = function(seoUrl) {
                var pageSplit = $location.path().split("/");
                var pageType = '/'+pageSplit[1];
                var port = $location.host() === 'localhost' || $location.host() === '127.0.0.1'?':'+$location.port():'';
                return $location.protocol()+'://'+$location.host()+port+pageType+seoUrl.replace(' ','-');
            };

            /**
             * @memberof bfRefineToolbar
             * @name redirectTo
             * @method redirectTo
             * @description Redirect to url using $location
             */
            this.redirectTo = function (item, facetType) {
                var pageSplit = $location.path().split("/");
                var pageType = pageSplit[1];
                var searchTarget = pageSplit[2];
                if(pageType === 'search'){
                    if(["location_facet", "categories_facet", "suburb_facet"].indexOf(facetType) > -1){
                        var itemName;
                        if(["location_facet", "suburb_facet"].indexOf(facetType) > -1){
                            itemName = (item) ? item.filterByName : '';
                        } else if("categories_facet" === facetType){
                            itemName = (item) ? item.name : '';
                        }
                        this.refineResults(facetType, itemName);
                    } else {
                        if("state_facet" === facetType){
                            apiConfig.searchFilteredbyState = true;
                        } else if ("region_facet" === facetType){
                            apiConfig.searchFilteredbyRegion = true;
                        }
                        $location.path('/search' + (("state_facet" === facetType && searchTarget) ? "/" + searchTarget : "") + item.seoUrl);
                    }
                } else if(pageType === 'search-location') {
                    if(["location_facet", "suburb_facet"].indexOf(facetType) > -1) {
                        $location.path('/search-location/' + item.filterByName.toLowerCase().replace(",","").split(" ").join('-'));
                    } else {
                        $location.path('/search' + item.seoUrl);
                    }
                }

                switch(facetType){
                    case "categories_facet":
                        DTM.trackAnalyticOnClick('refine by related business');
                        break;
                    case "location_facet":
                    case "suburb_facet":
                        DTM.trackAnalyticOnClick('refine by suburb');
                        break;
                    case "state_facet":
                    case "region_facet":
                        DTM.trackAnalyticOnClick('refine by region');
                        break;
                }
                if (platformService.getWidth() < 768 && !platformService.isIPad()) {
                    this.closeRefine();
                }
            };

            this.showMoreHandler = function () {
                angular.element(window).triggerHandler('bfrefine-height-change');
            };

            this.clickRecent = function (recent) {
                this.closeRefine();
                DTM.trackAnalyticOnClick('recently viewed listing');
            };

            this.isMobile = function () {
                if (platformService.getWidth() < 768 && !platformService.isIPad()) {
                    return true;
                }
                return false;
            }

            /**
             * @memberof bfRefineToolbar
             * @name closeRefine
             * @method closeRefine
             * @description Collapse refine toolbar.
             */

            this.closeRefine = function (_throughCloseButton) {
                angular.element(window).triggerHandler('close-refine');
                vm.isExpanded = false;
                $cookies.remove('tl_ref_exp', {path: '/'});
                angular.element(window).triggerHandler('enable-scrolling');
                angular.element(window).triggerHandler('unset-expand');
                if(_throughCloseButton){
                    DTM.trackAnalyticOnClick('close refine search');
                }
            };

            angular.element(window)
                .on('close-refine-toolbar', angular.bind(this, this.closeRefine));

            this.isCurrentlyOpened = function (category) {
                return category.value === $state.params.path;
            };
        }
    }
})();
