(function () {
    'use strict';

    angular
        .module('truelocal')

        /**
         * @memberof truelocal
         * @ngdoc directive
         * @name tlMapInfoBox
         * @description Directive holds popup that display business details with link to business
         *     on map.
         *
         * @param {service}   $location                  Angular window.location service wrapper
         * @param {factory}   uriService                uriService provide uriEncode and uriDecode
         *     methods that are responsible to unify different uri
         *
         * @example
         * <tl-map-info-box  listing="[object]" ></tl-map-info-box>
         */
        .directive('tlMapInfoBox', tlMapInfoBox);

    /** @ngInject */
    function tlMapInfoBox() {
        var directive = {
            restrict: 'E',
            templateUrl: '/app/components/searchpage/searchmap/searchmapinfobox.html',
            scope: {
                listing: '=',
            },
            controller: ['$location', 'uriService', BsMapInfoBoxController],
            controllerAs: 'vm',
            bindToController: true,
        };

        return directive;

        /** @ngInject */
        function BsMapInfoBoxController($location, uriService) {
            var vm = this;
            this.ifTitleTruncated = function () {
                if (this.listing.listing.name.length >= 90) {
                    return 'true';
                } else {
                    return 'false';
                }
            };

            /**
             * @memberof tlMapInfoBox
             * @name getCategoryUrl
             * @description return category url
             * @param {object} listing
             * @param {int} index
             */
            vm.categoriesUrls = [];
            vm.getCategoryUrl = function (listing, index) {
                if (angular.isUndefined(vm.categoriesUrls[index])) {
                    var _url = $location.url();
                    var _finalUrl;
                    var _location = '';
                    var _params, listingCategory;

                    if (_url.indexOf('search') != -1) {
                        _params = _url.split('search');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            if (angular.isUndefined(_params[2])) {
                                _location = 'australia';
                            }
                            listingCategory = listing.category.toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                _finalUrl =
                                    _url.replace(_params[1], uriService.uriEncode(listingCategory));
                                if (_location != '') {
                                    _finalUrl = _finalUrl + '/' + _location;
                                }
                            } else {
                                _finalUrl = _url + ((_location != '') ? '/' + _location : '');
                            }
                        }
                    } else {
                        _params = _url.split('find');
                        if (angular.isDefined(_params[1])) {
                            _params = _params[1].split('/');
                            listingCategory = vm.listing.category.toLowerCase();
                            if (uriService.uriDecode(_params[1]) != listingCategory) {
                                _finalUrl =
                                    _url.replace(_params[1], uriService.uriEncode(listingCategory));
                            } else {
                                _finalUrl = _url;
                            }
                        }
                    }
                    vm.categoriesUrls[index] = _finalUrl;
                }

                return vm.categoriesUrls[index];
            };

            vm.hasDeals = function (listing) {
                // if (angular.isUndefined(listing) || angular.isUndefined(listing.deals)
                //     || angular.isUndefined(listing.deals.deal) ||
                //     listing.deals.deal.length == 0) {
                //     return false;
                // } else {
                //     return true;
                // }
                if (listing.statistics.deals && listing.statistics.deals>0) {
                    return true;
                } else {
                    return false;
                }
            };
        }
    }
})();
