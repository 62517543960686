(function() {
    'use strict';

    angular.module('truelocal')
    .provider('recentlyViewed', {
        _limit: 4,
        setLimit: function(limit) {
            this._limit = limit;
        },
        $get: RecentlyViewed,
    });

  /** @ngInject */
    function RecentlyViewed($cookies, $window, $localstorage) {
        $localstorage = {
            setItem: function(key, value) {
                $cookies.put(key, value);
            },
            getItem: function(key) {
                return $cookies.get(key);
            },
        };


        var indexOf = function(link) {
            for (var i = list.length; i--;) {
                if (link === list[i].link) return i;
            }
            return -1;
        };

        var limit = this._limit;
        var list = $localstorage.getItem('recent-business');
        list = list ? JSON.parse(list) : [];

        return {
            add: function(name, link) {
                var item = {name: name, link: link};
                var indexLimit = indexOf(link);

                if (list.length === limit && indexLimit === -1) {
                    indexLimit = limit - 1;
                }

                if (indexLimit !== -1) {
                    for (var i = indexLimit; i >= 1; i--) {
                        list[i] = list[i - 1];
                    }
                    list[i] = item;
                } else {
                    list.unshift(item);
                }

                $localstorage.setItem('recent-business', JSON.stringify(list));
            },
            get: function() {
                return list;
            },
        };
    }
})();
