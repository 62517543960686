(function() {
    'use strict';

    angular
        .module('truelocal')
        .directive('serviceHeading', ['serviceConfigManager', function(serviceConfigManager) {
            var keyword = serviceConfigManager.getProperty('keyword').capitalizeSentence();
            keyword = keyword ? '<b>' + keyword + '</b>' : '<b>results</b>';
            var location = serviceConfigManager.getProperty('location').capitalizeSentence().capitalizeSuburb();
            location = location ? ' in <b>' + location+'</b>' : ' in <b>Australia</b>';

            var _createdTitle = keyword + location;
            return {
                restrict: 'E',
                replace: true,
                template: '<strong><span>' + _createdTitle + '</span></strong>',
            };
        }]);
})();
